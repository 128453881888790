import DefaultLayout from "../layouts/DefaultLayout";
import { useAppState, actions } from "../contexts/AppStateContext";
import { View, StyleSheet, Pressable, Alert } from "react-native";
import useResponsiveScreen from "../hooks/useResponsiveScreen";
import { useState } from "react";
import CustomText from "../components/common/general/CustomText/CustomText";
import { ScrollView } from "react-native-gesture-handler";
import Divider from "../components/common/layout/Divider/Divider";
import { useNavigation } from "@react-navigation/native";
import CustomButton from "../components/common/general/CustomButton/CustomButton";
import CustomSpacing from "../components/common/layout/CustomSpacing/CustomSpacing";
import {
  GetAllCoursesBySchoolDocument,
  useDeleteCourseCascadeMutation,
  useGetAllCoursesBySchoolQuery,
  useSchoolsQuery,
} from "../graphql/generated/graphql";
import ErrorLoader from "../components/common/status/ErrorLoader/ErrorLoader";
import Loader from "../components/common/status/Loader/Loader";
import DeleteCourseModal from "../components/content/ContentModalContainers/DeleteCourseModal";
import { useAppConfig } from "../AppConfigProvider";
import Sentry from "../utils/sentry";

function CustomCourseworkView() {
  const navigation = useNavigation();
  const { dispatch } = useAppState();
  const { isMedium } = useResponsiveScreen();

  const [deleteCourseModalVisible, setDeleteCourseModalVisible] = useState(null);

  const { data: schoolsData, loading: schoolDataLoading, error: schoolDataError } = useSchoolsQuery();

  const schoolId = schoolsData?.getSchools[0]?.id;

  const { primaryColor } = useAppConfig();

  const [deleteCourse] = useDeleteCourseCascadeMutation({
    refetchQueries: [
      {
        query: GetAllCoursesBySchoolDocument,
        variables: {
          schoolId,
        },
      },
    ],
  });

  const {
    loading: coursesLoading,
    data,
    error: coursesError,
  } = useGetAllCoursesBySchoolQuery({
    variables: {
      schoolId,
    },
    skip: !schoolId,
  });

  const courses = data?.getAllCoursesBySchool?.data;

  const handleCreateCourse = () => {
    navigation.navigate("Custom Coursework Course Form");
  };

  const handleCardPress = (courseName, courseId) => {
    navigation.navigate("Custom Coursework Modules", {
      courseId: courseId,
      courseName: courseName,
    });
  };

  const handleDeleteCourseModalOpen = (courseId) => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: false },
    });
    setDeleteCourseModalVisible(courseId);
  };

  const handleDeleteCourseModalClose = () => {
    dispatch({
      type: actions.SET_META,
      payload: { keypressIsListening: true },
    });
    setDeleteCourseModalVisible(null);
  };

  const handleDeleteCourse = async () => {
    const courseId = deleteCourseModalVisible;

    try {
      const response = await deleteCourse({
        variables: {
          id: courseId,
        },
      });
      if (response.data.deleteCourseCascade.success) {
        Alert.alert("Success", "Module deleted successfully");
        handleDeleteCourseModalClose();
      } else {
        Sentry.captureException(response.data?.deleteContentModule.message || "Failed to delete module");
        Alert.alert("Error", response.data?.deleteContentModule.message || "Failed to delete module");
      }
    } catch (error) {
      Sentry.captureException(error);
      Alert.alert("Error", "An error occurred while deleting the module.");
    }
  };

  const handleEditCourse = (courseId) => {
    navigation.navigate("Custom Coursework Course Form", {
      courseId,
    });
  };

  const error = schoolDataError || coursesError;

  const loading = schoolDataLoading || coursesLoading;

  if (error) {
    return (
      <DefaultLayout>
        <View style={styles.loaderContainer}>
          <ErrorLoader message="Something went wrong! Try reloading the page." />
        </View>
      </DefaultLayout>
    );
  }

  if (loading) {
    return (
      <DefaultLayout>
        <View style={styles.loaderContainer}>
          <Loader />
        </View>
      </DefaultLayout>
    );
  }

  return (
    <DefaultLayout>
      <View style={styles.headerContainer}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <CustomText
            role="header"
            text="My Courses"
            size={isMedium ? "l" : "xs"}
            textType="display"
            weight="ultraBold"
          />
          <CustomButton
            onPress={handleCreateCourse}
            size="l"
            text={"Create a Course"}
            disabled={loading}
            styleType="primary"
          />
        </View>
      </View>
      <CustomSpacing type="vertical" size="xxl" />
      <CustomSpacing type="vertical" size="xxl" />
      <ScrollView>
        <View>
          {courses.length === 0 ? (
            <View style={styles.emptyCourseContainer}>
              <CustomText
                useTranslationText={false}
                ultraBold
                text="You haven’t created a course yet"
                size="xl"
                style={{ color: "white" }}
              />
            </View>
          ) : (
            courses.map((course, courseIndex) => (
              <View key={`course-${courseIndex}`} style={styles.courseContainer}>
                <View style={{ flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
                  <CustomText useTranslationText={false} ultraBold text={course?.title} size="xxl" />
                  <View style={styles.buttonGroup}>
                    <CustomButton
                      onPress={() => handleCardPress(course.title, course.id)}
                      styleType="primaryLight"
                      text="View Course"
                    />
                    <Pressable onPress={() => handleEditCourse(course.id)}>
                      <CustomText
                        style={{
                          color: primaryColor,
                          padding: 12,
                          fontSize: 16,
                          fontFamily: "inter",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        text="Edit Course"
                      />
                    </Pressable>
                    <Pressable onPress={() => handleDeleteCourseModalOpen(course.id)}>
                      <CustomText
                        style={{
                          color: "#F75C8A",
                          padding: 12,
                          fontSize: 16,
                          fontFamily: "inter",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        text="Delete Course"
                      />
                    </Pressable>
                  </View>
                </View>
                <Divider type="vertical" />
                <View style={styles.detailsContainer}>
                  <View style={{ flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
                    <View style={{ flexDirection: "row", alignItems: "center", marginRight: 20 }}>
                      <CustomText ultraBold text="World View" size="l" />
                      <CustomText
                        style={{ color: "#777f89", marginLeft: 5 }}
                        useTranslationText={false}
                        text={course?.learningStandards}
                        size="m"
                      />
                    </View>
                    <View style={{ flexDirection: "row", alignItems: "center" }}>
                      <CustomText ultraBold text="Number of Weeks" size="l" />
                      <CustomText
                        style={{ color: "#777f89", marginLeft: 5 }}
                        useTranslationText={false}
                        text={course?.lengthInWeeks}
                        size="m"
                      />
                    </View>
                  </View>

                  <View style={styles.objectivesContainer}>
                    <CustomText ultraBold text="Objectives" size="l" />
                    {course?.objectives?.map((obj, objIndex) => (
                      <CustomText
                        style={{ color: "#777f89", marginLeft: 5 }}
                        useTranslationText={false}
                        key={`objective-${objIndex}`}
                        text={obj}
                        size="m"
                      />
                    ))}
                  </View>
                </View>
              </View>
            ))
          )}
        </View>
      </ScrollView>
      <DeleteCourseModal
        visible={!!deleteCourseModalVisible}
        onClose={handleDeleteCourseModalClose}
        onDeleteCourse={handleDeleteCourse}
      />
    </DefaultLayout>
  );
}

const styles = StyleSheet.create({
  courseContainer: {
    borderWidth: 1,
    borderColor: "#dcd6d6",
    borderRadius: 20,
    marginBottom: 30,
    padding: 20,
  },
  emptyCourseContainer: {
    backgroundColor: "#d3d3d3",
    borderRadius: 20,
    padding: 20,
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 30,
  },
  buttonGroup: {
    flexDirection: "row",
    gap: 10,
  },
  objectivesContainer: {
    flexDirection: "row",
    gap: 10,
    alignItems: "center",
  },
  detailsContainer: {
    marginTop: 10,
  },
  headerContainer: {
    flexDirection: "column",
  },
});

export default CustomCourseworkView;
