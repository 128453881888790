import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends " $fragmentName" | "__typename" ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string };
  String: { input: string; output: string };
  Boolean: { input: boolean; output: boolean };
  Int: { input: number; output: number };
  Float: { input: number; output: number };
  BigInt: { input: any; output: any };
  Date: { input: any; output: any };
  DateTime: { input: any; output: any };
  JSON: { input: any; output: any };
  Upload: { input: any; output: any };
};

export type Achievements = {
  __typename?: "Achievements";
  attributes: Array<Attribute>;
  attributesAggregate?: Maybe<AchievementsAttributeAttributesAggregationSelection>;
  attributesConnection: AchievementsAttributesConnection;
  created: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  last_modified: Scalars["DateTime"]["output"];
  name: Scalars["String"]["output"];
  users: Array<User>;
  usersAggregate?: Maybe<AchievementsUserUsersAggregationSelection>;
  usersConnection: AchievementsUsersConnection;
};

export type AchievementsAttributesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AttributeOptions>;
  where?: InputMaybe<AttributeWhere>;
};

export type AchievementsAttributesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AttributeWhere>;
};

export type AchievementsAttributesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AchievementsAttributesConnectionSort>>;
  where?: InputMaybe<AchievementsAttributesConnectionWhere>;
};

export type AchievementsUsersArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type AchievementsUsersAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type AchievementsUsersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AchievementsUsersConnectionSort>>;
  where?: InputMaybe<AchievementsUsersConnectionWhere>;
};

export type AchievementsAggregateSelection = {
  __typename?: "AchievementsAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
};

export type AchievementsAttributeAttributesAggregationSelection = {
  __typename?: "AchievementsAttributeAttributesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AchievementsAttributeAttributesNodeAggregateSelection>;
};

export type AchievementsAttributeAttributesNodeAggregateSelection = {
  __typename?: "AchievementsAttributeAttributesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  text: StringAggregateSelection;
};

export type AchievementsAttributesAggregateInput = {
  AND?: InputMaybe<Array<AchievementsAttributesAggregateInput>>;
  NOT?: InputMaybe<AchievementsAttributesAggregateInput>;
  OR?: InputMaybe<Array<AchievementsAttributesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AchievementsAttributesNodeAggregationWhereInput>;
};

export type AchievementsAttributesConnectFieldInput = {
  connect?: InputMaybe<Array<AttributeConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AttributeConnectWhere>;
};

export type AchievementsAttributesConnection = {
  __typename?: "AchievementsAttributesConnection";
  edges: Array<AchievementsAttributesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AchievementsAttributesConnectionSort = {
  node?: InputMaybe<AttributeSort>;
};

export type AchievementsAttributesConnectionWhere = {
  AND?: InputMaybe<Array<AchievementsAttributesConnectionWhere>>;
  NOT?: InputMaybe<AchievementsAttributesConnectionWhere>;
  OR?: InputMaybe<Array<AchievementsAttributesConnectionWhere>>;
  node?: InputMaybe<AttributeWhere>;
};

export type AchievementsAttributesCreateFieldInput = {
  node: AttributeCreateInput;
};

export type AchievementsAttributesDeleteFieldInput = {
  delete?: InputMaybe<AttributeDeleteInput>;
  where?: InputMaybe<AchievementsAttributesConnectionWhere>;
};

export type AchievementsAttributesDisconnectFieldInput = {
  disconnect?: InputMaybe<AttributeDisconnectInput>;
  where?: InputMaybe<AchievementsAttributesConnectionWhere>;
};

export type AchievementsAttributesFieldInput = {
  connect?: InputMaybe<Array<AchievementsAttributesConnectFieldInput>>;
  create?: InputMaybe<Array<AchievementsAttributesCreateFieldInput>>;
};

export type AchievementsAttributesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AchievementsAttributesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AchievementsAttributesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AchievementsAttributesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AchievementsAttributesRelationship = {
  __typename?: "AchievementsAttributesRelationship";
  cursor: Scalars["String"]["output"];
  node: Attribute;
};

export type AchievementsAttributesUpdateConnectionInput = {
  node?: InputMaybe<AttributeUpdateInput>;
};

export type AchievementsAttributesUpdateFieldInput = {
  connect?: InputMaybe<Array<AchievementsAttributesConnectFieldInput>>;
  create?: InputMaybe<Array<AchievementsAttributesCreateFieldInput>>;
  delete?: InputMaybe<Array<AchievementsAttributesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AchievementsAttributesDisconnectFieldInput>>;
  update?: InputMaybe<AchievementsAttributesUpdateConnectionInput>;
  where?: InputMaybe<AchievementsAttributesConnectionWhere>;
};

export type AchievementsConnectInput = {
  attributes?: InputMaybe<Array<AchievementsAttributesConnectFieldInput>>;
  users?: InputMaybe<Array<AchievementsUsersConnectFieldInput>>;
};

export type AchievementsConnectWhere = {
  node: AchievementsWhere;
};

export type AchievementsConnection = {
  __typename?: "AchievementsConnection";
  edges: Array<AchievementsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AchievementsCreateInput = {
  attributes?: InputMaybe<AchievementsAttributesFieldInput>;
  created: Scalars["DateTime"]["input"];
  id: Scalars["String"]["input"];
  last_modified: Scalars["DateTime"]["input"];
  name: Scalars["String"]["input"];
  users?: InputMaybe<AchievementsUsersFieldInput>;
};

export type AchievementsDeleteInput = {
  attributes?: InputMaybe<Array<AchievementsAttributesDeleteFieldInput>>;
  users?: InputMaybe<Array<AchievementsUsersDeleteFieldInput>>;
};

export type AchievementsDisconnectInput = {
  attributes?: InputMaybe<Array<AchievementsAttributesDisconnectFieldInput>>;
  users?: InputMaybe<Array<AchievementsUsersDisconnectFieldInput>>;
};

export type AchievementsEdge = {
  __typename?: "AchievementsEdge";
  cursor: Scalars["String"]["output"];
  node: Achievements;
};

export type AchievementsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AchievementsSort objects to sort Achievements by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AchievementsSort>>;
};

export type AchievementsRelationInput = {
  attributes?: InputMaybe<Array<AchievementsAttributesCreateFieldInput>>;
  users?: InputMaybe<Array<AchievementsUsersCreateFieldInput>>;
};

/** Fields to sort Achievements by. The order in which sorts are applied is not guaranteed when specifying many fields in one AchievementsSort object. */
export type AchievementsSort = {
  created?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type AchievementsUpdateInput = {
  attributes?: InputMaybe<Array<AchievementsAttributesUpdateFieldInput>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  users?: InputMaybe<Array<AchievementsUsersUpdateFieldInput>>;
};

export type AchievementsUserUsersAggregationSelection = {
  __typename?: "AchievementsUserUsersAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AchievementsUserUsersNodeAggregateSelection>;
};

export type AchievementsUserUsersNodeAggregateSelection = {
  __typename?: "AchievementsUserUsersNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type AchievementsUsersAggregateInput = {
  AND?: InputMaybe<Array<AchievementsUsersAggregateInput>>;
  NOT?: InputMaybe<AchievementsUsersAggregateInput>;
  OR?: InputMaybe<Array<AchievementsUsersAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AchievementsUsersNodeAggregationWhereInput>;
};

export type AchievementsUsersConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type AchievementsUsersConnection = {
  __typename?: "AchievementsUsersConnection";
  edges: Array<AchievementsUsersRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AchievementsUsersConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type AchievementsUsersConnectionWhere = {
  AND?: InputMaybe<Array<AchievementsUsersConnectionWhere>>;
  NOT?: InputMaybe<AchievementsUsersConnectionWhere>;
  OR?: InputMaybe<Array<AchievementsUsersConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type AchievementsUsersCreateFieldInput = {
  node: UserCreateInput;
};

export type AchievementsUsersDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<AchievementsUsersConnectionWhere>;
};

export type AchievementsUsersDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<AchievementsUsersConnectionWhere>;
};

export type AchievementsUsersFieldInput = {
  connect?: InputMaybe<Array<AchievementsUsersConnectFieldInput>>;
  create?: InputMaybe<Array<AchievementsUsersCreateFieldInput>>;
};

export type AchievementsUsersNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AchievementsUsersNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AchievementsUsersNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AchievementsUsersNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AchievementsUsersRelationship = {
  __typename?: "AchievementsUsersRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type AchievementsUsersUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type AchievementsUsersUpdateFieldInput = {
  connect?: InputMaybe<Array<AchievementsUsersConnectFieldInput>>;
  create?: InputMaybe<Array<AchievementsUsersCreateFieldInput>>;
  delete?: InputMaybe<Array<AchievementsUsersDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AchievementsUsersDisconnectFieldInput>>;
  update?: InputMaybe<AchievementsUsersUpdateConnectionInput>;
  where?: InputMaybe<AchievementsUsersConnectionWhere>;
};

export type AchievementsWhere = {
  AND?: InputMaybe<Array<AchievementsWhere>>;
  NOT?: InputMaybe<AchievementsWhere>;
  OR?: InputMaybe<Array<AchievementsWhere>>;
  attributesAggregate?: InputMaybe<AchievementsAttributesAggregateInput>;
  /** Return Achievements where all of the related AchievementsAttributesConnections match this filter */
  attributesConnection_ALL?: InputMaybe<AchievementsAttributesConnectionWhere>;
  /** Return Achievements where none of the related AchievementsAttributesConnections match this filter */
  attributesConnection_NONE?: InputMaybe<AchievementsAttributesConnectionWhere>;
  /** Return Achievements where one of the related AchievementsAttributesConnections match this filter */
  attributesConnection_SINGLE?: InputMaybe<AchievementsAttributesConnectionWhere>;
  /** Return Achievements where some of the related AchievementsAttributesConnections match this filter */
  attributesConnection_SOME?: InputMaybe<AchievementsAttributesConnectionWhere>;
  /** Return Achievements where all of the related Attributes match this filter */
  attributes_ALL?: InputMaybe<AttributeWhere>;
  /** Return Achievements where none of the related Attributes match this filter */
  attributes_NONE?: InputMaybe<AttributeWhere>;
  /** Return Achievements where one of the related Attributes match this filter */
  attributes_SINGLE?: InputMaybe<AttributeWhere>;
  /** Return Achievements where some of the related Attributes match this filter */
  attributes_SOME?: InputMaybe<AttributeWhere>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersAggregate?: InputMaybe<AchievementsUsersAggregateInput>;
  /** Return Achievements where all of the related AchievementsUsersConnections match this filter */
  usersConnection_ALL?: InputMaybe<AchievementsUsersConnectionWhere>;
  /** Return Achievements where none of the related AchievementsUsersConnections match this filter */
  usersConnection_NONE?: InputMaybe<AchievementsUsersConnectionWhere>;
  /** Return Achievements where one of the related AchievementsUsersConnections match this filter */
  usersConnection_SINGLE?: InputMaybe<AchievementsUsersConnectionWhere>;
  /** Return Achievements where some of the related AchievementsUsersConnections match this filter */
  usersConnection_SOME?: InputMaybe<AchievementsUsersConnectionWhere>;
  /** Return Achievements where all of the related Users match this filter */
  users_ALL?: InputMaybe<UserWhere>;
  /** Return Achievements where none of the related Users match this filter */
  users_NONE?: InputMaybe<UserWhere>;
  /** Return Achievements where one of the related Users match this filter */
  users_SINGLE?: InputMaybe<UserWhere>;
  /** Return Achievements where some of the related Users match this filter */
  users_SOME?: InputMaybe<UserWhere>;
};

export type ActiveTrack = {
  __typename?: "ActiveTrack";
  completedUnits?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  last_modified?: Maybe<Scalars["String"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  parentFields?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  parentSubfield?: Maybe<Scalars["String"]["output"]>;
  totalUnits?: Maybe<Scalars["Int"]["output"]>;
};

export type ActiveTrackAggregateSelection = {
  __typename?: "ActiveTrackAggregateSelection";
  completedUnits: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  imageUrl: StringAggregateSelection;
  last_modified: StringAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
  parentSubfield: StringAggregateSelection;
  totalUnits: IntAggregateSelection;
};

export type ActiveTrackCreateInput = {
  completedUnits?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  parentFields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentSubfield?: InputMaybe<Scalars["String"]["input"]>;
  totalUnits?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActiveTrackEdge = {
  __typename?: "ActiveTrackEdge";
  cursor: Scalars["String"]["output"];
  node: ActiveTrack;
};

export type ActiveTrackOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ActiveTrackSort objects to sort ActiveTracks by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ActiveTrackSort>>;
};

/** Fields to sort ActiveTracks by. The order in which sorts are applied is not guaranteed when specifying many fields in one ActiveTrackSort object. */
export type ActiveTrackSort = {
  completedUnits?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  imageUrl?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  mastery?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  parentSubfield?: InputMaybe<SortDirection>;
  totalUnits?: InputMaybe<SortDirection>;
};

export type ActiveTrackUpdateInput = {
  completedUnits?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  parentFields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentFields_POP?: InputMaybe<Scalars["Int"]["input"]>;
  parentFields_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentSubfield?: InputMaybe<Scalars["String"]["input"]>;
  totalUnits?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActiveTrackWhere = {
  AND?: InputMaybe<Array<ActiveTrackWhere>>;
  NOT?: InputMaybe<ActiveTrackWhere>;
  OR?: InputMaybe<Array<ActiveTrackWhere>>;
  completedUnits?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  completedUnits_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  imageUrl_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["String"]["input"]>;
  last_modified_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  last_modified_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  last_modified_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  parentFields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentFields_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  parentSubfield?: InputMaybe<Scalars["String"]["input"]>;
  parentSubfield_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  parentSubfield_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  parentSubfield_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentSubfield_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  totalUnits?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  totalUnits_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ActiveTracksConnection = {
  __typename?: "ActiveTracksConnection";
  edges: Array<ActiveTrackEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AddAssignmentResponse = {
  __typename?: "AddAssignmentResponse";
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type AddAssignmentResponseAggregateSelection = {
  __typename?: "AddAssignmentResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type AddAssignmentResponseCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type AddAssignmentResponseEdge = {
  __typename?: "AddAssignmentResponseEdge";
  cursor: Scalars["String"]["output"];
  node: AddAssignmentResponse;
};

export type AddAssignmentResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AddAssignmentResponseSort objects to sort AddAssignmentResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AddAssignmentResponseSort>>;
};

/** Fields to sort AddAssignmentResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AddAssignmentResponseSort object. */
export type AddAssignmentResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type AddAssignmentResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AddAssignmentResponseWhere = {
  AND?: InputMaybe<Array<AddAssignmentResponseWhere>>;
  NOT?: InputMaybe<AddAssignmentResponseWhere>;
  OR?: InputMaybe<Array<AddAssignmentResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AddAssignmentResponsesConnection = {
  __typename?: "AddAssignmentResponsesConnection";
  edges: Array<AddAssignmentResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AddContentModuleResponse = {
  __typename?: "AddContentModuleResponse";
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type AddContentModuleResponseAggregateSelection = {
  __typename?: "AddContentModuleResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type AddContentModuleResponseCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type AddContentModuleResponseEdge = {
  __typename?: "AddContentModuleResponseEdge";
  cursor: Scalars["String"]["output"];
  node: AddContentModuleResponse;
};

export type AddContentModuleResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AddContentModuleResponseSort objects to sort AddContentModuleResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AddContentModuleResponseSort>>;
};

/** Fields to sort AddContentModuleResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AddContentModuleResponseSort object. */
export type AddContentModuleResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type AddContentModuleResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AddContentModuleResponseWhere = {
  AND?: InputMaybe<Array<AddContentModuleResponseWhere>>;
  NOT?: InputMaybe<AddContentModuleResponseWhere>;
  OR?: InputMaybe<Array<AddContentModuleResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AddContentModuleResponsesConnection = {
  __typename?: "AddContentModuleResponsesConnection";
  edges: Array<AddContentModuleResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AspectDetailsType = {
  __typename?: "AspectDetailsType";
  calories?: Maybe<Scalars["Float"]["output"]>;
  carbohydrates_grams?: Maybe<Scalars["Float"]["output"]>;
  category?: Maybe<Scalars["String"]["output"]>;
  communicationChannel?: Maybe<Scalars["String"]["output"]>;
  completionStatus?: Maybe<Scalars["String"]["output"]>;
  connectionStrength?: Maybe<Scalars["Int"]["output"]>;
  contentType?: Maybe<Scalars["String"]["output"]>;
  context?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["Date"]["output"]>;
  deadline?: Maybe<Scalars["Date"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["Date"]["output"]>;
  fat_grams?: Maybe<Scalars["Float"]["output"]>;
  fiber_grams?: Maybe<Scalars["Float"]["output"]>;
  frequencyOfInteraction?: Maybe<Scalars["String"]["output"]>;
  friendId?: Maybe<Scalars["Int"]["output"]>;
  friendPersonalityTraits?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  id?: Maybe<Scalars["Int"]["output"]>;
  importance?: Maybe<Scalars["Int"]["output"]>;
  intensity?: Maybe<Scalars["Int"]["output"]>;
  language?: Maybe<Scalars["String"]["output"]>;
  lastInteractionDate?: Maybe<Scalars["Date"]["output"]>;
  lastUpdated?: Maybe<Scalars["Date"]["output"]>;
  learningDuration?: Maybe<Scalars["Int"]["output"]>;
  learningStyle?: Maybe<Scalars["String"]["output"]>;
  level?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  notificationPreferences?: Maybe<Scalars["String"]["output"]>;
  priority?: Maybe<Scalars["String"]["output"]>;
  protein_grams?: Maybe<Scalars["Float"]["output"]>;
  relationshipType?: Maybe<Scalars["String"]["output"]>;
  sentiment?: Maybe<Scalars["String"]["output"]>;
  serving_size?: Maybe<Scalars["String"]["output"]>;
  sharedEventsAttended?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sharedGoals?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sharedInterests?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  sodium_mg?: Maybe<Scalars["Float"]["output"]>;
  startDate?: Maybe<Scalars["Date"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
  sugars_grams?: Maybe<Scalars["Float"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  timeOfDay?: Maybe<Scalars["String"]["output"]>;
  timestamp?: Maybe<Scalars["Date"]["output"]>;
  totalCalories?: Maybe<Scalars["Float"]["output"]>;
  totalCaloriesBurned?: Maybe<Scalars["Float"]["output"]>;
  totalCarbs?: Maybe<Scalars["Float"]["output"]>;
  totalDurationMinutes?: Maybe<Scalars["Float"]["output"]>;
  totalFats?: Maybe<Scalars["Float"]["output"]>;
  totalProteins?: Maybe<Scalars["Float"]["output"]>;
  totalWorkouts?: Maybe<Scalars["Float"]["output"]>;
  trigger?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["Int"]["output"]>;
  water_intake_oz?: Maybe<Scalars["Float"]["output"]>;
};

export type AspectDetailsTypeAggregateSelection = {
  __typename?: "AspectDetailsTypeAggregateSelection";
  calories: FloatAggregateSelection;
  carbohydrates_grams: FloatAggregateSelection;
  category: StringAggregateSelection;
  communicationChannel: StringAggregateSelection;
  completionStatus: StringAggregateSelection;
  connectionStrength: IntAggregateSelection;
  contentType: StringAggregateSelection;
  context: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  fat_grams: FloatAggregateSelection;
  fiber_grams: FloatAggregateSelection;
  frequencyOfInteraction: StringAggregateSelection;
  friendId: IntAggregateSelection;
  id: IntAggregateSelection;
  importance: IntAggregateSelection;
  intensity: IntAggregateSelection;
  language: StringAggregateSelection;
  learningDuration: IntAggregateSelection;
  learningStyle: StringAggregateSelection;
  level: StringAggregateSelection;
  name: StringAggregateSelection;
  notificationPreferences: StringAggregateSelection;
  priority: StringAggregateSelection;
  protein_grams: FloatAggregateSelection;
  relationshipType: StringAggregateSelection;
  sentiment: StringAggregateSelection;
  serving_size: StringAggregateSelection;
  sodium_mg: FloatAggregateSelection;
  status: StringAggregateSelection;
  sugars_grams: FloatAggregateSelection;
  text: StringAggregateSelection;
  timeOfDay: StringAggregateSelection;
  totalCalories: FloatAggregateSelection;
  totalCaloriesBurned: FloatAggregateSelection;
  totalCarbs: FloatAggregateSelection;
  totalDurationMinutes: FloatAggregateSelection;
  totalFats: FloatAggregateSelection;
  totalProteins: FloatAggregateSelection;
  totalWorkouts: FloatAggregateSelection;
  trigger: StringAggregateSelection;
  type: StringAggregateSelection;
  value: IntAggregateSelection;
  water_intake_oz: FloatAggregateSelection;
};

export type AspectDetailsTypeCreateInput = {
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams?: InputMaybe<Scalars["Float"]["input"]>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  communicationChannel?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  connectionStrength?: InputMaybe<Scalars["Int"]["input"]>;
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["Date"]["input"]>;
  deadline?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  fat_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams?: InputMaybe<Scalars["Float"]["input"]>;
  frequencyOfInteraction?: InputMaybe<Scalars["String"]["input"]>;
  friendId?: InputMaybe<Scalars["Int"]["input"]>;
  friendPersonalityTraits?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  importance?: InputMaybe<Scalars["Int"]["input"]>;
  intensity?: InputMaybe<Scalars["Int"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  lastInteractionDate?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["Date"]["input"]>;
  learningDuration?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  protein_grams?: InputMaybe<Scalars["Float"]["input"]>;
  relationshipType?: InputMaybe<Scalars["String"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  sharedEventsAttended?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedGoals?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedInterests?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sodium_mg?: InputMaybe<Scalars["Float"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  sugars_grams?: InputMaybe<Scalars["Float"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["Date"]["input"]>;
  totalCalories?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts?: InputMaybe<Scalars["Float"]["input"]>;
  trigger?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_oz?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AspectDetailsTypeEdge = {
  __typename?: "AspectDetailsTypeEdge";
  cursor: Scalars["String"]["output"];
  node: AspectDetailsType;
};

export type AspectDetailsTypeOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AspectDetailsTypeSort objects to sort AspectDetailsTypes by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AspectDetailsTypeSort>>;
};

/** Fields to sort AspectDetailsTypes by. The order in which sorts are applied is not guaranteed when specifying many fields in one AspectDetailsTypeSort object. */
export type AspectDetailsTypeSort = {
  calories?: InputMaybe<SortDirection>;
  carbohydrates_grams?: InputMaybe<SortDirection>;
  category?: InputMaybe<SortDirection>;
  communicationChannel?: InputMaybe<SortDirection>;
  completionStatus?: InputMaybe<SortDirection>;
  connectionStrength?: InputMaybe<SortDirection>;
  contentType?: InputMaybe<SortDirection>;
  context?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  deadline?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  endDate?: InputMaybe<SortDirection>;
  fat_grams?: InputMaybe<SortDirection>;
  fiber_grams?: InputMaybe<SortDirection>;
  frequencyOfInteraction?: InputMaybe<SortDirection>;
  friendId?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  importance?: InputMaybe<SortDirection>;
  intensity?: InputMaybe<SortDirection>;
  language?: InputMaybe<SortDirection>;
  lastInteractionDate?: InputMaybe<SortDirection>;
  lastUpdated?: InputMaybe<SortDirection>;
  learningDuration?: InputMaybe<SortDirection>;
  learningStyle?: InputMaybe<SortDirection>;
  level?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  notificationPreferences?: InputMaybe<SortDirection>;
  priority?: InputMaybe<SortDirection>;
  protein_grams?: InputMaybe<SortDirection>;
  relationshipType?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  serving_size?: InputMaybe<SortDirection>;
  sodium_mg?: InputMaybe<SortDirection>;
  startDate?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
  sugars_grams?: InputMaybe<SortDirection>;
  text?: InputMaybe<SortDirection>;
  timeOfDay?: InputMaybe<SortDirection>;
  timestamp?: InputMaybe<SortDirection>;
  totalCalories?: InputMaybe<SortDirection>;
  totalCaloriesBurned?: InputMaybe<SortDirection>;
  totalCarbs?: InputMaybe<SortDirection>;
  totalDurationMinutes?: InputMaybe<SortDirection>;
  totalFats?: InputMaybe<SortDirection>;
  totalProteins?: InputMaybe<SortDirection>;
  totalWorkouts?: InputMaybe<SortDirection>;
  trigger?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
  value?: InputMaybe<SortDirection>;
  water_intake_oz?: InputMaybe<SortDirection>;
};

export type AspectDetailsTypeUpdateInput = {
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  calories_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  calories_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  communicationChannel?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  connectionStrength?: InputMaybe<Scalars["Int"]["input"]>;
  connectionStrength_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  connectionStrength_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["Date"]["input"]>;
  deadline?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  fat_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  frequencyOfInteraction?: InputMaybe<Scalars["String"]["input"]>;
  friendId?: InputMaybe<Scalars["Int"]["input"]>;
  friendId_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  friendId_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  friendPersonalityTraits?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  friendPersonalityTraits_POP?: InputMaybe<Scalars["Int"]["input"]>;
  friendPersonalityTraits_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  importance?: InputMaybe<Scalars["Int"]["input"]>;
  importance_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  importance_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  intensity?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  lastInteractionDate?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["Date"]["input"]>;
  learningDuration?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  protein_grams?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  relationshipType?: InputMaybe<Scalars["String"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  sharedEventsAttended?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedEventsAttended_POP?: InputMaybe<Scalars["Int"]["input"]>;
  sharedEventsAttended_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedGoals?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedGoals_POP?: InputMaybe<Scalars["Int"]["input"]>;
  sharedGoals_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedInterests?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedInterests_POP?: InputMaybe<Scalars["Int"]["input"]>;
  sharedInterests_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sodium_mg?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  sugars_grams?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["Date"]["input"]>;
  totalCalories?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  trigger?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["Int"]["input"]>;
  value_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  value_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_oz?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AspectDetailsTypeWhere = {
  AND?: InputMaybe<Array<AspectDetailsTypeWhere>>;
  NOT?: InputMaybe<AspectDetailsTypeWhere>;
  OR?: InputMaybe<Array<AspectDetailsTypeWhere>>;
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  calories_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  calories_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  carbohydrates_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  category_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  category_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  category_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  category_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  communicationChannel?: InputMaybe<Scalars["String"]["input"]>;
  communicationChannel_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  communicationChannel_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  communicationChannel_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  communicationChannel_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  completionStatus_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  connectionStrength?: InputMaybe<Scalars["Int"]["input"]>;
  connectionStrength_GT?: InputMaybe<Scalars["Int"]["input"]>;
  connectionStrength_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  connectionStrength_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  connectionStrength_LT?: InputMaybe<Scalars["Int"]["input"]>;
  connectionStrength_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  contentType_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  contentType_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  contentType_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contentType_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  context_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  context_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  context_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  context_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["Date"]["input"]>;
  date_GT?: InputMaybe<Scalars["Date"]["input"]>;
  date_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  date_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  date_LT?: InputMaybe<Scalars["Date"]["input"]>;
  date_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  deadline?: InputMaybe<Scalars["Date"]["input"]>;
  deadline_GT?: InputMaybe<Scalars["Date"]["input"]>;
  deadline_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  deadline_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  deadline_LT?: InputMaybe<Scalars["Date"]["input"]>;
  deadline_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  endDate_GT?: InputMaybe<Scalars["Date"]["input"]>;
  endDate_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  endDate_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  endDate_LT?: InputMaybe<Scalars["Date"]["input"]>;
  endDate_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  fat_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  fat_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  fiber_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  frequencyOfInteraction?: InputMaybe<Scalars["String"]["input"]>;
  frequencyOfInteraction_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  frequencyOfInteraction_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  frequencyOfInteraction_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  frequencyOfInteraction_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  friendId?: InputMaybe<Scalars["Int"]["input"]>;
  friendId_GT?: InputMaybe<Scalars["Int"]["input"]>;
  friendId_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  friendId_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  friendId_LT?: InputMaybe<Scalars["Int"]["input"]>;
  friendId_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  friendPersonalityTraits?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  friendPersonalityTraits_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  importance?: InputMaybe<Scalars["Int"]["input"]>;
  importance_GT?: InputMaybe<Scalars["Int"]["input"]>;
  importance_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  importance_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  importance_LT?: InputMaybe<Scalars["Int"]["input"]>;
  importance_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  intensity?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_GT?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  intensity_LT?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  language_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  language_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  language_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  language_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lastInteractionDate?: InputMaybe<Scalars["Date"]["input"]>;
  lastInteractionDate_GT?: InputMaybe<Scalars["Date"]["input"]>;
  lastInteractionDate_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  lastInteractionDate_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  lastInteractionDate_LT?: InputMaybe<Scalars["Date"]["input"]>;
  lastInteractionDate_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated_GT?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  lastUpdated_LT?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  learningDuration?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  learningDuration_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle?: InputMaybe<Scalars["String"]["input"]>;
  learningStyle_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  learningStyle_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  learningStyle_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  learningStyle_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  level_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  level_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  level_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  level_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  notificationPreferences_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  priority_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  priority_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  priority_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  priority_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  protein_grams?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  protein_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  relationshipType?: InputMaybe<Scalars["String"]["input"]>;
  relationshipType_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  relationshipType_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  relationshipType_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  relationshipType_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  serving_size_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  serving_size_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  serving_size_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  serving_size_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sharedEventsAttended?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedEventsAttended_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  sharedGoals?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedGoals_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  sharedInterests?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sharedInterests_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  sodium_mg?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  sodium_mg_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_GT?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  startDate_LT?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  status_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  status_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  status_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sugars_grams?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  sugars_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  text_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  text_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  text_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  timeOfDay_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["Date"]["input"]>;
  timestamp_GT?: InputMaybe<Scalars["Date"]["input"]>;
  timestamp_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  timestamp_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  timestamp_LT?: InputMaybe<Scalars["Date"]["input"]>;
  timestamp_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  totalCalories?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalCaloriesBurned_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalCalories_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalCarbs_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalDurationMinutes_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalFats_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalProteins_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalWorkouts_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  trigger?: InputMaybe<Scalars["String"]["input"]>;
  trigger_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  trigger_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  trigger_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  trigger_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["Int"]["input"]>;
  value_GT?: InputMaybe<Scalars["Int"]["input"]>;
  value_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  value_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  value_LT?: InputMaybe<Scalars["Int"]["input"]>;
  value_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_oz?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  water_intake_oz_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AspectDetailsTypesConnection = {
  __typename?: "AspectDetailsTypesConnection";
  edges: Array<AspectDetailsTypeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssignedQuest = {
  __typename?: "AssignedQuest";
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  isComplete: Scalars["Boolean"]["output"];
  title: Scalars["String"]["output"];
};

export type AssignedQuestAggregateSelection = {
  __typename?: "AssignedQuestAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  title: StringAggregateSelection;
};

export type AssignedQuestCreateInput = {
  description: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  isComplete: Scalars["Boolean"]["input"];
  title: Scalars["String"]["input"];
};

export type AssignedQuestEdge = {
  __typename?: "AssignedQuestEdge";
  cursor: Scalars["String"]["output"];
  node: AssignedQuest;
};

export type AssignedQuestOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AssignedQuestSort objects to sort AssignedQuests by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AssignedQuestSort>>;
};

/** Fields to sort AssignedQuests by. The order in which sorts are applied is not guaranteed when specifying many fields in one AssignedQuestSort object. */
export type AssignedQuestSort = {
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  isComplete?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
};

export type AssignedQuestUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  isComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssignedQuestWhere = {
  AND?: InputMaybe<Array<AssignedQuestWhere>>;
  NOT?: InputMaybe<AssignedQuestWhere>;
  OR?: InputMaybe<Array<AssignedQuestWhere>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  isComplete?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssignedQuestsConnection = {
  __typename?: "AssignedQuestsConnection";
  edges: Array<AssignedQuestEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Assignment = {
  __typename?: "Assignment";
  _empty?: Maybe<Scalars["String"]["output"]>;
  allowed_attempts?: Maybe<Scalars["Float"]["output"]>;
  anonymize_students?: Maybe<Scalars["Boolean"]["output"]>;
  anonymous_grading?: Maybe<Scalars["Boolean"]["output"]>;
  anonymous_instructor_annotations?: Maybe<Scalars["Boolean"]["output"]>;
  anonymous_peer_reviews?: Maybe<Scalars["Boolean"]["output"]>;
  assignee_ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  assignee_mode?: Maybe<Scalars["String"]["output"]>;
  assignment_group_id?: Maybe<Scalars["Float"]["output"]>;
  assignment_name?: Maybe<Scalars["String"]["output"]>;
  automatic_peer_reviews?: Maybe<Scalars["Boolean"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  can_duplicate?: Maybe<Scalars["Boolean"]["output"]>;
  category_id?: Maybe<Scalars["String"]["output"]>;
  courseCategoriesHasAssignment: Array<CourseCategory>;
  courseCategoriesHasAssignmentAggregate?: Maybe<AssignmentCourseCategoryCourseCategoriesHasAssignmentAggregationSelection>;
  courseCategoriesHasAssignmentConnection: AssignmentCourseCategoriesHasAssignmentConnection;
  course_id?: Maybe<Scalars["Float"]["output"]>;
  coursesHasAssignment: Array<Course>;
  coursesHasAssignmentAggregate?: Maybe<AssignmentCourseCoursesHasAssignmentAggregationSelection>;
  coursesHasAssignmentConnection: AssignmentCoursesHasAssignmentConnection;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  created_at?: Maybe<Scalars["String"]["output"]>;
  created_date?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  description_plaintext?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_allow_rating?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_assignment_id?: Maybe<Scalars["Float"]["output"]>;
  discussion_topic_attachments?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  discussion_topic_author_anonymous_id?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_author_avatar_image_url?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_author_display_name?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_author_html_url?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_author_id?: Maybe<Scalars["Float"]["output"]>;
  discussion_topic_can_group?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_can_lock?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_can_unpublish?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_comments_disabled?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_created_at?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_discussion_subentry_count?: Maybe<Scalars["Float"]["output"]>;
  discussion_topic_discussion_type?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_group_topic_children?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  discussion_topic_html_url?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_id?: Maybe<Scalars["Float"]["output"]>;
  discussion_topic_is_announcement?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_is_section_specific?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_last_reply_at?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_locked?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_locked_for_user?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_message?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_only_graders_can_rate?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_permissions_attach?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_permissions_delete?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_permissions_manage_assign_to?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_permissions_reply?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_permissions_update?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_pinned?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_podcast_has_student_posts?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_posted_at?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_published?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_read_state?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_sort_by_rating?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_subscribed?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_summary_enabled?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_title?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_topic_children?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  discussion_topic_unread_count?: Maybe<Scalars["Float"]["output"]>;
  discussion_topic_url?: Maybe<Scalars["String"]["output"]>;
  discussion_topic_user_can_see_posts?: Maybe<Scalars["Boolean"]["output"]>;
  discussion_topic_user_name?: Maybe<Scalars["String"]["output"]>;
  display_date?: Maybe<Scalars["String"]["output"]>;
  due_at?: Maybe<Scalars["String"]["output"]>;
  due_date?: Maybe<Scalars["String"]["output"]>;
  due_date_required?: Maybe<Scalars["Boolean"]["output"]>;
  embedding?: Maybe<Array<Maybe<Scalars["Float"]["output"]>>>;
  end_date?: Maybe<Scalars["String"]["output"]>;
  external_tool_tag_attributes_content_id?: Maybe<Scalars["Float"]["output"]>;
  external_tool_tag_attributes_content_type?: Maybe<Scalars["String"]["output"]>;
  external_tool_tag_attributes_external_data?: Maybe<Scalars["String"]["output"]>;
  external_tool_tag_attributes_new_tab?: Maybe<Scalars["Boolean"]["output"]>;
  external_tool_tag_attributes_resource_link_id?: Maybe<Scalars["String"]["output"]>;
  external_tool_tag_attributes_url?: Maybe<Scalars["String"]["output"]>;
  frozen_attributes?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  grade_group_students_individually?: Maybe<Scalars["Boolean"]["output"]>;
  graded_submissions_exist?: Maybe<Scalars["Boolean"]["output"]>;
  grader_comments_visible_to_graders?: Maybe<Scalars["Boolean"]["output"]>;
  grader_count?: Maybe<Scalars["Float"]["output"]>;
  grader_names_visible_to_final_grader?: Maybe<Scalars["Boolean"]["output"]>;
  graders_anonymous_to_graders?: Maybe<Scalars["Boolean"]["output"]>;
  grading_type?: Maybe<Scalars["String"]["output"]>;
  hasChatChats: Array<Chat>;
  hasChatChatsAggregate?: Maybe<AssignmentChatHasChatChatsAggregationSelection>;
  hasChatChatsConnection: AssignmentHasChatChatsConnection;
  hasObjectiveObjective: Array<Objective>;
  hasObjectiveObjectiveAggregate?: Maybe<AssignmentObjectiveHasObjectiveObjectiveAggregationSelection>;
  hasObjectiveObjectiveConnection: AssignmentHasObjectiveObjectiveConnection;
  has_overrides?: Maybe<Scalars["Boolean"]["output"]>;
  has_submitted_submissions?: Maybe<Scalars["Boolean"]["output"]>;
  hide_in_gradebook?: Maybe<Scalars["Boolean"]["output"]>;
  html?: Maybe<Scalars["String"]["output"]>;
  html_url?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  important_dates?: Maybe<Scalars["Boolean"]["output"]>;
  in_closed_grading_period?: Maybe<Scalars["Boolean"]["output"]>;
  intra_group_peer_reviews?: Maybe<Scalars["Boolean"]["output"]>;
  is_quiz_assignment?: Maybe<Scalars["Boolean"]["output"]>;
  is_quiz_lti_assignment?: Maybe<Scalars["Boolean"]["output"]>;
  lastModified?: Maybe<Scalars["DateTime"]["output"]>;
  locked_for_user?: Maybe<Scalars["Boolean"]["output"]>;
  lti_context_id?: Maybe<Scalars["String"]["output"]>;
  max_attempts?: Maybe<Scalars["Float"]["output"]>;
  max_name_length?: Maybe<Scalars["Float"]["output"]>;
  moderated_grading?: Maybe<Scalars["Boolean"]["output"]>;
  muted?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  needs_grading_count?: Maybe<Scalars["Float"]["output"]>;
  objectives?: Maybe<Scalars["String"]["output"]>;
  omit_from_final_grade?: Maybe<Scalars["Boolean"]["output"]>;
  only_visible_to_overrides?: Maybe<Scalars["Boolean"]["output"]>;
  peer_reviews?: Maybe<Scalars["Boolean"]["output"]>;
  points_possible?: Maybe<Scalars["Float"]["output"]>;
  position?: Maybe<Scalars["Float"]["output"]>;
  post_manually?: Maybe<Scalars["Boolean"]["output"]>;
  post_to_sis?: Maybe<Scalars["Boolean"]["output"]>;
  published?: Maybe<Scalars["Boolean"]["output"]>;
  require_lockdown_browser?: Maybe<Scalars["Boolean"]["output"]>;
  resourceHasAssignments: Array<Resource>;
  resourceHasAssignmentsAggregate?: Maybe<AssignmentResourceResourceHasAssignmentsAggregationSelection>;
  resourceHasAssignmentsConnection: AssignmentResourceHasAssignmentsConnection;
  resource_metadata?: Maybe<Scalars["String"]["output"]>;
  restrict_quantitative_data?: Maybe<Scalars["Boolean"]["output"]>;
  section_ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  secure_params?: Maybe<Scalars["String"]["output"]>;
  session_id?: Maybe<Scalars["String"]["output"]>;
  start_date?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  submissions_download_url?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  unpublishable?: Maybe<Scalars["Boolean"]["output"]>;
  updated_at?: Maybe<Scalars["String"]["output"]>;
  updated_date?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  usersHasSchoolAssignment: Array<User>;
  usersHasSchoolAssignmentAggregate?: Maybe<AssignmentUserUsersHasSchoolAssignmentAggregationSelection>;
  usersHasSchoolAssignmentConnection: AssignmentUsersHasSchoolAssignmentConnection;
  visible_to_everyone?: Maybe<Scalars["Boolean"]["output"]>;
  workflow_state?: Maybe<Scalars["String"]["output"]>;
};

export type AssignmentCourseCategoriesHasAssignmentArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<CourseCategoryOptions>;
  where?: InputMaybe<CourseCategoryWhere>;
};

export type AssignmentCourseCategoriesHasAssignmentAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<CourseCategoryWhere>;
};

export type AssignmentCourseCategoriesHasAssignmentConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentConnectionSort>>;
  where?: InputMaybe<AssignmentCourseCategoriesHasAssignmentConnectionWhere>;
};

export type AssignmentCoursesHasAssignmentArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<CourseOptions>;
  where?: InputMaybe<CourseWhere>;
};

export type AssignmentCoursesHasAssignmentAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<CourseWhere>;
};

export type AssignmentCoursesHasAssignmentConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AssignmentCoursesHasAssignmentConnectionSort>>;
  where?: InputMaybe<AssignmentCoursesHasAssignmentConnectionWhere>;
};

export type AssignmentHasChatChatsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ChatOptions>;
  where?: InputMaybe<ChatWhere>;
};

export type AssignmentHasChatChatsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ChatWhere>;
};

export type AssignmentHasChatChatsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AssignmentHasChatChatsConnectionSort>>;
  where?: InputMaybe<AssignmentHasChatChatsConnectionWhere>;
};

export type AssignmentHasObjectiveObjectiveArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ObjectiveOptions>;
  where?: InputMaybe<ObjectiveWhere>;
};

export type AssignmentHasObjectiveObjectiveAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ObjectiveWhere>;
};

export type AssignmentHasObjectiveObjectiveConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AssignmentHasObjectiveObjectiveConnectionSort>>;
  where?: InputMaybe<AssignmentHasObjectiveObjectiveConnectionWhere>;
};

export type AssignmentResourceHasAssignmentsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ResourceOptions>;
  where?: InputMaybe<ResourceWhere>;
};

export type AssignmentResourceHasAssignmentsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ResourceWhere>;
};

export type AssignmentResourceHasAssignmentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AssignmentResourceHasAssignmentsConnectionSort>>;
  where?: InputMaybe<AssignmentResourceHasAssignmentsConnectionWhere>;
};

export type AssignmentUsersHasSchoolAssignmentArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type AssignmentUsersHasSchoolAssignmentAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type AssignmentUsersHasSchoolAssignmentConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentConnectionSort>>;
  where?: InputMaybe<AssignmentUsersHasSchoolAssignmentConnectionWhere>;
};

export type AssignmentAggregateSelection = {
  __typename?: "AssignmentAggregateSelection";
  _empty: StringAggregateSelection;
  allowed_attempts: FloatAggregateSelection;
  assignee_mode: StringAggregateSelection;
  assignment_group_id: FloatAggregateSelection;
  assignment_name: StringAggregateSelection;
  body: StringAggregateSelection;
  category_id: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  course_id: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  description: StringAggregateSelection;
  description_plaintext: StringAggregateSelection;
  discussion_topic_assignment_id: FloatAggregateSelection;
  discussion_topic_author_anonymous_id: StringAggregateSelection;
  discussion_topic_author_avatar_image_url: StringAggregateSelection;
  discussion_topic_author_display_name: StringAggregateSelection;
  discussion_topic_author_html_url: StringAggregateSelection;
  discussion_topic_author_id: FloatAggregateSelection;
  discussion_topic_created_at: StringAggregateSelection;
  discussion_topic_discussion_subentry_count: FloatAggregateSelection;
  discussion_topic_discussion_type: StringAggregateSelection;
  discussion_topic_html_url: StringAggregateSelection;
  discussion_topic_id: FloatAggregateSelection;
  discussion_topic_last_reply_at: StringAggregateSelection;
  discussion_topic_message: StringAggregateSelection;
  discussion_topic_posted_at: StringAggregateSelection;
  discussion_topic_read_state: StringAggregateSelection;
  discussion_topic_title: StringAggregateSelection;
  discussion_topic_unread_count: FloatAggregateSelection;
  discussion_topic_url: StringAggregateSelection;
  discussion_topic_user_name: StringAggregateSelection;
  display_date: StringAggregateSelection;
  due_at: StringAggregateSelection;
  due_date: StringAggregateSelection;
  end_date: StringAggregateSelection;
  external_tool_tag_attributes_content_id: FloatAggregateSelection;
  external_tool_tag_attributes_content_type: StringAggregateSelection;
  external_tool_tag_attributes_external_data: StringAggregateSelection;
  external_tool_tag_attributes_resource_link_id: StringAggregateSelection;
  external_tool_tag_attributes_url: StringAggregateSelection;
  grader_count: FloatAggregateSelection;
  grading_type: StringAggregateSelection;
  html: StringAggregateSelection;
  html_url: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  lti_context_id: StringAggregateSelection;
  max_attempts: FloatAggregateSelection;
  max_name_length: FloatAggregateSelection;
  name: StringAggregateSelection;
  needs_grading_count: FloatAggregateSelection;
  objectives: StringAggregateSelection;
  points_possible: FloatAggregateSelection;
  position: FloatAggregateSelection;
  resource_metadata: StringAggregateSelection;
  secure_params: StringAggregateSelection;
  session_id: StringAggregateSelection;
  start_date: StringAggregateSelection;
  state: StringAggregateSelection;
  submissions_download_url: StringAggregateSelection;
  text: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_at: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  url: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type AssignmentChatHasChatChatsAggregationSelection = {
  __typename?: "AssignmentChatHasChatChatsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<AssignmentChatHasChatChatsEdgeAggregateSelection>;
  node?: Maybe<AssignmentChatHasChatChatsNodeAggregateSelection>;
};

export type AssignmentChatHasChatChatsEdgeAggregateSelection = {
  __typename?: "AssignmentChatHasChatChatsEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
};

export type AssignmentChatHasChatChatsNodeAggregateSelection = {
  __typename?: "AssignmentChatHasChatChatsNodeAggregateSelection";
  context: StringAggregateSelection;
  date: StringAggregateSelection;
  id: IdAggregateSelection;
  name: StringAggregateSelection;
  objectId: IdAggregateSelection;
  timezone: StringAggregateSelection;
  url: StringAggregateSelection;
  userId: IdAggregateSelection;
};

export type AssignmentConnectInput = {
  courseCategoriesHasAssignment?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentConnectFieldInput>>;
  coursesHasAssignment?: InputMaybe<Array<AssignmentCoursesHasAssignmentConnectFieldInput>>;
  hasChatChats?: InputMaybe<Array<AssignmentHasChatChatsConnectFieldInput>>;
  hasObjectiveObjective?: InputMaybe<Array<AssignmentHasObjectiveObjectiveConnectFieldInput>>;
  resourceHasAssignments?: InputMaybe<Array<AssignmentResourceHasAssignmentsConnectFieldInput>>;
  usersHasSchoolAssignment?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentConnectFieldInput>>;
};

export type AssignmentConnectOrCreateInput = {
  coursesHasAssignment?: InputMaybe<Array<AssignmentCoursesHasAssignmentConnectOrCreateFieldInput>>;
  resourceHasAssignments?: InputMaybe<Array<AssignmentResourceHasAssignmentsConnectOrCreateFieldInput>>;
};

export type AssignmentConnectOrCreateWhere = {
  node: AssignmentUniqueWhere;
};

export type AssignmentConnectWhere = {
  node: AssignmentWhere;
};

export type AssignmentCourseCategoriesHasAssignmentAggregateInput = {
  AND?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentAggregateInput>>;
  NOT?: InputMaybe<AssignmentCourseCategoriesHasAssignmentAggregateInput>;
  OR?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AssignmentCourseCategoriesHasAssignmentNodeAggregationWhereInput>;
};

export type AssignmentCourseCategoriesHasAssignmentConnectFieldInput = {
  connect?: InputMaybe<Array<CourseCategoryConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<CourseCategoryConnectWhere>;
};

export type AssignmentCourseCategoriesHasAssignmentConnection = {
  __typename?: "AssignmentCourseCategoriesHasAssignmentConnection";
  edges: Array<AssignmentCourseCategoriesHasAssignmentRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssignmentCourseCategoriesHasAssignmentConnectionSort = {
  node?: InputMaybe<CourseCategorySort>;
};

export type AssignmentCourseCategoriesHasAssignmentConnectionWhere = {
  AND?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentConnectionWhere>>;
  NOT?: InputMaybe<AssignmentCourseCategoriesHasAssignmentConnectionWhere>;
  OR?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentConnectionWhere>>;
  node?: InputMaybe<CourseCategoryWhere>;
};

export type AssignmentCourseCategoriesHasAssignmentCreateFieldInput = {
  node: CourseCategoryCreateInput;
};

export type AssignmentCourseCategoriesHasAssignmentDeleteFieldInput = {
  delete?: InputMaybe<CourseCategoryDeleteInput>;
  where?: InputMaybe<AssignmentCourseCategoriesHasAssignmentConnectionWhere>;
};

export type AssignmentCourseCategoriesHasAssignmentDisconnectFieldInput = {
  disconnect?: InputMaybe<CourseCategoryDisconnectInput>;
  where?: InputMaybe<AssignmentCourseCategoriesHasAssignmentConnectionWhere>;
};

export type AssignmentCourseCategoriesHasAssignmentFieldInput = {
  connect?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentConnectFieldInput>>;
  create?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentCreateFieldInput>>;
};

export type AssignmentCourseCategoriesHasAssignmentNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AssignmentCourseCategoriesHasAssignmentNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssignmentCourseCategoriesHasAssignmentRelationship = {
  __typename?: "AssignmentCourseCategoriesHasAssignmentRelationship";
  cursor: Scalars["String"]["output"];
  node: CourseCategory;
};

export type AssignmentCourseCategoriesHasAssignmentUpdateConnectionInput = {
  node?: InputMaybe<CourseCategoryUpdateInput>;
};

export type AssignmentCourseCategoriesHasAssignmentUpdateFieldInput = {
  connect?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentConnectFieldInput>>;
  create?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentCreateFieldInput>>;
  delete?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentDisconnectFieldInput>>;
  update?: InputMaybe<AssignmentCourseCategoriesHasAssignmentUpdateConnectionInput>;
  where?: InputMaybe<AssignmentCourseCategoriesHasAssignmentConnectionWhere>;
};

export type AssignmentCourseCategoryCourseCategoriesHasAssignmentAggregationSelection = {
  __typename?: "AssignmentCourseCategoryCourseCategoriesHasAssignmentAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AssignmentCourseCategoryCourseCategoriesHasAssignmentNodeAggregateSelection>;
};

export type AssignmentCourseCategoryCourseCategoriesHasAssignmentNodeAggregateSelection = {
  __typename?: "AssignmentCourseCategoryCourseCategoriesHasAssignmentNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  title: StringAggregateSelection;
};

export type AssignmentCourseCoursesHasAssignmentAggregationSelection = {
  __typename?: "AssignmentCourseCoursesHasAssignmentAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AssignmentCourseCoursesHasAssignmentNodeAggregateSelection>;
};

export type AssignmentCourseCoursesHasAssignmentNodeAggregateSelection = {
  __typename?: "AssignmentCourseCoursesHasAssignmentNodeAggregateSelection";
  account_id: FloatAggregateSelection;
  calendar_ics: StringAggregateSelection;
  courseId: StringAggregateSelection;
  course_code: StringAggregateSelection;
  course_id: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  default_view: StringAggregateSelection;
  description: StringAggregateSelection;
  end_at: StringAggregateSelection;
  enrollment_term_id: FloatAggregateSelection;
  grading_standard_id: FloatAggregateSelection;
  id: IdAggregateSelection;
  integration_id: StringAggregateSelection;
  label: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  learningStandards: StringAggregateSelection;
  lengthInWeeks: FloatAggregateSelection;
  license: StringAggregateSelection;
  lti_id: StringAggregateSelection;
  name: StringAggregateSelection;
  original_name: StringAggregateSelection;
  root_account_id: FloatAggregateSelection;
  sis_course_id: StringAggregateSelection;
  sis_import_id: FloatAggregateSelection;
  start_at: StringAggregateSelection;
  state: StringAggregateSelection;
  storage_quota_mb: FloatAggregateSelection;
  time_zone: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  uuid: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type AssignmentCoursesHasAssignmentAggregateInput = {
  AND?: InputMaybe<Array<AssignmentCoursesHasAssignmentAggregateInput>>;
  NOT?: InputMaybe<AssignmentCoursesHasAssignmentAggregateInput>;
  OR?: InputMaybe<Array<AssignmentCoursesHasAssignmentAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AssignmentCoursesHasAssignmentNodeAggregationWhereInput>;
};

export type AssignmentCoursesHasAssignmentConnectFieldInput = {
  connect?: InputMaybe<Array<CourseConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<CourseConnectWhere>;
};

export type AssignmentCoursesHasAssignmentConnectOrCreateFieldInput = {
  onCreate: AssignmentCoursesHasAssignmentConnectOrCreateFieldInputOnCreate;
  where: CourseConnectOrCreateWhere;
};

export type AssignmentCoursesHasAssignmentConnectOrCreateFieldInputOnCreate = {
  node: CourseOnCreateInput;
};

export type AssignmentCoursesHasAssignmentConnection = {
  __typename?: "AssignmentCoursesHasAssignmentConnection";
  edges: Array<AssignmentCoursesHasAssignmentRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssignmentCoursesHasAssignmentConnectionSort = {
  node?: InputMaybe<CourseSort>;
};

export type AssignmentCoursesHasAssignmentConnectionWhere = {
  AND?: InputMaybe<Array<AssignmentCoursesHasAssignmentConnectionWhere>>;
  NOT?: InputMaybe<AssignmentCoursesHasAssignmentConnectionWhere>;
  OR?: InputMaybe<Array<AssignmentCoursesHasAssignmentConnectionWhere>>;
  node?: InputMaybe<CourseWhere>;
};

export type AssignmentCoursesHasAssignmentCreateFieldInput = {
  node: CourseCreateInput;
};

export type AssignmentCoursesHasAssignmentDeleteFieldInput = {
  delete?: InputMaybe<CourseDeleteInput>;
  where?: InputMaybe<AssignmentCoursesHasAssignmentConnectionWhere>;
};

export type AssignmentCoursesHasAssignmentDisconnectFieldInput = {
  disconnect?: InputMaybe<CourseDisconnectInput>;
  where?: InputMaybe<AssignmentCoursesHasAssignmentConnectionWhere>;
};

export type AssignmentCoursesHasAssignmentFieldInput = {
  connect?: InputMaybe<Array<AssignmentCoursesHasAssignmentConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AssignmentCoursesHasAssignmentConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AssignmentCoursesHasAssignmentCreateFieldInput>>;
};

export type AssignmentCoursesHasAssignmentNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AssignmentCoursesHasAssignmentNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AssignmentCoursesHasAssignmentNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AssignmentCoursesHasAssignmentNodeAggregationWhereInput>>;
  account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_term_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lengthInWeeks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  root_account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_import_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  storage_quota_mb_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssignmentCoursesHasAssignmentRelationship = {
  __typename?: "AssignmentCoursesHasAssignmentRelationship";
  cursor: Scalars["String"]["output"];
  node: Course;
};

export type AssignmentCoursesHasAssignmentUpdateConnectionInput = {
  node?: InputMaybe<CourseUpdateInput>;
};

export type AssignmentCoursesHasAssignmentUpdateFieldInput = {
  connect?: InputMaybe<Array<AssignmentCoursesHasAssignmentConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AssignmentCoursesHasAssignmentConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AssignmentCoursesHasAssignmentCreateFieldInput>>;
  delete?: InputMaybe<Array<AssignmentCoursesHasAssignmentDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AssignmentCoursesHasAssignmentDisconnectFieldInput>>;
  update?: InputMaybe<AssignmentCoursesHasAssignmentUpdateConnectionInput>;
  where?: InputMaybe<AssignmentCoursesHasAssignmentConnectionWhere>;
};

export type AssignmentCreateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  allowed_attempts?: InputMaybe<Scalars["Float"]["input"]>;
  anonymize_students?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_grading?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_instructor_annotations?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  assignee_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignee_mode?: InputMaybe<Scalars["String"]["input"]>;
  assignment_group_id?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name?: InputMaybe<Scalars["String"]["input"]>;
  automatic_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  can_duplicate?: InputMaybe<Scalars["Boolean"]["input"]>;
  category_id?: InputMaybe<Scalars["String"]["input"]>;
  courseCategoriesHasAssignment?: InputMaybe<AssignmentCourseCategoriesHasAssignmentFieldInput>;
  course_id?: InputMaybe<Scalars["Float"]["input"]>;
  coursesHasAssignment?: InputMaybe<AssignmentCoursesHasAssignmentFieldInput>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_plaintext?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_allow_rating?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_assignment_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_attachments?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_author_anonymous_id?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_avatar_image_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_display_name?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_html_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_can_group?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_can_lock?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_can_unpublish?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_comments_disabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_created_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_discussion_subentry_count?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_group_topic_children?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_html_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_is_announcement?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_is_section_specific?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_last_reply_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_message?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_only_graders_can_rate?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_attach?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_manage_assign_to?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_reply?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_update?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_pinned?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_podcast_has_student_posts?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_posted_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_published?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_read_state?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_sort_by_rating?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_subscribed?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_summary_enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_title?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_topic_children?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_unread_count?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_user_can_see_posts?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_user_name?: InputMaybe<Scalars["String"]["input"]>;
  display_date?: InputMaybe<Scalars["String"]["input"]>;
  due_at?: InputMaybe<Scalars["String"]["input"]>;
  due_date?: InputMaybe<Scalars["String"]["input"]>;
  due_date_required?: InputMaybe<Scalars["Boolean"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  end_date?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_content_id?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_external_data?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_new_tab?: InputMaybe<Scalars["Boolean"]["input"]>;
  external_tool_tag_attributes_resource_link_id?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_url?: InputMaybe<Scalars["String"]["input"]>;
  frozen_attributes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  grade_group_students_individually?: InputMaybe<Scalars["Boolean"]["input"]>;
  graded_submissions_exist?: InputMaybe<Scalars["Boolean"]["input"]>;
  grader_comments_visible_to_graders?: InputMaybe<Scalars["Boolean"]["input"]>;
  grader_count?: InputMaybe<Scalars["Float"]["input"]>;
  grader_names_visible_to_final_grader?: InputMaybe<Scalars["Boolean"]["input"]>;
  graders_anonymous_to_graders?: InputMaybe<Scalars["Boolean"]["input"]>;
  grading_type?: InputMaybe<Scalars["String"]["input"]>;
  hasChatChats?: InputMaybe<AssignmentHasChatChatsFieldInput>;
  hasObjectiveObjective?: InputMaybe<AssignmentHasObjectiveObjectiveFieldInput>;
  has_overrides?: InputMaybe<Scalars["Boolean"]["input"]>;
  has_submitted_submissions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hide_in_gradebook?: InputMaybe<Scalars["Boolean"]["input"]>;
  html?: InputMaybe<Scalars["String"]["input"]>;
  html_url?: InputMaybe<Scalars["String"]["input"]>;
  important_dates?: InputMaybe<Scalars["Boolean"]["input"]>;
  in_closed_grading_period?: InputMaybe<Scalars["Boolean"]["input"]>;
  intra_group_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_quiz_assignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_quiz_lti_assignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  lti_context_id?: InputMaybe<Scalars["String"]["input"]>;
  max_attempts?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length?: InputMaybe<Scalars["Float"]["input"]>;
  moderated_grading?: InputMaybe<Scalars["Boolean"]["input"]>;
  muted?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  needs_grading_count?: InputMaybe<Scalars["Float"]["input"]>;
  objectives?: InputMaybe<Scalars["String"]["input"]>;
  omit_from_final_grade?: InputMaybe<Scalars["Boolean"]["input"]>;
  only_visible_to_overrides?: InputMaybe<Scalars["Boolean"]["input"]>;
  peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  points_possible?: InputMaybe<Scalars["Float"]["input"]>;
  position?: InputMaybe<Scalars["Float"]["input"]>;
  post_manually?: InputMaybe<Scalars["Boolean"]["input"]>;
  post_to_sis?: InputMaybe<Scalars["Boolean"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  require_lockdown_browser?: InputMaybe<Scalars["Boolean"]["input"]>;
  resourceHasAssignments?: InputMaybe<AssignmentResourceHasAssignmentsFieldInput>;
  resource_metadata?: InputMaybe<Scalars["String"]["input"]>;
  restrict_quantitative_data?: InputMaybe<Scalars["Boolean"]["input"]>;
  section_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  secure_params?: InputMaybe<Scalars["String"]["input"]>;
  session_id?: InputMaybe<Scalars["String"]["input"]>;
  start_date?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  submissions_download_url?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
  unpublishable?: InputMaybe<Scalars["Boolean"]["input"]>;
  updated_at?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSchoolAssignment?: InputMaybe<AssignmentUsersHasSchoolAssignmentFieldInput>;
  visible_to_everyone?: InputMaybe<Scalars["Boolean"]["input"]>;
  workflow_state?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssignmentDeleteInput = {
  courseCategoriesHasAssignment?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentDeleteFieldInput>>;
  coursesHasAssignment?: InputMaybe<Array<AssignmentCoursesHasAssignmentDeleteFieldInput>>;
  hasChatChats?: InputMaybe<Array<AssignmentHasChatChatsDeleteFieldInput>>;
  hasObjectiveObjective?: InputMaybe<Array<AssignmentHasObjectiveObjectiveDeleteFieldInput>>;
  resourceHasAssignments?: InputMaybe<Array<AssignmentResourceHasAssignmentsDeleteFieldInput>>;
  usersHasSchoolAssignment?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentDeleteFieldInput>>;
};

export type AssignmentDisconnectInput = {
  courseCategoriesHasAssignment?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentDisconnectFieldInput>>;
  coursesHasAssignment?: InputMaybe<Array<AssignmentCoursesHasAssignmentDisconnectFieldInput>>;
  hasChatChats?: InputMaybe<Array<AssignmentHasChatChatsDisconnectFieldInput>>;
  hasObjectiveObjective?: InputMaybe<Array<AssignmentHasObjectiveObjectiveDisconnectFieldInput>>;
  resourceHasAssignments?: InputMaybe<Array<AssignmentResourceHasAssignmentsDisconnectFieldInput>>;
  usersHasSchoolAssignment?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentDisconnectFieldInput>>;
};

export type AssignmentEdge = {
  __typename?: "AssignmentEdge";
  cursor: Scalars["String"]["output"];
  node: Assignment;
};

export type AssignmentHasChatChatsAggregateInput = {
  AND?: InputMaybe<Array<AssignmentHasChatChatsAggregateInput>>;
  NOT?: InputMaybe<AssignmentHasChatChatsAggregateInput>;
  OR?: InputMaybe<Array<AssignmentHasChatChatsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasChatPropertiesAggregationWhereInput>;
  node?: InputMaybe<AssignmentHasChatChatsNodeAggregationWhereInput>;
};

export type AssignmentHasChatChatsConnectFieldInput = {
  connect?: InputMaybe<Array<ChatConnectInput>>;
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ChatConnectWhere>;
};

export type AssignmentHasChatChatsConnection = {
  __typename?: "AssignmentHasChatChatsConnection";
  edges: Array<AssignmentHasChatChatsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssignmentHasChatChatsConnectionSort = {
  edge?: InputMaybe<HasChatPropertiesSort>;
  node?: InputMaybe<ChatSort>;
};

export type AssignmentHasChatChatsConnectionWhere = {
  AND?: InputMaybe<Array<AssignmentHasChatChatsConnectionWhere>>;
  NOT?: InputMaybe<AssignmentHasChatChatsConnectionWhere>;
  OR?: InputMaybe<Array<AssignmentHasChatChatsConnectionWhere>>;
  edge?: InputMaybe<HasChatPropertiesWhere>;
  node?: InputMaybe<ChatWhere>;
};

export type AssignmentHasChatChatsCreateFieldInput = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: ChatCreateInput;
};

export type AssignmentHasChatChatsDeleteFieldInput = {
  delete?: InputMaybe<ChatDeleteInput>;
  where?: InputMaybe<AssignmentHasChatChatsConnectionWhere>;
};

export type AssignmentHasChatChatsDisconnectFieldInput = {
  disconnect?: InputMaybe<ChatDisconnectInput>;
  where?: InputMaybe<AssignmentHasChatChatsConnectionWhere>;
};

export type AssignmentHasChatChatsFieldInput = {
  connect?: InputMaybe<Array<AssignmentHasChatChatsConnectFieldInput>>;
  create?: InputMaybe<Array<AssignmentHasChatChatsCreateFieldInput>>;
};

export type AssignmentHasChatChatsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AssignmentHasChatChatsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AssignmentHasChatChatsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AssignmentHasChatChatsNodeAggregationWhereInput>>;
  context_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssignmentHasChatChatsRelationship = {
  __typename?: "AssignmentHasChatChatsRelationship";
  cursor: Scalars["String"]["output"];
  node: Chat;
  properties: HasChatProperties;
};

export type AssignmentHasChatChatsUpdateConnectionInput = {
  edge?: InputMaybe<HasChatPropertiesUpdateInput>;
  node?: InputMaybe<ChatUpdateInput>;
};

export type AssignmentHasChatChatsUpdateFieldInput = {
  connect?: InputMaybe<Array<AssignmentHasChatChatsConnectFieldInput>>;
  create?: InputMaybe<Array<AssignmentHasChatChatsCreateFieldInput>>;
  delete?: InputMaybe<Array<AssignmentHasChatChatsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AssignmentHasChatChatsDisconnectFieldInput>>;
  update?: InputMaybe<AssignmentHasChatChatsUpdateConnectionInput>;
  where?: InputMaybe<AssignmentHasChatChatsConnectionWhere>;
};

export type AssignmentHasObjectiveObjectiveAggregateInput = {
  AND?: InputMaybe<Array<AssignmentHasObjectiveObjectiveAggregateInput>>;
  NOT?: InputMaybe<AssignmentHasObjectiveObjectiveAggregateInput>;
  OR?: InputMaybe<Array<AssignmentHasObjectiveObjectiveAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AssignmentHasObjectiveObjectiveNodeAggregationWhereInput>;
};

export type AssignmentHasObjectiveObjectiveConnectFieldInput = {
  connect?: InputMaybe<Array<ObjectiveConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ObjectiveConnectWhere>;
};

export type AssignmentHasObjectiveObjectiveConnection = {
  __typename?: "AssignmentHasObjectiveObjectiveConnection";
  edges: Array<AssignmentHasObjectiveObjectiveRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssignmentHasObjectiveObjectiveConnectionSort = {
  node?: InputMaybe<ObjectiveSort>;
};

export type AssignmentHasObjectiveObjectiveConnectionWhere = {
  AND?: InputMaybe<Array<AssignmentHasObjectiveObjectiveConnectionWhere>>;
  NOT?: InputMaybe<AssignmentHasObjectiveObjectiveConnectionWhere>;
  OR?: InputMaybe<Array<AssignmentHasObjectiveObjectiveConnectionWhere>>;
  node?: InputMaybe<ObjectiveWhere>;
};

export type AssignmentHasObjectiveObjectiveCreateFieldInput = {
  node: ObjectiveCreateInput;
};

export type AssignmentHasObjectiveObjectiveDeleteFieldInput = {
  delete?: InputMaybe<ObjectiveDeleteInput>;
  where?: InputMaybe<AssignmentHasObjectiveObjectiveConnectionWhere>;
};

export type AssignmentHasObjectiveObjectiveDisconnectFieldInput = {
  disconnect?: InputMaybe<ObjectiveDisconnectInput>;
  where?: InputMaybe<AssignmentHasObjectiveObjectiveConnectionWhere>;
};

export type AssignmentHasObjectiveObjectiveFieldInput = {
  connect?: InputMaybe<Array<AssignmentHasObjectiveObjectiveConnectFieldInput>>;
  create?: InputMaybe<Array<AssignmentHasObjectiveObjectiveCreateFieldInput>>;
};

export type AssignmentHasObjectiveObjectiveNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AssignmentHasObjectiveObjectiveNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AssignmentHasObjectiveObjectiveNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AssignmentHasObjectiveObjectiveNodeAggregationWhereInput>>;
  evalCriteria_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  evalCriteria_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  evalCriteria_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  evalCriteria_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  evalCriteria_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  evalCriteria_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  evalCriteria_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  evalCriteria_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  evalCriteria_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  evalCriteria_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  evalCriteria_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  evalCriteria_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  evalCriteria_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  evalCriteria_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  evalCriteria_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  objective_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  objective_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  objective_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  objective_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  objective_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objective_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objective_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objective_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objective_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objective_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objective_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sequence_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type AssignmentHasObjectiveObjectiveRelationship = {
  __typename?: "AssignmentHasObjectiveObjectiveRelationship";
  cursor: Scalars["String"]["output"];
  node: Objective;
};

export type AssignmentHasObjectiveObjectiveUpdateConnectionInput = {
  node?: InputMaybe<ObjectiveUpdateInput>;
};

export type AssignmentHasObjectiveObjectiveUpdateFieldInput = {
  connect?: InputMaybe<Array<AssignmentHasObjectiveObjectiveConnectFieldInput>>;
  create?: InputMaybe<Array<AssignmentHasObjectiveObjectiveCreateFieldInput>>;
  delete?: InputMaybe<Array<AssignmentHasObjectiveObjectiveDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AssignmentHasObjectiveObjectiveDisconnectFieldInput>>;
  update?: InputMaybe<AssignmentHasObjectiveObjectiveUpdateConnectionInput>;
  where?: InputMaybe<AssignmentHasObjectiveObjectiveConnectionWhere>;
};

export type AssignmentObjectiveHasObjectiveObjectiveAggregationSelection = {
  __typename?: "AssignmentObjectiveHasObjectiveObjectiveAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AssignmentObjectiveHasObjectiveObjectiveNodeAggregateSelection>;
};

export type AssignmentObjectiveHasObjectiveObjectiveNodeAggregateSelection = {
  __typename?: "AssignmentObjectiveHasObjectiveObjectiveNodeAggregateSelection";
  evalCriteria: StringAggregateSelection;
  id: IdAggregateSelection;
  objective: StringAggregateSelection;
  sequence: FloatAggregateSelection;
};

export type AssignmentOnCreateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  allowed_attempts?: InputMaybe<Scalars["Float"]["input"]>;
  anonymize_students?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_grading?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_instructor_annotations?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  assignee_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignee_mode?: InputMaybe<Scalars["String"]["input"]>;
  assignment_group_id?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name?: InputMaybe<Scalars["String"]["input"]>;
  automatic_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  can_duplicate?: InputMaybe<Scalars["Boolean"]["input"]>;
  category_id?: InputMaybe<Scalars["String"]["input"]>;
  course_id?: InputMaybe<Scalars["Float"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_plaintext?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_allow_rating?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_assignment_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_attachments?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_author_anonymous_id?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_avatar_image_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_display_name?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_html_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_can_group?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_can_lock?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_can_unpublish?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_comments_disabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_created_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_discussion_subentry_count?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_group_topic_children?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_html_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_is_announcement?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_is_section_specific?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_last_reply_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_message?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_only_graders_can_rate?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_attach?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_manage_assign_to?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_reply?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_update?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_pinned?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_podcast_has_student_posts?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_posted_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_published?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_read_state?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_sort_by_rating?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_subscribed?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_summary_enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_title?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_topic_children?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_unread_count?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_user_can_see_posts?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_user_name?: InputMaybe<Scalars["String"]["input"]>;
  display_date?: InputMaybe<Scalars["String"]["input"]>;
  due_at?: InputMaybe<Scalars["String"]["input"]>;
  due_date?: InputMaybe<Scalars["String"]["input"]>;
  due_date_required?: InputMaybe<Scalars["Boolean"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  end_date?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_content_id?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_external_data?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_new_tab?: InputMaybe<Scalars["Boolean"]["input"]>;
  external_tool_tag_attributes_resource_link_id?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_url?: InputMaybe<Scalars["String"]["input"]>;
  frozen_attributes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  grade_group_students_individually?: InputMaybe<Scalars["Boolean"]["input"]>;
  graded_submissions_exist?: InputMaybe<Scalars["Boolean"]["input"]>;
  grader_comments_visible_to_graders?: InputMaybe<Scalars["Boolean"]["input"]>;
  grader_count?: InputMaybe<Scalars["Float"]["input"]>;
  grader_names_visible_to_final_grader?: InputMaybe<Scalars["Boolean"]["input"]>;
  graders_anonymous_to_graders?: InputMaybe<Scalars["Boolean"]["input"]>;
  grading_type?: InputMaybe<Scalars["String"]["input"]>;
  has_overrides?: InputMaybe<Scalars["Boolean"]["input"]>;
  has_submitted_submissions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hide_in_gradebook?: InputMaybe<Scalars["Boolean"]["input"]>;
  html?: InputMaybe<Scalars["String"]["input"]>;
  html_url?: InputMaybe<Scalars["String"]["input"]>;
  important_dates?: InputMaybe<Scalars["Boolean"]["input"]>;
  in_closed_grading_period?: InputMaybe<Scalars["Boolean"]["input"]>;
  intra_group_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_quiz_assignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_quiz_lti_assignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  lti_context_id?: InputMaybe<Scalars["String"]["input"]>;
  max_attempts?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length?: InputMaybe<Scalars["Float"]["input"]>;
  moderated_grading?: InputMaybe<Scalars["Boolean"]["input"]>;
  muted?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  needs_grading_count?: InputMaybe<Scalars["Float"]["input"]>;
  objectives?: InputMaybe<Scalars["String"]["input"]>;
  omit_from_final_grade?: InputMaybe<Scalars["Boolean"]["input"]>;
  only_visible_to_overrides?: InputMaybe<Scalars["Boolean"]["input"]>;
  peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  points_possible?: InputMaybe<Scalars["Float"]["input"]>;
  position?: InputMaybe<Scalars["Float"]["input"]>;
  post_manually?: InputMaybe<Scalars["Boolean"]["input"]>;
  post_to_sis?: InputMaybe<Scalars["Boolean"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  require_lockdown_browser?: InputMaybe<Scalars["Boolean"]["input"]>;
  resource_metadata?: InputMaybe<Scalars["String"]["input"]>;
  restrict_quantitative_data?: InputMaybe<Scalars["Boolean"]["input"]>;
  section_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  secure_params?: InputMaybe<Scalars["String"]["input"]>;
  session_id?: InputMaybe<Scalars["String"]["input"]>;
  start_date?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  submissions_download_url?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
  unpublishable?: InputMaybe<Scalars["Boolean"]["input"]>;
  updated_at?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  visible_to_everyone?: InputMaybe<Scalars["Boolean"]["input"]>;
  workflow_state?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssignmentOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AssignmentSort objects to sort Assignments by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AssignmentSort>>;
};

export type AssignmentRelationInput = {
  courseCategoriesHasAssignment?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentCreateFieldInput>>;
  coursesHasAssignment?: InputMaybe<Array<AssignmentCoursesHasAssignmentCreateFieldInput>>;
  hasChatChats?: InputMaybe<Array<AssignmentHasChatChatsCreateFieldInput>>;
  hasObjectiveObjective?: InputMaybe<Array<AssignmentHasObjectiveObjectiveCreateFieldInput>>;
  resourceHasAssignments?: InputMaybe<Array<AssignmentResourceHasAssignmentsCreateFieldInput>>;
  usersHasSchoolAssignment?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentCreateFieldInput>>;
};

export type AssignmentResourceHasAssignmentsAggregateInput = {
  AND?: InputMaybe<Array<AssignmentResourceHasAssignmentsAggregateInput>>;
  NOT?: InputMaybe<AssignmentResourceHasAssignmentsAggregateInput>;
  OR?: InputMaybe<Array<AssignmentResourceHasAssignmentsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AssignmentResourceHasAssignmentsNodeAggregationWhereInput>;
};

export type AssignmentResourceHasAssignmentsConnectFieldInput = {
  connect?: InputMaybe<Array<ResourceConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ResourceConnectWhere>;
};

export type AssignmentResourceHasAssignmentsConnectOrCreateFieldInput = {
  onCreate: AssignmentResourceHasAssignmentsConnectOrCreateFieldInputOnCreate;
  where: ResourceConnectOrCreateWhere;
};

export type AssignmentResourceHasAssignmentsConnectOrCreateFieldInputOnCreate = {
  node: ResourceOnCreateInput;
};

export type AssignmentResourceHasAssignmentsConnection = {
  __typename?: "AssignmentResourceHasAssignmentsConnection";
  edges: Array<AssignmentResourceHasAssignmentsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssignmentResourceHasAssignmentsConnectionSort = {
  node?: InputMaybe<ResourceSort>;
};

export type AssignmentResourceHasAssignmentsConnectionWhere = {
  AND?: InputMaybe<Array<AssignmentResourceHasAssignmentsConnectionWhere>>;
  NOT?: InputMaybe<AssignmentResourceHasAssignmentsConnectionWhere>;
  OR?: InputMaybe<Array<AssignmentResourceHasAssignmentsConnectionWhere>>;
  node?: InputMaybe<ResourceWhere>;
};

export type AssignmentResourceHasAssignmentsCreateFieldInput = {
  node: ResourceCreateInput;
};

export type AssignmentResourceHasAssignmentsDeleteFieldInput = {
  delete?: InputMaybe<ResourceDeleteInput>;
  where?: InputMaybe<AssignmentResourceHasAssignmentsConnectionWhere>;
};

export type AssignmentResourceHasAssignmentsDisconnectFieldInput = {
  disconnect?: InputMaybe<ResourceDisconnectInput>;
  where?: InputMaybe<AssignmentResourceHasAssignmentsConnectionWhere>;
};

export type AssignmentResourceHasAssignmentsFieldInput = {
  connect?: InputMaybe<Array<AssignmentResourceHasAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AssignmentResourceHasAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AssignmentResourceHasAssignmentsCreateFieldInput>>;
};

export type AssignmentResourceHasAssignmentsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AssignmentResourceHasAssignmentsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AssignmentResourceHasAssignmentsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AssignmentResourceHasAssignmentsNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type AssignmentResourceHasAssignmentsRelationship = {
  __typename?: "AssignmentResourceHasAssignmentsRelationship";
  cursor: Scalars["String"]["output"];
  node: Resource;
};

export type AssignmentResourceHasAssignmentsUpdateConnectionInput = {
  node?: InputMaybe<ResourceUpdateInput>;
};

export type AssignmentResourceHasAssignmentsUpdateFieldInput = {
  connect?: InputMaybe<Array<AssignmentResourceHasAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<AssignmentResourceHasAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<AssignmentResourceHasAssignmentsCreateFieldInput>>;
  delete?: InputMaybe<Array<AssignmentResourceHasAssignmentsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AssignmentResourceHasAssignmentsDisconnectFieldInput>>;
  update?: InputMaybe<AssignmentResourceHasAssignmentsUpdateConnectionInput>;
  where?: InputMaybe<AssignmentResourceHasAssignmentsConnectionWhere>;
};

export type AssignmentResourceResourceHasAssignmentsAggregationSelection = {
  __typename?: "AssignmentResourceResourceHasAssignmentsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AssignmentResourceResourceHasAssignmentsNodeAggregateSelection>;
};

export type AssignmentResourceResourceHasAssignmentsNodeAggregateSelection = {
  __typename?: "AssignmentResourceResourceHasAssignmentsNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
};

export type AssignmentResponse = {
  __typename?: "AssignmentResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<Assignment>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AssignmentResponseAggregateSelection = {
  __typename?: "AssignmentResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type AssignmentResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssignmentResponseEdge = {
  __typename?: "AssignmentResponseEdge";
  cursor: Scalars["String"]["output"];
  node: AssignmentResponse;
};

export type AssignmentResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AssignmentResponseSort objects to sort AssignmentResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AssignmentResponseSort>>;
};

/** Fields to sort AssignmentResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AssignmentResponseSort object. */
export type AssignmentResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type AssignmentResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssignmentResponseWhere = {
  AND?: InputMaybe<Array<AssignmentResponseWhere>>;
  NOT?: InputMaybe<AssignmentResponseWhere>;
  OR?: InputMaybe<Array<AssignmentResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AssignmentResponsesConnection = {
  __typename?: "AssignmentResponsesConnection";
  edges: Array<AssignmentResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort Assignments by. The order in which sorts are applied is not guaranteed when specifying many fields in one AssignmentSort object. */
export type AssignmentSort = {
  _empty?: InputMaybe<SortDirection>;
  allowed_attempts?: InputMaybe<SortDirection>;
  anonymize_students?: InputMaybe<SortDirection>;
  anonymous_grading?: InputMaybe<SortDirection>;
  anonymous_instructor_annotations?: InputMaybe<SortDirection>;
  anonymous_peer_reviews?: InputMaybe<SortDirection>;
  assignee_mode?: InputMaybe<SortDirection>;
  assignment_group_id?: InputMaybe<SortDirection>;
  assignment_name?: InputMaybe<SortDirection>;
  automatic_peer_reviews?: InputMaybe<SortDirection>;
  body?: InputMaybe<SortDirection>;
  can_duplicate?: InputMaybe<SortDirection>;
  category_id?: InputMaybe<SortDirection>;
  course_id?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  created_at?: InputMaybe<SortDirection>;
  created_date?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  description_plaintext?: InputMaybe<SortDirection>;
  discussion_topic_allow_rating?: InputMaybe<SortDirection>;
  discussion_topic_assignment_id?: InputMaybe<SortDirection>;
  discussion_topic_author_anonymous_id?: InputMaybe<SortDirection>;
  discussion_topic_author_avatar_image_url?: InputMaybe<SortDirection>;
  discussion_topic_author_display_name?: InputMaybe<SortDirection>;
  discussion_topic_author_html_url?: InputMaybe<SortDirection>;
  discussion_topic_author_id?: InputMaybe<SortDirection>;
  discussion_topic_can_group?: InputMaybe<SortDirection>;
  discussion_topic_can_lock?: InputMaybe<SortDirection>;
  discussion_topic_can_unpublish?: InputMaybe<SortDirection>;
  discussion_topic_comments_disabled?: InputMaybe<SortDirection>;
  discussion_topic_created_at?: InputMaybe<SortDirection>;
  discussion_topic_discussion_subentry_count?: InputMaybe<SortDirection>;
  discussion_topic_discussion_type?: InputMaybe<SortDirection>;
  discussion_topic_html_url?: InputMaybe<SortDirection>;
  discussion_topic_id?: InputMaybe<SortDirection>;
  discussion_topic_is_announcement?: InputMaybe<SortDirection>;
  discussion_topic_is_section_specific?: InputMaybe<SortDirection>;
  discussion_topic_last_reply_at?: InputMaybe<SortDirection>;
  discussion_topic_locked?: InputMaybe<SortDirection>;
  discussion_topic_locked_for_user?: InputMaybe<SortDirection>;
  discussion_topic_message?: InputMaybe<SortDirection>;
  discussion_topic_only_graders_can_rate?: InputMaybe<SortDirection>;
  discussion_topic_permissions_attach?: InputMaybe<SortDirection>;
  discussion_topic_permissions_delete?: InputMaybe<SortDirection>;
  discussion_topic_permissions_manage_assign_to?: InputMaybe<SortDirection>;
  discussion_topic_permissions_reply?: InputMaybe<SortDirection>;
  discussion_topic_permissions_update?: InputMaybe<SortDirection>;
  discussion_topic_pinned?: InputMaybe<SortDirection>;
  discussion_topic_podcast_has_student_posts?: InputMaybe<SortDirection>;
  discussion_topic_posted_at?: InputMaybe<SortDirection>;
  discussion_topic_published?: InputMaybe<SortDirection>;
  discussion_topic_read_state?: InputMaybe<SortDirection>;
  discussion_topic_sort_by_rating?: InputMaybe<SortDirection>;
  discussion_topic_subscribed?: InputMaybe<SortDirection>;
  discussion_topic_summary_enabled?: InputMaybe<SortDirection>;
  discussion_topic_title?: InputMaybe<SortDirection>;
  discussion_topic_unread_count?: InputMaybe<SortDirection>;
  discussion_topic_url?: InputMaybe<SortDirection>;
  discussion_topic_user_can_see_posts?: InputMaybe<SortDirection>;
  discussion_topic_user_name?: InputMaybe<SortDirection>;
  display_date?: InputMaybe<SortDirection>;
  due_at?: InputMaybe<SortDirection>;
  due_date?: InputMaybe<SortDirection>;
  due_date_required?: InputMaybe<SortDirection>;
  end_date?: InputMaybe<SortDirection>;
  external_tool_tag_attributes_content_id?: InputMaybe<SortDirection>;
  external_tool_tag_attributes_content_type?: InputMaybe<SortDirection>;
  external_tool_tag_attributes_external_data?: InputMaybe<SortDirection>;
  external_tool_tag_attributes_new_tab?: InputMaybe<SortDirection>;
  external_tool_tag_attributes_resource_link_id?: InputMaybe<SortDirection>;
  external_tool_tag_attributes_url?: InputMaybe<SortDirection>;
  grade_group_students_individually?: InputMaybe<SortDirection>;
  graded_submissions_exist?: InputMaybe<SortDirection>;
  grader_comments_visible_to_graders?: InputMaybe<SortDirection>;
  grader_count?: InputMaybe<SortDirection>;
  grader_names_visible_to_final_grader?: InputMaybe<SortDirection>;
  graders_anonymous_to_graders?: InputMaybe<SortDirection>;
  grading_type?: InputMaybe<SortDirection>;
  has_overrides?: InputMaybe<SortDirection>;
  has_submitted_submissions?: InputMaybe<SortDirection>;
  hide_in_gradebook?: InputMaybe<SortDirection>;
  html?: InputMaybe<SortDirection>;
  html_url?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  important_dates?: InputMaybe<SortDirection>;
  in_closed_grading_period?: InputMaybe<SortDirection>;
  intra_group_peer_reviews?: InputMaybe<SortDirection>;
  is_quiz_assignment?: InputMaybe<SortDirection>;
  is_quiz_lti_assignment?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
  locked_for_user?: InputMaybe<SortDirection>;
  lti_context_id?: InputMaybe<SortDirection>;
  max_attempts?: InputMaybe<SortDirection>;
  max_name_length?: InputMaybe<SortDirection>;
  moderated_grading?: InputMaybe<SortDirection>;
  muted?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  needs_grading_count?: InputMaybe<SortDirection>;
  objectives?: InputMaybe<SortDirection>;
  omit_from_final_grade?: InputMaybe<SortDirection>;
  only_visible_to_overrides?: InputMaybe<SortDirection>;
  peer_reviews?: InputMaybe<SortDirection>;
  points_possible?: InputMaybe<SortDirection>;
  position?: InputMaybe<SortDirection>;
  post_manually?: InputMaybe<SortDirection>;
  post_to_sis?: InputMaybe<SortDirection>;
  published?: InputMaybe<SortDirection>;
  require_lockdown_browser?: InputMaybe<SortDirection>;
  resource_metadata?: InputMaybe<SortDirection>;
  restrict_quantitative_data?: InputMaybe<SortDirection>;
  secure_params?: InputMaybe<SortDirection>;
  session_id?: InputMaybe<SortDirection>;
  start_date?: InputMaybe<SortDirection>;
  state?: InputMaybe<SortDirection>;
  submissions_download_url?: InputMaybe<SortDirection>;
  text?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  unpublishable?: InputMaybe<SortDirection>;
  updated_at?: InputMaybe<SortDirection>;
  updated_date?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
  visible_to_everyone?: InputMaybe<SortDirection>;
  workflow_state?: InputMaybe<SortDirection>;
};

export type AssignmentUniqueWhere = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type AssignmentUpdateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  allowed_attempts?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  anonymize_students?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_grading?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_instructor_annotations?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  assignee_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignee_ids_POP?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_ids_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignee_mode?: InputMaybe<Scalars["String"]["input"]>;
  assignment_group_id?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name?: InputMaybe<Scalars["String"]["input"]>;
  automatic_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  can_duplicate?: InputMaybe<Scalars["Boolean"]["input"]>;
  category_id?: InputMaybe<Scalars["String"]["input"]>;
  courseCategoriesHasAssignment?: InputMaybe<Array<AssignmentCourseCategoriesHasAssignmentUpdateFieldInput>>;
  course_id?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  coursesHasAssignment?: InputMaybe<Array<AssignmentCoursesHasAssignmentUpdateFieldInput>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_plaintext?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_allow_rating?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_assignment_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_attachments?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_attachments_POP?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_attachments_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_author_anonymous_id?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_avatar_image_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_display_name?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_html_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_can_group?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_can_lock?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_can_unpublish?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_comments_disabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_created_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_discussion_subentry_count?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_group_topic_children?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_group_topic_children_POP?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_group_topic_children_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_html_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_is_announcement?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_is_section_specific?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_last_reply_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_message?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_only_graders_can_rate?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_attach?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_manage_assign_to?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_reply?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_update?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_pinned?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_podcast_has_student_posts?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_posted_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_published?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_read_state?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_sort_by_rating?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_subscribed?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_summary_enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_title?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_topic_children?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_topic_children_POP?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_topic_children_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_unread_count?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_user_can_see_posts?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_user_name?: InputMaybe<Scalars["String"]["input"]>;
  display_date?: InputMaybe<Scalars["String"]["input"]>;
  due_at?: InputMaybe<Scalars["String"]["input"]>;
  due_date?: InputMaybe<Scalars["String"]["input"]>;
  due_date_required?: InputMaybe<Scalars["Boolean"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  embedding_POP?: InputMaybe<Scalars["Int"]["input"]>;
  embedding_PUSH?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  end_date?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_content_id?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_external_data?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_new_tab?: InputMaybe<Scalars["Boolean"]["input"]>;
  external_tool_tag_attributes_resource_link_id?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_url?: InputMaybe<Scalars["String"]["input"]>;
  frozen_attributes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  frozen_attributes_POP?: InputMaybe<Scalars["Int"]["input"]>;
  frozen_attributes_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  grade_group_students_individually?: InputMaybe<Scalars["Boolean"]["input"]>;
  graded_submissions_exist?: InputMaybe<Scalars["Boolean"]["input"]>;
  grader_comments_visible_to_graders?: InputMaybe<Scalars["Boolean"]["input"]>;
  grader_count?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_names_visible_to_final_grader?: InputMaybe<Scalars["Boolean"]["input"]>;
  graders_anonymous_to_graders?: InputMaybe<Scalars["Boolean"]["input"]>;
  grading_type?: InputMaybe<Scalars["String"]["input"]>;
  hasChatChats?: InputMaybe<Array<AssignmentHasChatChatsUpdateFieldInput>>;
  hasObjectiveObjective?: InputMaybe<Array<AssignmentHasObjectiveObjectiveUpdateFieldInput>>;
  has_overrides?: InputMaybe<Scalars["Boolean"]["input"]>;
  has_submitted_submissions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hide_in_gradebook?: InputMaybe<Scalars["Boolean"]["input"]>;
  html?: InputMaybe<Scalars["String"]["input"]>;
  html_url?: InputMaybe<Scalars["String"]["input"]>;
  important_dates?: InputMaybe<Scalars["Boolean"]["input"]>;
  in_closed_grading_period?: InputMaybe<Scalars["Boolean"]["input"]>;
  intra_group_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_quiz_assignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_quiz_lti_assignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  lti_context_id?: InputMaybe<Scalars["String"]["input"]>;
  max_attempts?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  moderated_grading?: InputMaybe<Scalars["Boolean"]["input"]>;
  muted?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  needs_grading_count?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives?: InputMaybe<Scalars["String"]["input"]>;
  omit_from_final_grade?: InputMaybe<Scalars["Boolean"]["input"]>;
  only_visible_to_overrides?: InputMaybe<Scalars["Boolean"]["input"]>;
  peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  points_possible?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  position?: InputMaybe<Scalars["Float"]["input"]>;
  position_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  position_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  post_manually?: InputMaybe<Scalars["Boolean"]["input"]>;
  post_to_sis?: InputMaybe<Scalars["Boolean"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  require_lockdown_browser?: InputMaybe<Scalars["Boolean"]["input"]>;
  resourceHasAssignments?: InputMaybe<Array<AssignmentResourceHasAssignmentsUpdateFieldInput>>;
  resource_metadata?: InputMaybe<Scalars["String"]["input"]>;
  restrict_quantitative_data?: InputMaybe<Scalars["Boolean"]["input"]>;
  section_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  section_ids_POP?: InputMaybe<Scalars["Int"]["input"]>;
  section_ids_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  secure_params?: InputMaybe<Scalars["String"]["input"]>;
  session_id?: InputMaybe<Scalars["String"]["input"]>;
  start_date?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  submissions_download_url?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  unpublishable?: InputMaybe<Scalars["Boolean"]["input"]>;
  updated_at?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSchoolAssignment?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentUpdateFieldInput>>;
  visible_to_everyone?: InputMaybe<Scalars["Boolean"]["input"]>;
  workflow_state?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssignmentUserUsersHasSchoolAssignmentAggregationSelection = {
  __typename?: "AssignmentUserUsersHasSchoolAssignmentAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AssignmentUserUsersHasSchoolAssignmentNodeAggregateSelection>;
};

export type AssignmentUserUsersHasSchoolAssignmentNodeAggregateSelection = {
  __typename?: "AssignmentUserUsersHasSchoolAssignmentNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type AssignmentUsersHasSchoolAssignmentAggregateInput = {
  AND?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentAggregateInput>>;
  NOT?: InputMaybe<AssignmentUsersHasSchoolAssignmentAggregateInput>;
  OR?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AssignmentUsersHasSchoolAssignmentNodeAggregationWhereInput>;
};

export type AssignmentUsersHasSchoolAssignmentConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type AssignmentUsersHasSchoolAssignmentConnection = {
  __typename?: "AssignmentUsersHasSchoolAssignmentConnection";
  edges: Array<AssignmentUsersHasSchoolAssignmentRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AssignmentUsersHasSchoolAssignmentConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type AssignmentUsersHasSchoolAssignmentConnectionWhere = {
  AND?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentConnectionWhere>>;
  NOT?: InputMaybe<AssignmentUsersHasSchoolAssignmentConnectionWhere>;
  OR?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type AssignmentUsersHasSchoolAssignmentCreateFieldInput = {
  node: UserCreateInput;
};

export type AssignmentUsersHasSchoolAssignmentDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<AssignmentUsersHasSchoolAssignmentConnectionWhere>;
};

export type AssignmentUsersHasSchoolAssignmentDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<AssignmentUsersHasSchoolAssignmentConnectionWhere>;
};

export type AssignmentUsersHasSchoolAssignmentFieldInput = {
  connect?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentConnectFieldInput>>;
  create?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentCreateFieldInput>>;
};

export type AssignmentUsersHasSchoolAssignmentNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AssignmentUsersHasSchoolAssignmentNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AssignmentUsersHasSchoolAssignmentRelationship = {
  __typename?: "AssignmentUsersHasSchoolAssignmentRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type AssignmentUsersHasSchoolAssignmentUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type AssignmentUsersHasSchoolAssignmentUpdateFieldInput = {
  connect?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentConnectFieldInput>>;
  create?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentCreateFieldInput>>;
  delete?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AssignmentUsersHasSchoolAssignmentDisconnectFieldInput>>;
  update?: InputMaybe<AssignmentUsersHasSchoolAssignmentUpdateConnectionInput>;
  where?: InputMaybe<AssignmentUsersHasSchoolAssignmentConnectionWhere>;
};

export type AssignmentWhere = {
  AND?: InputMaybe<Array<AssignmentWhere>>;
  NOT?: InputMaybe<AssignmentWhere>;
  OR?: InputMaybe<Array<AssignmentWhere>>;
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  _empty_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  _empty_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  _empty_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  _empty_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  allowed_attempts?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  allowed_attempts_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  anonymize_students?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_grading?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_instructor_annotations?: InputMaybe<Scalars["Boolean"]["input"]>;
  anonymous_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  assignee_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignee_ids_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignee_mode_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  assignment_group_id?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  assignment_group_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name?: InputMaybe<Scalars["String"]["input"]>;
  assignment_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  assignment_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  assignment_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignment_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  automatic_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  body_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  body_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  body_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  body_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  can_duplicate?: InputMaybe<Scalars["Boolean"]["input"]>;
  category_id?: InputMaybe<Scalars["String"]["input"]>;
  category_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  category_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  category_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  category_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  courseCategoriesHasAssignmentAggregate?: InputMaybe<AssignmentCourseCategoriesHasAssignmentAggregateInput>;
  /** Return Assignments where all of the related AssignmentCourseCategoriesHasAssignmentConnections match this filter */
  courseCategoriesHasAssignmentConnection_ALL?: InputMaybe<AssignmentCourseCategoriesHasAssignmentConnectionWhere>;
  /** Return Assignments where none of the related AssignmentCourseCategoriesHasAssignmentConnections match this filter */
  courseCategoriesHasAssignmentConnection_NONE?: InputMaybe<AssignmentCourseCategoriesHasAssignmentConnectionWhere>;
  /** Return Assignments where one of the related AssignmentCourseCategoriesHasAssignmentConnections match this filter */
  courseCategoriesHasAssignmentConnection_SINGLE?: InputMaybe<AssignmentCourseCategoriesHasAssignmentConnectionWhere>;
  /** Return Assignments where some of the related AssignmentCourseCategoriesHasAssignmentConnections match this filter */
  courseCategoriesHasAssignmentConnection_SOME?: InputMaybe<AssignmentCourseCategoriesHasAssignmentConnectionWhere>;
  /** Return Assignments where all of the related CourseCategories match this filter */
  courseCategoriesHasAssignment_ALL?: InputMaybe<CourseCategoryWhere>;
  /** Return Assignments where none of the related CourseCategories match this filter */
  courseCategoriesHasAssignment_NONE?: InputMaybe<CourseCategoryWhere>;
  /** Return Assignments where one of the related CourseCategories match this filter */
  courseCategoriesHasAssignment_SINGLE?: InputMaybe<CourseCategoryWhere>;
  /** Return Assignments where some of the related CourseCategories match this filter */
  courseCategoriesHasAssignment_SOME?: InputMaybe<CourseCategoryWhere>;
  course_id?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  course_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  coursesHasAssignmentAggregate?: InputMaybe<AssignmentCoursesHasAssignmentAggregateInput>;
  /** Return Assignments where all of the related AssignmentCoursesHasAssignmentConnections match this filter */
  coursesHasAssignmentConnection_ALL?: InputMaybe<AssignmentCoursesHasAssignmentConnectionWhere>;
  /** Return Assignments where none of the related AssignmentCoursesHasAssignmentConnections match this filter */
  coursesHasAssignmentConnection_NONE?: InputMaybe<AssignmentCoursesHasAssignmentConnectionWhere>;
  /** Return Assignments where one of the related AssignmentCoursesHasAssignmentConnections match this filter */
  coursesHasAssignmentConnection_SINGLE?: InputMaybe<AssignmentCoursesHasAssignmentConnectionWhere>;
  /** Return Assignments where some of the related AssignmentCoursesHasAssignmentConnections match this filter */
  coursesHasAssignmentConnection_SOME?: InputMaybe<AssignmentCoursesHasAssignmentConnectionWhere>;
  /** Return Assignments where all of the related Courses match this filter */
  coursesHasAssignment_ALL?: InputMaybe<CourseWhere>;
  /** Return Assignments where none of the related Courses match this filter */
  coursesHasAssignment_NONE?: InputMaybe<CourseWhere>;
  /** Return Assignments where one of the related Courses match this filter */
  coursesHasAssignment_SINGLE?: InputMaybe<CourseWhere>;
  /** Return Assignments where some of the related Courses match this filter */
  coursesHasAssignment_SOME?: InputMaybe<CourseWhere>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  created_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  created_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  created_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  created_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  created_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_plaintext?: InputMaybe<Scalars["String"]["input"]>;
  description_plaintext_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_plaintext_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_plaintext_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_plaintext_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_allow_rating?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_assignment_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  discussion_topic_assignment_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_attachments?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_attachments_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_anonymous_id?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_anonymous_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_anonymous_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_anonymous_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_author_anonymous_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_avatar_image_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_avatar_image_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_avatar_image_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_avatar_image_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_author_avatar_image_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_display_name?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_display_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_display_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_display_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_author_display_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_html_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_html_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_html_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_html_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_author_html_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_author_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  discussion_topic_author_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_can_group?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_can_lock?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_can_unpublish?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_comments_disabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_created_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_created_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_created_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_created_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_created_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_discussion_subentry_count?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  discussion_topic_discussion_subentry_count_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_discussion_type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_discussion_type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_discussion_type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_discussion_type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_group_topic_children?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_group_topic_children_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_html_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_html_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_html_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_html_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_html_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_id?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  discussion_topic_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_is_announcement?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_is_section_specific?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_last_reply_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_last_reply_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_last_reply_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_last_reply_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_last_reply_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_locked?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_message?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_only_graders_can_rate?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_attach?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_delete?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_manage_assign_to?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_reply?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_permissions_update?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_pinned?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_podcast_has_student_posts?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_posted_at?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_posted_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_posted_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_posted_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_posted_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_published?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_read_state?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_read_state_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_read_state_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_read_state_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_read_state_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_sort_by_rating?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_subscribed?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_summary_enabled?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_title?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_title_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_topic_children?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_topic_children_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_unread_count?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  discussion_topic_unread_count_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_user_can_see_posts?: InputMaybe<Scalars["Boolean"]["input"]>;
  discussion_topic_user_name?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_user_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_user_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  discussion_topic_user_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  discussion_topic_user_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  display_date?: InputMaybe<Scalars["String"]["input"]>;
  display_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  display_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  display_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  display_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  due_at?: InputMaybe<Scalars["String"]["input"]>;
  due_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  due_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  due_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  due_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  due_date?: InputMaybe<Scalars["String"]["input"]>;
  due_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  due_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  due_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  due_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  due_date_required?: InputMaybe<Scalars["Boolean"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  embedding_INCLUDES?: InputMaybe<Scalars["Float"]["input"]>;
  end_date?: InputMaybe<Scalars["String"]["input"]>;
  end_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  end_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  end_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  end_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_content_id?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  external_tool_tag_attributes_content_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_content_type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_content_type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_content_type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  external_tool_tag_attributes_content_type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_external_data?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_external_data_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_external_data_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_external_data_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  external_tool_tag_attributes_external_data_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_new_tab?: InputMaybe<Scalars["Boolean"]["input"]>;
  external_tool_tag_attributes_resource_link_id?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_resource_link_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_resource_link_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_resource_link_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  external_tool_tag_attributes_resource_link_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_url?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  external_tool_tag_attributes_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  external_tool_tag_attributes_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  frozen_attributes?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  frozen_attributes_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  grade_group_students_individually?: InputMaybe<Scalars["Boolean"]["input"]>;
  graded_submissions_exist?: InputMaybe<Scalars["Boolean"]["input"]>;
  grader_comments_visible_to_graders?: InputMaybe<Scalars["Boolean"]["input"]>;
  grader_count?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  grader_count_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_names_visible_to_final_grader?: InputMaybe<Scalars["Boolean"]["input"]>;
  graders_anonymous_to_graders?: InputMaybe<Scalars["Boolean"]["input"]>;
  grading_type?: InputMaybe<Scalars["String"]["input"]>;
  grading_type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  grading_type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  grading_type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  grading_type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  hasChatChatsAggregate?: InputMaybe<AssignmentHasChatChatsAggregateInput>;
  /** Return Assignments where all of the related AssignmentHasChatChatsConnections match this filter */
  hasChatChatsConnection_ALL?: InputMaybe<AssignmentHasChatChatsConnectionWhere>;
  /** Return Assignments where none of the related AssignmentHasChatChatsConnections match this filter */
  hasChatChatsConnection_NONE?: InputMaybe<AssignmentHasChatChatsConnectionWhere>;
  /** Return Assignments where one of the related AssignmentHasChatChatsConnections match this filter */
  hasChatChatsConnection_SINGLE?: InputMaybe<AssignmentHasChatChatsConnectionWhere>;
  /** Return Assignments where some of the related AssignmentHasChatChatsConnections match this filter */
  hasChatChatsConnection_SOME?: InputMaybe<AssignmentHasChatChatsConnectionWhere>;
  /** Return Assignments where all of the related Chats match this filter */
  hasChatChats_ALL?: InputMaybe<ChatWhere>;
  /** Return Assignments where none of the related Chats match this filter */
  hasChatChats_NONE?: InputMaybe<ChatWhere>;
  /** Return Assignments where one of the related Chats match this filter */
  hasChatChats_SINGLE?: InputMaybe<ChatWhere>;
  /** Return Assignments where some of the related Chats match this filter */
  hasChatChats_SOME?: InputMaybe<ChatWhere>;
  hasObjectiveObjectiveAggregate?: InputMaybe<AssignmentHasObjectiveObjectiveAggregateInput>;
  /** Return Assignments where all of the related AssignmentHasObjectiveObjectiveConnections match this filter */
  hasObjectiveObjectiveConnection_ALL?: InputMaybe<AssignmentHasObjectiveObjectiveConnectionWhere>;
  /** Return Assignments where none of the related AssignmentHasObjectiveObjectiveConnections match this filter */
  hasObjectiveObjectiveConnection_NONE?: InputMaybe<AssignmentHasObjectiveObjectiveConnectionWhere>;
  /** Return Assignments where one of the related AssignmentHasObjectiveObjectiveConnections match this filter */
  hasObjectiveObjectiveConnection_SINGLE?: InputMaybe<AssignmentHasObjectiveObjectiveConnectionWhere>;
  /** Return Assignments where some of the related AssignmentHasObjectiveObjectiveConnections match this filter */
  hasObjectiveObjectiveConnection_SOME?: InputMaybe<AssignmentHasObjectiveObjectiveConnectionWhere>;
  /** Return Assignments where all of the related Objectives match this filter */
  hasObjectiveObjective_ALL?: InputMaybe<ObjectiveWhere>;
  /** Return Assignments where none of the related Objectives match this filter */
  hasObjectiveObjective_NONE?: InputMaybe<ObjectiveWhere>;
  /** Return Assignments where one of the related Objectives match this filter */
  hasObjectiveObjective_SINGLE?: InputMaybe<ObjectiveWhere>;
  /** Return Assignments where some of the related Objectives match this filter */
  hasObjectiveObjective_SOME?: InputMaybe<ObjectiveWhere>;
  has_overrides?: InputMaybe<Scalars["Boolean"]["input"]>;
  has_submitted_submissions?: InputMaybe<Scalars["Boolean"]["input"]>;
  hide_in_gradebook?: InputMaybe<Scalars["Boolean"]["input"]>;
  html?: InputMaybe<Scalars["String"]["input"]>;
  html_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  html_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  html_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  html_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  html_url?: InputMaybe<Scalars["String"]["input"]>;
  html_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  html_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  html_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  html_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  important_dates?: InputMaybe<Scalars["Boolean"]["input"]>;
  in_closed_grading_period?: InputMaybe<Scalars["Boolean"]["input"]>;
  intra_group_peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_quiz_assignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_quiz_lti_assignment?: InputMaybe<Scalars["Boolean"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastModified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  lti_context_id?: InputMaybe<Scalars["String"]["input"]>;
  lti_context_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  lti_context_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lti_context_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  lti_context_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  max_attempts?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  max_attempts_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  max_name_length_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moderated_grading?: InputMaybe<Scalars["Boolean"]["input"]>;
  muted?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  needs_grading_count?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  needs_grading_count_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives?: InputMaybe<Scalars["String"]["input"]>;
  objectives_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  objectives_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  objectives_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  objectives_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  omit_from_final_grade?: InputMaybe<Scalars["Boolean"]["input"]>;
  only_visible_to_overrides?: InputMaybe<Scalars["Boolean"]["input"]>;
  peer_reviews?: InputMaybe<Scalars["Boolean"]["input"]>;
  points_possible?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  points_possible_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position?: InputMaybe<Scalars["Float"]["input"]>;
  position_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  position_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  post_manually?: InputMaybe<Scalars["Boolean"]["input"]>;
  post_to_sis?: InputMaybe<Scalars["Boolean"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  require_lockdown_browser?: InputMaybe<Scalars["Boolean"]["input"]>;
  resourceHasAssignmentsAggregate?: InputMaybe<AssignmentResourceHasAssignmentsAggregateInput>;
  /** Return Assignments where all of the related AssignmentResourceHasAssignmentsConnections match this filter */
  resourceHasAssignmentsConnection_ALL?: InputMaybe<AssignmentResourceHasAssignmentsConnectionWhere>;
  /** Return Assignments where none of the related AssignmentResourceHasAssignmentsConnections match this filter */
  resourceHasAssignmentsConnection_NONE?: InputMaybe<AssignmentResourceHasAssignmentsConnectionWhere>;
  /** Return Assignments where one of the related AssignmentResourceHasAssignmentsConnections match this filter */
  resourceHasAssignmentsConnection_SINGLE?: InputMaybe<AssignmentResourceHasAssignmentsConnectionWhere>;
  /** Return Assignments where some of the related AssignmentResourceHasAssignmentsConnections match this filter */
  resourceHasAssignmentsConnection_SOME?: InputMaybe<AssignmentResourceHasAssignmentsConnectionWhere>;
  /** Return Assignments where all of the related Resources match this filter */
  resourceHasAssignments_ALL?: InputMaybe<ResourceWhere>;
  /** Return Assignments where none of the related Resources match this filter */
  resourceHasAssignments_NONE?: InputMaybe<ResourceWhere>;
  /** Return Assignments where one of the related Resources match this filter */
  resourceHasAssignments_SINGLE?: InputMaybe<ResourceWhere>;
  /** Return Assignments where some of the related Resources match this filter */
  resourceHasAssignments_SOME?: InputMaybe<ResourceWhere>;
  resource_metadata?: InputMaybe<Scalars["String"]["input"]>;
  resource_metadata_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  resource_metadata_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  resource_metadata_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  resource_metadata_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  restrict_quantitative_data?: InputMaybe<Scalars["Boolean"]["input"]>;
  section_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  section_ids_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  secure_params?: InputMaybe<Scalars["String"]["input"]>;
  secure_params_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  secure_params_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  secure_params_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  secure_params_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  session_id?: InputMaybe<Scalars["String"]["input"]>;
  session_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  session_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  session_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  session_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  start_date?: InputMaybe<Scalars["String"]["input"]>;
  start_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  start_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  start_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  start_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  state_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  state_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  state_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  state_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  submissions_download_url?: InputMaybe<Scalars["String"]["input"]>;
  submissions_download_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  submissions_download_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  submissions_download_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  submissions_download_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  text_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  text_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  text_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  unpublishable?: InputMaybe<Scalars["Boolean"]["input"]>;
  updated_at?: InputMaybe<Scalars["String"]["input"]>;
  updated_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  updated_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updated_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  updated_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  updated_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  updated_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updated_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  updated_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSchoolAssignmentAggregate?: InputMaybe<AssignmentUsersHasSchoolAssignmentAggregateInput>;
  /** Return Assignments where all of the related AssignmentUsersHasSchoolAssignmentConnections match this filter */
  usersHasSchoolAssignmentConnection_ALL?: InputMaybe<AssignmentUsersHasSchoolAssignmentConnectionWhere>;
  /** Return Assignments where none of the related AssignmentUsersHasSchoolAssignmentConnections match this filter */
  usersHasSchoolAssignmentConnection_NONE?: InputMaybe<AssignmentUsersHasSchoolAssignmentConnectionWhere>;
  /** Return Assignments where one of the related AssignmentUsersHasSchoolAssignmentConnections match this filter */
  usersHasSchoolAssignmentConnection_SINGLE?: InputMaybe<AssignmentUsersHasSchoolAssignmentConnectionWhere>;
  /** Return Assignments where some of the related AssignmentUsersHasSchoolAssignmentConnections match this filter */
  usersHasSchoolAssignmentConnection_SOME?: InputMaybe<AssignmentUsersHasSchoolAssignmentConnectionWhere>;
  /** Return Assignments where all of the related Users match this filter */
  usersHasSchoolAssignment_ALL?: InputMaybe<UserWhere>;
  /** Return Assignments where none of the related Users match this filter */
  usersHasSchoolAssignment_NONE?: InputMaybe<UserWhere>;
  /** Return Assignments where one of the related Users match this filter */
  usersHasSchoolAssignment_SINGLE?: InputMaybe<UserWhere>;
  /** Return Assignments where some of the related Users match this filter */
  usersHasSchoolAssignment_SOME?: InputMaybe<UserWhere>;
  visible_to_everyone?: InputMaybe<Scalars["Boolean"]["input"]>;
  workflow_state?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  workflow_state_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type AssignmentsConnection = {
  __typename?: "AssignmentsConnection";
  edges: Array<AssignmentEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Attribute = {
  __typename?: "Attribute";
  achievements: Array<Achievements>;
  achievementsAggregate?: Maybe<AttributeAchievementsAchievementsAggregationSelection>;
  achievementsConnection: AttributeAchievementsConnection;
  created: Scalars["DateTime"]["output"];
  embedding: Array<Maybe<Scalars["Float"]["output"]>>;
  id: Scalars["String"]["output"];
  text: Scalars["String"]["output"];
  users: Array<User>;
  usersAggregate?: Maybe<AttributeUserUsersAggregationSelection>;
  usersConnection: AttributeUsersConnection;
};

export type AttributeAchievementsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AchievementsOptions>;
  where?: InputMaybe<AchievementsWhere>;
};

export type AttributeAchievementsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AchievementsWhere>;
};

export type AttributeAchievementsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AttributeAchievementsConnectionSort>>;
  where?: InputMaybe<AttributeAchievementsConnectionWhere>;
};

export type AttributeUsersArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type AttributeUsersAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type AttributeUsersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<AttributeUsersConnectionSort>>;
  where?: InputMaybe<AttributeUsersConnectionWhere>;
};

export type AttributeAchievementsAchievementsAggregationSelection = {
  __typename?: "AttributeAchievementsAchievementsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AttributeAchievementsAchievementsNodeAggregateSelection>;
};

export type AttributeAchievementsAchievementsNodeAggregateSelection = {
  __typename?: "AttributeAchievementsAchievementsNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
};

export type AttributeAchievementsAggregateInput = {
  AND?: InputMaybe<Array<AttributeAchievementsAggregateInput>>;
  NOT?: InputMaybe<AttributeAchievementsAggregateInput>;
  OR?: InputMaybe<Array<AttributeAchievementsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AttributeAchievementsNodeAggregationWhereInput>;
};

export type AttributeAchievementsConnectFieldInput = {
  connect?: InputMaybe<Array<AchievementsConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AchievementsConnectWhere>;
};

export type AttributeAchievementsConnection = {
  __typename?: "AttributeAchievementsConnection";
  edges: Array<AttributeAchievementsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AttributeAchievementsConnectionSort = {
  node?: InputMaybe<AchievementsSort>;
};

export type AttributeAchievementsConnectionWhere = {
  AND?: InputMaybe<Array<AttributeAchievementsConnectionWhere>>;
  NOT?: InputMaybe<AttributeAchievementsConnectionWhere>;
  OR?: InputMaybe<Array<AttributeAchievementsConnectionWhere>>;
  node?: InputMaybe<AchievementsWhere>;
};

export type AttributeAchievementsCreateFieldInput = {
  node: AchievementsCreateInput;
};

export type AttributeAchievementsDeleteFieldInput = {
  delete?: InputMaybe<AchievementsDeleteInput>;
  where?: InputMaybe<AttributeAchievementsConnectionWhere>;
};

export type AttributeAchievementsDisconnectFieldInput = {
  disconnect?: InputMaybe<AchievementsDisconnectInput>;
  where?: InputMaybe<AttributeAchievementsConnectionWhere>;
};

export type AttributeAchievementsFieldInput = {
  connect?: InputMaybe<Array<AttributeAchievementsConnectFieldInput>>;
  create?: InputMaybe<Array<AttributeAchievementsCreateFieldInput>>;
};

export type AttributeAchievementsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AttributeAchievementsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AttributeAchievementsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AttributeAchievementsNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AttributeAchievementsRelationship = {
  __typename?: "AttributeAchievementsRelationship";
  cursor: Scalars["String"]["output"];
  node: Achievements;
};

export type AttributeAchievementsUpdateConnectionInput = {
  node?: InputMaybe<AchievementsUpdateInput>;
};

export type AttributeAchievementsUpdateFieldInput = {
  connect?: InputMaybe<Array<AttributeAchievementsConnectFieldInput>>;
  create?: InputMaybe<Array<AttributeAchievementsCreateFieldInput>>;
  delete?: InputMaybe<Array<AttributeAchievementsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AttributeAchievementsDisconnectFieldInput>>;
  update?: InputMaybe<AttributeAchievementsUpdateConnectionInput>;
  where?: InputMaybe<AttributeAchievementsConnectionWhere>;
};

export type AttributeAggregateSelection = {
  __typename?: "AttributeAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  text: StringAggregateSelection;
};

export type AttributeConnectInput = {
  achievements?: InputMaybe<Array<AttributeAchievementsConnectFieldInput>>;
  users?: InputMaybe<Array<AttributeUsersConnectFieldInput>>;
};

export type AttributeConnectWhere = {
  node: AttributeWhere;
};

export type AttributeCreateInput = {
  achievements?: InputMaybe<AttributeAchievementsFieldInput>;
  created: Scalars["DateTime"]["input"];
  embedding: Array<InputMaybe<Scalars["Float"]["input"]>>;
  id: Scalars["String"]["input"];
  text: Scalars["String"]["input"];
  users?: InputMaybe<AttributeUsersFieldInput>;
};

export type AttributeDeleteInput = {
  achievements?: InputMaybe<Array<AttributeAchievementsDeleteFieldInput>>;
  users?: InputMaybe<Array<AttributeUsersDeleteFieldInput>>;
};

export type AttributeDisconnectInput = {
  achievements?: InputMaybe<Array<AttributeAchievementsDisconnectFieldInput>>;
  users?: InputMaybe<Array<AttributeUsersDisconnectFieldInput>>;
};

export type AttributeEdge = {
  __typename?: "AttributeEdge";
  cursor: Scalars["String"]["output"];
  node: Attribute;
};

export type AttributeOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AttributeSort objects to sort Attributes by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AttributeSort>>;
};

export type AttributeRelationInput = {
  achievements?: InputMaybe<Array<AttributeAchievementsCreateFieldInput>>;
  users?: InputMaybe<Array<AttributeUsersCreateFieldInput>>;
};

/** Fields to sort Attributes by. The order in which sorts are applied is not guaranteed when specifying many fields in one AttributeSort object. */
export type AttributeSort = {
  created?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  text?: InputMaybe<SortDirection>;
};

export type AttributeUpdateInput = {
  achievements?: InputMaybe<Array<AttributeAchievementsUpdateFieldInput>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  embedding_POP?: InputMaybe<Scalars["Int"]["input"]>;
  embedding_PUSH?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  users?: InputMaybe<Array<AttributeUsersUpdateFieldInput>>;
};

export type AttributeUserUsersAggregationSelection = {
  __typename?: "AttributeUserUsersAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<AttributeUserUsersNodeAggregateSelection>;
};

export type AttributeUserUsersNodeAggregateSelection = {
  __typename?: "AttributeUserUsersNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type AttributeUsersAggregateInput = {
  AND?: InputMaybe<Array<AttributeUsersAggregateInput>>;
  NOT?: InputMaybe<AttributeUsersAggregateInput>;
  OR?: InputMaybe<Array<AttributeUsersAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<AttributeUsersNodeAggregationWhereInput>;
};

export type AttributeUsersConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type AttributeUsersConnection = {
  __typename?: "AttributeUsersConnection";
  edges: Array<AttributeUsersRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AttributeUsersConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type AttributeUsersConnectionWhere = {
  AND?: InputMaybe<Array<AttributeUsersConnectionWhere>>;
  NOT?: InputMaybe<AttributeUsersConnectionWhere>;
  OR?: InputMaybe<Array<AttributeUsersConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type AttributeUsersCreateFieldInput = {
  node: UserCreateInput;
};

export type AttributeUsersDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<AttributeUsersConnectionWhere>;
};

export type AttributeUsersDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<AttributeUsersConnectionWhere>;
};

export type AttributeUsersFieldInput = {
  connect?: InputMaybe<Array<AttributeUsersConnectFieldInput>>;
  create?: InputMaybe<Array<AttributeUsersCreateFieldInput>>;
};

export type AttributeUsersNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<AttributeUsersNodeAggregationWhereInput>>;
  NOT?: InputMaybe<AttributeUsersNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<AttributeUsersNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type AttributeUsersRelationship = {
  __typename?: "AttributeUsersRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type AttributeUsersUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type AttributeUsersUpdateFieldInput = {
  connect?: InputMaybe<Array<AttributeUsersConnectFieldInput>>;
  create?: InputMaybe<Array<AttributeUsersCreateFieldInput>>;
  delete?: InputMaybe<Array<AttributeUsersDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<AttributeUsersDisconnectFieldInput>>;
  update?: InputMaybe<AttributeUsersUpdateConnectionInput>;
  where?: InputMaybe<AttributeUsersConnectionWhere>;
};

export type AttributeWhere = {
  AND?: InputMaybe<Array<AttributeWhere>>;
  NOT?: InputMaybe<AttributeWhere>;
  OR?: InputMaybe<Array<AttributeWhere>>;
  achievementsAggregate?: InputMaybe<AttributeAchievementsAggregateInput>;
  /** Return Attributes where all of the related AttributeAchievementsConnections match this filter */
  achievementsConnection_ALL?: InputMaybe<AttributeAchievementsConnectionWhere>;
  /** Return Attributes where none of the related AttributeAchievementsConnections match this filter */
  achievementsConnection_NONE?: InputMaybe<AttributeAchievementsConnectionWhere>;
  /** Return Attributes where one of the related AttributeAchievementsConnections match this filter */
  achievementsConnection_SINGLE?: InputMaybe<AttributeAchievementsConnectionWhere>;
  /** Return Attributes where some of the related AttributeAchievementsConnections match this filter */
  achievementsConnection_SOME?: InputMaybe<AttributeAchievementsConnectionWhere>;
  /** Return Attributes where all of the related Achievements match this filter */
  achievements_ALL?: InputMaybe<AchievementsWhere>;
  /** Return Attributes where none of the related Achievements match this filter */
  achievements_NONE?: InputMaybe<AchievementsWhere>;
  /** Return Attributes where one of the related Achievements match this filter */
  achievements_SINGLE?: InputMaybe<AchievementsWhere>;
  /** Return Attributes where some of the related Achievements match this filter */
  achievements_SOME?: InputMaybe<AchievementsWhere>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  embedding_INCLUDES?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  text_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  text_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  text_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersAggregate?: InputMaybe<AttributeUsersAggregateInput>;
  /** Return Attributes where all of the related AttributeUsersConnections match this filter */
  usersConnection_ALL?: InputMaybe<AttributeUsersConnectionWhere>;
  /** Return Attributes where none of the related AttributeUsersConnections match this filter */
  usersConnection_NONE?: InputMaybe<AttributeUsersConnectionWhere>;
  /** Return Attributes where one of the related AttributeUsersConnections match this filter */
  usersConnection_SINGLE?: InputMaybe<AttributeUsersConnectionWhere>;
  /** Return Attributes where some of the related AttributeUsersConnections match this filter */
  usersConnection_SOME?: InputMaybe<AttributeUsersConnectionWhere>;
  /** Return Attributes where all of the related Users match this filter */
  users_ALL?: InputMaybe<UserWhere>;
  /** Return Attributes where none of the related Users match this filter */
  users_NONE?: InputMaybe<UserWhere>;
  /** Return Attributes where one of the related Users match this filter */
  users_SINGLE?: InputMaybe<UserWhere>;
  /** Return Attributes where some of the related Users match this filter */
  users_SOME?: InputMaybe<UserWhere>;
};

export type AttributesConnection = {
  __typename?: "AttributesConnection";
  edges: Array<AttributeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AudioToggleUserResponse = {
  __typename?: "AudioToggleUserResponse";
  audioOn?: Maybe<Scalars["Boolean"]["output"]>;
};

export type AudioToggleUserResponseAggregateSelection = {
  __typename?: "AudioToggleUserResponseAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type AudioToggleUserResponseCreateInput = {
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AudioToggleUserResponseEdge = {
  __typename?: "AudioToggleUserResponseEdge";
  cursor: Scalars["String"]["output"];
  node: AudioToggleUserResponse;
};

export type AudioToggleUserResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AudioToggleUserResponseSort objects to sort AudioToggleUserResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AudioToggleUserResponseSort>>;
};

/** Fields to sort AudioToggleUserResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AudioToggleUserResponseSort object. */
export type AudioToggleUserResponseSort = {
  audioOn?: InputMaybe<SortDirection>;
};

export type AudioToggleUserResponseUpdateInput = {
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AudioToggleUserResponseWhere = {
  AND?: InputMaybe<Array<AudioToggleUserResponseWhere>>;
  NOT?: InputMaybe<AudioToggleUserResponseWhere>;
  OR?: InputMaybe<Array<AudioToggleUserResponseWhere>>;
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type AudioToggleUserResponsesConnection = {
  __typename?: "AudioToggleUserResponsesConnection";
  edges: Array<AudioToggleUserResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AuthPayloadResponse = {
  __typename?: "AuthPayloadResponse";
  token: Scalars["String"]["output"];
  user?: Maybe<AuthPayloadUser>;
};

export type AuthPayloadResponseAggregateSelection = {
  __typename?: "AuthPayloadResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  token: StringAggregateSelection;
};

export type AuthPayloadResponseCreateInput = {
  token: Scalars["String"]["input"];
};

export type AuthPayloadResponseEdge = {
  __typename?: "AuthPayloadResponseEdge";
  cursor: Scalars["String"]["output"];
  node: AuthPayloadResponse;
};

export type AuthPayloadResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AuthPayloadResponseSort objects to sort AuthPayloadResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AuthPayloadResponseSort>>;
};

/** Fields to sort AuthPayloadResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one AuthPayloadResponseSort object. */
export type AuthPayloadResponseSort = {
  token?: InputMaybe<SortDirection>;
};

export type AuthPayloadResponseUpdateInput = {
  token?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuthPayloadResponseWhere = {
  AND?: InputMaybe<Array<AuthPayloadResponseWhere>>;
  NOT?: InputMaybe<AuthPayloadResponseWhere>;
  OR?: InputMaybe<Array<AuthPayloadResponseWhere>>;
  token?: InputMaybe<Scalars["String"]["input"]>;
  token_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  token_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  token_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  token_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuthPayloadResponsesConnection = {
  __typename?: "AuthPayloadResponsesConnection";
  edges: Array<AuthPayloadResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AuthPayloadUser = {
  __typename?: "AuthPayloadUser";
  birthday?: Maybe<Scalars["String"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  dailyEmailOptIn?: Maybe<Scalars["Boolean"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  fullPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Scalars["String"]["output"]>;
  googleAuthTokens?: Maybe<GoogleAuthTokens>;
  id?: Maybe<Scalars["String"]["output"]>;
  isEmailVerified?: Maybe<Scalars["Boolean"]["output"]>;
  isOnboarded?: Maybe<Scalars["Boolean"]["output"]>;
  isPro?: Maybe<Scalars["Boolean"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  otpPreference?: Maybe<Scalars["String"]["output"]>;
  password?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  preferences?: Maybe<AuthPayloadUserPreferences>;
};

export type AuthPayloadUserAggregateSelection = {
  __typename?: "AuthPayloadUserAggregateSelection";
  birthday: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  countryCode: StringAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  id: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
};

export type AuthPayloadUserCreateInput = {
  birthday?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  dailyEmailOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isEmailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuthPayloadUserEdge = {
  __typename?: "AuthPayloadUserEdge";
  cursor: Scalars["String"]["output"];
  node: AuthPayloadUser;
};

export type AuthPayloadUserOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AuthPayloadUserSort objects to sort AuthPayloadUsers by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AuthPayloadUserSort>>;
};

export type AuthPayloadUserPreferences = {
  __typename?: "AuthPayloadUserPreferences";
  language?: Maybe<Scalars["String"]["output"]>;
};

export type AuthPayloadUserPreferencesAggregateSelection = {
  __typename?: "AuthPayloadUserPreferencesAggregateSelection";
  count: Scalars["Int"]["output"];
  language: StringAggregateSelection;
};

export type AuthPayloadUserPreferencesConnection = {
  __typename?: "AuthPayloadUserPreferencesConnection";
  edges: Array<AuthPayloadUserPreferencesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type AuthPayloadUserPreferencesCreateInput = {
  language?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuthPayloadUserPreferencesEdge = {
  __typename?: "AuthPayloadUserPreferencesEdge";
  cursor: Scalars["String"]["output"];
  node: AuthPayloadUserPreferences;
};

export type AuthPayloadUserPreferencesOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more AuthPayloadUserPreferencesSort objects to sort AuthPayloadUserPreferences by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<AuthPayloadUserPreferencesSort>>;
};

/** Fields to sort AuthPayloadUserPreferences by. The order in which sorts are applied is not guaranteed when specifying many fields in one AuthPayloadUserPreferencesSort object. */
export type AuthPayloadUserPreferencesSort = {
  language?: InputMaybe<SortDirection>;
};

export type AuthPayloadUserPreferencesUpdateInput = {
  language?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuthPayloadUserPreferencesWhere = {
  AND?: InputMaybe<Array<AuthPayloadUserPreferencesWhere>>;
  NOT?: InputMaybe<AuthPayloadUserPreferencesWhere>;
  OR?: InputMaybe<Array<AuthPayloadUserPreferencesWhere>>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  language_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  language_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  language_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  language_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

/** Fields to sort AuthPayloadUsers by. The order in which sorts are applied is not guaranteed when specifying many fields in one AuthPayloadUserSort object. */
export type AuthPayloadUserSort = {
  birthday?: InputMaybe<SortDirection>;
  countryCode?: InputMaybe<SortDirection>;
  dailyEmailOptIn?: InputMaybe<SortDirection>;
  email?: InputMaybe<SortDirection>;
  fullPhoneNumber?: InputMaybe<SortDirection>;
  gender?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  isEmailVerified?: InputMaybe<SortDirection>;
  isOnboarded?: InputMaybe<SortDirection>;
  isPro?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  otpPreference?: InputMaybe<SortDirection>;
  password?: InputMaybe<SortDirection>;
  phoneNumber?: InputMaybe<SortDirection>;
};

export type AuthPayloadUserUpdateInput = {
  birthday?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  dailyEmailOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  isEmailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuthPayloadUserWhere = {
  AND?: InputMaybe<Array<AuthPayloadUserWhere>>;
  NOT?: InputMaybe<AuthPayloadUserWhere>;
  OR?: InputMaybe<Array<AuthPayloadUserWhere>>;
  birthday?: InputMaybe<Scalars["String"]["input"]>;
  birthday_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  birthday_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  birthday_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  birthday_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  countryCode_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  countryCode_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  countryCode_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  countryCode_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  dailyEmailOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  email_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  email_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  email_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  email_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fullPhoneNumber_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  gender_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gender_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gender_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  gender_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  isEmailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  otpPreference_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  password_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  password_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  password_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  password_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  phoneNumber_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type AuthPayloadUsersConnection = {
  __typename?: "AuthPayloadUsersConnection";
  edges: Array<AuthPayloadUserEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type BigIntAggregateSelection = {
  __typename?: "BigIntAggregateSelection";
  average?: Maybe<Scalars["BigInt"]["output"]>;
  max?: Maybe<Scalars["BigInt"]["output"]>;
  min?: Maybe<Scalars["BigInt"]["output"]>;
  sum?: Maybe<Scalars["BigInt"]["output"]>;
};

export type Career = {
  __typename?: "Career";
  brief_description?: Maybe<Scalars["String"]["output"]>;
  cons?: Maybe<Scalars["String"]["output"]>;
  core_skills?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["Date"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  growth?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  last_modified?: Maybe<Scalars["Date"]["output"]>;
  name: Scalars["String"]["output"];
  path?: Maybe<Scalars["JSON"]["output"]>;
  pros?: Maybe<Scalars["String"]["output"]>;
  salary?: Maybe<Scalars["String"]["output"]>;
  skills?: Maybe<Scalars["String"]["output"]>;
  trends?: Maybe<Scalars["JSON"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type CareerAggregateSelection = {
  __typename?: "CareerAggregateSelection";
  brief_description: StringAggregateSelection;
  cons: StringAggregateSelection;
  core_skills: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  growth: StringAggregateSelection;
  id: IdAggregateSelection;
  name: StringAggregateSelection;
  pros: StringAggregateSelection;
  salary: StringAggregateSelection;
  skills: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type CareerCreateInput = {
  brief_description?: InputMaybe<Scalars["String"]["input"]>;
  cons?: InputMaybe<Scalars["String"]["input"]>;
  core_skills?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  growth?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  last_modified?: InputMaybe<Scalars["Date"]["input"]>;
  name: Scalars["String"]["input"];
  path?: InputMaybe<Scalars["JSON"]["input"]>;
  pros?: InputMaybe<Scalars["String"]["input"]>;
  salary?: InputMaybe<Scalars["String"]["input"]>;
  skills?: InputMaybe<Scalars["String"]["input"]>;
  trends?: InputMaybe<Scalars["JSON"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type CareerEdge = {
  __typename?: "CareerEdge";
  cursor: Scalars["String"]["output"];
  node: Career;
};

export type CareerHeader = {
  __typename?: "CareerHeader";
  careerGoal?: Maybe<Scalars["String"]["output"]>;
  careerId?: Maybe<Scalars["String"]["output"]>;
  careerName?: Maybe<Scalars["String"]["output"]>;
  jobTitle?: Maybe<Scalars["String"]["output"]>;
};

export type CareerHeaderAggregateSelection = {
  __typename?: "CareerHeaderAggregateSelection";
  careerGoal: StringAggregateSelection;
  careerId: StringAggregateSelection;
  careerName: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  jobTitle: StringAggregateSelection;
};

export type CareerHeaderCreateInput = {
  careerGoal?: InputMaybe<Scalars["String"]["input"]>;
  careerId?: InputMaybe<Scalars["String"]["input"]>;
  careerName?: InputMaybe<Scalars["String"]["input"]>;
  jobTitle?: InputMaybe<Scalars["String"]["input"]>;
};

export type CareerHeaderEdge = {
  __typename?: "CareerHeaderEdge";
  cursor: Scalars["String"]["output"];
  node: CareerHeader;
};

export type CareerHeaderOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CareerHeaderSort objects to sort CareerHeaders by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CareerHeaderSort>>;
};

/** Fields to sort CareerHeaders by. The order in which sorts are applied is not guaranteed when specifying many fields in one CareerHeaderSort object. */
export type CareerHeaderSort = {
  careerGoal?: InputMaybe<SortDirection>;
  careerId?: InputMaybe<SortDirection>;
  careerName?: InputMaybe<SortDirection>;
  jobTitle?: InputMaybe<SortDirection>;
};

export type CareerHeaderUpdateInput = {
  careerGoal?: InputMaybe<Scalars["String"]["input"]>;
  careerId?: InputMaybe<Scalars["String"]["input"]>;
  careerName?: InputMaybe<Scalars["String"]["input"]>;
  jobTitle?: InputMaybe<Scalars["String"]["input"]>;
};

export type CareerHeaderWhere = {
  AND?: InputMaybe<Array<CareerHeaderWhere>>;
  NOT?: InputMaybe<CareerHeaderWhere>;
  OR?: InputMaybe<Array<CareerHeaderWhere>>;
  careerGoal?: InputMaybe<Scalars["String"]["input"]>;
  careerGoal_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  careerGoal_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  careerGoal_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  careerGoal_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  careerId?: InputMaybe<Scalars["String"]["input"]>;
  careerId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  careerId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  careerId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  careerId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  careerName?: InputMaybe<Scalars["String"]["input"]>;
  careerName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  careerName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  careerName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  careerName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  jobTitle?: InputMaybe<Scalars["String"]["input"]>;
  jobTitle_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  jobTitle_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  jobTitle_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  jobTitle_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type CareerHeadersConnection = {
  __typename?: "CareerHeadersConnection";
  edges: Array<CareerHeaderEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CareerOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CareerSort objects to sort Careers by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CareerSort>>;
};

/** Fields to sort Careers by. The order in which sorts are applied is not guaranteed when specifying many fields in one CareerSort object. */
export type CareerSort = {
  brief_description?: InputMaybe<SortDirection>;
  cons?: InputMaybe<SortDirection>;
  core_skills?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  growth?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  path?: InputMaybe<SortDirection>;
  pros?: InputMaybe<SortDirection>;
  salary?: InputMaybe<SortDirection>;
  skills?: InputMaybe<SortDirection>;
  trends?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
};

export type CareerTopicResponse = {
  __typename?: "CareerTopicResponse";
  careerName: Scalars["String"]["output"];
  relationshipIndex: Scalars["Int"]["output"];
  topicName: Scalars["String"]["output"];
};

export type CareerTopicResponseAggregateSelection = {
  __typename?: "CareerTopicResponseAggregateSelection";
  careerName: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  relationshipIndex: IntAggregateSelection;
  topicName: StringAggregateSelection;
};

export type CareerTopicResponseCreateInput = {
  careerName: Scalars["String"]["input"];
  relationshipIndex: Scalars["Int"]["input"];
  topicName: Scalars["String"]["input"];
};

export type CareerTopicResponseEdge = {
  __typename?: "CareerTopicResponseEdge";
  cursor: Scalars["String"]["output"];
  node: CareerTopicResponse;
};

export type CareerTopicResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CareerTopicResponseSort objects to sort CareerTopicResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CareerTopicResponseSort>>;
};

/** Fields to sort CareerTopicResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one CareerTopicResponseSort object. */
export type CareerTopicResponseSort = {
  careerName?: InputMaybe<SortDirection>;
  relationshipIndex?: InputMaybe<SortDirection>;
  topicName?: InputMaybe<SortDirection>;
};

export type CareerTopicResponseUpdateInput = {
  careerName?: InputMaybe<Scalars["String"]["input"]>;
  relationshipIndex?: InputMaybe<Scalars["Int"]["input"]>;
  relationshipIndex_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  relationshipIndex_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  topicName?: InputMaybe<Scalars["String"]["input"]>;
};

export type CareerTopicResponseWhere = {
  AND?: InputMaybe<Array<CareerTopicResponseWhere>>;
  NOT?: InputMaybe<CareerTopicResponseWhere>;
  OR?: InputMaybe<Array<CareerTopicResponseWhere>>;
  careerName?: InputMaybe<Scalars["String"]["input"]>;
  careerName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  careerName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  careerName_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  careerName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  relationshipIndex?: InputMaybe<Scalars["Int"]["input"]>;
  relationshipIndex_GT?: InputMaybe<Scalars["Int"]["input"]>;
  relationshipIndex_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  relationshipIndex_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  relationshipIndex_LT?: InputMaybe<Scalars["Int"]["input"]>;
  relationshipIndex_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  topicName?: InputMaybe<Scalars["String"]["input"]>;
  topicName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  topicName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  topicName_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  topicName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type CareerTopicResponsesConnection = {
  __typename?: "CareerTopicResponsesConnection";
  edges: Array<CareerTopicResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CareerUpdateInput = {
  brief_description?: InputMaybe<Scalars["String"]["input"]>;
  cons?: InputMaybe<Scalars["String"]["input"]>;
  core_skills?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  growth?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  last_modified?: InputMaybe<Scalars["Date"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  path?: InputMaybe<Scalars["JSON"]["input"]>;
  pros?: InputMaybe<Scalars["String"]["input"]>;
  salary?: InputMaybe<Scalars["String"]["input"]>;
  skills?: InputMaybe<Scalars["String"]["input"]>;
  trends?: InputMaybe<Scalars["JSON"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type CareerWhere = {
  AND?: InputMaybe<Array<CareerWhere>>;
  NOT?: InputMaybe<CareerWhere>;
  OR?: InputMaybe<Array<CareerWhere>>;
  brief_description?: InputMaybe<Scalars["String"]["input"]>;
  brief_description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  brief_description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  brief_description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  brief_description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  cons?: InputMaybe<Scalars["String"]["input"]>;
  cons_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  cons_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  cons_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  cons_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  core_skills?: InputMaybe<Scalars["String"]["input"]>;
  core_skills_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  core_skills_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  core_skills_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  core_skills_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["Date"]["input"]>;
  created_GT?: InputMaybe<Scalars["Date"]["input"]>;
  created_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["Date"]["input"]>;
  created_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  growth?: InputMaybe<Scalars["String"]["input"]>;
  growth_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  growth_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  growth_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  growth_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  last_modified?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  path?: InputMaybe<Scalars["JSON"]["input"]>;
  path_IN?: InputMaybe<Array<InputMaybe<Scalars["JSON"]["input"]>>>;
  pros?: InputMaybe<Scalars["String"]["input"]>;
  pros_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  pros_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  pros_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  pros_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  salary?: InputMaybe<Scalars["String"]["input"]>;
  salary_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  salary_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  salary_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  salary_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  skills?: InputMaybe<Scalars["String"]["input"]>;
  skills_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  skills_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  skills_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  skills_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  trends?: InputMaybe<Scalars["JSON"]["input"]>;
  trends_IN?: InputMaybe<Array<InputMaybe<Scalars["JSON"]["input"]>>>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type CareersConnection = {
  __typename?: "CareersConnection";
  edges: Array<CareerEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Challenge = {
  __typename?: "Challenge";
  category?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["Date"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["Date"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type ChallengeAggregateSelection = {
  __typename?: "ChallengeAggregateSelection";
  category: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  id: IntAggregateSelection;
  name: StringAggregateSelection;
  status: StringAggregateSelection;
};

export type ChallengeCreateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type ChallengeEdge = {
  __typename?: "ChallengeEdge";
  cursor: Scalars["String"]["output"];
  node: Challenge;
};

export type ChallengeOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ChallengeSort objects to sort Challenges by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ChallengeSort>>;
};

/** Fields to sort Challenges by. The order in which sorts are applied is not guaranteed when specifying many fields in one ChallengeSort object. */
export type ChallengeSort = {
  category?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  endDate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  startDate?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
};

export type ChallengeUpdateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type ChallengeWhere = {
  AND?: InputMaybe<Array<ChallengeWhere>>;
  NOT?: InputMaybe<ChallengeWhere>;
  OR?: InputMaybe<Array<ChallengeWhere>>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  category_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  category_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  category_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  category_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  endDate_GT?: InputMaybe<Scalars["Date"]["input"]>;
  endDate_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  endDate_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  endDate_LT?: InputMaybe<Scalars["Date"]["input"]>;
  endDate_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_GT?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  startDate_LT?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  status_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  status_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  status_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ChallengesConnection = {
  __typename?: "ChallengesConnection";
  edges: Array<ChallengeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Chapter = {
  __typename?: "Chapter";
  isRelevant?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
};

export type ChapterAggregateSelection = {
  __typename?: "ChapterAggregateSelection";
  count: Scalars["Int"]["output"];
  name: StringAggregateSelection;
};

export type ChapterCreateInput = {
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
};

export type ChapterEdge = {
  __typename?: "ChapterEdge";
  cursor: Scalars["String"]["output"];
  node: Chapter;
};

export type ChapterOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ChapterSort objects to sort Chapters by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ChapterSort>>;
};

/** Fields to sort Chapters by. The order in which sorts are applied is not guaranteed when specifying many fields in one ChapterSort object. */
export type ChapterSort = {
  isRelevant?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type ChapterUpdateInput = {
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type ChapterWhere = {
  AND?: InputMaybe<Array<ChapterWhere>>;
  NOT?: InputMaybe<ChapterWhere>;
  OR?: InputMaybe<Array<ChapterWhere>>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ChaptersConnection = {
  __typename?: "ChaptersConnection";
  edges: Array<ChapterEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Chat = {
  __typename?: "Chat";
  assignedQuests?: Maybe<Array<Maybe<AssignedQuest>>>;
  context?: Maybe<Scalars["String"]["output"]>;
  conversation: Array<Message>;
  date?: Maybe<Scalars["String"]["output"]>;
  hasMessageMessages: Array<Message>;
  hasMessageMessagesAggregate?: Maybe<ChatMessageHasMessageMessagesAggregationSelection>;
  hasMessageMessagesConnection: ChatHasMessageMessagesConnection;
  id: Scalars["ID"]["output"];
  journeysHasChat: Array<Journey>;
  journeysHasChatAggregate?: Maybe<ChatJourneyJourneysHasChatAggregationSelection>;
  journeysHasChatConnection: ChatJourneysHasChatConnection;
  name: Scalars["String"]["output"];
  objectId?: Maybe<Scalars["ID"]["output"]>;
  params?: Maybe<Scalars["JSON"]["output"]>;
  timezone: Scalars["String"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
  userId: Scalars["ID"]["output"];
  usersHasChat: Array<User>;
  usersHasChatAggregate?: Maybe<ChatUserUsersHasChatAggregationSelection>;
  usersHasChatConnection: ChatUsersHasChatConnection;
};

export type ChatHasMessageMessagesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<MessageOptions>;
  where?: InputMaybe<MessageWhere>;
};

export type ChatHasMessageMessagesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<MessageWhere>;
};

export type ChatHasMessageMessagesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ChatHasMessageMessagesConnectionSort>>;
  where?: InputMaybe<ChatHasMessageMessagesConnectionWhere>;
};

export type ChatJourneysHasChatArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<JourneyOptions>;
  where?: InputMaybe<JourneyWhere>;
};

export type ChatJourneysHasChatAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<JourneyWhere>;
};

export type ChatJourneysHasChatConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ChatJourneysHasChatConnectionSort>>;
  where?: InputMaybe<ChatJourneysHasChatConnectionWhere>;
};

export type ChatUsersHasChatArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type ChatUsersHasChatAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type ChatUsersHasChatConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ChatUsersHasChatConnectionSort>>;
  where?: InputMaybe<ChatUsersHasChatConnectionWhere>;
};

export type ChatAggregateSelection = {
  __typename?: "ChatAggregateSelection";
  context: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  date: StringAggregateSelection;
  id: IdAggregateSelection;
  name: StringAggregateSelection;
  objectId: IdAggregateSelection;
  timezone: StringAggregateSelection;
  url: StringAggregateSelection;
  userId: IdAggregateSelection;
};

export type ChatConnectInput = {
  hasMessageMessages?: InputMaybe<Array<ChatHasMessageMessagesConnectFieldInput>>;
  journeysHasChat?: InputMaybe<Array<ChatJourneysHasChatConnectFieldInput>>;
  usersHasChat?: InputMaybe<Array<ChatUsersHasChatConnectFieldInput>>;
};

export type ChatConnectWhere = {
  node: ChatWhere;
};

export type ChatCreateInput = {
  context?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  hasMessageMessages?: InputMaybe<ChatHasMessageMessagesFieldInput>;
  id: Scalars["ID"]["input"];
  journeysHasChat?: InputMaybe<ChatJourneysHasChatFieldInput>;
  name: Scalars["String"]["input"];
  objectId?: InputMaybe<Scalars["ID"]["input"]>;
  params?: InputMaybe<Scalars["JSON"]["input"]>;
  timezone: Scalars["String"]["input"];
  url?: InputMaybe<Scalars["String"]["input"]>;
  userId: Scalars["ID"]["input"];
  usersHasChat?: InputMaybe<ChatUsersHasChatFieldInput>;
};

export type ChatDeleteInput = {
  hasMessageMessages?: InputMaybe<Array<ChatHasMessageMessagesDeleteFieldInput>>;
  journeysHasChat?: InputMaybe<Array<ChatJourneysHasChatDeleteFieldInput>>;
  usersHasChat?: InputMaybe<Array<ChatUsersHasChatDeleteFieldInput>>;
};

export type ChatDisconnectInput = {
  hasMessageMessages?: InputMaybe<Array<ChatHasMessageMessagesDisconnectFieldInput>>;
  journeysHasChat?: InputMaybe<Array<ChatJourneysHasChatDisconnectFieldInput>>;
  usersHasChat?: InputMaybe<Array<ChatUsersHasChatDisconnectFieldInput>>;
};

export type ChatEdge = {
  __typename?: "ChatEdge";
  cursor: Scalars["String"]["output"];
  node: Chat;
};

export type ChatHasMessageMessagesAggregateInput = {
  AND?: InputMaybe<Array<ChatHasMessageMessagesAggregateInput>>;
  NOT?: InputMaybe<ChatHasMessageMessagesAggregateInput>;
  OR?: InputMaybe<Array<ChatHasMessageMessagesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasMessagePropertiesAggregationWhereInput>;
  node?: InputMaybe<ChatHasMessageMessagesNodeAggregationWhereInput>;
};

export type ChatHasMessageMessagesConnectFieldInput = {
  connect?: InputMaybe<Array<MessageConnectInput>>;
  edge: HasMessagePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<MessageConnectWhere>;
};

export type ChatHasMessageMessagesConnection = {
  __typename?: "ChatHasMessageMessagesConnection";
  edges: Array<ChatHasMessageMessagesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ChatHasMessageMessagesConnectionSort = {
  edge?: InputMaybe<HasMessagePropertiesSort>;
  node?: InputMaybe<MessageSort>;
};

export type ChatHasMessageMessagesConnectionWhere = {
  AND?: InputMaybe<Array<ChatHasMessageMessagesConnectionWhere>>;
  NOT?: InputMaybe<ChatHasMessageMessagesConnectionWhere>;
  OR?: InputMaybe<Array<ChatHasMessageMessagesConnectionWhere>>;
  edge?: InputMaybe<HasMessagePropertiesWhere>;
  node?: InputMaybe<MessageWhere>;
};

export type ChatHasMessageMessagesCreateFieldInput = {
  edge: HasMessagePropertiesCreateInput;
  node: MessageCreateInput;
};

export type ChatHasMessageMessagesDeleteFieldInput = {
  delete?: InputMaybe<MessageDeleteInput>;
  where?: InputMaybe<ChatHasMessageMessagesConnectionWhere>;
};

export type ChatHasMessageMessagesDisconnectFieldInput = {
  disconnect?: InputMaybe<MessageDisconnectInput>;
  where?: InputMaybe<ChatHasMessageMessagesConnectionWhere>;
};

export type ChatHasMessageMessagesFieldInput = {
  connect?: InputMaybe<Array<ChatHasMessageMessagesConnectFieldInput>>;
  create?: InputMaybe<Array<ChatHasMessageMessagesCreateFieldInput>>;
};

export type ChatHasMessageMessagesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChatHasMessageMessagesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChatHasMessageMessagesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChatHasMessageMessagesNodeAggregationWhereInput>>;
  content_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  content_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  emotion_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  emotion_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emotion_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  nodeId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  nodeId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  nodeId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  snackType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  snackType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChatHasMessageMessagesRelationship = {
  __typename?: "ChatHasMessageMessagesRelationship";
  cursor: Scalars["String"]["output"];
  node: Message;
  properties: HasMessageProperties;
};

export type ChatHasMessageMessagesUpdateConnectionInput = {
  edge?: InputMaybe<HasMessagePropertiesUpdateInput>;
  node?: InputMaybe<MessageUpdateInput>;
};

export type ChatHasMessageMessagesUpdateFieldInput = {
  connect?: InputMaybe<Array<ChatHasMessageMessagesConnectFieldInput>>;
  create?: InputMaybe<Array<ChatHasMessageMessagesCreateFieldInput>>;
  delete?: InputMaybe<Array<ChatHasMessageMessagesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChatHasMessageMessagesDisconnectFieldInput>>;
  update?: InputMaybe<ChatHasMessageMessagesUpdateConnectionInput>;
  where?: InputMaybe<ChatHasMessageMessagesConnectionWhere>;
};

export type ChatJourneyJourneysHasChatAggregationSelection = {
  __typename?: "ChatJourneyJourneysHasChatAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<ChatJourneyJourneysHasChatEdgeAggregateSelection>;
  node?: Maybe<ChatJourneyJourneysHasChatNodeAggregateSelection>;
};

export type ChatJourneyJourneysHasChatEdgeAggregateSelection = {
  __typename?: "ChatJourneyJourneysHasChatEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
};

export type ChatJourneyJourneysHasChatNodeAggregateSelection = {
  __typename?: "ChatJourneyJourneysHasChatNodeAggregateSelection";
  _empty: StringAggregateSelection;
  completedTasks: IntAggregateSelection;
  description: StringAggregateSelection;
  dueDate: StringAggregateSelection;
  id: IdAggregateSelection;
  name: StringAggregateSelection;
  totalTasks: IntAggregateSelection;
  url: StringAggregateSelection;
};

export type ChatJourneysHasChatAggregateInput = {
  AND?: InputMaybe<Array<ChatJourneysHasChatAggregateInput>>;
  NOT?: InputMaybe<ChatJourneysHasChatAggregateInput>;
  OR?: InputMaybe<Array<ChatJourneysHasChatAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasChatPropertiesAggregationWhereInput>;
  node?: InputMaybe<ChatJourneysHasChatNodeAggregationWhereInput>;
};

export type ChatJourneysHasChatConnectFieldInput = {
  connect?: InputMaybe<Array<JourneyConnectInput>>;
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<JourneyConnectWhere>;
};

export type ChatJourneysHasChatConnection = {
  __typename?: "ChatJourneysHasChatConnection";
  edges: Array<ChatJourneysHasChatRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ChatJourneysHasChatConnectionSort = {
  edge?: InputMaybe<HasChatPropertiesSort>;
  node?: InputMaybe<JourneySort>;
};

export type ChatJourneysHasChatConnectionWhere = {
  AND?: InputMaybe<Array<ChatJourneysHasChatConnectionWhere>>;
  NOT?: InputMaybe<ChatJourneysHasChatConnectionWhere>;
  OR?: InputMaybe<Array<ChatJourneysHasChatConnectionWhere>>;
  edge?: InputMaybe<HasChatPropertiesWhere>;
  node?: InputMaybe<JourneyWhere>;
};

export type ChatJourneysHasChatCreateFieldInput = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: JourneyCreateInput;
};

export type ChatJourneysHasChatDeleteFieldInput = {
  delete?: InputMaybe<JourneyDeleteInput>;
  where?: InputMaybe<ChatJourneysHasChatConnectionWhere>;
};

export type ChatJourneysHasChatDisconnectFieldInput = {
  disconnect?: InputMaybe<JourneyDisconnectInput>;
  where?: InputMaybe<ChatJourneysHasChatConnectionWhere>;
};

export type ChatJourneysHasChatFieldInput = {
  connect?: InputMaybe<Array<ChatJourneysHasChatConnectFieldInput>>;
  create?: InputMaybe<Array<ChatJourneysHasChatCreateFieldInput>>;
};

export type ChatJourneysHasChatNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChatJourneysHasChatNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChatJourneysHasChatNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChatJourneysHasChatNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  dueDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  dueDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  dueDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  dueDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  dueDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChatJourneysHasChatRelationship = {
  __typename?: "ChatJourneysHasChatRelationship";
  cursor: Scalars["String"]["output"];
  node: Journey;
  properties: HasChatProperties;
};

export type ChatJourneysHasChatUpdateConnectionInput = {
  edge?: InputMaybe<HasChatPropertiesUpdateInput>;
  node?: InputMaybe<JourneyUpdateInput>;
};

export type ChatJourneysHasChatUpdateFieldInput = {
  connect?: InputMaybe<Array<ChatJourneysHasChatConnectFieldInput>>;
  create?: InputMaybe<Array<ChatJourneysHasChatCreateFieldInput>>;
  delete?: InputMaybe<Array<ChatJourneysHasChatDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChatJourneysHasChatDisconnectFieldInput>>;
  update?: InputMaybe<ChatJourneysHasChatUpdateConnectionInput>;
  where?: InputMaybe<ChatJourneysHasChatConnectionWhere>;
};

export type ChatMessageHasMessageMessagesAggregationSelection = {
  __typename?: "ChatMessageHasMessageMessagesAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<ChatMessageHasMessageMessagesEdgeAggregateSelection>;
  node?: Maybe<ChatMessageHasMessageMessagesNodeAggregateSelection>;
};

export type ChatMessageHasMessageMessagesEdgeAggregateSelection = {
  __typename?: "ChatMessageHasMessageMessagesEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
};

export type ChatMessageHasMessageMessagesNodeAggregateSelection = {
  __typename?: "ChatMessageHasMessageMessagesNodeAggregateSelection";
  content: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  emotion: StringAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  nodeId: StringAggregateSelection;
  sender: StringAggregateSelection;
  snackType: StringAggregateSelection;
  type: StringAggregateSelection;
};

export type ChatOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ChatSort objects to sort Chats by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ChatSort>>;
};

export type ChatRelationInput = {
  hasMessageMessages?: InputMaybe<Array<ChatHasMessageMessagesCreateFieldInput>>;
  journeysHasChat?: InputMaybe<Array<ChatJourneysHasChatCreateFieldInput>>;
  usersHasChat?: InputMaybe<Array<ChatUsersHasChatCreateFieldInput>>;
};

/** Fields to sort Chats by. The order in which sorts are applied is not guaranteed when specifying many fields in one ChatSort object. */
export type ChatSort = {
  context?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  objectId?: InputMaybe<SortDirection>;
  params?: InputMaybe<SortDirection>;
  timezone?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
  userId?: InputMaybe<SortDirection>;
};

export type ChatUpdateInput = {
  context?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  hasMessageMessages?: InputMaybe<Array<ChatHasMessageMessagesUpdateFieldInput>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  journeysHasChat?: InputMaybe<Array<ChatJourneysHasChatUpdateFieldInput>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  objectId?: InputMaybe<Scalars["ID"]["input"]>;
  params?: InputMaybe<Scalars["JSON"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
  usersHasChat?: InputMaybe<Array<ChatUsersHasChatUpdateFieldInput>>;
};

export type ChatUserUsersHasChatAggregationSelection = {
  __typename?: "ChatUserUsersHasChatAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<ChatUserUsersHasChatEdgeAggregateSelection>;
  node?: Maybe<ChatUserUsersHasChatNodeAggregateSelection>;
};

export type ChatUserUsersHasChatEdgeAggregateSelection = {
  __typename?: "ChatUserUsersHasChatEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
};

export type ChatUserUsersHasChatNodeAggregateSelection = {
  __typename?: "ChatUserUsersHasChatNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type ChatUsersHasChatAggregateInput = {
  AND?: InputMaybe<Array<ChatUsersHasChatAggregateInput>>;
  NOT?: InputMaybe<ChatUsersHasChatAggregateInput>;
  OR?: InputMaybe<Array<ChatUsersHasChatAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasChatPropertiesAggregationWhereInput>;
  node?: InputMaybe<ChatUsersHasChatNodeAggregationWhereInput>;
};

export type ChatUsersHasChatConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type ChatUsersHasChatConnection = {
  __typename?: "ChatUsersHasChatConnection";
  edges: Array<ChatUsersHasChatRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ChatUsersHasChatConnectionSort = {
  edge?: InputMaybe<HasChatPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type ChatUsersHasChatConnectionWhere = {
  AND?: InputMaybe<Array<ChatUsersHasChatConnectionWhere>>;
  NOT?: InputMaybe<ChatUsersHasChatConnectionWhere>;
  OR?: InputMaybe<Array<ChatUsersHasChatConnectionWhere>>;
  edge?: InputMaybe<HasChatPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type ChatUsersHasChatCreateFieldInput = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: UserCreateInput;
};

export type ChatUsersHasChatDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<ChatUsersHasChatConnectionWhere>;
};

export type ChatUsersHasChatDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<ChatUsersHasChatConnectionWhere>;
};

export type ChatUsersHasChatFieldInput = {
  connect?: InputMaybe<Array<ChatUsersHasChatConnectFieldInput>>;
  create?: InputMaybe<Array<ChatUsersHasChatCreateFieldInput>>;
};

export type ChatUsersHasChatNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ChatUsersHasChatNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ChatUsersHasChatNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ChatUsersHasChatNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ChatUsersHasChatRelationship = {
  __typename?: "ChatUsersHasChatRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasChatProperties;
};

export type ChatUsersHasChatUpdateConnectionInput = {
  edge?: InputMaybe<HasChatPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type ChatUsersHasChatUpdateFieldInput = {
  connect?: InputMaybe<Array<ChatUsersHasChatConnectFieldInput>>;
  create?: InputMaybe<Array<ChatUsersHasChatCreateFieldInput>>;
  delete?: InputMaybe<Array<ChatUsersHasChatDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ChatUsersHasChatDisconnectFieldInput>>;
  update?: InputMaybe<ChatUsersHasChatUpdateConnectionInput>;
  where?: InputMaybe<ChatUsersHasChatConnectionWhere>;
};

export type ChatWhere = {
  AND?: InputMaybe<Array<ChatWhere>>;
  NOT?: InputMaybe<ChatWhere>;
  OR?: InputMaybe<Array<ChatWhere>>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  context_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  context_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  context_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  context_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  hasMessageMessagesAggregate?: InputMaybe<ChatHasMessageMessagesAggregateInput>;
  /** Return Chats where all of the related ChatHasMessageMessagesConnections match this filter */
  hasMessageMessagesConnection_ALL?: InputMaybe<ChatHasMessageMessagesConnectionWhere>;
  /** Return Chats where none of the related ChatHasMessageMessagesConnections match this filter */
  hasMessageMessagesConnection_NONE?: InputMaybe<ChatHasMessageMessagesConnectionWhere>;
  /** Return Chats where one of the related ChatHasMessageMessagesConnections match this filter */
  hasMessageMessagesConnection_SINGLE?: InputMaybe<ChatHasMessageMessagesConnectionWhere>;
  /** Return Chats where some of the related ChatHasMessageMessagesConnections match this filter */
  hasMessageMessagesConnection_SOME?: InputMaybe<ChatHasMessageMessagesConnectionWhere>;
  /** Return Chats where all of the related Messages match this filter */
  hasMessageMessages_ALL?: InputMaybe<MessageWhere>;
  /** Return Chats where none of the related Messages match this filter */
  hasMessageMessages_NONE?: InputMaybe<MessageWhere>;
  /** Return Chats where one of the related Messages match this filter */
  hasMessageMessages_SINGLE?: InputMaybe<MessageWhere>;
  /** Return Chats where some of the related Messages match this filter */
  hasMessageMessages_SOME?: InputMaybe<MessageWhere>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  journeysHasChatAggregate?: InputMaybe<ChatJourneysHasChatAggregateInput>;
  /** Return Chats where all of the related ChatJourneysHasChatConnections match this filter */
  journeysHasChatConnection_ALL?: InputMaybe<ChatJourneysHasChatConnectionWhere>;
  /** Return Chats where none of the related ChatJourneysHasChatConnections match this filter */
  journeysHasChatConnection_NONE?: InputMaybe<ChatJourneysHasChatConnectionWhere>;
  /** Return Chats where one of the related ChatJourneysHasChatConnections match this filter */
  journeysHasChatConnection_SINGLE?: InputMaybe<ChatJourneysHasChatConnectionWhere>;
  /** Return Chats where some of the related ChatJourneysHasChatConnections match this filter */
  journeysHasChatConnection_SOME?: InputMaybe<ChatJourneysHasChatConnectionWhere>;
  /** Return Chats where all of the related Journeys match this filter */
  journeysHasChat_ALL?: InputMaybe<JourneyWhere>;
  /** Return Chats where none of the related Journeys match this filter */
  journeysHasChat_NONE?: InputMaybe<JourneyWhere>;
  /** Return Chats where one of the related Journeys match this filter */
  journeysHasChat_SINGLE?: InputMaybe<JourneyWhere>;
  /** Return Chats where some of the related Journeys match this filter */
  journeysHasChat_SOME?: InputMaybe<JourneyWhere>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  objectId?: InputMaybe<Scalars["ID"]["input"]>;
  objectId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  objectId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  objectId_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  objectId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  params?: InputMaybe<Scalars["JSON"]["input"]>;
  params_IN?: InputMaybe<Array<InputMaybe<Scalars["JSON"]["input"]>>>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  timezone_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  timezone_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timezone_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  timezone_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
  userId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  userId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  userId_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  userId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  usersHasChatAggregate?: InputMaybe<ChatUsersHasChatAggregateInput>;
  /** Return Chats where all of the related ChatUsersHasChatConnections match this filter */
  usersHasChatConnection_ALL?: InputMaybe<ChatUsersHasChatConnectionWhere>;
  /** Return Chats where none of the related ChatUsersHasChatConnections match this filter */
  usersHasChatConnection_NONE?: InputMaybe<ChatUsersHasChatConnectionWhere>;
  /** Return Chats where one of the related ChatUsersHasChatConnections match this filter */
  usersHasChatConnection_SINGLE?: InputMaybe<ChatUsersHasChatConnectionWhere>;
  /** Return Chats where some of the related ChatUsersHasChatConnections match this filter */
  usersHasChatConnection_SOME?: InputMaybe<ChatUsersHasChatConnectionWhere>;
  /** Return Chats where all of the related Users match this filter */
  usersHasChat_ALL?: InputMaybe<UserWhere>;
  /** Return Chats where none of the related Users match this filter */
  usersHasChat_NONE?: InputMaybe<UserWhere>;
  /** Return Chats where one of the related Users match this filter */
  usersHasChat_SINGLE?: InputMaybe<UserWhere>;
  /** Return Chats where some of the related Users match this filter */
  usersHasChat_SOME?: InputMaybe<UserWhere>;
};

export type ChatsConnection = {
  __typename?: "ChatsConnection";
  edges: Array<ChatEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ChildInfo = {
  __typename?: "ChildInfo";
  completedTracks?: Maybe<Scalars["Float"]["output"]>;
  label: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  totalTracks?: Maybe<Scalars["Float"]["output"]>;
};

export type ChildInfoAggregateSelection = {
  __typename?: "ChildInfoAggregateSelection";
  completedTracks: FloatAggregateSelection;
  count: Scalars["Int"]["output"];
  label: StringAggregateSelection;
  name: StringAggregateSelection;
  totalTracks: FloatAggregateSelection;
};

export type ChildInfoCreateInput = {
  completedTracks?: InputMaybe<Scalars["Float"]["input"]>;
  label: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  totalTracks?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ChildInfoEdge = {
  __typename?: "ChildInfoEdge";
  cursor: Scalars["String"]["output"];
  node: ChildInfo;
};

export type ChildInfoOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ChildInfoSort objects to sort ChildInfos by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ChildInfoSort>>;
};

/** Fields to sort ChildInfos by. The order in which sorts are applied is not guaranteed when specifying many fields in one ChildInfoSort object. */
export type ChildInfoSort = {
  completedTracks?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  totalTracks?: InputMaybe<SortDirection>;
};

export type ChildInfoUpdateInput = {
  completedTracks?: InputMaybe<Scalars["Float"]["input"]>;
  completedTracks_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  completedTracks_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  completedTracks_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  completedTracks_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  totalTracks?: InputMaybe<Scalars["Float"]["input"]>;
  totalTracks_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalTracks_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalTracks_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalTracks_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ChildInfoWhere = {
  AND?: InputMaybe<Array<ChildInfoWhere>>;
  NOT?: InputMaybe<ChildInfoWhere>;
  OR?: InputMaybe<Array<ChildInfoWhere>>;
  completedTracks?: InputMaybe<Scalars["Float"]["input"]>;
  completedTracks_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completedTracks_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completedTracks_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  completedTracks_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completedTracks_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  totalTracks?: InputMaybe<Scalars["Float"]["input"]>;
  totalTracks_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalTracks_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalTracks_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalTracks_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalTracks_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type ChildInfosConnection = {
  __typename?: "ChildInfosConnection";
  edges: Array<ChildInfoEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CoreValue = {
  __typename?: "CoreValue";
  id?: Maybe<Scalars["Int"]["output"]>;
  importance?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type CoreValueAggregateSelection = {
  __typename?: "CoreValueAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IntAggregateSelection;
  importance: IntAggregateSelection;
  name: StringAggregateSelection;
};

export type CoreValueCreateInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  importance?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type CoreValueEdge = {
  __typename?: "CoreValueEdge";
  cursor: Scalars["String"]["output"];
  node: CoreValue;
};

export type CoreValueOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CoreValueSort objects to sort CoreValues by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CoreValueSort>>;
};

/** Fields to sort CoreValues by. The order in which sorts are applied is not guaranteed when specifying many fields in one CoreValueSort object. */
export type CoreValueSort = {
  id?: InputMaybe<SortDirection>;
  importance?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type CoreValueUpdateInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  importance?: InputMaybe<Scalars["Int"]["input"]>;
  importance_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  importance_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type CoreValueWhere = {
  AND?: InputMaybe<Array<CoreValueWhere>>;
  NOT?: InputMaybe<CoreValueWhere>;
  OR?: InputMaybe<Array<CoreValueWhere>>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  importance?: InputMaybe<Scalars["Int"]["input"]>;
  importance_GT?: InputMaybe<Scalars["Int"]["input"]>;
  importance_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  importance_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  importance_LT?: InputMaybe<Scalars["Int"]["input"]>;
  importance_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type CoreValuesConnection = {
  __typename?: "CoreValuesConnection";
  edges: Array<CoreValueEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Course = {
  __typename?: "Course";
  account_id?: Maybe<Scalars["Float"]["output"]>;
  apply_assignment_group_weights?: Maybe<Scalars["Boolean"]["output"]>;
  blueprint?: Maybe<Scalars["Boolean"]["output"]>;
  calendar_ics?: Maybe<Scalars["String"]["output"]>;
  courseId?: Maybe<Scalars["String"]["output"]>;
  course_code?: Maybe<Scalars["String"]["output"]>;
  course_id?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  created_at?: Maybe<Scalars["String"]["output"]>;
  created_date?: Maybe<Scalars["String"]["output"]>;
  default_view?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  end_at?: Maybe<Scalars["String"]["output"]>;
  enrollment_term_id?: Maybe<Scalars["Float"]["output"]>;
  grading_standard_id?: Maybe<Scalars["Float"]["output"]>;
  hasAssignmentAssignments: Array<Assignment>;
  hasAssignmentAssignmentsAggregate?: Maybe<CourseAssignmentHasAssignmentAssignmentsAggregationSelection>;
  hasAssignmentAssignmentsConnection: CourseHasAssignmentAssignmentsConnection;
  hasCourseCategoriesCourseCategories: Array<CourseCategory>;
  hasCourseCategoriesCourseCategoriesAggregate?: Maybe<CourseCourseCategoryHasCourseCategoriesCourseCategoriesAggregationSelection>;
  hasCourseCategoriesCourseCategoriesConnection: CourseHasCourseCategoriesCourseCategoriesConnection;
  hasPagePages: Array<Page>;
  hasPagePagesAggregate?: Maybe<CoursePageHasPagePagesAggregationSelection>;
  hasPagePagesConnection: CourseHasPagePagesConnection;
  hide_final_grades?: Maybe<Scalars["Boolean"]["output"]>;
  homeroom_course?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["ID"]["output"];
  integration_id?: Maybe<Scalars["String"]["output"]>;
  is_public?: Maybe<Scalars["Boolean"]["output"]>;
  is_public_to_auth_users?: Maybe<Scalars["Boolean"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
  learningStandards?: Maybe<Scalars["String"]["output"]>;
  lengthInWeeks?: Maybe<Scalars["Float"]["output"]>;
  license?: Maybe<Scalars["String"]["output"]>;
  lti_id?: Maybe<Scalars["String"]["output"]>;
  modules: Array<Module>;
  modulesAggregate?: Maybe<CourseModuleModulesAggregationSelection>;
  modulesConnection: CourseModulesConnection;
  name?: Maybe<Scalars["String"]["output"]>;
  objectives?: Maybe<Array<Scalars["String"]["output"]>>;
  original_name?: Maybe<Scalars["String"]["output"]>;
  period_ids?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  periods?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  public_syllabus?: Maybe<Scalars["Boolean"]["output"]>;
  public_syllabus_to_auth?: Maybe<Scalars["Boolean"]["output"]>;
  restrict_enrollments_to_course_dates?: Maybe<Scalars["Boolean"]["output"]>;
  root_account_id?: Maybe<Scalars["Float"]["output"]>;
  schoolsHasCourse: Array<School>;
  schoolsHasCourseAggregate?: Maybe<CourseSchoolSchoolsHasCourseAggregationSelection>;
  schoolsHasCourseConnection: CourseSchoolsHasCourseConnection;
  sis_course_id?: Maybe<Scalars["String"]["output"]>;
  sis_import_id?: Maybe<Scalars["Float"]["output"]>;
  start_at?: Maybe<Scalars["String"]["output"]>;
  state?: Maybe<Scalars["String"]["output"]>;
  storage_quota_mb?: Maybe<Scalars["Float"]["output"]>;
  template?: Maybe<Scalars["Boolean"]["output"]>;
  time_zone?: Maybe<Scalars["String"]["output"]>;
  title?: Maybe<Scalars["String"]["output"]>;
  updated_date?: Maybe<Scalars["String"]["output"]>;
  usersHasSchoolCourse: Array<User>;
  usersHasSchoolCourseAggregate?: Maybe<CourseUserUsersHasSchoolCourseAggregationSelection>;
  usersHasSchoolCourseConnection: CourseUsersHasSchoolCourseConnection;
  uuid?: Maybe<Scalars["String"]["output"]>;
  workflow_state?: Maybe<Scalars["String"]["output"]>;
};

export type CourseHasAssignmentAssignmentsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AssignmentOptions>;
  where?: InputMaybe<AssignmentWhere>;
};

export type CourseHasAssignmentAssignmentsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AssignmentWhere>;
};

export type CourseHasAssignmentAssignmentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseHasAssignmentAssignmentsConnectionSort>>;
  where?: InputMaybe<CourseHasAssignmentAssignmentsConnectionWhere>;
};

export type CourseHasCourseCategoriesCourseCategoriesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<CourseCategoryOptions>;
  where?: InputMaybe<CourseCategoryWhere>;
};

export type CourseHasCourseCategoriesCourseCategoriesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<CourseCategoryWhere>;
};

export type CourseHasCourseCategoriesCourseCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesConnectionSort>>;
  where?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>;
};

export type CourseHasPagePagesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<PageOptions>;
  where?: InputMaybe<PageWhere>;
};

export type CourseHasPagePagesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<PageWhere>;
};

export type CourseHasPagePagesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseHasPagePagesConnectionSort>>;
  where?: InputMaybe<CourseHasPagePagesConnectionWhere>;
};

export type CourseModulesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ModuleOptions>;
  where?: InputMaybe<ModuleWhere>;
};

export type CourseModulesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ModuleWhere>;
};

export type CourseModulesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseModulesConnectionSort>>;
  where?: InputMaybe<CourseModulesConnectionWhere>;
};

export type CourseSchoolsHasCourseArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<SchoolOptions>;
  where?: InputMaybe<SchoolWhere>;
};

export type CourseSchoolsHasCourseAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<SchoolWhere>;
};

export type CourseSchoolsHasCourseConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseSchoolsHasCourseConnectionSort>>;
  where?: InputMaybe<CourseSchoolsHasCourseConnectionWhere>;
};

export type CourseUsersHasSchoolCourseArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type CourseUsersHasSchoolCourseAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type CourseUsersHasSchoolCourseConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseUsersHasSchoolCourseConnectionSort>>;
  where?: InputMaybe<CourseUsersHasSchoolCourseConnectionWhere>;
};

export type CourseAggregateSelection = {
  __typename?: "CourseAggregateSelection";
  account_id: FloatAggregateSelection;
  calendar_ics: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  courseId: StringAggregateSelection;
  course_code: StringAggregateSelection;
  course_id: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  default_view: StringAggregateSelection;
  description: StringAggregateSelection;
  end_at: StringAggregateSelection;
  enrollment_term_id: FloatAggregateSelection;
  grading_standard_id: FloatAggregateSelection;
  id: IdAggregateSelection;
  integration_id: StringAggregateSelection;
  label: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  learningStandards: StringAggregateSelection;
  lengthInWeeks: FloatAggregateSelection;
  license: StringAggregateSelection;
  lti_id: StringAggregateSelection;
  name: StringAggregateSelection;
  original_name: StringAggregateSelection;
  root_account_id: FloatAggregateSelection;
  sis_course_id: StringAggregateSelection;
  sis_import_id: FloatAggregateSelection;
  start_at: StringAggregateSelection;
  state: StringAggregateSelection;
  storage_quota_mb: FloatAggregateSelection;
  time_zone: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  uuid: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type CourseAssignmentHasAssignmentAssignmentsAggregationSelection = {
  __typename?: "CourseAssignmentHasAssignmentAssignmentsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<CourseAssignmentHasAssignmentAssignmentsNodeAggregateSelection>;
};

export type CourseAssignmentHasAssignmentAssignmentsNodeAggregateSelection = {
  __typename?: "CourseAssignmentHasAssignmentAssignmentsNodeAggregateSelection";
  _empty: StringAggregateSelection;
  allowed_attempts: FloatAggregateSelection;
  assignee_mode: StringAggregateSelection;
  assignment_group_id: FloatAggregateSelection;
  assignment_name: StringAggregateSelection;
  body: StringAggregateSelection;
  category_id: StringAggregateSelection;
  course_id: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  description: StringAggregateSelection;
  description_plaintext: StringAggregateSelection;
  discussion_topic_assignment_id: FloatAggregateSelection;
  discussion_topic_author_anonymous_id: StringAggregateSelection;
  discussion_topic_author_avatar_image_url: StringAggregateSelection;
  discussion_topic_author_display_name: StringAggregateSelection;
  discussion_topic_author_html_url: StringAggregateSelection;
  discussion_topic_author_id: FloatAggregateSelection;
  discussion_topic_created_at: StringAggregateSelection;
  discussion_topic_discussion_subentry_count: FloatAggregateSelection;
  discussion_topic_discussion_type: StringAggregateSelection;
  discussion_topic_html_url: StringAggregateSelection;
  discussion_topic_id: FloatAggregateSelection;
  discussion_topic_last_reply_at: StringAggregateSelection;
  discussion_topic_message: StringAggregateSelection;
  discussion_topic_posted_at: StringAggregateSelection;
  discussion_topic_read_state: StringAggregateSelection;
  discussion_topic_title: StringAggregateSelection;
  discussion_topic_unread_count: FloatAggregateSelection;
  discussion_topic_url: StringAggregateSelection;
  discussion_topic_user_name: StringAggregateSelection;
  display_date: StringAggregateSelection;
  due_at: StringAggregateSelection;
  due_date: StringAggregateSelection;
  end_date: StringAggregateSelection;
  external_tool_tag_attributes_content_id: FloatAggregateSelection;
  external_tool_tag_attributes_content_type: StringAggregateSelection;
  external_tool_tag_attributes_external_data: StringAggregateSelection;
  external_tool_tag_attributes_resource_link_id: StringAggregateSelection;
  external_tool_tag_attributes_url: StringAggregateSelection;
  grader_count: FloatAggregateSelection;
  grading_type: StringAggregateSelection;
  html: StringAggregateSelection;
  html_url: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  lti_context_id: StringAggregateSelection;
  max_attempts: FloatAggregateSelection;
  max_name_length: FloatAggregateSelection;
  name: StringAggregateSelection;
  needs_grading_count: FloatAggregateSelection;
  objectives: StringAggregateSelection;
  points_possible: FloatAggregateSelection;
  position: FloatAggregateSelection;
  resource_metadata: StringAggregateSelection;
  secure_params: StringAggregateSelection;
  session_id: StringAggregateSelection;
  start_date: StringAggregateSelection;
  state: StringAggregateSelection;
  submissions_download_url: StringAggregateSelection;
  text: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_at: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  url: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type CourseCategoriesConnection = {
  __typename?: "CourseCategoriesConnection";
  edges: Array<CourseCategoryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseCategory = {
  __typename?: "CourseCategory";
  coursesHasCourseCategories: Array<Course>;
  coursesHasCourseCategoriesAggregate?: Maybe<CourseCategoryCourseCoursesHasCourseCategoriesAggregationSelection>;
  coursesHasCourseCategoriesConnection: CourseCategoryCoursesHasCourseCategoriesConnection;
  created: Scalars["DateTime"]["output"];
  hasAssignmentAssignments: Array<Assignment>;
  hasAssignmentAssignmentsAggregate?: Maybe<CourseCategoryAssignmentHasAssignmentAssignmentsAggregationSelection>;
  hasAssignmentAssignmentsConnection: CourseCategoryHasAssignmentAssignmentsConnection;
  id: Scalars["String"]["output"];
  last_modified: Scalars["DateTime"]["output"];
  title: Scalars["String"]["output"];
};

export type CourseCategoryCoursesHasCourseCategoriesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<CourseOptions>;
  where?: InputMaybe<CourseWhere>;
};

export type CourseCategoryCoursesHasCourseCategoriesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<CourseWhere>;
};

export type CourseCategoryCoursesHasCourseCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesConnectionSort>>;
  where?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>;
};

export type CourseCategoryHasAssignmentAssignmentsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AssignmentOptions>;
  where?: InputMaybe<AssignmentWhere>;
};

export type CourseCategoryHasAssignmentAssignmentsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AssignmentWhere>;
};

export type CourseCategoryHasAssignmentAssignmentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsConnectionSort>>;
  where?: InputMaybe<CourseCategoryHasAssignmentAssignmentsConnectionWhere>;
};

export type CourseCategoryAggregateSelection = {
  __typename?: "CourseCategoryAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  title: StringAggregateSelection;
};

export type CourseCategoryAssignmentHasAssignmentAssignmentsAggregationSelection = {
  __typename?: "CourseCategoryAssignmentHasAssignmentAssignmentsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<CourseCategoryAssignmentHasAssignmentAssignmentsNodeAggregateSelection>;
};

export type CourseCategoryAssignmentHasAssignmentAssignmentsNodeAggregateSelection = {
  __typename?: "CourseCategoryAssignmentHasAssignmentAssignmentsNodeAggregateSelection";
  _empty: StringAggregateSelection;
  allowed_attempts: FloatAggregateSelection;
  assignee_mode: StringAggregateSelection;
  assignment_group_id: FloatAggregateSelection;
  assignment_name: StringAggregateSelection;
  body: StringAggregateSelection;
  category_id: StringAggregateSelection;
  course_id: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  description: StringAggregateSelection;
  description_plaintext: StringAggregateSelection;
  discussion_topic_assignment_id: FloatAggregateSelection;
  discussion_topic_author_anonymous_id: StringAggregateSelection;
  discussion_topic_author_avatar_image_url: StringAggregateSelection;
  discussion_topic_author_display_name: StringAggregateSelection;
  discussion_topic_author_html_url: StringAggregateSelection;
  discussion_topic_author_id: FloatAggregateSelection;
  discussion_topic_created_at: StringAggregateSelection;
  discussion_topic_discussion_subentry_count: FloatAggregateSelection;
  discussion_topic_discussion_type: StringAggregateSelection;
  discussion_topic_html_url: StringAggregateSelection;
  discussion_topic_id: FloatAggregateSelection;
  discussion_topic_last_reply_at: StringAggregateSelection;
  discussion_topic_message: StringAggregateSelection;
  discussion_topic_posted_at: StringAggregateSelection;
  discussion_topic_read_state: StringAggregateSelection;
  discussion_topic_title: StringAggregateSelection;
  discussion_topic_unread_count: FloatAggregateSelection;
  discussion_topic_url: StringAggregateSelection;
  discussion_topic_user_name: StringAggregateSelection;
  display_date: StringAggregateSelection;
  due_at: StringAggregateSelection;
  due_date: StringAggregateSelection;
  end_date: StringAggregateSelection;
  external_tool_tag_attributes_content_id: FloatAggregateSelection;
  external_tool_tag_attributes_content_type: StringAggregateSelection;
  external_tool_tag_attributes_external_data: StringAggregateSelection;
  external_tool_tag_attributes_resource_link_id: StringAggregateSelection;
  external_tool_tag_attributes_url: StringAggregateSelection;
  grader_count: FloatAggregateSelection;
  grading_type: StringAggregateSelection;
  html: StringAggregateSelection;
  html_url: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  lti_context_id: StringAggregateSelection;
  max_attempts: FloatAggregateSelection;
  max_name_length: FloatAggregateSelection;
  name: StringAggregateSelection;
  needs_grading_count: FloatAggregateSelection;
  objectives: StringAggregateSelection;
  points_possible: FloatAggregateSelection;
  position: FloatAggregateSelection;
  resource_metadata: StringAggregateSelection;
  secure_params: StringAggregateSelection;
  session_id: StringAggregateSelection;
  start_date: StringAggregateSelection;
  state: StringAggregateSelection;
  submissions_download_url: StringAggregateSelection;
  text: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_at: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  url: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type CourseCategoryConnectInput = {
  coursesHasCourseCategories?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesConnectFieldInput>>;
  hasAssignmentAssignments?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsConnectFieldInput>>;
};

export type CourseCategoryConnectOrCreateInput = {
  coursesHasCourseCategories?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesConnectOrCreateFieldInput>>;
  hasAssignmentAssignments?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsConnectOrCreateFieldInput>>;
};

export type CourseCategoryConnectWhere = {
  node: CourseCategoryWhere;
};

export type CourseCategoryCourseCoursesHasCourseCategoriesAggregationSelection = {
  __typename?: "CourseCategoryCourseCoursesHasCourseCategoriesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<CourseCategoryCourseCoursesHasCourseCategoriesNodeAggregateSelection>;
};

export type CourseCategoryCourseCoursesHasCourseCategoriesNodeAggregateSelection = {
  __typename?: "CourseCategoryCourseCoursesHasCourseCategoriesNodeAggregateSelection";
  account_id: FloatAggregateSelection;
  calendar_ics: StringAggregateSelection;
  courseId: StringAggregateSelection;
  course_code: StringAggregateSelection;
  course_id: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  default_view: StringAggregateSelection;
  description: StringAggregateSelection;
  end_at: StringAggregateSelection;
  enrollment_term_id: FloatAggregateSelection;
  grading_standard_id: FloatAggregateSelection;
  id: IdAggregateSelection;
  integration_id: StringAggregateSelection;
  label: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  learningStandards: StringAggregateSelection;
  lengthInWeeks: FloatAggregateSelection;
  license: StringAggregateSelection;
  lti_id: StringAggregateSelection;
  name: StringAggregateSelection;
  original_name: StringAggregateSelection;
  root_account_id: FloatAggregateSelection;
  sis_course_id: StringAggregateSelection;
  sis_import_id: FloatAggregateSelection;
  start_at: StringAggregateSelection;
  state: StringAggregateSelection;
  storage_quota_mb: FloatAggregateSelection;
  time_zone: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  uuid: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type CourseCategoryCoursesHasCourseCategoriesAggregateInput = {
  AND?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesAggregateInput>>;
  NOT?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesAggregateInput>;
  OR?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesNodeAggregationWhereInput>;
};

export type CourseCategoryCoursesHasCourseCategoriesConnectFieldInput = {
  connect?: InputMaybe<Array<CourseConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<CourseConnectWhere>;
};

export type CourseCategoryCoursesHasCourseCategoriesConnectOrCreateFieldInput = {
  onCreate: CourseCategoryCoursesHasCourseCategoriesConnectOrCreateFieldInputOnCreate;
  where: CourseConnectOrCreateWhere;
};

export type CourseCategoryCoursesHasCourseCategoriesConnectOrCreateFieldInputOnCreate = {
  node: CourseOnCreateInput;
};

export type CourseCategoryCoursesHasCourseCategoriesConnection = {
  __typename?: "CourseCategoryCoursesHasCourseCategoriesConnection";
  edges: Array<CourseCategoryCoursesHasCourseCategoriesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseCategoryCoursesHasCourseCategoriesConnectionSort = {
  node?: InputMaybe<CourseSort>;
};

export type CourseCategoryCoursesHasCourseCategoriesConnectionWhere = {
  AND?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>>;
  NOT?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>;
  OR?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>>;
  node?: InputMaybe<CourseWhere>;
};

export type CourseCategoryCoursesHasCourseCategoriesCreateFieldInput = {
  node: CourseCreateInput;
};

export type CourseCategoryCoursesHasCourseCategoriesDeleteFieldInput = {
  delete?: InputMaybe<CourseDeleteInput>;
  where?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>;
};

export type CourseCategoryCoursesHasCourseCategoriesDisconnectFieldInput = {
  disconnect?: InputMaybe<CourseDisconnectInput>;
  where?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>;
};

export type CourseCategoryCoursesHasCourseCategoriesFieldInput = {
  connect?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesCreateFieldInput>>;
};

export type CourseCategoryCoursesHasCourseCategoriesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesNodeAggregationWhereInput>>;
  account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_term_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lengthInWeeks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  root_account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_import_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  storage_quota_mb_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseCategoryCoursesHasCourseCategoriesRelationship = {
  __typename?: "CourseCategoryCoursesHasCourseCategoriesRelationship";
  cursor: Scalars["String"]["output"];
  node: Course;
};

export type CourseCategoryCoursesHasCourseCategoriesUpdateConnectionInput = {
  node?: InputMaybe<CourseUpdateInput>;
};

export type CourseCategoryCoursesHasCourseCategoriesUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesDisconnectFieldInput>>;
  update?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesUpdateConnectionInput>;
  where?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>;
};

export type CourseCategoryCreateInput = {
  coursesHasCourseCategories?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesFieldInput>;
  created: Scalars["DateTime"]["input"];
  hasAssignmentAssignments?: InputMaybe<CourseCategoryHasAssignmentAssignmentsFieldInput>;
  id: Scalars["String"]["input"];
  last_modified: Scalars["DateTime"]["input"];
  title: Scalars["String"]["input"];
};

export type CourseCategoryDeleteInput = {
  coursesHasCourseCategories?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesDeleteFieldInput>>;
  hasAssignmentAssignments?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsDeleteFieldInput>>;
};

export type CourseCategoryDisconnectInput = {
  coursesHasCourseCategories?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesDisconnectFieldInput>>;
  hasAssignmentAssignments?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsDisconnectFieldInput>>;
};

export type CourseCategoryEdge = {
  __typename?: "CourseCategoryEdge";
  cursor: Scalars["String"]["output"];
  node: CourseCategory;
};

export type CourseCategoryHasAssignmentAssignmentsAggregateInput = {
  AND?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsAggregateInput>>;
  NOT?: InputMaybe<CourseCategoryHasAssignmentAssignmentsAggregateInput>;
  OR?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<CourseCategoryHasAssignmentAssignmentsNodeAggregationWhereInput>;
};

export type CourseCategoryHasAssignmentAssignmentsConnectFieldInput = {
  connect?: InputMaybe<Array<AssignmentConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AssignmentConnectWhere>;
};

export type CourseCategoryHasAssignmentAssignmentsConnectOrCreateFieldInput = {
  onCreate: CourseCategoryHasAssignmentAssignmentsConnectOrCreateFieldInputOnCreate;
  where: AssignmentConnectOrCreateWhere;
};

export type CourseCategoryHasAssignmentAssignmentsConnectOrCreateFieldInputOnCreate = {
  node: AssignmentOnCreateInput;
};

export type CourseCategoryHasAssignmentAssignmentsConnection = {
  __typename?: "CourseCategoryHasAssignmentAssignmentsConnection";
  edges: Array<CourseCategoryHasAssignmentAssignmentsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseCategoryHasAssignmentAssignmentsConnectionSort = {
  node?: InputMaybe<AssignmentSort>;
};

export type CourseCategoryHasAssignmentAssignmentsConnectionWhere = {
  AND?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsConnectionWhere>>;
  NOT?: InputMaybe<CourseCategoryHasAssignmentAssignmentsConnectionWhere>;
  OR?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsConnectionWhere>>;
  node?: InputMaybe<AssignmentWhere>;
};

export type CourseCategoryHasAssignmentAssignmentsCreateFieldInput = {
  node: AssignmentCreateInput;
};

export type CourseCategoryHasAssignmentAssignmentsDeleteFieldInput = {
  delete?: InputMaybe<AssignmentDeleteInput>;
  where?: InputMaybe<CourseCategoryHasAssignmentAssignmentsConnectionWhere>;
};

export type CourseCategoryHasAssignmentAssignmentsDisconnectFieldInput = {
  disconnect?: InputMaybe<AssignmentDisconnectInput>;
  where?: InputMaybe<CourseCategoryHasAssignmentAssignmentsConnectionWhere>;
};

export type CourseCategoryHasAssignmentAssignmentsFieldInput = {
  connect?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsCreateFieldInput>>;
};

export type CourseCategoryHasAssignmentAssignmentsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseCategoryHasAssignmentAssignmentsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  allowed_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_group_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_unread_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  due_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  due_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  due_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grader_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  max_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  needs_grading_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  points_possible_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseCategoryHasAssignmentAssignmentsRelationship = {
  __typename?: "CourseCategoryHasAssignmentAssignmentsRelationship";
  cursor: Scalars["String"]["output"];
  node: Assignment;
};

export type CourseCategoryHasAssignmentAssignmentsUpdateConnectionInput = {
  node?: InputMaybe<AssignmentUpdateInput>;
};

export type CourseCategoryHasAssignmentAssignmentsUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsDisconnectFieldInput>>;
  update?: InputMaybe<CourseCategoryHasAssignmentAssignmentsUpdateConnectionInput>;
  where?: InputMaybe<CourseCategoryHasAssignmentAssignmentsConnectionWhere>;
};

export type CourseCategoryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseCategorySort objects to sort CourseCategories by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseCategorySort>>;
};

export type CourseCategoryRelationInput = {
  coursesHasCourseCategories?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesCreateFieldInput>>;
  hasAssignmentAssignments?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsCreateFieldInput>>;
};

/** Fields to sort CourseCategories by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseCategorySort object. */
export type CourseCategorySort = {
  created?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
};

export type CourseCategoryUpdateInput = {
  coursesHasCourseCategories?: InputMaybe<Array<CourseCategoryCoursesHasCourseCategoriesUpdateFieldInput>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  hasAssignmentAssignments?: InputMaybe<Array<CourseCategoryHasAssignmentAssignmentsUpdateFieldInput>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type CourseCategoryWhere = {
  AND?: InputMaybe<Array<CourseCategoryWhere>>;
  NOT?: InputMaybe<CourseCategoryWhere>;
  OR?: InputMaybe<Array<CourseCategoryWhere>>;
  coursesHasCourseCategoriesAggregate?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesAggregateInput>;
  /** Return CourseCategories where all of the related CourseCategoryCoursesHasCourseCategoriesConnections match this filter */
  coursesHasCourseCategoriesConnection_ALL?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>;
  /** Return CourseCategories where none of the related CourseCategoryCoursesHasCourseCategoriesConnections match this filter */
  coursesHasCourseCategoriesConnection_NONE?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>;
  /** Return CourseCategories where one of the related CourseCategoryCoursesHasCourseCategoriesConnections match this filter */
  coursesHasCourseCategoriesConnection_SINGLE?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>;
  /** Return CourseCategories where some of the related CourseCategoryCoursesHasCourseCategoriesConnections match this filter */
  coursesHasCourseCategoriesConnection_SOME?: InputMaybe<CourseCategoryCoursesHasCourseCategoriesConnectionWhere>;
  /** Return CourseCategories where all of the related Courses match this filter */
  coursesHasCourseCategories_ALL?: InputMaybe<CourseWhere>;
  /** Return CourseCategories where none of the related Courses match this filter */
  coursesHasCourseCategories_NONE?: InputMaybe<CourseWhere>;
  /** Return CourseCategories where one of the related Courses match this filter */
  coursesHasCourseCategories_SINGLE?: InputMaybe<CourseWhere>;
  /** Return CourseCategories where some of the related Courses match this filter */
  coursesHasCourseCategories_SOME?: InputMaybe<CourseWhere>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  hasAssignmentAssignmentsAggregate?: InputMaybe<CourseCategoryHasAssignmentAssignmentsAggregateInput>;
  /** Return CourseCategories where all of the related CourseCategoryHasAssignmentAssignmentsConnections match this filter */
  hasAssignmentAssignmentsConnection_ALL?: InputMaybe<CourseCategoryHasAssignmentAssignmentsConnectionWhere>;
  /** Return CourseCategories where none of the related CourseCategoryHasAssignmentAssignmentsConnections match this filter */
  hasAssignmentAssignmentsConnection_NONE?: InputMaybe<CourseCategoryHasAssignmentAssignmentsConnectionWhere>;
  /** Return CourseCategories where one of the related CourseCategoryHasAssignmentAssignmentsConnections match this filter */
  hasAssignmentAssignmentsConnection_SINGLE?: InputMaybe<CourseCategoryHasAssignmentAssignmentsConnectionWhere>;
  /** Return CourseCategories where some of the related CourseCategoryHasAssignmentAssignmentsConnections match this filter */
  hasAssignmentAssignmentsConnection_SOME?: InputMaybe<CourseCategoryHasAssignmentAssignmentsConnectionWhere>;
  /** Return CourseCategories where all of the related Assignments match this filter */
  hasAssignmentAssignments_ALL?: InputMaybe<AssignmentWhere>;
  /** Return CourseCategories where none of the related Assignments match this filter */
  hasAssignmentAssignments_NONE?: InputMaybe<AssignmentWhere>;
  /** Return CourseCategories where one of the related Assignments match this filter */
  hasAssignmentAssignments_SINGLE?: InputMaybe<AssignmentWhere>;
  /** Return CourseCategories where some of the related Assignments match this filter */
  hasAssignmentAssignments_SOME?: InputMaybe<AssignmentWhere>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type CourseConnectInput = {
  hasAssignmentAssignments?: InputMaybe<Array<CourseHasAssignmentAssignmentsConnectFieldInput>>;
  hasCourseCategoriesCourseCategories?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesConnectFieldInput>>;
  hasPagePages?: InputMaybe<Array<CourseHasPagePagesConnectFieldInput>>;
  modules?: InputMaybe<Array<CourseModulesConnectFieldInput>>;
  schoolsHasCourse?: InputMaybe<Array<CourseSchoolsHasCourseConnectFieldInput>>;
  usersHasSchoolCourse?: InputMaybe<Array<CourseUsersHasSchoolCourseConnectFieldInput>>;
};

export type CourseConnectOrCreateInput = {
  hasAssignmentAssignments?: InputMaybe<Array<CourseHasAssignmentAssignmentsConnectOrCreateFieldInput>>;
  hasPagePages?: InputMaybe<Array<CourseHasPagePagesConnectOrCreateFieldInput>>;
  modules?: InputMaybe<Array<CourseModulesConnectOrCreateFieldInput>>;
};

export type CourseConnectOrCreateWhere = {
  node: CourseUniqueWhere;
};

export type CourseConnectWhere = {
  node: CourseWhere;
};

export type CourseCourseCategoryHasCourseCategoriesCourseCategoriesAggregationSelection = {
  __typename?: "CourseCourseCategoryHasCourseCategoriesCourseCategoriesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<CourseCourseCategoryHasCourseCategoriesCourseCategoriesNodeAggregateSelection>;
};

export type CourseCourseCategoryHasCourseCategoriesCourseCategoriesNodeAggregateSelection = {
  __typename?: "CourseCourseCategoryHasCourseCategoriesCourseCategoriesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  title: StringAggregateSelection;
};

export type CourseCreateInput = {
  account_id?: InputMaybe<Scalars["Float"]["input"]>;
  apply_assignment_group_weights?: InputMaybe<Scalars["Boolean"]["input"]>;
  blueprint?: InputMaybe<Scalars["Boolean"]["input"]>;
  calendar_ics?: InputMaybe<Scalars["String"]["input"]>;
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  course_code?: InputMaybe<Scalars["String"]["input"]>;
  course_id?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  default_view?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  end_at?: InputMaybe<Scalars["String"]["input"]>;
  enrollment_term_id?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id?: InputMaybe<Scalars["Float"]["input"]>;
  hasAssignmentAssignments?: InputMaybe<CourseHasAssignmentAssignmentsFieldInput>;
  hasCourseCategoriesCourseCategories?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesFieldInput>;
  hasPagePages?: InputMaybe<CourseHasPagePagesFieldInput>;
  hide_final_grades?: InputMaybe<Scalars["Boolean"]["input"]>;
  homeroom_course?: InputMaybe<Scalars["Boolean"]["input"]>;
  integration_id?: InputMaybe<Scalars["String"]["input"]>;
  is_public?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_public_to_auth_users?: InputMaybe<Scalars["Boolean"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards?: InputMaybe<Scalars["String"]["input"]>;
  lengthInWeeks?: InputMaybe<Scalars["Float"]["input"]>;
  license?: InputMaybe<Scalars["String"]["input"]>;
  lti_id?: InputMaybe<Scalars["String"]["input"]>;
  modules?: InputMaybe<CourseModulesFieldInput>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Array<Scalars["String"]["input"]>>;
  original_name?: InputMaybe<Scalars["String"]["input"]>;
  period_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  periods?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  public_syllabus?: InputMaybe<Scalars["Boolean"]["input"]>;
  public_syllabus_to_auth?: InputMaybe<Scalars["Boolean"]["input"]>;
  restrict_enrollments_to_course_dates?: InputMaybe<Scalars["Boolean"]["input"]>;
  root_account_id?: InputMaybe<Scalars["Float"]["input"]>;
  schoolsHasCourse?: InputMaybe<CourseSchoolsHasCourseFieldInput>;
  sis_course_id?: InputMaybe<Scalars["String"]["input"]>;
  sis_import_id?: InputMaybe<Scalars["Float"]["input"]>;
  start_at?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  storage_quota_mb?: InputMaybe<Scalars["Float"]["input"]>;
  template?: InputMaybe<Scalars["Boolean"]["input"]>;
  time_zone?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSchoolCourse?: InputMaybe<CourseUsersHasSchoolCourseFieldInput>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state?: InputMaybe<Scalars["String"]["input"]>;
};

export type CourseCreationResponse = {
  __typename?: "CourseCreationResponse";
  data?: Maybe<Curriculum>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type CourseCreationResponseAggregateSelection = {
  __typename?: "CourseCreationResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type CourseCreationResponseCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type CourseCreationResponseEdge = {
  __typename?: "CourseCreationResponseEdge";
  cursor: Scalars["String"]["output"];
  node: CourseCreationResponse;
};

export type CourseCreationResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseCreationResponseSort objects to sort CourseCreationResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseCreationResponseSort>>;
};

/** Fields to sort CourseCreationResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseCreationResponseSort object. */
export type CourseCreationResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type CourseCreationResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseCreationResponseWhere = {
  AND?: InputMaybe<Array<CourseCreationResponseWhere>>;
  NOT?: InputMaybe<CourseCreationResponseWhere>;
  OR?: InputMaybe<Array<CourseCreationResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseCreationResponsesConnection = {
  __typename?: "CourseCreationResponsesConnection";
  edges: Array<CourseCreationResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseDeleteInput = {
  hasAssignmentAssignments?: InputMaybe<Array<CourseHasAssignmentAssignmentsDeleteFieldInput>>;
  hasCourseCategoriesCourseCategories?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesDeleteFieldInput>>;
  hasPagePages?: InputMaybe<Array<CourseHasPagePagesDeleteFieldInput>>;
  modules?: InputMaybe<Array<CourseModulesDeleteFieldInput>>;
  schoolsHasCourse?: InputMaybe<Array<CourseSchoolsHasCourseDeleteFieldInput>>;
  usersHasSchoolCourse?: InputMaybe<Array<CourseUsersHasSchoolCourseDeleteFieldInput>>;
};

export type CourseDisconnectInput = {
  hasAssignmentAssignments?: InputMaybe<Array<CourseHasAssignmentAssignmentsDisconnectFieldInput>>;
  hasCourseCategoriesCourseCategories?: InputMaybe<
    Array<CourseHasCourseCategoriesCourseCategoriesDisconnectFieldInput>
  >;
  hasPagePages?: InputMaybe<Array<CourseHasPagePagesDisconnectFieldInput>>;
  modules?: InputMaybe<Array<CourseModulesDisconnectFieldInput>>;
  schoolsHasCourse?: InputMaybe<Array<CourseSchoolsHasCourseDisconnectFieldInput>>;
  usersHasSchoolCourse?: InputMaybe<Array<CourseUsersHasSchoolCourseDisconnectFieldInput>>;
};

export type CourseEdge = {
  __typename?: "CourseEdge";
  cursor: Scalars["String"]["output"];
  node: Course;
};

export type CourseHasAssignmentAssignmentsAggregateInput = {
  AND?: InputMaybe<Array<CourseHasAssignmentAssignmentsAggregateInput>>;
  NOT?: InputMaybe<CourseHasAssignmentAssignmentsAggregateInput>;
  OR?: InputMaybe<Array<CourseHasAssignmentAssignmentsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<CourseHasAssignmentAssignmentsNodeAggregationWhereInput>;
};

export type CourseHasAssignmentAssignmentsConnectFieldInput = {
  connect?: InputMaybe<Array<AssignmentConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AssignmentConnectWhere>;
};

export type CourseHasAssignmentAssignmentsConnectOrCreateFieldInput = {
  onCreate: CourseHasAssignmentAssignmentsConnectOrCreateFieldInputOnCreate;
  where: AssignmentConnectOrCreateWhere;
};

export type CourseHasAssignmentAssignmentsConnectOrCreateFieldInputOnCreate = {
  node: AssignmentOnCreateInput;
};

export type CourseHasAssignmentAssignmentsConnection = {
  __typename?: "CourseHasAssignmentAssignmentsConnection";
  edges: Array<CourseHasAssignmentAssignmentsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseHasAssignmentAssignmentsConnectionSort = {
  node?: InputMaybe<AssignmentSort>;
};

export type CourseHasAssignmentAssignmentsConnectionWhere = {
  AND?: InputMaybe<Array<CourseHasAssignmentAssignmentsConnectionWhere>>;
  NOT?: InputMaybe<CourseHasAssignmentAssignmentsConnectionWhere>;
  OR?: InputMaybe<Array<CourseHasAssignmentAssignmentsConnectionWhere>>;
  node?: InputMaybe<AssignmentWhere>;
};

export type CourseHasAssignmentAssignmentsCreateFieldInput = {
  node: AssignmentCreateInput;
};

export type CourseHasAssignmentAssignmentsDeleteFieldInput = {
  delete?: InputMaybe<AssignmentDeleteInput>;
  where?: InputMaybe<CourseHasAssignmentAssignmentsConnectionWhere>;
};

export type CourseHasAssignmentAssignmentsDisconnectFieldInput = {
  disconnect?: InputMaybe<AssignmentDisconnectInput>;
  where?: InputMaybe<CourseHasAssignmentAssignmentsConnectionWhere>;
};

export type CourseHasAssignmentAssignmentsFieldInput = {
  connect?: InputMaybe<Array<CourseHasAssignmentAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseHasAssignmentAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseHasAssignmentAssignmentsCreateFieldInput>>;
};

export type CourseHasAssignmentAssignmentsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseHasAssignmentAssignmentsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseHasAssignmentAssignmentsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseHasAssignmentAssignmentsNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  allowed_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_group_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_unread_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  due_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  due_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  due_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grader_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  max_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  needs_grading_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  points_possible_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseHasAssignmentAssignmentsRelationship = {
  __typename?: "CourseHasAssignmentAssignmentsRelationship";
  cursor: Scalars["String"]["output"];
  node: Assignment;
};

export type CourseHasAssignmentAssignmentsUpdateConnectionInput = {
  node?: InputMaybe<AssignmentUpdateInput>;
};

export type CourseHasAssignmentAssignmentsUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseHasAssignmentAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseHasAssignmentAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseHasAssignmentAssignmentsCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseHasAssignmentAssignmentsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseHasAssignmentAssignmentsDisconnectFieldInput>>;
  update?: InputMaybe<CourseHasAssignmentAssignmentsUpdateConnectionInput>;
  where?: InputMaybe<CourseHasAssignmentAssignmentsConnectionWhere>;
};

export type CourseHasCourseCategoriesCourseCategoriesAggregateInput = {
  AND?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesAggregateInput>>;
  NOT?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesAggregateInput>;
  OR?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesNodeAggregationWhereInput>;
};

export type CourseHasCourseCategoriesCourseCategoriesConnectFieldInput = {
  connect?: InputMaybe<Array<CourseCategoryConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<CourseCategoryConnectWhere>;
};

export type CourseHasCourseCategoriesCourseCategoriesConnection = {
  __typename?: "CourseHasCourseCategoriesCourseCategoriesConnection";
  edges: Array<CourseHasCourseCategoriesCourseCategoriesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseHasCourseCategoriesCourseCategoriesConnectionSort = {
  node?: InputMaybe<CourseCategorySort>;
};

export type CourseHasCourseCategoriesCourseCategoriesConnectionWhere = {
  AND?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>>;
  NOT?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>;
  OR?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>>;
  node?: InputMaybe<CourseCategoryWhere>;
};

export type CourseHasCourseCategoriesCourseCategoriesCreateFieldInput = {
  node: CourseCategoryCreateInput;
};

export type CourseHasCourseCategoriesCourseCategoriesDeleteFieldInput = {
  delete?: InputMaybe<CourseCategoryDeleteInput>;
  where?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>;
};

export type CourseHasCourseCategoriesCourseCategoriesDisconnectFieldInput = {
  disconnect?: InputMaybe<CourseCategoryDisconnectInput>;
  where?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>;
};

export type CourseHasCourseCategoriesCourseCategoriesFieldInput = {
  connect?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesConnectFieldInput>>;
  create?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesCreateFieldInput>>;
};

export type CourseHasCourseCategoriesCourseCategoriesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseHasCourseCategoriesCourseCategoriesRelationship = {
  __typename?: "CourseHasCourseCategoriesCourseCategoriesRelationship";
  cursor: Scalars["String"]["output"];
  node: CourseCategory;
};

export type CourseHasCourseCategoriesCourseCategoriesUpdateConnectionInput = {
  node?: InputMaybe<CourseCategoryUpdateInput>;
};

export type CourseHasCourseCategoriesCourseCategoriesUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesConnectFieldInput>>;
  create?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesDisconnectFieldInput>>;
  update?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesUpdateConnectionInput>;
  where?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>;
};

export type CourseHasPagePagesAggregateInput = {
  AND?: InputMaybe<Array<CourseHasPagePagesAggregateInput>>;
  NOT?: InputMaybe<CourseHasPagePagesAggregateInput>;
  OR?: InputMaybe<Array<CourseHasPagePagesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<CourseHasPagePagesNodeAggregationWhereInput>;
};

export type CourseHasPagePagesConnectFieldInput = {
  connect?: InputMaybe<Array<PageConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<PageConnectWhere>;
};

export type CourseHasPagePagesConnectOrCreateFieldInput = {
  onCreate: CourseHasPagePagesConnectOrCreateFieldInputOnCreate;
  where: PageConnectOrCreateWhere;
};

export type CourseHasPagePagesConnectOrCreateFieldInputOnCreate = {
  node: PageOnCreateInput;
};

export type CourseHasPagePagesConnection = {
  __typename?: "CourseHasPagePagesConnection";
  edges: Array<CourseHasPagePagesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseHasPagePagesConnectionSort = {
  node?: InputMaybe<PageSort>;
};

export type CourseHasPagePagesConnectionWhere = {
  AND?: InputMaybe<Array<CourseHasPagePagesConnectionWhere>>;
  NOT?: InputMaybe<CourseHasPagePagesConnectionWhere>;
  OR?: InputMaybe<Array<CourseHasPagePagesConnectionWhere>>;
  node?: InputMaybe<PageWhere>;
};

export type CourseHasPagePagesCreateFieldInput = {
  node: PageCreateInput;
};

export type CourseHasPagePagesDeleteFieldInput = {
  delete?: InputMaybe<PageDeleteInput>;
  where?: InputMaybe<CourseHasPagePagesConnectionWhere>;
};

export type CourseHasPagePagesDisconnectFieldInput = {
  disconnect?: InputMaybe<PageDisconnectInput>;
  where?: InputMaybe<CourseHasPagePagesConnectionWhere>;
};

export type CourseHasPagePagesFieldInput = {
  connect?: InputMaybe<Array<CourseHasPagePagesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseHasPagePagesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseHasPagePagesCreateFieldInput>>;
};

export type CourseHasPagePagesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseHasPagePagesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseHasPagePagesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseHasPagePagesNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseHasPagePagesRelationship = {
  __typename?: "CourseHasPagePagesRelationship";
  cursor: Scalars["String"]["output"];
  node: Page;
};

export type CourseHasPagePagesUpdateConnectionInput = {
  node?: InputMaybe<PageUpdateInput>;
};

export type CourseHasPagePagesUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseHasPagePagesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseHasPagePagesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseHasPagePagesCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseHasPagePagesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseHasPagePagesDisconnectFieldInput>>;
  update?: InputMaybe<CourseHasPagePagesUpdateConnectionInput>;
  where?: InputMaybe<CourseHasPagePagesConnectionWhere>;
};

export type CourseModuleModulesAggregationSelection = {
  __typename?: "CourseModuleModulesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<CourseModuleModulesNodeAggregateSelection>;
};

export type CourseModuleModulesNodeAggregateSelection = {
  __typename?: "CourseModuleModulesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  module_name: StringAggregateSelection;
  order: FloatAggregateSelection;
  title: StringAggregateSelection;
};

export type CourseModulesAggregateInput = {
  AND?: InputMaybe<Array<CourseModulesAggregateInput>>;
  NOT?: InputMaybe<CourseModulesAggregateInput>;
  OR?: InputMaybe<Array<CourseModulesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<CourseModulesNodeAggregationWhereInput>;
};

export type CourseModulesConnectFieldInput = {
  connect?: InputMaybe<Array<ModuleConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ModuleConnectWhere>;
};

export type CourseModulesConnectOrCreateFieldInput = {
  onCreate: CourseModulesConnectOrCreateFieldInputOnCreate;
  where: ModuleConnectOrCreateWhere;
};

export type CourseModulesConnectOrCreateFieldInputOnCreate = {
  node: ModuleOnCreateInput;
};

export type CourseModulesConnection = {
  __typename?: "CourseModulesConnection";
  edges: Array<CourseModulesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseModulesConnectionSort = {
  node?: InputMaybe<ModuleSort>;
};

export type CourseModulesConnectionWhere = {
  AND?: InputMaybe<Array<CourseModulesConnectionWhere>>;
  NOT?: InputMaybe<CourseModulesConnectionWhere>;
  OR?: InputMaybe<Array<CourseModulesConnectionWhere>>;
  node?: InputMaybe<ModuleWhere>;
};

export type CourseModulesCreateFieldInput = {
  node: ModuleCreateInput;
};

export type CourseModulesDeleteFieldInput = {
  delete?: InputMaybe<ModuleDeleteInput>;
  where?: InputMaybe<CourseModulesConnectionWhere>;
};

export type CourseModulesDisconnectFieldInput = {
  disconnect?: InputMaybe<ModuleDisconnectInput>;
  where?: InputMaybe<CourseModulesConnectionWhere>;
};

export type CourseModulesFieldInput = {
  connect?: InputMaybe<Array<CourseModulesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseModulesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseModulesCreateFieldInput>>;
};

export type CourseModulesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseModulesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseModulesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseModulesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  module_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseModulesRelationship = {
  __typename?: "CourseModulesRelationship";
  cursor: Scalars["String"]["output"];
  node: Module;
};

export type CourseModulesUpdateConnectionInput = {
  node?: InputMaybe<ModuleUpdateInput>;
};

export type CourseModulesUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseModulesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<CourseModulesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<CourseModulesCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseModulesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseModulesDisconnectFieldInput>>;
  update?: InputMaybe<CourseModulesUpdateConnectionInput>;
  where?: InputMaybe<CourseModulesConnectionWhere>;
};

export type CourseOnCreateInput = {
  account_id?: InputMaybe<Scalars["Float"]["input"]>;
  apply_assignment_group_weights?: InputMaybe<Scalars["Boolean"]["input"]>;
  blueprint?: InputMaybe<Scalars["Boolean"]["input"]>;
  calendar_ics?: InputMaybe<Scalars["String"]["input"]>;
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  course_code?: InputMaybe<Scalars["String"]["input"]>;
  course_id?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  default_view?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  end_at?: InputMaybe<Scalars["String"]["input"]>;
  enrollment_term_id?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id?: InputMaybe<Scalars["Float"]["input"]>;
  hide_final_grades?: InputMaybe<Scalars["Boolean"]["input"]>;
  homeroom_course?: InputMaybe<Scalars["Boolean"]["input"]>;
  integration_id?: InputMaybe<Scalars["String"]["input"]>;
  is_public?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_public_to_auth_users?: InputMaybe<Scalars["Boolean"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards?: InputMaybe<Scalars["String"]["input"]>;
  lengthInWeeks?: InputMaybe<Scalars["Float"]["input"]>;
  license?: InputMaybe<Scalars["String"]["input"]>;
  lti_id?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Array<Scalars["String"]["input"]>>;
  original_name?: InputMaybe<Scalars["String"]["input"]>;
  period_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  periods?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  public_syllabus?: InputMaybe<Scalars["Boolean"]["input"]>;
  public_syllabus_to_auth?: InputMaybe<Scalars["Boolean"]["input"]>;
  restrict_enrollments_to_course_dates?: InputMaybe<Scalars["Boolean"]["input"]>;
  root_account_id?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id?: InputMaybe<Scalars["String"]["input"]>;
  sis_import_id?: InputMaybe<Scalars["Float"]["input"]>;
  start_at?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  storage_quota_mb?: InputMaybe<Scalars["Float"]["input"]>;
  template?: InputMaybe<Scalars["Boolean"]["input"]>;
  time_zone?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state?: InputMaybe<Scalars["String"]["input"]>;
};

export type CourseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseSort objects to sort Courses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseSort>>;
};

export type CoursePageHasPagePagesAggregationSelection = {
  __typename?: "CoursePageHasPagePagesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<CoursePageHasPagePagesNodeAggregateSelection>;
};

export type CoursePageHasPagePagesNodeAggregateSelection = {
  __typename?: "CoursePageHasPagePagesNodeAggregateSelection";
  _empty: StringAggregateSelection;
  assignee_mode: StringAggregateSelection;
  body: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  description: StringAggregateSelection;
  editing_roles: StringAggregateSelection;
  html_url: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  last_edited_by_anonymous_id: StringAggregateSelection;
  last_edited_by_avatar_image_url: StringAggregateSelection;
  last_edited_by_display_name: StringAggregateSelection;
  last_edited_by_html_url: StringAggregateSelection;
  last_edited_by_id: FloatAggregateSelection;
  page_id: FloatAggregateSelection;
  state: StringAggregateSelection;
  text: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_at: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type CourseRelationInput = {
  hasAssignmentAssignments?: InputMaybe<Array<CourseHasAssignmentAssignmentsCreateFieldInput>>;
  hasCourseCategoriesCourseCategories?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesCreateFieldInput>>;
  hasPagePages?: InputMaybe<Array<CourseHasPagePagesCreateFieldInput>>;
  modules?: InputMaybe<Array<CourseModulesCreateFieldInput>>;
  schoolsHasCourse?: InputMaybe<Array<CourseSchoolsHasCourseCreateFieldInput>>;
  usersHasSchoolCourse?: InputMaybe<Array<CourseUsersHasSchoolCourseCreateFieldInput>>;
};

export type CourseResponse = {
  __typename?: "CourseResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<Course>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type CourseResponseAggregateSelection = {
  __typename?: "CourseResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type CourseResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseResponseEdge = {
  __typename?: "CourseResponseEdge";
  cursor: Scalars["String"]["output"];
  node: CourseResponse;
};

export type CourseResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CourseResponseSort objects to sort CourseResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CourseResponseSort>>;
};

/** Fields to sort CourseResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseResponseSort object. */
export type CourseResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type CourseResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseResponseWhere = {
  AND?: InputMaybe<Array<CourseResponseWhere>>;
  NOT?: InputMaybe<CourseResponseWhere>;
  OR?: InputMaybe<Array<CourseResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type CourseResponsesConnection = {
  __typename?: "CourseResponsesConnection";
  edges: Array<CourseResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseSchoolSchoolsHasCourseAggregationSelection = {
  __typename?: "CourseSchoolSchoolsHasCourseAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<CourseSchoolSchoolsHasCourseNodeAggregateSelection>;
};

export type CourseSchoolSchoolsHasCourseNodeAggregateSelection = {
  __typename?: "CourseSchoolSchoolsHasCourseNodeAggregateSelection";
  icon: StringAggregateSelection;
  id: IdAggregateSelection;
  providerName: StringAggregateSelection;
  teamName: StringAggregateSelection;
};

export type CourseSchoolsHasCourseAggregateInput = {
  AND?: InputMaybe<Array<CourseSchoolsHasCourseAggregateInput>>;
  NOT?: InputMaybe<CourseSchoolsHasCourseAggregateInput>;
  OR?: InputMaybe<Array<CourseSchoolsHasCourseAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<CourseSchoolsHasCourseNodeAggregationWhereInput>;
};

export type CourseSchoolsHasCourseConnectFieldInput = {
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<SchoolConnectWhere>;
};

export type CourseSchoolsHasCourseConnection = {
  __typename?: "CourseSchoolsHasCourseConnection";
  edges: Array<CourseSchoolsHasCourseRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseSchoolsHasCourseConnectionSort = {
  node?: InputMaybe<SchoolSort>;
};

export type CourseSchoolsHasCourseConnectionWhere = {
  AND?: InputMaybe<Array<CourseSchoolsHasCourseConnectionWhere>>;
  NOT?: InputMaybe<CourseSchoolsHasCourseConnectionWhere>;
  OR?: InputMaybe<Array<CourseSchoolsHasCourseConnectionWhere>>;
  node?: InputMaybe<SchoolWhere>;
};

export type CourseSchoolsHasCourseCreateFieldInput = {
  node: SchoolCreateInput;
};

export type CourseSchoolsHasCourseDeleteFieldInput = {
  where?: InputMaybe<CourseSchoolsHasCourseConnectionWhere>;
};

export type CourseSchoolsHasCourseDisconnectFieldInput = {
  where?: InputMaybe<CourseSchoolsHasCourseConnectionWhere>;
};

export type CourseSchoolsHasCourseFieldInput = {
  connect?: InputMaybe<Array<CourseSchoolsHasCourseConnectFieldInput>>;
  create?: InputMaybe<Array<CourseSchoolsHasCourseCreateFieldInput>>;
};

export type CourseSchoolsHasCourseNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseSchoolsHasCourseNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseSchoolsHasCourseNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseSchoolsHasCourseNodeAggregationWhereInput>>;
  icon_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  icon_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  icon_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  icon_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  icon_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  icon_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  icon_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  icon_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  icon_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  icon_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  icon_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  providerName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  providerName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  teamName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  teamName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseSchoolsHasCourseRelationship = {
  __typename?: "CourseSchoolsHasCourseRelationship";
  cursor: Scalars["String"]["output"];
  node: School;
};

export type CourseSchoolsHasCourseUpdateConnectionInput = {
  node?: InputMaybe<SchoolUpdateInput>;
};

export type CourseSchoolsHasCourseUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseSchoolsHasCourseConnectFieldInput>>;
  create?: InputMaybe<Array<CourseSchoolsHasCourseCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseSchoolsHasCourseDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseSchoolsHasCourseDisconnectFieldInput>>;
  update?: InputMaybe<CourseSchoolsHasCourseUpdateConnectionInput>;
  where?: InputMaybe<CourseSchoolsHasCourseConnectionWhere>;
};

/** Fields to sort Courses by. The order in which sorts are applied is not guaranteed when specifying many fields in one CourseSort object. */
export type CourseSort = {
  account_id?: InputMaybe<SortDirection>;
  apply_assignment_group_weights?: InputMaybe<SortDirection>;
  blueprint?: InputMaybe<SortDirection>;
  calendar_ics?: InputMaybe<SortDirection>;
  courseId?: InputMaybe<SortDirection>;
  course_code?: InputMaybe<SortDirection>;
  course_id?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  created_at?: InputMaybe<SortDirection>;
  created_date?: InputMaybe<SortDirection>;
  default_view?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  end_at?: InputMaybe<SortDirection>;
  enrollment_term_id?: InputMaybe<SortDirection>;
  grading_standard_id?: InputMaybe<SortDirection>;
  hide_final_grades?: InputMaybe<SortDirection>;
  homeroom_course?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  integration_id?: InputMaybe<SortDirection>;
  is_public?: InputMaybe<SortDirection>;
  is_public_to_auth_users?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  learningStandards?: InputMaybe<SortDirection>;
  lengthInWeeks?: InputMaybe<SortDirection>;
  license?: InputMaybe<SortDirection>;
  lti_id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  original_name?: InputMaybe<SortDirection>;
  public_syllabus?: InputMaybe<SortDirection>;
  public_syllabus_to_auth?: InputMaybe<SortDirection>;
  restrict_enrollments_to_course_dates?: InputMaybe<SortDirection>;
  root_account_id?: InputMaybe<SortDirection>;
  sis_course_id?: InputMaybe<SortDirection>;
  sis_import_id?: InputMaybe<SortDirection>;
  start_at?: InputMaybe<SortDirection>;
  state?: InputMaybe<SortDirection>;
  storage_quota_mb?: InputMaybe<SortDirection>;
  template?: InputMaybe<SortDirection>;
  time_zone?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  updated_date?: InputMaybe<SortDirection>;
  uuid?: InputMaybe<SortDirection>;
  workflow_state?: InputMaybe<SortDirection>;
};

export type CourseUniqueWhere = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type CourseUpdateInput = {
  account_id?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  apply_assignment_group_weights?: InputMaybe<Scalars["Boolean"]["input"]>;
  blueprint?: InputMaybe<Scalars["Boolean"]["input"]>;
  calendar_ics?: InputMaybe<Scalars["String"]["input"]>;
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  course_code?: InputMaybe<Scalars["String"]["input"]>;
  course_id?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  default_view?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  end_at?: InputMaybe<Scalars["String"]["input"]>;
  enrollment_term_id?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  hasAssignmentAssignments?: InputMaybe<Array<CourseHasAssignmentAssignmentsUpdateFieldInput>>;
  hasCourseCategoriesCourseCategories?: InputMaybe<Array<CourseHasCourseCategoriesCourseCategoriesUpdateFieldInput>>;
  hasPagePages?: InputMaybe<Array<CourseHasPagePagesUpdateFieldInput>>;
  hide_final_grades?: InputMaybe<Scalars["Boolean"]["input"]>;
  homeroom_course?: InputMaybe<Scalars["Boolean"]["input"]>;
  integration_id?: InputMaybe<Scalars["String"]["input"]>;
  is_public?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_public_to_auth_users?: InputMaybe<Scalars["Boolean"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards?: InputMaybe<Scalars["String"]["input"]>;
  lengthInWeeks?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  license?: InputMaybe<Scalars["String"]["input"]>;
  lti_id?: InputMaybe<Scalars["String"]["input"]>;
  modules?: InputMaybe<Array<CourseModulesUpdateFieldInput>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Array<Scalars["String"]["input"]>>;
  objectives_POP?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_PUSH?: InputMaybe<Array<Scalars["String"]["input"]>>;
  original_name?: InputMaybe<Scalars["String"]["input"]>;
  period_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  period_ids_POP?: InputMaybe<Scalars["Int"]["input"]>;
  period_ids_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  periods?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  periods_POP?: InputMaybe<Scalars["Int"]["input"]>;
  periods_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  public_syllabus?: InputMaybe<Scalars["Boolean"]["input"]>;
  public_syllabus_to_auth?: InputMaybe<Scalars["Boolean"]["input"]>;
  restrict_enrollments_to_course_dates?: InputMaybe<Scalars["Boolean"]["input"]>;
  root_account_id?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  schoolsHasCourse?: InputMaybe<Array<CourseSchoolsHasCourseUpdateFieldInput>>;
  sis_course_id?: InputMaybe<Scalars["String"]["input"]>;
  sis_import_id?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  storage_quota_mb?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  template?: InputMaybe<Scalars["Boolean"]["input"]>;
  time_zone?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSchoolCourse?: InputMaybe<Array<CourseUsersHasSchoolCourseUpdateFieldInput>>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state?: InputMaybe<Scalars["String"]["input"]>;
};

export type CourseUserUsersHasSchoolCourseAggregationSelection = {
  __typename?: "CourseUserUsersHasSchoolCourseAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<CourseUserUsersHasSchoolCourseNodeAggregateSelection>;
};

export type CourseUserUsersHasSchoolCourseNodeAggregateSelection = {
  __typename?: "CourseUserUsersHasSchoolCourseNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type CourseUsersHasSchoolCourseAggregateInput = {
  AND?: InputMaybe<Array<CourseUsersHasSchoolCourseAggregateInput>>;
  NOT?: InputMaybe<CourseUsersHasSchoolCourseAggregateInput>;
  OR?: InputMaybe<Array<CourseUsersHasSchoolCourseAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<CourseUsersHasSchoolCourseNodeAggregationWhereInput>;
};

export type CourseUsersHasSchoolCourseConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type CourseUsersHasSchoolCourseConnection = {
  __typename?: "CourseUsersHasSchoolCourseConnection";
  edges: Array<CourseUsersHasSchoolCourseRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CourseUsersHasSchoolCourseConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type CourseUsersHasSchoolCourseConnectionWhere = {
  AND?: InputMaybe<Array<CourseUsersHasSchoolCourseConnectionWhere>>;
  NOT?: InputMaybe<CourseUsersHasSchoolCourseConnectionWhere>;
  OR?: InputMaybe<Array<CourseUsersHasSchoolCourseConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type CourseUsersHasSchoolCourseCreateFieldInput = {
  node: UserCreateInput;
};

export type CourseUsersHasSchoolCourseDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<CourseUsersHasSchoolCourseConnectionWhere>;
};

export type CourseUsersHasSchoolCourseDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<CourseUsersHasSchoolCourseConnectionWhere>;
};

export type CourseUsersHasSchoolCourseFieldInput = {
  connect?: InputMaybe<Array<CourseUsersHasSchoolCourseConnectFieldInput>>;
  create?: InputMaybe<Array<CourseUsersHasSchoolCourseCreateFieldInput>>;
};

export type CourseUsersHasSchoolCourseNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<CourseUsersHasSchoolCourseNodeAggregationWhereInput>>;
  NOT?: InputMaybe<CourseUsersHasSchoolCourseNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<CourseUsersHasSchoolCourseNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CourseUsersHasSchoolCourseRelationship = {
  __typename?: "CourseUsersHasSchoolCourseRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type CourseUsersHasSchoolCourseUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type CourseUsersHasSchoolCourseUpdateFieldInput = {
  connect?: InputMaybe<Array<CourseUsersHasSchoolCourseConnectFieldInput>>;
  create?: InputMaybe<Array<CourseUsersHasSchoolCourseCreateFieldInput>>;
  delete?: InputMaybe<Array<CourseUsersHasSchoolCourseDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<CourseUsersHasSchoolCourseDisconnectFieldInput>>;
  update?: InputMaybe<CourseUsersHasSchoolCourseUpdateConnectionInput>;
  where?: InputMaybe<CourseUsersHasSchoolCourseConnectionWhere>;
};

export type CourseWhere = {
  AND?: InputMaybe<Array<CourseWhere>>;
  NOT?: InputMaybe<CourseWhere>;
  OR?: InputMaybe<Array<CourseWhere>>;
  account_id?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  account_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  apply_assignment_group_weights?: InputMaybe<Scalars["Boolean"]["input"]>;
  blueprint?: InputMaybe<Scalars["Boolean"]["input"]>;
  calendar_ics?: InputMaybe<Scalars["String"]["input"]>;
  calendar_ics_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  calendar_ics_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  calendar_ics_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  calendar_ics_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  courseId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  courseId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  courseId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  courseId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  course_code?: InputMaybe<Scalars["String"]["input"]>;
  course_code_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  course_code_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  course_code_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  course_code_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  course_id?: InputMaybe<Scalars["String"]["input"]>;
  course_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  course_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  course_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  course_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  created_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  created_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  created_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  created_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  created_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  default_view?: InputMaybe<Scalars["String"]["input"]>;
  default_view_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  default_view_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  default_view_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  default_view_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  end_at?: InputMaybe<Scalars["String"]["input"]>;
  end_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  end_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  end_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  end_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  enrollment_term_id?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  enrollment_term_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  grading_standard_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  hasAssignmentAssignmentsAggregate?: InputMaybe<CourseHasAssignmentAssignmentsAggregateInput>;
  /** Return Courses where all of the related CourseHasAssignmentAssignmentsConnections match this filter */
  hasAssignmentAssignmentsConnection_ALL?: InputMaybe<CourseHasAssignmentAssignmentsConnectionWhere>;
  /** Return Courses where none of the related CourseHasAssignmentAssignmentsConnections match this filter */
  hasAssignmentAssignmentsConnection_NONE?: InputMaybe<CourseHasAssignmentAssignmentsConnectionWhere>;
  /** Return Courses where one of the related CourseHasAssignmentAssignmentsConnections match this filter */
  hasAssignmentAssignmentsConnection_SINGLE?: InputMaybe<CourseHasAssignmentAssignmentsConnectionWhere>;
  /** Return Courses where some of the related CourseHasAssignmentAssignmentsConnections match this filter */
  hasAssignmentAssignmentsConnection_SOME?: InputMaybe<CourseHasAssignmentAssignmentsConnectionWhere>;
  /** Return Courses where all of the related Assignments match this filter */
  hasAssignmentAssignments_ALL?: InputMaybe<AssignmentWhere>;
  /** Return Courses where none of the related Assignments match this filter */
  hasAssignmentAssignments_NONE?: InputMaybe<AssignmentWhere>;
  /** Return Courses where one of the related Assignments match this filter */
  hasAssignmentAssignments_SINGLE?: InputMaybe<AssignmentWhere>;
  /** Return Courses where some of the related Assignments match this filter */
  hasAssignmentAssignments_SOME?: InputMaybe<AssignmentWhere>;
  hasCourseCategoriesCourseCategoriesAggregate?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesAggregateInput>;
  /** Return Courses where all of the related CourseHasCourseCategoriesCourseCategoriesConnections match this filter */
  hasCourseCategoriesCourseCategoriesConnection_ALL?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>;
  /** Return Courses where none of the related CourseHasCourseCategoriesCourseCategoriesConnections match this filter */
  hasCourseCategoriesCourseCategoriesConnection_NONE?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>;
  /** Return Courses where one of the related CourseHasCourseCategoriesCourseCategoriesConnections match this filter */
  hasCourseCategoriesCourseCategoriesConnection_SINGLE?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>;
  /** Return Courses where some of the related CourseHasCourseCategoriesCourseCategoriesConnections match this filter */
  hasCourseCategoriesCourseCategoriesConnection_SOME?: InputMaybe<CourseHasCourseCategoriesCourseCategoriesConnectionWhere>;
  /** Return Courses where all of the related CourseCategories match this filter */
  hasCourseCategoriesCourseCategories_ALL?: InputMaybe<CourseCategoryWhere>;
  /** Return Courses where none of the related CourseCategories match this filter */
  hasCourseCategoriesCourseCategories_NONE?: InputMaybe<CourseCategoryWhere>;
  /** Return Courses where one of the related CourseCategories match this filter */
  hasCourseCategoriesCourseCategories_SINGLE?: InputMaybe<CourseCategoryWhere>;
  /** Return Courses where some of the related CourseCategories match this filter */
  hasCourseCategoriesCourseCategories_SOME?: InputMaybe<CourseCategoryWhere>;
  hasPagePagesAggregate?: InputMaybe<CourseHasPagePagesAggregateInput>;
  /** Return Courses where all of the related CourseHasPagePagesConnections match this filter */
  hasPagePagesConnection_ALL?: InputMaybe<CourseHasPagePagesConnectionWhere>;
  /** Return Courses where none of the related CourseHasPagePagesConnections match this filter */
  hasPagePagesConnection_NONE?: InputMaybe<CourseHasPagePagesConnectionWhere>;
  /** Return Courses where one of the related CourseHasPagePagesConnections match this filter */
  hasPagePagesConnection_SINGLE?: InputMaybe<CourseHasPagePagesConnectionWhere>;
  /** Return Courses where some of the related CourseHasPagePagesConnections match this filter */
  hasPagePagesConnection_SOME?: InputMaybe<CourseHasPagePagesConnectionWhere>;
  /** Return Courses where all of the related Pages match this filter */
  hasPagePages_ALL?: InputMaybe<PageWhere>;
  /** Return Courses where none of the related Pages match this filter */
  hasPagePages_NONE?: InputMaybe<PageWhere>;
  /** Return Courses where one of the related Pages match this filter */
  hasPagePages_SINGLE?: InputMaybe<PageWhere>;
  /** Return Courses where some of the related Pages match this filter */
  hasPagePages_SOME?: InputMaybe<PageWhere>;
  hide_final_grades?: InputMaybe<Scalars["Boolean"]["input"]>;
  homeroom_course?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  integration_id?: InputMaybe<Scalars["String"]["input"]>;
  integration_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  integration_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  integration_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  integration_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  is_public?: InputMaybe<Scalars["Boolean"]["input"]>;
  is_public_to_auth_users?: InputMaybe<Scalars["Boolean"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards?: InputMaybe<Scalars["String"]["input"]>;
  learningStandards_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  learningStandards_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  learningStandards_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  learningStandards_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lengthInWeeks?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  lengthInWeeks_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license?: InputMaybe<Scalars["String"]["input"]>;
  license_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  license_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  license_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  license_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lti_id?: InputMaybe<Scalars["String"]["input"]>;
  lti_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  lti_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lti_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  lti_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  modulesAggregate?: InputMaybe<CourseModulesAggregateInput>;
  /** Return Courses where all of the related CourseModulesConnections match this filter */
  modulesConnection_ALL?: InputMaybe<CourseModulesConnectionWhere>;
  /** Return Courses where none of the related CourseModulesConnections match this filter */
  modulesConnection_NONE?: InputMaybe<CourseModulesConnectionWhere>;
  /** Return Courses where one of the related CourseModulesConnections match this filter */
  modulesConnection_SINGLE?: InputMaybe<CourseModulesConnectionWhere>;
  /** Return Courses where some of the related CourseModulesConnections match this filter */
  modulesConnection_SOME?: InputMaybe<CourseModulesConnectionWhere>;
  /** Return Courses where all of the related Modules match this filter */
  modules_ALL?: InputMaybe<ModuleWhere>;
  /** Return Courses where none of the related Modules match this filter */
  modules_NONE?: InputMaybe<ModuleWhere>;
  /** Return Courses where one of the related Modules match this filter */
  modules_SINGLE?: InputMaybe<ModuleWhere>;
  /** Return Courses where some of the related Modules match this filter */
  modules_SOME?: InputMaybe<ModuleWhere>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Array<Scalars["String"]["input"]>>;
  objectives_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  original_name?: InputMaybe<Scalars["String"]["input"]>;
  original_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  original_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  original_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  original_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  period_ids?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  period_ids_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  periods?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  periods_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  public_syllabus?: InputMaybe<Scalars["Boolean"]["input"]>;
  public_syllabus_to_auth?: InputMaybe<Scalars["Boolean"]["input"]>;
  restrict_enrollments_to_course_dates?: InputMaybe<Scalars["Boolean"]["input"]>;
  root_account_id?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  root_account_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  schoolsHasCourseAggregate?: InputMaybe<CourseSchoolsHasCourseAggregateInput>;
  /** Return Courses where all of the related CourseSchoolsHasCourseConnections match this filter */
  schoolsHasCourseConnection_ALL?: InputMaybe<CourseSchoolsHasCourseConnectionWhere>;
  /** Return Courses where none of the related CourseSchoolsHasCourseConnections match this filter */
  schoolsHasCourseConnection_NONE?: InputMaybe<CourseSchoolsHasCourseConnectionWhere>;
  /** Return Courses where one of the related CourseSchoolsHasCourseConnections match this filter */
  schoolsHasCourseConnection_SINGLE?: InputMaybe<CourseSchoolsHasCourseConnectionWhere>;
  /** Return Courses where some of the related CourseSchoolsHasCourseConnections match this filter */
  schoolsHasCourseConnection_SOME?: InputMaybe<CourseSchoolsHasCourseConnectionWhere>;
  /** Return Courses where all of the related Schools match this filter */
  schoolsHasCourse_ALL?: InputMaybe<SchoolWhere>;
  /** Return Courses where none of the related Schools match this filter */
  schoolsHasCourse_NONE?: InputMaybe<SchoolWhere>;
  /** Return Courses where one of the related Schools match this filter */
  schoolsHasCourse_SINGLE?: InputMaybe<SchoolWhere>;
  /** Return Courses where some of the related Schools match this filter */
  schoolsHasCourse_SOME?: InputMaybe<SchoolWhere>;
  sis_course_id?: InputMaybe<Scalars["String"]["input"]>;
  sis_course_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sis_course_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sis_course_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sis_course_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sis_import_id?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  sis_import_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at?: InputMaybe<Scalars["String"]["input"]>;
  start_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  start_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  start_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  start_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  state_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  state_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  state_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  state_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  storage_quota_mb?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  storage_quota_mb_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  template?: InputMaybe<Scalars["Boolean"]["input"]>;
  time_zone?: InputMaybe<Scalars["String"]["input"]>;
  time_zone_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  time_zone_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  time_zone_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  time_zone_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  updated_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  updated_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updated_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  updated_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSchoolCourseAggregate?: InputMaybe<CourseUsersHasSchoolCourseAggregateInput>;
  /** Return Courses where all of the related CourseUsersHasSchoolCourseConnections match this filter */
  usersHasSchoolCourseConnection_ALL?: InputMaybe<CourseUsersHasSchoolCourseConnectionWhere>;
  /** Return Courses where none of the related CourseUsersHasSchoolCourseConnections match this filter */
  usersHasSchoolCourseConnection_NONE?: InputMaybe<CourseUsersHasSchoolCourseConnectionWhere>;
  /** Return Courses where one of the related CourseUsersHasSchoolCourseConnections match this filter */
  usersHasSchoolCourseConnection_SINGLE?: InputMaybe<CourseUsersHasSchoolCourseConnectionWhere>;
  /** Return Courses where some of the related CourseUsersHasSchoolCourseConnections match this filter */
  usersHasSchoolCourseConnection_SOME?: InputMaybe<CourseUsersHasSchoolCourseConnectionWhere>;
  /** Return Courses where all of the related Users match this filter */
  usersHasSchoolCourse_ALL?: InputMaybe<UserWhere>;
  /** Return Courses where none of the related Users match this filter */
  usersHasSchoolCourse_NONE?: InputMaybe<UserWhere>;
  /** Return Courses where one of the related Users match this filter */
  usersHasSchoolCourse_SINGLE?: InputMaybe<UserWhere>;
  /** Return Courses where some of the related Users match this filter */
  usersHasSchoolCourse_SOME?: InputMaybe<UserWhere>;
  uuid?: InputMaybe<Scalars["String"]["input"]>;
  uuid_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  uuid_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  uuid_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  uuid_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  workflow_state_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  workflow_state_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type CoursesConnection = {
  __typename?: "CoursesConnection";
  edges: Array<CourseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type CreateAchievementsMutationResponse = {
  __typename?: "CreateAchievementsMutationResponse";
  achievements: Array<Achievements>;
  info: CreateInfo;
};

export type CreateActiveTracksMutationResponse = {
  __typename?: "CreateActiveTracksMutationResponse";
  activeTracks: Array<ActiveTrack>;
  info: CreateInfo;
};

export type CreateAddAssignmentResponsesMutationResponse = {
  __typename?: "CreateAddAssignmentResponsesMutationResponse";
  addAssignmentResponses: Array<AddAssignmentResponse>;
  info: CreateInfo;
};

export type CreateAddContentModuleResponsesMutationResponse = {
  __typename?: "CreateAddContentModuleResponsesMutationResponse";
  addContentModuleResponses: Array<AddContentModuleResponse>;
  info: CreateInfo;
};

export type CreateAspectDetailsTypesMutationResponse = {
  __typename?: "CreateAspectDetailsTypesMutationResponse";
  aspectDetailsTypes: Array<AspectDetailsType>;
  info: CreateInfo;
};

export type CreateAssignedQuestsMutationResponse = {
  __typename?: "CreateAssignedQuestsMutationResponse";
  assignedQuests: Array<AssignedQuest>;
  info: CreateInfo;
};

export type CreateAssignmentInput = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  dueDate: Scalars["String"]["input"];
  parentResourceId: Scalars["ID"]["input"];
  title: Scalars["String"]["input"];
};

export type CreateAssignmentResponsesMutationResponse = {
  __typename?: "CreateAssignmentResponsesMutationResponse";
  assignmentResponses: Array<AssignmentResponse>;
  info: CreateInfo;
};

export type CreateAssignmentsMutationResponse = {
  __typename?: "CreateAssignmentsMutationResponse";
  assignments: Array<Assignment>;
  info: CreateInfo;
};

export type CreateAttributesMutationResponse = {
  __typename?: "CreateAttributesMutationResponse";
  attributes: Array<Attribute>;
  info: CreateInfo;
};

export type CreateAudioToggleUserResponsesMutationResponse = {
  __typename?: "CreateAudioToggleUserResponsesMutationResponse";
  audioToggleUserResponses: Array<AudioToggleUserResponse>;
  info: CreateInfo;
};

export type CreateAuthPayloadResponsesMutationResponse = {
  __typename?: "CreateAuthPayloadResponsesMutationResponse";
  authPayloadResponses: Array<AuthPayloadResponse>;
  info: CreateInfo;
};

export type CreateAuthPayloadUserPreferencesMutationResponse = {
  __typename?: "CreateAuthPayloadUserPreferencesMutationResponse";
  authPayloadUserPreferences: Array<AuthPayloadUserPreferences>;
  info: CreateInfo;
};

export type CreateAuthPayloadUsersMutationResponse = {
  __typename?: "CreateAuthPayloadUsersMutationResponse";
  authPayloadUsers: Array<AuthPayloadUser>;
  info: CreateInfo;
};

export type CreateCareerHeadersMutationResponse = {
  __typename?: "CreateCareerHeadersMutationResponse";
  careerHeaders: Array<CareerHeader>;
  info: CreateInfo;
};

export type CreateCareerTopicResponsesMutationResponse = {
  __typename?: "CreateCareerTopicResponsesMutationResponse";
  careerTopicResponses: Array<CareerTopicResponse>;
  info: CreateInfo;
};

export type CreateCareersMutationResponse = {
  __typename?: "CreateCareersMutationResponse";
  careers: Array<Career>;
  info: CreateInfo;
};

export type CreateChallengesMutationResponse = {
  __typename?: "CreateChallengesMutationResponse";
  challenges: Array<Challenge>;
  info: CreateInfo;
};

export type CreateChaptersMutationResponse = {
  __typename?: "CreateChaptersMutationResponse";
  chapters: Array<Chapter>;
  info: CreateInfo;
};

export type CreateChatsMutationResponse = {
  __typename?: "CreateChatsMutationResponse";
  chats: Array<Chat>;
  info: CreateInfo;
};

export type CreateChildInfosMutationResponse = {
  __typename?: "CreateChildInfosMutationResponse";
  childInfos: Array<ChildInfo>;
  info: CreateInfo;
};

export type CreateCoreValuesMutationResponse = {
  __typename?: "CreateCoreValuesMutationResponse";
  coreValues: Array<CoreValue>;
  info: CreateInfo;
};

export type CreateCourseCategoriesMutationResponse = {
  __typename?: "CreateCourseCategoriesMutationResponse";
  courseCategories: Array<CourseCategory>;
  info: CreateInfo;
};

export type CreateCourseCreationResponsesMutationResponse = {
  __typename?: "CreateCourseCreationResponsesMutationResponse";
  courseCreationResponses: Array<CourseCreationResponse>;
  info: CreateInfo;
};

export type CreateCourseInput = {
  learningStandards?: InputMaybe<Scalars["String"]["input"]>;
  lengthInWeeks?: InputMaybe<Scalars["Float"]["input"]>;
  objectives?: InputMaybe<Array<Scalars["String"]["input"]>>;
  parentSchoolId: Scalars["ID"]["input"];
  title: Scalars["String"]["input"];
};

export type CreateCourseResponsesMutationResponse = {
  __typename?: "CreateCourseResponsesMutationResponse";
  courseResponses: Array<CourseResponse>;
  info: CreateInfo;
};

export type CreateCoursesMutationResponse = {
  __typename?: "CreateCoursesMutationResponse";
  courses: Array<Course>;
  info: CreateInfo;
};

export type CreateCurriculaMutationResponse = {
  __typename?: "CreateCurriculaMutationResponse";
  curricula: Array<Curriculum>;
  info: CreateInfo;
};

export type CreateDailyCompletedTodosCountsMutationResponse = {
  __typename?: "CreateDailyCompletedTodosCountsMutationResponse";
  dailyCompletedTodosCounts: Array<DailyCompletedTodosCount>;
  info: CreateInfo;
};

export type CreateDailyExerciseTotalsMutationResponse = {
  __typename?: "CreateDailyExerciseTotalsMutationResponse";
  dailyExerciseTotals: Array<DailyExerciseTotals>;
  info: CreateInfo;
};

export type CreateDailyNutritionTotalsMutationResponse = {
  __typename?: "CreateDailyNutritionTotalsMutationResponse";
  dailyNutritionTotals: Array<DailyNutritionTotals>;
  info: CreateInfo;
};

export type CreateDailySummariesMutationResponse = {
  __typename?: "CreateDailySummariesMutationResponse";
  dailySummaries: Array<DailySummary>;
  info: CreateInfo;
};

export type CreateDeleteContentAssignmentResponsesMutationResponse = {
  __typename?: "CreateDeleteContentAssignmentResponsesMutationResponse";
  deleteContentAssignmentResponses: Array<DeleteContentAssignmentResponse>;
  info: CreateInfo;
};

export type CreateDeleteContentAssignmentVariablesMutationResponse = {
  __typename?: "CreateDeleteContentAssignmentVariablesMutationResponse";
  deleteContentAssignmentVariables: Array<DeleteContentAssignmentVariables>;
  info: CreateInfo;
};

export type CreateDeleteContentModuleResponsesMutationResponse = {
  __typename?: "CreateDeleteContentModuleResponsesMutationResponse";
  deleteContentModuleResponses: Array<DeleteContentModuleResponse>;
  info: CreateInfo;
};

export type CreateDeleteContentModuleVariablesMutationResponse = {
  __typename?: "CreateDeleteContentModuleVariablesMutationResponse";
  deleteContentModuleVariables: Array<DeleteContentModuleVariables>;
  info: CreateInfo;
};

export type CreateDeleteCourseResponsesMutationResponse = {
  __typename?: "CreateDeleteCourseResponsesMutationResponse";
  deleteCourseResponses: Array<DeleteCourseResponse>;
  info: CreateInfo;
};

export type CreateDeviceSessionsMutationResponse = {
  __typename?: "CreateDeviceSessionsMutationResponse";
  deviceSessions: Array<DeviceSession>;
  info: CreateInfo;
};

export type CreateEmotionalStatesMutationResponse = {
  __typename?: "CreateEmotionalStatesMutationResponse";
  emotionalStates: Array<EmotionalState>;
  info: CreateInfo;
};

export type CreateEventsMutationResponse = {
  __typename?: "CreateEventsMutationResponse";
  events: Array<Event>;
  info: CreateInfo;
};

export type CreateExportCsvResponsesMutationResponse = {
  __typename?: "CreateExportCsvResponsesMutationResponse";
  exportCsvResponses: Array<ExportCsvResponse>;
  info: CreateInfo;
};

export type CreateFeedbacksMutationResponse = {
  __typename?: "CreateFeedbacksMutationResponse";
  feedbacks: Array<Feedback>;
  info: CreateInfo;
};

export type CreateFieldsMutationResponse = {
  __typename?: "CreateFieldsMutationResponse";
  fields: Array<Field>;
  info: CreateInfo;
};

export type CreateFilesMutationResponse = {
  __typename?: "CreateFilesMutationResponse";
  files: Array<File>;
  info: CreateInfo;
};

export type CreateFoodItemsMutationResponse = {
  __typename?: "CreateFoodItemsMutationResponse";
  foodItems: Array<FoodItem>;
  info: CreateInfo;
};

export type CreateFoodsMutationResponse = {
  __typename?: "CreateFoodsMutationResponse";
  foods: Array<Food>;
  info: CreateInfo;
};

export type CreateGamesMutationResponse = {
  __typename?: "CreateGamesMutationResponse";
  games: Array<Game>;
  info: CreateInfo;
};

export type CreateGeneratedsMutationResponse = {
  __typename?: "CreateGeneratedsMutationResponse";
  generateds: Array<Generated>;
  info: CreateInfo;
};

export type CreateGetAssignmentObjectiveResponsesMutationResponse = {
  __typename?: "CreateGetAssignmentObjectiveResponsesMutationResponse";
  getAssignmentObjectiveResponses: Array<GetAssignmentObjectiveResponse>;
  info: CreateInfo;
};

export type CreateGetAssignmentResponsesMutationResponse = {
  __typename?: "CreateGetAssignmentResponsesMutationResponse";
  getAssignmentResponses: Array<GetAssignmentResponse>;
  info: CreateInfo;
};

export type CreateGetJourneyByIdResponsesMutationResponse = {
  __typename?: "CreateGetJourneyByIdResponsesMutationResponse";
  getJourneyByIdResponses: Array<GetJourneyByIdResponse>;
  info: CreateInfo;
};

export type CreateGetJourneysResponsesMutationResponse = {
  __typename?: "CreateGetJourneysResponsesMutationResponse";
  getJourneysResponses: Array<GetJourneysResponse>;
  info: CreateInfo;
};

export type CreateGoalsMutationResponse = {
  __typename?: "CreateGoalsMutationResponse";
  goals: Array<Goal>;
  info: CreateInfo;
};

export type CreateGoogleAuthTokensMutationResponse = {
  __typename?: "CreateGoogleAuthTokensMutationResponse";
  googleAuthTokens: Array<GoogleAuthTokens>;
  info: CreateInfo;
};

export type CreateHierarchiesMutationResponse = {
  __typename?: "CreateHierarchiesMutationResponse";
  hierarchies: Array<Hierarchy>;
  info: CreateInfo;
};

export type CreateHierarchyNodesMutationResponse = {
  __typename?: "CreateHierarchyNodesMutationResponse";
  hierarchyNodes: Array<HierarchyNode>;
  info: CreateInfo;
};

export type CreateHobbiesMutationResponse = {
  __typename?: "CreateHobbiesMutationResponse";
  hobbies: Array<Hobby>;
  info: CreateInfo;
};

/** Information about the number of nodes and relationships created during a create mutation */
export type CreateInfo = {
  __typename?: "CreateInfo";
  /** @deprecated This field has been deprecated because bookmarks are now handled by the driver. */
  bookmark?: Maybe<Scalars["String"]["output"]>;
  nodesCreated: Scalars["Int"]["output"];
  relationshipsCreated: Scalars["Int"]["output"];
};

export type CreateInterestsMutationResponse = {
  __typename?: "CreateInterestsMutationResponse";
  info: CreateInfo;
  interests: Array<Interest>;
};

export type CreateInviteCountResponsesMutationResponse = {
  __typename?: "CreateInviteCountResponsesMutationResponse";
  info: CreateInfo;
  inviteCountResponses: Array<InviteCountResponse>;
};

export type CreateJourneysMutationResponse = {
  __typename?: "CreateJourneysMutationResponse";
  info: CreateInfo;
  journeys: Array<Journey>;
};

export type CreateLearningNodesMutationResponse = {
  __typename?: "CreateLearningNodesMutationResponse";
  info: CreateInfo;
  learningNodes: Array<LearningNode>;
};

export type CreateLearningObjectiveResponsesMutationResponse = {
  __typename?: "CreateLearningObjectiveResponsesMutationResponse";
  info: CreateInfo;
  learningObjectiveResponses: Array<LearningObjectiveResponse>;
};

export type CreateLearningObjectivesMutationResponse = {
  __typename?: "CreateLearningObjectivesMutationResponse";
  info: CreateInfo;
  learningObjectives: Array<LearningObjective>;
};

export type CreateLearningSummariesMutationResponse = {
  __typename?: "CreateLearningSummariesMutationResponse";
  info: CreateInfo;
  learningSummaries: Array<LearningSummary>;
};

export type CreateLearningTreesMutationResponse = {
  __typename?: "CreateLearningTreesMutationResponse";
  info: CreateInfo;
  learningTrees: Array<LearningTree>;
};

export type CreateLessonsMutationResponse = {
  __typename?: "CreateLessonsMutationResponse";
  info: CreateInfo;
  lessons: Array<Lesson>;
};

export type CreateLevelDetailsMutationResponse = {
  __typename?: "CreateLevelDetailsMutationResponse";
  info: CreateInfo;
  levelDetails: Array<LevelDetails>;
};

export type CreateLifeAspirationsMutationResponse = {
  __typename?: "CreateLifeAspirationsMutationResponse";
  info: CreateInfo;
  lifeAspirations: Array<LifeAspiration>;
};

export type CreateMasteryResultsMutationResponse = {
  __typename?: "CreateMasteryResultsMutationResponse";
  info: CreateInfo;
  masteryResults: Array<MasteryResult>;
};

export type CreateMeResponsesMutationResponse = {
  __typename?: "CreateMeResponsesMutationResponse";
  info: CreateInfo;
  meResponses: Array<MeResponse>;
};

export type CreateMessagesMutationResponse = {
  __typename?: "CreateMessagesMutationResponse";
  info: CreateInfo;
  messages: Array<Message>;
};

export type CreateModuleInput = {
  order: Scalars["Float"]["input"];
  parentCourseId: Scalars["ID"]["input"];
  title: Scalars["String"]["input"];
};

export type CreateModuleResponsesMutationResponse = {
  __typename?: "CreateModuleResponsesMutationResponse";
  info: CreateInfo;
  moduleResponses: Array<ModuleResponse>;
};

export type CreateModulesMutationResponse = {
  __typename?: "CreateModulesMutationResponse";
  info: CreateInfo;
  modules: Array<Module>;
};

export type CreateMonthlySummariesMutationResponse = {
  __typename?: "CreateMonthlySummariesMutationResponse";
  info: CreateInfo;
  monthlySummaries: Array<MonthlySummary>;
};

export type CreateNodeIdentitiesMutationResponse = {
  __typename?: "CreateNodeIdentitiesMutationResponse";
  info: CreateInfo;
  nodeIdentities: Array<NodeIdentity>;
};

export type CreateNodePropertiesMutationResponse = {
  __typename?: "CreateNodePropertiesMutationResponse";
  info: CreateInfo;
  nodeProperties: Array<NodeProperties>;
};

export type CreateNutritionsMutationResponse = {
  __typename?: "CreateNutritionsMutationResponse";
  info: CreateInfo;
  nutritions: Array<Nutrition>;
};

export type CreateObjectivesMutationResponse = {
  __typename?: "CreateObjectivesMutationResponse";
  info: CreateInfo;
  objectives: Array<Objective>;
};

export type CreatePageInput = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  parentResourceId: Scalars["ID"]["input"];
  title: Scalars["String"]["input"];
};

export type CreatePageResponsesMutationResponse = {
  __typename?: "CreatePageResponsesMutationResponse";
  info: CreateInfo;
  pageResponses: Array<PageResponse>;
};

export type CreatePagesMutationResponse = {
  __typename?: "CreatePagesMutationResponse";
  info: CreateInfo;
  pages: Array<Page>;
};

export type CreateParametersTypesMutationResponse = {
  __typename?: "CreateParametersTypesMutationResponse";
  info: CreateInfo;
  parametersTypes: Array<ParametersType>;
};

export type CreatePaymentsMutationResponse = {
  __typename?: "CreatePaymentsMutationResponse";
  info: CreateInfo;
  payments: Array<Payment>;
};

export type CreatePermissionsMutationResponse = {
  __typename?: "CreatePermissionsMutationResponse";
  info: CreateInfo;
  permissions: Array<Permission>;
};

export type CreatePersonalityTraitsMutationResponse = {
  __typename?: "CreatePersonalityTraitsMutationResponse";
  info: CreateInfo;
  personalityTraits: Array<PersonalityTrait>;
};

export type CreatePreferencesMutationResponse = {
  __typename?: "CreatePreferencesMutationResponse";
  info: CreateInfo;
  preferences: Array<Preferences>;
};

export type CreatePrerequisitesMutationResponse = {
  __typename?: "CreatePrerequisitesMutationResponse";
  info: CreateInfo;
  prerequisites: Array<Prerequisite>;
};

export type CreateProgressesMutationResponse = {
  __typename?: "CreateProgressesMutationResponse";
  info: CreateInfo;
  progresses: Array<Progress>;
};

export type CreateQuarterlySummariesMutationResponse = {
  __typename?: "CreateQuarterlySummariesMutationResponse";
  info: CreateInfo;
  quarterlySummaries: Array<QuarterlySummary>;
};

export type CreateRateLimitResultsMutationResponse = {
  __typename?: "CreateRateLimitResultsMutationResponse";
  info: CreateInfo;
  rateLimitResults: Array<RateLimitResult>;
};

export type CreateRecentCategoriesMutationResponse = {
  __typename?: "CreateRecentCategoriesMutationResponse";
  info: CreateInfo;
  recentCategories: Array<RecentCategory>;
};

export type CreateRecentTracksMutationResponse = {
  __typename?: "CreateRecentTracksMutationResponse";
  info: CreateInfo;
  recentTracks: Array<RecentTrack>;
};

export type CreateRecomendedsMutationResponse = {
  __typename?: "CreateRecomendedsMutationResponse";
  info: CreateInfo;
  recomendeds: Array<Recomended>;
};

export type CreateReflectionsMutationResponse = {
  __typename?: "CreateReflectionsMutationResponse";
  info: CreateInfo;
  reflections: Array<Reflection>;
};

export type CreateRefreshQuestResponsesMutationResponse = {
  __typename?: "CreateRefreshQuestResponsesMutationResponse";
  info: CreateInfo;
  refreshQuestResponses: Array<RefreshQuestResponse>;
};

export type CreateResourcesMutationResponse = {
  __typename?: "CreateResourcesMutationResponse";
  info: CreateInfo;
  resources: Array<Resource>;
};

export type CreateResponseTypesMutationResponse = {
  __typename?: "CreateResponseTypesMutationResponse";
  info: CreateInfo;
  responseTypes: Array<ResponseType>;
};

export type CreateRolesMutationResponse = {
  __typename?: "CreateRolesMutationResponse";
  info: CreateInfo;
  roles: Array<Role>;
};

export type CreateSchoolsMutationResponse = {
  __typename?: "CreateSchoolsMutationResponse";
  info: CreateInfo;
  schools: Array<School>;
};

export type CreateSessionResponsesMutationResponse = {
  __typename?: "CreateSessionResponsesMutationResponse";
  info: CreateInfo;
  sessionResponses: Array<SessionResponse>;
};

export type CreateSubchapterDetailsMutationResponse = {
  __typename?: "CreateSubchapterDetailsMutationResponse";
  info: CreateInfo;
  subchapterDetails: Array<SubchapterDetails>;
};

export type CreateSubchaptersMutationResponse = {
  __typename?: "CreateSubchaptersMutationResponse";
  info: CreateInfo;
  subchapters: Array<Subchapter>;
};

export type CreateSubfieldsMutationResponse = {
  __typename?: "CreateSubfieldsMutationResponse";
  info: CreateInfo;
  subfields: Array<Subfield>;
};

export type CreateSuggestionsMutationResponse = {
  __typename?: "CreateSuggestionsMutationResponse";
  info: CreateInfo;
  suggestions: Array<Suggestion>;
};

export type CreateTenantResponsesMutationResponse = {
  __typename?: "CreateTenantResponsesMutationResponse";
  info: CreateInfo;
  tenantResponses: Array<TenantResponse>;
};

export type CreateTenantsMutationResponse = {
  __typename?: "CreateTenantsMutationResponse";
  info: CreateInfo;
  tenants: Array<Tenant>;
};

export type CreateTodosMutationResponse = {
  __typename?: "CreateTodosMutationResponse";
  info: CreateInfo;
  todos: Array<Todo>;
};

export type CreateToolAssignmentsMutationResponse = {
  __typename?: "CreateToolAssignmentsMutationResponse";
  info: CreateInfo;
  toolAssignments: Array<ToolAssignment>;
};

export type CreateTopicsMutationResponse = {
  __typename?: "CreateTopicsMutationResponse";
  info: CreateInfo;
  topics: Array<Topic>;
};

export type CreateTrackOverviewsMutationResponse = {
  __typename?: "CreateTrackOverviewsMutationResponse";
  info: CreateInfo;
  trackOverviews: Array<TrackOverview>;
};

export type CreateTransactionByDatesMutationResponse = {
  __typename?: "CreateTransactionByDatesMutationResponse";
  info: CreateInfo;
  transactionByDates: Array<TransactionByDate>;
};

export type CreateTransactionsMutationResponse = {
  __typename?: "CreateTransactionsMutationResponse";
  info: CreateInfo;
  transactions: Array<Transaction>;
};

export type CreateUpdateAssignmentResponsesMutationResponse = {
  __typename?: "CreateUpdateAssignmentResponsesMutationResponse";
  info: CreateInfo;
  updateAssignmentResponses: Array<UpdateAssignmentResponse>;
};

export type CreateUpdateContentModuleNameResponsesMutationResponse = {
  __typename?: "CreateUpdateContentModuleNameResponsesMutationResponse";
  info: CreateInfo;
  updateContentModuleNameResponses: Array<UpdateContentModuleNameResponse>;
};

export type CreateUpdateUserModalOnboardingResponsesMutationResponse = {
  __typename?: "CreateUpdateUserModalOnboardingResponsesMutationResponse";
  info: CreateInfo;
  updateUserModalOnboardingResponses: Array<UpdateUserModalOnboardingResponse>;
};

export type CreateUploadDocumentsResponsesMutationResponse = {
  __typename?: "CreateUploadDocumentsResponsesMutationResponse";
  info: CreateInfo;
  uploadDocumentsResponses: Array<UploadDocumentsResponse>;
};

export type CreateUploadDocumentsToBucketResponsesMutationResponse = {
  __typename?: "CreateUploadDocumentsToBucketResponsesMutationResponse";
  info: CreateInfo;
  uploadDocumentsToBucketResponses: Array<UploadDocumentsToBucketResponse>;
};

export type CreateUserCareerLoadingResponsesMutationResponse = {
  __typename?: "CreateUserCareerLoadingResponsesMutationResponse";
  info: CreateInfo;
  userCareerLoadingResponses: Array<UserCareerLoadingResponse>;
};

export type CreateUserCourseLoadingResponsesMutationResponse = {
  __typename?: "CreateUserCourseLoadingResponsesMutationResponse";
  info: CreateInfo;
  userCourseLoadingResponses: Array<UserCourseLoadingResponse>;
};

export type CreateUserMasteriesMutationResponse = {
  __typename?: "CreateUserMasteriesMutationResponse";
  info: CreateInfo;
  userMasteries: Array<UserMastery>;
};

export type CreateUserProgressesMutationResponse = {
  __typename?: "CreateUserProgressesMutationResponse";
  info: CreateInfo;
  userProgresses: Array<UserProgress>;
};

export type CreateUsersMutationResponse = {
  __typename?: "CreateUsersMutationResponse";
  info: CreateInfo;
  users: Array<User>;
};

export type CreateWaterIntakeSummariesMutationResponse = {
  __typename?: "CreateWaterIntakeSummariesMutationResponse";
  info: CreateInfo;
  waterIntakeSummaries: Array<WaterIntakeSummary>;
};

export type CreateWaterIntakesMutationResponse = {
  __typename?: "CreateWaterIntakesMutationResponse";
  info: CreateInfo;
  waterIntakes: Array<WaterIntake>;
};

export type CreateWeeklySummariesMutationResponse = {
  __typename?: "CreateWeeklySummariesMutationResponse";
  info: CreateInfo;
  weeklySummaries: Array<WeeklySummary>;
};

export type CreateWhitelistedEmailsMutationResponse = {
  __typename?: "CreateWhitelistedEmailsMutationResponse";
  info: CreateInfo;
  whitelistedEmails: Array<WhitelistedEmail>;
};

export type CreateWorkoutsMutationResponse = {
  __typename?: "CreateWorkoutsMutationResponse";
  info: CreateInfo;
  workouts: Array<Workout>;
};

export type CreateYearlySummariesMutationResponse = {
  __typename?: "CreateYearlySummariesMutationResponse";
  info: CreateInfo;
  yearlySummaries: Array<YearlySummary>;
};

export type CurriculaConnection = {
  __typename?: "CurriculaConnection";
  edges: Array<CurriculumEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Curriculum = {
  __typename?: "Curriculum";
  created?: Maybe<Scalars["Date"]["output"]>;
  id: Scalars["ID"]["output"];
  last_modified?: Maybe<Scalars["Date"]["output"]>;
  modules: Array<Module>;
  name: Scalars["String"]["output"];
  objectives: Array<Scalars["String"]["output"]>;
  standard: Scalars["String"]["output"];
  weeks: Scalars["Int"]["output"];
};

export type CurriculumAggregateSelection = {
  __typename?: "CurriculumAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  name: StringAggregateSelection;
  standard: StringAggregateSelection;
  weeks: IntAggregateSelection;
};

export type CurriculumCreateInput = {
  created?: InputMaybe<Scalars["Date"]["input"]>;
  id: Scalars["ID"]["input"];
  last_modified?: InputMaybe<Scalars["Date"]["input"]>;
  name: Scalars["String"]["input"];
  objectives: Array<Scalars["String"]["input"]>;
  standard: Scalars["String"]["input"];
  weeks: Scalars["Int"]["input"];
};

export type CurriculumEdge = {
  __typename?: "CurriculumEdge";
  cursor: Scalars["String"]["output"];
  node: Curriculum;
};

export type CurriculumOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more CurriculumSort objects to sort Curricula by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<CurriculumSort>>;
};

/** Fields to sort Curricula by. The order in which sorts are applied is not guaranteed when specifying many fields in one CurriculumSort object. */
export type CurriculumSort = {
  created?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  standard?: InputMaybe<SortDirection>;
  weeks?: InputMaybe<SortDirection>;
};

export type CurriculumUpdateInput = {
  created?: InputMaybe<Scalars["Date"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  last_modified?: InputMaybe<Scalars["Date"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Array<Scalars["String"]["input"]>>;
  objectives_POP?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_PUSH?: InputMaybe<Array<Scalars["String"]["input"]>>;
  standard?: InputMaybe<Scalars["String"]["input"]>;
  weeks?: InputMaybe<Scalars["Int"]["input"]>;
  weeks_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  weeks_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type CurriculumWhere = {
  AND?: InputMaybe<Array<CurriculumWhere>>;
  NOT?: InputMaybe<CurriculumWhere>;
  OR?: InputMaybe<Array<CurriculumWhere>>;
  created?: InputMaybe<Scalars["Date"]["input"]>;
  created_GT?: InputMaybe<Scalars["Date"]["input"]>;
  created_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["Date"]["input"]>;
  created_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  last_modified?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Array<Scalars["String"]["input"]>>;
  objectives_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  standard?: InputMaybe<Scalars["String"]["input"]>;
  standard_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  standard_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  standard_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  standard_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  weeks?: InputMaybe<Scalars["Int"]["input"]>;
  weeks_GT?: InputMaybe<Scalars["Int"]["input"]>;
  weeks_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  weeks_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  weeks_LT?: InputMaybe<Scalars["Int"]["input"]>;
  weeks_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DailyCompletedTodosCount = {
  __typename?: "DailyCompletedTodosCount";
  completedTodosCount: Scalars["Int"]["output"];
};

export type DailyCompletedTodosCountAggregateSelection = {
  __typename?: "DailyCompletedTodosCountAggregateSelection";
  completedTodosCount: IntAggregateSelection;
  count: Scalars["Int"]["output"];
};

export type DailyCompletedTodosCountCreateInput = {
  completedTodosCount: Scalars["Int"]["input"];
};

export type DailyCompletedTodosCountEdge = {
  __typename?: "DailyCompletedTodosCountEdge";
  cursor: Scalars["String"]["output"];
  node: DailyCompletedTodosCount;
};

export type DailyCompletedTodosCountOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DailyCompletedTodosCountSort objects to sort DailyCompletedTodosCounts by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DailyCompletedTodosCountSort>>;
};

/** Fields to sort DailyCompletedTodosCounts by. The order in which sorts are applied is not guaranteed when specifying many fields in one DailyCompletedTodosCountSort object. */
export type DailyCompletedTodosCountSort = {
  completedTodosCount?: InputMaybe<SortDirection>;
};

export type DailyCompletedTodosCountUpdateInput = {
  completedTodosCount?: InputMaybe<Scalars["Int"]["input"]>;
  completedTodosCount_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTodosCount_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DailyCompletedTodosCountWhere = {
  AND?: InputMaybe<Array<DailyCompletedTodosCountWhere>>;
  NOT?: InputMaybe<DailyCompletedTodosCountWhere>;
  OR?: InputMaybe<Array<DailyCompletedTodosCountWhere>>;
  completedTodosCount?: InputMaybe<Scalars["Int"]["input"]>;
  completedTodosCount_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTodosCount_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTodosCount_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  completedTodosCount_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTodosCount_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DailyCompletedTodosCountsConnection = {
  __typename?: "DailyCompletedTodosCountsConnection";
  edges: Array<DailyCompletedTodosCountEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DailyExerciseTotals = {
  __typename?: "DailyExerciseTotals";
  totalCaloriesBurned?: Maybe<Scalars["Float"]["output"]>;
  totalDurationMinutes?: Maybe<Scalars["Float"]["output"]>;
  totalWorkouts?: Maybe<Scalars["Float"]["output"]>;
  workoutsList?: Maybe<Array<Maybe<Workout>>>;
};

export type DailyExerciseTotalsAggregateSelection = {
  __typename?: "DailyExerciseTotalsAggregateSelection";
  count: Scalars["Int"]["output"];
  totalCaloriesBurned: FloatAggregateSelection;
  totalDurationMinutes: FloatAggregateSelection;
  totalWorkouts: FloatAggregateSelection;
};

export type DailyExerciseTotalsConnection = {
  __typename?: "DailyExerciseTotalsConnection";
  edges: Array<DailyExerciseTotalsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DailyExerciseTotalsCreateInput = {
  totalCaloriesBurned?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts?: InputMaybe<Scalars["Float"]["input"]>;
};

export type DailyExerciseTotalsEdge = {
  __typename?: "DailyExerciseTotalsEdge";
  cursor: Scalars["String"]["output"];
  node: DailyExerciseTotals;
};

export type DailyExerciseTotalsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DailyExerciseTotalsSort objects to sort DailyExerciseTotals by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DailyExerciseTotalsSort>>;
};

/** Fields to sort DailyExerciseTotals by. The order in which sorts are applied is not guaranteed when specifying many fields in one DailyExerciseTotalsSort object. */
export type DailyExerciseTotalsSort = {
  totalCaloriesBurned?: InputMaybe<SortDirection>;
  totalDurationMinutes?: InputMaybe<SortDirection>;
  totalWorkouts?: InputMaybe<SortDirection>;
};

export type DailyExerciseTotalsUpdateInput = {
  totalCaloriesBurned?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type DailyExerciseTotalsWhere = {
  AND?: InputMaybe<Array<DailyExerciseTotalsWhere>>;
  NOT?: InputMaybe<DailyExerciseTotalsWhere>;
  OR?: InputMaybe<Array<DailyExerciseTotalsWhere>>;
  totalCaloriesBurned?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalCaloriesBurned_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCaloriesBurned_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalDurationMinutes_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalDurationMinutes_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalWorkouts_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalWorkouts_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type DailyNutritionTotals = {
  __typename?: "DailyNutritionTotals";
  totalCalories?: Maybe<Scalars["Float"]["output"]>;
  totalCarbs?: Maybe<Scalars["Float"]["output"]>;
  totalFats?: Maybe<Scalars["Float"]["output"]>;
  totalProteins?: Maybe<Scalars["Float"]["output"]>;
};

export type DailyNutritionTotalsAggregateSelection = {
  __typename?: "DailyNutritionTotalsAggregateSelection";
  count: Scalars["Int"]["output"];
  totalCalories: FloatAggregateSelection;
  totalCarbs: FloatAggregateSelection;
  totalFats: FloatAggregateSelection;
  totalProteins: FloatAggregateSelection;
};

export type DailyNutritionTotalsConnection = {
  __typename?: "DailyNutritionTotalsConnection";
  edges: Array<DailyNutritionTotalsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DailyNutritionTotalsCreateInput = {
  totalCalories?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins?: InputMaybe<Scalars["Float"]["input"]>;
};

export type DailyNutritionTotalsEdge = {
  __typename?: "DailyNutritionTotalsEdge";
  cursor: Scalars["String"]["output"];
  node: DailyNutritionTotals;
};

export type DailyNutritionTotalsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DailyNutritionTotalsSort objects to sort DailyNutritionTotals by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DailyNutritionTotalsSort>>;
};

/** Fields to sort DailyNutritionTotals by. The order in which sorts are applied is not guaranteed when specifying many fields in one DailyNutritionTotalsSort object. */
export type DailyNutritionTotalsSort = {
  totalCalories?: InputMaybe<SortDirection>;
  totalCarbs?: InputMaybe<SortDirection>;
  totalFats?: InputMaybe<SortDirection>;
  totalProteins?: InputMaybe<SortDirection>;
};

export type DailyNutritionTotalsUpdateInput = {
  totalCalories?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type DailyNutritionTotalsWhere = {
  AND?: InputMaybe<Array<DailyNutritionTotalsWhere>>;
  NOT?: InputMaybe<DailyNutritionTotalsWhere>;
  OR?: InputMaybe<Array<DailyNutritionTotalsWhere>>;
  totalCalories?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalCalories_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCalories_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalCarbs_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalCarbs_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalFats_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalFats_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalProteins_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalProteins_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type DailySummariesConnection = {
  __typename?: "DailySummariesConnection";
  edges: Array<DailySummaryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DailySummary = {
  __typename?: "DailySummary";
  created: Scalars["DateTime"]["output"];
  date: Scalars["String"]["output"];
  isArchived: Scalars["Boolean"]["output"];
  last_modified: Scalars["DateTime"]["output"];
  summary: Scalars["String"]["output"];
  usersHasSummary: Array<User>;
  usersHasSummaryAggregate?: Maybe<DailySummaryUserUsersHasSummaryAggregationSelection>;
  usersHasSummaryConnection: DailySummaryUsersHasSummaryConnection;
};

export type DailySummaryUsersHasSummaryArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type DailySummaryUsersHasSummaryAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type DailySummaryUsersHasSummaryConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<DailySummaryUsersHasSummaryConnectionSort>>;
  where?: InputMaybe<DailySummaryUsersHasSummaryConnectionWhere>;
};

export type DailySummaryAggregateSelection = {
  __typename?: "DailySummaryAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  date: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type DailySummaryConnectInput = {
  usersHasSummary?: InputMaybe<Array<DailySummaryUsersHasSummaryConnectFieldInput>>;
};

export type DailySummaryConnectWhere = {
  node: DailySummaryWhere;
};

export type DailySummaryCreateInput = {
  created: Scalars["DateTime"]["input"];
  date: Scalars["String"]["input"];
  isArchived: Scalars["Boolean"]["input"];
  last_modified: Scalars["DateTime"]["input"];
  summary: Scalars["String"]["input"];
  usersHasSummary?: InputMaybe<DailySummaryUsersHasSummaryFieldInput>;
};

export type DailySummaryDeleteInput = {
  usersHasSummary?: InputMaybe<Array<DailySummaryUsersHasSummaryDeleteFieldInput>>;
};

export type DailySummaryDisconnectInput = {
  usersHasSummary?: InputMaybe<Array<DailySummaryUsersHasSummaryDisconnectFieldInput>>;
};

export type DailySummaryEdge = {
  __typename?: "DailySummaryEdge";
  cursor: Scalars["String"]["output"];
  node: DailySummary;
};

export type DailySummaryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DailySummarySort objects to sort DailySummaries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DailySummarySort>>;
};

export type DailySummaryRelationInput = {
  usersHasSummary?: InputMaybe<Array<DailySummaryUsersHasSummaryCreateFieldInput>>;
};

/** Fields to sort DailySummaries by. The order in which sorts are applied is not guaranteed when specifying many fields in one DailySummarySort object. */
export type DailySummarySort = {
  created?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  isArchived?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  summary?: InputMaybe<SortDirection>;
};

export type DailySummaryUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSummary?: InputMaybe<Array<DailySummaryUsersHasSummaryUpdateFieldInput>>;
};

export type DailySummaryUserUsersHasSummaryAggregationSelection = {
  __typename?: "DailySummaryUserUsersHasSummaryAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<DailySummaryUserUsersHasSummaryNodeAggregateSelection>;
};

export type DailySummaryUserUsersHasSummaryNodeAggregateSelection = {
  __typename?: "DailySummaryUserUsersHasSummaryNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type DailySummaryUsersHasSummaryAggregateInput = {
  AND?: InputMaybe<Array<DailySummaryUsersHasSummaryAggregateInput>>;
  NOT?: InputMaybe<DailySummaryUsersHasSummaryAggregateInput>;
  OR?: InputMaybe<Array<DailySummaryUsersHasSummaryAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<DailySummaryUsersHasSummaryNodeAggregationWhereInput>;
};

export type DailySummaryUsersHasSummaryConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type DailySummaryUsersHasSummaryConnection = {
  __typename?: "DailySummaryUsersHasSummaryConnection";
  edges: Array<DailySummaryUsersHasSummaryRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DailySummaryUsersHasSummaryConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type DailySummaryUsersHasSummaryConnectionWhere = {
  AND?: InputMaybe<Array<DailySummaryUsersHasSummaryConnectionWhere>>;
  NOT?: InputMaybe<DailySummaryUsersHasSummaryConnectionWhere>;
  OR?: InputMaybe<Array<DailySummaryUsersHasSummaryConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type DailySummaryUsersHasSummaryCreateFieldInput = {
  node: UserCreateInput;
};

export type DailySummaryUsersHasSummaryDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<DailySummaryUsersHasSummaryConnectionWhere>;
};

export type DailySummaryUsersHasSummaryDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<DailySummaryUsersHasSummaryConnectionWhere>;
};

export type DailySummaryUsersHasSummaryFieldInput = {
  connect?: InputMaybe<Array<DailySummaryUsersHasSummaryConnectFieldInput>>;
  create?: InputMaybe<Array<DailySummaryUsersHasSummaryCreateFieldInput>>;
};

export type DailySummaryUsersHasSummaryNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<DailySummaryUsersHasSummaryNodeAggregationWhereInput>>;
  NOT?: InputMaybe<DailySummaryUsersHasSummaryNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<DailySummaryUsersHasSummaryNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DailySummaryUsersHasSummaryRelationship = {
  __typename?: "DailySummaryUsersHasSummaryRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type DailySummaryUsersHasSummaryUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type DailySummaryUsersHasSummaryUpdateFieldInput = {
  connect?: InputMaybe<Array<DailySummaryUsersHasSummaryConnectFieldInput>>;
  create?: InputMaybe<Array<DailySummaryUsersHasSummaryCreateFieldInput>>;
  delete?: InputMaybe<Array<DailySummaryUsersHasSummaryDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<DailySummaryUsersHasSummaryDisconnectFieldInput>>;
  update?: InputMaybe<DailySummaryUsersHasSummaryUpdateConnectionInput>;
  where?: InputMaybe<DailySummaryUsersHasSummaryConnectionWhere>;
};

export type DailySummaryWhere = {
  AND?: InputMaybe<Array<DailySummaryWhere>>;
  NOT?: InputMaybe<DailySummaryWhere>;
  OR?: InputMaybe<Array<DailySummaryWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  date_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  summary_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  summary_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  summary_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  summary_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSummaryAggregate?: InputMaybe<DailySummaryUsersHasSummaryAggregateInput>;
  /** Return DailySummaries where all of the related DailySummaryUsersHasSummaryConnections match this filter */
  usersHasSummaryConnection_ALL?: InputMaybe<DailySummaryUsersHasSummaryConnectionWhere>;
  /** Return DailySummaries where none of the related DailySummaryUsersHasSummaryConnections match this filter */
  usersHasSummaryConnection_NONE?: InputMaybe<DailySummaryUsersHasSummaryConnectionWhere>;
  /** Return DailySummaries where one of the related DailySummaryUsersHasSummaryConnections match this filter */
  usersHasSummaryConnection_SINGLE?: InputMaybe<DailySummaryUsersHasSummaryConnectionWhere>;
  /** Return DailySummaries where some of the related DailySummaryUsersHasSummaryConnections match this filter */
  usersHasSummaryConnection_SOME?: InputMaybe<DailySummaryUsersHasSummaryConnectionWhere>;
  /** Return DailySummaries where all of the related Users match this filter */
  usersHasSummary_ALL?: InputMaybe<UserWhere>;
  /** Return DailySummaries where none of the related Users match this filter */
  usersHasSummary_NONE?: InputMaybe<UserWhere>;
  /** Return DailySummaries where one of the related Users match this filter */
  usersHasSummary_SINGLE?: InputMaybe<UserWhere>;
  /** Return DailySummaries where some of the related Users match this filter */
  usersHasSummary_SOME?: InputMaybe<UserWhere>;
};

export type DateTimeAggregateSelection = {
  __typename?: "DateTimeAggregateSelection";
  max?: Maybe<Scalars["DateTime"]["output"]>;
  min?: Maybe<Scalars["DateTime"]["output"]>;
};

export type DeleteContentAssignmentResponse = {
  __typename?: "DeleteContentAssignmentResponse";
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type DeleteContentAssignmentResponseAggregateSelection = {
  __typename?: "DeleteContentAssignmentResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type DeleteContentAssignmentResponseCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type DeleteContentAssignmentResponseEdge = {
  __typename?: "DeleteContentAssignmentResponseEdge";
  cursor: Scalars["String"]["output"];
  node: DeleteContentAssignmentResponse;
};

export type DeleteContentAssignmentResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DeleteContentAssignmentResponseSort objects to sort DeleteContentAssignmentResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DeleteContentAssignmentResponseSort>>;
};

/** Fields to sort DeleteContentAssignmentResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeleteContentAssignmentResponseSort object. */
export type DeleteContentAssignmentResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type DeleteContentAssignmentResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DeleteContentAssignmentResponseWhere = {
  AND?: InputMaybe<Array<DeleteContentAssignmentResponseWhere>>;
  NOT?: InputMaybe<DeleteContentAssignmentResponseWhere>;
  OR?: InputMaybe<Array<DeleteContentAssignmentResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DeleteContentAssignmentResponsesConnection = {
  __typename?: "DeleteContentAssignmentResponsesConnection";
  edges: Array<DeleteContentAssignmentResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DeleteContentAssignmentVariables = {
  __typename?: "DeleteContentAssignmentVariables";
  assignmentName?: Maybe<Scalars["String"]["output"]>;
  courseId?: Maybe<Scalars["String"]["output"]>;
  moduleName?: Maybe<Scalars["String"]["output"]>;
};

export type DeleteContentAssignmentVariablesAggregateSelection = {
  __typename?: "DeleteContentAssignmentVariablesAggregateSelection";
  assignmentName: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  courseId: StringAggregateSelection;
  moduleName: StringAggregateSelection;
};

export type DeleteContentAssignmentVariablesConnection = {
  __typename?: "DeleteContentAssignmentVariablesConnection";
  edges: Array<DeleteContentAssignmentVariablesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DeleteContentAssignmentVariablesCreateInput = {
  assignmentName?: InputMaybe<Scalars["String"]["input"]>;
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeleteContentAssignmentVariablesEdge = {
  __typename?: "DeleteContentAssignmentVariablesEdge";
  cursor: Scalars["String"]["output"];
  node: DeleteContentAssignmentVariables;
};

export type DeleteContentAssignmentVariablesOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DeleteContentAssignmentVariablesSort objects to sort DeleteContentAssignmentVariables by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DeleteContentAssignmentVariablesSort>>;
};

/** Fields to sort DeleteContentAssignmentVariables by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeleteContentAssignmentVariablesSort object. */
export type DeleteContentAssignmentVariablesSort = {
  assignmentName?: InputMaybe<SortDirection>;
  courseId?: InputMaybe<SortDirection>;
  moduleName?: InputMaybe<SortDirection>;
};

export type DeleteContentAssignmentVariablesUpdateInput = {
  assignmentName?: InputMaybe<Scalars["String"]["input"]>;
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeleteContentAssignmentVariablesWhere = {
  AND?: InputMaybe<Array<DeleteContentAssignmentVariablesWhere>>;
  NOT?: InputMaybe<DeleteContentAssignmentVariablesWhere>;
  OR?: InputMaybe<Array<DeleteContentAssignmentVariablesWhere>>;
  assignmentName?: InputMaybe<Scalars["String"]["input"]>;
  assignmentName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  assignmentName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  assignmentName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignmentName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  courseId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  courseId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  courseId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  courseId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  moduleName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeleteContentModuleResponse = {
  __typename?: "DeleteContentModuleResponse";
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type DeleteContentModuleResponseAggregateSelection = {
  __typename?: "DeleteContentModuleResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type DeleteContentModuleResponseCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type DeleteContentModuleResponseEdge = {
  __typename?: "DeleteContentModuleResponseEdge";
  cursor: Scalars["String"]["output"];
  node: DeleteContentModuleResponse;
};

export type DeleteContentModuleResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DeleteContentModuleResponseSort objects to sort DeleteContentModuleResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DeleteContentModuleResponseSort>>;
};

/** Fields to sort DeleteContentModuleResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeleteContentModuleResponseSort object. */
export type DeleteContentModuleResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type DeleteContentModuleResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DeleteContentModuleResponseWhere = {
  AND?: InputMaybe<Array<DeleteContentModuleResponseWhere>>;
  NOT?: InputMaybe<DeleteContentModuleResponseWhere>;
  OR?: InputMaybe<Array<DeleteContentModuleResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DeleteContentModuleResponsesConnection = {
  __typename?: "DeleteContentModuleResponsesConnection";
  edges: Array<DeleteContentModuleResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DeleteContentModuleVariables = {
  __typename?: "DeleteContentModuleVariables";
  courseId?: Maybe<Scalars["String"]["output"]>;
  moduleName?: Maybe<Scalars["String"]["output"]>;
};

export type DeleteContentModuleVariablesAggregateSelection = {
  __typename?: "DeleteContentModuleVariablesAggregateSelection";
  count: Scalars["Int"]["output"];
  courseId: StringAggregateSelection;
  moduleName: StringAggregateSelection;
};

export type DeleteContentModuleVariablesConnection = {
  __typename?: "DeleteContentModuleVariablesConnection";
  edges: Array<DeleteContentModuleVariablesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DeleteContentModuleVariablesCreateInput = {
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeleteContentModuleVariablesEdge = {
  __typename?: "DeleteContentModuleVariablesEdge";
  cursor: Scalars["String"]["output"];
  node: DeleteContentModuleVariables;
};

export type DeleteContentModuleVariablesOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DeleteContentModuleVariablesSort objects to sort DeleteContentModuleVariables by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DeleteContentModuleVariablesSort>>;
};

/** Fields to sort DeleteContentModuleVariables by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeleteContentModuleVariablesSort object. */
export type DeleteContentModuleVariablesSort = {
  courseId?: InputMaybe<SortDirection>;
  moduleName?: InputMaybe<SortDirection>;
};

export type DeleteContentModuleVariablesUpdateInput = {
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeleteContentModuleVariablesWhere = {
  AND?: InputMaybe<Array<DeleteContentModuleVariablesWhere>>;
  NOT?: InputMaybe<DeleteContentModuleVariablesWhere>;
  OR?: InputMaybe<Array<DeleteContentModuleVariablesWhere>>;
  courseId?: InputMaybe<Scalars["String"]["input"]>;
  courseId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  courseId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  courseId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  courseId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  moduleName?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  moduleName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  moduleName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type DeleteCourseResponse = {
  __typename?: "DeleteCourseResponse";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type DeleteCourseResponseAggregateSelection = {
  __typename?: "DeleteCourseResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type DeleteCourseResponseCreateInput = {
  message: Scalars["String"]["input"];
  success: Scalars["Boolean"]["input"];
};

export type DeleteCourseResponseEdge = {
  __typename?: "DeleteCourseResponseEdge";
  cursor: Scalars["String"]["output"];
  node: DeleteCourseResponse;
};

export type DeleteCourseResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DeleteCourseResponseSort objects to sort DeleteCourseResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DeleteCourseResponseSort>>;
};

/** Fields to sort DeleteCourseResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeleteCourseResponseSort object. */
export type DeleteCourseResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type DeleteCourseResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DeleteCourseResponseWhere = {
  AND?: InputMaybe<Array<DeleteCourseResponseWhere>>;
  NOT?: InputMaybe<DeleteCourseResponseWhere>;
  OR?: InputMaybe<Array<DeleteCourseResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type DeleteCourseResponsesConnection = {
  __typename?: "DeleteCourseResponsesConnection";
  edges: Array<DeleteCourseResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Information about the number of nodes and relationships deleted during a delete mutation */
export type DeleteInfo = {
  __typename?: "DeleteInfo";
  /** @deprecated This field has been deprecated because bookmarks are now handled by the driver. */
  bookmark?: Maybe<Scalars["String"]["output"]>;
  nodesDeleted: Scalars["Int"]["output"];
  relationshipsDeleted: Scalars["Int"]["output"];
};

export type DeviceSession = {
  __typename?: "DeviceSession";
  id: Scalars["String"]["output"];
  usersHasSession: Array<User>;
  usersHasSessionAggregate?: Maybe<DeviceSessionUserUsersHasSessionAggregationSelection>;
  usersHasSessionConnection: DeviceSessionUsersHasSessionConnection;
};

export type DeviceSessionUsersHasSessionArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type DeviceSessionUsersHasSessionAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type DeviceSessionUsersHasSessionConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<DeviceSessionUsersHasSessionConnectionSort>>;
  where?: InputMaybe<DeviceSessionUsersHasSessionConnectionWhere>;
};

export type DeviceSessionAggregateSelection = {
  __typename?: "DeviceSessionAggregateSelection";
  count: Scalars["Int"]["output"];
  id: StringAggregateSelection;
};

export type DeviceSessionConnectInput = {
  usersHasSession?: InputMaybe<Array<DeviceSessionUsersHasSessionConnectFieldInput>>;
};

export type DeviceSessionConnectWhere = {
  node: DeviceSessionWhere;
};

export type DeviceSessionCreateInput = {
  id: Scalars["String"]["input"];
  usersHasSession?: InputMaybe<DeviceSessionUsersHasSessionFieldInput>;
};

export type DeviceSessionDeleteInput = {
  usersHasSession?: InputMaybe<Array<DeviceSessionUsersHasSessionDeleteFieldInput>>;
};

export type DeviceSessionDisconnectInput = {
  usersHasSession?: InputMaybe<Array<DeviceSessionUsersHasSessionDisconnectFieldInput>>;
};

export type DeviceSessionEdge = {
  __typename?: "DeviceSessionEdge";
  cursor: Scalars["String"]["output"];
  node: DeviceSession;
};

export type DeviceSessionOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more DeviceSessionSort objects to sort DeviceSessions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<DeviceSessionSort>>;
};

export type DeviceSessionRelationInput = {
  usersHasSession?: InputMaybe<Array<DeviceSessionUsersHasSessionCreateFieldInput>>;
};

/** Fields to sort DeviceSessions by. The order in which sorts are applied is not guaranteed when specifying many fields in one DeviceSessionSort object. */
export type DeviceSessionSort = {
  id?: InputMaybe<SortDirection>;
};

export type DeviceSessionUpdateInput = {
  id?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSession?: InputMaybe<Array<DeviceSessionUsersHasSessionUpdateFieldInput>>;
};

export type DeviceSessionUserUsersHasSessionAggregationSelection = {
  __typename?: "DeviceSessionUserUsersHasSessionAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<DeviceSessionUserUsersHasSessionEdgeAggregateSelection>;
  node?: Maybe<DeviceSessionUserUsersHasSessionNodeAggregateSelection>;
};

export type DeviceSessionUserUsersHasSessionEdgeAggregateSelection = {
  __typename?: "DeviceSessionUserUsersHasSessionEdgeAggregateSelection";
  createdAt: DateTimeAggregateSelection;
  expiresAt: DateTimeAggregateSelection;
};

export type DeviceSessionUserUsersHasSessionNodeAggregateSelection = {
  __typename?: "DeviceSessionUserUsersHasSessionNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type DeviceSessionUsersHasSessionAggregateInput = {
  AND?: InputMaybe<Array<DeviceSessionUsersHasSessionAggregateInput>>;
  NOT?: InputMaybe<DeviceSessionUsersHasSessionAggregateInput>;
  OR?: InputMaybe<Array<DeviceSessionUsersHasSessionAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasSessionPropertiesAggregationWhereInput>;
  node?: InputMaybe<DeviceSessionUsersHasSessionNodeAggregationWhereInput>;
};

export type DeviceSessionUsersHasSessionConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge: HasSessionPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type DeviceSessionUsersHasSessionConnection = {
  __typename?: "DeviceSessionUsersHasSessionConnection";
  edges: Array<DeviceSessionUsersHasSessionRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type DeviceSessionUsersHasSessionConnectionSort = {
  edge?: InputMaybe<HasSessionPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type DeviceSessionUsersHasSessionConnectionWhere = {
  AND?: InputMaybe<Array<DeviceSessionUsersHasSessionConnectionWhere>>;
  NOT?: InputMaybe<DeviceSessionUsersHasSessionConnectionWhere>;
  OR?: InputMaybe<Array<DeviceSessionUsersHasSessionConnectionWhere>>;
  edge?: InputMaybe<HasSessionPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type DeviceSessionUsersHasSessionCreateFieldInput = {
  edge: HasSessionPropertiesCreateInput;
  node: UserCreateInput;
};

export type DeviceSessionUsersHasSessionDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<DeviceSessionUsersHasSessionConnectionWhere>;
};

export type DeviceSessionUsersHasSessionDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<DeviceSessionUsersHasSessionConnectionWhere>;
};

export type DeviceSessionUsersHasSessionFieldInput = {
  connect?: InputMaybe<Array<DeviceSessionUsersHasSessionConnectFieldInput>>;
  create?: InputMaybe<Array<DeviceSessionUsersHasSessionCreateFieldInput>>;
};

export type DeviceSessionUsersHasSessionNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<DeviceSessionUsersHasSessionNodeAggregationWhereInput>>;
  NOT?: InputMaybe<DeviceSessionUsersHasSessionNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<DeviceSessionUsersHasSessionNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type DeviceSessionUsersHasSessionRelationship = {
  __typename?: "DeviceSessionUsersHasSessionRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasSessionProperties;
};

export type DeviceSessionUsersHasSessionUpdateConnectionInput = {
  edge?: InputMaybe<HasSessionPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type DeviceSessionUsersHasSessionUpdateFieldInput = {
  connect?: InputMaybe<Array<DeviceSessionUsersHasSessionConnectFieldInput>>;
  create?: InputMaybe<Array<DeviceSessionUsersHasSessionCreateFieldInput>>;
  delete?: InputMaybe<Array<DeviceSessionUsersHasSessionDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<DeviceSessionUsersHasSessionDisconnectFieldInput>>;
  update?: InputMaybe<DeviceSessionUsersHasSessionUpdateConnectionInput>;
  where?: InputMaybe<DeviceSessionUsersHasSessionConnectionWhere>;
};

export type DeviceSessionWhere = {
  AND?: InputMaybe<Array<DeviceSessionWhere>>;
  NOT?: InputMaybe<DeviceSessionWhere>;
  OR?: InputMaybe<Array<DeviceSessionWhere>>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSessionAggregate?: InputMaybe<DeviceSessionUsersHasSessionAggregateInput>;
  /** Return DeviceSessions where all of the related DeviceSessionUsersHasSessionConnections match this filter */
  usersHasSessionConnection_ALL?: InputMaybe<DeviceSessionUsersHasSessionConnectionWhere>;
  /** Return DeviceSessions where none of the related DeviceSessionUsersHasSessionConnections match this filter */
  usersHasSessionConnection_NONE?: InputMaybe<DeviceSessionUsersHasSessionConnectionWhere>;
  /** Return DeviceSessions where one of the related DeviceSessionUsersHasSessionConnections match this filter */
  usersHasSessionConnection_SINGLE?: InputMaybe<DeviceSessionUsersHasSessionConnectionWhere>;
  /** Return DeviceSessions where some of the related DeviceSessionUsersHasSessionConnections match this filter */
  usersHasSessionConnection_SOME?: InputMaybe<DeviceSessionUsersHasSessionConnectionWhere>;
  /** Return DeviceSessions where all of the related Users match this filter */
  usersHasSession_ALL?: InputMaybe<UserWhere>;
  /** Return DeviceSessions where none of the related Users match this filter */
  usersHasSession_NONE?: InputMaybe<UserWhere>;
  /** Return DeviceSessions where one of the related Users match this filter */
  usersHasSession_SINGLE?: InputMaybe<UserWhere>;
  /** Return DeviceSessions where some of the related Users match this filter */
  usersHasSession_SOME?: InputMaybe<UserWhere>;
};

export type DeviceSessionsConnection = {
  __typename?: "DeviceSessionsConnection";
  edges: Array<DeviceSessionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type EmotionalState = {
  __typename?: "EmotionalState";
  id?: Maybe<Scalars["Int"]["output"]>;
  intensity?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  timestamp?: Maybe<Scalars["Date"]["output"]>;
  trigger?: Maybe<Scalars["String"]["output"]>;
};

export type EmotionalStateAggregateSelection = {
  __typename?: "EmotionalStateAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IntAggregateSelection;
  intensity: IntAggregateSelection;
  name: StringAggregateSelection;
  trigger: StringAggregateSelection;
};

export type EmotionalStateCreateInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  intensity?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["Date"]["input"]>;
  trigger?: InputMaybe<Scalars["String"]["input"]>;
};

export type EmotionalStateEdge = {
  __typename?: "EmotionalStateEdge";
  cursor: Scalars["String"]["output"];
  node: EmotionalState;
};

export type EmotionalStateOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more EmotionalStateSort objects to sort EmotionalStates by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<EmotionalStateSort>>;
};

/** Fields to sort EmotionalStates by. The order in which sorts are applied is not guaranteed when specifying many fields in one EmotionalStateSort object. */
export type EmotionalStateSort = {
  id?: InputMaybe<SortDirection>;
  intensity?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  timestamp?: InputMaybe<SortDirection>;
  trigger?: InputMaybe<SortDirection>;
};

export type EmotionalStateUpdateInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  intensity?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["Date"]["input"]>;
  trigger?: InputMaybe<Scalars["String"]["input"]>;
};

export type EmotionalStateWhere = {
  AND?: InputMaybe<Array<EmotionalStateWhere>>;
  NOT?: InputMaybe<EmotionalStateWhere>;
  OR?: InputMaybe<Array<EmotionalStateWhere>>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  intensity?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_GT?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  intensity_LT?: InputMaybe<Scalars["Int"]["input"]>;
  intensity_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timestamp?: InputMaybe<Scalars["Date"]["input"]>;
  timestamp_GT?: InputMaybe<Scalars["Date"]["input"]>;
  timestamp_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  timestamp_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  timestamp_LT?: InputMaybe<Scalars["Date"]["input"]>;
  timestamp_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  trigger?: InputMaybe<Scalars["String"]["input"]>;
  trigger_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  trigger_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  trigger_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  trigger_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type EmotionalStatesConnection = {
  __typename?: "EmotionalStatesConnection";
  edges: Array<EmotionalStateEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Event = {
  __typename?: "Event";
  date?: Maybe<Scalars["Date"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type EventAggregateSelection = {
  __typename?: "EventAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  id: IntAggregateSelection;
  name: StringAggregateSelection;
};

export type EventCreateInput = {
  date?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type EventEdge = {
  __typename?: "EventEdge";
  cursor: Scalars["String"]["output"];
  node: Event;
};

export type EventOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more EventSort objects to sort Events by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<EventSort>>;
};

/** Fields to sort Events by. The order in which sorts are applied is not guaranteed when specifying many fields in one EventSort object. */
export type EventSort = {
  date?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type EventUpdateInput = {
  date?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type EventWhere = {
  AND?: InputMaybe<Array<EventWhere>>;
  NOT?: InputMaybe<EventWhere>;
  OR?: InputMaybe<Array<EventWhere>>;
  date?: InputMaybe<Scalars["Date"]["input"]>;
  date_GT?: InputMaybe<Scalars["Date"]["input"]>;
  date_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  date_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  date_LT?: InputMaybe<Scalars["Date"]["input"]>;
  date_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type EventsConnection = {
  __typename?: "EventsConnection";
  edges: Array<EventEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ExportCsvResponse = {
  __typename?: "ExportCsvResponse";
  csvData?: Maybe<Scalars["String"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type ExportCsvResponseAggregateSelection = {
  __typename?: "ExportCsvResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  csvData: StringAggregateSelection;
  message: StringAggregateSelection;
};

export type ExportCsvResponseCreateInput = {
  csvData?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type ExportCsvResponseEdge = {
  __typename?: "ExportCsvResponseEdge";
  cursor: Scalars["String"]["output"];
  node: ExportCsvResponse;
};

export type ExportCsvResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ExportCsvResponseSort objects to sort ExportCsvResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ExportCsvResponseSort>>;
};

/** Fields to sort ExportCsvResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one ExportCsvResponseSort object. */
export type ExportCsvResponseSort = {
  csvData?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type ExportCsvResponseUpdateInput = {
  csvData?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ExportCsvResponseWhere = {
  AND?: InputMaybe<Array<ExportCsvResponseWhere>>;
  NOT?: InputMaybe<ExportCsvResponseWhere>;
  OR?: InputMaybe<Array<ExportCsvResponseWhere>>;
  csvData?: InputMaybe<Scalars["String"]["input"]>;
  csvData_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  csvData_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  csvData_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  csvData_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ExportCsvResponsesConnection = {
  __typename?: "ExportCsvResponsesConnection";
  edges: Array<ExportCsvResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Feedback = {
  __typename?: "Feedback";
  context?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["Date"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  sentiment?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type FeedbackAggregateSelection = {
  __typename?: "FeedbackAggregateSelection";
  context: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  id: IntAggregateSelection;
  sentiment: StringAggregateSelection;
  text: StringAggregateSelection;
};

export type FeedbackCreateInput = {
  context?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["Date"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type FeedbackEdge = {
  __typename?: "FeedbackEdge";
  cursor: Scalars["String"]["output"];
  node: Feedback;
};

export type FeedbackOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FeedbackSort objects to sort Feedbacks by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FeedbackSort>>;
};

/** Fields to sort Feedbacks by. The order in which sorts are applied is not guaranteed when specifying many fields in one FeedbackSort object. */
export type FeedbackSort = {
  context?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  text?: InputMaybe<SortDirection>;
};

export type FeedbackUpdateInput = {
  context?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["Date"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type FeedbackWhere = {
  AND?: InputMaybe<Array<FeedbackWhere>>;
  NOT?: InputMaybe<FeedbackWhere>;
  OR?: InputMaybe<Array<FeedbackWhere>>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  context_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  context_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  context_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  context_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["Date"]["input"]>;
  date_GT?: InputMaybe<Scalars["Date"]["input"]>;
  date_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  date_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  date_LT?: InputMaybe<Scalars["Date"]["input"]>;
  date_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  text_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  text_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  text_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type FeedbacksConnection = {
  __typename?: "FeedbacksConnection";
  edges: Array<FeedbackEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Field = {
  __typename?: "Field";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  isRelevant?: Maybe<Scalars["Boolean"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
};

export type FieldAggregateSelection = {
  __typename?: "FieldAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  imageUrl: StringAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
};

export type FieldCreateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
};

export type FieldEdge = {
  __typename?: "FieldEdge";
  cursor: Scalars["String"]["output"];
  node: Field;
};

export type FieldOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FieldSort objects to sort Fields by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FieldSort>>;
};

/** Fields to sort Fields by. The order in which sorts are applied is not guaranteed when specifying many fields in one FieldSort object. */
export type FieldSort = {
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  imageUrl?: InputMaybe<SortDirection>;
  isRelevant?: InputMaybe<SortDirection>;
  mastery?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type FieldUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type FieldWhere = {
  AND?: InputMaybe<Array<FieldWhere>>;
  NOT?: InputMaybe<FieldWhere>;
  OR?: InputMaybe<Array<FieldWhere>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  imageUrl_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type FieldsConnection = {
  __typename?: "FieldsConnection";
  edges: Array<FieldEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type File = {
  __typename?: "File";
  encoding: Scalars["String"]["output"];
  filename: Scalars["String"]["output"];
  mimetype: Scalars["String"]["output"];
};

export type FileAggregateSelection = {
  __typename?: "FileAggregateSelection";
  count: Scalars["Int"]["output"];
  encoding: StringAggregateSelection;
  filename: StringAggregateSelection;
  mimetype: StringAggregateSelection;
};

export type FileCreateInput = {
  encoding: Scalars["String"]["input"];
  filename: Scalars["String"]["input"];
  mimetype: Scalars["String"]["input"];
};

export type FileEdge = {
  __typename?: "FileEdge";
  cursor: Scalars["String"]["output"];
  node: File;
};

export type FileOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FileSort objects to sort Files by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FileSort>>;
};

/** Fields to sort Files by. The order in which sorts are applied is not guaranteed when specifying many fields in one FileSort object. */
export type FileSort = {
  encoding?: InputMaybe<SortDirection>;
  filename?: InputMaybe<SortDirection>;
  mimetype?: InputMaybe<SortDirection>;
};

export type FileUpdateInput = {
  encoding?: InputMaybe<Scalars["String"]["input"]>;
  filename?: InputMaybe<Scalars["String"]["input"]>;
  mimetype?: InputMaybe<Scalars["String"]["input"]>;
};

export type FileWhere = {
  AND?: InputMaybe<Array<FileWhere>>;
  NOT?: InputMaybe<FileWhere>;
  OR?: InputMaybe<Array<FileWhere>>;
  encoding?: InputMaybe<Scalars["String"]["input"]>;
  encoding_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  encoding_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  encoding_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  encoding_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  filename?: InputMaybe<Scalars["String"]["input"]>;
  filename_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  filename_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  filename_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  filename_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  mimetype?: InputMaybe<Scalars["String"]["input"]>;
  mimetype_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  mimetype_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  mimetype_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  mimetype_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type FilesConnection = {
  __typename?: "FilesConnection";
  edges: Array<FileEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FloatAggregateSelection = {
  __typename?: "FloatAggregateSelection";
  average?: Maybe<Scalars["Float"]["output"]>;
  max?: Maybe<Scalars["Float"]["output"]>;
  min?: Maybe<Scalars["Float"]["output"]>;
  sum?: Maybe<Scalars["Float"]["output"]>;
};

export type Food = {
  __typename?: "Food";
  calories?: Maybe<Scalars["Float"]["output"]>;
  carbohydrates_grams?: Maybe<Scalars["Float"]["output"]>;
  fat_grams?: Maybe<Scalars["Float"]["output"]>;
  fiber_grams?: Maybe<Scalars["Float"]["output"]>;
  id: Scalars["ID"]["output"];
  name: Scalars["String"]["output"];
  protein_grams?: Maybe<Scalars["Float"]["output"]>;
  serving_size?: Maybe<Scalars["String"]["output"]>;
  sodium_mg?: Maybe<Scalars["Float"]["output"]>;
  sugars_grams?: Maybe<Scalars["Float"]["output"]>;
};

export type FoodAggregateSelection = {
  __typename?: "FoodAggregateSelection";
  calories: FloatAggregateSelection;
  carbohydrates_grams: FloatAggregateSelection;
  count: Scalars["Int"]["output"];
  fat_grams: FloatAggregateSelection;
  fiber_grams: FloatAggregateSelection;
  id: IdAggregateSelection;
  name: StringAggregateSelection;
  protein_grams: FloatAggregateSelection;
  serving_size: StringAggregateSelection;
  sodium_mg: FloatAggregateSelection;
  sugars_grams: FloatAggregateSelection;
};

export type FoodCreateInput = {
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams?: InputMaybe<Scalars["Float"]["input"]>;
  id: Scalars["ID"]["input"];
  name: Scalars["String"]["input"];
  protein_grams?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  sodium_mg?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams?: InputMaybe<Scalars["Float"]["input"]>;
};

export type FoodEdge = {
  __typename?: "FoodEdge";
  cursor: Scalars["String"]["output"];
  node: Food;
};

export type FoodItem = {
  __typename?: "FoodItem";
  calories?: Maybe<Scalars["Float"]["output"]>;
  created: Scalars["DateTime"]["output"];
  date?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["String"]["output"];
  last_modified: Scalars["DateTime"]["output"];
  name: Scalars["String"]["output"];
  serving_size?: Maybe<Scalars["String"]["output"]>;
  sugar_grams?: Maybe<Scalars["Float"]["output"]>;
  usersHasFoodItem: Array<User>;
  usersHasFoodItemAggregate?: Maybe<FoodItemUserUsersHasFoodItemAggregationSelection>;
  usersHasFoodItemConnection: FoodItemUsersHasFoodItemConnection;
  usersundefined: Array<User>;
  usersundefinedAggregate?: Maybe<FoodItemUserUsersundefinedAggregationSelection>;
  usersundefinedConnection: FoodItemUsersundefinedConnection;
};

export type FoodItemUsersHasFoodItemArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type FoodItemUsersHasFoodItemAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type FoodItemUsersHasFoodItemConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<FoodItemUsersHasFoodItemConnectionSort>>;
  where?: InputMaybe<FoodItemUsersHasFoodItemConnectionWhere>;
};

export type FoodItemUsersundefinedArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type FoodItemUsersundefinedAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type FoodItemUsersundefinedConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<FoodItemUsersundefinedConnectionSort>>;
  where?: InputMaybe<FoodItemUsersundefinedConnectionWhere>;
};

export type FoodItemAggregateSelection = {
  __typename?: "FoodItemAggregateSelection";
  calories: FloatAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  date: StringAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  serving_size: StringAggregateSelection;
  sugar_grams: FloatAggregateSelection;
};

export type FoodItemConnectInput = {
  usersHasFoodItem?: InputMaybe<Array<FoodItemUsersHasFoodItemConnectFieldInput>>;
  usersundefined?: InputMaybe<Array<FoodItemUsersundefinedConnectFieldInput>>;
};

export type FoodItemConnectWhere = {
  node: FoodItemWhere;
};

export type FoodItemCreateInput = {
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  created: Scalars["DateTime"]["input"];
  date?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  last_modified: Scalars["DateTime"]["input"];
  name: Scalars["String"]["input"];
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  sugar_grams?: InputMaybe<Scalars["Float"]["input"]>;
  usersHasFoodItem?: InputMaybe<FoodItemUsersHasFoodItemFieldInput>;
  usersundefined?: InputMaybe<FoodItemUsersundefinedFieldInput>;
};

export type FoodItemDeleteInput = {
  usersHasFoodItem?: InputMaybe<Array<FoodItemUsersHasFoodItemDeleteFieldInput>>;
  usersundefined?: InputMaybe<Array<FoodItemUsersundefinedDeleteFieldInput>>;
};

export type FoodItemDisconnectInput = {
  usersHasFoodItem?: InputMaybe<Array<FoodItemUsersHasFoodItemDisconnectFieldInput>>;
  usersundefined?: InputMaybe<Array<FoodItemUsersundefinedDisconnectFieldInput>>;
};

export type FoodItemEdge = {
  __typename?: "FoodItemEdge";
  cursor: Scalars["String"]["output"];
  node: FoodItem;
};

export type FoodItemOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more FoodItemSort objects to sort FoodItems by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<FoodItemSort>>;
};

export type FoodItemRelationInput = {
  usersHasFoodItem?: InputMaybe<Array<FoodItemUsersHasFoodItemCreateFieldInput>>;
  usersundefined?: InputMaybe<Array<FoodItemUsersundefinedCreateFieldInput>>;
};

/** Fields to sort FoodItems by. The order in which sorts are applied is not guaranteed when specifying many fields in one FoodItemSort object. */
export type FoodItemSort = {
  calories?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  serving_size?: InputMaybe<SortDirection>;
  sugar_grams?: InputMaybe<SortDirection>;
};

export type FoodItemUpdateInput = {
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  calories_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  calories_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  sugar_grams?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  usersHasFoodItem?: InputMaybe<Array<FoodItemUsersHasFoodItemUpdateFieldInput>>;
  usersundefined?: InputMaybe<Array<FoodItemUsersundefinedUpdateFieldInput>>;
};

export type FoodItemUserUsersHasFoodItemAggregationSelection = {
  __typename?: "FoodItemUserUsersHasFoodItemAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<FoodItemUserUsersHasFoodItemEdgeAggregateSelection>;
  node?: Maybe<FoodItemUserUsersHasFoodItemNodeAggregateSelection>;
};

export type FoodItemUserUsersHasFoodItemEdgeAggregateSelection = {
  __typename?: "FoodItemUserUsersHasFoodItemEdgeAggregateSelection";
  consumption_date: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  date: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  meal: StringAggregateSelection;
  meal_date: StringAggregateSelection;
  meal_time: StringAggregateSelection;
  meal_type: StringAggregateSelection;
  portion: FloatAggregateSelection;
  quality: BigIntAggregateSelection;
  quantity: FloatAggregateSelection;
  strength: BigIntAggregateSelection;
  time: StringAggregateSelection;
};

export type FoodItemUserUsersHasFoodItemNodeAggregateSelection = {
  __typename?: "FoodItemUserUsersHasFoodItemNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type FoodItemUserUsersundefinedAggregationSelection = {
  __typename?: "FoodItemUserUsersundefinedAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<FoodItemUserUsersundefinedEdgeAggregateSelection>;
  node?: Maybe<FoodItemUserUsersundefinedNodeAggregateSelection>;
};

export type FoodItemUserUsersundefinedEdgeAggregateSelection = {
  __typename?: "FoodItemUserUsersundefinedEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  quality: BigIntAggregateSelection;
  strength: BigIntAggregateSelection;
};

export type FoodItemUserUsersundefinedNodeAggregateSelection = {
  __typename?: "FoodItemUserUsersundefinedNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type FoodItemUsersHasFoodItemAggregateInput = {
  AND?: InputMaybe<Array<FoodItemUsersHasFoodItemAggregateInput>>;
  NOT?: InputMaybe<FoodItemUsersHasFoodItemAggregateInput>;
  OR?: InputMaybe<Array<FoodItemUsersHasFoodItemAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasFoodItemPropertiesAggregationWhereInput>;
  node?: InputMaybe<FoodItemUsersHasFoodItemNodeAggregationWhereInput>;
};

export type FoodItemUsersHasFoodItemConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge: HasFoodItemPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type FoodItemUsersHasFoodItemConnection = {
  __typename?: "FoodItemUsersHasFoodItemConnection";
  edges: Array<FoodItemUsersHasFoodItemRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FoodItemUsersHasFoodItemConnectionSort = {
  edge?: InputMaybe<HasFoodItemPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type FoodItemUsersHasFoodItemConnectionWhere = {
  AND?: InputMaybe<Array<FoodItemUsersHasFoodItemConnectionWhere>>;
  NOT?: InputMaybe<FoodItemUsersHasFoodItemConnectionWhere>;
  OR?: InputMaybe<Array<FoodItemUsersHasFoodItemConnectionWhere>>;
  edge?: InputMaybe<HasFoodItemPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type FoodItemUsersHasFoodItemCreateFieldInput = {
  edge: HasFoodItemPropertiesCreateInput;
  node: UserCreateInput;
};

export type FoodItemUsersHasFoodItemDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<FoodItemUsersHasFoodItemConnectionWhere>;
};

export type FoodItemUsersHasFoodItemDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<FoodItemUsersHasFoodItemConnectionWhere>;
};

export type FoodItemUsersHasFoodItemFieldInput = {
  connect?: InputMaybe<Array<FoodItemUsersHasFoodItemConnectFieldInput>>;
  create?: InputMaybe<Array<FoodItemUsersHasFoodItemCreateFieldInput>>;
};

export type FoodItemUsersHasFoodItemNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<FoodItemUsersHasFoodItemNodeAggregationWhereInput>>;
  NOT?: InputMaybe<FoodItemUsersHasFoodItemNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<FoodItemUsersHasFoodItemNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FoodItemUsersHasFoodItemRelationship = {
  __typename?: "FoodItemUsersHasFoodItemRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasFoodItemProperties;
};

export type FoodItemUsersHasFoodItemUpdateConnectionInput = {
  edge?: InputMaybe<HasFoodItemPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type FoodItemUsersHasFoodItemUpdateFieldInput = {
  connect?: InputMaybe<Array<FoodItemUsersHasFoodItemConnectFieldInput>>;
  create?: InputMaybe<Array<FoodItemUsersHasFoodItemCreateFieldInput>>;
  delete?: InputMaybe<Array<FoodItemUsersHasFoodItemDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<FoodItemUsersHasFoodItemDisconnectFieldInput>>;
  update?: InputMaybe<FoodItemUsersHasFoodItemUpdateConnectionInput>;
  where?: InputMaybe<FoodItemUsersHasFoodItemConnectionWhere>;
};

export type FoodItemUsersundefinedAggregateInput = {
  AND?: InputMaybe<Array<FoodItemUsersundefinedAggregateInput>>;
  NOT?: InputMaybe<FoodItemUsersundefinedAggregateInput>;
  OR?: InputMaybe<Array<FoodItemUsersundefinedAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<UndefinedPropertiesAggregationWhereInput>;
  node?: InputMaybe<FoodItemUsersundefinedNodeAggregationWhereInput>;
};

export type FoodItemUsersundefinedConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge: UndefinedPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type FoodItemUsersundefinedConnection = {
  __typename?: "FoodItemUsersundefinedConnection";
  edges: Array<FoodItemUsersundefinedRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type FoodItemUsersundefinedConnectionSort = {
  edge?: InputMaybe<UndefinedPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type FoodItemUsersundefinedConnectionWhere = {
  AND?: InputMaybe<Array<FoodItemUsersundefinedConnectionWhere>>;
  NOT?: InputMaybe<FoodItemUsersundefinedConnectionWhere>;
  OR?: InputMaybe<Array<FoodItemUsersundefinedConnectionWhere>>;
  edge?: InputMaybe<UndefinedPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type FoodItemUsersundefinedCreateFieldInput = {
  edge: UndefinedPropertiesCreateInput;
  node: UserCreateInput;
};

export type FoodItemUsersundefinedDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<FoodItemUsersundefinedConnectionWhere>;
};

export type FoodItemUsersundefinedDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<FoodItemUsersundefinedConnectionWhere>;
};

export type FoodItemUsersundefinedFieldInput = {
  connect?: InputMaybe<Array<FoodItemUsersundefinedConnectFieldInput>>;
  create?: InputMaybe<Array<FoodItemUsersundefinedCreateFieldInput>>;
};

export type FoodItemUsersundefinedNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<FoodItemUsersundefinedNodeAggregationWhereInput>>;
  NOT?: InputMaybe<FoodItemUsersundefinedNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<FoodItemUsersundefinedNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type FoodItemUsersundefinedRelationship = {
  __typename?: "FoodItemUsersundefinedRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: UndefinedProperties;
};

export type FoodItemUsersundefinedUpdateConnectionInput = {
  edge?: InputMaybe<UndefinedPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type FoodItemUsersundefinedUpdateFieldInput = {
  connect?: InputMaybe<Array<FoodItemUsersundefinedConnectFieldInput>>;
  create?: InputMaybe<Array<FoodItemUsersundefinedCreateFieldInput>>;
  delete?: InputMaybe<Array<FoodItemUsersundefinedDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<FoodItemUsersundefinedDisconnectFieldInput>>;
  update?: InputMaybe<FoodItemUsersundefinedUpdateConnectionInput>;
  where?: InputMaybe<FoodItemUsersundefinedConnectionWhere>;
};

export type FoodItemWhere = {
  AND?: InputMaybe<Array<FoodItemWhere>>;
  NOT?: InputMaybe<FoodItemWhere>;
  OR?: InputMaybe<Array<FoodItemWhere>>;
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  calories_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  calories_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  serving_size_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  serving_size_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  serving_size_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  serving_size_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sugar_grams?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  sugar_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  usersHasFoodItemAggregate?: InputMaybe<FoodItemUsersHasFoodItemAggregateInput>;
  /** Return FoodItems where all of the related FoodItemUsersHasFoodItemConnections match this filter */
  usersHasFoodItemConnection_ALL?: InputMaybe<FoodItemUsersHasFoodItemConnectionWhere>;
  /** Return FoodItems where none of the related FoodItemUsersHasFoodItemConnections match this filter */
  usersHasFoodItemConnection_NONE?: InputMaybe<FoodItemUsersHasFoodItemConnectionWhere>;
  /** Return FoodItems where one of the related FoodItemUsersHasFoodItemConnections match this filter */
  usersHasFoodItemConnection_SINGLE?: InputMaybe<FoodItemUsersHasFoodItemConnectionWhere>;
  /** Return FoodItems where some of the related FoodItemUsersHasFoodItemConnections match this filter */
  usersHasFoodItemConnection_SOME?: InputMaybe<FoodItemUsersHasFoodItemConnectionWhere>;
  /** Return FoodItems where all of the related Users match this filter */
  usersHasFoodItem_ALL?: InputMaybe<UserWhere>;
  /** Return FoodItems where none of the related Users match this filter */
  usersHasFoodItem_NONE?: InputMaybe<UserWhere>;
  /** Return FoodItems where one of the related Users match this filter */
  usersHasFoodItem_SINGLE?: InputMaybe<UserWhere>;
  /** Return FoodItems where some of the related Users match this filter */
  usersHasFoodItem_SOME?: InputMaybe<UserWhere>;
  usersundefinedAggregate?: InputMaybe<FoodItemUsersundefinedAggregateInput>;
  /** Return FoodItems where all of the related FoodItemUsersundefinedConnections match this filter */
  usersundefinedConnection_ALL?: InputMaybe<FoodItemUsersundefinedConnectionWhere>;
  /** Return FoodItems where none of the related FoodItemUsersundefinedConnections match this filter */
  usersundefinedConnection_NONE?: InputMaybe<FoodItemUsersundefinedConnectionWhere>;
  /** Return FoodItems where one of the related FoodItemUsersundefinedConnections match this filter */
  usersundefinedConnection_SINGLE?: InputMaybe<FoodItemUsersundefinedConnectionWhere>;
  /** Return FoodItems where some of the related FoodItemUsersundefinedConnections match this filter */
  usersundefinedConnection_SOME?: InputMaybe<FoodItemUsersundefinedConnectionWhere>;
  /** Return FoodItems where all of the related Users match this filter */
  usersundefined_ALL?: InputMaybe<UserWhere>;
  /** Return FoodItems where none of the related Users match this filter */
  usersundefined_NONE?: InputMaybe<UserWhere>;
  /** Return FoodItems where one of the related Users match this filter */
  usersundefined_SINGLE?: InputMaybe<UserWhere>;
  /** Return FoodItems where some of the related Users match this filter */
  usersundefined_SOME?: InputMaybe<UserWhere>;
};

export type FoodItemsConnection = {
  __typename?: "FoodItemsConnection";
  edges: Array<FoodItemEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort Foods by. The order in which sorts are applied is not guaranteed when specifying many fields in one FoodSort object. */
export type FoodSort = {
  calories?: InputMaybe<SortDirection>;
  carbohydrates_grams?: InputMaybe<SortDirection>;
  fat_grams?: InputMaybe<SortDirection>;
  fiber_grams?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  protein_grams?: InputMaybe<SortDirection>;
  serving_size?: InputMaybe<SortDirection>;
  sodium_mg?: InputMaybe<SortDirection>;
  sugars_grams?: InputMaybe<SortDirection>;
};

export type FoodUpdateInput = {
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  calories_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  calories_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  protein_grams?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  sodium_mg?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type FoodWhere = {
  AND?: InputMaybe<Array<FoodWhere>>;
  NOT?: InputMaybe<FoodWhere>;
  OR?: InputMaybe<Array<FoodWhere>>;
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  calories_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  calories_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  carbohydrates_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  fat_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  fiber_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  protein_grams?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  protein_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  protein_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  serving_size_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  serving_size_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  serving_size_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  serving_size_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sodium_mg?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  sodium_mg_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sodium_mg_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  sugars_grams_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type FoodsConnection = {
  __typename?: "FoodsConnection";
  edges: Array<FoodEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Game = {
  __typename?: "Game";
  assignedQuests?: Maybe<Array<Maybe<AssignedQuest>>>;
  chatId?: Maybe<Scalars["ID"]["output"]>;
  dailyCalorieCompletedForToday?: Maybe<Scalars["Int"]["output"]>;
  dailyCalorieGoalForToday?: Maybe<Scalars["Int"]["output"]>;
  dailyLearningModulesCompletedForToday?: Maybe<Scalars["Int"]["output"]>;
  dailyLearningModulesGoalForToday?: Maybe<Scalars["Int"]["output"]>;
  dailyTaskCompletedForToday?: Maybe<Scalars["Int"]["output"]>;
  dailyTaskGoalForToday?: Maybe<Scalars["Int"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  refreshCounter?: Maybe<Scalars["Int"]["output"]>;
};

export type GameAggregateSelection = {
  __typename?: "GameAggregateSelection";
  chatId: IdAggregateSelection;
  count: Scalars["Int"]["output"];
  dailyCalorieCompletedForToday: IntAggregateSelection;
  dailyCalorieGoalForToday: IntAggregateSelection;
  dailyLearningModulesCompletedForToday: IntAggregateSelection;
  dailyLearningModulesGoalForToday: IntAggregateSelection;
  dailyTaskCompletedForToday: IntAggregateSelection;
  dailyTaskGoalForToday: IntAggregateSelection;
  id: IdAggregateSelection;
  refreshCounter: IntAggregateSelection;
};

export type GameCreateInput = {
  chatId?: InputMaybe<Scalars["ID"]["input"]>;
  dailyCalorieCompletedForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieGoalForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesCompletedForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesGoalForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskCompletedForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskGoalForToday?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  refreshCounter?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GameEdge = {
  __typename?: "GameEdge";
  cursor: Scalars["String"]["output"];
  node: Game;
};

export type GameOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GameSort objects to sort Games by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GameSort>>;
};

/** Fields to sort Games by. The order in which sorts are applied is not guaranteed when specifying many fields in one GameSort object. */
export type GameSort = {
  chatId?: InputMaybe<SortDirection>;
  dailyCalorieCompletedForToday?: InputMaybe<SortDirection>;
  dailyCalorieGoalForToday?: InputMaybe<SortDirection>;
  dailyLearningModulesCompletedForToday?: InputMaybe<SortDirection>;
  dailyLearningModulesGoalForToday?: InputMaybe<SortDirection>;
  dailyTaskCompletedForToday?: InputMaybe<SortDirection>;
  dailyTaskGoalForToday?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  refreshCounter?: InputMaybe<SortDirection>;
};

export type GameUpdateInput = {
  chatId?: InputMaybe<Scalars["ID"]["input"]>;
  dailyCalorieCompletedForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieCompletedForToday_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieCompletedForToday_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieGoalForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieGoalForToday_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieGoalForToday_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesCompletedForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesCompletedForToday_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesCompletedForToday_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesGoalForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesGoalForToday_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesGoalForToday_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskCompletedForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskCompletedForToday_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskCompletedForToday_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskGoalForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskGoalForToday_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskGoalForToday_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  refreshCounter?: InputMaybe<Scalars["Int"]["input"]>;
  refreshCounter_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  refreshCounter_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GameWhere = {
  AND?: InputMaybe<Array<GameWhere>>;
  NOT?: InputMaybe<GameWhere>;
  OR?: InputMaybe<Array<GameWhere>>;
  chatId?: InputMaybe<Scalars["ID"]["input"]>;
  chatId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  chatId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  chatId_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  chatId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  dailyCalorieCompletedForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieCompletedForToday_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieCompletedForToday_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieCompletedForToday_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  dailyCalorieCompletedForToday_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieCompletedForToday_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieGoalForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieGoalForToday_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieGoalForToday_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieGoalForToday_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  dailyCalorieGoalForToday_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyCalorieGoalForToday_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesCompletedForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesCompletedForToday_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesCompletedForToday_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesCompletedForToday_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  dailyLearningModulesCompletedForToday_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesCompletedForToday_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesGoalForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesGoalForToday_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesGoalForToday_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesGoalForToday_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  dailyLearningModulesGoalForToday_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyLearningModulesGoalForToday_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskCompletedForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskCompletedForToday_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskCompletedForToday_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskCompletedForToday_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  dailyTaskCompletedForToday_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskCompletedForToday_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskGoalForToday?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskGoalForToday_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskGoalForToday_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskGoalForToday_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  dailyTaskGoalForToday_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyTaskGoalForToday_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  refreshCounter?: InputMaybe<Scalars["Int"]["input"]>;
  refreshCounter_GT?: InputMaybe<Scalars["Int"]["input"]>;
  refreshCounter_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  refreshCounter_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  refreshCounter_LT?: InputMaybe<Scalars["Int"]["input"]>;
  refreshCounter_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type GamesConnection = {
  __typename?: "GamesConnection";
  edges: Array<GameEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Generated = {
  __typename?: "Generated";
  data?: Maybe<Array<Maybe<Subfield>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type GeneratedAggregateSelection = {
  __typename?: "GeneratedAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type GeneratedCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type GeneratedEdge = {
  __typename?: "GeneratedEdge";
  cursor: Scalars["String"]["output"];
  node: Generated;
};

export type GeneratedOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GeneratedSort objects to sort Generateds by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GeneratedSort>>;
};

/** Fields to sort Generateds by. The order in which sorts are applied is not guaranteed when specifying many fields in one GeneratedSort object. */
export type GeneratedSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type GeneratedUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type GeneratedWhere = {
  AND?: InputMaybe<Array<GeneratedWhere>>;
  NOT?: InputMaybe<GeneratedWhere>;
  OR?: InputMaybe<Array<GeneratedWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type GeneratedsConnection = {
  __typename?: "GeneratedsConnection";
  edges: Array<GeneratedEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GetAssignmentObjectiveResponse = {
  __typename?: "GetAssignmentObjectiveResponse";
  id: Scalars["ID"]["output"];
  objective?: Maybe<Scalars["String"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type GetAssignmentObjectiveResponseAggregateSelection = {
  __typename?: "GetAssignmentObjectiveResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  objective: StringAggregateSelection;
  status: StringAggregateSelection;
};

export type GetAssignmentObjectiveResponseCreateInput = {
  id: Scalars["ID"]["input"];
  objective?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetAssignmentObjectiveResponseEdge = {
  __typename?: "GetAssignmentObjectiveResponseEdge";
  cursor: Scalars["String"]["output"];
  node: GetAssignmentObjectiveResponse;
};

export type GetAssignmentObjectiveResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GetAssignmentObjectiveResponseSort objects to sort GetAssignmentObjectiveResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GetAssignmentObjectiveResponseSort>>;
};

/** Fields to sort GetAssignmentObjectiveResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one GetAssignmentObjectiveResponseSort object. */
export type GetAssignmentObjectiveResponseSort = {
  id?: InputMaybe<SortDirection>;
  objective?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
};

export type GetAssignmentObjectiveResponseUpdateInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  objective?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetAssignmentObjectiveResponseWhere = {
  AND?: InputMaybe<Array<GetAssignmentObjectiveResponseWhere>>;
  NOT?: InputMaybe<GetAssignmentObjectiveResponseWhere>;
  OR?: InputMaybe<Array<GetAssignmentObjectiveResponseWhere>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  objective?: InputMaybe<Scalars["String"]["input"]>;
  objective_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  objective_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  objective_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  objective_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  status_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  status_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  status_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetAssignmentObjectiveResponsesConnection = {
  __typename?: "GetAssignmentObjectiveResponsesConnection";
  edges: Array<GetAssignmentObjectiveResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GetAssignmentResponse = {
  __typename?: "GetAssignmentResponse";
  description?: Maybe<Scalars["String"]["output"]>;
  dueDate?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  objectives?: Maybe<Array<Maybe<GetAssignmentObjectiveResponse>>>;
  title: Scalars["String"]["output"];
  url?: Maybe<Scalars["String"]["output"]>;
};

export type GetAssignmentResponseAggregateSelection = {
  __typename?: "GetAssignmentResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  dueDate: StringAggregateSelection;
  id: IdAggregateSelection;
  title: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type GetAssignmentResponseCreateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  title: Scalars["String"]["input"];
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetAssignmentResponseEdge = {
  __typename?: "GetAssignmentResponseEdge";
  cursor: Scalars["String"]["output"];
  node: GetAssignmentResponse;
};

export type GetAssignmentResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GetAssignmentResponseSort objects to sort GetAssignmentResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GetAssignmentResponseSort>>;
};

/** Fields to sort GetAssignmentResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one GetAssignmentResponseSort object. */
export type GetAssignmentResponseSort = {
  description?: InputMaybe<SortDirection>;
  dueDate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
};

export type GetAssignmentResponseUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetAssignmentResponseWhere = {
  AND?: InputMaybe<Array<GetAssignmentResponseWhere>>;
  NOT?: InputMaybe<GetAssignmentResponseWhere>;
  OR?: InputMaybe<Array<GetAssignmentResponseWhere>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["String"]["input"]>;
  dueDate_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  dueDate_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  dueDate_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  dueDate_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetAssignmentResponsesConnection = {
  __typename?: "GetAssignmentResponsesConnection";
  edges: Array<GetAssignmentResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GetJourneyByIdResponse = {
  __typename?: "GetJourneyByIdResponse";
  created?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  dueDate?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastModified?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  tasks?: Maybe<Array<Maybe<Todo>>>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type GetJourneyByIdResponseAggregateSelection = {
  __typename?: "GetJourneyByIdResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  created: StringAggregateSelection;
  description: StringAggregateSelection;
  dueDate: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: StringAggregateSelection;
  name: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type GetJourneyByIdResponseCreateInput = {
  created?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  lastModified?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetJourneyByIdResponseEdge = {
  __typename?: "GetJourneyByIdResponseEdge";
  cursor: Scalars["String"]["output"];
  node: GetJourneyByIdResponse;
};

export type GetJourneyByIdResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GetJourneyByIdResponseSort objects to sort GetJourneyByIdResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GetJourneyByIdResponseSort>>;
};

/** Fields to sort GetJourneyByIdResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one GetJourneyByIdResponseSort object. */
export type GetJourneyByIdResponseSort = {
  created?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  dueDate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
};

export type GetJourneyByIdResponseUpdateInput = {
  created?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetJourneyByIdResponseWhere = {
  AND?: InputMaybe<Array<GetJourneyByIdResponseWhere>>;
  NOT?: InputMaybe<GetJourneyByIdResponseWhere>;
  OR?: InputMaybe<Array<GetJourneyByIdResponseWhere>>;
  created?: InputMaybe<Scalars["String"]["input"]>;
  created_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  created_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  created_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["String"]["input"]>;
  dueDate_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  dueDate_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  dueDate_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  dueDate_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["String"]["input"]>;
  lastModified_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  lastModified_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  lastModified_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetJourneyByIdResponsesConnection = {
  __typename?: "GetJourneyByIdResponsesConnection";
  edges: Array<GetJourneyByIdResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GetJourneysResponse = {
  __typename?: "GetJourneysResponse";
  completedTasks?: Maybe<Scalars["Int"]["output"]>;
  created?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  dueDate?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastModified?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  totalTasks?: Maybe<Scalars["Int"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
};

export type GetJourneysResponseAggregateSelection = {
  __typename?: "GetJourneysResponseAggregateSelection";
  completedTasks: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  created: StringAggregateSelection;
  description: StringAggregateSelection;
  dueDate: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: StringAggregateSelection;
  name: StringAggregateSelection;
  totalTasks: IntAggregateSelection;
  url: StringAggregateSelection;
};

export type GetJourneysResponseCreateInput = {
  completedTasks?: InputMaybe<Scalars["Int"]["input"]>;
  created?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  lastModified?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  totalTasks?: InputMaybe<Scalars["Int"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetJourneysResponseEdge = {
  __typename?: "GetJourneysResponseEdge";
  cursor: Scalars["String"]["output"];
  node: GetJourneysResponse;
};

export type GetJourneysResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GetJourneysResponseSort objects to sort GetJourneysResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GetJourneysResponseSort>>;
};

/** Fields to sort GetJourneysResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one GetJourneysResponseSort object. */
export type GetJourneysResponseSort = {
  completedTasks?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  dueDate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  totalTasks?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
};

export type GetJourneysResponseUpdateInput = {
  completedTasks?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  created?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  totalTasks?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetJourneysResponseWhere = {
  AND?: InputMaybe<Array<GetJourneysResponseWhere>>;
  NOT?: InputMaybe<GetJourneysResponseWhere>;
  OR?: InputMaybe<Array<GetJourneysResponseWhere>>;
  completedTasks?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  completedTasks_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created?: InputMaybe<Scalars["String"]["input"]>;
  created_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  created_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  created_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["String"]["input"]>;
  dueDate_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  dueDate_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  dueDate_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  dueDate_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["String"]["input"]>;
  lastModified_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  lastModified_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  lastModified_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  totalTasks?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  totalTasks_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type GetJourneysResponsesConnection = {
  __typename?: "GetJourneysResponsesConnection";
  edges: Array<GetJourneysResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Goal = {
  __typename?: "Goal";
  category?: Maybe<Scalars["String"]["output"]>;
  completionStatus?: Maybe<Scalars["String"]["output"]>;
  deadline?: Maybe<Scalars["Date"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  priority?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["Date"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type GoalAggregateSelection = {
  __typename?: "GoalAggregateSelection";
  category: StringAggregateSelection;
  completionStatus: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  id: IntAggregateSelection;
  name: StringAggregateSelection;
  priority: StringAggregateSelection;
  type: StringAggregateSelection;
};

export type GoalCreateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  deadline?: InputMaybe<Scalars["Date"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type GoalEdge = {
  __typename?: "GoalEdge";
  cursor: Scalars["String"]["output"];
  node: Goal;
};

export type GoalOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GoalSort objects to sort Goals by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GoalSort>>;
};

/** Fields to sort Goals by. The order in which sorts are applied is not guaranteed when specifying many fields in one GoalSort object. */
export type GoalSort = {
  category?: InputMaybe<SortDirection>;
  completionStatus?: InputMaybe<SortDirection>;
  deadline?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  priority?: InputMaybe<SortDirection>;
  startDate?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
};

export type GoalUpdateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  deadline?: InputMaybe<Scalars["Date"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type GoalWhere = {
  AND?: InputMaybe<Array<GoalWhere>>;
  NOT?: InputMaybe<GoalWhere>;
  OR?: InputMaybe<Array<GoalWhere>>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  category_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  category_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  category_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  category_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  completionStatus_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  deadline?: InputMaybe<Scalars["Date"]["input"]>;
  deadline_GT?: InputMaybe<Scalars["Date"]["input"]>;
  deadline_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  deadline_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  deadline_LT?: InputMaybe<Scalars["Date"]["input"]>;
  deadline_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  priority_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  priority_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  priority_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  priority_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_GT?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  startDate_LT?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type GoalsConnection = {
  __typename?: "GoalsConnection";
  edges: Array<GoalEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GoogleAuthTokens = {
  __typename?: "GoogleAuthTokens";
  access_token?: Maybe<Scalars["String"]["output"]>;
  expiry_date?: Maybe<Scalars["Float"]["output"]>;
  refresh_token?: Maybe<Scalars["String"]["output"]>;
  scope?: Maybe<Scalars["String"]["output"]>;
  token_type?: Maybe<Scalars["String"]["output"]>;
};

export type GoogleAuthTokensAggregateSelection = {
  __typename?: "GoogleAuthTokensAggregateSelection";
  access_token: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  expiry_date: FloatAggregateSelection;
  refresh_token: StringAggregateSelection;
  scope: StringAggregateSelection;
  token_type: StringAggregateSelection;
};

export type GoogleAuthTokensConnection = {
  __typename?: "GoogleAuthTokensConnection";
  edges: Array<GoogleAuthTokensEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type GoogleAuthTokensCreateInput = {
  access_token?: InputMaybe<Scalars["String"]["input"]>;
  expiry_date?: InputMaybe<Scalars["Float"]["input"]>;
  refresh_token?: InputMaybe<Scalars["String"]["input"]>;
  scope?: InputMaybe<Scalars["String"]["input"]>;
  token_type?: InputMaybe<Scalars["String"]["input"]>;
};

export type GoogleAuthTokensEdge = {
  __typename?: "GoogleAuthTokensEdge";
  cursor: Scalars["String"]["output"];
  node: GoogleAuthTokens;
};

export type GoogleAuthTokensOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more GoogleAuthTokensSort objects to sort GoogleAuthTokens by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<GoogleAuthTokensSort>>;
};

/** Fields to sort GoogleAuthTokens by. The order in which sorts are applied is not guaranteed when specifying many fields in one GoogleAuthTokensSort object. */
export type GoogleAuthTokensSort = {
  access_token?: InputMaybe<SortDirection>;
  expiry_date?: InputMaybe<SortDirection>;
  refresh_token?: InputMaybe<SortDirection>;
  scope?: InputMaybe<SortDirection>;
  token_type?: InputMaybe<SortDirection>;
};

export type GoogleAuthTokensUpdateInput = {
  access_token?: InputMaybe<Scalars["String"]["input"]>;
  expiry_date?: InputMaybe<Scalars["Float"]["input"]>;
  expiry_date_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  expiry_date_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  expiry_date_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  expiry_date_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  refresh_token?: InputMaybe<Scalars["String"]["input"]>;
  scope?: InputMaybe<Scalars["String"]["input"]>;
  token_type?: InputMaybe<Scalars["String"]["input"]>;
};

export type GoogleAuthTokensWhere = {
  AND?: InputMaybe<Array<GoogleAuthTokensWhere>>;
  NOT?: InputMaybe<GoogleAuthTokensWhere>;
  OR?: InputMaybe<Array<GoogleAuthTokensWhere>>;
  access_token?: InputMaybe<Scalars["String"]["input"]>;
  access_token_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  access_token_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  access_token_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  access_token_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  expiry_date?: InputMaybe<Scalars["Float"]["input"]>;
  expiry_date_GT?: InputMaybe<Scalars["Float"]["input"]>;
  expiry_date_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  expiry_date_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  expiry_date_LT?: InputMaybe<Scalars["Float"]["input"]>;
  expiry_date_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  refresh_token?: InputMaybe<Scalars["String"]["input"]>;
  refresh_token_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  refresh_token_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  refresh_token_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  refresh_token_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  scope?: InputMaybe<Scalars["String"]["input"]>;
  scope_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  scope_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  scope_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  scope_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  token_type?: InputMaybe<Scalars["String"]["input"]>;
  token_type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  token_type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  token_type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  token_type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * User.hasChatChats
 * * Journey.hasChatChats
 * * Assignment.hasChatChats
 * * Chat.usersHasChat
 * * Chat.journeysHasChat
 */
export type HasChatProperties = {
  __typename?: "HasChatProperties";
  created?: Maybe<Scalars["DateTime"]["output"]>;
};

export type HasChatPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasChatPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasChatPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasChatPropertiesAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasChatPropertiesCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasChatPropertiesSort = {
  created?: InputMaybe<SortDirection>;
};

export type HasChatPropertiesUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasChatPropertiesWhere = {
  AND?: InputMaybe<Array<HasChatPropertiesWhere>>;
  NOT?: InputMaybe<HasChatPropertiesWhere>;
  OR?: InputMaybe<Array<HasChatPropertiesWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * User.hasFoodItemFoodItems
 * * FoodItem.usersHasFoodItem
 */
export type HasFoodItemProperties = {
  __typename?: "HasFoodItemProperties";
  consumption_date?: Maybe<Scalars["String"]["output"]>;
  created: Scalars["DateTime"]["output"];
  date?: Maybe<Scalars["String"]["output"]>;
  isArchived: Scalars["Boolean"]["output"];
  isHealthyOption?: Maybe<Scalars["Boolean"]["output"]>;
  isPositive?: Maybe<Scalars["Boolean"]["output"]>;
  last_modified: Scalars["DateTime"]["output"];
  meal?: Maybe<Scalars["String"]["output"]>;
  meal_date?: Maybe<Scalars["String"]["output"]>;
  meal_time?: Maybe<Scalars["String"]["output"]>;
  meal_type?: Maybe<Scalars["String"]["output"]>;
  portion?: Maybe<Scalars["Float"]["output"]>;
  quality: Scalars["BigInt"]["output"];
  quantity?: Maybe<Scalars["Float"]["output"]>;
  strength: Scalars["BigInt"]["output"];
  time?: Maybe<Scalars["String"]["output"]>;
};

export type HasFoodItemPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasFoodItemPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasFoodItemPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasFoodItemPropertiesAggregationWhereInput>>;
  consumption_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  consumption_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  consumption_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  consumption_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  consumption_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  consumption_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  consumption_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  consumption_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  consumption_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  consumption_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  consumption_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  consumption_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  consumption_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  consumption_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  consumption_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  meal_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meal_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meal_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meal_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meal_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meal_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meal_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meal_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meal_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meal_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meal_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meal_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meal_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meal_time_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meal_time_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meal_time_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meal_time_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meal_time_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_time_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  meal_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  meal_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  meal_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  meal_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  meal_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  meal_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  portion_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  portion_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  portion_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  portion_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  portion_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  portion_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  quality_AVERAGE_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quantity_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  strength_AVERAGE_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  time_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  time_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  time_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  time_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type HasFoodItemPropertiesCreateInput = {
  consumption_date?: InputMaybe<Scalars["String"]["input"]>;
  created: Scalars["DateTime"]["input"];
  date?: InputMaybe<Scalars["String"]["input"]>;
  isArchived: Scalars["Boolean"]["input"];
  isHealthyOption?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified: Scalars["DateTime"]["input"];
  meal?: InputMaybe<Scalars["String"]["input"]>;
  meal_date?: InputMaybe<Scalars["String"]["input"]>;
  meal_time?: InputMaybe<Scalars["String"]["input"]>;
  meal_type?: InputMaybe<Scalars["String"]["input"]>;
  portion?: InputMaybe<Scalars["Float"]["input"]>;
  quality: Scalars["BigInt"]["input"];
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  strength: Scalars["BigInt"]["input"];
  time?: InputMaybe<Scalars["String"]["input"]>;
};

export type HasFoodItemPropertiesSort = {
  consumption_date?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  isArchived?: InputMaybe<SortDirection>;
  isHealthyOption?: InputMaybe<SortDirection>;
  isPositive?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  meal?: InputMaybe<SortDirection>;
  meal_date?: InputMaybe<SortDirection>;
  meal_time?: InputMaybe<SortDirection>;
  meal_type?: InputMaybe<SortDirection>;
  portion?: InputMaybe<SortDirection>;
  quality?: InputMaybe<SortDirection>;
  quantity?: InputMaybe<SortDirection>;
  strength?: InputMaybe<SortDirection>;
  time?: InputMaybe<SortDirection>;
};

export type HasFoodItemPropertiesUpdateInput = {
  consumption_date?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHealthyOption?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  meal?: InputMaybe<Scalars["String"]["input"]>;
  meal_date?: InputMaybe<Scalars["String"]["input"]>;
  meal_time?: InputMaybe<Scalars["String"]["input"]>;
  meal_type?: InputMaybe<Scalars["String"]["input"]>;
  portion?: InputMaybe<Scalars["Float"]["input"]>;
  portion_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  portion_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  portion_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  portion_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  quality?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_DECREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_INCREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  strength?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_DECREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_INCREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  time?: InputMaybe<Scalars["String"]["input"]>;
};

export type HasFoodItemPropertiesWhere = {
  AND?: InputMaybe<Array<HasFoodItemPropertiesWhere>>;
  NOT?: InputMaybe<HasFoodItemPropertiesWhere>;
  OR?: InputMaybe<Array<HasFoodItemPropertiesWhere>>;
  consumption_date?: InputMaybe<Scalars["String"]["input"]>;
  consumption_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  consumption_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  consumption_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  consumption_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isHealthyOption?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  meal?: InputMaybe<Scalars["String"]["input"]>;
  meal_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  meal_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  meal_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  meal_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  meal_date?: InputMaybe<Scalars["String"]["input"]>;
  meal_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  meal_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  meal_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  meal_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  meal_time?: InputMaybe<Scalars["String"]["input"]>;
  meal_time_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  meal_time_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  meal_time_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  meal_time_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  meal_type?: InputMaybe<Scalars["String"]["input"]>;
  meal_type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  meal_type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  meal_type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  meal_type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  portion?: InputMaybe<Scalars["Float"]["input"]>;
  portion_GT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  portion_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  portion_LT?: InputMaybe<Scalars["Float"]["input"]>;
  portion_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  quality?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_IN?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  quality_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quantity?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_GT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  quantity_LT?: InputMaybe<Scalars["Float"]["input"]>;
  quantity_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  strength?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_IN?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  strength_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  time?: InputMaybe<Scalars["String"]["input"]>;
  time_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  time_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  time_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  time_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * User.hasJourneyJourneys
 * * Journey.usersHasJourney
 */
export type HasJourneyProperties = {
  __typename?: "HasJourneyProperties";
  created?: Maybe<Scalars["Date"]["output"]>;
  last_modified?: Maybe<Scalars["Date"]["output"]>;
};

export type HasJourneyPropertiesCreateInput = {
  created?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified?: InputMaybe<Scalars["Date"]["input"]>;
};

export type HasJourneyPropertiesSort = {
  created?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
};

export type HasJourneyPropertiesUpdateInput = {
  created?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified?: InputMaybe<Scalars["Date"]["input"]>;
};

export type HasJourneyPropertiesWhere = {
  AND?: InputMaybe<Array<HasJourneyPropertiesWhere>>;
  NOT?: InputMaybe<HasJourneyPropertiesWhere>;
  OR?: InputMaybe<Array<HasJourneyPropertiesWhere>>;
  created?: InputMaybe<Scalars["Date"]["input"]>;
  created_GT?: InputMaybe<Scalars["Date"]["input"]>;
  created_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["Date"]["input"]>;
  created_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["Date"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * Message.chatsHasMessage
 * * Chat.hasMessageMessages
 */
export type HasMessageProperties = {
  __typename?: "HasMessageProperties";
  created: Scalars["DateTime"]["output"];
};

export type HasMessagePropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasMessagePropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasMessagePropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasMessagePropertiesAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasMessagePropertiesCreateInput = {
  created: Scalars["DateTime"]["input"];
};

export type HasMessagePropertiesSort = {
  created?: InputMaybe<SortDirection>;
};

export type HasMessagePropertiesUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasMessagePropertiesWhere = {
  AND?: InputMaybe<Array<HasMessagePropertiesWhere>>;
  NOT?: InputMaybe<HasMessagePropertiesWhere>;
  OR?: InputMaybe<Array<HasMessagePropertiesWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * User.hasPaymentPayments
 * * Payment.usersHasPayment
 */
export type HasPaymentProperties = {
  __typename?: "HasPaymentProperties";
  integrationId?: Maybe<Scalars["String"]["output"]>;
  isPro: Scalars["Boolean"]["output"];
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
  stripeCustomerId?: Maybe<Scalars["String"]["output"]>;
  stripeSubscriptionId?: Maybe<Scalars["String"]["output"]>;
  trialEndDate?: Maybe<Scalars["DateTime"]["output"]>;
};

export type HasPaymentPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasPaymentPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasPaymentPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasPaymentPropertiesAggregationWhereInput>>;
  integrationId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trialEndDate_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasPaymentPropertiesCreateInput = {
  integrationId?: InputMaybe<Scalars["String"]["input"]>;
  isPro: Scalars["Boolean"]["input"];
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  stripeCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  trialEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasPaymentPropertiesSort = {
  integrationId?: InputMaybe<SortDirection>;
  isPro?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  stripeCustomerId?: InputMaybe<SortDirection>;
  stripeSubscriptionId?: InputMaybe<SortDirection>;
  trialEndDate?: InputMaybe<SortDirection>;
};

export type HasPaymentPropertiesUpdateInput = {
  integrationId?: InputMaybe<Scalars["String"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  stripeCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  trialEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasPaymentPropertiesWhere = {
  AND?: InputMaybe<Array<HasPaymentPropertiesWhere>>;
  NOT?: InputMaybe<HasPaymentPropertiesWhere>;
  OR?: InputMaybe<Array<HasPaymentPropertiesWhere>>;
  integrationId?: InputMaybe<Scalars["String"]["input"]>;
  integrationId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  integrationId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  integrationId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  integrationId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  stripeCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  stripeCustomerId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  stripeSubscriptionId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  trialEndDate?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  trialEndDate_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  trialEndDate_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * Objective.userHasProgress
 */
export type HasProgressProperties = {
  __typename?: "HasProgressProperties";
  status?: Maybe<Scalars["String"]["output"]>;
};

export type HasProgressPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasProgressPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasProgressPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasProgressPropertiesAggregationWhereInput>>;
  status_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  status_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  status_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  status_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  status_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  status_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  status_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  status_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  status_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  status_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  status_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  status_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  status_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  status_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  status_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type HasProgressPropertiesCreateInput = {
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type HasProgressPropertiesSort = {
  status?: InputMaybe<SortDirection>;
};

export type HasProgressPropertiesUpdateInput = {
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type HasProgressPropertiesWhere = {
  AND?: InputMaybe<Array<HasProgressPropertiesWhere>>;
  NOT?: InputMaybe<HasProgressPropertiesWhere>;
  OR?: InputMaybe<Array<HasProgressPropertiesWhere>>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  status_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  status_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  status_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * User.hasSessionDeviceSessions
 * * DeviceSession.usersHasSession
 */
export type HasSessionProperties = {
  __typename?: "HasSessionProperties";
  createdAt: Scalars["DateTime"]["output"];
  expiresAt: Scalars["DateTime"]["output"];
  isActive: Scalars["Boolean"]["output"];
};

export type HasSessionPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasSessionPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasSessionPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasSessionPropertiesAggregationWhereInput>>;
  createdAt_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasSessionPropertiesCreateInput = {
  createdAt: Scalars["DateTime"]["input"];
  expiresAt: Scalars["DateTime"]["input"];
  isActive: Scalars["Boolean"]["input"];
};

export type HasSessionPropertiesSort = {
  createdAt?: InputMaybe<SortDirection>;
  expiresAt?: InputMaybe<SortDirection>;
  isActive?: InputMaybe<SortDirection>;
};

export type HasSessionPropertiesUpdateInput = {
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type HasSessionPropertiesWhere = {
  AND?: InputMaybe<Array<HasSessionPropertiesWhere>>;
  NOT?: InputMaybe<HasSessionPropertiesWhere>;
  OR?: InputMaybe<Array<HasSessionPropertiesWhere>>;
  createdAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  createdAt_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  createdAt_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  expiresAt_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  expiresAt_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  isActive?: InputMaybe<Scalars["Boolean"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * Journey.hasTaskTodos
 * * Todo.journeysHasTask
 */
export type HasTaskProperties = {
  __typename?: "HasTaskProperties";
  created?: Maybe<Scalars["DateTime"]["output"]>;
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
};

export type HasTaskPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasTaskPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasTaskPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasTaskPropertiesAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasTaskPropertiesCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasTaskPropertiesSort = {
  created?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
};

export type HasTaskPropertiesUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type HasTaskPropertiesWhere = {
  AND?: InputMaybe<Array<HasTaskPropertiesWhere>>;
  NOT?: InputMaybe<HasTaskPropertiesWhere>;
  OR?: InputMaybe<Array<HasTaskPropertiesWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * User.todos
 * * Todo.usersHasTodo
 */
export type HasTodoProperties = {
  __typename?: "HasTodoProperties";
  created?: Maybe<Scalars["DateTime"]["output"]>;
  isArchived?: Maybe<Scalars["Boolean"]["output"]>;
  isPositive?: Maybe<Scalars["Boolean"]["output"]>;
  isRelative?: Maybe<Scalars["Boolean"]["output"]>;
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
  quality?: Maybe<Scalars["BigInt"]["output"]>;
  strength?: Maybe<Scalars["BigInt"]["output"]>;
};

export type HasTodoPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasTodoPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasTodoPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasTodoPropertiesAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality_AVERAGE_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type HasTodoPropertiesCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRelative?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type HasTodoPropertiesSort = {
  created?: InputMaybe<SortDirection>;
  isArchived?: InputMaybe<SortDirection>;
  isPositive?: InputMaybe<SortDirection>;
  isRelative?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  quality?: InputMaybe<SortDirection>;
  strength?: InputMaybe<SortDirection>;
};

export type HasTodoPropertiesUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRelative?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_DECREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_INCREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_DECREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_INCREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type HasTodoPropertiesWhere = {
  AND?: InputMaybe<Array<HasTodoPropertiesWhere>>;
  NOT?: InputMaybe<HasTodoPropertiesWhere>;
  OR?: InputMaybe<Array<HasTodoPropertiesWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRelative?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_IN?: InputMaybe<Array<InputMaybe<Scalars["BigInt"]["input"]>>>;
  quality_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_IN?: InputMaybe<Array<InputMaybe<Scalars["BigInt"]["input"]>>>;
  strength_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
};

/**
 * The edge properties for the following fields:
 * * User.hasWaterIntakeWaterIntakes
 * * WaterIntake.usersHasWaterIntake
 */
export type HasWaterIntakeProperties = {
  __typename?: "HasWaterIntakeProperties";
  created: Scalars["DateTime"]["output"];
  isArchived: Scalars["Boolean"]["output"];
  isPositive?: Maybe<Scalars["Boolean"]["output"]>;
  last_modified: Scalars["DateTime"]["output"];
  quality: Scalars["BigInt"]["output"];
  strength: Scalars["BigInt"]["output"];
};

export type HasWaterIntakePropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<HasWaterIntakePropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<HasWaterIntakePropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<HasWaterIntakePropertiesAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality_AVERAGE_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type HasWaterIntakePropertiesCreateInput = {
  created: Scalars["DateTime"]["input"];
  isArchived: Scalars["Boolean"]["input"];
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified: Scalars["DateTime"]["input"];
  quality: Scalars["BigInt"]["input"];
  strength: Scalars["BigInt"]["input"];
};

export type HasWaterIntakePropertiesSort = {
  created?: InputMaybe<SortDirection>;
  isArchived?: InputMaybe<SortDirection>;
  isPositive?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  quality?: InputMaybe<SortDirection>;
  strength?: InputMaybe<SortDirection>;
};

export type HasWaterIntakePropertiesUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_DECREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_INCREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_DECREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_INCREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type HasWaterIntakePropertiesWhere = {
  AND?: InputMaybe<Array<HasWaterIntakePropertiesWhere>>;
  NOT?: InputMaybe<HasWaterIntakePropertiesWhere>;
  OR?: InputMaybe<Array<HasWaterIntakePropertiesWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_IN?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  quality_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_IN?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  strength_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type HierarchiesConnection = {
  __typename?: "HierarchiesConnection";
  edges: Array<HierarchyEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Hierarchy = {
  __typename?: "Hierarchy";
  completed?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  index?: Maybe<Scalars["Int"]["output"]>;
  isRelevant: Scalars["Boolean"]["output"];
  mastery: Scalars["Float"]["output"];
  name: Scalars["String"]["output"];
  parentName: Scalars["String"]["output"];
};

export type HierarchyAggregateSelection = {
  __typename?: "HierarchyAggregateSelection";
  completed: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  index: IntAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
  parentName: StringAggregateSelection;
};

export type HierarchyCreateInput = {
  completed?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  index?: InputMaybe<Scalars["Int"]["input"]>;
  isRelevant: Scalars["Boolean"]["input"];
  mastery: Scalars["Float"]["input"];
  name: Scalars["String"]["input"];
  parentName: Scalars["String"]["input"];
};

export type HierarchyEdge = {
  __typename?: "HierarchyEdge";
  cursor: Scalars["String"]["output"];
  node: Hierarchy;
};

export type HierarchyNode = {
  __typename?: "HierarchyNode";
  label: Scalars["String"]["output"];
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
};

export type HierarchyNodeAggregateSelection = {
  __typename?: "HierarchyNodeAggregateSelection";
  count: Scalars["Int"]["output"];
  label: StringAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
};

export type HierarchyNodeCreateInput = {
  label: Scalars["String"]["input"];
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
};

export type HierarchyNodeEdge = {
  __typename?: "HierarchyNodeEdge";
  cursor: Scalars["String"]["output"];
  node: HierarchyNode;
};

export type HierarchyNodeOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more HierarchyNodeSort objects to sort HierarchyNodes by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<HierarchyNodeSort>>;
};

/** Fields to sort HierarchyNodes by. The order in which sorts are applied is not guaranteed when specifying many fields in one HierarchyNodeSort object. */
export type HierarchyNodeSort = {
  label?: InputMaybe<SortDirection>;
  mastery?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type HierarchyNodeUpdateInput = {
  label?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type HierarchyNodeWhere = {
  AND?: InputMaybe<Array<HierarchyNodeWhere>>;
  NOT?: InputMaybe<HierarchyNodeWhere>;
  OR?: InputMaybe<Array<HierarchyNodeWhere>>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type HierarchyNodesConnection = {
  __typename?: "HierarchyNodesConnection";
  edges: Array<HierarchyNodeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type HierarchyOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more HierarchySort objects to sort Hierarchies by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<HierarchySort>>;
};

/** Fields to sort Hierarchies by. The order in which sorts are applied is not guaranteed when specifying many fields in one HierarchySort object. */
export type HierarchySort = {
  completed?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  index?: InputMaybe<SortDirection>;
  isRelevant?: InputMaybe<SortDirection>;
  mastery?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  parentName?: InputMaybe<SortDirection>;
};

export type HierarchyUpdateInput = {
  completed?: InputMaybe<Scalars["Int"]["input"]>;
  completed_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  index?: InputMaybe<Scalars["Int"]["input"]>;
  index_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  index_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  parentName?: InputMaybe<Scalars["String"]["input"]>;
};

export type HierarchyWhere = {
  AND?: InputMaybe<Array<HierarchyWhere>>;
  NOT?: InputMaybe<HierarchyWhere>;
  OR?: InputMaybe<Array<HierarchyWhere>>;
  completed?: InputMaybe<Scalars["Int"]["input"]>;
  completed_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completed_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  completed_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  index?: InputMaybe<Scalars["Int"]["input"]>;
  index_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  index_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  mastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  parentName?: InputMaybe<Scalars["String"]["input"]>;
  parentName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  parentName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  parentName_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  parentName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type HobbiesConnection = {
  __typename?: "HobbiesConnection";
  edges: Array<HobbyEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Hobby = {
  __typename?: "Hobby";
  category?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  lastUpdated?: Maybe<Scalars["Date"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["Date"]["output"]>;
};

export type HobbyAggregateSelection = {
  __typename?: "HobbyAggregateSelection";
  category: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  id: IntAggregateSelection;
  name: StringAggregateSelection;
};

export type HobbyCreateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["Date"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type HobbyEdge = {
  __typename?: "HobbyEdge";
  cursor: Scalars["String"]["output"];
  node: Hobby;
};

export type HobbyOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more HobbySort objects to sort Hobbies by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<HobbySort>>;
};

/** Fields to sort Hobbies by. The order in which sorts are applied is not guaranteed when specifying many fields in one HobbySort object. */
export type HobbySort = {
  category?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastUpdated?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  startDate?: InputMaybe<SortDirection>;
};

export type HobbyUpdateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["Date"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type HobbyWhere = {
  AND?: InputMaybe<Array<HobbyWhere>>;
  NOT?: InputMaybe<HobbyWhere>;
  OR?: InputMaybe<Array<HobbyWhere>>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  category_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  category_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  category_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  category_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated_GT?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  lastUpdated_LT?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_GT?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  startDate_LT?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_LTE?: InputMaybe<Scalars["Date"]["input"]>;
};

export type IdAggregateSelection = {
  __typename?: "IDAggregateSelection";
  longest?: Maybe<Scalars["ID"]["output"]>;
  shortest?: Maybe<Scalars["ID"]["output"]>;
};

export type IntAggregateSelection = {
  __typename?: "IntAggregateSelection";
  average?: Maybe<Scalars["Float"]["output"]>;
  max?: Maybe<Scalars["Int"]["output"]>;
  min?: Maybe<Scalars["Int"]["output"]>;
  sum?: Maybe<Scalars["Int"]["output"]>;
};

export type Interest = {
  __typename?: "Interest";
  category?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  lastUpdated?: Maybe<Scalars["Date"]["output"]>;
  level?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["Date"]["output"]>;
};

export type InterestAggregateSelection = {
  __typename?: "InterestAggregateSelection";
  category: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  id: IntAggregateSelection;
  level: StringAggregateSelection;
  name: StringAggregateSelection;
};

export type InterestCreateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["Date"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type InterestEdge = {
  __typename?: "InterestEdge";
  cursor: Scalars["String"]["output"];
  node: Interest;
};

export type InterestOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more InterestSort objects to sort Interests by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<InterestSort>>;
};

/** Fields to sort Interests by. The order in which sorts are applied is not guaranteed when specifying many fields in one InterestSort object. */
export type InterestSort = {
  category?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastUpdated?: InputMaybe<SortDirection>;
  level?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  startDate?: InputMaybe<SortDirection>;
};

export type InterestUpdateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["Date"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type InterestWhere = {
  AND?: InputMaybe<Array<InterestWhere>>;
  NOT?: InputMaybe<InterestWhere>;
  OR?: InputMaybe<Array<InterestWhere>>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  category_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  category_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  category_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  category_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated_GT?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  lastUpdated_LT?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  level_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  level_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  level_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  level_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_GT?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  startDate_LT?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_LTE?: InputMaybe<Scalars["Date"]["input"]>;
};

export type InterestsConnection = {
  __typename?: "InterestsConnection";
  edges: Array<InterestEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type InviteCountResponse = {
  __typename?: "InviteCountResponse";
  count: Scalars["Int"]["output"];
};

export type InviteCountResponseAggregateSelection = {
  __typename?: "InviteCountResponseAggregateSelection";
  count: IntAggregateSelection;
};

export type InviteCountResponseCreateInput = {
  count: Scalars["Int"]["input"];
};

export type InviteCountResponseEdge = {
  __typename?: "InviteCountResponseEdge";
  cursor: Scalars["String"]["output"];
  node: InviteCountResponse;
};

export type InviteCountResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more InviteCountResponseSort objects to sort InviteCountResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<InviteCountResponseSort>>;
};

/** Fields to sort InviteCountResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one InviteCountResponseSort object. */
export type InviteCountResponseSort = {
  count?: InputMaybe<SortDirection>;
};

export type InviteCountResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InviteCountResponseWhere = {
  AND?: InputMaybe<Array<InviteCountResponseWhere>>;
  NOT?: InputMaybe<InviteCountResponseWhere>;
  OR?: InputMaybe<Array<InviteCountResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type InviteCountResponsesConnection = {
  __typename?: "InviteCountResponsesConnection";
  edges: Array<InviteCountResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Journey = {
  __typename?: "Journey";
  _empty?: Maybe<Scalars["String"]["output"]>;
  completed: Scalars["Boolean"]["output"];
  completedTasks?: Maybe<Scalars["Int"]["output"]>;
  created?: Maybe<Scalars["Date"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  dueDate?: Maybe<Scalars["String"]["output"]>;
  hasChatChats: Array<Chat>;
  hasChatChatsAggregate?: Maybe<JourneyChatHasChatChatsAggregationSelection>;
  hasChatChatsConnection: JourneyHasChatChatsConnection;
  hasTaskTodos: Array<Todo>;
  hasTaskTodosAggregate?: Maybe<JourneyTodoHasTaskTodosAggregationSelection>;
  hasTaskTodosConnection: JourneyHasTaskTodosConnection;
  id: Scalars["ID"]["output"];
  last_modified?: Maybe<Scalars["Date"]["output"]>;
  name: Scalars["String"]["output"];
  tasks?: Maybe<Array<Maybe<Todo>>>;
  totalTasks?: Maybe<Scalars["Int"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  usersHasJourney: Array<User>;
  usersHasJourneyAggregate?: Maybe<JourneyUserUsersHasJourneyAggregationSelection>;
  usersHasJourneyConnection: JourneyUsersHasJourneyConnection;
};

export type JourneyHasChatChatsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ChatOptions>;
  where?: InputMaybe<ChatWhere>;
};

export type JourneyHasChatChatsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ChatWhere>;
};

export type JourneyHasChatChatsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<JourneyHasChatChatsConnectionSort>>;
  where?: InputMaybe<JourneyHasChatChatsConnectionWhere>;
};

export type JourneyHasTaskTodosArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<TodoOptions>;
  where?: InputMaybe<TodoWhere>;
};

export type JourneyHasTaskTodosAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<TodoWhere>;
};

export type JourneyHasTaskTodosConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<JourneyHasTaskTodosConnectionSort>>;
  where?: InputMaybe<JourneyHasTaskTodosConnectionWhere>;
};

export type JourneyUsersHasJourneyArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type JourneyUsersHasJourneyAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type JourneyUsersHasJourneyConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<JourneyUsersHasJourneyConnectionSort>>;
  where?: InputMaybe<JourneyUsersHasJourneyConnectionWhere>;
};

export type JourneyAggregateSelection = {
  __typename?: "JourneyAggregateSelection";
  _empty: StringAggregateSelection;
  completedTasks: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  dueDate: StringAggregateSelection;
  id: IdAggregateSelection;
  name: StringAggregateSelection;
  totalTasks: IntAggregateSelection;
  url: StringAggregateSelection;
};

export type JourneyChatHasChatChatsAggregationSelection = {
  __typename?: "JourneyChatHasChatChatsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<JourneyChatHasChatChatsEdgeAggregateSelection>;
  node?: Maybe<JourneyChatHasChatChatsNodeAggregateSelection>;
};

export type JourneyChatHasChatChatsEdgeAggregateSelection = {
  __typename?: "JourneyChatHasChatChatsEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
};

export type JourneyChatHasChatChatsNodeAggregateSelection = {
  __typename?: "JourneyChatHasChatChatsNodeAggregateSelection";
  context: StringAggregateSelection;
  date: StringAggregateSelection;
  id: IdAggregateSelection;
  name: StringAggregateSelection;
  objectId: IdAggregateSelection;
  timezone: StringAggregateSelection;
  url: StringAggregateSelection;
  userId: IdAggregateSelection;
};

export type JourneyConnectInput = {
  hasChatChats?: InputMaybe<Array<JourneyHasChatChatsConnectFieldInput>>;
  hasTaskTodos?: InputMaybe<Array<JourneyHasTaskTodosConnectFieldInput>>;
  usersHasJourney?: InputMaybe<Array<JourneyUsersHasJourneyConnectFieldInput>>;
};

export type JourneyConnectWhere = {
  node: JourneyWhere;
};

export type JourneyCreateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  completed: Scalars["Boolean"]["input"];
  completedTasks?: InputMaybe<Scalars["Int"]["input"]>;
  created?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["String"]["input"]>;
  hasChatChats?: InputMaybe<JourneyHasChatChatsFieldInput>;
  hasTaskTodos?: InputMaybe<JourneyHasTaskTodosFieldInput>;
  id: Scalars["ID"]["input"];
  last_modified?: InputMaybe<Scalars["Date"]["input"]>;
  name: Scalars["String"]["input"];
  totalTasks?: InputMaybe<Scalars["Int"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  usersHasJourney?: InputMaybe<JourneyUsersHasJourneyFieldInput>;
};

export type JourneyDeleteInput = {
  hasChatChats?: InputMaybe<Array<JourneyHasChatChatsDeleteFieldInput>>;
  hasTaskTodos?: InputMaybe<Array<JourneyHasTaskTodosDeleteFieldInput>>;
  usersHasJourney?: InputMaybe<Array<JourneyUsersHasJourneyDeleteFieldInput>>;
};

export type JourneyDisconnectInput = {
  hasChatChats?: InputMaybe<Array<JourneyHasChatChatsDisconnectFieldInput>>;
  hasTaskTodos?: InputMaybe<Array<JourneyHasTaskTodosDisconnectFieldInput>>;
  usersHasJourney?: InputMaybe<Array<JourneyUsersHasJourneyDisconnectFieldInput>>;
};

export type JourneyEdge = {
  __typename?: "JourneyEdge";
  cursor: Scalars["String"]["output"];
  node: Journey;
};

export type JourneyHasChatChatsAggregateInput = {
  AND?: InputMaybe<Array<JourneyHasChatChatsAggregateInput>>;
  NOT?: InputMaybe<JourneyHasChatChatsAggregateInput>;
  OR?: InputMaybe<Array<JourneyHasChatChatsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasChatPropertiesAggregationWhereInput>;
  node?: InputMaybe<JourneyHasChatChatsNodeAggregationWhereInput>;
};

export type JourneyHasChatChatsConnectFieldInput = {
  connect?: InputMaybe<Array<ChatConnectInput>>;
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ChatConnectWhere>;
};

export type JourneyHasChatChatsConnection = {
  __typename?: "JourneyHasChatChatsConnection";
  edges: Array<JourneyHasChatChatsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type JourneyHasChatChatsConnectionSort = {
  edge?: InputMaybe<HasChatPropertiesSort>;
  node?: InputMaybe<ChatSort>;
};

export type JourneyHasChatChatsConnectionWhere = {
  AND?: InputMaybe<Array<JourneyHasChatChatsConnectionWhere>>;
  NOT?: InputMaybe<JourneyHasChatChatsConnectionWhere>;
  OR?: InputMaybe<Array<JourneyHasChatChatsConnectionWhere>>;
  edge?: InputMaybe<HasChatPropertiesWhere>;
  node?: InputMaybe<ChatWhere>;
};

export type JourneyHasChatChatsCreateFieldInput = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: ChatCreateInput;
};

export type JourneyHasChatChatsDeleteFieldInput = {
  delete?: InputMaybe<ChatDeleteInput>;
  where?: InputMaybe<JourneyHasChatChatsConnectionWhere>;
};

export type JourneyHasChatChatsDisconnectFieldInput = {
  disconnect?: InputMaybe<ChatDisconnectInput>;
  where?: InputMaybe<JourneyHasChatChatsConnectionWhere>;
};

export type JourneyHasChatChatsFieldInput = {
  connect?: InputMaybe<Array<JourneyHasChatChatsConnectFieldInput>>;
  create?: InputMaybe<Array<JourneyHasChatChatsCreateFieldInput>>;
};

export type JourneyHasChatChatsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<JourneyHasChatChatsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<JourneyHasChatChatsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<JourneyHasChatChatsNodeAggregationWhereInput>>;
  context_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type JourneyHasChatChatsRelationship = {
  __typename?: "JourneyHasChatChatsRelationship";
  cursor: Scalars["String"]["output"];
  node: Chat;
  properties: HasChatProperties;
};

export type JourneyHasChatChatsUpdateConnectionInput = {
  edge?: InputMaybe<HasChatPropertiesUpdateInput>;
  node?: InputMaybe<ChatUpdateInput>;
};

export type JourneyHasChatChatsUpdateFieldInput = {
  connect?: InputMaybe<Array<JourneyHasChatChatsConnectFieldInput>>;
  create?: InputMaybe<Array<JourneyHasChatChatsCreateFieldInput>>;
  delete?: InputMaybe<Array<JourneyHasChatChatsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<JourneyHasChatChatsDisconnectFieldInput>>;
  update?: InputMaybe<JourneyHasChatChatsUpdateConnectionInput>;
  where?: InputMaybe<JourneyHasChatChatsConnectionWhere>;
};

export type JourneyHasTaskTodosAggregateInput = {
  AND?: InputMaybe<Array<JourneyHasTaskTodosAggregateInput>>;
  NOT?: InputMaybe<JourneyHasTaskTodosAggregateInput>;
  OR?: InputMaybe<Array<JourneyHasTaskTodosAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasTaskPropertiesAggregationWhereInput>;
  node?: InputMaybe<JourneyHasTaskTodosNodeAggregationWhereInput>;
};

export type JourneyHasTaskTodosConnectFieldInput = {
  connect?: InputMaybe<Array<TodoConnectInput>>;
  edge?: InputMaybe<HasTaskPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<TodoConnectWhere>;
};

export type JourneyHasTaskTodosConnection = {
  __typename?: "JourneyHasTaskTodosConnection";
  edges: Array<JourneyHasTaskTodosRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type JourneyHasTaskTodosConnectionSort = {
  edge?: InputMaybe<HasTaskPropertiesSort>;
  node?: InputMaybe<TodoSort>;
};

export type JourneyHasTaskTodosConnectionWhere = {
  AND?: InputMaybe<Array<JourneyHasTaskTodosConnectionWhere>>;
  NOT?: InputMaybe<JourneyHasTaskTodosConnectionWhere>;
  OR?: InputMaybe<Array<JourneyHasTaskTodosConnectionWhere>>;
  edge?: InputMaybe<HasTaskPropertiesWhere>;
  node?: InputMaybe<TodoWhere>;
};

export type JourneyHasTaskTodosCreateFieldInput = {
  edge?: InputMaybe<HasTaskPropertiesCreateInput>;
  node: TodoCreateInput;
};

export type JourneyHasTaskTodosDeleteFieldInput = {
  delete?: InputMaybe<TodoDeleteInput>;
  where?: InputMaybe<JourneyHasTaskTodosConnectionWhere>;
};

export type JourneyHasTaskTodosDisconnectFieldInput = {
  disconnect?: InputMaybe<TodoDisconnectInput>;
  where?: InputMaybe<JourneyHasTaskTodosConnectionWhere>;
};

export type JourneyHasTaskTodosFieldInput = {
  connect?: InputMaybe<Array<JourneyHasTaskTodosConnectFieldInput>>;
  create?: InputMaybe<Array<JourneyHasTaskTodosCreateFieldInput>>;
};

export type JourneyHasTaskTodosNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<JourneyHasTaskTodosNodeAggregationWhereInput>>;
  NOT?: InputMaybe<JourneyHasTaskTodosNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<JourneyHasTaskTodosNodeAggregationWhereInput>>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  priority_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  priority_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  priority_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  priority_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  priority_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  priority_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  priority_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  priority_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  priority_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  priority_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type JourneyHasTaskTodosRelationship = {
  __typename?: "JourneyHasTaskTodosRelationship";
  cursor: Scalars["String"]["output"];
  node: Todo;
  properties: HasTaskProperties;
};

export type JourneyHasTaskTodosUpdateConnectionInput = {
  edge?: InputMaybe<HasTaskPropertiesUpdateInput>;
  node?: InputMaybe<TodoUpdateInput>;
};

export type JourneyHasTaskTodosUpdateFieldInput = {
  connect?: InputMaybe<Array<JourneyHasTaskTodosConnectFieldInput>>;
  create?: InputMaybe<Array<JourneyHasTaskTodosCreateFieldInput>>;
  delete?: InputMaybe<Array<JourneyHasTaskTodosDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<JourneyHasTaskTodosDisconnectFieldInput>>;
  update?: InputMaybe<JourneyHasTaskTodosUpdateConnectionInput>;
  where?: InputMaybe<JourneyHasTaskTodosConnectionWhere>;
};

export type JourneyOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more JourneySort objects to sort Journeys by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<JourneySort>>;
};

export type JourneyRelationInput = {
  hasChatChats?: InputMaybe<Array<JourneyHasChatChatsCreateFieldInput>>;
  hasTaskTodos?: InputMaybe<Array<JourneyHasTaskTodosCreateFieldInput>>;
  usersHasJourney?: InputMaybe<Array<JourneyUsersHasJourneyCreateFieldInput>>;
};

/** Fields to sort Journeys by. The order in which sorts are applied is not guaranteed when specifying many fields in one JourneySort object. */
export type JourneySort = {
  _empty?: InputMaybe<SortDirection>;
  completed?: InputMaybe<SortDirection>;
  completedTasks?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  dueDate?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  totalTasks?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
};

export type JourneyTodoHasTaskTodosAggregationSelection = {
  __typename?: "JourneyTodoHasTaskTodosAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<JourneyTodoHasTaskTodosEdgeAggregateSelection>;
  node?: Maybe<JourneyTodoHasTaskTodosNodeAggregateSelection>;
};

export type JourneyTodoHasTaskTodosEdgeAggregateSelection = {
  __typename?: "JourneyTodoHasTaskTodosEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
};

export type JourneyTodoHasTaskTodosNodeAggregateSelection = {
  __typename?: "JourneyTodoHasTaskTodosNodeAggregateSelection";
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  journeyId: IdAggregateSelection;
  name: StringAggregateSelection;
  pointsReceived: IntAggregateSelection;
  priority: StringAggregateSelection;
  recurringDayOfMonth: IntAggregateSelection;
  recurringDayOfWeek: StringAggregateSelection;
  recurringFrequency: StringAggregateSelection;
  recurringTime: StringAggregateSelection;
  timeOfDay: StringAggregateSelection;
  timezone: StringAggregateSelection;
};

export type JourneyUpdateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  completedTasks?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  created?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["String"]["input"]>;
  hasChatChats?: InputMaybe<Array<JourneyHasChatChatsUpdateFieldInput>>;
  hasTaskTodos?: InputMaybe<Array<JourneyHasTaskTodosUpdateFieldInput>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  last_modified?: InputMaybe<Scalars["Date"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  totalTasks?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  usersHasJourney?: InputMaybe<Array<JourneyUsersHasJourneyUpdateFieldInput>>;
};

export type JourneyUserUsersHasJourneyAggregationSelection = {
  __typename?: "JourneyUserUsersHasJourneyAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<JourneyUserUsersHasJourneyNodeAggregateSelection>;
};

export type JourneyUserUsersHasJourneyNodeAggregateSelection = {
  __typename?: "JourneyUserUsersHasJourneyNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type JourneyUsersHasJourneyAggregateInput = {
  AND?: InputMaybe<Array<JourneyUsersHasJourneyAggregateInput>>;
  NOT?: InputMaybe<JourneyUsersHasJourneyAggregateInput>;
  OR?: InputMaybe<Array<JourneyUsersHasJourneyAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<JourneyUsersHasJourneyNodeAggregationWhereInput>;
};

export type JourneyUsersHasJourneyConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge?: InputMaybe<HasJourneyPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type JourneyUsersHasJourneyConnection = {
  __typename?: "JourneyUsersHasJourneyConnection";
  edges: Array<JourneyUsersHasJourneyRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type JourneyUsersHasJourneyConnectionSort = {
  edge?: InputMaybe<HasJourneyPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type JourneyUsersHasJourneyConnectionWhere = {
  AND?: InputMaybe<Array<JourneyUsersHasJourneyConnectionWhere>>;
  NOT?: InputMaybe<JourneyUsersHasJourneyConnectionWhere>;
  OR?: InputMaybe<Array<JourneyUsersHasJourneyConnectionWhere>>;
  edge?: InputMaybe<HasJourneyPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type JourneyUsersHasJourneyCreateFieldInput = {
  edge?: InputMaybe<HasJourneyPropertiesCreateInput>;
  node: UserCreateInput;
};

export type JourneyUsersHasJourneyDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<JourneyUsersHasJourneyConnectionWhere>;
};

export type JourneyUsersHasJourneyDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<JourneyUsersHasJourneyConnectionWhere>;
};

export type JourneyUsersHasJourneyFieldInput = {
  connect?: InputMaybe<Array<JourneyUsersHasJourneyConnectFieldInput>>;
  create?: InputMaybe<Array<JourneyUsersHasJourneyCreateFieldInput>>;
};

export type JourneyUsersHasJourneyNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<JourneyUsersHasJourneyNodeAggregationWhereInput>>;
  NOT?: InputMaybe<JourneyUsersHasJourneyNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<JourneyUsersHasJourneyNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type JourneyUsersHasJourneyRelationship = {
  __typename?: "JourneyUsersHasJourneyRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasJourneyProperties;
};

export type JourneyUsersHasJourneyUpdateConnectionInput = {
  edge?: InputMaybe<HasJourneyPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type JourneyUsersHasJourneyUpdateFieldInput = {
  connect?: InputMaybe<Array<JourneyUsersHasJourneyConnectFieldInput>>;
  create?: InputMaybe<Array<JourneyUsersHasJourneyCreateFieldInput>>;
  delete?: InputMaybe<Array<JourneyUsersHasJourneyDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<JourneyUsersHasJourneyDisconnectFieldInput>>;
  update?: InputMaybe<JourneyUsersHasJourneyUpdateConnectionInput>;
  where?: InputMaybe<JourneyUsersHasJourneyConnectionWhere>;
};

export type JourneyWhere = {
  AND?: InputMaybe<Array<JourneyWhere>>;
  NOT?: InputMaybe<JourneyWhere>;
  OR?: InputMaybe<Array<JourneyWhere>>;
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  _empty_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  _empty_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  _empty_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  _empty_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  completedTasks?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  completedTasks_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created?: InputMaybe<Scalars["Date"]["input"]>;
  created_GT?: InputMaybe<Scalars["Date"]["input"]>;
  created_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["Date"]["input"]>;
  created_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["String"]["input"]>;
  dueDate_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  dueDate_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  dueDate_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  dueDate_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  hasChatChatsAggregate?: InputMaybe<JourneyHasChatChatsAggregateInput>;
  /** Return Journeys where all of the related JourneyHasChatChatsConnections match this filter */
  hasChatChatsConnection_ALL?: InputMaybe<JourneyHasChatChatsConnectionWhere>;
  /** Return Journeys where none of the related JourneyHasChatChatsConnections match this filter */
  hasChatChatsConnection_NONE?: InputMaybe<JourneyHasChatChatsConnectionWhere>;
  /** Return Journeys where one of the related JourneyHasChatChatsConnections match this filter */
  hasChatChatsConnection_SINGLE?: InputMaybe<JourneyHasChatChatsConnectionWhere>;
  /** Return Journeys where some of the related JourneyHasChatChatsConnections match this filter */
  hasChatChatsConnection_SOME?: InputMaybe<JourneyHasChatChatsConnectionWhere>;
  /** Return Journeys where all of the related Chats match this filter */
  hasChatChats_ALL?: InputMaybe<ChatWhere>;
  /** Return Journeys where none of the related Chats match this filter */
  hasChatChats_NONE?: InputMaybe<ChatWhere>;
  /** Return Journeys where one of the related Chats match this filter */
  hasChatChats_SINGLE?: InputMaybe<ChatWhere>;
  /** Return Journeys where some of the related Chats match this filter */
  hasChatChats_SOME?: InputMaybe<ChatWhere>;
  hasTaskTodosAggregate?: InputMaybe<JourneyHasTaskTodosAggregateInput>;
  /** Return Journeys where all of the related JourneyHasTaskTodosConnections match this filter */
  hasTaskTodosConnection_ALL?: InputMaybe<JourneyHasTaskTodosConnectionWhere>;
  /** Return Journeys where none of the related JourneyHasTaskTodosConnections match this filter */
  hasTaskTodosConnection_NONE?: InputMaybe<JourneyHasTaskTodosConnectionWhere>;
  /** Return Journeys where one of the related JourneyHasTaskTodosConnections match this filter */
  hasTaskTodosConnection_SINGLE?: InputMaybe<JourneyHasTaskTodosConnectionWhere>;
  /** Return Journeys where some of the related JourneyHasTaskTodosConnections match this filter */
  hasTaskTodosConnection_SOME?: InputMaybe<JourneyHasTaskTodosConnectionWhere>;
  /** Return Journeys where all of the related Todos match this filter */
  hasTaskTodos_ALL?: InputMaybe<TodoWhere>;
  /** Return Journeys where none of the related Todos match this filter */
  hasTaskTodos_NONE?: InputMaybe<TodoWhere>;
  /** Return Journeys where one of the related Todos match this filter */
  hasTaskTodos_SINGLE?: InputMaybe<TodoWhere>;
  /** Return Journeys where some of the related Todos match this filter */
  hasTaskTodos_SOME?: InputMaybe<TodoWhere>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  last_modified?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  totalTasks?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  totalTasks_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasJourneyAggregate?: InputMaybe<JourneyUsersHasJourneyAggregateInput>;
  /** Return Journeys where all of the related JourneyUsersHasJourneyConnections match this filter */
  usersHasJourneyConnection_ALL?: InputMaybe<JourneyUsersHasJourneyConnectionWhere>;
  /** Return Journeys where none of the related JourneyUsersHasJourneyConnections match this filter */
  usersHasJourneyConnection_NONE?: InputMaybe<JourneyUsersHasJourneyConnectionWhere>;
  /** Return Journeys where one of the related JourneyUsersHasJourneyConnections match this filter */
  usersHasJourneyConnection_SINGLE?: InputMaybe<JourneyUsersHasJourneyConnectionWhere>;
  /** Return Journeys where some of the related JourneyUsersHasJourneyConnections match this filter */
  usersHasJourneyConnection_SOME?: InputMaybe<JourneyUsersHasJourneyConnectionWhere>;
  /** Return Journeys where all of the related Users match this filter */
  usersHasJourney_ALL?: InputMaybe<UserWhere>;
  /** Return Journeys where none of the related Users match this filter */
  usersHasJourney_NONE?: InputMaybe<UserWhere>;
  /** Return Journeys where one of the related Users match this filter */
  usersHasJourney_SINGLE?: InputMaybe<UserWhere>;
  /** Return Journeys where some of the related Users match this filter */
  usersHasJourney_SOME?: InputMaybe<UserWhere>;
};

export type JourneysConnection = {
  __typename?: "JourneysConnection";
  edges: Array<JourneyEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LearningNode = {
  __typename?: "LearningNode";
  description?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  label?: Maybe<Scalars["String"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
  unitsCount?: Maybe<Scalars["Int"]["output"]>;
};

export type LearningNodeAggregateSelection = {
  __typename?: "LearningNodeAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  imageUrl: StringAggregateSelection;
  label: StringAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
  unitsCount: IntAggregateSelection;
};

export type LearningNodeCreateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  unitsCount?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningNodeEdge = {
  __typename?: "LearningNodeEdge";
  cursor: Scalars["String"]["output"];
  node: LearningNode;
};

export type LearningNodeOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more LearningNodeSort objects to sort LearningNodes by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<LearningNodeSort>>;
};

/** Fields to sort LearningNodes by. The order in which sorts are applied is not guaranteed when specifying many fields in one LearningNodeSort object. */
export type LearningNodeSort = {
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  imageUrl?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  mastery?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  unitsCount?: InputMaybe<SortDirection>;
};

export type LearningNodeUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  unitsCount?: InputMaybe<Scalars["Int"]["input"]>;
  unitsCount_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  unitsCount_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningNodeWhere = {
  AND?: InputMaybe<Array<LearningNodeWhere>>;
  NOT?: InputMaybe<LearningNodeWhere>;
  OR?: InputMaybe<Array<LearningNodeWhere>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  imageUrl_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  unitsCount?: InputMaybe<Scalars["Int"]["input"]>;
  unitsCount_GT?: InputMaybe<Scalars["Int"]["input"]>;
  unitsCount_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  unitsCount_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  unitsCount_LT?: InputMaybe<Scalars["Int"]["input"]>;
  unitsCount_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningNodesConnection = {
  __typename?: "LearningNodesConnection";
  edges: Array<LearningNodeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LearningObjective = {
  __typename?: "LearningObjective";
  completed?: Maybe<Scalars["Int"]["output"]>;
  description: Scalars["String"]["output"];
  id: Scalars["ID"]["output"];
  index?: Maybe<Scalars["Int"]["output"]>;
  title: Scalars["String"]["output"];
  total_mastery?: Maybe<Scalars["Int"]["output"]>;
};

export type LearningObjectiveAggregateSelection = {
  __typename?: "LearningObjectiveAggregateSelection";
  completed: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  index: IntAggregateSelection;
  title: StringAggregateSelection;
  total_mastery: IntAggregateSelection;
};

export type LearningObjectiveCreateInput = {
  completed?: InputMaybe<Scalars["Int"]["input"]>;
  description: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  index?: InputMaybe<Scalars["Int"]["input"]>;
  title: Scalars["String"]["input"];
  total_mastery?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningObjectiveEdge = {
  __typename?: "LearningObjectiveEdge";
  cursor: Scalars["String"]["output"];
  node: LearningObjective;
};

export type LearningObjectiveOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more LearningObjectiveSort objects to sort LearningObjectives by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<LearningObjectiveSort>>;
};

export type LearningObjectiveResponse = {
  __typename?: "LearningObjectiveResponse";
  completed?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
  title?: Maybe<Scalars["String"]["output"]>;
};

export type LearningObjectiveResponseAggregateSelection = {
  __typename?: "LearningObjectiveResponseAggregateSelection";
  completed: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  message: StringAggregateSelection;
  title: StringAggregateSelection;
};

export type LearningObjectiveResponseCreateInput = {
  completed?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type LearningObjectiveResponseEdge = {
  __typename?: "LearningObjectiveResponseEdge";
  cursor: Scalars["String"]["output"];
  node: LearningObjectiveResponse;
};

export type LearningObjectiveResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more LearningObjectiveResponseSort objects to sort LearningObjectiveResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<LearningObjectiveResponseSort>>;
};

/** Fields to sort LearningObjectiveResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one LearningObjectiveResponseSort object. */
export type LearningObjectiveResponseSort = {
  completed?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
};

export type LearningObjectiveResponseUpdateInput = {
  completed?: InputMaybe<Scalars["Int"]["input"]>;
  completed_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type LearningObjectiveResponseWhere = {
  AND?: InputMaybe<Array<LearningObjectiveResponseWhere>>;
  NOT?: InputMaybe<LearningObjectiveResponseWhere>;
  OR?: InputMaybe<Array<LearningObjectiveResponseWhere>>;
  completed?: InputMaybe<Scalars["Int"]["input"]>;
  completed_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completed_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  completed_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type LearningObjectiveResponsesConnection = {
  __typename?: "LearningObjectiveResponsesConnection";
  edges: Array<LearningObjectiveResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort LearningObjectives by. The order in which sorts are applied is not guaranteed when specifying many fields in one LearningObjectiveSort object. */
export type LearningObjectiveSort = {
  completed?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  index?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  total_mastery?: InputMaybe<SortDirection>;
};

export type LearningObjectiveUpdateInput = {
  completed?: InputMaybe<Scalars["Int"]["input"]>;
  completed_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  index?: InputMaybe<Scalars["Int"]["input"]>;
  index_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  index_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  total_mastery?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningObjectiveWhere = {
  AND?: InputMaybe<Array<LearningObjectiveWhere>>;
  NOT?: InputMaybe<LearningObjectiveWhere>;
  OR?: InputMaybe<Array<LearningObjectiveWhere>>;
  completed?: InputMaybe<Scalars["Int"]["input"]>;
  completed_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completed_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  completed_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completed_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  index?: InputMaybe<Scalars["Int"]["input"]>;
  index_GT?: InputMaybe<Scalars["Int"]["input"]>;
  index_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  index_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  index_LT?: InputMaybe<Scalars["Int"]["input"]>;
  index_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  total_mastery?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_GT?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  total_mastery_LT?: InputMaybe<Scalars["Int"]["input"]>;
  total_mastery_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningObjectivesConnection = {
  __typename?: "LearningObjectivesConnection";
  edges: Array<LearningObjectiveEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LearningSummariesConnection = {
  __typename?: "LearningSummariesConnection";
  edges: Array<LearningSummaryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LearningSummary = {
  __typename?: "LearningSummary";
  subchaptersProgressed?: Maybe<Scalars["Int"]["output"]>;
};

export type LearningSummaryAggregateSelection = {
  __typename?: "LearningSummaryAggregateSelection";
  count: Scalars["Int"]["output"];
  subchaptersProgressed: IntAggregateSelection;
};

export type LearningSummaryCreateInput = {
  subchaptersProgressed?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningSummaryEdge = {
  __typename?: "LearningSummaryEdge";
  cursor: Scalars["String"]["output"];
  node: LearningSummary;
};

export type LearningSummaryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more LearningSummarySort objects to sort LearningSummaries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<LearningSummarySort>>;
};

/** Fields to sort LearningSummaries by. The order in which sorts are applied is not guaranteed when specifying many fields in one LearningSummarySort object. */
export type LearningSummarySort = {
  subchaptersProgressed?: InputMaybe<SortDirection>;
};

export type LearningSummaryUpdateInput = {
  subchaptersProgressed?: InputMaybe<Scalars["Int"]["input"]>;
  subchaptersProgressed_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  subchaptersProgressed_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningSummaryWhere = {
  AND?: InputMaybe<Array<LearningSummaryWhere>>;
  NOT?: InputMaybe<LearningSummaryWhere>;
  OR?: InputMaybe<Array<LearningSummaryWhere>>;
  subchaptersProgressed?: InputMaybe<Scalars["Int"]["input"]>;
  subchaptersProgressed_GT?: InputMaybe<Scalars["Int"]["input"]>;
  subchaptersProgressed_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  subchaptersProgressed_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  subchaptersProgressed_LT?: InputMaybe<Scalars["Int"]["input"]>;
  subchaptersProgressed_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LearningTree = {
  __typename?: "LearningTree";
  ChapterName?: Maybe<Scalars["String"]["output"]>;
  FieldName?: Maybe<Scalars["String"]["output"]>;
  SubchapterName?: Maybe<Scalars["String"]["output"]>;
  SubfieldName?: Maybe<Scalars["String"]["output"]>;
  TopicName?: Maybe<Scalars["String"]["output"]>;
};

export type LearningTreeAggregateSelection = {
  __typename?: "LearningTreeAggregateSelection";
  ChapterName: StringAggregateSelection;
  FieldName: StringAggregateSelection;
  SubchapterName: StringAggregateSelection;
  SubfieldName: StringAggregateSelection;
  TopicName: StringAggregateSelection;
  count: Scalars["Int"]["output"];
};

export type LearningTreeCreateInput = {
  ChapterName?: InputMaybe<Scalars["String"]["input"]>;
  FieldName?: InputMaybe<Scalars["String"]["input"]>;
  SubchapterName?: InputMaybe<Scalars["String"]["input"]>;
  SubfieldName?: InputMaybe<Scalars["String"]["input"]>;
  TopicName?: InputMaybe<Scalars["String"]["input"]>;
};

export type LearningTreeEdge = {
  __typename?: "LearningTreeEdge";
  cursor: Scalars["String"]["output"];
  node: LearningTree;
};

export type LearningTreeOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more LearningTreeSort objects to sort LearningTrees by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<LearningTreeSort>>;
};

/** Fields to sort LearningTrees by. The order in which sorts are applied is not guaranteed when specifying many fields in one LearningTreeSort object. */
export type LearningTreeSort = {
  ChapterName?: InputMaybe<SortDirection>;
  FieldName?: InputMaybe<SortDirection>;
  SubchapterName?: InputMaybe<SortDirection>;
  SubfieldName?: InputMaybe<SortDirection>;
  TopicName?: InputMaybe<SortDirection>;
};

export type LearningTreeUpdateInput = {
  ChapterName?: InputMaybe<Scalars["String"]["input"]>;
  FieldName?: InputMaybe<Scalars["String"]["input"]>;
  SubchapterName?: InputMaybe<Scalars["String"]["input"]>;
  SubfieldName?: InputMaybe<Scalars["String"]["input"]>;
  TopicName?: InputMaybe<Scalars["String"]["input"]>;
};

export type LearningTreeWhere = {
  AND?: InputMaybe<Array<LearningTreeWhere>>;
  ChapterName?: InputMaybe<Scalars["String"]["input"]>;
  ChapterName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  ChapterName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  ChapterName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  ChapterName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  FieldName?: InputMaybe<Scalars["String"]["input"]>;
  FieldName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  FieldName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  FieldName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  FieldName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  NOT?: InputMaybe<LearningTreeWhere>;
  OR?: InputMaybe<Array<LearningTreeWhere>>;
  SubchapterName?: InputMaybe<Scalars["String"]["input"]>;
  SubchapterName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  SubchapterName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  SubchapterName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  SubchapterName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  SubfieldName?: InputMaybe<Scalars["String"]["input"]>;
  SubfieldName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  SubfieldName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  SubfieldName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  SubfieldName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  TopicName?: InputMaybe<Scalars["String"]["input"]>;
  TopicName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  TopicName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  TopicName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  TopicName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type LearningTreesConnection = {
  __typename?: "LearningTreesConnection";
  edges: Array<LearningTreeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Lesson = {
  __typename?: "Lesson";
  category?: Maybe<Scalars["String"]["output"]>;
  completionStatus?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  difficultyLevel?: Maybe<Scalars["String"]["output"]>;
  endDate?: Maybe<Scalars["Date"]["output"]>;
  format?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["Date"]["output"]>;
};

export type LessonAggregateSelection = {
  __typename?: "LessonAggregateSelection";
  category: StringAggregateSelection;
  completionStatus: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  difficultyLevel: StringAggregateSelection;
  format: StringAggregateSelection;
  id: IntAggregateSelection;
  name: StringAggregateSelection;
};

export type LessonCreateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  format?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type LessonEdge = {
  __typename?: "LessonEdge";
  cursor: Scalars["String"]["output"];
  node: Lesson;
};

export type LessonOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more LessonSort objects to sort Lessons by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<LessonSort>>;
};

/** Fields to sort Lessons by. The order in which sorts are applied is not guaranteed when specifying many fields in one LessonSort object. */
export type LessonSort = {
  category?: InputMaybe<SortDirection>;
  completionStatus?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  difficultyLevel?: InputMaybe<SortDirection>;
  endDate?: InputMaybe<SortDirection>;
  format?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  startDate?: InputMaybe<SortDirection>;
};

export type LessonUpdateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  format?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
};

export type LessonWhere = {
  AND?: InputMaybe<Array<LessonWhere>>;
  NOT?: InputMaybe<LessonWhere>;
  OR?: InputMaybe<Array<LessonWhere>>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  category_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  category_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  category_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  category_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  completionStatus_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  difficultyLevel_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  endDate_GT?: InputMaybe<Scalars["Date"]["input"]>;
  endDate_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  endDate_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  endDate_LT?: InputMaybe<Scalars["Date"]["input"]>;
  endDate_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  format?: InputMaybe<Scalars["String"]["input"]>;
  format_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  format_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  format_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  format_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_GT?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  startDate_LT?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_LTE?: InputMaybe<Scalars["Date"]["input"]>;
};

export type LessonsConnection = {
  __typename?: "LessonsConnection";
  edges: Array<LessonEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LevelDetails = {
  __typename?: "LevelDetails";
  currentXP: Scalars["Int"]["output"];
  level: Scalars["Int"]["output"];
  name?: Maybe<Scalars["String"]["output"]>;
  progressPercentage: Scalars["Int"]["output"];
  startingXPForCurrentLevel: Scalars["Int"]["output"];
  xpToNextLevel: Scalars["Int"]["output"];
};

export type LevelDetailsAggregateSelection = {
  __typename?: "LevelDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
  currentXP: IntAggregateSelection;
  level: IntAggregateSelection;
  name: StringAggregateSelection;
  progressPercentage: IntAggregateSelection;
  startingXPForCurrentLevel: IntAggregateSelection;
  xpToNextLevel: IntAggregateSelection;
};

export type LevelDetailsConnection = {
  __typename?: "LevelDetailsConnection";
  edges: Array<LevelDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type LevelDetailsCreateInput = {
  currentXP: Scalars["Int"]["input"];
  level: Scalars["Int"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  progressPercentage: Scalars["Int"]["input"];
  startingXPForCurrentLevel: Scalars["Int"]["input"];
  xpToNextLevel: Scalars["Int"]["input"];
};

export type LevelDetailsEdge = {
  __typename?: "LevelDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: LevelDetails;
};

export type LevelDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more LevelDetailsSort objects to sort LevelDetails by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<LevelDetailsSort>>;
};

/** Fields to sort LevelDetails by. The order in which sorts are applied is not guaranteed when specifying many fields in one LevelDetailsSort object. */
export type LevelDetailsSort = {
  currentXP?: InputMaybe<SortDirection>;
  level?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  progressPercentage?: InputMaybe<SortDirection>;
  startingXPForCurrentLevel?: InputMaybe<SortDirection>;
  xpToNextLevel?: InputMaybe<SortDirection>;
};

export type LevelDetailsUpdateInput = {
  currentXP?: InputMaybe<Scalars["Int"]["input"]>;
  currentXP_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  currentXP_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  level?: InputMaybe<Scalars["Int"]["input"]>;
  level_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  level_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  progressPercentage?: InputMaybe<Scalars["Int"]["input"]>;
  progressPercentage_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  progressPercentage_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  startingXPForCurrentLevel?: InputMaybe<Scalars["Int"]["input"]>;
  startingXPForCurrentLevel_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  startingXPForCurrentLevel_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  xpToNextLevel?: InputMaybe<Scalars["Int"]["input"]>;
  xpToNextLevel_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  xpToNextLevel_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LevelDetailsWhere = {
  AND?: InputMaybe<Array<LevelDetailsWhere>>;
  NOT?: InputMaybe<LevelDetailsWhere>;
  OR?: InputMaybe<Array<LevelDetailsWhere>>;
  currentXP?: InputMaybe<Scalars["Int"]["input"]>;
  currentXP_GT?: InputMaybe<Scalars["Int"]["input"]>;
  currentXP_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  currentXP_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  currentXP_LT?: InputMaybe<Scalars["Int"]["input"]>;
  currentXP_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  level?: InputMaybe<Scalars["Int"]["input"]>;
  level_GT?: InputMaybe<Scalars["Int"]["input"]>;
  level_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  level_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  level_LT?: InputMaybe<Scalars["Int"]["input"]>;
  level_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  progressPercentage?: InputMaybe<Scalars["Int"]["input"]>;
  progressPercentage_GT?: InputMaybe<Scalars["Int"]["input"]>;
  progressPercentage_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  progressPercentage_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  progressPercentage_LT?: InputMaybe<Scalars["Int"]["input"]>;
  progressPercentage_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  startingXPForCurrentLevel?: InputMaybe<Scalars["Int"]["input"]>;
  startingXPForCurrentLevel_GT?: InputMaybe<Scalars["Int"]["input"]>;
  startingXPForCurrentLevel_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  startingXPForCurrentLevel_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  startingXPForCurrentLevel_LT?: InputMaybe<Scalars["Int"]["input"]>;
  startingXPForCurrentLevel_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  xpToNextLevel?: InputMaybe<Scalars["Int"]["input"]>;
  xpToNextLevel_GT?: InputMaybe<Scalars["Int"]["input"]>;
  xpToNextLevel_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  xpToNextLevel_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  xpToNextLevel_LT?: InputMaybe<Scalars["Int"]["input"]>;
  xpToNextLevel_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type LifeAspiration = {
  __typename?: "LifeAspiration";
  category?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  lastUpdated?: Maybe<Scalars["Date"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  startDate?: Maybe<Scalars["Date"]["output"]>;
  status?: Maybe<Scalars["String"]["output"]>;
};

export type LifeAspirationAggregateSelection = {
  __typename?: "LifeAspirationAggregateSelection";
  category: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  id: IntAggregateSelection;
  name: StringAggregateSelection;
  status: StringAggregateSelection;
};

export type LifeAspirationCreateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["Date"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type LifeAspirationEdge = {
  __typename?: "LifeAspirationEdge";
  cursor: Scalars["String"]["output"];
  node: LifeAspiration;
};

export type LifeAspirationOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more LifeAspirationSort objects to sort LifeAspirations by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<LifeAspirationSort>>;
};

/** Fields to sort LifeAspirations by. The order in which sorts are applied is not guaranteed when specifying many fields in one LifeAspirationSort object. */
export type LifeAspirationSort = {
  category?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastUpdated?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  startDate?: InputMaybe<SortDirection>;
  status?: InputMaybe<SortDirection>;
};

export type LifeAspirationUpdateInput = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["Date"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
};

export type LifeAspirationWhere = {
  AND?: InputMaybe<Array<LifeAspirationWhere>>;
  NOT?: InputMaybe<LifeAspirationWhere>;
  OR?: InputMaybe<Array<LifeAspirationWhere>>;
  category?: InputMaybe<Scalars["String"]["input"]>;
  category_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  category_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  category_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  category_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastUpdated?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated_GT?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  lastUpdated_LT?: InputMaybe<Scalars["Date"]["input"]>;
  lastUpdated_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_GT?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  startDate_LT?: InputMaybe<Scalars["Date"]["input"]>;
  startDate_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  status?: InputMaybe<Scalars["String"]["input"]>;
  status_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  status_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  status_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  status_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type LifeAspirationsConnection = {
  __typename?: "LifeAspirationsConnection";
  edges: Array<LifeAspirationEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type MasteryResult = {
  __typename?: "MasteryResult";
  learningObjectiveId?: Maybe<Scalars["ID"]["output"]>;
  totalMastery?: Maybe<Scalars["Float"]["output"]>;
  userId?: Maybe<Scalars["ID"]["output"]>;
};

export type MasteryResultAggregateSelection = {
  __typename?: "MasteryResultAggregateSelection";
  count: Scalars["Int"]["output"];
  learningObjectiveId: IdAggregateSelection;
  totalMastery: FloatAggregateSelection;
  userId: IdAggregateSelection;
};

export type MasteryResultCreateInput = {
  learningObjectiveId?: InputMaybe<Scalars["ID"]["input"]>;
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MasteryResultEdge = {
  __typename?: "MasteryResultEdge";
  cursor: Scalars["String"]["output"];
  node: MasteryResult;
};

export type MasteryResultOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more MasteryResultSort objects to sort MasteryResults by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<MasteryResultSort>>;
};

/** Fields to sort MasteryResults by. The order in which sorts are applied is not guaranteed when specifying many fields in one MasteryResultSort object. */
export type MasteryResultSort = {
  learningObjectiveId?: InputMaybe<SortDirection>;
  totalMastery?: InputMaybe<SortDirection>;
  userId?: InputMaybe<SortDirection>;
};

export type MasteryResultUpdateInput = {
  learningObjectiveId?: InputMaybe<Scalars["ID"]["input"]>;
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MasteryResultWhere = {
  AND?: InputMaybe<Array<MasteryResultWhere>>;
  NOT?: InputMaybe<MasteryResultWhere>;
  OR?: InputMaybe<Array<MasteryResultWhere>>;
  learningObjectiveId?: InputMaybe<Scalars["ID"]["input"]>;
  learningObjectiveId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  learningObjectiveId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  learningObjectiveId_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  learningObjectiveId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalMastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  userId?: InputMaybe<Scalars["ID"]["input"]>;
  userId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  userId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  userId_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  userId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MasteryResultsConnection = {
  __typename?: "MasteryResultsConnection";
  edges: Array<MasteryResultEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type MeResponse = {
  __typename?: "MeResponse";
  audioOn?: Maybe<Scalars["Boolean"]["output"]>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  currentWeightLbs?: Maybe<Scalars["Float"]["output"]>;
  dailyEmailOptIn?: Maybe<Scalars["Boolean"]["output"]>;
  email?: Maybe<Scalars["String"]["output"]>;
  enrolledTracks?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  fullPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Scalars["String"]["output"]>;
  goalWeightLbs?: Maybe<Scalars["Float"]["output"]>;
  googleCalendarAuthToken?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  isCareerLoading?: Maybe<Scalars["Boolean"]["output"]>;
  isCourseLoading?: Maybe<Scalars["Boolean"]["output"]>;
  isEmailVerified?: Maybe<Scalars["Boolean"]["output"]>;
  isOnboarded?: Maybe<Scalars["Boolean"]["output"]>;
  isPro?: Maybe<Scalars["Boolean"]["output"]>;
  modalOnboarding?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  otpPreference?: Maybe<Scalars["String"]["output"]>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  trialEndDate?: Maybe<Scalars["Date"]["output"]>;
  username?: Maybe<Scalars["String"]["output"]>;
};

export type MeResponseAggregateSelection = {
  __typename?: "MeResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  countryCode: StringAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type MeResponseCreateInput = {
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  currentWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  dailyEmailOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  enrolledTracks?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fullPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  goalWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  isCareerLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCourseLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEmailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  trialEndDate?: InputMaybe<Scalars["Date"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type MeResponseEdge = {
  __typename?: "MeResponseEdge";
  cursor: Scalars["String"]["output"];
  node: MeResponse;
};

export type MeResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more MeResponseSort objects to sort MeResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<MeResponseSort>>;
};

/** Fields to sort MeResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one MeResponseSort object. */
export type MeResponseSort = {
  audioOn?: InputMaybe<SortDirection>;
  countryCode?: InputMaybe<SortDirection>;
  currentWeightLbs?: InputMaybe<SortDirection>;
  dailyEmailOptIn?: InputMaybe<SortDirection>;
  email?: InputMaybe<SortDirection>;
  fullPhoneNumber?: InputMaybe<SortDirection>;
  gender?: InputMaybe<SortDirection>;
  goalWeightLbs?: InputMaybe<SortDirection>;
  googleCalendarAuthToken?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  isCareerLoading?: InputMaybe<SortDirection>;
  isCourseLoading?: InputMaybe<SortDirection>;
  isEmailVerified?: InputMaybe<SortDirection>;
  isOnboarded?: InputMaybe<SortDirection>;
  isPro?: InputMaybe<SortDirection>;
  modalOnboarding?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  otpPreference?: InputMaybe<SortDirection>;
  phoneNumber?: InputMaybe<SortDirection>;
  trialEndDate?: InputMaybe<SortDirection>;
  username?: InputMaybe<SortDirection>;
};

export type MeResponseUpdateInput = {
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  currentWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  dailyEmailOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  enrolledTracks?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  enrolledTracks_POP?: InputMaybe<Scalars["Int"]["input"]>;
  enrolledTracks_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fullPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  goalWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  isCareerLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCourseLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEmailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  trialEndDate?: InputMaybe<Scalars["Date"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type MeResponseWhere = {
  AND?: InputMaybe<Array<MeResponseWhere>>;
  NOT?: InputMaybe<MeResponseWhere>;
  OR?: InputMaybe<Array<MeResponseWhere>>;
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  countryCode_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  countryCode_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  countryCode_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  countryCode_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  currentWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  currentWeightLbs_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  dailyEmailOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  email_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  email_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  email_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  email_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  enrolledTracks?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  enrolledTracks_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fullPhoneNumber_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  gender_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gender_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gender_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  gender_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  goalWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  goalWeightLbs_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken?: InputMaybe<Scalars["String"]["input"]>;
  googleCalendarAuthToken_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  googleCalendarAuthToken_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  googleCalendarAuthToken_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  googleCalendarAuthToken_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  isCareerLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCourseLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEmailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  modalOnboarding_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  otpPreference_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  phoneNumber_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  trialEndDate?: InputMaybe<Scalars["Date"]["input"]>;
  trialEndDate_GT?: InputMaybe<Scalars["Date"]["input"]>;
  trialEndDate_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  trialEndDate_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  trialEndDate_LT?: InputMaybe<Scalars["Date"]["input"]>;
  trialEndDate_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  username?: InputMaybe<Scalars["String"]["input"]>;
  username_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  username_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  username_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  username_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type MeResponsesConnection = {
  __typename?: "MeResponsesConnection";
  edges: Array<MeResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Message = {
  __typename?: "Message";
  aspectDetails?: Maybe<AspectDetailsType>;
  chatsHasMessage: Array<Chat>;
  chatsHasMessageAggregate?: Maybe<MessageChatChatsHasMessageAggregationSelection>;
  chatsHasMessageConnection: MessageChatsHasMessageConnection;
  content?: Maybe<Scalars["String"]["output"]>;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  emotion?: Maybe<Scalars["String"]["output"]>;
  flagged?: Maybe<Scalars["Boolean"]["output"]>;
  harassment?: Maybe<Scalars["Boolean"]["output"]>;
  harassment_threatening?: Maybe<Scalars["Boolean"]["output"]>;
  hate?: Maybe<Scalars["Boolean"]["output"]>;
  hate_threatening?: Maybe<Scalars["Boolean"]["output"]>;
  id: Scalars["String"]["output"];
  last_modified?: Maybe<Scalars["DateTime"]["output"]>;
  nodeId?: Maybe<Scalars["String"]["output"]>;
  parameters?: Maybe<ParametersType>;
  self_harm?: Maybe<Scalars["Boolean"]["output"]>;
  self_harm_instructions?: Maybe<Scalars["Boolean"]["output"]>;
  self_harm_intent?: Maybe<Scalars["Boolean"]["output"]>;
  sender?: Maybe<Scalars["String"]["output"]>;
  sexual?: Maybe<Scalars["Boolean"]["output"]>;
  sexual_minors?: Maybe<Scalars["Boolean"]["output"]>;
  snackType?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
  violence?: Maybe<Scalars["Boolean"]["output"]>;
  violence_graphic?: Maybe<Scalars["Boolean"]["output"]>;
};

export type MessageChatsHasMessageArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ChatOptions>;
  where?: InputMaybe<ChatWhere>;
};

export type MessageChatsHasMessageAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ChatWhere>;
};

export type MessageChatsHasMessageConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<MessageChatsHasMessageConnectionSort>>;
  where?: InputMaybe<MessageChatsHasMessageConnectionWhere>;
};

export type MessageAggregateSelection = {
  __typename?: "MessageAggregateSelection";
  content: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  emotion: StringAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  nodeId: StringAggregateSelection;
  sender: StringAggregateSelection;
  snackType: StringAggregateSelection;
  type: StringAggregateSelection;
};

export type MessageChatChatsHasMessageAggregationSelection = {
  __typename?: "MessageChatChatsHasMessageAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<MessageChatChatsHasMessageEdgeAggregateSelection>;
  node?: Maybe<MessageChatChatsHasMessageNodeAggregateSelection>;
};

export type MessageChatChatsHasMessageEdgeAggregateSelection = {
  __typename?: "MessageChatChatsHasMessageEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
};

export type MessageChatChatsHasMessageNodeAggregateSelection = {
  __typename?: "MessageChatChatsHasMessageNodeAggregateSelection";
  context: StringAggregateSelection;
  date: StringAggregateSelection;
  id: IdAggregateSelection;
  name: StringAggregateSelection;
  objectId: IdAggregateSelection;
  timezone: StringAggregateSelection;
  url: StringAggregateSelection;
  userId: IdAggregateSelection;
};

export type MessageChatsHasMessageAggregateInput = {
  AND?: InputMaybe<Array<MessageChatsHasMessageAggregateInput>>;
  NOT?: InputMaybe<MessageChatsHasMessageAggregateInput>;
  OR?: InputMaybe<Array<MessageChatsHasMessageAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasMessagePropertiesAggregationWhereInput>;
  node?: InputMaybe<MessageChatsHasMessageNodeAggregationWhereInput>;
};

export type MessageChatsHasMessageConnectFieldInput = {
  connect?: InputMaybe<Array<ChatConnectInput>>;
  edge: HasMessagePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ChatConnectWhere>;
};

export type MessageChatsHasMessageConnection = {
  __typename?: "MessageChatsHasMessageConnection";
  edges: Array<MessageChatsHasMessageRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type MessageChatsHasMessageConnectionSort = {
  edge?: InputMaybe<HasMessagePropertiesSort>;
  node?: InputMaybe<ChatSort>;
};

export type MessageChatsHasMessageConnectionWhere = {
  AND?: InputMaybe<Array<MessageChatsHasMessageConnectionWhere>>;
  NOT?: InputMaybe<MessageChatsHasMessageConnectionWhere>;
  OR?: InputMaybe<Array<MessageChatsHasMessageConnectionWhere>>;
  edge?: InputMaybe<HasMessagePropertiesWhere>;
  node?: InputMaybe<ChatWhere>;
};

export type MessageChatsHasMessageCreateFieldInput = {
  edge: HasMessagePropertiesCreateInput;
  node: ChatCreateInput;
};

export type MessageChatsHasMessageDeleteFieldInput = {
  delete?: InputMaybe<ChatDeleteInput>;
  where?: InputMaybe<MessageChatsHasMessageConnectionWhere>;
};

export type MessageChatsHasMessageDisconnectFieldInput = {
  disconnect?: InputMaybe<ChatDisconnectInput>;
  where?: InputMaybe<MessageChatsHasMessageConnectionWhere>;
};

export type MessageChatsHasMessageFieldInput = {
  connect?: InputMaybe<Array<MessageChatsHasMessageConnectFieldInput>>;
  create?: InputMaybe<Array<MessageChatsHasMessageCreateFieldInput>>;
};

export type MessageChatsHasMessageNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<MessageChatsHasMessageNodeAggregationWhereInput>>;
  NOT?: InputMaybe<MessageChatsHasMessageNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<MessageChatsHasMessageNodeAggregationWhereInput>>;
  context_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MessageChatsHasMessageRelationship = {
  __typename?: "MessageChatsHasMessageRelationship";
  cursor: Scalars["String"]["output"];
  node: Chat;
  properties: HasMessageProperties;
};

export type MessageChatsHasMessageUpdateConnectionInput = {
  edge?: InputMaybe<HasMessagePropertiesUpdateInput>;
  node?: InputMaybe<ChatUpdateInput>;
};

export type MessageChatsHasMessageUpdateFieldInput = {
  connect?: InputMaybe<Array<MessageChatsHasMessageConnectFieldInput>>;
  create?: InputMaybe<Array<MessageChatsHasMessageCreateFieldInput>>;
  delete?: InputMaybe<Array<MessageChatsHasMessageDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<MessageChatsHasMessageDisconnectFieldInput>>;
  update?: InputMaybe<MessageChatsHasMessageUpdateConnectionInput>;
  where?: InputMaybe<MessageChatsHasMessageConnectionWhere>;
};

export type MessageConnectInput = {
  chatsHasMessage?: InputMaybe<Array<MessageChatsHasMessageConnectFieldInput>>;
};

export type MessageConnectWhere = {
  node: MessageWhere;
};

export type MessageCreateInput = {
  chatsHasMessage?: InputMaybe<MessageChatsHasMessageFieldInput>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  emotion?: InputMaybe<Scalars["String"]["input"]>;
  flagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  harassment?: InputMaybe<Scalars["Boolean"]["input"]>;
  harassment_threatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  hate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hate_threatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  id: Scalars["String"]["input"];
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  nodeId?: InputMaybe<Scalars["String"]["input"]>;
  self_harm?: InputMaybe<Scalars["Boolean"]["input"]>;
  self_harm_instructions?: InputMaybe<Scalars["Boolean"]["input"]>;
  self_harm_intent?: InputMaybe<Scalars["Boolean"]["input"]>;
  sender?: InputMaybe<Scalars["String"]["input"]>;
  sexual?: InputMaybe<Scalars["Boolean"]["input"]>;
  sexual_minors?: InputMaybe<Scalars["Boolean"]["input"]>;
  snackType?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  violence?: InputMaybe<Scalars["Boolean"]["input"]>;
  violence_graphic?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MessageDeleteInput = {
  chatsHasMessage?: InputMaybe<Array<MessageChatsHasMessageDeleteFieldInput>>;
};

export type MessageDisconnectInput = {
  chatsHasMessage?: InputMaybe<Array<MessageChatsHasMessageDisconnectFieldInput>>;
};

export type MessageEdge = {
  __typename?: "MessageEdge";
  cursor: Scalars["String"]["output"];
  node: Message;
};

export type MessageOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more MessageSort objects to sort Messages by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<MessageSort>>;
};

export type MessageRelationInput = {
  chatsHasMessage?: InputMaybe<Array<MessageChatsHasMessageCreateFieldInput>>;
};

/** Fields to sort Messages by. The order in which sorts are applied is not guaranteed when specifying many fields in one MessageSort object. */
export type MessageSort = {
  content?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  emotion?: InputMaybe<SortDirection>;
  flagged?: InputMaybe<SortDirection>;
  harassment?: InputMaybe<SortDirection>;
  harassment_threatening?: InputMaybe<SortDirection>;
  hate?: InputMaybe<SortDirection>;
  hate_threatening?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  nodeId?: InputMaybe<SortDirection>;
  self_harm?: InputMaybe<SortDirection>;
  self_harm_instructions?: InputMaybe<SortDirection>;
  self_harm_intent?: InputMaybe<SortDirection>;
  sender?: InputMaybe<SortDirection>;
  sexual?: InputMaybe<SortDirection>;
  sexual_minors?: InputMaybe<SortDirection>;
  snackType?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
  violence?: InputMaybe<SortDirection>;
  violence_graphic?: InputMaybe<SortDirection>;
};

export type MessageUpdateInput = {
  chatsHasMessage?: InputMaybe<Array<MessageChatsHasMessageUpdateFieldInput>>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  emotion?: InputMaybe<Scalars["String"]["input"]>;
  flagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  harassment?: InputMaybe<Scalars["Boolean"]["input"]>;
  harassment_threatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  hate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hate_threatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  nodeId?: InputMaybe<Scalars["String"]["input"]>;
  self_harm?: InputMaybe<Scalars["Boolean"]["input"]>;
  self_harm_instructions?: InputMaybe<Scalars["Boolean"]["input"]>;
  self_harm_intent?: InputMaybe<Scalars["Boolean"]["input"]>;
  sender?: InputMaybe<Scalars["String"]["input"]>;
  sexual?: InputMaybe<Scalars["Boolean"]["input"]>;
  sexual_minors?: InputMaybe<Scalars["Boolean"]["input"]>;
  snackType?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  violence?: InputMaybe<Scalars["Boolean"]["input"]>;
  violence_graphic?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MessageWhere = {
  AND?: InputMaybe<Array<MessageWhere>>;
  NOT?: InputMaybe<MessageWhere>;
  OR?: InputMaybe<Array<MessageWhere>>;
  chatsHasMessageAggregate?: InputMaybe<MessageChatsHasMessageAggregateInput>;
  /** Return Messages where all of the related MessageChatsHasMessageConnections match this filter */
  chatsHasMessageConnection_ALL?: InputMaybe<MessageChatsHasMessageConnectionWhere>;
  /** Return Messages where none of the related MessageChatsHasMessageConnections match this filter */
  chatsHasMessageConnection_NONE?: InputMaybe<MessageChatsHasMessageConnectionWhere>;
  /** Return Messages where one of the related MessageChatsHasMessageConnections match this filter */
  chatsHasMessageConnection_SINGLE?: InputMaybe<MessageChatsHasMessageConnectionWhere>;
  /** Return Messages where some of the related MessageChatsHasMessageConnections match this filter */
  chatsHasMessageConnection_SOME?: InputMaybe<MessageChatsHasMessageConnectionWhere>;
  /** Return Messages where all of the related Chats match this filter */
  chatsHasMessage_ALL?: InputMaybe<ChatWhere>;
  /** Return Messages where none of the related Chats match this filter */
  chatsHasMessage_NONE?: InputMaybe<ChatWhere>;
  /** Return Messages where one of the related Chats match this filter */
  chatsHasMessage_SINGLE?: InputMaybe<ChatWhere>;
  /** Return Messages where some of the related Chats match this filter */
  chatsHasMessage_SOME?: InputMaybe<ChatWhere>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  content_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  content_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  content_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  content_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  emotion?: InputMaybe<Scalars["String"]["input"]>;
  emotion_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  emotion_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  emotion_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  emotion_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  flagged?: InputMaybe<Scalars["Boolean"]["input"]>;
  harassment?: InputMaybe<Scalars["Boolean"]["input"]>;
  harassment_threatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  hate?: InputMaybe<Scalars["Boolean"]["input"]>;
  hate_threatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  nodeId?: InputMaybe<Scalars["String"]["input"]>;
  nodeId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  nodeId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  nodeId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  nodeId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  self_harm?: InputMaybe<Scalars["Boolean"]["input"]>;
  self_harm_instructions?: InputMaybe<Scalars["Boolean"]["input"]>;
  self_harm_intent?: InputMaybe<Scalars["Boolean"]["input"]>;
  sender?: InputMaybe<Scalars["String"]["input"]>;
  sender_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sender_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sender_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sender_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sexual?: InputMaybe<Scalars["Boolean"]["input"]>;
  sexual_minors?: InputMaybe<Scalars["Boolean"]["input"]>;
  snackType?: InputMaybe<Scalars["String"]["input"]>;
  snackType_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  snackType_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  snackType_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  snackType_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  violence?: InputMaybe<Scalars["Boolean"]["input"]>;
  violence_graphic?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MessagesConnection = {
  __typename?: "MessagesConnection";
  edges: Array<MessageEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Module = {
  __typename?: "Module";
  assignments: Array<ToolAssignment>;
  courseHasModules: Array<Course>;
  courseHasModulesAggregate?: Maybe<ModuleCourseCourseHasModulesAggregationSelection>;
  courseHasModulesConnection: ModuleCourseHasModulesConnection;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  lastModified?: Maybe<Scalars["DateTime"]["output"]>;
  module_name: Scalars["String"]["output"];
  order?: Maybe<Scalars["Float"]["output"]>;
  resources: Array<Resource>;
  resourcesAggregate?: Maybe<ModuleResourceResourcesAggregationSelection>;
  resourcesConnection: ModuleResourcesConnection;
  title?: Maybe<Scalars["String"]["output"]>;
};

export type ModuleCourseHasModulesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<CourseOptions>;
  where?: InputMaybe<CourseWhere>;
};

export type ModuleCourseHasModulesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<CourseWhere>;
};

export type ModuleCourseHasModulesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ModuleCourseHasModulesConnectionSort>>;
  where?: InputMaybe<ModuleCourseHasModulesConnectionWhere>;
};

export type ModuleResourcesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ResourceOptions>;
  where?: InputMaybe<ResourceWhere>;
};

export type ModuleResourcesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ResourceWhere>;
};

export type ModuleResourcesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ModuleResourcesConnectionSort>>;
  where?: InputMaybe<ModuleResourcesConnectionWhere>;
};

export type ModuleAggregateSelection = {
  __typename?: "ModuleAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  module_name: StringAggregateSelection;
  order: FloatAggregateSelection;
  title: StringAggregateSelection;
};

export type ModuleConnectInput = {
  courseHasModules?: InputMaybe<Array<ModuleCourseHasModulesConnectFieldInput>>;
  resources?: InputMaybe<Array<ModuleResourcesConnectFieldInput>>;
};

export type ModuleConnectOrCreateInput = {
  courseHasModules?: InputMaybe<Array<ModuleCourseHasModulesConnectOrCreateFieldInput>>;
  resources?: InputMaybe<Array<ModuleResourcesConnectOrCreateFieldInput>>;
};

export type ModuleConnectOrCreateWhere = {
  node: ModuleUniqueWhere;
};

export type ModuleConnectWhere = {
  node: ModuleWhere;
};

export type ModuleCourseCourseHasModulesAggregationSelection = {
  __typename?: "ModuleCourseCourseHasModulesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<ModuleCourseCourseHasModulesNodeAggregateSelection>;
};

export type ModuleCourseCourseHasModulesNodeAggregateSelection = {
  __typename?: "ModuleCourseCourseHasModulesNodeAggregateSelection";
  account_id: FloatAggregateSelection;
  calendar_ics: StringAggregateSelection;
  courseId: StringAggregateSelection;
  course_code: StringAggregateSelection;
  course_id: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  default_view: StringAggregateSelection;
  description: StringAggregateSelection;
  end_at: StringAggregateSelection;
  enrollment_term_id: FloatAggregateSelection;
  grading_standard_id: FloatAggregateSelection;
  id: IdAggregateSelection;
  integration_id: StringAggregateSelection;
  label: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  learningStandards: StringAggregateSelection;
  lengthInWeeks: FloatAggregateSelection;
  license: StringAggregateSelection;
  lti_id: StringAggregateSelection;
  name: StringAggregateSelection;
  original_name: StringAggregateSelection;
  root_account_id: FloatAggregateSelection;
  sis_course_id: StringAggregateSelection;
  sis_import_id: FloatAggregateSelection;
  start_at: StringAggregateSelection;
  state: StringAggregateSelection;
  storage_quota_mb: FloatAggregateSelection;
  time_zone: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  uuid: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type ModuleCourseHasModulesAggregateInput = {
  AND?: InputMaybe<Array<ModuleCourseHasModulesAggregateInput>>;
  NOT?: InputMaybe<ModuleCourseHasModulesAggregateInput>;
  OR?: InputMaybe<Array<ModuleCourseHasModulesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<ModuleCourseHasModulesNodeAggregationWhereInput>;
};

export type ModuleCourseHasModulesConnectFieldInput = {
  connect?: InputMaybe<Array<CourseConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<CourseConnectWhere>;
};

export type ModuleCourseHasModulesConnectOrCreateFieldInput = {
  onCreate: ModuleCourseHasModulesConnectOrCreateFieldInputOnCreate;
  where: CourseConnectOrCreateWhere;
};

export type ModuleCourseHasModulesConnectOrCreateFieldInputOnCreate = {
  node: CourseOnCreateInput;
};

export type ModuleCourseHasModulesConnection = {
  __typename?: "ModuleCourseHasModulesConnection";
  edges: Array<ModuleCourseHasModulesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ModuleCourseHasModulesConnectionSort = {
  node?: InputMaybe<CourseSort>;
};

export type ModuleCourseHasModulesConnectionWhere = {
  AND?: InputMaybe<Array<ModuleCourseHasModulesConnectionWhere>>;
  NOT?: InputMaybe<ModuleCourseHasModulesConnectionWhere>;
  OR?: InputMaybe<Array<ModuleCourseHasModulesConnectionWhere>>;
  node?: InputMaybe<CourseWhere>;
};

export type ModuleCourseHasModulesCreateFieldInput = {
  node: CourseCreateInput;
};

export type ModuleCourseHasModulesDeleteFieldInput = {
  delete?: InputMaybe<CourseDeleteInput>;
  where?: InputMaybe<ModuleCourseHasModulesConnectionWhere>;
};

export type ModuleCourseHasModulesDisconnectFieldInput = {
  disconnect?: InputMaybe<CourseDisconnectInput>;
  where?: InputMaybe<ModuleCourseHasModulesConnectionWhere>;
};

export type ModuleCourseHasModulesFieldInput = {
  connect?: InputMaybe<Array<ModuleCourseHasModulesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleCourseHasModulesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ModuleCourseHasModulesCreateFieldInput>>;
};

export type ModuleCourseHasModulesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ModuleCourseHasModulesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ModuleCourseHasModulesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ModuleCourseHasModulesNodeAggregationWhereInput>>;
  account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_term_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lengthInWeeks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  root_account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_import_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  storage_quota_mb_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ModuleCourseHasModulesRelationship = {
  __typename?: "ModuleCourseHasModulesRelationship";
  cursor: Scalars["String"]["output"];
  node: Course;
};

export type ModuleCourseHasModulesUpdateConnectionInput = {
  node?: InputMaybe<CourseUpdateInput>;
};

export type ModuleCourseHasModulesUpdateFieldInput = {
  connect?: InputMaybe<Array<ModuleCourseHasModulesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleCourseHasModulesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ModuleCourseHasModulesCreateFieldInput>>;
  delete?: InputMaybe<Array<ModuleCourseHasModulesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ModuleCourseHasModulesDisconnectFieldInput>>;
  update?: InputMaybe<ModuleCourseHasModulesUpdateConnectionInput>;
  where?: InputMaybe<ModuleCourseHasModulesConnectionWhere>;
};

export type ModuleCreateInput = {
  courseHasModules?: InputMaybe<ModuleCourseHasModulesFieldInput>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  module_name: Scalars["String"]["input"];
  order?: InputMaybe<Scalars["Float"]["input"]>;
  resources?: InputMaybe<ModuleResourcesFieldInput>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type ModuleDeleteInput = {
  courseHasModules?: InputMaybe<Array<ModuleCourseHasModulesDeleteFieldInput>>;
  resources?: InputMaybe<Array<ModuleResourcesDeleteFieldInput>>;
};

export type ModuleDisconnectInput = {
  courseHasModules?: InputMaybe<Array<ModuleCourseHasModulesDisconnectFieldInput>>;
  resources?: InputMaybe<Array<ModuleResourcesDisconnectFieldInput>>;
};

export type ModuleEdge = {
  __typename?: "ModuleEdge";
  cursor: Scalars["String"]["output"];
  node: Module;
};

export type ModuleOnCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  module_name: Scalars["String"]["input"];
  order?: InputMaybe<Scalars["Float"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type ModuleOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ModuleSort objects to sort Modules by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ModuleSort>>;
};

export type ModuleRelationInput = {
  courseHasModules?: InputMaybe<Array<ModuleCourseHasModulesCreateFieldInput>>;
  resources?: InputMaybe<Array<ModuleResourcesCreateFieldInput>>;
};

export type ModuleResourceResourcesAggregationSelection = {
  __typename?: "ModuleResourceResourcesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<ModuleResourceResourcesNodeAggregateSelection>;
};

export type ModuleResourceResourcesNodeAggregateSelection = {
  __typename?: "ModuleResourceResourcesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
};

export type ModuleResourcesAggregateInput = {
  AND?: InputMaybe<Array<ModuleResourcesAggregateInput>>;
  NOT?: InputMaybe<ModuleResourcesAggregateInput>;
  OR?: InputMaybe<Array<ModuleResourcesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<ModuleResourcesNodeAggregationWhereInput>;
};

export type ModuleResourcesConnectFieldInput = {
  connect?: InputMaybe<Array<ResourceConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ResourceConnectWhere>;
};

export type ModuleResourcesConnectOrCreateFieldInput = {
  onCreate: ModuleResourcesConnectOrCreateFieldInputOnCreate;
  where: ResourceConnectOrCreateWhere;
};

export type ModuleResourcesConnectOrCreateFieldInputOnCreate = {
  node: ResourceOnCreateInput;
};

export type ModuleResourcesConnection = {
  __typename?: "ModuleResourcesConnection";
  edges: Array<ModuleResourcesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ModuleResourcesConnectionSort = {
  node?: InputMaybe<ResourceSort>;
};

export type ModuleResourcesConnectionWhere = {
  AND?: InputMaybe<Array<ModuleResourcesConnectionWhere>>;
  NOT?: InputMaybe<ModuleResourcesConnectionWhere>;
  OR?: InputMaybe<Array<ModuleResourcesConnectionWhere>>;
  node?: InputMaybe<ResourceWhere>;
};

export type ModuleResourcesCreateFieldInput = {
  node: ResourceCreateInput;
};

export type ModuleResourcesDeleteFieldInput = {
  delete?: InputMaybe<ResourceDeleteInput>;
  where?: InputMaybe<ModuleResourcesConnectionWhere>;
};

export type ModuleResourcesDisconnectFieldInput = {
  disconnect?: InputMaybe<ResourceDisconnectInput>;
  where?: InputMaybe<ModuleResourcesConnectionWhere>;
};

export type ModuleResourcesFieldInput = {
  connect?: InputMaybe<Array<ModuleResourcesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleResourcesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ModuleResourcesCreateFieldInput>>;
};

export type ModuleResourcesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ModuleResourcesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ModuleResourcesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ModuleResourcesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ModuleResourcesRelationship = {
  __typename?: "ModuleResourcesRelationship";
  cursor: Scalars["String"]["output"];
  node: Resource;
};

export type ModuleResourcesUpdateConnectionInput = {
  node?: InputMaybe<ResourceUpdateInput>;
};

export type ModuleResourcesUpdateFieldInput = {
  connect?: InputMaybe<Array<ModuleResourcesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ModuleResourcesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ModuleResourcesCreateFieldInput>>;
  delete?: InputMaybe<Array<ModuleResourcesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ModuleResourcesDisconnectFieldInput>>;
  update?: InputMaybe<ModuleResourcesUpdateConnectionInput>;
  where?: InputMaybe<ModuleResourcesConnectionWhere>;
};

export type ModuleResponse = {
  __typename?: "ModuleResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<Module>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type ModuleResponseAggregateSelection = {
  __typename?: "ModuleResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type ModuleResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModuleResponseEdge = {
  __typename?: "ModuleResponseEdge";
  cursor: Scalars["String"]["output"];
  node: ModuleResponse;
};

export type ModuleResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ModuleResponseSort objects to sort ModuleResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ModuleResponseSort>>;
};

/** Fields to sort ModuleResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one ModuleResponseSort object. */
export type ModuleResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type ModuleResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModuleResponseWhere = {
  AND?: InputMaybe<Array<ModuleResponseWhere>>;
  NOT?: InputMaybe<ModuleResponseWhere>;
  OR?: InputMaybe<Array<ModuleResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ModuleResponsesConnection = {
  __typename?: "ModuleResponsesConnection";
  edges: Array<ModuleResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort Modules by. The order in which sorts are applied is not guaranteed when specifying many fields in one ModuleSort object. */
export type ModuleSort = {
  created?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
  module_name?: InputMaybe<SortDirection>;
  order?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
};

export type ModuleUniqueWhere = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ModuleUpdateInput = {
  courseHasModules?: InputMaybe<Array<ModuleCourseHasModulesUpdateFieldInput>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  module_name?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Float"]["input"]>;
  order_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  order_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  resources?: InputMaybe<Array<ModuleResourcesUpdateFieldInput>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type ModuleWhere = {
  AND?: InputMaybe<Array<ModuleWhere>>;
  NOT?: InputMaybe<ModuleWhere>;
  OR?: InputMaybe<Array<ModuleWhere>>;
  courseHasModulesAggregate?: InputMaybe<ModuleCourseHasModulesAggregateInput>;
  /** Return Modules where all of the related ModuleCourseHasModulesConnections match this filter */
  courseHasModulesConnection_ALL?: InputMaybe<ModuleCourseHasModulesConnectionWhere>;
  /** Return Modules where none of the related ModuleCourseHasModulesConnections match this filter */
  courseHasModulesConnection_NONE?: InputMaybe<ModuleCourseHasModulesConnectionWhere>;
  /** Return Modules where one of the related ModuleCourseHasModulesConnections match this filter */
  courseHasModulesConnection_SINGLE?: InputMaybe<ModuleCourseHasModulesConnectionWhere>;
  /** Return Modules where some of the related ModuleCourseHasModulesConnections match this filter */
  courseHasModulesConnection_SOME?: InputMaybe<ModuleCourseHasModulesConnectionWhere>;
  /** Return Modules where all of the related Courses match this filter */
  courseHasModules_ALL?: InputMaybe<CourseWhere>;
  /** Return Modules where none of the related Courses match this filter */
  courseHasModules_NONE?: InputMaybe<CourseWhere>;
  /** Return Modules where one of the related Courses match this filter */
  courseHasModules_SINGLE?: InputMaybe<CourseWhere>;
  /** Return Modules where some of the related Courses match this filter */
  courseHasModules_SOME?: InputMaybe<CourseWhere>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastModified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  module_name?: InputMaybe<Scalars["String"]["input"]>;
  module_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  module_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  module_name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  module_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Float"]["input"]>;
  order_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  order_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resourcesAggregate?: InputMaybe<ModuleResourcesAggregateInput>;
  /** Return Modules where all of the related ModuleResourcesConnections match this filter */
  resourcesConnection_ALL?: InputMaybe<ModuleResourcesConnectionWhere>;
  /** Return Modules where none of the related ModuleResourcesConnections match this filter */
  resourcesConnection_NONE?: InputMaybe<ModuleResourcesConnectionWhere>;
  /** Return Modules where one of the related ModuleResourcesConnections match this filter */
  resourcesConnection_SINGLE?: InputMaybe<ModuleResourcesConnectionWhere>;
  /** Return Modules where some of the related ModuleResourcesConnections match this filter */
  resourcesConnection_SOME?: InputMaybe<ModuleResourcesConnectionWhere>;
  /** Return Modules where all of the related Resources match this filter */
  resources_ALL?: InputMaybe<ResourceWhere>;
  /** Return Modules where none of the related Resources match this filter */
  resources_NONE?: InputMaybe<ResourceWhere>;
  /** Return Modules where one of the related Resources match this filter */
  resources_SINGLE?: InputMaybe<ResourceWhere>;
  /** Return Modules where some of the related Resources match this filter */
  resources_SOME?: InputMaybe<ResourceWhere>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ModulesConnection = {
  __typename?: "ModulesConnection";
  edges: Array<ModuleEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type MonthlySummariesConnection = {
  __typename?: "MonthlySummariesConnection";
  edges: Array<MonthlySummaryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type MonthlySummary = {
  __typename?: "MonthlySummary";
  created: Scalars["DateTime"]["output"];
  date: Scalars["String"]["output"];
  isArchived: Scalars["Boolean"]["output"];
  last_modified: Scalars["DateTime"]["output"];
  summary: Scalars["String"]["output"];
};

export type MonthlySummaryAggregateSelection = {
  __typename?: "MonthlySummaryAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  date: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type MonthlySummaryConnectWhere = {
  node: MonthlySummaryWhere;
};

export type MonthlySummaryCreateInput = {
  created: Scalars["DateTime"]["input"];
  date: Scalars["String"]["input"];
  isArchived: Scalars["Boolean"]["input"];
  last_modified: Scalars["DateTime"]["input"];
  summary: Scalars["String"]["input"];
};

export type MonthlySummaryEdge = {
  __typename?: "MonthlySummaryEdge";
  cursor: Scalars["String"]["output"];
  node: MonthlySummary;
};

export type MonthlySummaryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more MonthlySummarySort objects to sort MonthlySummaries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<MonthlySummarySort>>;
};

/** Fields to sort MonthlySummaries by. The order in which sorts are applied is not guaranteed when specifying many fields in one MonthlySummarySort object. */
export type MonthlySummarySort = {
  created?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  isArchived?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  summary?: InputMaybe<SortDirection>;
};

export type MonthlySummaryUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
};

export type MonthlySummaryWhere = {
  AND?: InputMaybe<Array<MonthlySummaryWhere>>;
  NOT?: InputMaybe<MonthlySummaryWhere>;
  OR?: InputMaybe<Array<MonthlySummaryWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  date_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  summary_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  summary_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  summary_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  summary_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type Mutation = {
  __typename?: "Mutation";
  _empty?: Maybe<Scalars["String"]["output"]>;
  addAssignment: AddAssignmentResponse;
  addContentModule: AddContentModuleResponse;
  addFood?: Maybe<Food>;
  addNutrition?: Maybe<Nutrition>;
  addToWhitelist: WhitelistedEmail;
  addTodo?: Maybe<Todo>;
  assignRoleToUserForTenant?: Maybe<ResponseType>;
  audioToggleUser?: Maybe<AudioToggleUserResponse>;
  bulkCreateTenants?: Maybe<TenantResponse>;
  careerIsLoading: UserCareerLoadingResponse;
  careerSubmitForm: Scalars["Boolean"]["output"];
  careerTopicRelationship: CareerTopicResponse;
  completeJourney?: Maybe<ResponseType>;
  courseCreation?: Maybe<CourseCreationResponse>;
  courseIsLoading: UserCourseLoadingResponse;
  createAchievements: CreateAchievementsMutationResponse;
  createActiveTracks: CreateActiveTracksMutationResponse;
  createAddAssignmentResponses: CreateAddAssignmentResponsesMutationResponse;
  createAddContentModuleResponses: CreateAddContentModuleResponsesMutationResponse;
  createAspectDetailsTypes: CreateAspectDetailsTypesMutationResponse;
  createAssignedQuests: CreateAssignedQuestsMutationResponse;
  createAssignment?: Maybe<AssignmentResponse>;
  createAssignmentResponses: CreateAssignmentResponsesMutationResponse;
  createAssignments: CreateAssignmentsMutationResponse;
  createAttributes: CreateAttributesMutationResponse;
  createAudioToggleUserResponses: CreateAudioToggleUserResponsesMutationResponse;
  createAuthPayloadResponses: CreateAuthPayloadResponsesMutationResponse;
  createAuthPayloadUserPreferences: CreateAuthPayloadUserPreferencesMutationResponse;
  createAuthPayloadUsers: CreateAuthPayloadUsersMutationResponse;
  createCareerHeaders: CreateCareerHeadersMutationResponse;
  createCareerTopicResponses: CreateCareerTopicResponsesMutationResponse;
  createCareers: CreateCareersMutationResponse;
  createChallenges: CreateChallengesMutationResponse;
  createChapters: CreateChaptersMutationResponse;
  createChats: CreateChatsMutationResponse;
  createChildInfos: CreateChildInfosMutationResponse;
  createCoreValues: CreateCoreValuesMutationResponse;
  createCourse?: Maybe<CourseResponse>;
  createCourseCategories: CreateCourseCategoriesMutationResponse;
  createCourseCreationResponses: CreateCourseCreationResponsesMutationResponse;
  createCourseResponses: CreateCourseResponsesMutationResponse;
  createCourses: CreateCoursesMutationResponse;
  createCurricula: CreateCurriculaMutationResponse;
  createDailyCompletedTodosCounts: CreateDailyCompletedTodosCountsMutationResponse;
  createDailyExerciseTotals: CreateDailyExerciseTotalsMutationResponse;
  createDailyNutritionTotals: CreateDailyNutritionTotalsMutationResponse;
  createDailySummaries: CreateDailySummariesMutationResponse;
  createDeleteContentAssignmentResponses: CreateDeleteContentAssignmentResponsesMutationResponse;
  createDeleteContentAssignmentVariables: CreateDeleteContentAssignmentVariablesMutationResponse;
  createDeleteContentModuleResponses: CreateDeleteContentModuleResponsesMutationResponse;
  createDeleteContentModuleVariables: CreateDeleteContentModuleVariablesMutationResponse;
  createDeleteCourseResponses: CreateDeleteCourseResponsesMutationResponse;
  createDeviceSessions: CreateDeviceSessionsMutationResponse;
  createEducationChildren: Generated;
  createEmotionalStates: CreateEmotionalStatesMutationResponse;
  createEvents: CreateEventsMutationResponse;
  createExportCsvResponses: CreateExportCsvResponsesMutationResponse;
  createFeedback?: Maybe<Feedback>;
  createFeedbacks: CreateFeedbacksMutationResponse;
  createFields: CreateFieldsMutationResponse;
  createFiles: CreateFilesMutationResponse;
  createFoodItems: CreateFoodItemsMutationResponse;
  createFoods: CreateFoodsMutationResponse;
  createGames: CreateGamesMutationResponse;
  createGenerateds: CreateGeneratedsMutationResponse;
  createGetAssignmentObjectiveResponses: CreateGetAssignmentObjectiveResponsesMutationResponse;
  createGetAssignmentResponses: CreateGetAssignmentResponsesMutationResponse;
  createGetJourneyByIdResponses: CreateGetJourneyByIdResponsesMutationResponse;
  createGetJourneysResponses: CreateGetJourneysResponsesMutationResponse;
  createGoal?: Maybe<Goal>;
  createGoals: CreateGoalsMutationResponse;
  createGoogleAuthTokens: CreateGoogleAuthTokensMutationResponse;
  createHierarchies: CreateHierarchiesMutationResponse;
  createHierarchyNodes: CreateHierarchyNodesMutationResponse;
  createHobbies: CreateHobbiesMutationResponse;
  createInterest?: Maybe<Interest>;
  createInterests: CreateInterestsMutationResponse;
  createInviteCountResponses: CreateInviteCountResponsesMutationResponse;
  createJourneys: CreateJourneysMutationResponse;
  createLearningNodes: CreateLearningNodesMutationResponse;
  createLearningObjectiveResponses: CreateLearningObjectiveResponsesMutationResponse;
  createLearningObjectives: CreateLearningObjectivesMutationResponse;
  createLearningSummaries: CreateLearningSummariesMutationResponse;
  createLearningTrees: CreateLearningTreesMutationResponse;
  createLesson?: Maybe<Lesson>;
  createLessons: CreateLessonsMutationResponse;
  createLevelDetails: CreateLevelDetailsMutationResponse;
  createLifeAspirations: CreateLifeAspirationsMutationResponse;
  createMasteryResults: CreateMasteryResultsMutationResponse;
  createMeResponses: CreateMeResponsesMutationResponse;
  createMessages: CreateMessagesMutationResponse;
  createModuleAndResource?: Maybe<ModuleResponse>;
  createModuleResponses: CreateModuleResponsesMutationResponse;
  createModules: CreateModulesMutationResponse;
  createMonthlySummaries: CreateMonthlySummariesMutationResponse;
  createNodeIdentities: CreateNodeIdentitiesMutationResponse;
  createNodeProperties: CreateNodePropertiesMutationResponse;
  createNutritions: CreateNutritionsMutationResponse;
  createObjectives: CreateObjectivesMutationResponse;
  createPage?: Maybe<PageResponse>;
  createPageResponses: CreatePageResponsesMutationResponse;
  createPages: CreatePagesMutationResponse;
  createParametersTypes: CreateParametersTypesMutationResponse;
  createPayments: CreatePaymentsMutationResponse;
  createPermissions: CreatePermissionsMutationResponse;
  createPersonalityTraits: CreatePersonalityTraitsMutationResponse;
  createPreference?: Maybe<Preferences>;
  createPreferences: CreatePreferencesMutationResponse;
  createPrerequisites: CreatePrerequisitesMutationResponse;
  createProgress?: Maybe<Progress>;
  createProgresses: CreateProgressesMutationResponse;
  createQuarterlySummaries: CreateQuarterlySummariesMutationResponse;
  createRateLimitResults: CreateRateLimitResultsMutationResponse;
  createRecentCategories: CreateRecentCategoriesMutationResponse;
  createRecentTracks: CreateRecentTracksMutationResponse;
  createRecomendeds: CreateRecomendedsMutationResponse;
  createReflections: CreateReflectionsMutationResponse;
  createRefreshQuestResponses: CreateRefreshQuestResponsesMutationResponse;
  createResources: CreateResourcesMutationResponse;
  createResponseTypes: CreateResponseTypesMutationResponse;
  createRoles: CreateRolesMutationResponse;
  createSchools: CreateSchoolsMutationResponse;
  createSessionResponses: CreateSessionResponsesMutationResponse;
  createSubchapterDetails: CreateSubchapterDetailsMutationResponse;
  createSubchapters: CreateSubchaptersMutationResponse;
  createSubfields: CreateSubfieldsMutationResponse;
  createSuggestions: CreateSuggestionsMutationResponse;
  createTenant?: Maybe<TenantResponse>;
  createTenantResponses: CreateTenantResponsesMutationResponse;
  createTenants: CreateTenantsMutationResponse;
  createTodos: CreateTodosMutationResponse;
  createToolAssignments: CreateToolAssignmentsMutationResponse;
  createTopics: CreateTopicsMutationResponse;
  createTrackOverviews: CreateTrackOverviewsMutationResponse;
  createTransactionByDates: CreateTransactionByDatesMutationResponse;
  createTransactions: CreateTransactionsMutationResponse;
  createUpdateAssignmentResponses: CreateUpdateAssignmentResponsesMutationResponse;
  createUpdateContentModuleNameResponses: CreateUpdateContentModuleNameResponsesMutationResponse;
  createUpdateUserModalOnboardingResponses: CreateUpdateUserModalOnboardingResponsesMutationResponse;
  createUploadDocumentsResponses: CreateUploadDocumentsResponsesMutationResponse;
  createUploadDocumentsToBucketResponses: CreateUploadDocumentsToBucketResponsesMutationResponse;
  createUserCareerLoadingResponses: CreateUserCareerLoadingResponsesMutationResponse;
  createUserCourseLoadingResponses: CreateUserCourseLoadingResponsesMutationResponse;
  createUserMasteries: CreateUserMasteriesMutationResponse;
  createUserProgresses: CreateUserProgressesMutationResponse;
  createUsers: CreateUsersMutationResponse;
  createWaterIntakeSummaries: CreateWaterIntakeSummariesMutationResponse;
  createWaterIntakes: CreateWaterIntakesMutationResponse;
  createWeeklySummaries: CreateWeeklySummariesMutationResponse;
  createWhitelistedEmails: CreateWhitelistedEmailsMutationResponse;
  createWorkouts: CreateWorkoutsMutationResponse;
  createYearlySummaries: CreateYearlySummariesMutationResponse;
  deleteAccount?: Maybe<ResponseType>;
  deleteAchievements: DeleteInfo;
  deleteActiveTracks: DeleteInfo;
  deleteAddAssignmentResponses: DeleteInfo;
  deleteAddContentModuleResponses: DeleteInfo;
  deleteAspect: ResponseType;
  deleteAspectDetailsTypes: DeleteInfo;
  deleteAssignedQuests: DeleteInfo;
  deleteAssignment?: Maybe<AssignmentResponse>;
  deleteAssignmentResponses: DeleteInfo;
  deleteAssignments: DeleteInfo;
  deleteAttributes: DeleteInfo;
  deleteAudioToggleUserResponses: DeleteInfo;
  deleteAuthPayloadResponses: DeleteInfo;
  deleteAuthPayloadUserPreferences: DeleteInfo;
  deleteAuthPayloadUsers: DeleteInfo;
  deleteCareerHeaders: DeleteInfo;
  deleteCareerTopicResponses: DeleteInfo;
  deleteCareers: DeleteInfo;
  deleteChallenges: DeleteInfo;
  deleteChapters: DeleteInfo;
  deleteChats: DeleteInfo;
  deleteChildInfos: DeleteInfo;
  deleteContentAssignment: DeleteContentAssignmentResponse;
  deleteContentModule: DeleteContentModuleResponse;
  deleteCoreValues: DeleteInfo;
  deleteCourse: DeleteCourseResponse;
  deleteCourseCascade?: Maybe<CourseResponse>;
  deleteCourseCategories: DeleteInfo;
  deleteCourseCreationResponses: DeleteInfo;
  deleteCourseResponses: DeleteInfo;
  deleteCourses: DeleteInfo;
  deleteCurricula: DeleteInfo;
  deleteDailyCompletedTodosCounts: DeleteInfo;
  deleteDailyExerciseTotals: DeleteInfo;
  deleteDailyNutritionTotals: DeleteInfo;
  deleteDailySummaries: DeleteInfo;
  deleteDeleteContentAssignmentResponses: DeleteInfo;
  deleteDeleteContentAssignmentVariables: DeleteInfo;
  deleteDeleteContentModuleResponses: DeleteInfo;
  deleteDeleteContentModuleVariables: DeleteInfo;
  deleteDeleteCourseResponses: DeleteInfo;
  deleteDeviceSessions: DeleteInfo;
  deleteEmotionalStates: DeleteInfo;
  deleteEvents: DeleteInfo;
  deleteExportCsvResponses: DeleteInfo;
  deleteFeedbacks: DeleteInfo;
  deleteFields: DeleteInfo;
  deleteFiles: DeleteInfo;
  deleteFood?: Maybe<Food>;
  deleteFoodItems: DeleteInfo;
  deleteFoods: DeleteInfo;
  deleteGames: DeleteInfo;
  deleteGenerateds: DeleteInfo;
  deleteGetAssignmentObjectiveResponses: DeleteInfo;
  deleteGetAssignmentResponses: DeleteInfo;
  deleteGetJourneyByIdResponses: DeleteInfo;
  deleteGetJourneysResponses: DeleteInfo;
  deleteGoal?: Maybe<Scalars["Boolean"]["output"]>;
  deleteGoals: DeleteInfo;
  deleteGoogleAuthTokens: DeleteInfo;
  deleteHierarchies: DeleteInfo;
  deleteHierarchyNodes: DeleteInfo;
  deleteHobbies: DeleteInfo;
  deleteInterest?: Maybe<Scalars["Boolean"]["output"]>;
  deleteInterests: DeleteInfo;
  deleteInviteCountResponses: DeleteInfo;
  deleteJourney?: Maybe<ResponseType>;
  deleteJourneys: DeleteInfo;
  deleteLearningNodes: DeleteInfo;
  deleteLearningObjectiveResponses: DeleteInfo;
  deleteLearningObjectives: DeleteInfo;
  deleteLearningSummaries: DeleteInfo;
  deleteLearningTrees: DeleteInfo;
  deleteLesson?: Maybe<Scalars["Boolean"]["output"]>;
  deleteLessons: DeleteInfo;
  deleteLevelDetails: DeleteInfo;
  deleteLifeAspirations: DeleteInfo;
  deleteLoaderMessages: Scalars["Boolean"]["output"];
  deleteMasteryResults: DeleteInfo;
  deleteMeResponses: DeleteInfo;
  deleteMessage?: Maybe<Message>;
  deleteMessages: DeleteInfo;
  deleteModuleCascade?: Maybe<ModuleResponse>;
  deleteModuleResponses: DeleteInfo;
  deleteModules: DeleteInfo;
  deleteMonthlySummaries: DeleteInfo;
  deleteNodeIdentities: DeleteInfo;
  deleteNodeProperties: DeleteInfo;
  deleteNutritions: DeleteInfo;
  deleteObjectives: DeleteInfo;
  deletePage?: Maybe<PageResponse>;
  deletePageResponses: DeleteInfo;
  deletePages: DeleteInfo;
  deleteParametersTypes: DeleteInfo;
  deletePayments: DeleteInfo;
  deletePermissions: DeleteInfo;
  deletePersonalityTraits: DeleteInfo;
  deletePreference?: Maybe<Scalars["Boolean"]["output"]>;
  deletePreferences: DeleteInfo;
  deletePrerequisites: DeleteInfo;
  deleteProgress?: Maybe<Scalars["Boolean"]["output"]>;
  deleteProgresses: DeleteInfo;
  deleteQuarterlySummaries: DeleteInfo;
  deleteRateLimitResults: DeleteInfo;
  deleteRecentCategories: DeleteInfo;
  deleteRecentTracks: DeleteInfo;
  deleteRecomendeds: DeleteInfo;
  deleteReflections: DeleteInfo;
  deleteRefreshQuestResponses: DeleteInfo;
  deleteResources: DeleteInfo;
  deleteResponseTypes: DeleteInfo;
  deleteRoles: DeleteInfo;
  deleteSchools: DeleteInfo;
  deleteSessionResponses: DeleteInfo;
  deleteSubchapterDetails: DeleteInfo;
  deleteSubchapters: DeleteInfo;
  deleteSubfields: DeleteInfo;
  deleteSuggestions: DeleteInfo;
  deleteTenant?: Maybe<TenantResponse>;
  deleteTenantResponses: DeleteInfo;
  deleteTenants: DeleteInfo;
  deleteTodo?: Maybe<ResponseType>;
  deleteTodos: DeleteInfo;
  deleteToolAssignments: DeleteInfo;
  deleteTopics: DeleteInfo;
  deleteTrackOverviews: DeleteInfo;
  deleteTransactionByDates: DeleteInfo;
  deleteTransactions: DeleteInfo;
  deleteUpdateAssignmentResponses: DeleteInfo;
  deleteUpdateContentModuleNameResponses: DeleteInfo;
  deleteUpdateUserModalOnboardingResponses: DeleteInfo;
  deleteUploadDocumentsResponses: DeleteInfo;
  deleteUploadDocumentsToBucketResponses: DeleteInfo;
  deleteUserCareerLoadingResponses: DeleteInfo;
  deleteUserCourseLoadingResponses: DeleteInfo;
  deleteUserMasteries: DeleteInfo;
  deleteUserProgresses: DeleteInfo;
  deleteUsers: DeleteInfo;
  deleteWaterIntakeSummaries: DeleteInfo;
  deleteWaterIntakes: DeleteInfo;
  deleteWeeklySummaries: DeleteInfo;
  deleteWhitelistedEmails: DeleteInfo;
  deleteWorkouts: DeleteInfo;
  deleteYearlySummaries: DeleteInfo;
  editAssignment?: Maybe<AssignmentResponse>;
  enrollUserInTrack: ResponseType;
  generateSuggestedCareers?: Maybe<Career>;
  generateYoutubeKeywords?: Maybe<Scalars["String"]["output"]>;
  journeySubmitForm?: Maybe<ResponseType>;
  login?: Maybe<AuthPayloadResponse>;
  logout: ResponseType;
  markQuestComplete?: Maybe<Chat>;
  refreshQuest?: Maybe<RefreshQuestResponse>;
  removeFromWhitelist: Scalars["Boolean"]["output"];
  removeRoleFromUserForTenant?: Maybe<ResponseType>;
  resetAccount?: Maybe<ResponseType>;
  resetPassword?: Maybe<ResponseType>;
  setArchiveStatus: ResponseType;
  setCareerPathActive: Scalars["Boolean"]["output"];
  setKnowledgeRelevance: ResponseType;
  setOnboardingStatus: ResponseType;
  signup?: Maybe<AuthPayloadResponse>;
  updateAchievements: UpdateAchievementsMutationResponse;
  updateActiveTracks: UpdateActiveTracksMutationResponse;
  updateAddAssignmentResponses: UpdateAddAssignmentResponsesMutationResponse;
  updateAddContentModuleResponses: UpdateAddContentModuleResponsesMutationResponse;
  updateAspectDetailsTypes: UpdateAspectDetailsTypesMutationResponse;
  updateAssignedQuests: UpdateAssignedQuestsMutationResponse;
  updateAssignment: UpdateAssignmentResponse;
  updateAssignmentResponses: UpdateAssignmentResponsesMutationResponse;
  updateAssignments: UpdateAssignmentsMutationResponse;
  updateAttributes: UpdateAttributesMutationResponse;
  updateAudioToggleUserResponses: UpdateAudioToggleUserResponsesMutationResponse;
  updateAuthPayloadResponses: UpdateAuthPayloadResponsesMutationResponse;
  updateAuthPayloadUserPreferences: UpdateAuthPayloadUserPreferencesMutationResponse;
  updateAuthPayloadUsers: UpdateAuthPayloadUsersMutationResponse;
  updateCareerHeaders: UpdateCareerHeadersMutationResponse;
  updateCareerTopicResponses: UpdateCareerTopicResponsesMutationResponse;
  updateCareers: UpdateCareersMutationResponse;
  updateChallenges: UpdateChallengesMutationResponse;
  updateChapters: UpdateChaptersMutationResponse;
  updateChat: Chat;
  updateChats: UpdateChatsMutationResponse;
  updateChildInfos: UpdateChildInfosMutationResponse;
  updateContentModuleName: UpdateContentModuleNameResponse;
  updateCoreValues: UpdateCoreValuesMutationResponse;
  updateCourse?: Maybe<CourseResponse>;
  updateCourseCategories: UpdateCourseCategoriesMutationResponse;
  updateCourseCreationResponses: UpdateCourseCreationResponsesMutationResponse;
  updateCourseResponses: UpdateCourseResponsesMutationResponse;
  updateCourses: UpdateCoursesMutationResponse;
  updateCurricula: UpdateCurriculaMutationResponse;
  updateDailyCompletedTodosCounts: UpdateDailyCompletedTodosCountsMutationResponse;
  updateDailyExerciseTotals: UpdateDailyExerciseTotalsMutationResponse;
  updateDailyNutritionTotals: UpdateDailyNutritionTotalsMutationResponse;
  updateDailySummaries: UpdateDailySummariesMutationResponse;
  updateDeleteContentAssignmentResponses: UpdateDeleteContentAssignmentResponsesMutationResponse;
  updateDeleteContentAssignmentVariables: UpdateDeleteContentAssignmentVariablesMutationResponse;
  updateDeleteContentModuleResponses: UpdateDeleteContentModuleResponsesMutationResponse;
  updateDeleteContentModuleVariables: UpdateDeleteContentModuleVariablesMutationResponse;
  updateDeleteCourseResponses: UpdateDeleteCourseResponsesMutationResponse;
  updateDeviceSessions: UpdateDeviceSessionsMutationResponse;
  updateEmotionalStates: UpdateEmotionalStatesMutationResponse;
  updateEvents: UpdateEventsMutationResponse;
  updateExportCsvResponses: UpdateExportCsvResponsesMutationResponse;
  updateFeedback?: Maybe<Feedback>;
  updateFeedbacks: UpdateFeedbacksMutationResponse;
  updateFields: UpdateFieldsMutationResponse;
  updateFiles: UpdateFilesMutationResponse;
  updateFood?: Maybe<Food>;
  updateFoodItems: UpdateFoodItemsMutationResponse;
  updateFoods: UpdateFoodsMutationResponse;
  updateGames: UpdateGamesMutationResponse;
  updateGenerateds: UpdateGeneratedsMutationResponse;
  updateGetAssignmentObjectiveResponses: UpdateGetAssignmentObjectiveResponsesMutationResponse;
  updateGetAssignmentResponses: UpdateGetAssignmentResponsesMutationResponse;
  updateGetJourneyByIdResponses: UpdateGetJourneyByIdResponsesMutationResponse;
  updateGetJourneysResponses: UpdateGetJourneysResponsesMutationResponse;
  updateGoal?: Maybe<Goal>;
  updateGoals: UpdateGoalsMutationResponse;
  updateGoogleAuthTokens: UpdateGoogleAuthTokensMutationResponse;
  updateHierarchies: UpdateHierarchiesMutationResponse;
  updateHierarchyNodes: UpdateHierarchyNodesMutationResponse;
  updateHobbies: UpdateHobbiesMutationResponse;
  updateInterest?: Maybe<Interest>;
  updateInterests: UpdateInterestsMutationResponse;
  updateInviteCountResponses: UpdateInviteCountResponsesMutationResponse;
  updateJourneys: UpdateJourneysMutationResponse;
  updateLearningNodes: UpdateLearningNodesMutationResponse;
  updateLearningObjectiveCompletion: ResponseType;
  updateLearningObjectiveResponses: UpdateLearningObjectiveResponsesMutationResponse;
  updateLearningObjectives: UpdateLearningObjectivesMutationResponse;
  updateLearningSummaries: UpdateLearningSummariesMutationResponse;
  updateLearningTrees: UpdateLearningTreesMutationResponse;
  updateLesson?: Maybe<Lesson>;
  updateLessons: UpdateLessonsMutationResponse;
  updateLevelDetails: UpdateLevelDetailsMutationResponse;
  updateLifeAspirations: UpdateLifeAspirationsMutationResponse;
  updateMasteryResults: UpdateMasteryResultsMutationResponse;
  updateMeResponses: UpdateMeResponsesMutationResponse;
  updateMessages: UpdateMessagesMutationResponse;
  updateModule?: Maybe<ModuleResponse>;
  updateModuleResponses: UpdateModuleResponsesMutationResponse;
  updateModules: UpdateModulesMutationResponse;
  updateMonthlySummaries: UpdateMonthlySummariesMutationResponse;
  updateNodeIdentities: UpdateNodeIdentitiesMutationResponse;
  updateNodeProperties: UpdateNodePropertiesMutationResponse;
  updateNotifications?: Maybe<ResponseType>;
  updateNutritions: UpdateNutritionsMutationResponse;
  updateObjectives: UpdateObjectivesMutationResponse;
  updatePage?: Maybe<PageResponse>;
  updatePageResponses: UpdatePageResponsesMutationResponse;
  updatePages: UpdatePagesMutationResponse;
  updateParametersTypes: UpdateParametersTypesMutationResponse;
  updatePayments: UpdatePaymentsMutationResponse;
  updatePermissions: UpdatePermissionsMutationResponse;
  updatePersonalityTraits: UpdatePersonalityTraitsMutationResponse;
  updatePreference?: Maybe<Preferences>;
  updatePreferences: UpdatePreferencesMutationResponse;
  updatePrerequisites: UpdatePrerequisitesMutationResponse;
  updateProgress?: Maybe<Progress>;
  updateProgresses: UpdateProgressesMutationResponse;
  updateQuarterlySummaries: UpdateQuarterlySummariesMutationResponse;
  updateRateLimitResults: UpdateRateLimitResultsMutationResponse;
  updateRecentCategories: UpdateRecentCategoriesMutationResponse;
  updateRecentTracks: UpdateRecentTracksMutationResponse;
  updateRecomendeds: UpdateRecomendedsMutationResponse;
  updateReflections: UpdateReflectionsMutationResponse;
  updateRefreshQuestResponses: UpdateRefreshQuestResponsesMutationResponse;
  updateResources: UpdateResourcesMutationResponse;
  updateResponseTypes: UpdateResponseTypesMutationResponse;
  updateRoles: UpdateRolesMutationResponse;
  updateSchools: UpdateSchoolsMutationResponse;
  updateSessionResponses: UpdateSessionResponsesMutationResponse;
  updateSubchapterDetails: UpdateSubchapterDetailsMutationResponse;
  updateSubchapters: UpdateSubchaptersMutationResponse;
  updateSubfields: UpdateSubfieldsMutationResponse;
  updateSuggestions: UpdateSuggestionsMutationResponse;
  updateTenant?: Maybe<TenantResponse>;
  updateTenantResponses: UpdateTenantResponsesMutationResponse;
  updateTenants: UpdateTenantsMutationResponse;
  updateTodo?: Maybe<Todo>;
  updateTodos: UpdateTodosMutationResponse;
  updateToolAssignments: UpdateToolAssignmentsMutationResponse;
  updateTopic: ResponseType;
  updateTopics: UpdateTopicsMutationResponse;
  updateTrackOverviews: UpdateTrackOverviewsMutationResponse;
  updateTransactionByDates: UpdateTransactionByDatesMutationResponse;
  updateTransactions: UpdateTransactionsMutationResponse;
  updateUpdateAssignmentResponses: UpdateUpdateAssignmentResponsesMutationResponse;
  updateUpdateContentModuleNameResponses: UpdateUpdateContentModuleNameResponsesMutationResponse;
  updateUpdateUserModalOnboardingResponses: UpdateUpdateUserModalOnboardingResponsesMutationResponse;
  updateUploadDocumentsResponses: UpdateUploadDocumentsResponsesMutationResponse;
  updateUploadDocumentsToBucketResponses: UpdateUploadDocumentsToBucketResponsesMutationResponse;
  updateUserCareerLoadingResponses: UpdateUserCareerLoadingResponsesMutationResponse;
  updateUserCourseLoadingResponses: UpdateUserCourseLoadingResponsesMutationResponse;
  updateUserMasteries: UpdateUserMasteriesMutationResponse;
  updateUserModalOnboarding?: Maybe<UpdateUserModalOnboardingResponse>;
  updateUserProgresses: UpdateUserProgressesMutationResponse;
  updateUsers: UpdateUsersMutationResponse;
  updateWaterIntakeSummaries: UpdateWaterIntakeSummariesMutationResponse;
  updateWaterIntakes: UpdateWaterIntakesMutationResponse;
  updateWeeklySummaries: UpdateWeeklySummariesMutationResponse;
  updateWhitelistedEmails: UpdateWhitelistedEmailsMutationResponse;
  updateWorkouts: UpdateWorkoutsMutationResponse;
  updateYearlySummaries: UpdateYearlySummariesMutationResponse;
  uploadDocuments?: Maybe<UploadDocumentsResponse>;
  uploadDocumentsToBucket?: Maybe<UploadDocumentsToBucketResponse>;
};

export type MutationAddAssignmentArgs = {
  courseId: Scalars["ID"]["input"];
  moduleName: Scalars["String"]["input"];
  newAssignmentName: Scalars["String"]["input"];
  updatedHtml: Scalars["String"]["input"];
};

export type MutationAddContentModuleArgs = {
  courseId: Scalars["ID"]["input"];
  moduleName: Scalars["String"]["input"];
};

export type MutationAddFoodArgs = {
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  nutritionId?: InputMaybe<Scalars["String"]["input"]>;
  protein_grams?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  sodium_mg?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationAddNutritionArgs = {
  date: Scalars["String"]["input"];
  water_intake_ml?: InputMaybe<Scalars["Int"]["input"]>;
};

export type MutationAddToWhitelistArgs = {
  email: Scalars["String"]["input"];
};

export type MutationAddTodoArgs = {
  completed_date?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  isPartOfJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyId?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  priority?: InputMaybe<Scalars["String"]["input"]>;
  recurringDaily?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringDayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek?: InputMaybe<Scalars["String"]["input"]>;
  recurringFrequency?: InputMaybe<Scalars["String"]["input"]>;
  recurringTime?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationAssignRoleToUserForTenantArgs = {
  roleId: Scalars["ID"]["input"];
  tenantId: Scalars["ID"]["input"];
};

export type MutationAudioToggleUserArgs = {
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type MutationBulkCreateTenantsArgs = {
  tenants: Array<TenantInput>;
};

export type MutationCareerIsLoadingArgs = {
  isLoading: Scalars["Boolean"]["input"];
};

export type MutationCareerSubmitFormArgs = {
  careerGoal: Scalars["String"]["input"];
  careerPathName: Scalars["String"]["input"];
  currentOccupation: Scalars["String"]["input"];
};

export type MutationCareerTopicRelationshipArgs = {
  careerName: Scalars["String"]["input"];
  index: Scalars["Int"]["input"];
  topicName: Scalars["String"]["input"];
};

export type MutationCompleteJourneyArgs = {
  journeyId: Scalars["ID"]["input"];
};

export type MutationCourseCreationArgs = {
  objectives: Array<Scalars["String"]["input"]>;
  standard: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
  urls: Array<Scalars["String"]["input"]>;
  weeks: Scalars["Int"]["input"];
};

export type MutationCourseIsLoadingArgs = {
  isLoading: Scalars["Boolean"]["input"];
};

export type MutationCreateAchievementsArgs = {
  input: Array<AchievementsCreateInput>;
};

export type MutationCreateActiveTracksArgs = {
  input: Array<ActiveTrackCreateInput>;
};

export type MutationCreateAddAssignmentResponsesArgs = {
  input: Array<AddAssignmentResponseCreateInput>;
};

export type MutationCreateAddContentModuleResponsesArgs = {
  input: Array<AddContentModuleResponseCreateInput>;
};

export type MutationCreateAspectDetailsTypesArgs = {
  input: Array<AspectDetailsTypeCreateInput>;
};

export type MutationCreateAssignedQuestsArgs = {
  input: Array<AssignedQuestCreateInput>;
};

export type MutationCreateAssignmentArgs = {
  assignment: CreateAssignmentInput;
};

export type MutationCreateAssignmentResponsesArgs = {
  input: Array<AssignmentResponseCreateInput>;
};

export type MutationCreateAssignmentsArgs = {
  input: Array<AssignmentCreateInput>;
};

export type MutationCreateAttributesArgs = {
  input: Array<AttributeCreateInput>;
};

export type MutationCreateAudioToggleUserResponsesArgs = {
  input: Array<AudioToggleUserResponseCreateInput>;
};

export type MutationCreateAuthPayloadResponsesArgs = {
  input: Array<AuthPayloadResponseCreateInput>;
};

export type MutationCreateAuthPayloadUserPreferencesArgs = {
  input: Array<AuthPayloadUserPreferencesCreateInput>;
};

export type MutationCreateAuthPayloadUsersArgs = {
  input: Array<AuthPayloadUserCreateInput>;
};

export type MutationCreateCareerHeadersArgs = {
  input: Array<CareerHeaderCreateInput>;
};

export type MutationCreateCareerTopicResponsesArgs = {
  input: Array<CareerTopicResponseCreateInput>;
};

export type MutationCreateCareersArgs = {
  input: Array<CareerCreateInput>;
};

export type MutationCreateChallengesArgs = {
  input: Array<ChallengeCreateInput>;
};

export type MutationCreateChaptersArgs = {
  input: Array<ChapterCreateInput>;
};

export type MutationCreateChatsArgs = {
  input: Array<ChatCreateInput>;
};

export type MutationCreateChildInfosArgs = {
  input: Array<ChildInfoCreateInput>;
};

export type MutationCreateCoreValuesArgs = {
  input: Array<CoreValueCreateInput>;
};

export type MutationCreateCourseArgs = {
  course: CreateCourseInput;
};

export type MutationCreateCourseCategoriesArgs = {
  input: Array<CourseCategoryCreateInput>;
};

export type MutationCreateCourseCreationResponsesArgs = {
  input: Array<CourseCreationResponseCreateInput>;
};

export type MutationCreateCourseResponsesArgs = {
  input: Array<CourseResponseCreateInput>;
};

export type MutationCreateCoursesArgs = {
  input: Array<CourseCreateInput>;
};

export type MutationCreateCurriculaArgs = {
  input: Array<CurriculumCreateInput>;
};

export type MutationCreateDailyCompletedTodosCountsArgs = {
  input: Array<DailyCompletedTodosCountCreateInput>;
};

export type MutationCreateDailyExerciseTotalsArgs = {
  input: Array<DailyExerciseTotalsCreateInput>;
};

export type MutationCreateDailyNutritionTotalsArgs = {
  input: Array<DailyNutritionTotalsCreateInput>;
};

export type MutationCreateDailySummariesArgs = {
  input: Array<DailySummaryCreateInput>;
};

export type MutationCreateDeleteContentAssignmentResponsesArgs = {
  input: Array<DeleteContentAssignmentResponseCreateInput>;
};

export type MutationCreateDeleteContentAssignmentVariablesArgs = {
  input: Array<DeleteContentAssignmentVariablesCreateInput>;
};

export type MutationCreateDeleteContentModuleResponsesArgs = {
  input: Array<DeleteContentModuleResponseCreateInput>;
};

export type MutationCreateDeleteContentModuleVariablesArgs = {
  input: Array<DeleteContentModuleVariablesCreateInput>;
};

export type MutationCreateDeleteCourseResponsesArgs = {
  input: Array<DeleteCourseResponseCreateInput>;
};

export type MutationCreateDeviceSessionsArgs = {
  input: Array<DeviceSessionCreateInput>;
};

export type MutationCreateEducationChildrenArgs = {
  label: NodeLabel;
  name: Scalars["String"]["input"];
};

export type MutationCreateEmotionalStatesArgs = {
  input: Array<EmotionalStateCreateInput>;
};

export type MutationCreateEventsArgs = {
  input: Array<EventCreateInput>;
};

export type MutationCreateExportCsvResponsesArgs = {
  input: Array<ExportCsvResponseCreateInput>;
};

export type MutationCreateFeedbackArgs = {
  context: Scalars["String"]["input"];
  date: Scalars["Date"]["input"];
  sentiment: Scalars["String"]["input"];
  text: Scalars["String"]["input"];
  user: UserInput;
};

export type MutationCreateFeedbacksArgs = {
  input: Array<FeedbackCreateInput>;
};

export type MutationCreateFieldsArgs = {
  input: Array<FieldCreateInput>;
};

export type MutationCreateFilesArgs = {
  input: Array<FileCreateInput>;
};

export type MutationCreateFoodItemsArgs = {
  input: Array<FoodItemCreateInput>;
};

export type MutationCreateFoodsArgs = {
  input: Array<FoodCreateInput>;
};

export type MutationCreateGamesArgs = {
  input: Array<GameCreateInput>;
};

export type MutationCreateGeneratedsArgs = {
  input: Array<GeneratedCreateInput>;
};

export type MutationCreateGetAssignmentObjectiveResponsesArgs = {
  input: Array<GetAssignmentObjectiveResponseCreateInput>;
};

export type MutationCreateGetAssignmentResponsesArgs = {
  input: Array<GetAssignmentResponseCreateInput>;
};

export type MutationCreateGetJourneyByIdResponsesArgs = {
  input: Array<GetJourneyByIdResponseCreateInput>;
};

export type MutationCreateGetJourneysResponsesArgs = {
  input: Array<GetJourneysResponseCreateInput>;
};

export type MutationCreateGoalArgs = {
  category: Scalars["String"]["input"];
  completionStatus: Scalars["String"]["input"];
  deadline: Scalars["Date"]["input"];
  name: Scalars["String"]["input"];
  priority: Scalars["String"]["input"];
  startDate: Scalars["Date"]["input"];
  type: Scalars["String"]["input"];
  user: UserInput;
};

export type MutationCreateGoalsArgs = {
  input: Array<GoalCreateInput>;
};

export type MutationCreateGoogleAuthTokensArgs = {
  input: Array<GoogleAuthTokensCreateInput>;
};

export type MutationCreateHierarchiesArgs = {
  input: Array<HierarchyCreateInput>;
};

export type MutationCreateHierarchyNodesArgs = {
  input: Array<HierarchyNodeCreateInput>;
};

export type MutationCreateHobbiesArgs = {
  input: Array<HobbyCreateInput>;
};

export type MutationCreateInterestArgs = {
  category: Scalars["String"]["input"];
  lastUpdated: Scalars["Date"]["input"];
  level: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  startDate: Scalars["Date"]["input"];
  user: UserInput;
};

export type MutationCreateInterestsArgs = {
  input: Array<InterestCreateInput>;
};

export type MutationCreateInviteCountResponsesArgs = {
  input: Array<InviteCountResponseCreateInput>;
};

export type MutationCreateJourneysArgs = {
  input: Array<JourneyCreateInput>;
};

export type MutationCreateLearningNodesArgs = {
  input: Array<LearningNodeCreateInput>;
};

export type MutationCreateLearningObjectiveResponsesArgs = {
  input: Array<LearningObjectiveResponseCreateInput>;
};

export type MutationCreateLearningObjectivesArgs = {
  input: Array<LearningObjectiveCreateInput>;
};

export type MutationCreateLearningSummariesArgs = {
  input: Array<LearningSummaryCreateInput>;
};

export type MutationCreateLearningTreesArgs = {
  input: Array<LearningTreeCreateInput>;
};

export type MutationCreateLessonArgs = {
  category: Scalars["String"]["input"];
  completionStatus: Scalars["String"]["input"];
  difficultyLevel: Scalars["String"]["input"];
  endDate: Scalars["Date"]["input"];
  format: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  startDate: Scalars["Date"]["input"];
  user: UserInput;
};

export type MutationCreateLessonsArgs = {
  input: Array<LessonCreateInput>;
};

export type MutationCreateLevelDetailsArgs = {
  input: Array<LevelDetailsCreateInput>;
};

export type MutationCreateLifeAspirationsArgs = {
  input: Array<LifeAspirationCreateInput>;
};

export type MutationCreateMasteryResultsArgs = {
  input: Array<MasteryResultCreateInput>;
};

export type MutationCreateMeResponsesArgs = {
  input: Array<MeResponseCreateInput>;
};

export type MutationCreateMessagesArgs = {
  input: Array<MessageCreateInput>;
};

export type MutationCreateModuleAndResourceArgs = {
  module: CreateModuleInput;
};

export type MutationCreateModuleResponsesArgs = {
  input: Array<ModuleResponseCreateInput>;
};

export type MutationCreateModulesArgs = {
  input: Array<ModuleCreateInput>;
};

export type MutationCreateMonthlySummariesArgs = {
  input: Array<MonthlySummaryCreateInput>;
};

export type MutationCreateNodeIdentitiesArgs = {
  input: Array<NodeIdentityCreateInput>;
};

export type MutationCreateNodePropertiesArgs = {
  input: Array<NodePropertiesCreateInput>;
};

export type MutationCreateNutritionsArgs = {
  input: Array<NutritionCreateInput>;
};

export type MutationCreateObjectivesArgs = {
  input: Array<ObjectiveCreateInput>;
};

export type MutationCreatePageArgs = {
  page: CreatePageInput;
};

export type MutationCreatePageResponsesArgs = {
  input: Array<PageResponseCreateInput>;
};

export type MutationCreatePagesArgs = {
  input: Array<PageCreateInput>;
};

export type MutationCreateParametersTypesArgs = {
  input: Array<ParametersTypeCreateInput>;
};

export type MutationCreatePaymentsArgs = {
  input: Array<PaymentCreateInput>;
};

export type MutationCreatePermissionsArgs = {
  input: Array<PermissionCreateInput>;
};

export type MutationCreatePersonalityTraitsArgs = {
  input: Array<PersonalityTraitCreateInput>;
};

export type MutationCreatePreferenceArgs = {
  contentType: Scalars["String"]["input"];
  language: Scalars["String"]["input"];
  learningDuration: Scalars["Int"]["input"];
  learningStyle: Scalars["String"]["input"];
  notificationPreferences: Scalars["String"]["input"];
  timeOfDay: Scalars["String"]["input"];
  user: UserInput;
};

export type MutationCreatePreferencesArgs = {
  input: Array<PreferencesCreateInput>;
};

export type MutationCreatePrerequisitesArgs = {
  input: Array<PrerequisiteCreateInput>;
};

export type MutationCreateProgressArgs = {
  date: Scalars["Date"]["input"];
  description: Scalars["String"]["input"];
  level: Scalars["String"]["input"];
  type: Scalars["String"]["input"];
  user: UserInput;
};

export type MutationCreateProgressesArgs = {
  input: Array<ProgressCreateInput>;
};

export type MutationCreateQuarterlySummariesArgs = {
  input: Array<QuarterlySummaryCreateInput>;
};

export type MutationCreateRateLimitResultsArgs = {
  input: Array<RateLimitResultCreateInput>;
};

export type MutationCreateRecentCategoriesArgs = {
  input: Array<RecentCategoryCreateInput>;
};

export type MutationCreateRecentTracksArgs = {
  input: Array<RecentTrackCreateInput>;
};

export type MutationCreateRecomendedsArgs = {
  input: Array<RecomendedCreateInput>;
};

export type MutationCreateReflectionsArgs = {
  input: Array<ReflectionCreateInput>;
};

export type MutationCreateRefreshQuestResponsesArgs = {
  input: Array<RefreshQuestResponseCreateInput>;
};

export type MutationCreateResourcesArgs = {
  input: Array<ResourceCreateInput>;
};

export type MutationCreateResponseTypesArgs = {
  input: Array<ResponseTypeCreateInput>;
};

export type MutationCreateRolesArgs = {
  input: Array<RoleCreateInput>;
};

export type MutationCreateSchoolsArgs = {
  input: Array<SchoolCreateInput>;
};

export type MutationCreateSessionResponsesArgs = {
  input: Array<SessionResponseCreateInput>;
};

export type MutationCreateSubchapterDetailsArgs = {
  input: Array<SubchapterDetailsCreateInput>;
};

export type MutationCreateSubchaptersArgs = {
  input: Array<SubchapterCreateInput>;
};

export type MutationCreateSubfieldsArgs = {
  input: Array<SubfieldCreateInput>;
};

export type MutationCreateSuggestionsArgs = {
  input: Array<SuggestionCreateInput>;
};

export type MutationCreateTenantArgs = {
  tenant: TenantInput;
};

export type MutationCreateTenantResponsesArgs = {
  input: Array<TenantResponseCreateInput>;
};

export type MutationCreateTenantsArgs = {
  input: Array<TenantCreateInput>;
};

export type MutationCreateTodosArgs = {
  input: Array<TodoCreateInput>;
};

export type MutationCreateToolAssignmentsArgs = {
  input: Array<ToolAssignmentCreateInput>;
};

export type MutationCreateTopicsArgs = {
  input: Array<TopicCreateInput>;
};

export type MutationCreateTrackOverviewsArgs = {
  input: Array<TrackOverviewCreateInput>;
};

export type MutationCreateTransactionByDatesArgs = {
  input: Array<TransactionByDateCreateInput>;
};

export type MutationCreateTransactionsArgs = {
  input: Array<TransactionCreateInput>;
};

export type MutationCreateUpdateAssignmentResponsesArgs = {
  input: Array<UpdateAssignmentResponseCreateInput>;
};

export type MutationCreateUpdateContentModuleNameResponsesArgs = {
  input: Array<UpdateContentModuleNameResponseCreateInput>;
};

export type MutationCreateUpdateUserModalOnboardingResponsesArgs = {
  input: Array<UpdateUserModalOnboardingResponseCreateInput>;
};

export type MutationCreateUploadDocumentsResponsesArgs = {
  input: Array<UploadDocumentsResponseCreateInput>;
};

export type MutationCreateUploadDocumentsToBucketResponsesArgs = {
  input: Array<UploadDocumentsToBucketResponseCreateInput>;
};

export type MutationCreateUserCareerLoadingResponsesArgs = {
  input: Array<UserCareerLoadingResponseCreateInput>;
};

export type MutationCreateUserCourseLoadingResponsesArgs = {
  input: Array<UserCourseLoadingResponseCreateInput>;
};

export type MutationCreateUserMasteriesArgs = {
  input: Array<UserMasteryCreateInput>;
};

export type MutationCreateUserProgressesArgs = {
  input: Array<UserProgressCreateInput>;
};

export type MutationCreateUsersArgs = {
  input: Array<UserCreateInput>;
};

export type MutationCreateWaterIntakeSummariesArgs = {
  input: Array<WaterIntakeSummaryCreateInput>;
};

export type MutationCreateWaterIntakesArgs = {
  input: Array<WaterIntakeCreateInput>;
};

export type MutationCreateWeeklySummariesArgs = {
  input: Array<WeeklySummaryCreateInput>;
};

export type MutationCreateWhitelistedEmailsArgs = {
  input: Array<WhitelistedEmailCreateInput>;
};

export type MutationCreateWorkoutsArgs = {
  input: Array<WorkoutCreateInput>;
};

export type MutationCreateYearlySummariesArgs = {
  input: Array<YearlySummaryCreateInput>;
};

export type MutationDeleteAchievementsArgs = {
  delete?: InputMaybe<AchievementsDeleteInput>;
  where?: InputMaybe<AchievementsWhere>;
};

export type MutationDeleteActiveTracksArgs = {
  where?: InputMaybe<ActiveTrackWhere>;
};

export type MutationDeleteAddAssignmentResponsesArgs = {
  where?: InputMaybe<AddAssignmentResponseWhere>;
};

export type MutationDeleteAddContentModuleResponsesArgs = {
  where?: InputMaybe<AddContentModuleResponseWhere>;
};

export type MutationDeleteAspectArgs = {
  nodeId: Scalars["ID"]["input"];
};

export type MutationDeleteAspectDetailsTypesArgs = {
  where?: InputMaybe<AspectDetailsTypeWhere>;
};

export type MutationDeleteAssignedQuestsArgs = {
  where?: InputMaybe<AssignedQuestWhere>;
};

export type MutationDeleteAssignmentArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteAssignmentResponsesArgs = {
  where?: InputMaybe<AssignmentResponseWhere>;
};

export type MutationDeleteAssignmentsArgs = {
  delete?: InputMaybe<AssignmentDeleteInput>;
  where?: InputMaybe<AssignmentWhere>;
};

export type MutationDeleteAttributesArgs = {
  delete?: InputMaybe<AttributeDeleteInput>;
  where?: InputMaybe<AttributeWhere>;
};

export type MutationDeleteAudioToggleUserResponsesArgs = {
  where?: InputMaybe<AudioToggleUserResponseWhere>;
};

export type MutationDeleteAuthPayloadResponsesArgs = {
  where?: InputMaybe<AuthPayloadResponseWhere>;
};

export type MutationDeleteAuthPayloadUserPreferencesArgs = {
  where?: InputMaybe<AuthPayloadUserPreferencesWhere>;
};

export type MutationDeleteAuthPayloadUsersArgs = {
  where?: InputMaybe<AuthPayloadUserWhere>;
};

export type MutationDeleteCareerHeadersArgs = {
  where?: InputMaybe<CareerHeaderWhere>;
};

export type MutationDeleteCareerTopicResponsesArgs = {
  where?: InputMaybe<CareerTopicResponseWhere>;
};

export type MutationDeleteCareersArgs = {
  where?: InputMaybe<CareerWhere>;
};

export type MutationDeleteChallengesArgs = {
  where?: InputMaybe<ChallengeWhere>;
};

export type MutationDeleteChaptersArgs = {
  where?: InputMaybe<ChapterWhere>;
};

export type MutationDeleteChatsArgs = {
  delete?: InputMaybe<ChatDeleteInput>;
  where?: InputMaybe<ChatWhere>;
};

export type MutationDeleteChildInfosArgs = {
  where?: InputMaybe<ChildInfoWhere>;
};

export type MutationDeleteContentAssignmentArgs = {
  assignmentName: Scalars["String"]["input"];
  courseId: Scalars["ID"]["input"];
  moduleName: Scalars["String"]["input"];
};

export type MutationDeleteContentModuleArgs = {
  courseId: Scalars["ID"]["input"];
  moduleName: Scalars["String"]["input"];
};

export type MutationDeleteCoreValuesArgs = {
  where?: InputMaybe<CoreValueWhere>;
};

export type MutationDeleteCourseArgs = {
  courseId: Scalars["ID"]["input"];
};

export type MutationDeleteCourseCascadeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteCourseCategoriesArgs = {
  delete?: InputMaybe<CourseCategoryDeleteInput>;
  where?: InputMaybe<CourseCategoryWhere>;
};

export type MutationDeleteCourseCreationResponsesArgs = {
  where?: InputMaybe<CourseCreationResponseWhere>;
};

export type MutationDeleteCourseResponsesArgs = {
  where?: InputMaybe<CourseResponseWhere>;
};

export type MutationDeleteCoursesArgs = {
  delete?: InputMaybe<CourseDeleteInput>;
  where?: InputMaybe<CourseWhere>;
};

export type MutationDeleteCurriculaArgs = {
  where?: InputMaybe<CurriculumWhere>;
};

export type MutationDeleteDailyCompletedTodosCountsArgs = {
  where?: InputMaybe<DailyCompletedTodosCountWhere>;
};

export type MutationDeleteDailyExerciseTotalsArgs = {
  where?: InputMaybe<DailyExerciseTotalsWhere>;
};

export type MutationDeleteDailyNutritionTotalsArgs = {
  where?: InputMaybe<DailyNutritionTotalsWhere>;
};

export type MutationDeleteDailySummariesArgs = {
  delete?: InputMaybe<DailySummaryDeleteInput>;
  where?: InputMaybe<DailySummaryWhere>;
};

export type MutationDeleteDeleteContentAssignmentResponsesArgs = {
  where?: InputMaybe<DeleteContentAssignmentResponseWhere>;
};

export type MutationDeleteDeleteContentAssignmentVariablesArgs = {
  where?: InputMaybe<DeleteContentAssignmentVariablesWhere>;
};

export type MutationDeleteDeleteContentModuleResponsesArgs = {
  where?: InputMaybe<DeleteContentModuleResponseWhere>;
};

export type MutationDeleteDeleteContentModuleVariablesArgs = {
  where?: InputMaybe<DeleteContentModuleVariablesWhere>;
};

export type MutationDeleteDeleteCourseResponsesArgs = {
  where?: InputMaybe<DeleteCourseResponseWhere>;
};

export type MutationDeleteDeviceSessionsArgs = {
  delete?: InputMaybe<DeviceSessionDeleteInput>;
  where?: InputMaybe<DeviceSessionWhere>;
};

export type MutationDeleteEmotionalStatesArgs = {
  where?: InputMaybe<EmotionalStateWhere>;
};

export type MutationDeleteEventsArgs = {
  where?: InputMaybe<EventWhere>;
};

export type MutationDeleteExportCsvResponsesArgs = {
  where?: InputMaybe<ExportCsvResponseWhere>;
};

export type MutationDeleteFeedbacksArgs = {
  where?: InputMaybe<FeedbackWhere>;
};

export type MutationDeleteFieldsArgs = {
  where?: InputMaybe<FieldWhere>;
};

export type MutationDeleteFilesArgs = {
  where?: InputMaybe<FileWhere>;
};

export type MutationDeleteFoodArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteFoodItemsArgs = {
  delete?: InputMaybe<FoodItemDeleteInput>;
  where?: InputMaybe<FoodItemWhere>;
};

export type MutationDeleteFoodsArgs = {
  where?: InputMaybe<FoodWhere>;
};

export type MutationDeleteGamesArgs = {
  where?: InputMaybe<GameWhere>;
};

export type MutationDeleteGeneratedsArgs = {
  where?: InputMaybe<GeneratedWhere>;
};

export type MutationDeleteGetAssignmentObjectiveResponsesArgs = {
  where?: InputMaybe<GetAssignmentObjectiveResponseWhere>;
};

export type MutationDeleteGetAssignmentResponsesArgs = {
  where?: InputMaybe<GetAssignmentResponseWhere>;
};

export type MutationDeleteGetJourneyByIdResponsesArgs = {
  where?: InputMaybe<GetJourneyByIdResponseWhere>;
};

export type MutationDeleteGetJourneysResponsesArgs = {
  where?: InputMaybe<GetJourneysResponseWhere>;
};

export type MutationDeleteGoalArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteGoalsArgs = {
  where?: InputMaybe<GoalWhere>;
};

export type MutationDeleteGoogleAuthTokensArgs = {
  where?: InputMaybe<GoogleAuthTokensWhere>;
};

export type MutationDeleteHierarchiesArgs = {
  where?: InputMaybe<HierarchyWhere>;
};

export type MutationDeleteHierarchyNodesArgs = {
  where?: InputMaybe<HierarchyNodeWhere>;
};

export type MutationDeleteHobbiesArgs = {
  where?: InputMaybe<HobbyWhere>;
};

export type MutationDeleteInterestArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteInterestsArgs = {
  where?: InputMaybe<InterestWhere>;
};

export type MutationDeleteInviteCountResponsesArgs = {
  where?: InputMaybe<InviteCountResponseWhere>;
};

export type MutationDeleteJourneyArgs = {
  journeyId: Scalars["ID"]["input"];
};

export type MutationDeleteJourneysArgs = {
  delete?: InputMaybe<JourneyDeleteInput>;
  where?: InputMaybe<JourneyWhere>;
};

export type MutationDeleteLearningNodesArgs = {
  where?: InputMaybe<LearningNodeWhere>;
};

export type MutationDeleteLearningObjectiveResponsesArgs = {
  where?: InputMaybe<LearningObjectiveResponseWhere>;
};

export type MutationDeleteLearningObjectivesArgs = {
  where?: InputMaybe<LearningObjectiveWhere>;
};

export type MutationDeleteLearningSummariesArgs = {
  where?: InputMaybe<LearningSummaryWhere>;
};

export type MutationDeleteLearningTreesArgs = {
  where?: InputMaybe<LearningTreeWhere>;
};

export type MutationDeleteLessonArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteLessonsArgs = {
  where?: InputMaybe<LessonWhere>;
};

export type MutationDeleteLevelDetailsArgs = {
  where?: InputMaybe<LevelDetailsWhere>;
};

export type MutationDeleteLifeAspirationsArgs = {
  where?: InputMaybe<LifeAspirationWhere>;
};

export type MutationDeleteMasteryResultsArgs = {
  where?: InputMaybe<MasteryResultWhere>;
};

export type MutationDeleteMeResponsesArgs = {
  where?: InputMaybe<MeResponseWhere>;
};

export type MutationDeleteMessageArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteMessagesArgs = {
  delete?: InputMaybe<MessageDeleteInput>;
  where?: InputMaybe<MessageWhere>;
};

export type MutationDeleteModuleCascadeArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteModuleResponsesArgs = {
  where?: InputMaybe<ModuleResponseWhere>;
};

export type MutationDeleteModulesArgs = {
  delete?: InputMaybe<ModuleDeleteInput>;
  where?: InputMaybe<ModuleWhere>;
};

export type MutationDeleteMonthlySummariesArgs = {
  where?: InputMaybe<MonthlySummaryWhere>;
};

export type MutationDeleteNodeIdentitiesArgs = {
  where?: InputMaybe<NodeIdentityWhere>;
};

export type MutationDeleteNodePropertiesArgs = {
  where?: InputMaybe<NodePropertiesWhere>;
};

export type MutationDeleteNutritionsArgs = {
  where?: InputMaybe<NutritionWhere>;
};

export type MutationDeleteObjectivesArgs = {
  delete?: InputMaybe<ObjectiveDeleteInput>;
  where?: InputMaybe<ObjectiveWhere>;
};

export type MutationDeletePageArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeletePageResponsesArgs = {
  where?: InputMaybe<PageResponseWhere>;
};

export type MutationDeletePagesArgs = {
  delete?: InputMaybe<PageDeleteInput>;
  where?: InputMaybe<PageWhere>;
};

export type MutationDeleteParametersTypesArgs = {
  where?: InputMaybe<ParametersTypeWhere>;
};

export type MutationDeletePaymentsArgs = {
  delete?: InputMaybe<PaymentDeleteInput>;
  where?: InputMaybe<PaymentWhere>;
};

export type MutationDeletePermissionsArgs = {
  delete?: InputMaybe<PermissionDeleteInput>;
  where?: InputMaybe<PermissionWhere>;
};

export type MutationDeletePersonalityTraitsArgs = {
  where?: InputMaybe<PersonalityTraitWhere>;
};

export type MutationDeletePreferenceArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeletePreferencesArgs = {
  delete?: InputMaybe<PreferencesDeleteInput>;
  where?: InputMaybe<PreferencesWhere>;
};

export type MutationDeletePrerequisitesArgs = {
  where?: InputMaybe<PrerequisiteWhere>;
};

export type MutationDeleteProgressArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteProgressesArgs = {
  where?: InputMaybe<ProgressWhere>;
};

export type MutationDeleteQuarterlySummariesArgs = {
  where?: InputMaybe<QuarterlySummaryWhere>;
};

export type MutationDeleteRateLimitResultsArgs = {
  where?: InputMaybe<RateLimitResultWhere>;
};

export type MutationDeleteRecentCategoriesArgs = {
  where?: InputMaybe<RecentCategoryWhere>;
};

export type MutationDeleteRecentTracksArgs = {
  where?: InputMaybe<RecentTrackWhere>;
};

export type MutationDeleteRecomendedsArgs = {
  where?: InputMaybe<RecomendedWhere>;
};

export type MutationDeleteReflectionsArgs = {
  where?: InputMaybe<ReflectionWhere>;
};

export type MutationDeleteRefreshQuestResponsesArgs = {
  where?: InputMaybe<RefreshQuestResponseWhere>;
};

export type MutationDeleteResourcesArgs = {
  delete?: InputMaybe<ResourceDeleteInput>;
  where?: InputMaybe<ResourceWhere>;
};

export type MutationDeleteResponseTypesArgs = {
  where?: InputMaybe<ResponseTypeWhere>;
};

export type MutationDeleteRolesArgs = {
  delete?: InputMaybe<RoleDeleteInput>;
  where?: InputMaybe<RoleWhere>;
};

export type MutationDeleteSchoolsArgs = {
  where?: InputMaybe<SchoolWhere>;
};

export type MutationDeleteSessionResponsesArgs = {
  where?: InputMaybe<SessionResponseWhere>;
};

export type MutationDeleteSubchapterDetailsArgs = {
  where?: InputMaybe<SubchapterDetailsWhere>;
};

export type MutationDeleteSubchaptersArgs = {
  where?: InputMaybe<SubchapterWhere>;
};

export type MutationDeleteSubfieldsArgs = {
  where?: InputMaybe<SubfieldWhere>;
};

export type MutationDeleteSuggestionsArgs = {
  delete?: InputMaybe<SuggestionDeleteInput>;
  where?: InputMaybe<SuggestionWhere>;
};

export type MutationDeleteTenantArgs = {
  id: Scalars["ID"]["input"];
};

export type MutationDeleteTenantResponsesArgs = {
  where?: InputMaybe<TenantResponseWhere>;
};

export type MutationDeleteTenantsArgs = {
  delete?: InputMaybe<TenantDeleteInput>;
  where?: InputMaybe<TenantWhere>;
};

export type MutationDeleteTodoArgs = {
  id: Scalars["String"]["input"];
};

export type MutationDeleteTodosArgs = {
  delete?: InputMaybe<TodoDeleteInput>;
  where?: InputMaybe<TodoWhere>;
};

export type MutationDeleteToolAssignmentsArgs = {
  where?: InputMaybe<ToolAssignmentWhere>;
};

export type MutationDeleteTopicsArgs = {
  where?: InputMaybe<TopicWhere>;
};

export type MutationDeleteTrackOverviewsArgs = {
  where?: InputMaybe<TrackOverviewWhere>;
};

export type MutationDeleteTransactionByDatesArgs = {
  where?: InputMaybe<TransactionByDateWhere>;
};

export type MutationDeleteTransactionsArgs = {
  where?: InputMaybe<TransactionWhere>;
};

export type MutationDeleteUpdateAssignmentResponsesArgs = {
  where?: InputMaybe<UpdateAssignmentResponseWhere>;
};

export type MutationDeleteUpdateContentModuleNameResponsesArgs = {
  where?: InputMaybe<UpdateContentModuleNameResponseWhere>;
};

export type MutationDeleteUpdateUserModalOnboardingResponsesArgs = {
  where?: InputMaybe<UpdateUserModalOnboardingResponseWhere>;
};

export type MutationDeleteUploadDocumentsResponsesArgs = {
  where?: InputMaybe<UploadDocumentsResponseWhere>;
};

export type MutationDeleteUploadDocumentsToBucketResponsesArgs = {
  where?: InputMaybe<UploadDocumentsToBucketResponseWhere>;
};

export type MutationDeleteUserCareerLoadingResponsesArgs = {
  where?: InputMaybe<UserCareerLoadingResponseWhere>;
};

export type MutationDeleteUserCourseLoadingResponsesArgs = {
  where?: InputMaybe<UserCourseLoadingResponseWhere>;
};

export type MutationDeleteUserMasteriesArgs = {
  where?: InputMaybe<UserMasteryWhere>;
};

export type MutationDeleteUserProgressesArgs = {
  where?: InputMaybe<UserProgressWhere>;
};

export type MutationDeleteUsersArgs = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<UserWhere>;
};

export type MutationDeleteWaterIntakeSummariesArgs = {
  where?: InputMaybe<WaterIntakeSummaryWhere>;
};

export type MutationDeleteWaterIntakesArgs = {
  delete?: InputMaybe<WaterIntakeDeleteInput>;
  where?: InputMaybe<WaterIntakeWhere>;
};

export type MutationDeleteWeeklySummariesArgs = {
  delete?: InputMaybe<WeeklySummaryDeleteInput>;
  where?: InputMaybe<WeeklySummaryWhere>;
};

export type MutationDeleteWhitelistedEmailsArgs = {
  delete?: InputMaybe<WhitelistedEmailDeleteInput>;
  where?: InputMaybe<WhitelistedEmailWhere>;
};

export type MutationDeleteWorkoutsArgs = {
  where?: InputMaybe<WorkoutWhere>;
};

export type MutationDeleteYearlySummariesArgs = {
  where?: InputMaybe<YearlySummaryWhere>;
};

export type MutationEditAssignmentArgs = {
  assignment: UpdateAssignmentInput;
  id: Scalars["ID"]["input"];
};

export type MutationEnrollUserInTrackArgs = {
  topicName: Scalars["String"]["input"];
};

export type MutationGenerateYoutubeKeywordsArgs = {
  description: Scalars["String"]["input"];
  title: Scalars["String"]["input"];
};

export type MutationJourneySubmitFormArgs = {
  description: Scalars["String"]["input"];
  dueDate: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type MutationLoginArgs = {
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
};

export type MutationMarkQuestCompleteArgs = {
  chatId: Scalars["ID"]["input"];
  questId: Scalars["ID"]["input"];
};

export type MutationRefreshQuestArgs = {
  questIdToReplace: Scalars["ID"]["input"];
  timezone: Scalars["String"]["input"];
};

export type MutationRemoveFromWhitelistArgs = {
  email: Scalars["String"]["input"];
};

export type MutationRemoveRoleFromUserForTenantArgs = {
  roleId: Scalars["ID"]["input"];
  tenantId: Scalars["ID"]["input"];
};

export type MutationResetPasswordArgs = {
  newPassword: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
};

export type MutationSetArchiveStatusArgs = {
  isArchived: Scalars["Boolean"]["input"];
  nodeId: Scalars["ID"]["input"];
};

export type MutationSetCareerPathActiveArgs = {
  careerId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type MutationSetKnowledgeRelevanceArgs = {
  isRelevant: Scalars["Boolean"]["input"];
  nodeLabel: Scalars["String"]["input"];
  nodeName: Scalars["String"]["input"];
};

export type MutationSetOnboardingStatusArgs = {
  isOnboarded: Scalars["Boolean"]["input"];
};

export type MutationSignupArgs = {
  birthday: Scalars["String"]["input"];
  dailyEmailOptIn: Scalars["Boolean"]["input"];
  email: Scalars["String"]["input"];
  gender?: InputMaybe<Scalars["String"]["input"]>;
  language: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type MutationUpdateAchievementsArgs = {
  update?: InputMaybe<AchievementsUpdateInput>;
  where?: InputMaybe<AchievementsWhere>;
};

export type MutationUpdateActiveTracksArgs = {
  update?: InputMaybe<ActiveTrackUpdateInput>;
  where?: InputMaybe<ActiveTrackWhere>;
};

export type MutationUpdateAddAssignmentResponsesArgs = {
  update?: InputMaybe<AddAssignmentResponseUpdateInput>;
  where?: InputMaybe<AddAssignmentResponseWhere>;
};

export type MutationUpdateAddContentModuleResponsesArgs = {
  update?: InputMaybe<AddContentModuleResponseUpdateInput>;
  where?: InputMaybe<AddContentModuleResponseWhere>;
};

export type MutationUpdateAspectDetailsTypesArgs = {
  update?: InputMaybe<AspectDetailsTypeUpdateInput>;
  where?: InputMaybe<AspectDetailsTypeWhere>;
};

export type MutationUpdateAssignedQuestsArgs = {
  update?: InputMaybe<AssignedQuestUpdateInput>;
  where?: InputMaybe<AssignedQuestWhere>;
};

export type MutationUpdateAssignmentArgs = {
  courseId: Scalars["ID"]["input"];
  currentAssignmentName: Scalars["String"]["input"];
  moduleName: Scalars["String"]["input"];
  newAssignmentName: Scalars["String"]["input"];
  updatedHtml: Scalars["String"]["input"];
};

export type MutationUpdateAssignmentResponsesArgs = {
  update?: InputMaybe<AssignmentResponseUpdateInput>;
  where?: InputMaybe<AssignmentResponseWhere>;
};

export type MutationUpdateAssignmentsArgs = {
  update?: InputMaybe<AssignmentUpdateInput>;
  where?: InputMaybe<AssignmentWhere>;
};

export type MutationUpdateAttributesArgs = {
  update?: InputMaybe<AttributeUpdateInput>;
  where?: InputMaybe<AttributeWhere>;
};

export type MutationUpdateAudioToggleUserResponsesArgs = {
  update?: InputMaybe<AudioToggleUserResponseUpdateInput>;
  where?: InputMaybe<AudioToggleUserResponseWhere>;
};

export type MutationUpdateAuthPayloadResponsesArgs = {
  update?: InputMaybe<AuthPayloadResponseUpdateInput>;
  where?: InputMaybe<AuthPayloadResponseWhere>;
};

export type MutationUpdateAuthPayloadUserPreferencesArgs = {
  update?: InputMaybe<AuthPayloadUserPreferencesUpdateInput>;
  where?: InputMaybe<AuthPayloadUserPreferencesWhere>;
};

export type MutationUpdateAuthPayloadUsersArgs = {
  update?: InputMaybe<AuthPayloadUserUpdateInput>;
  where?: InputMaybe<AuthPayloadUserWhere>;
};

export type MutationUpdateCareerHeadersArgs = {
  update?: InputMaybe<CareerHeaderUpdateInput>;
  where?: InputMaybe<CareerHeaderWhere>;
};

export type MutationUpdateCareerTopicResponsesArgs = {
  update?: InputMaybe<CareerTopicResponseUpdateInput>;
  where?: InputMaybe<CareerTopicResponseWhere>;
};

export type MutationUpdateCareersArgs = {
  update?: InputMaybe<CareerUpdateInput>;
  where?: InputMaybe<CareerWhere>;
};

export type MutationUpdateChallengesArgs = {
  update?: InputMaybe<ChallengeUpdateInput>;
  where?: InputMaybe<ChallengeWhere>;
};

export type MutationUpdateChaptersArgs = {
  update?: InputMaybe<ChapterUpdateInput>;
  where?: InputMaybe<ChapterWhere>;
};

export type MutationUpdateChatArgs = {
  chatId: Scalars["String"]["input"];
  messageId: Scalars["String"]["input"];
  newContent: Scalars["String"]["input"];
};

export type MutationUpdateChatsArgs = {
  update?: InputMaybe<ChatUpdateInput>;
  where?: InputMaybe<ChatWhere>;
};

export type MutationUpdateChildInfosArgs = {
  update?: InputMaybe<ChildInfoUpdateInput>;
  where?: InputMaybe<ChildInfoWhere>;
};

export type MutationUpdateContentModuleNameArgs = {
  courseId: Scalars["ID"]["input"];
  newModuleName: Scalars["String"]["input"];
  oldModuleName: Scalars["String"]["input"];
};

export type MutationUpdateCoreValuesArgs = {
  update?: InputMaybe<CoreValueUpdateInput>;
  where?: InputMaybe<CoreValueWhere>;
};

export type MutationUpdateCourseArgs = {
  course: UpdateCourseInput;
  id: Scalars["ID"]["input"];
};

export type MutationUpdateCourseCategoriesArgs = {
  update?: InputMaybe<CourseCategoryUpdateInput>;
  where?: InputMaybe<CourseCategoryWhere>;
};

export type MutationUpdateCourseCreationResponsesArgs = {
  update?: InputMaybe<CourseCreationResponseUpdateInput>;
  where?: InputMaybe<CourseCreationResponseWhere>;
};

export type MutationUpdateCourseResponsesArgs = {
  update?: InputMaybe<CourseResponseUpdateInput>;
  where?: InputMaybe<CourseResponseWhere>;
};

export type MutationUpdateCoursesArgs = {
  update?: InputMaybe<CourseUpdateInput>;
  where?: InputMaybe<CourseWhere>;
};

export type MutationUpdateCurriculaArgs = {
  update?: InputMaybe<CurriculumUpdateInput>;
  where?: InputMaybe<CurriculumWhere>;
};

export type MutationUpdateDailyCompletedTodosCountsArgs = {
  update?: InputMaybe<DailyCompletedTodosCountUpdateInput>;
  where?: InputMaybe<DailyCompletedTodosCountWhere>;
};

export type MutationUpdateDailyExerciseTotalsArgs = {
  update?: InputMaybe<DailyExerciseTotalsUpdateInput>;
  where?: InputMaybe<DailyExerciseTotalsWhere>;
};

export type MutationUpdateDailyNutritionTotalsArgs = {
  update?: InputMaybe<DailyNutritionTotalsUpdateInput>;
  where?: InputMaybe<DailyNutritionTotalsWhere>;
};

export type MutationUpdateDailySummariesArgs = {
  update?: InputMaybe<DailySummaryUpdateInput>;
  where?: InputMaybe<DailySummaryWhere>;
};

export type MutationUpdateDeleteContentAssignmentResponsesArgs = {
  update?: InputMaybe<DeleteContentAssignmentResponseUpdateInput>;
  where?: InputMaybe<DeleteContentAssignmentResponseWhere>;
};

export type MutationUpdateDeleteContentAssignmentVariablesArgs = {
  update?: InputMaybe<DeleteContentAssignmentVariablesUpdateInput>;
  where?: InputMaybe<DeleteContentAssignmentVariablesWhere>;
};

export type MutationUpdateDeleteContentModuleResponsesArgs = {
  update?: InputMaybe<DeleteContentModuleResponseUpdateInput>;
  where?: InputMaybe<DeleteContentModuleResponseWhere>;
};

export type MutationUpdateDeleteContentModuleVariablesArgs = {
  update?: InputMaybe<DeleteContentModuleVariablesUpdateInput>;
  where?: InputMaybe<DeleteContentModuleVariablesWhere>;
};

export type MutationUpdateDeleteCourseResponsesArgs = {
  update?: InputMaybe<DeleteCourseResponseUpdateInput>;
  where?: InputMaybe<DeleteCourseResponseWhere>;
};

export type MutationUpdateDeviceSessionsArgs = {
  update?: InputMaybe<DeviceSessionUpdateInput>;
  where?: InputMaybe<DeviceSessionWhere>;
};

export type MutationUpdateEmotionalStatesArgs = {
  update?: InputMaybe<EmotionalStateUpdateInput>;
  where?: InputMaybe<EmotionalStateWhere>;
};

export type MutationUpdateEventsArgs = {
  update?: InputMaybe<EventUpdateInput>;
  where?: InputMaybe<EventWhere>;
};

export type MutationUpdateExportCsvResponsesArgs = {
  update?: InputMaybe<ExportCsvResponseUpdateInput>;
  where?: InputMaybe<ExportCsvResponseWhere>;
};

export type MutationUpdateFeedbackArgs = {
  context?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["Date"]["input"]>;
  id: Scalars["ID"]["input"];
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  user: UserInput;
};

export type MutationUpdateFeedbacksArgs = {
  update?: InputMaybe<FeedbackUpdateInput>;
  where?: InputMaybe<FeedbackWhere>;
};

export type MutationUpdateFieldsArgs = {
  update?: InputMaybe<FieldUpdateInput>;
  where?: InputMaybe<FieldWhere>;
};

export type MutationUpdateFilesArgs = {
  update?: InputMaybe<FileUpdateInput>;
  where?: InputMaybe<FileWhere>;
};

export type MutationUpdateFoodArgs = {
  calories?: InputMaybe<Scalars["Float"]["input"]>;
  carbohydrates_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fat_grams?: InputMaybe<Scalars["Float"]["input"]>;
  fiber_grams?: InputMaybe<Scalars["Float"]["input"]>;
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  protein_grams?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size?: InputMaybe<Scalars["String"]["input"]>;
  sodium_mg?: InputMaybe<Scalars["Float"]["input"]>;
  sugars_grams?: InputMaybe<Scalars["Float"]["input"]>;
};

export type MutationUpdateFoodItemsArgs = {
  update?: InputMaybe<FoodItemUpdateInput>;
  where?: InputMaybe<FoodItemWhere>;
};

export type MutationUpdateFoodsArgs = {
  update?: InputMaybe<FoodUpdateInput>;
  where?: InputMaybe<FoodWhere>;
};

export type MutationUpdateGamesArgs = {
  update?: InputMaybe<GameUpdateInput>;
  where?: InputMaybe<GameWhere>;
};

export type MutationUpdateGeneratedsArgs = {
  update?: InputMaybe<GeneratedUpdateInput>;
  where?: InputMaybe<GeneratedWhere>;
};

export type MutationUpdateGetAssignmentObjectiveResponsesArgs = {
  update?: InputMaybe<GetAssignmentObjectiveResponseUpdateInput>;
  where?: InputMaybe<GetAssignmentObjectiveResponseWhere>;
};

export type MutationUpdateGetAssignmentResponsesArgs = {
  update?: InputMaybe<GetAssignmentResponseUpdateInput>;
  where?: InputMaybe<GetAssignmentResponseWhere>;
};

export type MutationUpdateGetJourneyByIdResponsesArgs = {
  update?: InputMaybe<GetJourneyByIdResponseUpdateInput>;
  where?: InputMaybe<GetJourneyByIdResponseWhere>;
};

export type MutationUpdateGetJourneysResponsesArgs = {
  update?: InputMaybe<GetJourneysResponseUpdateInput>;
  where?: InputMaybe<GetJourneysResponseWhere>;
};

export type MutationUpdateGoalArgs = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  deadline?: InputMaybe<Scalars["Date"]["input"]>;
  id: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  user: UserInput;
};

export type MutationUpdateGoalsArgs = {
  update?: InputMaybe<GoalUpdateInput>;
  where?: InputMaybe<GoalWhere>;
};

export type MutationUpdateGoogleAuthTokensArgs = {
  update?: InputMaybe<GoogleAuthTokensUpdateInput>;
  where?: InputMaybe<GoogleAuthTokensWhere>;
};

export type MutationUpdateHierarchiesArgs = {
  update?: InputMaybe<HierarchyUpdateInput>;
  where?: InputMaybe<HierarchyWhere>;
};

export type MutationUpdateHierarchyNodesArgs = {
  update?: InputMaybe<HierarchyNodeUpdateInput>;
  where?: InputMaybe<HierarchyNodeWhere>;
};

export type MutationUpdateHobbiesArgs = {
  update?: InputMaybe<HobbyUpdateInput>;
  where?: InputMaybe<HobbyWhere>;
};

export type MutationUpdateInterestArgs = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  lastUpdated?: InputMaybe<Scalars["Date"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  user: UserInput;
};

export type MutationUpdateInterestsArgs = {
  update?: InputMaybe<InterestUpdateInput>;
  where?: InputMaybe<InterestWhere>;
};

export type MutationUpdateInviteCountResponsesArgs = {
  update?: InputMaybe<InviteCountResponseUpdateInput>;
  where?: InputMaybe<InviteCountResponseWhere>;
};

export type MutationUpdateJourneysArgs = {
  update?: InputMaybe<JourneyUpdateInput>;
  where?: InputMaybe<JourneyWhere>;
};

export type MutationUpdateLearningNodesArgs = {
  update?: InputMaybe<LearningNodeUpdateInput>;
  where?: InputMaybe<LearningNodeWhere>;
};

export type MutationUpdateLearningObjectiveCompletionArgs = {
  completed: Scalars["Boolean"]["input"];
  id: Scalars["ID"]["input"];
};

export type MutationUpdateLearningObjectiveResponsesArgs = {
  update?: InputMaybe<LearningObjectiveResponseUpdateInput>;
  where?: InputMaybe<LearningObjectiveResponseWhere>;
};

export type MutationUpdateLearningObjectivesArgs = {
  update?: InputMaybe<LearningObjectiveUpdateInput>;
  where?: InputMaybe<LearningObjectiveWhere>;
};

export type MutationUpdateLearningSummariesArgs = {
  update?: InputMaybe<LearningSummaryUpdateInput>;
  where?: InputMaybe<LearningSummaryWhere>;
};

export type MutationUpdateLearningTreesArgs = {
  update?: InputMaybe<LearningTreeUpdateInput>;
  where?: InputMaybe<LearningTreeWhere>;
};

export type MutationUpdateLessonArgs = {
  category?: InputMaybe<Scalars["String"]["input"]>;
  completionStatus?: InputMaybe<Scalars["String"]["input"]>;
  difficultyLevel?: InputMaybe<Scalars["String"]["input"]>;
  endDate?: InputMaybe<Scalars["Date"]["input"]>;
  format?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  name?: InputMaybe<Scalars["String"]["input"]>;
  startDate?: InputMaybe<Scalars["Date"]["input"]>;
  user: UserInput;
};

export type MutationUpdateLessonsArgs = {
  update?: InputMaybe<LessonUpdateInput>;
  where?: InputMaybe<LessonWhere>;
};

export type MutationUpdateLevelDetailsArgs = {
  update?: InputMaybe<LevelDetailsUpdateInput>;
  where?: InputMaybe<LevelDetailsWhere>;
};

export type MutationUpdateLifeAspirationsArgs = {
  update?: InputMaybe<LifeAspirationUpdateInput>;
  where?: InputMaybe<LifeAspirationWhere>;
};

export type MutationUpdateMasteryResultsArgs = {
  update?: InputMaybe<MasteryResultUpdateInput>;
  where?: InputMaybe<MasteryResultWhere>;
};

export type MutationUpdateMeResponsesArgs = {
  update?: InputMaybe<MeResponseUpdateInput>;
  where?: InputMaybe<MeResponseWhere>;
};

export type MutationUpdateMessagesArgs = {
  update?: InputMaybe<MessageUpdateInput>;
  where?: InputMaybe<MessageWhere>;
};

export type MutationUpdateModuleArgs = {
  id: Scalars["ID"]["input"];
  module: UpdateModuleInput;
};

export type MutationUpdateModuleResponsesArgs = {
  update?: InputMaybe<ModuleResponseUpdateInput>;
  where?: InputMaybe<ModuleResponseWhere>;
};

export type MutationUpdateModulesArgs = {
  update?: InputMaybe<ModuleUpdateInput>;
  where?: InputMaybe<ModuleWhere>;
};

export type MutationUpdateMonthlySummariesArgs = {
  update?: InputMaybe<MonthlySummaryUpdateInput>;
  where?: InputMaybe<MonthlySummaryWhere>;
};

export type MutationUpdateNodeIdentitiesArgs = {
  update?: InputMaybe<NodeIdentityUpdateInput>;
  where?: InputMaybe<NodeIdentityWhere>;
};

export type MutationUpdateNodePropertiesArgs = {
  update?: InputMaybe<NodePropertiesUpdateInput>;
  where?: InputMaybe<NodePropertiesWhere>;
};

export type MutationUpdateNotificationsArgs = {
  dailyEmailOptIn: Scalars["Boolean"]["input"];
};

export type MutationUpdateNutritionsArgs = {
  update?: InputMaybe<NutritionUpdateInput>;
  where?: InputMaybe<NutritionWhere>;
};

export type MutationUpdateObjectivesArgs = {
  update?: InputMaybe<ObjectiveUpdateInput>;
  where?: InputMaybe<ObjectiveWhere>;
};

export type MutationUpdatePageArgs = {
  id: Scalars["ID"]["input"];
  page: UpdatePageInput;
};

export type MutationUpdatePageResponsesArgs = {
  update?: InputMaybe<PageResponseUpdateInput>;
  where?: InputMaybe<PageResponseWhere>;
};

export type MutationUpdatePagesArgs = {
  update?: InputMaybe<PageUpdateInput>;
  where?: InputMaybe<PageWhere>;
};

export type MutationUpdateParametersTypesArgs = {
  update?: InputMaybe<ParametersTypeUpdateInput>;
  where?: InputMaybe<ParametersTypeWhere>;
};

export type MutationUpdatePaymentsArgs = {
  update?: InputMaybe<PaymentUpdateInput>;
  where?: InputMaybe<PaymentWhere>;
};

export type MutationUpdatePermissionsArgs = {
  update?: InputMaybe<PermissionUpdateInput>;
  where?: InputMaybe<PermissionWhere>;
};

export type MutationUpdatePersonalityTraitsArgs = {
  update?: InputMaybe<PersonalityTraitUpdateInput>;
  where?: InputMaybe<PersonalityTraitWhere>;
};

export type MutationUpdatePreferenceArgs = {
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  language?: InputMaybe<Scalars["String"]["input"]>;
  learningDuration?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  user: UserInput;
};

export type MutationUpdatePreferencesArgs = {
  update?: InputMaybe<PreferencesUpdateInput>;
  where?: InputMaybe<PreferencesWhere>;
};

export type MutationUpdatePrerequisitesArgs = {
  update?: InputMaybe<PrerequisiteUpdateInput>;
  where?: InputMaybe<PrerequisiteWhere>;
};

export type MutationUpdateProgressArgs = {
  date?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  level?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  user: UserInput;
};

export type MutationUpdateProgressesArgs = {
  update?: InputMaybe<ProgressUpdateInput>;
  where?: InputMaybe<ProgressWhere>;
};

export type MutationUpdateQuarterlySummariesArgs = {
  update?: InputMaybe<QuarterlySummaryUpdateInput>;
  where?: InputMaybe<QuarterlySummaryWhere>;
};

export type MutationUpdateRateLimitResultsArgs = {
  update?: InputMaybe<RateLimitResultUpdateInput>;
  where?: InputMaybe<RateLimitResultWhere>;
};

export type MutationUpdateRecentCategoriesArgs = {
  update?: InputMaybe<RecentCategoryUpdateInput>;
  where?: InputMaybe<RecentCategoryWhere>;
};

export type MutationUpdateRecentTracksArgs = {
  update?: InputMaybe<RecentTrackUpdateInput>;
  where?: InputMaybe<RecentTrackWhere>;
};

export type MutationUpdateRecomendedsArgs = {
  update?: InputMaybe<RecomendedUpdateInput>;
  where?: InputMaybe<RecomendedWhere>;
};

export type MutationUpdateReflectionsArgs = {
  update?: InputMaybe<ReflectionUpdateInput>;
  where?: InputMaybe<ReflectionWhere>;
};

export type MutationUpdateRefreshQuestResponsesArgs = {
  update?: InputMaybe<RefreshQuestResponseUpdateInput>;
  where?: InputMaybe<RefreshQuestResponseWhere>;
};

export type MutationUpdateResourcesArgs = {
  update?: InputMaybe<ResourceUpdateInput>;
  where?: InputMaybe<ResourceWhere>;
};

export type MutationUpdateResponseTypesArgs = {
  update?: InputMaybe<ResponseTypeUpdateInput>;
  where?: InputMaybe<ResponseTypeWhere>;
};

export type MutationUpdateRolesArgs = {
  update?: InputMaybe<RoleUpdateInput>;
  where?: InputMaybe<RoleWhere>;
};

export type MutationUpdateSchoolsArgs = {
  update?: InputMaybe<SchoolUpdateInput>;
  where?: InputMaybe<SchoolWhere>;
};

export type MutationUpdateSessionResponsesArgs = {
  update?: InputMaybe<SessionResponseUpdateInput>;
  where?: InputMaybe<SessionResponseWhere>;
};

export type MutationUpdateSubchapterDetailsArgs = {
  update?: InputMaybe<SubchapterDetailsUpdateInput>;
  where?: InputMaybe<SubchapterDetailsWhere>;
};

export type MutationUpdateSubchaptersArgs = {
  update?: InputMaybe<SubchapterUpdateInput>;
  where?: InputMaybe<SubchapterWhere>;
};

export type MutationUpdateSubfieldsArgs = {
  update?: InputMaybe<SubfieldUpdateInput>;
  where?: InputMaybe<SubfieldWhere>;
};

export type MutationUpdateSuggestionsArgs = {
  update?: InputMaybe<SuggestionUpdateInput>;
  where?: InputMaybe<SuggestionWhere>;
};

export type MutationUpdateTenantArgs = {
  id: Scalars["ID"]["input"];
  tenant: TenantInput;
};

export type MutationUpdateTenantResponsesArgs = {
  update?: InputMaybe<TenantResponseUpdateInput>;
  where?: InputMaybe<TenantResponseWhere>;
};

export type MutationUpdateTenantsArgs = {
  update?: InputMaybe<TenantUpdateInput>;
  where?: InputMaybe<TenantWhere>;
};

export type MutationUpdateTodoArgs = {
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  completed_date?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["String"]["input"];
  isPartOfJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyId?: InputMaybe<Scalars["ID"]["input"]>;
  name: Scalars["String"]["input"];
  pointsReceived?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  recurringDaily?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringDayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek?: InputMaybe<Scalars["String"]["input"]>;
  recurringFrequency?: InputMaybe<Scalars["String"]["input"]>;
  recurringTime?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timezone: Scalars["String"]["input"];
};

export type MutationUpdateTodosArgs = {
  update?: InputMaybe<TodoUpdateInput>;
  where?: InputMaybe<TodoWhere>;
};

export type MutationUpdateToolAssignmentsArgs = {
  update?: InputMaybe<ToolAssignmentUpdateInput>;
  where?: InputMaybe<ToolAssignmentWhere>;
};

export type MutationUpdateTopicArgs = {
  operationType: Scalars["String"]["input"];
  topicName: Scalars["String"]["input"];
};

export type MutationUpdateTopicsArgs = {
  update?: InputMaybe<TopicUpdateInput>;
  where?: InputMaybe<TopicWhere>;
};

export type MutationUpdateTrackOverviewsArgs = {
  update?: InputMaybe<TrackOverviewUpdateInput>;
  where?: InputMaybe<TrackOverviewWhere>;
};

export type MutationUpdateTransactionByDatesArgs = {
  update?: InputMaybe<TransactionByDateUpdateInput>;
  where?: InputMaybe<TransactionByDateWhere>;
};

export type MutationUpdateTransactionsArgs = {
  update?: InputMaybe<TransactionUpdateInput>;
  where?: InputMaybe<TransactionWhere>;
};

export type MutationUpdateUpdateAssignmentResponsesArgs = {
  update?: InputMaybe<UpdateAssignmentResponseUpdateInput>;
  where?: InputMaybe<UpdateAssignmentResponseWhere>;
};

export type MutationUpdateUpdateContentModuleNameResponsesArgs = {
  update?: InputMaybe<UpdateContentModuleNameResponseUpdateInput>;
  where?: InputMaybe<UpdateContentModuleNameResponseWhere>;
};

export type MutationUpdateUpdateUserModalOnboardingResponsesArgs = {
  update?: InputMaybe<UpdateUserModalOnboardingResponseUpdateInput>;
  where?: InputMaybe<UpdateUserModalOnboardingResponseWhere>;
};

export type MutationUpdateUploadDocumentsResponsesArgs = {
  update?: InputMaybe<UploadDocumentsResponseUpdateInput>;
  where?: InputMaybe<UploadDocumentsResponseWhere>;
};

export type MutationUpdateUploadDocumentsToBucketResponsesArgs = {
  update?: InputMaybe<UploadDocumentsToBucketResponseUpdateInput>;
  where?: InputMaybe<UploadDocumentsToBucketResponseWhere>;
};

export type MutationUpdateUserCareerLoadingResponsesArgs = {
  update?: InputMaybe<UserCareerLoadingResponseUpdateInput>;
  where?: InputMaybe<UserCareerLoadingResponseWhere>;
};

export type MutationUpdateUserCourseLoadingResponsesArgs = {
  update?: InputMaybe<UserCourseLoadingResponseUpdateInput>;
  where?: InputMaybe<UserCourseLoadingResponseWhere>;
};

export type MutationUpdateUserMasteriesArgs = {
  update?: InputMaybe<UserMasteryUpdateInput>;
  where?: InputMaybe<UserMasteryWhere>;
};

export type MutationUpdateUserModalOnboardingArgs = {
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
};

export type MutationUpdateUserProgressesArgs = {
  update?: InputMaybe<UserProgressUpdateInput>;
  where?: InputMaybe<UserProgressWhere>;
};

export type MutationUpdateUsersArgs = {
  update?: InputMaybe<UserUpdateInput>;
  where?: InputMaybe<UserWhere>;
};

export type MutationUpdateWaterIntakeSummariesArgs = {
  update?: InputMaybe<WaterIntakeSummaryUpdateInput>;
  where?: InputMaybe<WaterIntakeSummaryWhere>;
};

export type MutationUpdateWaterIntakesArgs = {
  update?: InputMaybe<WaterIntakeUpdateInput>;
  where?: InputMaybe<WaterIntakeWhere>;
};

export type MutationUpdateWeeklySummariesArgs = {
  update?: InputMaybe<WeeklySummaryUpdateInput>;
  where?: InputMaybe<WeeklySummaryWhere>;
};

export type MutationUpdateWhitelistedEmailsArgs = {
  update?: InputMaybe<WhitelistedEmailUpdateInput>;
  where?: InputMaybe<WhitelistedEmailWhere>;
};

export type MutationUpdateWorkoutsArgs = {
  update?: InputMaybe<WorkoutUpdateInput>;
  where?: InputMaybe<WorkoutWhere>;
};

export type MutationUpdateYearlySummariesArgs = {
  update?: InputMaybe<YearlySummaryUpdateInput>;
  where?: InputMaybe<YearlySummaryWhere>;
};

export type MutationUploadDocumentsArgs = {
  files: Array<Scalars["Upload"]["input"]>;
};

export type MutationUploadDocumentsToBucketArgs = {
  files: Array<Scalars["Upload"]["input"]>;
};

export type NodeIdentitiesConnection = {
  __typename?: "NodeIdentitiesConnection";
  edges: Array<NodeIdentityEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type NodeIdentity = {
  __typename?: "NodeIdentity";
  high: Scalars["Int"]["output"];
  low: Scalars["Int"]["output"];
};

export type NodeIdentityAggregateSelection = {
  __typename?: "NodeIdentityAggregateSelection";
  count: Scalars["Int"]["output"];
  high: IntAggregateSelection;
  low: IntAggregateSelection;
};

export type NodeIdentityCreateInput = {
  high: Scalars["Int"]["input"];
  low: Scalars["Int"]["input"];
};

export type NodeIdentityEdge = {
  __typename?: "NodeIdentityEdge";
  cursor: Scalars["String"]["output"];
  node: NodeIdentity;
};

export type NodeIdentityOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more NodeIdentitySort objects to sort NodeIdentities by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<NodeIdentitySort>>;
};

/** Fields to sort NodeIdentities by. The order in which sorts are applied is not guaranteed when specifying many fields in one NodeIdentitySort object. */
export type NodeIdentitySort = {
  high?: InputMaybe<SortDirection>;
  low?: InputMaybe<SortDirection>;
};

export type NodeIdentityUpdateInput = {
  high?: InputMaybe<Scalars["Int"]["input"]>;
  high_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  high_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  low?: InputMaybe<Scalars["Int"]["input"]>;
  low_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  low_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type NodeIdentityWhere = {
  AND?: InputMaybe<Array<NodeIdentityWhere>>;
  NOT?: InputMaybe<NodeIdentityWhere>;
  OR?: InputMaybe<Array<NodeIdentityWhere>>;
  high?: InputMaybe<Scalars["Int"]["input"]>;
  high_GT?: InputMaybe<Scalars["Int"]["input"]>;
  high_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  high_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  high_LT?: InputMaybe<Scalars["Int"]["input"]>;
  high_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  low?: InputMaybe<Scalars["Int"]["input"]>;
  low_GT?: InputMaybe<Scalars["Int"]["input"]>;
  low_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  low_IN?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  low_LT?: InputMaybe<Scalars["Int"]["input"]>;
  low_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export enum NodeLabel {
  Chapter = "Chapter",
  Field = "Field",
  Subchapter = "Subchapter",
  Subfield = "Subfield",
  Topic = "Topic",
}

export type NodeProperties = {
  __typename?: "NodeProperties";
  name: Scalars["String"]["output"];
};

export type NodePropertiesAggregateSelection = {
  __typename?: "NodePropertiesAggregateSelection";
  count: Scalars["Int"]["output"];
  name: StringAggregateSelection;
};

export type NodePropertiesConnection = {
  __typename?: "NodePropertiesConnection";
  edges: Array<NodePropertiesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type NodePropertiesCreateInput = {
  name: Scalars["String"]["input"];
};

export type NodePropertiesEdge = {
  __typename?: "NodePropertiesEdge";
  cursor: Scalars["String"]["output"];
  node: NodeProperties;
};

export type NodePropertiesOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more NodePropertiesSort objects to sort NodeProperties by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<NodePropertiesSort>>;
};

/** Fields to sort NodeProperties by. The order in which sorts are applied is not guaranteed when specifying many fields in one NodePropertiesSort object. */
export type NodePropertiesSort = {
  name?: InputMaybe<SortDirection>;
};

export type NodePropertiesUpdateInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type NodePropertiesWhere = {
  AND?: InputMaybe<Array<NodePropertiesWhere>>;
  NOT?: InputMaybe<NodePropertiesWhere>;
  OR?: InputMaybe<Array<NodePropertiesWhere>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type Nutrition = {
  __typename?: "Nutrition";
  date: Scalars["String"]["output"];
  foods?: Maybe<Array<Maybe<Food>>>;
  id: Scalars["ID"]["output"];
  water_intake_ml?: Maybe<Scalars["Int"]["output"]>;
};

export type NutritionAggregateSelection = {
  __typename?: "NutritionAggregateSelection";
  count: Scalars["Int"]["output"];
  date: StringAggregateSelection;
  id: IdAggregateSelection;
  water_intake_ml: IntAggregateSelection;
};

export type NutritionCreateInput = {
  date: Scalars["String"]["input"];
  id: Scalars["ID"]["input"];
  water_intake_ml?: InputMaybe<Scalars["Int"]["input"]>;
};

export type NutritionEdge = {
  __typename?: "NutritionEdge";
  cursor: Scalars["String"]["output"];
  node: Nutrition;
};

export type NutritionOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more NutritionSort objects to sort Nutritions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<NutritionSort>>;
};

/** Fields to sort Nutritions by. The order in which sorts are applied is not guaranteed when specifying many fields in one NutritionSort object. */
export type NutritionSort = {
  date?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  water_intake_ml?: InputMaybe<SortDirection>;
};

export type NutritionUpdateInput = {
  date?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  water_intake_ml?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_ml_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_ml_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type NutritionWhere = {
  AND?: InputMaybe<Array<NutritionWhere>>;
  NOT?: InputMaybe<NutritionWhere>;
  OR?: InputMaybe<Array<NutritionWhere>>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  date_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  water_intake_ml?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_ml_GT?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_ml_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_ml_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  water_intake_ml_LT?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_ml_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type NutritionsConnection = {
  __typename?: "NutritionsConnection";
  edges: Array<NutritionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Objective = {
  __typename?: "Objective";
  evalCriteria?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  objective?: Maybe<Scalars["String"]["output"]>;
  sequence?: Maybe<Scalars["Float"]["output"]>;
  userHasProgress: Array<User>;
  userHasProgressAggregate?: Maybe<ObjectiveUserUserHasProgressAggregationSelection>;
  userHasProgressConnection: ObjectiveUserHasProgressConnection;
};

export type ObjectiveUserHasProgressArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type ObjectiveUserHasProgressAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type ObjectiveUserHasProgressConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ObjectiveUserHasProgressConnectionSort>>;
  where?: InputMaybe<ObjectiveUserHasProgressConnectionWhere>;
};

export type ObjectiveAggregateSelection = {
  __typename?: "ObjectiveAggregateSelection";
  count: Scalars["Int"]["output"];
  evalCriteria: StringAggregateSelection;
  id: IdAggregateSelection;
  objective: StringAggregateSelection;
  sequence: FloatAggregateSelection;
};

export type ObjectiveConnectInput = {
  userHasProgress?: InputMaybe<Array<ObjectiveUserHasProgressConnectFieldInput>>;
};

export type ObjectiveConnectWhere = {
  node: ObjectiveWhere;
};

export type ObjectiveCreateInput = {
  evalCriteria?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  objective?: InputMaybe<Scalars["String"]["input"]>;
  sequence?: InputMaybe<Scalars["Float"]["input"]>;
  userHasProgress?: InputMaybe<ObjectiveUserHasProgressFieldInput>;
};

export type ObjectiveDeleteInput = {
  userHasProgress?: InputMaybe<Array<ObjectiveUserHasProgressDeleteFieldInput>>;
};

export type ObjectiveDisconnectInput = {
  userHasProgress?: InputMaybe<Array<ObjectiveUserHasProgressDisconnectFieldInput>>;
};

export type ObjectiveEdge = {
  __typename?: "ObjectiveEdge";
  cursor: Scalars["String"]["output"];
  node: Objective;
};

export type ObjectiveOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ObjectiveSort objects to sort Objectives by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ObjectiveSort>>;
};

export type ObjectiveRelationInput = {
  userHasProgress?: InputMaybe<Array<ObjectiveUserHasProgressCreateFieldInput>>;
};

/** Fields to sort Objectives by. The order in which sorts are applied is not guaranteed when specifying many fields in one ObjectiveSort object. */
export type ObjectiveSort = {
  evalCriteria?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  objective?: InputMaybe<SortDirection>;
  sequence?: InputMaybe<SortDirection>;
};

export type ObjectiveUpdateInput = {
  evalCriteria?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  objective?: InputMaybe<Scalars["String"]["input"]>;
  sequence?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  userHasProgress?: InputMaybe<Array<ObjectiveUserHasProgressUpdateFieldInput>>;
};

export type ObjectiveUserHasProgressAggregateInput = {
  AND?: InputMaybe<Array<ObjectiveUserHasProgressAggregateInput>>;
  NOT?: InputMaybe<ObjectiveUserHasProgressAggregateInput>;
  OR?: InputMaybe<Array<ObjectiveUserHasProgressAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasProgressPropertiesAggregationWhereInput>;
  node?: InputMaybe<ObjectiveUserHasProgressNodeAggregationWhereInput>;
};

export type ObjectiveUserHasProgressConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge?: InputMaybe<HasProgressPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type ObjectiveUserHasProgressConnection = {
  __typename?: "ObjectiveUserHasProgressConnection";
  edges: Array<ObjectiveUserHasProgressRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ObjectiveUserHasProgressConnectionSort = {
  edge?: InputMaybe<HasProgressPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type ObjectiveUserHasProgressConnectionWhere = {
  AND?: InputMaybe<Array<ObjectiveUserHasProgressConnectionWhere>>;
  NOT?: InputMaybe<ObjectiveUserHasProgressConnectionWhere>;
  OR?: InputMaybe<Array<ObjectiveUserHasProgressConnectionWhere>>;
  edge?: InputMaybe<HasProgressPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type ObjectiveUserHasProgressCreateFieldInput = {
  edge?: InputMaybe<HasProgressPropertiesCreateInput>;
  node: UserCreateInput;
};

export type ObjectiveUserHasProgressDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<ObjectiveUserHasProgressConnectionWhere>;
};

export type ObjectiveUserHasProgressDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<ObjectiveUserHasProgressConnectionWhere>;
};

export type ObjectiveUserHasProgressFieldInput = {
  connect?: InputMaybe<Array<ObjectiveUserHasProgressConnectFieldInput>>;
  create?: InputMaybe<Array<ObjectiveUserHasProgressCreateFieldInput>>;
};

export type ObjectiveUserHasProgressNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ObjectiveUserHasProgressNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ObjectiveUserHasProgressNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ObjectiveUserHasProgressNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ObjectiveUserHasProgressRelationship = {
  __typename?: "ObjectiveUserHasProgressRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasProgressProperties;
};

export type ObjectiveUserHasProgressUpdateConnectionInput = {
  edge?: InputMaybe<HasProgressPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type ObjectiveUserHasProgressUpdateFieldInput = {
  connect?: InputMaybe<Array<ObjectiveUserHasProgressConnectFieldInput>>;
  create?: InputMaybe<Array<ObjectiveUserHasProgressCreateFieldInput>>;
  delete?: InputMaybe<Array<ObjectiveUserHasProgressDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ObjectiveUserHasProgressDisconnectFieldInput>>;
  update?: InputMaybe<ObjectiveUserHasProgressUpdateConnectionInput>;
  where?: InputMaybe<ObjectiveUserHasProgressConnectionWhere>;
};

export type ObjectiveUserUserHasProgressAggregationSelection = {
  __typename?: "ObjectiveUserUserHasProgressAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<ObjectiveUserUserHasProgressEdgeAggregateSelection>;
  node?: Maybe<ObjectiveUserUserHasProgressNodeAggregateSelection>;
};

export type ObjectiveUserUserHasProgressEdgeAggregateSelection = {
  __typename?: "ObjectiveUserUserHasProgressEdgeAggregateSelection";
  status: StringAggregateSelection;
};

export type ObjectiveUserUserHasProgressNodeAggregateSelection = {
  __typename?: "ObjectiveUserUserHasProgressNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type ObjectiveWhere = {
  AND?: InputMaybe<Array<ObjectiveWhere>>;
  NOT?: InputMaybe<ObjectiveWhere>;
  OR?: InputMaybe<Array<ObjectiveWhere>>;
  evalCriteria?: InputMaybe<Scalars["String"]["input"]>;
  evalCriteria_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  evalCriteria_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  evalCriteria_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  evalCriteria_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  objective?: InputMaybe<Scalars["String"]["input"]>;
  objective_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  objective_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  objective_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  objective_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sequence?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  sequence_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sequence_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  userHasProgressAggregate?: InputMaybe<ObjectiveUserHasProgressAggregateInput>;
  /** Return Objectives where all of the related ObjectiveUserHasProgressConnections match this filter */
  userHasProgressConnection_ALL?: InputMaybe<ObjectiveUserHasProgressConnectionWhere>;
  /** Return Objectives where none of the related ObjectiveUserHasProgressConnections match this filter */
  userHasProgressConnection_NONE?: InputMaybe<ObjectiveUserHasProgressConnectionWhere>;
  /** Return Objectives where one of the related ObjectiveUserHasProgressConnections match this filter */
  userHasProgressConnection_SINGLE?: InputMaybe<ObjectiveUserHasProgressConnectionWhere>;
  /** Return Objectives where some of the related ObjectiveUserHasProgressConnections match this filter */
  userHasProgressConnection_SOME?: InputMaybe<ObjectiveUserHasProgressConnectionWhere>;
  /** Return Objectives where all of the related Users match this filter */
  userHasProgress_ALL?: InputMaybe<UserWhere>;
  /** Return Objectives where none of the related Users match this filter */
  userHasProgress_NONE?: InputMaybe<UserWhere>;
  /** Return Objectives where one of the related Users match this filter */
  userHasProgress_SINGLE?: InputMaybe<UserWhere>;
  /** Return Objectives where some of the related Users match this filter */
  userHasProgress_SOME?: InputMaybe<UserWhere>;
};

export type ObjectivesConnection = {
  __typename?: "ObjectivesConnection";
  edges: Array<ObjectiveEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Page = {
  __typename?: "Page";
  _empty?: Maybe<Scalars["String"]["output"]>;
  assignee_mode?: Maybe<Scalars["String"]["output"]>;
  body?: Maybe<Scalars["String"]["output"]>;
  coursesHasPage: Array<Course>;
  coursesHasPageAggregate?: Maybe<PageCourseCoursesHasPageAggregationSelection>;
  coursesHasPageConnection: PageCoursesHasPageConnection;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  created_at?: Maybe<Scalars["String"]["output"]>;
  created_date?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  editing_roles?: Maybe<Scalars["String"]["output"]>;
  embedding?: Maybe<Array<Maybe<Scalars["Float"]["output"]>>>;
  front_page?: Maybe<Scalars["Boolean"]["output"]>;
  hide_from_students?: Maybe<Scalars["Boolean"]["output"]>;
  html_url?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  lastModified?: Maybe<Scalars["DateTime"]["output"]>;
  last_edited_by_anonymous_id?: Maybe<Scalars["String"]["output"]>;
  last_edited_by_avatar_image_url?: Maybe<Scalars["String"]["output"]>;
  last_edited_by_display_name?: Maybe<Scalars["String"]["output"]>;
  last_edited_by_html_url?: Maybe<Scalars["String"]["output"]>;
  last_edited_by_id?: Maybe<Scalars["Float"]["output"]>;
  locked_for_user?: Maybe<Scalars["Boolean"]["output"]>;
  page_id?: Maybe<Scalars["Float"]["output"]>;
  published?: Maybe<Scalars["Boolean"]["output"]>;
  resourceHasPages: Array<Resource>;
  resourceHasPagesAggregate?: Maybe<PageResourceResourceHasPagesAggregationSelection>;
  resourceHasPagesConnection: PageResourceHasPagesConnection;
  state?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
  title: Scalars["String"]["output"];
  updated_at?: Maybe<Scalars["String"]["output"]>;
  updated_date?: Maybe<Scalars["String"]["output"]>;
  url?: Maybe<Scalars["String"]["output"]>;
  usersHasSchoolPage: Array<User>;
  usersHasSchoolPageAggregate?: Maybe<PageUserUsersHasSchoolPageAggregationSelection>;
  usersHasSchoolPageConnection: PageUsersHasSchoolPageConnection;
};

export type PageCoursesHasPageArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<CourseOptions>;
  where?: InputMaybe<CourseWhere>;
};

export type PageCoursesHasPageAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<CourseWhere>;
};

export type PageCoursesHasPageConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<PageCoursesHasPageConnectionSort>>;
  where?: InputMaybe<PageCoursesHasPageConnectionWhere>;
};

export type PageResourceHasPagesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ResourceOptions>;
  where?: InputMaybe<ResourceWhere>;
};

export type PageResourceHasPagesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ResourceWhere>;
};

export type PageResourceHasPagesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<PageResourceHasPagesConnectionSort>>;
  where?: InputMaybe<PageResourceHasPagesConnectionWhere>;
};

export type PageUsersHasSchoolPageArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type PageUsersHasSchoolPageAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type PageUsersHasSchoolPageConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<PageUsersHasSchoolPageConnectionSort>>;
  where?: InputMaybe<PageUsersHasSchoolPageConnectionWhere>;
};

export type PageAggregateSelection = {
  __typename?: "PageAggregateSelection";
  _empty: StringAggregateSelection;
  assignee_mode: StringAggregateSelection;
  body: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  description: StringAggregateSelection;
  editing_roles: StringAggregateSelection;
  html_url: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  last_edited_by_anonymous_id: StringAggregateSelection;
  last_edited_by_avatar_image_url: StringAggregateSelection;
  last_edited_by_display_name: StringAggregateSelection;
  last_edited_by_html_url: StringAggregateSelection;
  last_edited_by_id: FloatAggregateSelection;
  page_id: FloatAggregateSelection;
  state: StringAggregateSelection;
  text: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_at: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type PageConnectInput = {
  coursesHasPage?: InputMaybe<Array<PageCoursesHasPageConnectFieldInput>>;
  resourceHasPages?: InputMaybe<Array<PageResourceHasPagesConnectFieldInput>>;
  usersHasSchoolPage?: InputMaybe<Array<PageUsersHasSchoolPageConnectFieldInput>>;
};

export type PageConnectOrCreateInput = {
  coursesHasPage?: InputMaybe<Array<PageCoursesHasPageConnectOrCreateFieldInput>>;
  resourceHasPages?: InputMaybe<Array<PageResourceHasPagesConnectOrCreateFieldInput>>;
};

export type PageConnectOrCreateWhere = {
  node: PageUniqueWhere;
};

export type PageConnectWhere = {
  node: PageWhere;
};

export type PageCourseCoursesHasPageAggregationSelection = {
  __typename?: "PageCourseCoursesHasPageAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<PageCourseCoursesHasPageNodeAggregateSelection>;
};

export type PageCourseCoursesHasPageNodeAggregateSelection = {
  __typename?: "PageCourseCoursesHasPageNodeAggregateSelection";
  account_id: FloatAggregateSelection;
  calendar_ics: StringAggregateSelection;
  courseId: StringAggregateSelection;
  course_code: StringAggregateSelection;
  course_id: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  default_view: StringAggregateSelection;
  description: StringAggregateSelection;
  end_at: StringAggregateSelection;
  enrollment_term_id: FloatAggregateSelection;
  grading_standard_id: FloatAggregateSelection;
  id: IdAggregateSelection;
  integration_id: StringAggregateSelection;
  label: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  learningStandards: StringAggregateSelection;
  lengthInWeeks: FloatAggregateSelection;
  license: StringAggregateSelection;
  lti_id: StringAggregateSelection;
  name: StringAggregateSelection;
  original_name: StringAggregateSelection;
  root_account_id: FloatAggregateSelection;
  sis_course_id: StringAggregateSelection;
  sis_import_id: FloatAggregateSelection;
  start_at: StringAggregateSelection;
  state: StringAggregateSelection;
  storage_quota_mb: FloatAggregateSelection;
  time_zone: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  uuid: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type PageCoursesHasPageAggregateInput = {
  AND?: InputMaybe<Array<PageCoursesHasPageAggregateInput>>;
  NOT?: InputMaybe<PageCoursesHasPageAggregateInput>;
  OR?: InputMaybe<Array<PageCoursesHasPageAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<PageCoursesHasPageNodeAggregationWhereInput>;
};

export type PageCoursesHasPageConnectFieldInput = {
  connect?: InputMaybe<Array<CourseConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<CourseConnectWhere>;
};

export type PageCoursesHasPageConnectOrCreateFieldInput = {
  onCreate: PageCoursesHasPageConnectOrCreateFieldInputOnCreate;
  where: CourseConnectOrCreateWhere;
};

export type PageCoursesHasPageConnectOrCreateFieldInputOnCreate = {
  node: CourseOnCreateInput;
};

export type PageCoursesHasPageConnection = {
  __typename?: "PageCoursesHasPageConnection";
  edges: Array<PageCoursesHasPageRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PageCoursesHasPageConnectionSort = {
  node?: InputMaybe<CourseSort>;
};

export type PageCoursesHasPageConnectionWhere = {
  AND?: InputMaybe<Array<PageCoursesHasPageConnectionWhere>>;
  NOT?: InputMaybe<PageCoursesHasPageConnectionWhere>;
  OR?: InputMaybe<Array<PageCoursesHasPageConnectionWhere>>;
  node?: InputMaybe<CourseWhere>;
};

export type PageCoursesHasPageCreateFieldInput = {
  node: CourseCreateInput;
};

export type PageCoursesHasPageDeleteFieldInput = {
  delete?: InputMaybe<CourseDeleteInput>;
  where?: InputMaybe<PageCoursesHasPageConnectionWhere>;
};

export type PageCoursesHasPageDisconnectFieldInput = {
  disconnect?: InputMaybe<CourseDisconnectInput>;
  where?: InputMaybe<PageCoursesHasPageConnectionWhere>;
};

export type PageCoursesHasPageFieldInput = {
  connect?: InputMaybe<Array<PageCoursesHasPageConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<PageCoursesHasPageConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<PageCoursesHasPageCreateFieldInput>>;
};

export type PageCoursesHasPageNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PageCoursesHasPageNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PageCoursesHasPageNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PageCoursesHasPageNodeAggregationWhereInput>>;
  account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calendar_ics_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  calendar_ics_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  courseId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  courseId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_code_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_code_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  default_view_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  default_view_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  enrollment_term_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  enrollment_term_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_standard_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integration_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integration_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  label_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  label_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  label_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  learningStandards_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStandards_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStandards_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lengthInWeeks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lengthInWeeks_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  license_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  license_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  license_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  original_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  original_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  root_account_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  root_account_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_course_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  sis_course_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sis_import_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sis_import_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  storage_quota_mb_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  storage_quota_mb_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  time_zone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  time_zone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  uuid_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  uuid_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PageCoursesHasPageRelationship = {
  __typename?: "PageCoursesHasPageRelationship";
  cursor: Scalars["String"]["output"];
  node: Course;
};

export type PageCoursesHasPageUpdateConnectionInput = {
  node?: InputMaybe<CourseUpdateInput>;
};

export type PageCoursesHasPageUpdateFieldInput = {
  connect?: InputMaybe<Array<PageCoursesHasPageConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<PageCoursesHasPageConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<PageCoursesHasPageCreateFieldInput>>;
  delete?: InputMaybe<Array<PageCoursesHasPageDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<PageCoursesHasPageDisconnectFieldInput>>;
  update?: InputMaybe<PageCoursesHasPageUpdateConnectionInput>;
  where?: InputMaybe<PageCoursesHasPageConnectionWhere>;
};

export type PageCreateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode?: InputMaybe<Scalars["String"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  coursesHasPage?: InputMaybe<PageCoursesHasPageFieldInput>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  editing_roles?: InputMaybe<Scalars["String"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  front_page?: InputMaybe<Scalars["Boolean"]["input"]>;
  hide_from_students?: InputMaybe<Scalars["Boolean"]["input"]>;
  html_url?: InputMaybe<Scalars["String"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_edited_by_anonymous_id?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_avatar_image_url?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_display_name?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_html_url?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_id?: InputMaybe<Scalars["Float"]["input"]>;
  locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  page_id?: InputMaybe<Scalars["Float"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  resourceHasPages?: InputMaybe<PageResourceHasPagesFieldInput>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
  updated_at?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSchoolPage?: InputMaybe<PageUsersHasSchoolPageFieldInput>;
};

export type PageDeleteInput = {
  coursesHasPage?: InputMaybe<Array<PageCoursesHasPageDeleteFieldInput>>;
  resourceHasPages?: InputMaybe<Array<PageResourceHasPagesDeleteFieldInput>>;
  usersHasSchoolPage?: InputMaybe<Array<PageUsersHasSchoolPageDeleteFieldInput>>;
};

export type PageDisconnectInput = {
  coursesHasPage?: InputMaybe<Array<PageCoursesHasPageDisconnectFieldInput>>;
  resourceHasPages?: InputMaybe<Array<PageResourceHasPagesDisconnectFieldInput>>;
  usersHasSchoolPage?: InputMaybe<Array<PageUsersHasSchoolPageDisconnectFieldInput>>;
};

export type PageEdge = {
  __typename?: "PageEdge";
  cursor: Scalars["String"]["output"];
  node: Page;
};

/** Pagination information (Relay) */
export type PageInfo = {
  __typename?: "PageInfo";
  endCursor?: Maybe<Scalars["String"]["output"]>;
  hasNextPage: Scalars["Boolean"]["output"];
  hasPreviousPage: Scalars["Boolean"]["output"];
  startCursor?: Maybe<Scalars["String"]["output"]>;
};

export type PageOnCreateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode?: InputMaybe<Scalars["String"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  editing_roles?: InputMaybe<Scalars["String"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  front_page?: InputMaybe<Scalars["Boolean"]["input"]>;
  hide_from_students?: InputMaybe<Scalars["Boolean"]["input"]>;
  html_url?: InputMaybe<Scalars["String"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_edited_by_anonymous_id?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_avatar_image_url?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_display_name?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_html_url?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_id?: InputMaybe<Scalars["Float"]["input"]>;
  locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  page_id?: InputMaybe<Scalars["Float"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  title: Scalars["String"]["input"];
  updated_at?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
};

export type PageOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more PageSort objects to sort Pages by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<PageSort>>;
};

export type PageRelationInput = {
  coursesHasPage?: InputMaybe<Array<PageCoursesHasPageCreateFieldInput>>;
  resourceHasPages?: InputMaybe<Array<PageResourceHasPagesCreateFieldInput>>;
  usersHasSchoolPage?: InputMaybe<Array<PageUsersHasSchoolPageCreateFieldInput>>;
};

export type PageResourceHasPagesAggregateInput = {
  AND?: InputMaybe<Array<PageResourceHasPagesAggregateInput>>;
  NOT?: InputMaybe<PageResourceHasPagesAggregateInput>;
  OR?: InputMaybe<Array<PageResourceHasPagesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<PageResourceHasPagesNodeAggregationWhereInput>;
};

export type PageResourceHasPagesConnectFieldInput = {
  connect?: InputMaybe<Array<ResourceConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ResourceConnectWhere>;
};

export type PageResourceHasPagesConnectOrCreateFieldInput = {
  onCreate: PageResourceHasPagesConnectOrCreateFieldInputOnCreate;
  where: ResourceConnectOrCreateWhere;
};

export type PageResourceHasPagesConnectOrCreateFieldInputOnCreate = {
  node: ResourceOnCreateInput;
};

export type PageResourceHasPagesConnection = {
  __typename?: "PageResourceHasPagesConnection";
  edges: Array<PageResourceHasPagesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PageResourceHasPagesConnectionSort = {
  node?: InputMaybe<ResourceSort>;
};

export type PageResourceHasPagesConnectionWhere = {
  AND?: InputMaybe<Array<PageResourceHasPagesConnectionWhere>>;
  NOT?: InputMaybe<PageResourceHasPagesConnectionWhere>;
  OR?: InputMaybe<Array<PageResourceHasPagesConnectionWhere>>;
  node?: InputMaybe<ResourceWhere>;
};

export type PageResourceHasPagesCreateFieldInput = {
  node: ResourceCreateInput;
};

export type PageResourceHasPagesDeleteFieldInput = {
  delete?: InputMaybe<ResourceDeleteInput>;
  where?: InputMaybe<PageResourceHasPagesConnectionWhere>;
};

export type PageResourceHasPagesDisconnectFieldInput = {
  disconnect?: InputMaybe<ResourceDisconnectInput>;
  where?: InputMaybe<PageResourceHasPagesConnectionWhere>;
};

export type PageResourceHasPagesFieldInput = {
  connect?: InputMaybe<Array<PageResourceHasPagesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<PageResourceHasPagesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<PageResourceHasPagesCreateFieldInput>>;
};

export type PageResourceHasPagesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PageResourceHasPagesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PageResourceHasPagesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PageResourceHasPagesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type PageResourceHasPagesRelationship = {
  __typename?: "PageResourceHasPagesRelationship";
  cursor: Scalars["String"]["output"];
  node: Resource;
};

export type PageResourceHasPagesUpdateConnectionInput = {
  node?: InputMaybe<ResourceUpdateInput>;
};

export type PageResourceHasPagesUpdateFieldInput = {
  connect?: InputMaybe<Array<PageResourceHasPagesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<PageResourceHasPagesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<PageResourceHasPagesCreateFieldInput>>;
  delete?: InputMaybe<Array<PageResourceHasPagesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<PageResourceHasPagesDisconnectFieldInput>>;
  update?: InputMaybe<PageResourceHasPagesUpdateConnectionInput>;
  where?: InputMaybe<PageResourceHasPagesConnectionWhere>;
};

export type PageResourceResourceHasPagesAggregationSelection = {
  __typename?: "PageResourceResourceHasPagesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<PageResourceResourceHasPagesNodeAggregateSelection>;
};

export type PageResourceResourceHasPagesNodeAggregateSelection = {
  __typename?: "PageResourceResourceHasPagesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
};

export type PageResponse = {
  __typename?: "PageResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<Page>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type PageResponseAggregateSelection = {
  __typename?: "PageResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type PageResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PageResponseEdge = {
  __typename?: "PageResponseEdge";
  cursor: Scalars["String"]["output"];
  node: PageResponse;
};

export type PageResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more PageResponseSort objects to sort PageResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<PageResponseSort>>;
};

/** Fields to sort PageResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one PageResponseSort object. */
export type PageResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type PageResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PageResponseWhere = {
  AND?: InputMaybe<Array<PageResponseWhere>>;
  NOT?: InputMaybe<PageResponseWhere>;
  OR?: InputMaybe<Array<PageResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type PageResponsesConnection = {
  __typename?: "PageResponsesConnection";
  edges: Array<PageResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort Pages by. The order in which sorts are applied is not guaranteed when specifying many fields in one PageSort object. */
export type PageSort = {
  _empty?: InputMaybe<SortDirection>;
  assignee_mode?: InputMaybe<SortDirection>;
  body?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  created_at?: InputMaybe<SortDirection>;
  created_date?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  editing_roles?: InputMaybe<SortDirection>;
  front_page?: InputMaybe<SortDirection>;
  hide_from_students?: InputMaybe<SortDirection>;
  html_url?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
  last_edited_by_anonymous_id?: InputMaybe<SortDirection>;
  last_edited_by_avatar_image_url?: InputMaybe<SortDirection>;
  last_edited_by_display_name?: InputMaybe<SortDirection>;
  last_edited_by_html_url?: InputMaybe<SortDirection>;
  last_edited_by_id?: InputMaybe<SortDirection>;
  locked_for_user?: InputMaybe<SortDirection>;
  page_id?: InputMaybe<SortDirection>;
  published?: InputMaybe<SortDirection>;
  state?: InputMaybe<SortDirection>;
  text?: InputMaybe<SortDirection>;
  title?: InputMaybe<SortDirection>;
  updated_at?: InputMaybe<SortDirection>;
  updated_date?: InputMaybe<SortDirection>;
  url?: InputMaybe<SortDirection>;
};

export type PageUniqueWhere = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type PageUpdateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode?: InputMaybe<Scalars["String"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  coursesHasPage?: InputMaybe<Array<PageCoursesHasPageUpdateFieldInput>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  editing_roles?: InputMaybe<Scalars["String"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  embedding_POP?: InputMaybe<Scalars["Int"]["input"]>;
  embedding_PUSH?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  front_page?: InputMaybe<Scalars["Boolean"]["input"]>;
  hide_from_students?: InputMaybe<Scalars["Boolean"]["input"]>;
  html_url?: InputMaybe<Scalars["String"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_edited_by_anonymous_id?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_avatar_image_url?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_display_name?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_html_url?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_id?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  page_id?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  resourceHasPages?: InputMaybe<Array<PageResourceHasPagesUpdateFieldInput>>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSchoolPage?: InputMaybe<Array<PageUsersHasSchoolPageUpdateFieldInput>>;
};

export type PageUserUsersHasSchoolPageAggregationSelection = {
  __typename?: "PageUserUsersHasSchoolPageAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<PageUserUsersHasSchoolPageNodeAggregateSelection>;
};

export type PageUserUsersHasSchoolPageNodeAggregateSelection = {
  __typename?: "PageUserUsersHasSchoolPageNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type PageUsersHasSchoolPageAggregateInput = {
  AND?: InputMaybe<Array<PageUsersHasSchoolPageAggregateInput>>;
  NOT?: InputMaybe<PageUsersHasSchoolPageAggregateInput>;
  OR?: InputMaybe<Array<PageUsersHasSchoolPageAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<PageUsersHasSchoolPageNodeAggregationWhereInput>;
};

export type PageUsersHasSchoolPageConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type PageUsersHasSchoolPageConnection = {
  __typename?: "PageUsersHasSchoolPageConnection";
  edges: Array<PageUsersHasSchoolPageRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PageUsersHasSchoolPageConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type PageUsersHasSchoolPageConnectionWhere = {
  AND?: InputMaybe<Array<PageUsersHasSchoolPageConnectionWhere>>;
  NOT?: InputMaybe<PageUsersHasSchoolPageConnectionWhere>;
  OR?: InputMaybe<Array<PageUsersHasSchoolPageConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type PageUsersHasSchoolPageCreateFieldInput = {
  node: UserCreateInput;
};

export type PageUsersHasSchoolPageDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<PageUsersHasSchoolPageConnectionWhere>;
};

export type PageUsersHasSchoolPageDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<PageUsersHasSchoolPageConnectionWhere>;
};

export type PageUsersHasSchoolPageFieldInput = {
  connect?: InputMaybe<Array<PageUsersHasSchoolPageConnectFieldInput>>;
  create?: InputMaybe<Array<PageUsersHasSchoolPageCreateFieldInput>>;
};

export type PageUsersHasSchoolPageNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PageUsersHasSchoolPageNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PageUsersHasSchoolPageNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PageUsersHasSchoolPageNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PageUsersHasSchoolPageRelationship = {
  __typename?: "PageUsersHasSchoolPageRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type PageUsersHasSchoolPageUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type PageUsersHasSchoolPageUpdateFieldInput = {
  connect?: InputMaybe<Array<PageUsersHasSchoolPageConnectFieldInput>>;
  create?: InputMaybe<Array<PageUsersHasSchoolPageCreateFieldInput>>;
  delete?: InputMaybe<Array<PageUsersHasSchoolPageDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<PageUsersHasSchoolPageDisconnectFieldInput>>;
  update?: InputMaybe<PageUsersHasSchoolPageUpdateConnectionInput>;
  where?: InputMaybe<PageUsersHasSchoolPageConnectionWhere>;
};

export type PageWhere = {
  AND?: InputMaybe<Array<PageWhere>>;
  NOT?: InputMaybe<PageWhere>;
  OR?: InputMaybe<Array<PageWhere>>;
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  _empty_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  _empty_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  _empty_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  _empty_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  assignee_mode_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignee_mode_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
  body_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  body_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  body_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  body_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  coursesHasPageAggregate?: InputMaybe<PageCoursesHasPageAggregateInput>;
  /** Return Pages where all of the related PageCoursesHasPageConnections match this filter */
  coursesHasPageConnection_ALL?: InputMaybe<PageCoursesHasPageConnectionWhere>;
  /** Return Pages where none of the related PageCoursesHasPageConnections match this filter */
  coursesHasPageConnection_NONE?: InputMaybe<PageCoursesHasPageConnectionWhere>;
  /** Return Pages where one of the related PageCoursesHasPageConnections match this filter */
  coursesHasPageConnection_SINGLE?: InputMaybe<PageCoursesHasPageConnectionWhere>;
  /** Return Pages where some of the related PageCoursesHasPageConnections match this filter */
  coursesHasPageConnection_SOME?: InputMaybe<PageCoursesHasPageConnectionWhere>;
  /** Return Pages where all of the related Courses match this filter */
  coursesHasPage_ALL?: InputMaybe<CourseWhere>;
  /** Return Pages where none of the related Courses match this filter */
  coursesHasPage_NONE?: InputMaybe<CourseWhere>;
  /** Return Pages where one of the related Courses match this filter */
  coursesHasPage_SINGLE?: InputMaybe<CourseWhere>;
  /** Return Pages where some of the related Courses match this filter */
  coursesHasPage_SOME?: InputMaybe<CourseWhere>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at?: InputMaybe<Scalars["String"]["input"]>;
  created_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  created_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  created_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_date?: InputMaybe<Scalars["String"]["input"]>;
  created_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  created_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  created_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  editing_roles?: InputMaybe<Scalars["String"]["input"]>;
  editing_roles_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  editing_roles_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  editing_roles_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  editing_roles_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  embedding?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  embedding_INCLUDES?: InputMaybe<Scalars["Float"]["input"]>;
  front_page?: InputMaybe<Scalars["Boolean"]["input"]>;
  hide_from_students?: InputMaybe<Scalars["Boolean"]["input"]>;
  html_url?: InputMaybe<Scalars["String"]["input"]>;
  html_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  html_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  html_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  html_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastModified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_edited_by_anonymous_id?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_anonymous_id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_anonymous_id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_anonymous_id_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  last_edited_by_anonymous_id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_avatar_image_url?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_avatar_image_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_avatar_image_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_avatar_image_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  last_edited_by_avatar_image_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_display_name?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_display_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_display_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_display_name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  last_edited_by_display_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_html_url?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_html_url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_html_url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_html_url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  last_edited_by_html_url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_edited_by_id?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  last_edited_by_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  locked_for_user?: InputMaybe<Scalars["Boolean"]["input"]>;
  page_id?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_GT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  page_id_LT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  published?: InputMaybe<Scalars["Boolean"]["input"]>;
  resourceHasPagesAggregate?: InputMaybe<PageResourceHasPagesAggregateInput>;
  /** Return Pages where all of the related PageResourceHasPagesConnections match this filter */
  resourceHasPagesConnection_ALL?: InputMaybe<PageResourceHasPagesConnectionWhere>;
  /** Return Pages where none of the related PageResourceHasPagesConnections match this filter */
  resourceHasPagesConnection_NONE?: InputMaybe<PageResourceHasPagesConnectionWhere>;
  /** Return Pages where one of the related PageResourceHasPagesConnections match this filter */
  resourceHasPagesConnection_SINGLE?: InputMaybe<PageResourceHasPagesConnectionWhere>;
  /** Return Pages where some of the related PageResourceHasPagesConnections match this filter */
  resourceHasPagesConnection_SOME?: InputMaybe<PageResourceHasPagesConnectionWhere>;
  /** Return Pages where all of the related Resources match this filter */
  resourceHasPages_ALL?: InputMaybe<ResourceWhere>;
  /** Return Pages where none of the related Resources match this filter */
  resourceHasPages_NONE?: InputMaybe<ResourceWhere>;
  /** Return Pages where one of the related Resources match this filter */
  resourceHasPages_SINGLE?: InputMaybe<ResourceWhere>;
  /** Return Pages where some of the related Resources match this filter */
  resourceHasPages_SOME?: InputMaybe<ResourceWhere>;
  state?: InputMaybe<Scalars["String"]["input"]>;
  state_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  state_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  state_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  state_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  text_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  text_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  text_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
  title_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  title_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  title_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updated_at?: InputMaybe<Scalars["String"]["input"]>;
  updated_at_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  updated_at_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updated_at_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  updated_at_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updated_date?: InputMaybe<Scalars["String"]["input"]>;
  updated_date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  updated_date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  updated_date_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  updated_date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url?: InputMaybe<Scalars["String"]["input"]>;
  url_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  url_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  url_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  url_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSchoolPageAggregate?: InputMaybe<PageUsersHasSchoolPageAggregateInput>;
  /** Return Pages where all of the related PageUsersHasSchoolPageConnections match this filter */
  usersHasSchoolPageConnection_ALL?: InputMaybe<PageUsersHasSchoolPageConnectionWhere>;
  /** Return Pages where none of the related PageUsersHasSchoolPageConnections match this filter */
  usersHasSchoolPageConnection_NONE?: InputMaybe<PageUsersHasSchoolPageConnectionWhere>;
  /** Return Pages where one of the related PageUsersHasSchoolPageConnections match this filter */
  usersHasSchoolPageConnection_SINGLE?: InputMaybe<PageUsersHasSchoolPageConnectionWhere>;
  /** Return Pages where some of the related PageUsersHasSchoolPageConnections match this filter */
  usersHasSchoolPageConnection_SOME?: InputMaybe<PageUsersHasSchoolPageConnectionWhere>;
  /** Return Pages where all of the related Users match this filter */
  usersHasSchoolPage_ALL?: InputMaybe<UserWhere>;
  /** Return Pages where none of the related Users match this filter */
  usersHasSchoolPage_NONE?: InputMaybe<UserWhere>;
  /** Return Pages where one of the related Users match this filter */
  usersHasSchoolPage_SINGLE?: InputMaybe<UserWhere>;
  /** Return Pages where some of the related Users match this filter */
  usersHasSchoolPage_SOME?: InputMaybe<UserWhere>;
};

export type PagesConnection = {
  __typename?: "PagesConnection";
  edges: Array<PageEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ParametersType = {
  __typename?: "ParametersType";
  lastGeneratedLabel?: Maybe<Scalars["String"]["output"]>;
  lastGeneratedTopic?: Maybe<Scalars["String"]["output"]>;
  questContext?: Maybe<Scalars["String"]["output"]>;
  userResponse?: Maybe<Scalars["String"]["output"]>;
};

export type ParametersTypeAggregateSelection = {
  __typename?: "ParametersTypeAggregateSelection";
  count: Scalars["Int"]["output"];
  lastGeneratedLabel: StringAggregateSelection;
  lastGeneratedTopic: StringAggregateSelection;
  questContext: StringAggregateSelection;
  userResponse: StringAggregateSelection;
};

export type ParametersTypeCreateInput = {
  lastGeneratedLabel?: InputMaybe<Scalars["String"]["input"]>;
  lastGeneratedTopic?: InputMaybe<Scalars["String"]["input"]>;
  questContext?: InputMaybe<Scalars["String"]["input"]>;
  userResponse?: InputMaybe<Scalars["String"]["input"]>;
};

export type ParametersTypeEdge = {
  __typename?: "ParametersTypeEdge";
  cursor: Scalars["String"]["output"];
  node: ParametersType;
};

export type ParametersTypeOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ParametersTypeSort objects to sort ParametersTypes by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ParametersTypeSort>>;
};

/** Fields to sort ParametersTypes by. The order in which sorts are applied is not guaranteed when specifying many fields in one ParametersTypeSort object. */
export type ParametersTypeSort = {
  lastGeneratedLabel?: InputMaybe<SortDirection>;
  lastGeneratedTopic?: InputMaybe<SortDirection>;
  questContext?: InputMaybe<SortDirection>;
  userResponse?: InputMaybe<SortDirection>;
};

export type ParametersTypeUpdateInput = {
  lastGeneratedLabel?: InputMaybe<Scalars["String"]["input"]>;
  lastGeneratedTopic?: InputMaybe<Scalars["String"]["input"]>;
  questContext?: InputMaybe<Scalars["String"]["input"]>;
  userResponse?: InputMaybe<Scalars["String"]["input"]>;
};

export type ParametersTypeWhere = {
  AND?: InputMaybe<Array<ParametersTypeWhere>>;
  NOT?: InputMaybe<ParametersTypeWhere>;
  OR?: InputMaybe<Array<ParametersTypeWhere>>;
  lastGeneratedLabel?: InputMaybe<Scalars["String"]["input"]>;
  lastGeneratedLabel_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  lastGeneratedLabel_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lastGeneratedLabel_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  lastGeneratedLabel_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lastGeneratedTopic?: InputMaybe<Scalars["String"]["input"]>;
  lastGeneratedTopic_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  lastGeneratedTopic_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lastGeneratedTopic_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  lastGeneratedTopic_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questContext?: InputMaybe<Scalars["String"]["input"]>;
  questContext_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  questContext_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  questContext_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  questContext_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  userResponse?: InputMaybe<Scalars["String"]["input"]>;
  userResponse_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  userResponse_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  userResponse_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  userResponse_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ParametersTypesConnection = {
  __typename?: "ParametersTypesConnection";
  edges: Array<ParametersTypeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Payment = {
  __typename?: "Payment";
  domain: Scalars["String"]["output"];
  usersHasPayment: Array<User>;
  usersHasPaymentAggregate?: Maybe<PaymentUserUsersHasPaymentAggregationSelection>;
  usersHasPaymentConnection: PaymentUsersHasPaymentConnection;
};

export type PaymentUsersHasPaymentArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type PaymentUsersHasPaymentAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type PaymentUsersHasPaymentConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<PaymentUsersHasPaymentConnectionSort>>;
  where?: InputMaybe<PaymentUsersHasPaymentConnectionWhere>;
};

export type PaymentAggregateSelection = {
  __typename?: "PaymentAggregateSelection";
  count: Scalars["Int"]["output"];
  domain: StringAggregateSelection;
};

export type PaymentConnectInput = {
  usersHasPayment?: InputMaybe<Array<PaymentUsersHasPaymentConnectFieldInput>>;
};

export type PaymentConnectWhere = {
  node: PaymentWhere;
};

export type PaymentCreateInput = {
  domain: Scalars["String"]["input"];
  usersHasPayment?: InputMaybe<PaymentUsersHasPaymentFieldInput>;
};

export type PaymentDeleteInput = {
  usersHasPayment?: InputMaybe<Array<PaymentUsersHasPaymentDeleteFieldInput>>;
};

export type PaymentDisconnectInput = {
  usersHasPayment?: InputMaybe<Array<PaymentUsersHasPaymentDisconnectFieldInput>>;
};

export type PaymentEdge = {
  __typename?: "PaymentEdge";
  cursor: Scalars["String"]["output"];
  node: Payment;
};

export type PaymentOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more PaymentSort objects to sort Payments by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<PaymentSort>>;
};

export type PaymentRelationInput = {
  usersHasPayment?: InputMaybe<Array<PaymentUsersHasPaymentCreateFieldInput>>;
};

/** Fields to sort Payments by. The order in which sorts are applied is not guaranteed when specifying many fields in one PaymentSort object. */
export type PaymentSort = {
  domain?: InputMaybe<SortDirection>;
};

export type PaymentUpdateInput = {
  domain?: InputMaybe<Scalars["String"]["input"]>;
  usersHasPayment?: InputMaybe<Array<PaymentUsersHasPaymentUpdateFieldInput>>;
};

export type PaymentUserUsersHasPaymentAggregationSelection = {
  __typename?: "PaymentUserUsersHasPaymentAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<PaymentUserUsersHasPaymentEdgeAggregateSelection>;
  node?: Maybe<PaymentUserUsersHasPaymentNodeAggregateSelection>;
};

export type PaymentUserUsersHasPaymentEdgeAggregateSelection = {
  __typename?: "PaymentUserUsersHasPaymentEdgeAggregateSelection";
  integrationId: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
};

export type PaymentUserUsersHasPaymentNodeAggregateSelection = {
  __typename?: "PaymentUserUsersHasPaymentNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type PaymentUsersHasPaymentAggregateInput = {
  AND?: InputMaybe<Array<PaymentUsersHasPaymentAggregateInput>>;
  NOT?: InputMaybe<PaymentUsersHasPaymentAggregateInput>;
  OR?: InputMaybe<Array<PaymentUsersHasPaymentAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasPaymentPropertiesAggregationWhereInput>;
  node?: InputMaybe<PaymentUsersHasPaymentNodeAggregationWhereInput>;
};

export type PaymentUsersHasPaymentConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge: HasPaymentPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type PaymentUsersHasPaymentConnection = {
  __typename?: "PaymentUsersHasPaymentConnection";
  edges: Array<PaymentUsersHasPaymentRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PaymentUsersHasPaymentConnectionSort = {
  edge?: InputMaybe<HasPaymentPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type PaymentUsersHasPaymentConnectionWhere = {
  AND?: InputMaybe<Array<PaymentUsersHasPaymentConnectionWhere>>;
  NOT?: InputMaybe<PaymentUsersHasPaymentConnectionWhere>;
  OR?: InputMaybe<Array<PaymentUsersHasPaymentConnectionWhere>>;
  edge?: InputMaybe<HasPaymentPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type PaymentUsersHasPaymentCreateFieldInput = {
  edge: HasPaymentPropertiesCreateInput;
  node: UserCreateInput;
};

export type PaymentUsersHasPaymentDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<PaymentUsersHasPaymentConnectionWhere>;
};

export type PaymentUsersHasPaymentDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<PaymentUsersHasPaymentConnectionWhere>;
};

export type PaymentUsersHasPaymentFieldInput = {
  connect?: InputMaybe<Array<PaymentUsersHasPaymentConnectFieldInput>>;
  create?: InputMaybe<Array<PaymentUsersHasPaymentCreateFieldInput>>;
};

export type PaymentUsersHasPaymentNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PaymentUsersHasPaymentNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PaymentUsersHasPaymentNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PaymentUsersHasPaymentNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PaymentUsersHasPaymentRelationship = {
  __typename?: "PaymentUsersHasPaymentRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasPaymentProperties;
};

export type PaymentUsersHasPaymentUpdateConnectionInput = {
  edge?: InputMaybe<HasPaymentPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type PaymentUsersHasPaymentUpdateFieldInput = {
  connect?: InputMaybe<Array<PaymentUsersHasPaymentConnectFieldInput>>;
  create?: InputMaybe<Array<PaymentUsersHasPaymentCreateFieldInput>>;
  delete?: InputMaybe<Array<PaymentUsersHasPaymentDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<PaymentUsersHasPaymentDisconnectFieldInput>>;
  update?: InputMaybe<PaymentUsersHasPaymentUpdateConnectionInput>;
  where?: InputMaybe<PaymentUsersHasPaymentConnectionWhere>;
};

export type PaymentWhere = {
  AND?: InputMaybe<Array<PaymentWhere>>;
  NOT?: InputMaybe<PaymentWhere>;
  OR?: InputMaybe<Array<PaymentWhere>>;
  domain?: InputMaybe<Scalars["String"]["input"]>;
  domain_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  domain_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  domain_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  domain_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasPaymentAggregate?: InputMaybe<PaymentUsersHasPaymentAggregateInput>;
  /** Return Payments where all of the related PaymentUsersHasPaymentConnections match this filter */
  usersHasPaymentConnection_ALL?: InputMaybe<PaymentUsersHasPaymentConnectionWhere>;
  /** Return Payments where none of the related PaymentUsersHasPaymentConnections match this filter */
  usersHasPaymentConnection_NONE?: InputMaybe<PaymentUsersHasPaymentConnectionWhere>;
  /** Return Payments where one of the related PaymentUsersHasPaymentConnections match this filter */
  usersHasPaymentConnection_SINGLE?: InputMaybe<PaymentUsersHasPaymentConnectionWhere>;
  /** Return Payments where some of the related PaymentUsersHasPaymentConnections match this filter */
  usersHasPaymentConnection_SOME?: InputMaybe<PaymentUsersHasPaymentConnectionWhere>;
  /** Return Payments where all of the related Users match this filter */
  usersHasPayment_ALL?: InputMaybe<UserWhere>;
  /** Return Payments where none of the related Users match this filter */
  usersHasPayment_NONE?: InputMaybe<UserWhere>;
  /** Return Payments where one of the related Users match this filter */
  usersHasPayment_SINGLE?: InputMaybe<UserWhere>;
  /** Return Payments where some of the related Users match this filter */
  usersHasPayment_SOME?: InputMaybe<UserWhere>;
};

export type PaymentsConnection = {
  __typename?: "PaymentsConnection";
  edges: Array<PaymentEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Permission = {
  __typename?: "Permission";
  name: Scalars["String"]["output"];
  rolesHasPermission: Array<Role>;
  rolesHasPermissionAggregate?: Maybe<PermissionRoleRolesHasPermissionAggregationSelection>;
  rolesHasPermissionConnection: PermissionRolesHasPermissionConnection;
};

export type PermissionRolesHasPermissionArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<RoleOptions>;
  where?: InputMaybe<RoleWhere>;
};

export type PermissionRolesHasPermissionAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<RoleWhere>;
};

export type PermissionRolesHasPermissionConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<PermissionRolesHasPermissionConnectionSort>>;
  where?: InputMaybe<PermissionRolesHasPermissionConnectionWhere>;
};

export type PermissionAggregateSelection = {
  __typename?: "PermissionAggregateSelection";
  count: Scalars["Int"]["output"];
  name: StringAggregateSelection;
};

export type PermissionConnectInput = {
  rolesHasPermission?: InputMaybe<Array<PermissionRolesHasPermissionConnectFieldInput>>;
};

export type PermissionConnectWhere = {
  node: PermissionWhere;
};

export type PermissionCreateInput = {
  name: Scalars["String"]["input"];
  rolesHasPermission?: InputMaybe<PermissionRolesHasPermissionFieldInput>;
};

export type PermissionDeleteInput = {
  rolesHasPermission?: InputMaybe<Array<PermissionRolesHasPermissionDeleteFieldInput>>;
};

export type PermissionDisconnectInput = {
  rolesHasPermission?: InputMaybe<Array<PermissionRolesHasPermissionDisconnectFieldInput>>;
};

export type PermissionEdge = {
  __typename?: "PermissionEdge";
  cursor: Scalars["String"]["output"];
  node: Permission;
};

export type PermissionOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more PermissionSort objects to sort Permissions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<PermissionSort>>;
};

export type PermissionRelationInput = {
  rolesHasPermission?: InputMaybe<Array<PermissionRolesHasPermissionCreateFieldInput>>;
};

export type PermissionRoleRolesHasPermissionAggregationSelection = {
  __typename?: "PermissionRoleRolesHasPermissionAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<PermissionRoleRolesHasPermissionNodeAggregateSelection>;
};

export type PermissionRoleRolesHasPermissionNodeAggregateSelection = {
  __typename?: "PermissionRoleRolesHasPermissionNodeAggregateSelection";
  name: StringAggregateSelection;
};

export type PermissionRolesHasPermissionAggregateInput = {
  AND?: InputMaybe<Array<PermissionRolesHasPermissionAggregateInput>>;
  NOT?: InputMaybe<PermissionRolesHasPermissionAggregateInput>;
  OR?: InputMaybe<Array<PermissionRolesHasPermissionAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<PermissionRolesHasPermissionNodeAggregationWhereInput>;
};

export type PermissionRolesHasPermissionConnectFieldInput = {
  connect?: InputMaybe<Array<RoleConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<RoleConnectWhere>;
};

export type PermissionRolesHasPermissionConnection = {
  __typename?: "PermissionRolesHasPermissionConnection";
  edges: Array<PermissionRolesHasPermissionRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PermissionRolesHasPermissionConnectionSort = {
  node?: InputMaybe<RoleSort>;
};

export type PermissionRolesHasPermissionConnectionWhere = {
  AND?: InputMaybe<Array<PermissionRolesHasPermissionConnectionWhere>>;
  NOT?: InputMaybe<PermissionRolesHasPermissionConnectionWhere>;
  OR?: InputMaybe<Array<PermissionRolesHasPermissionConnectionWhere>>;
  node?: InputMaybe<RoleWhere>;
};

export type PermissionRolesHasPermissionCreateFieldInput = {
  node: RoleCreateInput;
};

export type PermissionRolesHasPermissionDeleteFieldInput = {
  delete?: InputMaybe<RoleDeleteInput>;
  where?: InputMaybe<PermissionRolesHasPermissionConnectionWhere>;
};

export type PermissionRolesHasPermissionDisconnectFieldInput = {
  disconnect?: InputMaybe<RoleDisconnectInput>;
  where?: InputMaybe<PermissionRolesHasPermissionConnectionWhere>;
};

export type PermissionRolesHasPermissionFieldInput = {
  connect?: InputMaybe<Array<PermissionRolesHasPermissionConnectFieldInput>>;
  create?: InputMaybe<Array<PermissionRolesHasPermissionCreateFieldInput>>;
};

export type PermissionRolesHasPermissionNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PermissionRolesHasPermissionNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PermissionRolesHasPermissionNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PermissionRolesHasPermissionNodeAggregationWhereInput>>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PermissionRolesHasPermissionRelationship = {
  __typename?: "PermissionRolesHasPermissionRelationship";
  cursor: Scalars["String"]["output"];
  node: Role;
};

export type PermissionRolesHasPermissionUpdateConnectionInput = {
  node?: InputMaybe<RoleUpdateInput>;
};

export type PermissionRolesHasPermissionUpdateFieldInput = {
  connect?: InputMaybe<Array<PermissionRolesHasPermissionConnectFieldInput>>;
  create?: InputMaybe<Array<PermissionRolesHasPermissionCreateFieldInput>>;
  delete?: InputMaybe<Array<PermissionRolesHasPermissionDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<PermissionRolesHasPermissionDisconnectFieldInput>>;
  update?: InputMaybe<PermissionRolesHasPermissionUpdateConnectionInput>;
  where?: InputMaybe<PermissionRolesHasPermissionConnectionWhere>;
};

/** Fields to sort Permissions by. The order in which sorts are applied is not guaranteed when specifying many fields in one PermissionSort object. */
export type PermissionSort = {
  name?: InputMaybe<SortDirection>;
};

export type PermissionUpdateInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  rolesHasPermission?: InputMaybe<Array<PermissionRolesHasPermissionUpdateFieldInput>>;
};

export type PermissionWhere = {
  AND?: InputMaybe<Array<PermissionWhere>>;
  NOT?: InputMaybe<PermissionWhere>;
  OR?: InputMaybe<Array<PermissionWhere>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  rolesHasPermissionAggregate?: InputMaybe<PermissionRolesHasPermissionAggregateInput>;
  /** Return Permissions where all of the related PermissionRolesHasPermissionConnections match this filter */
  rolesHasPermissionConnection_ALL?: InputMaybe<PermissionRolesHasPermissionConnectionWhere>;
  /** Return Permissions where none of the related PermissionRolesHasPermissionConnections match this filter */
  rolesHasPermissionConnection_NONE?: InputMaybe<PermissionRolesHasPermissionConnectionWhere>;
  /** Return Permissions where one of the related PermissionRolesHasPermissionConnections match this filter */
  rolesHasPermissionConnection_SINGLE?: InputMaybe<PermissionRolesHasPermissionConnectionWhere>;
  /** Return Permissions where some of the related PermissionRolesHasPermissionConnections match this filter */
  rolesHasPermissionConnection_SOME?: InputMaybe<PermissionRolesHasPermissionConnectionWhere>;
  /** Return Permissions where all of the related Roles match this filter */
  rolesHasPermission_ALL?: InputMaybe<RoleWhere>;
  /** Return Permissions where none of the related Roles match this filter */
  rolesHasPermission_NONE?: InputMaybe<RoleWhere>;
  /** Return Permissions where one of the related Roles match this filter */
  rolesHasPermission_SINGLE?: InputMaybe<RoleWhere>;
  /** Return Permissions where some of the related Roles match this filter */
  rolesHasPermission_SOME?: InputMaybe<RoleWhere>;
};

export type PermissionsConnection = {
  __typename?: "PermissionsConnection";
  edges: Array<PermissionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PersonalityTrait = {
  __typename?: "PersonalityTrait";
  id?: Maybe<Scalars["Int"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  value?: Maybe<Scalars["Int"]["output"]>;
};

export type PersonalityTraitAggregateSelection = {
  __typename?: "PersonalityTraitAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IntAggregateSelection;
  name: StringAggregateSelection;
  value: IntAggregateSelection;
};

export type PersonalityTraitCreateInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PersonalityTraitEdge = {
  __typename?: "PersonalityTraitEdge";
  cursor: Scalars["String"]["output"];
  node: PersonalityTrait;
};

export type PersonalityTraitOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more PersonalityTraitSort objects to sort PersonalityTraits by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<PersonalityTraitSort>>;
};

/** Fields to sort PersonalityTraits by. The order in which sorts are applied is not guaranteed when specifying many fields in one PersonalityTraitSort object. */
export type PersonalityTraitSort = {
  id?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  value?: InputMaybe<SortDirection>;
};

export type PersonalityTraitUpdateInput = {
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["Int"]["input"]>;
  value_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  value_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PersonalityTraitWhere = {
  AND?: InputMaybe<Array<PersonalityTraitWhere>>;
  NOT?: InputMaybe<PersonalityTraitWhere>;
  OR?: InputMaybe<Array<PersonalityTraitWhere>>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  value?: InputMaybe<Scalars["Int"]["input"]>;
  value_GT?: InputMaybe<Scalars["Int"]["input"]>;
  value_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  value_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  value_LT?: InputMaybe<Scalars["Int"]["input"]>;
  value_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PersonalityTraitsConnection = {
  __typename?: "PersonalityTraitsConnection";
  edges: Array<PersonalityTraitEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Preferences = {
  __typename?: "Preferences";
  contentType?: Maybe<Scalars["String"]["output"]>;
  language: Scalars["String"]["output"];
  learningDuration?: Maybe<Scalars["Int"]["output"]>;
  learningStyle?: Maybe<Scalars["String"]["output"]>;
  notificationPreferences?: Maybe<Scalars["String"]["output"]>;
  timeOfDay?: Maybe<Scalars["String"]["output"]>;
  usersHasPreferences: Array<User>;
  usersHasPreferencesAggregate?: Maybe<PreferencesUserUsersHasPreferencesAggregationSelection>;
  usersHasPreferencesConnection: PreferencesUsersHasPreferencesConnection;
};

export type PreferencesUsersHasPreferencesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type PreferencesUsersHasPreferencesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type PreferencesUsersHasPreferencesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<PreferencesUsersHasPreferencesConnectionSort>>;
  where?: InputMaybe<PreferencesUsersHasPreferencesConnectionWhere>;
};

export type PreferencesAggregateSelection = {
  __typename?: "PreferencesAggregateSelection";
  contentType: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  language: StringAggregateSelection;
  learningDuration: IntAggregateSelection;
  learningStyle: StringAggregateSelection;
  notificationPreferences: StringAggregateSelection;
  timeOfDay: StringAggregateSelection;
};

export type PreferencesConnectInput = {
  usersHasPreferences?: InputMaybe<Array<PreferencesUsersHasPreferencesConnectFieldInput>>;
};

export type PreferencesConnectWhere = {
  node: PreferencesWhere;
};

export type PreferencesConnection = {
  __typename?: "PreferencesConnection";
  edges: Array<PreferencesEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PreferencesCreateInput = {
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  language: Scalars["String"]["input"];
  learningDuration?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  usersHasPreferences?: InputMaybe<PreferencesUsersHasPreferencesFieldInput>;
};

export type PreferencesDeleteInput = {
  usersHasPreferences?: InputMaybe<Array<PreferencesUsersHasPreferencesDeleteFieldInput>>;
};

export type PreferencesDisconnectInput = {
  usersHasPreferences?: InputMaybe<Array<PreferencesUsersHasPreferencesDisconnectFieldInput>>;
};

export type PreferencesEdge = {
  __typename?: "PreferencesEdge";
  cursor: Scalars["String"]["output"];
  node: Preferences;
};

export type PreferencesOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more PreferencesSort objects to sort Preferences by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<PreferencesSort>>;
};

export type PreferencesRelationInput = {
  usersHasPreferences?: InputMaybe<Array<PreferencesUsersHasPreferencesCreateFieldInput>>;
};

/** Fields to sort Preferences by. The order in which sorts are applied is not guaranteed when specifying many fields in one PreferencesSort object. */
export type PreferencesSort = {
  contentType?: InputMaybe<SortDirection>;
  language?: InputMaybe<SortDirection>;
  learningDuration?: InputMaybe<SortDirection>;
  learningStyle?: InputMaybe<SortDirection>;
  notificationPreferences?: InputMaybe<SortDirection>;
  timeOfDay?: InputMaybe<SortDirection>;
};

export type PreferencesUpdateInput = {
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  learningDuration?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  usersHasPreferences?: InputMaybe<Array<PreferencesUsersHasPreferencesUpdateFieldInput>>;
};

export type PreferencesUserUsersHasPreferencesAggregationSelection = {
  __typename?: "PreferencesUserUsersHasPreferencesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<PreferencesUserUsersHasPreferencesNodeAggregateSelection>;
};

export type PreferencesUserUsersHasPreferencesNodeAggregateSelection = {
  __typename?: "PreferencesUserUsersHasPreferencesNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type PreferencesUsersHasPreferencesAggregateInput = {
  AND?: InputMaybe<Array<PreferencesUsersHasPreferencesAggregateInput>>;
  NOT?: InputMaybe<PreferencesUsersHasPreferencesAggregateInput>;
  OR?: InputMaybe<Array<PreferencesUsersHasPreferencesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<PreferencesUsersHasPreferencesNodeAggregationWhereInput>;
};

export type PreferencesUsersHasPreferencesConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type PreferencesUsersHasPreferencesConnection = {
  __typename?: "PreferencesUsersHasPreferencesConnection";
  edges: Array<PreferencesUsersHasPreferencesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type PreferencesUsersHasPreferencesConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type PreferencesUsersHasPreferencesConnectionWhere = {
  AND?: InputMaybe<Array<PreferencesUsersHasPreferencesConnectionWhere>>;
  NOT?: InputMaybe<PreferencesUsersHasPreferencesConnectionWhere>;
  OR?: InputMaybe<Array<PreferencesUsersHasPreferencesConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type PreferencesUsersHasPreferencesCreateFieldInput = {
  node: UserCreateInput;
};

export type PreferencesUsersHasPreferencesDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<PreferencesUsersHasPreferencesConnectionWhere>;
};

export type PreferencesUsersHasPreferencesDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<PreferencesUsersHasPreferencesConnectionWhere>;
};

export type PreferencesUsersHasPreferencesFieldInput = {
  connect?: InputMaybe<Array<PreferencesUsersHasPreferencesConnectFieldInput>>;
  create?: InputMaybe<Array<PreferencesUsersHasPreferencesCreateFieldInput>>;
};

export type PreferencesUsersHasPreferencesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<PreferencesUsersHasPreferencesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<PreferencesUsersHasPreferencesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<PreferencesUsersHasPreferencesNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type PreferencesUsersHasPreferencesRelationship = {
  __typename?: "PreferencesUsersHasPreferencesRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type PreferencesUsersHasPreferencesUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type PreferencesUsersHasPreferencesUpdateFieldInput = {
  connect?: InputMaybe<Array<PreferencesUsersHasPreferencesConnectFieldInput>>;
  create?: InputMaybe<Array<PreferencesUsersHasPreferencesCreateFieldInput>>;
  delete?: InputMaybe<Array<PreferencesUsersHasPreferencesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<PreferencesUsersHasPreferencesDisconnectFieldInput>>;
  update?: InputMaybe<PreferencesUsersHasPreferencesUpdateConnectionInput>;
  where?: InputMaybe<PreferencesUsersHasPreferencesConnectionWhere>;
};

export type PreferencesWhere = {
  AND?: InputMaybe<Array<PreferencesWhere>>;
  NOT?: InputMaybe<PreferencesWhere>;
  OR?: InputMaybe<Array<PreferencesWhere>>;
  contentType?: InputMaybe<Scalars["String"]["input"]>;
  contentType_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  contentType_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  contentType_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  contentType_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  language?: InputMaybe<Scalars["String"]["input"]>;
  language_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  language_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  language_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  language_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  learningDuration?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  learningDuration_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle?: InputMaybe<Scalars["String"]["input"]>;
  learningStyle_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  learningStyle_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  learningStyle_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  learningStyle_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  notificationPreferences_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  notificationPreferences_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  timeOfDay_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasPreferencesAggregate?: InputMaybe<PreferencesUsersHasPreferencesAggregateInput>;
  /** Return Preferences where all of the related PreferencesUsersHasPreferencesConnections match this filter */
  usersHasPreferencesConnection_ALL?: InputMaybe<PreferencesUsersHasPreferencesConnectionWhere>;
  /** Return Preferences where none of the related PreferencesUsersHasPreferencesConnections match this filter */
  usersHasPreferencesConnection_NONE?: InputMaybe<PreferencesUsersHasPreferencesConnectionWhere>;
  /** Return Preferences where one of the related PreferencesUsersHasPreferencesConnections match this filter */
  usersHasPreferencesConnection_SINGLE?: InputMaybe<PreferencesUsersHasPreferencesConnectionWhere>;
  /** Return Preferences where some of the related PreferencesUsersHasPreferencesConnections match this filter */
  usersHasPreferencesConnection_SOME?: InputMaybe<PreferencesUsersHasPreferencesConnectionWhere>;
  /** Return Preferences where all of the related Users match this filter */
  usersHasPreferences_ALL?: InputMaybe<UserWhere>;
  /** Return Preferences where none of the related Users match this filter */
  usersHasPreferences_NONE?: InputMaybe<UserWhere>;
  /** Return Preferences where one of the related Users match this filter */
  usersHasPreferences_SINGLE?: InputMaybe<UserWhere>;
  /** Return Preferences where some of the related Users match this filter */
  usersHasPreferences_SOME?: InputMaybe<UserWhere>;
};

export type Prerequisite = {
  __typename?: "Prerequisite";
  field?: Maybe<Scalars["String"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  subfield?: Maybe<Scalars["String"]["output"]>;
};

export type PrerequisiteAggregateSelection = {
  __typename?: "PrerequisiteAggregateSelection";
  count: Scalars["Int"]["output"];
  field: StringAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
  subfield: StringAggregateSelection;
};

export type PrerequisiteCreateInput = {
  field?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  subfield?: InputMaybe<Scalars["String"]["input"]>;
};

export type PrerequisiteEdge = {
  __typename?: "PrerequisiteEdge";
  cursor: Scalars["String"]["output"];
  node: Prerequisite;
};

export type PrerequisiteOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more PrerequisiteSort objects to sort Prerequisites by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<PrerequisiteSort>>;
};

/** Fields to sort Prerequisites by. The order in which sorts are applied is not guaranteed when specifying many fields in one PrerequisiteSort object. */
export type PrerequisiteSort = {
  field?: InputMaybe<SortDirection>;
  mastery?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  subfield?: InputMaybe<SortDirection>;
};

export type PrerequisiteUpdateInput = {
  field?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  subfield?: InputMaybe<Scalars["String"]["input"]>;
};

export type PrerequisiteWhere = {
  AND?: InputMaybe<Array<PrerequisiteWhere>>;
  NOT?: InputMaybe<PrerequisiteWhere>;
  OR?: InputMaybe<Array<PrerequisiteWhere>>;
  field?: InputMaybe<Scalars["String"]["input"]>;
  field_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  field_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  field_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  field_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  subfield?: InputMaybe<Scalars["String"]["input"]>;
  subfield_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  subfield_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  subfield_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  subfield_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type PrerequisitesConnection = {
  __typename?: "PrerequisitesConnection";
  edges: Array<PrerequisiteEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Progress = {
  __typename?: "Progress";
  date?: Maybe<Scalars["Date"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  level?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type ProgressAggregateSelection = {
  __typename?: "ProgressAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  id: IntAggregateSelection;
  level: StringAggregateSelection;
  type: StringAggregateSelection;
};

export type ProgressCreateInput = {
  date?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProgressEdge = {
  __typename?: "ProgressEdge";
  cursor: Scalars["String"]["output"];
  node: Progress;
};

export type ProgressOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ProgressSort objects to sort Progresses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ProgressSort>>;
};

/** Fields to sort Progresses by. The order in which sorts are applied is not guaranteed when specifying many fields in one ProgressSort object. */
export type ProgressSort = {
  date?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  level?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
};

export type ProgressUpdateInput = {
  date?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProgressWhere = {
  AND?: InputMaybe<Array<ProgressWhere>>;
  NOT?: InputMaybe<ProgressWhere>;
  OR?: InputMaybe<Array<ProgressWhere>>;
  date?: InputMaybe<Scalars["Date"]["input"]>;
  date_GT?: InputMaybe<Scalars["Date"]["input"]>;
  date_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  date_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  date_LT?: InputMaybe<Scalars["Date"]["input"]>;
  date_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  level?: InputMaybe<Scalars["String"]["input"]>;
  level_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  level_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  level_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  level_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ProgressesConnection = {
  __typename?: "ProgressesConnection";
  edges: Array<ProgressEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type QuarterlySummariesConnection = {
  __typename?: "QuarterlySummariesConnection";
  edges: Array<QuarterlySummaryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type QuarterlySummary = {
  __typename?: "QuarterlySummary";
  created: Scalars["DateTime"]["output"];
  date: Scalars["String"]["output"];
  isArchived: Scalars["Boolean"]["output"];
  last_modified: Scalars["DateTime"]["output"];
  summary: Scalars["String"]["output"];
};

export type QuarterlySummaryAggregateSelection = {
  __typename?: "QuarterlySummaryAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  date: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type QuarterlySummaryConnectWhere = {
  node: QuarterlySummaryWhere;
};

export type QuarterlySummaryCreateInput = {
  created: Scalars["DateTime"]["input"];
  date: Scalars["String"]["input"];
  isArchived: Scalars["Boolean"]["input"];
  last_modified: Scalars["DateTime"]["input"];
  summary: Scalars["String"]["input"];
};

export type QuarterlySummaryEdge = {
  __typename?: "QuarterlySummaryEdge";
  cursor: Scalars["String"]["output"];
  node: QuarterlySummary;
};

export type QuarterlySummaryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more QuarterlySummarySort objects to sort QuarterlySummaries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<QuarterlySummarySort>>;
};

/** Fields to sort QuarterlySummaries by. The order in which sorts are applied is not guaranteed when specifying many fields in one QuarterlySummarySort object. */
export type QuarterlySummarySort = {
  created?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  isArchived?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  summary?: InputMaybe<SortDirection>;
};

export type QuarterlySummaryUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
};

export type QuarterlySummaryWhere = {
  AND?: InputMaybe<Array<QuarterlySummaryWhere>>;
  NOT?: InputMaybe<QuarterlySummaryWhere>;
  OR?: InputMaybe<Array<QuarterlySummaryWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  date_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  summary_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  summary_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  summary_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  summary_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type Query = {
  __typename?: "Query";
  _empty?: Maybe<Scalars["String"]["output"]>;
  achievements: Array<Achievements>;
  achievementsAggregate: AchievementsAggregateSelection;
  achievementsConnection: AchievementsConnection;
  activeTracks: Array<ActiveTrack>;
  activeTracksAggregate: ActiveTrackAggregateSelection;
  activeTracksConnection: ActiveTracksConnection;
  addAssignmentResponses: Array<AddAssignmentResponse>;
  addAssignmentResponsesAggregate: AddAssignmentResponseAggregateSelection;
  addAssignmentResponsesConnection: AddAssignmentResponsesConnection;
  addContentModuleResponses: Array<AddContentModuleResponse>;
  addContentModuleResponsesAggregate: AddContentModuleResponseAggregateSelection;
  addContentModuleResponsesConnection: AddContentModuleResponsesConnection;
  aspectDetailsTypes: Array<AspectDetailsType>;
  aspectDetailsTypesAggregate: AspectDetailsTypeAggregateSelection;
  aspectDetailsTypesConnection: AspectDetailsTypesConnection;
  assignedQuests: Array<AssignedQuest>;
  assignedQuestsAggregate: AssignedQuestAggregateSelection;
  assignedQuestsConnection: AssignedQuestsConnection;
  assignmentResponses: Array<AssignmentResponse>;
  assignmentResponsesAggregate: AssignmentResponseAggregateSelection;
  assignmentResponsesConnection: AssignmentResponsesConnection;
  assignments: Array<Assignment>;
  assignmentsAggregate: AssignmentAggregateSelection;
  assignmentsConnection: AssignmentsConnection;
  attributes: Array<Attribute>;
  attributesAggregate: AttributeAggregateSelection;
  attributesConnection: AttributesConnection;
  audioToggleUserResponses: Array<AudioToggleUserResponse>;
  audioToggleUserResponsesAggregate: AudioToggleUserResponseAggregateSelection;
  audioToggleUserResponsesConnection: AudioToggleUserResponsesConnection;
  authPayloadResponses: Array<AuthPayloadResponse>;
  authPayloadResponsesAggregate: AuthPayloadResponseAggregateSelection;
  authPayloadResponsesConnection: AuthPayloadResponsesConnection;
  authPayloadUserPreferences: Array<AuthPayloadUserPreferences>;
  authPayloadUserPreferencesAggregate: AuthPayloadUserPreferencesAggregateSelection;
  authPayloadUserPreferencesConnection: AuthPayloadUserPreferencesConnection;
  authPayloadUsers: Array<AuthPayloadUser>;
  authPayloadUsersAggregate: AuthPayloadUserAggregateSelection;
  authPayloadUsersConnection: AuthPayloadUsersConnection;
  careerHeaders: Array<CareerHeader>;
  careerHeadersAggregate: CareerHeaderAggregateSelection;
  careerHeadersConnection: CareerHeadersConnection;
  careerTopicResponses: Array<CareerTopicResponse>;
  careerTopicResponsesAggregate: CareerTopicResponseAggregateSelection;
  careerTopicResponsesConnection: CareerTopicResponsesConnection;
  careers: Array<Career>;
  careersAggregate: CareerAggregateSelection;
  careersConnection: CareersConnection;
  challenges: Array<Challenge>;
  challengesAggregate: ChallengeAggregateSelection;
  challengesConnection: ChallengesConnection;
  chapters: Array<Chapter>;
  chaptersAggregate: ChapterAggregateSelection;
  chaptersConnection: ChaptersConnection;
  chats: Array<Chat>;
  chatsAggregate: ChatAggregateSelection;
  chatsConnection: ChatsConnection;
  childInfos: Array<ChildInfo>;
  childInfosAggregate: ChildInfoAggregateSelection;
  childInfosConnection: ChildInfosConnection;
  coreValues: Array<CoreValue>;
  coreValuesAggregate: CoreValueAggregateSelection;
  coreValuesConnection: CoreValuesConnection;
  courseCategories: Array<CourseCategory>;
  courseCategoriesAggregate: CourseCategoryAggregateSelection;
  courseCategoriesConnection: CourseCategoriesConnection;
  courseCreationResponses: Array<CourseCreationResponse>;
  courseCreationResponsesAggregate: CourseCreationResponseAggregateSelection;
  courseCreationResponsesConnection: CourseCreationResponsesConnection;
  courseResponses: Array<CourseResponse>;
  courseResponsesAggregate: CourseResponseAggregateSelection;
  courseResponsesConnection: CourseResponsesConnection;
  courses: Array<Course>;
  coursesAggregate: CourseAggregateSelection;
  coursesConnection: CoursesConnection;
  curricula: Array<Curriculum>;
  curriculaAggregate: CurriculumAggregateSelection;
  curriculaConnection: CurriculaConnection;
  dailyCompletedTodosCounts: Array<DailyCompletedTodosCount>;
  dailyCompletedTodosCountsAggregate: DailyCompletedTodosCountAggregateSelection;
  dailyCompletedTodosCountsConnection: DailyCompletedTodosCountsConnection;
  dailyExerciseTotals: Array<DailyExerciseTotals>;
  dailyExerciseTotalsAggregate: DailyExerciseTotalsAggregateSelection;
  dailyExerciseTotalsConnection: DailyExerciseTotalsConnection;
  dailyNutritionTotals: Array<DailyNutritionTotals>;
  dailyNutritionTotalsAggregate: DailyNutritionTotalsAggregateSelection;
  dailyNutritionTotalsConnection: DailyNutritionTotalsConnection;
  dailySummaries: Array<DailySummary>;
  dailySummariesAggregate: DailySummaryAggregateSelection;
  dailySummariesConnection: DailySummariesConnection;
  deleteContentAssignmentResponses: Array<DeleteContentAssignmentResponse>;
  deleteContentAssignmentResponsesAggregate: DeleteContentAssignmentResponseAggregateSelection;
  deleteContentAssignmentResponsesConnection: DeleteContentAssignmentResponsesConnection;
  deleteContentAssignmentVariables: Array<DeleteContentAssignmentVariables>;
  deleteContentAssignmentVariablesAggregate: DeleteContentAssignmentVariablesAggregateSelection;
  deleteContentAssignmentVariablesConnection: DeleteContentAssignmentVariablesConnection;
  deleteContentModuleResponses: Array<DeleteContentModuleResponse>;
  deleteContentModuleResponsesAggregate: DeleteContentModuleResponseAggregateSelection;
  deleteContentModuleResponsesConnection: DeleteContentModuleResponsesConnection;
  deleteContentModuleVariables: Array<DeleteContentModuleVariables>;
  deleteContentModuleVariablesAggregate: DeleteContentModuleVariablesAggregateSelection;
  deleteContentModuleVariablesConnection: DeleteContentModuleVariablesConnection;
  deleteCourseResponses: Array<DeleteCourseResponse>;
  deleteCourseResponsesAggregate: DeleteCourseResponseAggregateSelection;
  deleteCourseResponsesConnection: DeleteCourseResponsesConnection;
  deviceSessions: Array<DeviceSession>;
  deviceSessionsAggregate: DeviceSessionAggregateSelection;
  deviceSessionsConnection: DeviceSessionsConnection;
  emotionalStates: Array<EmotionalState>;
  emotionalStatesAggregate: EmotionalStateAggregateSelection;
  emotionalStatesConnection: EmotionalStatesConnection;
  events: Array<Event>;
  eventsAggregate: EventAggregateSelection;
  eventsConnection: EventsConnection;
  exportCsv: ExportCsvResponse;
  exportCsvResponses: Array<ExportCsvResponse>;
  exportCsvResponsesAggregate: ExportCsvResponseAggregateSelection;
  exportCsvResponsesConnection: ExportCsvResponsesConnection;
  feedbacks: Array<Feedback>;
  feedbacksAggregate: FeedbackAggregateSelection;
  feedbacksConnection: FeedbacksConnection;
  fields: Array<Field>;
  fieldsAggregate: FieldAggregateSelection;
  fieldsConnection: FieldsConnection;
  files: Array<File>;
  filesAggregate: FileAggregateSelection;
  filesConnection: FilesConnection;
  foodItems: Array<FoodItem>;
  foodItemsAggregate: FoodItemAggregateSelection;
  foodItemsConnection: FoodItemsConnection;
  foods?: Maybe<Array<Maybe<Food>>>;
  foodsAggregate: FoodAggregateSelection;
  foodsConnection: FoodsConnection;
  games: Array<Game>;
  gamesAggregate: GameAggregateSelection;
  gamesConnection: GamesConnection;
  generateds: Array<Generated>;
  generatedsAggregate: GeneratedAggregateSelection;
  generatedsConnection: GeneratedsConnection;
  getActiveTracks?: Maybe<Array<Maybe<ActiveTrack>>>;
  getAllAssignmentsByResources?: Maybe<AssignmentResponse>;
  getAllCoursesBySchool?: Maybe<CourseResponse>;
  getAllModulesByCourse?: Maybe<ModuleResponse>;
  getAllPagesByResources?: Maybe<PageResponse>;
  getAllTenants?: Maybe<TenantResponse>;
  getAssignment?: Maybe<GetAssignmentResponse>;
  getAssignmentObjectiveResponses: Array<GetAssignmentObjectiveResponse>;
  getAssignmentObjectiveResponsesAggregate: GetAssignmentObjectiveResponseAggregateSelection;
  getAssignmentObjectiveResponsesConnection: GetAssignmentObjectiveResponsesConnection;
  getAssignmentResponses: Array<GetAssignmentResponse>;
  getAssignmentResponsesAggregate: GetAssignmentResponseAggregateSelection;
  getAssignmentResponsesConnection: GetAssignmentResponsesConnection;
  getAssignments?: Maybe<Array<Maybe<GetAssignmentResponse>>>;
  getCareerById?: Maybe<Career>;
  getCareerHeader: CareerHeader;
  getCareerTopics?: Maybe<Array<Maybe<Topic>>>;
  getCareers?: Maybe<Array<Maybe<Career>>>;
  getChapters?: Maybe<Array<Maybe<Subfield>>>;
  getChatByContextAndParams?: Maybe<Chat>;
  getChatById?: Maybe<Chat>;
  getChatByObjectId?: Maybe<Chat>;
  getChatForDate?: Maybe<Chat>;
  getChats?: Maybe<Array<Maybe<Chat>>>;
  getContentById?: Maybe<Curriculum>;
  getCourse?: Maybe<CourseResponse>;
  getCustomAssignment?: Maybe<AssignmentResponse>;
  getDailyCompletedTodos?: Maybe<DailyCompletedTodosCount>;
  getDailyExerciseTotalsByDate?: Maybe<DailyExerciseTotals>;
  getDailyLimit?: Maybe<RateLimitResult>;
  getDailyNutritionTotalsByDate?: Maybe<DailyNutritionTotals>;
  getFields: Array<Maybe<Field>>;
  getFirstSubchapterByTopicName: SubchapterDetails;
  getFoodsByDate?: Maybe<Array<Maybe<Food>>>;
  getGeneratedContent?: Maybe<Array<Maybe<Curriculum>>>;
  getJourneyById?: Maybe<GetJourneyByIdResponse>;
  getJourneyByIdResponses: Array<GetJourneyByIdResponse>;
  getJourneyByIdResponsesAggregate: GetJourneyByIdResponseAggregateSelection;
  getJourneyByIdResponsesConnection: GetJourneyByIdResponsesConnection;
  getJourneys?: Maybe<Array<Maybe<GetJourneysResponse>>>;
  getJourneysResponses: Array<GetJourneysResponse>;
  getJourneysResponsesAggregate: GetJourneysResponseAggregateSelection;
  getJourneysResponsesConnection: GetJourneysResponsesConnection;
  getLearnedSubchaptersByDate?: Maybe<LearningSummary>;
  getLearningObjectiveById: LearningObjectiveResponse;
  getLearningObjectivesBySubchapter?: Maybe<Array<Maybe<LearningObjective>>>;
  getLearningTree?: Maybe<LearningTree>;
  getLevelDetails?: Maybe<LevelDetails>;
  getModule?: Maybe<ModuleResponse>;
  getNode?: Maybe<LearningNode>;
  getNodeChildren: Array<Maybe<LearningNode>>;
  getNodeParents: Array<Maybe<LearningNode>>;
  getPage?: Maybe<PageResponse>;
  getRecentTracks?: Maybe<Array<Maybe<RecentTrack>>>;
  getSchools?: Maybe<Array<Maybe<School>>>;
  getSiblingSubchapters?: Maybe<Array<Maybe<HierarchyNode>>>;
  getSubchapterById?: Maybe<SubchapterDetails>;
  getSubchapterPrerequisites?: Maybe<Array<Maybe<HierarchyNode>>>;
  getSubchapters?: Maybe<Array<Maybe<Subfield>>>;
  getSubfields?: Maybe<Array<Maybe<Subfield>>>;
  getSuggestions?: Maybe<Array<Maybe<Suggestion>>>;
  getTableOfContents?: Maybe<Array<Maybe<Hierarchy>>>;
  getTenant?: Maybe<TenantResponse>;
  getTodayChat?: Maybe<Chat>;
  getTodayGame?: Maybe<Game>;
  getTopics?: Maybe<Array<Maybe<Subfield>>>;
  getTrackOverview?: Maybe<TrackOverview>;
  getTransactionHistory?: Maybe<Array<Maybe<Transaction>>>;
  getTransactionHistoryForDate: Array<TransactionByDate>;
  getUserDailySummaries?: Maybe<Array<Maybe<DailySummary>>>;
  getUserJournalEntry?: Maybe<DailySummary>;
  getUserLearningObjectiveMastery?: Maybe<MasteryResult>;
  getUserMastery: Array<UserMastery>;
  getUserOverallProgress: Array<UserProgress>;
  getUserProgress: Array<UserProgress>;
  getWaterIntakeByDate?: Maybe<WaterIntakeSummary>;
  goals: Array<Goal>;
  goalsAggregate: GoalAggregateSelection;
  goalsConnection: GoalsConnection;
  googleAuthTokens: Array<GoogleAuthTokens>;
  googleAuthTokensAggregate: GoogleAuthTokensAggregateSelection;
  googleAuthTokensConnection: GoogleAuthTokensConnection;
  hierarchies: Array<Hierarchy>;
  hierarchiesAggregate: HierarchyAggregateSelection;
  hierarchiesConnection: HierarchiesConnection;
  hierarchyNodes: Array<HierarchyNode>;
  hierarchyNodesAggregate: HierarchyNodeAggregateSelection;
  hierarchyNodesConnection: HierarchyNodesConnection;
  hobbies: Array<Hobby>;
  hobbiesAggregate: HobbyAggregateSelection;
  hobbiesConnection: HobbiesConnection;
  interests: Array<Interest>;
  interestsAggregate: InterestAggregateSelection;
  interestsConnection: InterestsConnection;
  inviteCountResponses: Array<InviteCountResponse>;
  inviteCountResponsesAggregate: InviteCountResponseAggregateSelection;
  inviteCountResponsesConnection: InviteCountResponsesConnection;
  journeys: Array<Journey>;
  journeysAggregate: JourneyAggregateSelection;
  journeysConnection: JourneysConnection;
  learningNodes: Array<LearningNode>;
  learningNodesAggregate: LearningNodeAggregateSelection;
  learningNodesConnection: LearningNodesConnection;
  learningObjectiveResponses: Array<LearningObjectiveResponse>;
  learningObjectiveResponsesAggregate: LearningObjectiveResponseAggregateSelection;
  learningObjectiveResponsesConnection: LearningObjectiveResponsesConnection;
  learningObjectives: Array<LearningObjective>;
  learningObjectivesAggregate: LearningObjectiveAggregateSelection;
  learningObjectivesConnection: LearningObjectivesConnection;
  learningSummaries: Array<LearningSummary>;
  learningSummariesAggregate: LearningSummaryAggregateSelection;
  learningSummariesConnection: LearningSummariesConnection;
  learningTrees: Array<LearningTree>;
  learningTreesAggregate: LearningTreeAggregateSelection;
  learningTreesConnection: LearningTreesConnection;
  lessons: Array<Lesson>;
  lessonsAggregate: LessonAggregateSelection;
  lessonsConnection: LessonsConnection;
  levelDetails: Array<LevelDetails>;
  levelDetailsAggregate: LevelDetailsAggregateSelection;
  levelDetailsConnection: LevelDetailsConnection;
  lifeAspirations: Array<LifeAspiration>;
  lifeAspirationsAggregate: LifeAspirationAggregateSelection;
  lifeAspirationsConnection: LifeAspirationsConnection;
  masteryResults: Array<MasteryResult>;
  masteryResultsAggregate: MasteryResultAggregateSelection;
  masteryResultsConnection: MasteryResultsConnection;
  me?: Maybe<MeResponse>;
  meResponses: Array<MeResponse>;
  meResponsesAggregate: MeResponseAggregateSelection;
  meResponsesConnection: MeResponsesConnection;
  messages: Array<Message>;
  messagesAggregate: MessageAggregateSelection;
  messagesConnection: MessagesConnection;
  moduleResponses: Array<ModuleResponse>;
  moduleResponsesAggregate: ModuleResponseAggregateSelection;
  moduleResponsesConnection: ModuleResponsesConnection;
  modules: Array<Module>;
  modulesAggregate: ModuleAggregateSelection;
  modulesConnection: ModulesConnection;
  monthlySummaries: Array<MonthlySummary>;
  monthlySummariesAggregate: MonthlySummaryAggregateSelection;
  monthlySummariesConnection: MonthlySummariesConnection;
  nodeIdentities: Array<NodeIdentity>;
  nodeIdentitiesAggregate: NodeIdentityAggregateSelection;
  nodeIdentitiesConnection: NodeIdentitiesConnection;
  nodeProperties: Array<NodeProperties>;
  nodePropertiesAggregate: NodePropertiesAggregateSelection;
  nodePropertiesConnection: NodePropertiesConnection;
  nutrition?: Maybe<Array<Maybe<Nutrition>>>;
  nutritionByDate?: Maybe<Array<Maybe<Nutrition>>>;
  nutritions: Array<Nutrition>;
  nutritionsAggregate: NutritionAggregateSelection;
  nutritionsConnection: NutritionsConnection;
  objectives: Array<Objective>;
  objectivesAggregate: ObjectiveAggregateSelection;
  objectivesConnection: ObjectivesConnection;
  pageResponses: Array<PageResponse>;
  pageResponsesAggregate: PageResponseAggregateSelection;
  pageResponsesConnection: PageResponsesConnection;
  pages: Array<Page>;
  pagesAggregate: PageAggregateSelection;
  pagesConnection: PagesConnection;
  parametersTypes: Array<ParametersType>;
  parametersTypesAggregate: ParametersTypeAggregateSelection;
  parametersTypesConnection: ParametersTypesConnection;
  payments: Array<Payment>;
  paymentsAggregate: PaymentAggregateSelection;
  paymentsConnection: PaymentsConnection;
  permissions: Array<Permission>;
  permissionsAggregate: PermissionAggregateSelection;
  permissionsConnection: PermissionsConnection;
  personalityTraits: Array<PersonalityTrait>;
  personalityTraitsAggregate: PersonalityTraitAggregateSelection;
  personalityTraitsConnection: PersonalityTraitsConnection;
  preferences: Array<Preferences>;
  preferencesAggregate: PreferencesAggregateSelection;
  preferencesConnection: PreferencesConnection;
  prerequisites: Array<Prerequisite>;
  prerequisitesAggregate: PrerequisiteAggregateSelection;
  prerequisitesConnection: PrerequisitesConnection;
  progresses: Array<Progress>;
  progressesAggregate: ProgressAggregateSelection;
  progressesConnection: ProgressesConnection;
  quarterlySummaries: Array<QuarterlySummary>;
  quarterlySummariesAggregate: QuarterlySummaryAggregateSelection;
  quarterlySummariesConnection: QuarterlySummariesConnection;
  rateLimitResults: Array<RateLimitResult>;
  rateLimitResultsAggregate: RateLimitResultAggregateSelection;
  rateLimitResultsConnection: RateLimitResultsConnection;
  recentCategories: Array<RecentCategory>;
  recentCategoriesAggregate: RecentCategoryAggregateSelection;
  recentCategoriesConnection: RecentCategoriesConnection;
  recentTodos?: Maybe<Array<Maybe<Todo>>>;
  recentTracks: Array<RecentTrack>;
  recentTracksAggregate: RecentTrackAggregateSelection;
  recentTracksConnection: RecentTracksConnection;
  recomendeds: Array<Recomended>;
  recomendedsAggregate: RecomendedAggregateSelection;
  recomendedsConnection: RecomendedsConnection;
  reflections: Array<Reflection>;
  reflectionsAggregate: ReflectionAggregateSelection;
  reflectionsConnection: ReflectionsConnection;
  refreshQuestResponses: Array<RefreshQuestResponse>;
  refreshQuestResponsesAggregate: RefreshQuestResponseAggregateSelection;
  refreshQuestResponsesConnection: RefreshQuestResponsesConnection;
  resources: Array<Resource>;
  resourcesAggregate: ResourceAggregateSelection;
  resourcesConnection: ResourcesConnection;
  responseTypes: Array<ResponseType>;
  responseTypesAggregate: ResponseTypeAggregateSelection;
  responseTypesConnection: ResponseTypesConnection;
  roles: Array<Role>;
  rolesAggregate: RoleAggregateSelection;
  rolesConnection: RolesConnection;
  schools: Array<School>;
  schoolsAggregate: SchoolAggregateSelection;
  schoolsConnection: SchoolsConnection;
  sessionResponses: Array<SessionResponse>;
  sessionResponsesAggregate: SessionResponseAggregateSelection;
  sessionResponsesConnection: SessionResponsesConnection;
  subchapterDetails: Array<SubchapterDetails>;
  subchapterDetailsAggregate: SubchapterDetailsAggregateSelection;
  subchapterDetailsConnection: SubchapterDetailsConnection;
  subchapters: Array<Subchapter>;
  subchaptersAggregate: SubchapterAggregateSelection;
  subchaptersConnection: SubchaptersConnection;
  subfields: Array<Subfield>;
  subfieldsAggregate: SubfieldAggregateSelection;
  subfieldsConnection: SubfieldsConnection;
  suggestions: Array<Suggestion>;
  suggestionsAggregate: SuggestionAggregateSelection;
  suggestionsConnection: SuggestionsConnection;
  tenantResponses: Array<TenantResponse>;
  tenantResponsesAggregate: TenantResponseAggregateSelection;
  tenantResponsesConnection: TenantResponsesConnection;
  tenants: Array<Tenant>;
  tenantsAggregate: TenantAggregateSelection;
  tenantsConnection: TenantsConnection;
  todos?: Maybe<Array<Maybe<Todo>>>;
  todosAggregate: TodoAggregateSelection;
  todosConnection: TodosConnection;
  toolAssignments: Array<ToolAssignment>;
  toolAssignmentsAggregate: ToolAssignmentAggregateSelection;
  toolAssignmentsConnection: ToolAssignmentsConnection;
  topics: Array<Topic>;
  topicsAggregate: TopicAggregateSelection;
  topicsConnection: TopicsConnection;
  trackOverviews: Array<TrackOverview>;
  trackOverviewsAggregate: TrackOverviewAggregateSelection;
  trackOverviewsConnection: TrackOverviewsConnection;
  transactionByDates: Array<TransactionByDate>;
  transactionByDatesAggregate: TransactionByDateAggregateSelection;
  transactionByDatesConnection: TransactionByDatesConnection;
  transactions: Array<Transaction>;
  transactionsAggregate: TransactionAggregateSelection;
  transactionsConnection: TransactionsConnection;
  updateAssignmentResponses: Array<UpdateAssignmentResponse>;
  updateAssignmentResponsesAggregate: UpdateAssignmentResponseAggregateSelection;
  updateAssignmentResponsesConnection: UpdateAssignmentResponsesConnection;
  updateContentModuleNameResponses: Array<UpdateContentModuleNameResponse>;
  updateContentModuleNameResponsesAggregate: UpdateContentModuleNameResponseAggregateSelection;
  updateContentModuleNameResponsesConnection: UpdateContentModuleNameResponsesConnection;
  updateUserModalOnboardingResponses: Array<UpdateUserModalOnboardingResponse>;
  updateUserModalOnboardingResponsesAggregate: UpdateUserModalOnboardingResponseAggregateSelection;
  updateUserModalOnboardingResponsesConnection: UpdateUserModalOnboardingResponsesConnection;
  uploadDocumentsResponses: Array<UploadDocumentsResponse>;
  uploadDocumentsResponsesAggregate: UploadDocumentsResponseAggregateSelection;
  uploadDocumentsResponsesConnection: UploadDocumentsResponsesConnection;
  uploadDocumentsToBucketResponses: Array<UploadDocumentsToBucketResponse>;
  uploadDocumentsToBucketResponsesAggregate: UploadDocumentsToBucketResponseAggregateSelection;
  uploadDocumentsToBucketResponsesConnection: UploadDocumentsToBucketResponsesConnection;
  userCareerLoadingResponses: Array<UserCareerLoadingResponse>;
  userCareerLoadingResponsesAggregate: UserCareerLoadingResponseAggregateSelection;
  userCareerLoadingResponsesConnection: UserCareerLoadingResponsesConnection;
  userCourseLoadingResponses: Array<UserCourseLoadingResponse>;
  userCourseLoadingResponsesAggregate: UserCourseLoadingResponseAggregateSelection;
  userCourseLoadingResponsesConnection: UserCourseLoadingResponsesConnection;
  userInviteCount?: Maybe<InviteCountResponse>;
  userMasteries: Array<UserMastery>;
  userMasteriesAggregate: UserMasteryAggregateSelection;
  userMasteriesConnection: UserMasteriesConnection;
  userProgresses: Array<UserProgress>;
  userProgressesAggregate: UserProgressAggregateSelection;
  userProgressesConnection: UserProgressesConnection;
  users: Array<User>;
  usersAggregate: UserAggregateSelection;
  usersConnection: UsersConnection;
  validateSession: SessionResponse;
  waterIntakeSummaries: Array<WaterIntakeSummary>;
  waterIntakeSummariesAggregate: WaterIntakeSummaryAggregateSelection;
  waterIntakeSummariesConnection: WaterIntakeSummariesConnection;
  waterIntakes: Array<WaterIntake>;
  waterIntakesAggregate: WaterIntakeAggregateSelection;
  waterIntakesConnection: WaterIntakesConnection;
  weeklySummaries: Array<WeeklySummary>;
  weeklySummariesAggregate: WeeklySummaryAggregateSelection;
  weeklySummariesConnection: WeeklySummariesConnection;
  whitelistedEmails: Array<WhitelistedEmail>;
  whitelistedEmailsAggregate: WhitelistedEmailAggregateSelection;
  whitelistedEmailsConnection: WhitelistedEmailsConnection;
  workouts: Array<Workout>;
  workoutsAggregate: WorkoutAggregateSelection;
  workoutsConnection: WorkoutsConnection;
  yearlySummaries: Array<YearlySummary>;
  yearlySummariesAggregate: YearlySummaryAggregateSelection;
  yearlySummariesConnection: YearlySummariesConnection;
};

export type QueryAchievementsArgs = {
  options?: InputMaybe<AchievementsOptions>;
  where?: InputMaybe<AchievementsWhere>;
};

export type QueryAchievementsAggregateArgs = {
  where?: InputMaybe<AchievementsWhere>;
};

export type QueryAchievementsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AchievementsSort>>>;
  where?: InputMaybe<AchievementsWhere>;
};

export type QueryActiveTracksArgs = {
  options?: InputMaybe<ActiveTrackOptions>;
  where?: InputMaybe<ActiveTrackWhere>;
};

export type QueryActiveTracksAggregateArgs = {
  where?: InputMaybe<ActiveTrackWhere>;
};

export type QueryActiveTracksConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ActiveTrackSort>>>;
  where?: InputMaybe<ActiveTrackWhere>;
};

export type QueryAddAssignmentResponsesArgs = {
  options?: InputMaybe<AddAssignmentResponseOptions>;
  where?: InputMaybe<AddAssignmentResponseWhere>;
};

export type QueryAddAssignmentResponsesAggregateArgs = {
  where?: InputMaybe<AddAssignmentResponseWhere>;
};

export type QueryAddAssignmentResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AddAssignmentResponseSort>>>;
  where?: InputMaybe<AddAssignmentResponseWhere>;
};

export type QueryAddContentModuleResponsesArgs = {
  options?: InputMaybe<AddContentModuleResponseOptions>;
  where?: InputMaybe<AddContentModuleResponseWhere>;
};

export type QueryAddContentModuleResponsesAggregateArgs = {
  where?: InputMaybe<AddContentModuleResponseWhere>;
};

export type QueryAddContentModuleResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AddContentModuleResponseSort>>>;
  where?: InputMaybe<AddContentModuleResponseWhere>;
};

export type QueryAspectDetailsTypesArgs = {
  options?: InputMaybe<AspectDetailsTypeOptions>;
  where?: InputMaybe<AspectDetailsTypeWhere>;
};

export type QueryAspectDetailsTypesAggregateArgs = {
  where?: InputMaybe<AspectDetailsTypeWhere>;
};

export type QueryAspectDetailsTypesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AspectDetailsTypeSort>>>;
  where?: InputMaybe<AspectDetailsTypeWhere>;
};

export type QueryAssignedQuestsArgs = {
  options?: InputMaybe<AssignedQuestOptions>;
  where?: InputMaybe<AssignedQuestWhere>;
};

export type QueryAssignedQuestsAggregateArgs = {
  where?: InputMaybe<AssignedQuestWhere>;
};

export type QueryAssignedQuestsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AssignedQuestSort>>>;
  where?: InputMaybe<AssignedQuestWhere>;
};

export type QueryAssignmentResponsesArgs = {
  options?: InputMaybe<AssignmentResponseOptions>;
  where?: InputMaybe<AssignmentResponseWhere>;
};

export type QueryAssignmentResponsesAggregateArgs = {
  where?: InputMaybe<AssignmentResponseWhere>;
};

export type QueryAssignmentResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AssignmentResponseSort>>>;
  where?: InputMaybe<AssignmentResponseWhere>;
};

export type QueryAssignmentsArgs = {
  options?: InputMaybe<AssignmentOptions>;
  where?: InputMaybe<AssignmentWhere>;
};

export type QueryAssignmentsAggregateArgs = {
  where?: InputMaybe<AssignmentWhere>;
};

export type QueryAssignmentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AssignmentSort>>>;
  where?: InputMaybe<AssignmentWhere>;
};

export type QueryAttributesArgs = {
  options?: InputMaybe<AttributeOptions>;
  where?: InputMaybe<AttributeWhere>;
};

export type QueryAttributesAggregateArgs = {
  where?: InputMaybe<AttributeWhere>;
};

export type QueryAttributesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AttributeSort>>>;
  where?: InputMaybe<AttributeWhere>;
};

export type QueryAudioToggleUserResponsesArgs = {
  options?: InputMaybe<AudioToggleUserResponseOptions>;
  where?: InputMaybe<AudioToggleUserResponseWhere>;
};

export type QueryAudioToggleUserResponsesAggregateArgs = {
  where?: InputMaybe<AudioToggleUserResponseWhere>;
};

export type QueryAudioToggleUserResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AudioToggleUserResponseSort>>>;
  where?: InputMaybe<AudioToggleUserResponseWhere>;
};

export type QueryAuthPayloadResponsesArgs = {
  options?: InputMaybe<AuthPayloadResponseOptions>;
  where?: InputMaybe<AuthPayloadResponseWhere>;
};

export type QueryAuthPayloadResponsesAggregateArgs = {
  where?: InputMaybe<AuthPayloadResponseWhere>;
};

export type QueryAuthPayloadResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AuthPayloadResponseSort>>>;
  where?: InputMaybe<AuthPayloadResponseWhere>;
};

export type QueryAuthPayloadUserPreferencesArgs = {
  options?: InputMaybe<AuthPayloadUserPreferencesOptions>;
  where?: InputMaybe<AuthPayloadUserPreferencesWhere>;
};

export type QueryAuthPayloadUserPreferencesAggregateArgs = {
  where?: InputMaybe<AuthPayloadUserPreferencesWhere>;
};

export type QueryAuthPayloadUserPreferencesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AuthPayloadUserPreferencesSort>>>;
  where?: InputMaybe<AuthPayloadUserPreferencesWhere>;
};

export type QueryAuthPayloadUsersArgs = {
  options?: InputMaybe<AuthPayloadUserOptions>;
  where?: InputMaybe<AuthPayloadUserWhere>;
};

export type QueryAuthPayloadUsersAggregateArgs = {
  where?: InputMaybe<AuthPayloadUserWhere>;
};

export type QueryAuthPayloadUsersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<AuthPayloadUserSort>>>;
  where?: InputMaybe<AuthPayloadUserWhere>;
};

export type QueryCareerHeadersArgs = {
  options?: InputMaybe<CareerHeaderOptions>;
  where?: InputMaybe<CareerHeaderWhere>;
};

export type QueryCareerHeadersAggregateArgs = {
  where?: InputMaybe<CareerHeaderWhere>;
};

export type QueryCareerHeadersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CareerHeaderSort>>>;
  where?: InputMaybe<CareerHeaderWhere>;
};

export type QueryCareerTopicResponsesArgs = {
  options?: InputMaybe<CareerTopicResponseOptions>;
  where?: InputMaybe<CareerTopicResponseWhere>;
};

export type QueryCareerTopicResponsesAggregateArgs = {
  where?: InputMaybe<CareerTopicResponseWhere>;
};

export type QueryCareerTopicResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CareerTopicResponseSort>>>;
  where?: InputMaybe<CareerTopicResponseWhere>;
};

export type QueryCareersArgs = {
  options?: InputMaybe<CareerOptions>;
  where?: InputMaybe<CareerWhere>;
};

export type QueryCareersAggregateArgs = {
  where?: InputMaybe<CareerWhere>;
};

export type QueryCareersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CareerSort>>>;
  where?: InputMaybe<CareerWhere>;
};

export type QueryChallengesArgs = {
  options?: InputMaybe<ChallengeOptions>;
  where?: InputMaybe<ChallengeWhere>;
};

export type QueryChallengesAggregateArgs = {
  where?: InputMaybe<ChallengeWhere>;
};

export type QueryChallengesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ChallengeSort>>>;
  where?: InputMaybe<ChallengeWhere>;
};

export type QueryChaptersArgs = {
  options?: InputMaybe<ChapterOptions>;
  where?: InputMaybe<ChapterWhere>;
};

export type QueryChaptersAggregateArgs = {
  where?: InputMaybe<ChapterWhere>;
};

export type QueryChaptersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ChapterSort>>>;
  where?: InputMaybe<ChapterWhere>;
};

export type QueryChatsArgs = {
  options?: InputMaybe<ChatOptions>;
  where?: InputMaybe<ChatWhere>;
};

export type QueryChatsAggregateArgs = {
  where?: InputMaybe<ChatWhere>;
};

export type QueryChatsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ChatSort>>>;
  where?: InputMaybe<ChatWhere>;
};

export type QueryChildInfosArgs = {
  options?: InputMaybe<ChildInfoOptions>;
  where?: InputMaybe<ChildInfoWhere>;
};

export type QueryChildInfosAggregateArgs = {
  where?: InputMaybe<ChildInfoWhere>;
};

export type QueryChildInfosConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ChildInfoSort>>>;
  where?: InputMaybe<ChildInfoWhere>;
};

export type QueryCoreValuesArgs = {
  options?: InputMaybe<CoreValueOptions>;
  where?: InputMaybe<CoreValueWhere>;
};

export type QueryCoreValuesAggregateArgs = {
  where?: InputMaybe<CoreValueWhere>;
};

export type QueryCoreValuesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CoreValueSort>>>;
  where?: InputMaybe<CoreValueWhere>;
};

export type QueryCourseCategoriesArgs = {
  options?: InputMaybe<CourseCategoryOptions>;
  where?: InputMaybe<CourseCategoryWhere>;
};

export type QueryCourseCategoriesAggregateArgs = {
  where?: InputMaybe<CourseCategoryWhere>;
};

export type QueryCourseCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseCategorySort>>>;
  where?: InputMaybe<CourseCategoryWhere>;
};

export type QueryCourseCreationResponsesArgs = {
  options?: InputMaybe<CourseCreationResponseOptions>;
  where?: InputMaybe<CourseCreationResponseWhere>;
};

export type QueryCourseCreationResponsesAggregateArgs = {
  where?: InputMaybe<CourseCreationResponseWhere>;
};

export type QueryCourseCreationResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseCreationResponseSort>>>;
  where?: InputMaybe<CourseCreationResponseWhere>;
};

export type QueryCourseResponsesArgs = {
  options?: InputMaybe<CourseResponseOptions>;
  where?: InputMaybe<CourseResponseWhere>;
};

export type QueryCourseResponsesAggregateArgs = {
  where?: InputMaybe<CourseResponseWhere>;
};

export type QueryCourseResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseResponseSort>>>;
  where?: InputMaybe<CourseResponseWhere>;
};

export type QueryCoursesArgs = {
  options?: InputMaybe<CourseOptions>;
  where?: InputMaybe<CourseWhere>;
};

export type QueryCoursesAggregateArgs = {
  where?: InputMaybe<CourseWhere>;
};

export type QueryCoursesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CourseSort>>>;
  where?: InputMaybe<CourseWhere>;
};

export type QueryCurriculaArgs = {
  options?: InputMaybe<CurriculumOptions>;
  where?: InputMaybe<CurriculumWhere>;
};

export type QueryCurriculaAggregateArgs = {
  where?: InputMaybe<CurriculumWhere>;
};

export type QueryCurriculaConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<CurriculumSort>>>;
  where?: InputMaybe<CurriculumWhere>;
};

export type QueryDailyCompletedTodosCountsArgs = {
  options?: InputMaybe<DailyCompletedTodosCountOptions>;
  where?: InputMaybe<DailyCompletedTodosCountWhere>;
};

export type QueryDailyCompletedTodosCountsAggregateArgs = {
  where?: InputMaybe<DailyCompletedTodosCountWhere>;
};

export type QueryDailyCompletedTodosCountsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DailyCompletedTodosCountSort>>>;
  where?: InputMaybe<DailyCompletedTodosCountWhere>;
};

export type QueryDailyExerciseTotalsArgs = {
  options?: InputMaybe<DailyExerciseTotalsOptions>;
  where?: InputMaybe<DailyExerciseTotalsWhere>;
};

export type QueryDailyExerciseTotalsAggregateArgs = {
  where?: InputMaybe<DailyExerciseTotalsWhere>;
};

export type QueryDailyExerciseTotalsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DailyExerciseTotalsSort>>>;
  where?: InputMaybe<DailyExerciseTotalsWhere>;
};

export type QueryDailyNutritionTotalsArgs = {
  options?: InputMaybe<DailyNutritionTotalsOptions>;
  where?: InputMaybe<DailyNutritionTotalsWhere>;
};

export type QueryDailyNutritionTotalsAggregateArgs = {
  where?: InputMaybe<DailyNutritionTotalsWhere>;
};

export type QueryDailyNutritionTotalsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DailyNutritionTotalsSort>>>;
  where?: InputMaybe<DailyNutritionTotalsWhere>;
};

export type QueryDailySummariesArgs = {
  options?: InputMaybe<DailySummaryOptions>;
  where?: InputMaybe<DailySummaryWhere>;
};

export type QueryDailySummariesAggregateArgs = {
  where?: InputMaybe<DailySummaryWhere>;
};

export type QueryDailySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DailySummarySort>>>;
  where?: InputMaybe<DailySummaryWhere>;
};

export type QueryDeleteContentAssignmentResponsesArgs = {
  options?: InputMaybe<DeleteContentAssignmentResponseOptions>;
  where?: InputMaybe<DeleteContentAssignmentResponseWhere>;
};

export type QueryDeleteContentAssignmentResponsesAggregateArgs = {
  where?: InputMaybe<DeleteContentAssignmentResponseWhere>;
};

export type QueryDeleteContentAssignmentResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DeleteContentAssignmentResponseSort>>>;
  where?: InputMaybe<DeleteContentAssignmentResponseWhere>;
};

export type QueryDeleteContentAssignmentVariablesArgs = {
  options?: InputMaybe<DeleteContentAssignmentVariablesOptions>;
  where?: InputMaybe<DeleteContentAssignmentVariablesWhere>;
};

export type QueryDeleteContentAssignmentVariablesAggregateArgs = {
  where?: InputMaybe<DeleteContentAssignmentVariablesWhere>;
};

export type QueryDeleteContentAssignmentVariablesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DeleteContentAssignmentVariablesSort>>>;
  where?: InputMaybe<DeleteContentAssignmentVariablesWhere>;
};

export type QueryDeleteContentModuleResponsesArgs = {
  options?: InputMaybe<DeleteContentModuleResponseOptions>;
  where?: InputMaybe<DeleteContentModuleResponseWhere>;
};

export type QueryDeleteContentModuleResponsesAggregateArgs = {
  where?: InputMaybe<DeleteContentModuleResponseWhere>;
};

export type QueryDeleteContentModuleResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DeleteContentModuleResponseSort>>>;
  where?: InputMaybe<DeleteContentModuleResponseWhere>;
};

export type QueryDeleteContentModuleVariablesArgs = {
  options?: InputMaybe<DeleteContentModuleVariablesOptions>;
  where?: InputMaybe<DeleteContentModuleVariablesWhere>;
};

export type QueryDeleteContentModuleVariablesAggregateArgs = {
  where?: InputMaybe<DeleteContentModuleVariablesWhere>;
};

export type QueryDeleteContentModuleVariablesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DeleteContentModuleVariablesSort>>>;
  where?: InputMaybe<DeleteContentModuleVariablesWhere>;
};

export type QueryDeleteCourseResponsesArgs = {
  options?: InputMaybe<DeleteCourseResponseOptions>;
  where?: InputMaybe<DeleteCourseResponseWhere>;
};

export type QueryDeleteCourseResponsesAggregateArgs = {
  where?: InputMaybe<DeleteCourseResponseWhere>;
};

export type QueryDeleteCourseResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DeleteCourseResponseSort>>>;
  where?: InputMaybe<DeleteCourseResponseWhere>;
};

export type QueryDeviceSessionsArgs = {
  options?: InputMaybe<DeviceSessionOptions>;
  where?: InputMaybe<DeviceSessionWhere>;
};

export type QueryDeviceSessionsAggregateArgs = {
  where?: InputMaybe<DeviceSessionWhere>;
};

export type QueryDeviceSessionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<DeviceSessionSort>>>;
  where?: InputMaybe<DeviceSessionWhere>;
};

export type QueryEmotionalStatesArgs = {
  options?: InputMaybe<EmotionalStateOptions>;
  where?: InputMaybe<EmotionalStateWhere>;
};

export type QueryEmotionalStatesAggregateArgs = {
  where?: InputMaybe<EmotionalStateWhere>;
};

export type QueryEmotionalStatesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<EmotionalStateSort>>>;
  where?: InputMaybe<EmotionalStateWhere>;
};

export type QueryEventsArgs = {
  options?: InputMaybe<EventOptions>;
  where?: InputMaybe<EventWhere>;
};

export type QueryEventsAggregateArgs = {
  where?: InputMaybe<EventWhere>;
};

export type QueryEventsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<EventSort>>>;
  where?: InputMaybe<EventWhere>;
};

export type QueryExportCsvResponsesArgs = {
  options?: InputMaybe<ExportCsvResponseOptions>;
  where?: InputMaybe<ExportCsvResponseWhere>;
};

export type QueryExportCsvResponsesAggregateArgs = {
  where?: InputMaybe<ExportCsvResponseWhere>;
};

export type QueryExportCsvResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ExportCsvResponseSort>>>;
  where?: InputMaybe<ExportCsvResponseWhere>;
};

export type QueryFeedbacksArgs = {
  options?: InputMaybe<FeedbackOptions>;
  where?: InputMaybe<FeedbackWhere>;
};

export type QueryFeedbacksAggregateArgs = {
  where?: InputMaybe<FeedbackWhere>;
};

export type QueryFeedbacksConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FeedbackSort>>>;
  where?: InputMaybe<FeedbackWhere>;
};

export type QueryFieldsArgs = {
  options?: InputMaybe<FieldOptions>;
  where?: InputMaybe<FieldWhere>;
};

export type QueryFieldsAggregateArgs = {
  where?: InputMaybe<FieldWhere>;
};

export type QueryFieldsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FieldSort>>>;
  where?: InputMaybe<FieldWhere>;
};

export type QueryFilesArgs = {
  options?: InputMaybe<FileOptions>;
  where?: InputMaybe<FileWhere>;
};

export type QueryFilesAggregateArgs = {
  where?: InputMaybe<FileWhere>;
};

export type QueryFilesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FileSort>>>;
  where?: InputMaybe<FileWhere>;
};

export type QueryFoodItemsArgs = {
  options?: InputMaybe<FoodItemOptions>;
  where?: InputMaybe<FoodItemWhere>;
};

export type QueryFoodItemsAggregateArgs = {
  where?: InputMaybe<FoodItemWhere>;
};

export type QueryFoodItemsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FoodItemSort>>>;
  where?: InputMaybe<FoodItemWhere>;
};

export type QueryFoodsArgs = {
  nutritionId: Scalars["String"]["input"];
};

export type QueryFoodsAggregateArgs = {
  where?: InputMaybe<FoodWhere>;
};

export type QueryFoodsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<FoodSort>>>;
  where?: InputMaybe<FoodWhere>;
};

export type QueryGamesArgs = {
  options?: InputMaybe<GameOptions>;
  where?: InputMaybe<GameWhere>;
};

export type QueryGamesAggregateArgs = {
  where?: InputMaybe<GameWhere>;
};

export type QueryGamesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GameSort>>>;
  where?: InputMaybe<GameWhere>;
};

export type QueryGeneratedsArgs = {
  options?: InputMaybe<GeneratedOptions>;
  where?: InputMaybe<GeneratedWhere>;
};

export type QueryGeneratedsAggregateArgs = {
  where?: InputMaybe<GeneratedWhere>;
};

export type QueryGeneratedsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GeneratedSort>>>;
  where?: InputMaybe<GeneratedWhere>;
};

export type QueryGetAllAssignmentsByResourcesArgs = {
  resourceIds: Array<Scalars["ID"]["input"]>;
};

export type QueryGetAllCoursesBySchoolArgs = {
  schoolId: Scalars["ID"]["input"];
};

export type QueryGetAllModulesByCourseArgs = {
  courseId: Scalars["ID"]["input"];
};

export type QueryGetAllPagesByResourcesArgs = {
  resourceIds: Array<Scalars["ID"]["input"]>;
};

export type QueryGetAssignmentArgs = {
  assignmentId: Scalars["ID"]["input"];
};

export type QueryGetAssignmentObjectiveResponsesArgs = {
  options?: InputMaybe<GetAssignmentObjectiveResponseOptions>;
  where?: InputMaybe<GetAssignmentObjectiveResponseWhere>;
};

export type QueryGetAssignmentObjectiveResponsesAggregateArgs = {
  where?: InputMaybe<GetAssignmentObjectiveResponseWhere>;
};

export type QueryGetAssignmentObjectiveResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GetAssignmentObjectiveResponseSort>>>;
  where?: InputMaybe<GetAssignmentObjectiveResponseWhere>;
};

export type QueryGetAssignmentResponsesArgs = {
  options?: InputMaybe<GetAssignmentResponseOptions>;
  where?: InputMaybe<GetAssignmentResponseWhere>;
};

export type QueryGetAssignmentResponsesAggregateArgs = {
  where?: InputMaybe<GetAssignmentResponseWhere>;
};

export type QueryGetAssignmentResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GetAssignmentResponseSort>>>;
  where?: InputMaybe<GetAssignmentResponseWhere>;
};

export type QueryGetCareerByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetCareerTopicsArgs = {
  careerId: Scalars["ID"]["input"];
};

export type QueryGetChaptersArgs = {
  topicName: Scalars["String"]["input"];
};

export type QueryGetChatByContextAndParamsArgs = {
  context: Scalars["String"]["input"];
  params?: InputMaybe<Scalars["JSON"]["input"]>;
};

export type QueryGetChatByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetChatByObjectIdArgs = {
  context: Scalars["String"]["input"];
  objectId: Scalars["ID"]["input"];
};

export type QueryGetChatForDateArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetContentByIdArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetCourseArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetCustomAssignmentArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetDailyCompletedTodosArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetDailyExerciseTotalsByDateArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetDailyNutritionTotalsByDateArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetFirstSubchapterByTopicNameArgs = {
  topicName: Scalars["String"]["input"];
};

export type QueryGetFoodsByDateArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetJourneyByIdArgs = {
  journeyId: Scalars["ID"]["input"];
};

export type QueryGetJourneyByIdResponsesArgs = {
  options?: InputMaybe<GetJourneyByIdResponseOptions>;
  where?: InputMaybe<GetJourneyByIdResponseWhere>;
};

export type QueryGetJourneyByIdResponsesAggregateArgs = {
  where?: InputMaybe<GetJourneyByIdResponseWhere>;
};

export type QueryGetJourneyByIdResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GetJourneyByIdResponseSort>>>;
  where?: InputMaybe<GetJourneyByIdResponseWhere>;
};

export type QueryGetJourneysResponsesArgs = {
  options?: InputMaybe<GetJourneysResponseOptions>;
  where?: InputMaybe<GetJourneysResponseWhere>;
};

export type QueryGetJourneysResponsesAggregateArgs = {
  where?: InputMaybe<GetJourneysResponseWhere>;
};

export type QueryGetJourneysResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GetJourneysResponseSort>>>;
  where?: InputMaybe<GetJourneysResponseWhere>;
};

export type QueryGetLearnedSubchaptersByDateArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetLearningObjectiveByIdArgs = {
  loId: Scalars["ID"]["input"];
};

export type QueryGetLearningObjectivesBySubchapterArgs = {
  learningObjectiveId: Scalars["ID"]["input"];
};

export type QueryGetLearningTreeArgs = {
  level: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
};

export type QueryGetModuleArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetNodeArgs = {
  label: NodeLabel;
  name: Scalars["String"]["input"];
};

export type QueryGetNodeChildrenArgs = {
  label: NodeLabel;
  name: Scalars["String"]["input"];
};

export type QueryGetNodeParentsArgs = {
  label: NodeLabel;
  name: Scalars["String"]["input"];
};

export type QueryGetPageArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetRecentTracksArgs = {
  filterByCategory?: InputMaybe<Scalars["String"]["input"]>;
};

export type QueryGetSiblingSubchaptersArgs = {
  chapterName: Scalars["String"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  subchapterName: Scalars["String"]["input"];
};

export type QueryGetSubchapterByIdArgs = {
  name: Scalars["String"]["input"];
};

export type QueryGetSubchapterPrerequisitesArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  subchapterName: Scalars["String"]["input"];
};

export type QueryGetSubchaptersArgs = {
  chapterName: Scalars["String"]["input"];
};

export type QueryGetSubfieldsArgs = {
  fieldName: Scalars["String"]["input"];
};

export type QueryGetTableOfContentsArgs = {
  hierarchyLabel: Scalars["String"]["input"];
  learningNodeNames: Array<InputMaybe<Scalars["String"]["input"]>>;
};

export type QueryGetTenantArgs = {
  id: Scalars["ID"]["input"];
};

export type QueryGetTodayChatArgs = {
  timezone: Scalars["String"]["input"];
};

export type QueryGetTodayGameArgs = {
  timezone: Scalars["String"]["input"];
};

export type QueryGetTopicsArgs = {
  subfieldName: Scalars["String"]["input"];
};

export type QueryGetTrackOverviewArgs = {
  topicName: Scalars["String"]["input"];
};

export type QueryGetTransactionHistoryArgs = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
};

export type QueryGetTransactionHistoryForDateArgs = {
  date: Scalars["String"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  skip?: InputMaybe<Scalars["Int"]["input"]>;
  timezone: Scalars["String"]["input"];
};

export type QueryGetUserDailySummariesArgs = {
  timeRange: Scalars["String"]["input"];
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserJournalEntryArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGetUserLearningObjectiveMasteryArgs = {
  learningObjectiveId: Scalars["ID"]["input"];
};

export type QueryGetUserMasteryArgs = {
  nodeLabel: NodeLabel;
  nodeName: Scalars["String"]["input"];
  userId: Scalars["ID"]["input"];
};

export type QueryGetUserProgressArgs = {
  nodeLabel: NodeLabel;
  nodeName: Scalars["String"]["input"];
};

export type QueryGetWaterIntakeByDateArgs = {
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
};

export type QueryGoalsArgs = {
  options?: InputMaybe<GoalOptions>;
  where?: InputMaybe<GoalWhere>;
};

export type QueryGoalsAggregateArgs = {
  where?: InputMaybe<GoalWhere>;
};

export type QueryGoalsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GoalSort>>>;
  where?: InputMaybe<GoalWhere>;
};

export type QueryGoogleAuthTokensArgs = {
  options?: InputMaybe<GoogleAuthTokensOptions>;
  where?: InputMaybe<GoogleAuthTokensWhere>;
};

export type QueryGoogleAuthTokensAggregateArgs = {
  where?: InputMaybe<GoogleAuthTokensWhere>;
};

export type QueryGoogleAuthTokensConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<GoogleAuthTokensSort>>>;
  where?: InputMaybe<GoogleAuthTokensWhere>;
};

export type QueryHierarchiesArgs = {
  options?: InputMaybe<HierarchyOptions>;
  where?: InputMaybe<HierarchyWhere>;
};

export type QueryHierarchiesAggregateArgs = {
  where?: InputMaybe<HierarchyWhere>;
};

export type QueryHierarchiesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<HierarchySort>>>;
  where?: InputMaybe<HierarchyWhere>;
};

export type QueryHierarchyNodesArgs = {
  options?: InputMaybe<HierarchyNodeOptions>;
  where?: InputMaybe<HierarchyNodeWhere>;
};

export type QueryHierarchyNodesAggregateArgs = {
  where?: InputMaybe<HierarchyNodeWhere>;
};

export type QueryHierarchyNodesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<HierarchyNodeSort>>>;
  where?: InputMaybe<HierarchyNodeWhere>;
};

export type QueryHobbiesArgs = {
  options?: InputMaybe<HobbyOptions>;
  where?: InputMaybe<HobbyWhere>;
};

export type QueryHobbiesAggregateArgs = {
  where?: InputMaybe<HobbyWhere>;
};

export type QueryHobbiesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<HobbySort>>>;
  where?: InputMaybe<HobbyWhere>;
};

export type QueryInterestsArgs = {
  options?: InputMaybe<InterestOptions>;
  where?: InputMaybe<InterestWhere>;
};

export type QueryInterestsAggregateArgs = {
  where?: InputMaybe<InterestWhere>;
};

export type QueryInterestsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<InterestSort>>>;
  where?: InputMaybe<InterestWhere>;
};

export type QueryInviteCountResponsesArgs = {
  options?: InputMaybe<InviteCountResponseOptions>;
  where?: InputMaybe<InviteCountResponseWhere>;
};

export type QueryInviteCountResponsesAggregateArgs = {
  where?: InputMaybe<InviteCountResponseWhere>;
};

export type QueryInviteCountResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<InviteCountResponseSort>>>;
  where?: InputMaybe<InviteCountResponseWhere>;
};

export type QueryJourneysArgs = {
  options?: InputMaybe<JourneyOptions>;
  where?: InputMaybe<JourneyWhere>;
};

export type QueryJourneysAggregateArgs = {
  where?: InputMaybe<JourneyWhere>;
};

export type QueryJourneysConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<JourneySort>>>;
  where?: InputMaybe<JourneyWhere>;
};

export type QueryLearningNodesArgs = {
  options?: InputMaybe<LearningNodeOptions>;
  where?: InputMaybe<LearningNodeWhere>;
};

export type QueryLearningNodesAggregateArgs = {
  where?: InputMaybe<LearningNodeWhere>;
};

export type QueryLearningNodesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<LearningNodeSort>>>;
  where?: InputMaybe<LearningNodeWhere>;
};

export type QueryLearningObjectiveResponsesArgs = {
  options?: InputMaybe<LearningObjectiveResponseOptions>;
  where?: InputMaybe<LearningObjectiveResponseWhere>;
};

export type QueryLearningObjectiveResponsesAggregateArgs = {
  where?: InputMaybe<LearningObjectiveResponseWhere>;
};

export type QueryLearningObjectiveResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<LearningObjectiveResponseSort>>>;
  where?: InputMaybe<LearningObjectiveResponseWhere>;
};

export type QueryLearningObjectivesArgs = {
  options?: InputMaybe<LearningObjectiveOptions>;
  where?: InputMaybe<LearningObjectiveWhere>;
};

export type QueryLearningObjectivesAggregateArgs = {
  where?: InputMaybe<LearningObjectiveWhere>;
};

export type QueryLearningObjectivesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<LearningObjectiveSort>>>;
  where?: InputMaybe<LearningObjectiveWhere>;
};

export type QueryLearningSummariesArgs = {
  options?: InputMaybe<LearningSummaryOptions>;
  where?: InputMaybe<LearningSummaryWhere>;
};

export type QueryLearningSummariesAggregateArgs = {
  where?: InputMaybe<LearningSummaryWhere>;
};

export type QueryLearningSummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<LearningSummarySort>>>;
  where?: InputMaybe<LearningSummaryWhere>;
};

export type QueryLearningTreesArgs = {
  options?: InputMaybe<LearningTreeOptions>;
  where?: InputMaybe<LearningTreeWhere>;
};

export type QueryLearningTreesAggregateArgs = {
  where?: InputMaybe<LearningTreeWhere>;
};

export type QueryLearningTreesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<LearningTreeSort>>>;
  where?: InputMaybe<LearningTreeWhere>;
};

export type QueryLessonsArgs = {
  options?: InputMaybe<LessonOptions>;
  where?: InputMaybe<LessonWhere>;
};

export type QueryLessonsAggregateArgs = {
  where?: InputMaybe<LessonWhere>;
};

export type QueryLessonsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<LessonSort>>>;
  where?: InputMaybe<LessonWhere>;
};

export type QueryLevelDetailsArgs = {
  options?: InputMaybe<LevelDetailsOptions>;
  where?: InputMaybe<LevelDetailsWhere>;
};

export type QueryLevelDetailsAggregateArgs = {
  where?: InputMaybe<LevelDetailsWhere>;
};

export type QueryLevelDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<LevelDetailsSort>>>;
  where?: InputMaybe<LevelDetailsWhere>;
};

export type QueryLifeAspirationsArgs = {
  options?: InputMaybe<LifeAspirationOptions>;
  where?: InputMaybe<LifeAspirationWhere>;
};

export type QueryLifeAspirationsAggregateArgs = {
  where?: InputMaybe<LifeAspirationWhere>;
};

export type QueryLifeAspirationsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<LifeAspirationSort>>>;
  where?: InputMaybe<LifeAspirationWhere>;
};

export type QueryMasteryResultsArgs = {
  options?: InputMaybe<MasteryResultOptions>;
  where?: InputMaybe<MasteryResultWhere>;
};

export type QueryMasteryResultsAggregateArgs = {
  where?: InputMaybe<MasteryResultWhere>;
};

export type QueryMasteryResultsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<MasteryResultSort>>>;
  where?: InputMaybe<MasteryResultWhere>;
};

export type QueryMeResponsesArgs = {
  options?: InputMaybe<MeResponseOptions>;
  where?: InputMaybe<MeResponseWhere>;
};

export type QueryMeResponsesAggregateArgs = {
  where?: InputMaybe<MeResponseWhere>;
};

export type QueryMeResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<MeResponseSort>>>;
  where?: InputMaybe<MeResponseWhere>;
};

export type QueryMessagesArgs = {
  options?: InputMaybe<MessageOptions>;
  where?: InputMaybe<MessageWhere>;
};

export type QueryMessagesAggregateArgs = {
  where?: InputMaybe<MessageWhere>;
};

export type QueryMessagesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<MessageSort>>>;
  where?: InputMaybe<MessageWhere>;
};

export type QueryModuleResponsesArgs = {
  options?: InputMaybe<ModuleResponseOptions>;
  where?: InputMaybe<ModuleResponseWhere>;
};

export type QueryModuleResponsesAggregateArgs = {
  where?: InputMaybe<ModuleResponseWhere>;
};

export type QueryModuleResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ModuleResponseSort>>>;
  where?: InputMaybe<ModuleResponseWhere>;
};

export type QueryModulesArgs = {
  options?: InputMaybe<ModuleOptions>;
  where?: InputMaybe<ModuleWhere>;
};

export type QueryModulesAggregateArgs = {
  where?: InputMaybe<ModuleWhere>;
};

export type QueryModulesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ModuleSort>>>;
  where?: InputMaybe<ModuleWhere>;
};

export type QueryMonthlySummariesArgs = {
  options?: InputMaybe<MonthlySummaryOptions>;
  where?: InputMaybe<MonthlySummaryWhere>;
};

export type QueryMonthlySummariesAggregateArgs = {
  where?: InputMaybe<MonthlySummaryWhere>;
};

export type QueryMonthlySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<MonthlySummarySort>>>;
  where?: InputMaybe<MonthlySummaryWhere>;
};

export type QueryNodeIdentitiesArgs = {
  options?: InputMaybe<NodeIdentityOptions>;
  where?: InputMaybe<NodeIdentityWhere>;
};

export type QueryNodeIdentitiesAggregateArgs = {
  where?: InputMaybe<NodeIdentityWhere>;
};

export type QueryNodeIdentitiesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<NodeIdentitySort>>>;
  where?: InputMaybe<NodeIdentityWhere>;
};

export type QueryNodePropertiesArgs = {
  options?: InputMaybe<NodePropertiesOptions>;
  where?: InputMaybe<NodePropertiesWhere>;
};

export type QueryNodePropertiesAggregateArgs = {
  where?: InputMaybe<NodePropertiesWhere>;
};

export type QueryNodePropertiesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<NodePropertiesSort>>>;
  where?: InputMaybe<NodePropertiesWhere>;
};

export type QueryNutritionByDateArgs = {
  date: Scalars["String"]["input"];
};

export type QueryNutritionsArgs = {
  options?: InputMaybe<NutritionOptions>;
  where?: InputMaybe<NutritionWhere>;
};

export type QueryNutritionsAggregateArgs = {
  where?: InputMaybe<NutritionWhere>;
};

export type QueryNutritionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<NutritionSort>>>;
  where?: InputMaybe<NutritionWhere>;
};

export type QueryObjectivesArgs = {
  options?: InputMaybe<ObjectiveOptions>;
  where?: InputMaybe<ObjectiveWhere>;
};

export type QueryObjectivesAggregateArgs = {
  where?: InputMaybe<ObjectiveWhere>;
};

export type QueryObjectivesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ObjectiveSort>>>;
  where?: InputMaybe<ObjectiveWhere>;
};

export type QueryPageResponsesArgs = {
  options?: InputMaybe<PageResponseOptions>;
  where?: InputMaybe<PageResponseWhere>;
};

export type QueryPageResponsesAggregateArgs = {
  where?: InputMaybe<PageResponseWhere>;
};

export type QueryPageResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<PageResponseSort>>>;
  where?: InputMaybe<PageResponseWhere>;
};

export type QueryPagesArgs = {
  options?: InputMaybe<PageOptions>;
  where?: InputMaybe<PageWhere>;
};

export type QueryPagesAggregateArgs = {
  where?: InputMaybe<PageWhere>;
};

export type QueryPagesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<PageSort>>>;
  where?: InputMaybe<PageWhere>;
};

export type QueryParametersTypesArgs = {
  options?: InputMaybe<ParametersTypeOptions>;
  where?: InputMaybe<ParametersTypeWhere>;
};

export type QueryParametersTypesAggregateArgs = {
  where?: InputMaybe<ParametersTypeWhere>;
};

export type QueryParametersTypesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ParametersTypeSort>>>;
  where?: InputMaybe<ParametersTypeWhere>;
};

export type QueryPaymentsArgs = {
  options?: InputMaybe<PaymentOptions>;
  where?: InputMaybe<PaymentWhere>;
};

export type QueryPaymentsAggregateArgs = {
  where?: InputMaybe<PaymentWhere>;
};

export type QueryPaymentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<PaymentSort>>>;
  where?: InputMaybe<PaymentWhere>;
};

export type QueryPermissionsArgs = {
  options?: InputMaybe<PermissionOptions>;
  where?: InputMaybe<PermissionWhere>;
};

export type QueryPermissionsAggregateArgs = {
  where?: InputMaybe<PermissionWhere>;
};

export type QueryPermissionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<PermissionSort>>>;
  where?: InputMaybe<PermissionWhere>;
};

export type QueryPersonalityTraitsArgs = {
  options?: InputMaybe<PersonalityTraitOptions>;
  where?: InputMaybe<PersonalityTraitWhere>;
};

export type QueryPersonalityTraitsAggregateArgs = {
  where?: InputMaybe<PersonalityTraitWhere>;
};

export type QueryPersonalityTraitsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<PersonalityTraitSort>>>;
  where?: InputMaybe<PersonalityTraitWhere>;
};

export type QueryPreferencesArgs = {
  options?: InputMaybe<PreferencesOptions>;
  where?: InputMaybe<PreferencesWhere>;
};

export type QueryPreferencesAggregateArgs = {
  where?: InputMaybe<PreferencesWhere>;
};

export type QueryPreferencesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<PreferencesSort>>>;
  where?: InputMaybe<PreferencesWhere>;
};

export type QueryPrerequisitesArgs = {
  options?: InputMaybe<PrerequisiteOptions>;
  where?: InputMaybe<PrerequisiteWhere>;
};

export type QueryPrerequisitesAggregateArgs = {
  where?: InputMaybe<PrerequisiteWhere>;
};

export type QueryPrerequisitesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<PrerequisiteSort>>>;
  where?: InputMaybe<PrerequisiteWhere>;
};

export type QueryProgressesArgs = {
  options?: InputMaybe<ProgressOptions>;
  where?: InputMaybe<ProgressWhere>;
};

export type QueryProgressesAggregateArgs = {
  where?: InputMaybe<ProgressWhere>;
};

export type QueryProgressesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ProgressSort>>>;
  where?: InputMaybe<ProgressWhere>;
};

export type QueryQuarterlySummariesArgs = {
  options?: InputMaybe<QuarterlySummaryOptions>;
  where?: InputMaybe<QuarterlySummaryWhere>;
};

export type QueryQuarterlySummariesAggregateArgs = {
  where?: InputMaybe<QuarterlySummaryWhere>;
};

export type QueryQuarterlySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<QuarterlySummarySort>>>;
  where?: InputMaybe<QuarterlySummaryWhere>;
};

export type QueryRateLimitResultsArgs = {
  options?: InputMaybe<RateLimitResultOptions>;
  where?: InputMaybe<RateLimitResultWhere>;
};

export type QueryRateLimitResultsAggregateArgs = {
  where?: InputMaybe<RateLimitResultWhere>;
};

export type QueryRateLimitResultsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<RateLimitResultSort>>>;
  where?: InputMaybe<RateLimitResultWhere>;
};

export type QueryRecentCategoriesArgs = {
  options?: InputMaybe<RecentCategoryOptions>;
  where?: InputMaybe<RecentCategoryWhere>;
};

export type QueryRecentCategoriesAggregateArgs = {
  where?: InputMaybe<RecentCategoryWhere>;
};

export type QueryRecentCategoriesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<RecentCategorySort>>>;
  where?: InputMaybe<RecentCategoryWhere>;
};

export type QueryRecentTracksArgs = {
  options?: InputMaybe<RecentTrackOptions>;
  where?: InputMaybe<RecentTrackWhere>;
};

export type QueryRecentTracksAggregateArgs = {
  where?: InputMaybe<RecentTrackWhere>;
};

export type QueryRecentTracksConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<RecentTrackSort>>>;
  where?: InputMaybe<RecentTrackWhere>;
};

export type QueryRecomendedsArgs = {
  options?: InputMaybe<RecomendedOptions>;
  where?: InputMaybe<RecomendedWhere>;
};

export type QueryRecomendedsAggregateArgs = {
  where?: InputMaybe<RecomendedWhere>;
};

export type QueryRecomendedsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<RecomendedSort>>>;
  where?: InputMaybe<RecomendedWhere>;
};

export type QueryReflectionsArgs = {
  options?: InputMaybe<ReflectionOptions>;
  where?: InputMaybe<ReflectionWhere>;
};

export type QueryReflectionsAggregateArgs = {
  where?: InputMaybe<ReflectionWhere>;
};

export type QueryReflectionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ReflectionSort>>>;
  where?: InputMaybe<ReflectionWhere>;
};

export type QueryRefreshQuestResponsesArgs = {
  options?: InputMaybe<RefreshQuestResponseOptions>;
  where?: InputMaybe<RefreshQuestResponseWhere>;
};

export type QueryRefreshQuestResponsesAggregateArgs = {
  where?: InputMaybe<RefreshQuestResponseWhere>;
};

export type QueryRefreshQuestResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<RefreshQuestResponseSort>>>;
  where?: InputMaybe<RefreshQuestResponseWhere>;
};

export type QueryResourcesArgs = {
  options?: InputMaybe<ResourceOptions>;
  where?: InputMaybe<ResourceWhere>;
};

export type QueryResourcesAggregateArgs = {
  where?: InputMaybe<ResourceWhere>;
};

export type QueryResourcesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ResourceSort>>>;
  where?: InputMaybe<ResourceWhere>;
};

export type QueryResponseTypesArgs = {
  options?: InputMaybe<ResponseTypeOptions>;
  where?: InputMaybe<ResponseTypeWhere>;
};

export type QueryResponseTypesAggregateArgs = {
  where?: InputMaybe<ResponseTypeWhere>;
};

export type QueryResponseTypesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ResponseTypeSort>>>;
  where?: InputMaybe<ResponseTypeWhere>;
};

export type QueryRolesArgs = {
  options?: InputMaybe<RoleOptions>;
  where?: InputMaybe<RoleWhere>;
};

export type QueryRolesAggregateArgs = {
  where?: InputMaybe<RoleWhere>;
};

export type QueryRolesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<RoleSort>>>;
  where?: InputMaybe<RoleWhere>;
};

export type QuerySchoolsArgs = {
  options?: InputMaybe<SchoolOptions>;
  where?: InputMaybe<SchoolWhere>;
};

export type QuerySchoolsAggregateArgs = {
  where?: InputMaybe<SchoolWhere>;
};

export type QuerySchoolsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<SchoolSort>>>;
  where?: InputMaybe<SchoolWhere>;
};

export type QuerySessionResponsesArgs = {
  options?: InputMaybe<SessionResponseOptions>;
  where?: InputMaybe<SessionResponseWhere>;
};

export type QuerySessionResponsesAggregateArgs = {
  where?: InputMaybe<SessionResponseWhere>;
};

export type QuerySessionResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<SessionResponseSort>>>;
  where?: InputMaybe<SessionResponseWhere>;
};

export type QuerySubchapterDetailsArgs = {
  options?: InputMaybe<SubchapterDetailsOptions>;
  where?: InputMaybe<SubchapterDetailsWhere>;
};

export type QuerySubchapterDetailsAggregateArgs = {
  where?: InputMaybe<SubchapterDetailsWhere>;
};

export type QuerySubchapterDetailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<SubchapterDetailsSort>>>;
  where?: InputMaybe<SubchapterDetailsWhere>;
};

export type QuerySubchaptersArgs = {
  options?: InputMaybe<SubchapterOptions>;
  where?: InputMaybe<SubchapterWhere>;
};

export type QuerySubchaptersAggregateArgs = {
  where?: InputMaybe<SubchapterWhere>;
};

export type QuerySubchaptersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<SubchapterSort>>>;
  where?: InputMaybe<SubchapterWhere>;
};

export type QuerySubfieldsArgs = {
  options?: InputMaybe<SubfieldOptions>;
  where?: InputMaybe<SubfieldWhere>;
};

export type QuerySubfieldsAggregateArgs = {
  where?: InputMaybe<SubfieldWhere>;
};

export type QuerySubfieldsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<SubfieldSort>>>;
  where?: InputMaybe<SubfieldWhere>;
};

export type QuerySuggestionsArgs = {
  options?: InputMaybe<SuggestionOptions>;
  where?: InputMaybe<SuggestionWhere>;
};

export type QuerySuggestionsAggregateArgs = {
  where?: InputMaybe<SuggestionWhere>;
};

export type QuerySuggestionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<SuggestionSort>>>;
  where?: InputMaybe<SuggestionWhere>;
};

export type QueryTenantResponsesArgs = {
  options?: InputMaybe<TenantResponseOptions>;
  where?: InputMaybe<TenantResponseWhere>;
};

export type QueryTenantResponsesAggregateArgs = {
  where?: InputMaybe<TenantResponseWhere>;
};

export type QueryTenantResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<TenantResponseSort>>>;
  where?: InputMaybe<TenantResponseWhere>;
};

export type QueryTenantsArgs = {
  options?: InputMaybe<TenantOptions>;
  where?: InputMaybe<TenantWhere>;
};

export type QueryTenantsAggregateArgs = {
  where?: InputMaybe<TenantWhere>;
};

export type QueryTenantsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<TenantSort>>>;
  where?: InputMaybe<TenantWhere>;
};

export type QueryTodosAggregateArgs = {
  where?: InputMaybe<TodoWhere>;
};

export type QueryTodosConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<TodoSort>>>;
  where?: InputMaybe<TodoWhere>;
};

export type QueryToolAssignmentsArgs = {
  options?: InputMaybe<ToolAssignmentOptions>;
  where?: InputMaybe<ToolAssignmentWhere>;
};

export type QueryToolAssignmentsAggregateArgs = {
  where?: InputMaybe<ToolAssignmentWhere>;
};

export type QueryToolAssignmentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<ToolAssignmentSort>>>;
  where?: InputMaybe<ToolAssignmentWhere>;
};

export type QueryTopicsArgs = {
  options?: InputMaybe<TopicOptions>;
  where?: InputMaybe<TopicWhere>;
};

export type QueryTopicsAggregateArgs = {
  where?: InputMaybe<TopicWhere>;
};

export type QueryTopicsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<TopicSort>>>;
  where?: InputMaybe<TopicWhere>;
};

export type QueryTrackOverviewsArgs = {
  options?: InputMaybe<TrackOverviewOptions>;
  where?: InputMaybe<TrackOverviewWhere>;
};

export type QueryTrackOverviewsAggregateArgs = {
  where?: InputMaybe<TrackOverviewWhere>;
};

export type QueryTrackOverviewsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<TrackOverviewSort>>>;
  where?: InputMaybe<TrackOverviewWhere>;
};

export type QueryTransactionByDatesArgs = {
  options?: InputMaybe<TransactionByDateOptions>;
  where?: InputMaybe<TransactionByDateWhere>;
};

export type QueryTransactionByDatesAggregateArgs = {
  where?: InputMaybe<TransactionByDateWhere>;
};

export type QueryTransactionByDatesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<TransactionByDateSort>>>;
  where?: InputMaybe<TransactionByDateWhere>;
};

export type QueryTransactionsArgs = {
  options?: InputMaybe<TransactionOptions>;
  where?: InputMaybe<TransactionWhere>;
};

export type QueryTransactionsAggregateArgs = {
  where?: InputMaybe<TransactionWhere>;
};

export type QueryTransactionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<TransactionSort>>>;
  where?: InputMaybe<TransactionWhere>;
};

export type QueryUpdateAssignmentResponsesArgs = {
  options?: InputMaybe<UpdateAssignmentResponseOptions>;
  where?: InputMaybe<UpdateAssignmentResponseWhere>;
};

export type QueryUpdateAssignmentResponsesAggregateArgs = {
  where?: InputMaybe<UpdateAssignmentResponseWhere>;
};

export type QueryUpdateAssignmentResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UpdateAssignmentResponseSort>>>;
  where?: InputMaybe<UpdateAssignmentResponseWhere>;
};

export type QueryUpdateContentModuleNameResponsesArgs = {
  options?: InputMaybe<UpdateContentModuleNameResponseOptions>;
  where?: InputMaybe<UpdateContentModuleNameResponseWhere>;
};

export type QueryUpdateContentModuleNameResponsesAggregateArgs = {
  where?: InputMaybe<UpdateContentModuleNameResponseWhere>;
};

export type QueryUpdateContentModuleNameResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UpdateContentModuleNameResponseSort>>>;
  where?: InputMaybe<UpdateContentModuleNameResponseWhere>;
};

export type QueryUpdateUserModalOnboardingResponsesArgs = {
  options?: InputMaybe<UpdateUserModalOnboardingResponseOptions>;
  where?: InputMaybe<UpdateUserModalOnboardingResponseWhere>;
};

export type QueryUpdateUserModalOnboardingResponsesAggregateArgs = {
  where?: InputMaybe<UpdateUserModalOnboardingResponseWhere>;
};

export type QueryUpdateUserModalOnboardingResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UpdateUserModalOnboardingResponseSort>>>;
  where?: InputMaybe<UpdateUserModalOnboardingResponseWhere>;
};

export type QueryUploadDocumentsResponsesArgs = {
  options?: InputMaybe<UploadDocumentsResponseOptions>;
  where?: InputMaybe<UploadDocumentsResponseWhere>;
};

export type QueryUploadDocumentsResponsesAggregateArgs = {
  where?: InputMaybe<UploadDocumentsResponseWhere>;
};

export type QueryUploadDocumentsResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UploadDocumentsResponseSort>>>;
  where?: InputMaybe<UploadDocumentsResponseWhere>;
};

export type QueryUploadDocumentsToBucketResponsesArgs = {
  options?: InputMaybe<UploadDocumentsToBucketResponseOptions>;
  where?: InputMaybe<UploadDocumentsToBucketResponseWhere>;
};

export type QueryUploadDocumentsToBucketResponsesAggregateArgs = {
  where?: InputMaybe<UploadDocumentsToBucketResponseWhere>;
};

export type QueryUploadDocumentsToBucketResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UploadDocumentsToBucketResponseSort>>>;
  where?: InputMaybe<UploadDocumentsToBucketResponseWhere>;
};

export type QueryUserCareerLoadingResponsesArgs = {
  options?: InputMaybe<UserCareerLoadingResponseOptions>;
  where?: InputMaybe<UserCareerLoadingResponseWhere>;
};

export type QueryUserCareerLoadingResponsesAggregateArgs = {
  where?: InputMaybe<UserCareerLoadingResponseWhere>;
};

export type QueryUserCareerLoadingResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UserCareerLoadingResponseSort>>>;
  where?: InputMaybe<UserCareerLoadingResponseWhere>;
};

export type QueryUserCourseLoadingResponsesArgs = {
  options?: InputMaybe<UserCourseLoadingResponseOptions>;
  where?: InputMaybe<UserCourseLoadingResponseWhere>;
};

export type QueryUserCourseLoadingResponsesAggregateArgs = {
  where?: InputMaybe<UserCourseLoadingResponseWhere>;
};

export type QueryUserCourseLoadingResponsesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UserCourseLoadingResponseSort>>>;
  where?: InputMaybe<UserCourseLoadingResponseWhere>;
};

export type QueryUserMasteriesArgs = {
  options?: InputMaybe<UserMasteryOptions>;
  where?: InputMaybe<UserMasteryWhere>;
};

export type QueryUserMasteriesAggregateArgs = {
  where?: InputMaybe<UserMasteryWhere>;
};

export type QueryUserMasteriesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UserMasterySort>>>;
  where?: InputMaybe<UserMasteryWhere>;
};

export type QueryUserProgressesArgs = {
  options?: InputMaybe<UserProgressOptions>;
  where?: InputMaybe<UserProgressWhere>;
};

export type QueryUserProgressesAggregateArgs = {
  where?: InputMaybe<UserProgressWhere>;
};

export type QueryUserProgressesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UserProgressSort>>>;
  where?: InputMaybe<UserProgressWhere>;
};

export type QueryUsersArgs = {
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type QueryUsersAggregateArgs = {
  where?: InputMaybe<UserWhere>;
};

export type QueryUsersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<UserSort>>>;
  where?: InputMaybe<UserWhere>;
};

export type QueryWaterIntakeSummariesArgs = {
  options?: InputMaybe<WaterIntakeSummaryOptions>;
  where?: InputMaybe<WaterIntakeSummaryWhere>;
};

export type QueryWaterIntakeSummariesAggregateArgs = {
  where?: InputMaybe<WaterIntakeSummaryWhere>;
};

export type QueryWaterIntakeSummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<WaterIntakeSummarySort>>>;
  where?: InputMaybe<WaterIntakeSummaryWhere>;
};

export type QueryWaterIntakesArgs = {
  options?: InputMaybe<WaterIntakeOptions>;
  where?: InputMaybe<WaterIntakeWhere>;
};

export type QueryWaterIntakesAggregateArgs = {
  where?: InputMaybe<WaterIntakeWhere>;
};

export type QueryWaterIntakesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<WaterIntakeSort>>>;
  where?: InputMaybe<WaterIntakeWhere>;
};

export type QueryWeeklySummariesArgs = {
  options?: InputMaybe<WeeklySummaryOptions>;
  where?: InputMaybe<WeeklySummaryWhere>;
};

export type QueryWeeklySummariesAggregateArgs = {
  where?: InputMaybe<WeeklySummaryWhere>;
};

export type QueryWeeklySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<WeeklySummarySort>>>;
  where?: InputMaybe<WeeklySummaryWhere>;
};

export type QueryWhitelistedEmailsArgs = {
  options?: InputMaybe<WhitelistedEmailOptions>;
  where?: InputMaybe<WhitelistedEmailWhere>;
};

export type QueryWhitelistedEmailsAggregateArgs = {
  where?: InputMaybe<WhitelistedEmailWhere>;
};

export type QueryWhitelistedEmailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<WhitelistedEmailSort>>>;
  where?: InputMaybe<WhitelistedEmailWhere>;
};

export type QueryWorkoutsArgs = {
  options?: InputMaybe<WorkoutOptions>;
  where?: InputMaybe<WorkoutWhere>;
};

export type QueryWorkoutsAggregateArgs = {
  where?: InputMaybe<WorkoutWhere>;
};

export type QueryWorkoutsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<WorkoutSort>>>;
  where?: InputMaybe<WorkoutWhere>;
};

export type QueryYearlySummariesArgs = {
  options?: InputMaybe<YearlySummaryOptions>;
  where?: InputMaybe<YearlySummaryWhere>;
};

export type QueryYearlySummariesAggregateArgs = {
  where?: InputMaybe<YearlySummaryWhere>;
};

export type QueryYearlySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<InputMaybe<YearlySummarySort>>>;
  where?: InputMaybe<YearlySummaryWhere>;
};

export type RateLimitResult = {
  __typename?: "RateLimitResult";
  dailyRequests?: Maybe<Scalars["Int"]["output"]>;
  result?: Maybe<Scalars["Boolean"]["output"]>;
};

export type RateLimitResultAggregateSelection = {
  __typename?: "RateLimitResultAggregateSelection";
  count: Scalars["Int"]["output"];
  dailyRequests: IntAggregateSelection;
};

export type RateLimitResultCreateInput = {
  dailyRequests?: InputMaybe<Scalars["Int"]["input"]>;
  result?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RateLimitResultEdge = {
  __typename?: "RateLimitResultEdge";
  cursor: Scalars["String"]["output"];
  node: RateLimitResult;
};

export type RateLimitResultOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more RateLimitResultSort objects to sort RateLimitResults by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<RateLimitResultSort>>;
};

/** Fields to sort RateLimitResults by. The order in which sorts are applied is not guaranteed when specifying many fields in one RateLimitResultSort object. */
export type RateLimitResultSort = {
  dailyRequests?: InputMaybe<SortDirection>;
  result?: InputMaybe<SortDirection>;
};

export type RateLimitResultUpdateInput = {
  dailyRequests?: InputMaybe<Scalars["Int"]["input"]>;
  dailyRequests_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyRequests_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  result?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RateLimitResultWhere = {
  AND?: InputMaybe<Array<RateLimitResultWhere>>;
  NOT?: InputMaybe<RateLimitResultWhere>;
  OR?: InputMaybe<Array<RateLimitResultWhere>>;
  dailyRequests?: InputMaybe<Scalars["Int"]["input"]>;
  dailyRequests_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyRequests_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dailyRequests_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  dailyRequests_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dailyRequests_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  result?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RateLimitResultsConnection = {
  __typename?: "RateLimitResultsConnection";
  edges: Array<RateLimitResultEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type RecentCategoriesConnection = {
  __typename?: "RecentCategoriesConnection";
  edges: Array<RecentCategoryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type RecentCategory = {
  __typename?: "RecentCategory";
  completedUnits?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
  parentFields?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  totalUnits?: Maybe<Scalars["Int"]["output"]>;
};

export type RecentCategoryAggregateSelection = {
  __typename?: "RecentCategoryAggregateSelection";
  completedUnits: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  imageUrl: StringAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
  totalUnits: IntAggregateSelection;
};

export type RecentCategoryCreateInput = {
  completedUnits?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  parentFields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  totalUnits?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RecentCategoryEdge = {
  __typename?: "RecentCategoryEdge";
  cursor: Scalars["String"]["output"];
  node: RecentCategory;
};

export type RecentCategoryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more RecentCategorySort objects to sort RecentCategories by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<RecentCategorySort>>;
};

/** Fields to sort RecentCategories by. The order in which sorts are applied is not guaranteed when specifying many fields in one RecentCategorySort object. */
export type RecentCategorySort = {
  completedUnits?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  imageUrl?: InputMaybe<SortDirection>;
  mastery?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  totalUnits?: InputMaybe<SortDirection>;
};

export type RecentCategoryUpdateInput = {
  completedUnits?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  parentFields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentFields_POP?: InputMaybe<Scalars["Int"]["input"]>;
  parentFields_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  totalUnits?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RecentCategoryWhere = {
  AND?: InputMaybe<Array<RecentCategoryWhere>>;
  NOT?: InputMaybe<RecentCategoryWhere>;
  OR?: InputMaybe<Array<RecentCategoryWhere>>;
  completedUnits?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  completedUnits_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  imageUrl_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  parentFields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentFields_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  totalUnits?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  totalUnits_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RecentTrack = {
  __typename?: "RecentTrack";
  completedUnits?: Maybe<Scalars["Int"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  imageUrl?: Maybe<Scalars["String"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
  parentFields?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  parentSubfield?: Maybe<Scalars["String"]["output"]>;
  totalUnits?: Maybe<Scalars["Int"]["output"]>;
};

export type RecentTrackAggregateSelection = {
  __typename?: "RecentTrackAggregateSelection";
  completedUnits: IntAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  imageUrl: StringAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
  parentSubfield: StringAggregateSelection;
  totalUnits: IntAggregateSelection;
};

export type RecentTrackCreateInput = {
  completedUnits?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
  parentFields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentSubfield?: InputMaybe<Scalars["String"]["input"]>;
  totalUnits?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RecentTrackEdge = {
  __typename?: "RecentTrackEdge";
  cursor: Scalars["String"]["output"];
  node: RecentTrack;
};

export type RecentTrackOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more RecentTrackSort objects to sort RecentTracks by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<RecentTrackSort>>;
};

/** Fields to sort RecentTracks by. The order in which sorts are applied is not guaranteed when specifying many fields in one RecentTrackSort object. */
export type RecentTrackSort = {
  completedUnits?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  imageUrl?: InputMaybe<SortDirection>;
  mastery?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  parentSubfield?: InputMaybe<SortDirection>;
  totalUnits?: InputMaybe<SortDirection>;
};

export type RecentTrackUpdateInput = {
  completedUnits?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  parentFields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentFields_POP?: InputMaybe<Scalars["Int"]["input"]>;
  parentFields_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentSubfield?: InputMaybe<Scalars["String"]["input"]>;
  totalUnits?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RecentTrackWhere = {
  AND?: InputMaybe<Array<RecentTrackWhere>>;
  NOT?: InputMaybe<RecentTrackWhere>;
  OR?: InputMaybe<Array<RecentTrackWhere>>;
  completedUnits?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  completedUnits_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedUnits_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  imageUrl_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  imageUrl_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  parentFields?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentFields_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  parentSubfield?: InputMaybe<Scalars["String"]["input"]>;
  parentSubfield_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  parentSubfield_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  parentSubfield_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  parentSubfield_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  totalUnits?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  totalUnits_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalUnits_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RecentTracksConnection = {
  __typename?: "RecentTracksConnection";
  edges: Array<RecentTrackEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Recomended = {
  __typename?: "Recomended";
  chapterName?: Maybe<Scalars["String"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
};

export type RecomendedAggregateSelection = {
  __typename?: "RecomendedAggregateSelection";
  chapterName: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  name: StringAggregateSelection;
};

export type RecomendedCreateInput = {
  chapterName?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type RecomendedEdge = {
  __typename?: "RecomendedEdge";
  cursor: Scalars["String"]["output"];
  node: Recomended;
};

export type RecomendedOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more RecomendedSort objects to sort Recomendeds by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<RecomendedSort>>;
};

/** Fields to sort Recomendeds by. The order in which sorts are applied is not guaranteed when specifying many fields in one RecomendedSort object. */
export type RecomendedSort = {
  chapterName?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type RecomendedUpdateInput = {
  chapterName?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type RecomendedWhere = {
  AND?: InputMaybe<Array<RecomendedWhere>>;
  NOT?: InputMaybe<RecomendedWhere>;
  OR?: InputMaybe<Array<RecomendedWhere>>;
  chapterName?: InputMaybe<Scalars["String"]["input"]>;
  chapterName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  chapterName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  chapterName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  chapterName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type RecomendedsConnection = {
  __typename?: "RecomendedsConnection";
  edges: Array<RecomendedEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Reflection = {
  __typename?: "Reflection";
  context?: Maybe<Scalars["String"]["output"]>;
  date?: Maybe<Scalars["Date"]["output"]>;
  id?: Maybe<Scalars["Int"]["output"]>;
  sentiment?: Maybe<Scalars["String"]["output"]>;
  text?: Maybe<Scalars["String"]["output"]>;
};

export type ReflectionAggregateSelection = {
  __typename?: "ReflectionAggregateSelection";
  context: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  id: IntAggregateSelection;
  sentiment: StringAggregateSelection;
  text: StringAggregateSelection;
};

export type ReflectionCreateInput = {
  context?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["Date"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type ReflectionEdge = {
  __typename?: "ReflectionEdge";
  cursor: Scalars["String"]["output"];
  node: Reflection;
};

export type ReflectionOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ReflectionSort objects to sort Reflections by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ReflectionSort>>;
};

/** Fields to sort Reflections by. The order in which sorts are applied is not guaranteed when specifying many fields in one ReflectionSort object. */
export type ReflectionSort = {
  context?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  sentiment?: InputMaybe<SortDirection>;
  text?: InputMaybe<SortDirection>;
};

export type ReflectionUpdateInput = {
  context?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["Date"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  id_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
};

export type ReflectionWhere = {
  AND?: InputMaybe<Array<ReflectionWhere>>;
  NOT?: InputMaybe<ReflectionWhere>;
  OR?: InputMaybe<Array<ReflectionWhere>>;
  context?: InputMaybe<Scalars["String"]["input"]>;
  context_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  context_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  context_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  context_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["Date"]["input"]>;
  date_GT?: InputMaybe<Scalars["Date"]["input"]>;
  date_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  date_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  date_LT?: InputMaybe<Scalars["Date"]["input"]>;
  date_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  id?: InputMaybe<Scalars["Int"]["input"]>;
  id_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  id_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sentiment?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  sentiment_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  sentiment_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text?: InputMaybe<Scalars["String"]["input"]>;
  text_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  text_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  text_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  text_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ReflectionsConnection = {
  __typename?: "ReflectionsConnection";
  edges: Array<ReflectionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type RefreshQuestResponse = {
  __typename?: "RefreshQuestResponse";
  message: Scalars["String"]["output"];
  quests: Array<AssignedQuest>;
  success: Scalars["Boolean"]["output"];
};

export type RefreshQuestResponseAggregateSelection = {
  __typename?: "RefreshQuestResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type RefreshQuestResponseCreateInput = {
  message: Scalars["String"]["input"];
  success: Scalars["Boolean"]["input"];
};

export type RefreshQuestResponseEdge = {
  __typename?: "RefreshQuestResponseEdge";
  cursor: Scalars["String"]["output"];
  node: RefreshQuestResponse;
};

export type RefreshQuestResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more RefreshQuestResponseSort objects to sort RefreshQuestResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<RefreshQuestResponseSort>>;
};

/** Fields to sort RefreshQuestResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one RefreshQuestResponseSort object. */
export type RefreshQuestResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type RefreshQuestResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RefreshQuestResponseWhere = {
  AND?: InputMaybe<Array<RefreshQuestResponseWhere>>;
  NOT?: InputMaybe<RefreshQuestResponseWhere>;
  OR?: InputMaybe<Array<RefreshQuestResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type RefreshQuestResponsesConnection = {
  __typename?: "RefreshQuestResponsesConnection";
  edges: Array<RefreshQuestResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Resource = {
  __typename?: "Resource";
  assignments: Array<Assignment>;
  assignmentsAggregate?: Maybe<ResourceAssignmentAssignmentsAggregationSelection>;
  assignmentsConnection: ResourceAssignmentsConnection;
  created?: Maybe<Scalars["DateTime"]["output"]>;
  id: Scalars["ID"]["output"];
  lastModified?: Maybe<Scalars["DateTime"]["output"]>;
  moduleHasResources: Array<Module>;
  moduleHasResourcesAggregate?: Maybe<ResourceModuleModuleHasResourcesAggregationSelection>;
  moduleHasResourcesConnection: ResourceModuleHasResourcesConnection;
  pages: Array<Page>;
  pagesAggregate?: Maybe<ResourcePagePagesAggregationSelection>;
  pagesConnection: ResourcePagesConnection;
};

export type ResourceAssignmentsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AssignmentOptions>;
  where?: InputMaybe<AssignmentWhere>;
};

export type ResourceAssignmentsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AssignmentWhere>;
};

export type ResourceAssignmentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ResourceAssignmentsConnectionSort>>;
  where?: InputMaybe<ResourceAssignmentsConnectionWhere>;
};

export type ResourceModuleHasResourcesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ModuleOptions>;
  where?: InputMaybe<ModuleWhere>;
};

export type ResourceModuleHasResourcesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ModuleWhere>;
};

export type ResourceModuleHasResourcesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ResourceModuleHasResourcesConnectionSort>>;
  where?: InputMaybe<ResourceModuleHasResourcesConnectionWhere>;
};

export type ResourcePagesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<PageOptions>;
  where?: InputMaybe<PageWhere>;
};

export type ResourcePagesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<PageWhere>;
};

export type ResourcePagesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<ResourcePagesConnectionSort>>;
  where?: InputMaybe<ResourcePagesConnectionWhere>;
};

export type ResourceAggregateSelection = {
  __typename?: "ResourceAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
};

export type ResourceAssignmentAssignmentsAggregationSelection = {
  __typename?: "ResourceAssignmentAssignmentsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<ResourceAssignmentAssignmentsNodeAggregateSelection>;
};

export type ResourceAssignmentAssignmentsNodeAggregateSelection = {
  __typename?: "ResourceAssignmentAssignmentsNodeAggregateSelection";
  _empty: StringAggregateSelection;
  allowed_attempts: FloatAggregateSelection;
  assignee_mode: StringAggregateSelection;
  assignment_group_id: FloatAggregateSelection;
  assignment_name: StringAggregateSelection;
  body: StringAggregateSelection;
  category_id: StringAggregateSelection;
  course_id: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  description: StringAggregateSelection;
  description_plaintext: StringAggregateSelection;
  discussion_topic_assignment_id: FloatAggregateSelection;
  discussion_topic_author_anonymous_id: StringAggregateSelection;
  discussion_topic_author_avatar_image_url: StringAggregateSelection;
  discussion_topic_author_display_name: StringAggregateSelection;
  discussion_topic_author_html_url: StringAggregateSelection;
  discussion_topic_author_id: FloatAggregateSelection;
  discussion_topic_created_at: StringAggregateSelection;
  discussion_topic_discussion_subentry_count: FloatAggregateSelection;
  discussion_topic_discussion_type: StringAggregateSelection;
  discussion_topic_html_url: StringAggregateSelection;
  discussion_topic_id: FloatAggregateSelection;
  discussion_topic_last_reply_at: StringAggregateSelection;
  discussion_topic_message: StringAggregateSelection;
  discussion_topic_posted_at: StringAggregateSelection;
  discussion_topic_read_state: StringAggregateSelection;
  discussion_topic_title: StringAggregateSelection;
  discussion_topic_unread_count: FloatAggregateSelection;
  discussion_topic_url: StringAggregateSelection;
  discussion_topic_user_name: StringAggregateSelection;
  display_date: StringAggregateSelection;
  due_at: StringAggregateSelection;
  due_date: StringAggregateSelection;
  end_date: StringAggregateSelection;
  external_tool_tag_attributes_content_id: FloatAggregateSelection;
  external_tool_tag_attributes_content_type: StringAggregateSelection;
  external_tool_tag_attributes_external_data: StringAggregateSelection;
  external_tool_tag_attributes_resource_link_id: StringAggregateSelection;
  external_tool_tag_attributes_url: StringAggregateSelection;
  grader_count: FloatAggregateSelection;
  grading_type: StringAggregateSelection;
  html: StringAggregateSelection;
  html_url: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  lti_context_id: StringAggregateSelection;
  max_attempts: FloatAggregateSelection;
  max_name_length: FloatAggregateSelection;
  name: StringAggregateSelection;
  needs_grading_count: FloatAggregateSelection;
  objectives: StringAggregateSelection;
  points_possible: FloatAggregateSelection;
  position: FloatAggregateSelection;
  resource_metadata: StringAggregateSelection;
  secure_params: StringAggregateSelection;
  session_id: StringAggregateSelection;
  start_date: StringAggregateSelection;
  state: StringAggregateSelection;
  submissions_download_url: StringAggregateSelection;
  text: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_at: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  url: StringAggregateSelection;
  workflow_state: StringAggregateSelection;
};

export type ResourceAssignmentsAggregateInput = {
  AND?: InputMaybe<Array<ResourceAssignmentsAggregateInput>>;
  NOT?: InputMaybe<ResourceAssignmentsAggregateInput>;
  OR?: InputMaybe<Array<ResourceAssignmentsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<ResourceAssignmentsNodeAggregationWhereInput>;
};

export type ResourceAssignmentsConnectFieldInput = {
  connect?: InputMaybe<Array<AssignmentConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AssignmentConnectWhere>;
};

export type ResourceAssignmentsConnectOrCreateFieldInput = {
  onCreate: ResourceAssignmentsConnectOrCreateFieldInputOnCreate;
  where: AssignmentConnectOrCreateWhere;
};

export type ResourceAssignmentsConnectOrCreateFieldInputOnCreate = {
  node: AssignmentOnCreateInput;
};

export type ResourceAssignmentsConnection = {
  __typename?: "ResourceAssignmentsConnection";
  edges: Array<ResourceAssignmentsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ResourceAssignmentsConnectionSort = {
  node?: InputMaybe<AssignmentSort>;
};

export type ResourceAssignmentsConnectionWhere = {
  AND?: InputMaybe<Array<ResourceAssignmentsConnectionWhere>>;
  NOT?: InputMaybe<ResourceAssignmentsConnectionWhere>;
  OR?: InputMaybe<Array<ResourceAssignmentsConnectionWhere>>;
  node?: InputMaybe<AssignmentWhere>;
};

export type ResourceAssignmentsCreateFieldInput = {
  node: AssignmentCreateInput;
};

export type ResourceAssignmentsDeleteFieldInput = {
  delete?: InputMaybe<AssignmentDeleteInput>;
  where?: InputMaybe<ResourceAssignmentsConnectionWhere>;
};

export type ResourceAssignmentsDisconnectFieldInput = {
  disconnect?: InputMaybe<AssignmentDisconnectInput>;
  where?: InputMaybe<ResourceAssignmentsConnectionWhere>;
};

export type ResourceAssignmentsFieldInput = {
  connect?: InputMaybe<Array<ResourceAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ResourceAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ResourceAssignmentsCreateFieldInput>>;
};

export type ResourceAssignmentsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ResourceAssignmentsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ResourceAssignmentsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ResourceAssignmentsNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  allowed_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  allowed_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_group_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_group_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignment_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignment_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  category_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  category_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  course_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  course_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_plaintext_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_plaintext_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_assignment_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_anonymous_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_avatar_image_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_display_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_author_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_author_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_subentry_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_discussion_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_last_reply_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_message_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_posted_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_read_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_unread_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_unread_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  discussion_topic_user_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  display_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  display_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  due_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  due_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  due_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  due_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  due_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  end_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  end_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_content_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_external_data_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_resource_link_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  external_tool_tag_attributes_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grader_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grader_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  grading_type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  grading_type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  lti_context_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  lti_context_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  max_attempts_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_attempts_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  max_name_length_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  needs_grading_count_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  needs_grading_count_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  objectives_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  points_possible_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  points_possible_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  position_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  resource_metadata_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  resource_metadata_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  secure_params_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  secure_params_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  session_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  session_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  start_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  start_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  submissions_download_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  workflow_state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  workflow_state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ResourceAssignmentsRelationship = {
  __typename?: "ResourceAssignmentsRelationship";
  cursor: Scalars["String"]["output"];
  node: Assignment;
};

export type ResourceAssignmentsUpdateConnectionInput = {
  node?: InputMaybe<AssignmentUpdateInput>;
};

export type ResourceAssignmentsUpdateFieldInput = {
  connect?: InputMaybe<Array<ResourceAssignmentsConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ResourceAssignmentsConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ResourceAssignmentsCreateFieldInput>>;
  delete?: InputMaybe<Array<ResourceAssignmentsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ResourceAssignmentsDisconnectFieldInput>>;
  update?: InputMaybe<ResourceAssignmentsUpdateConnectionInput>;
  where?: InputMaybe<ResourceAssignmentsConnectionWhere>;
};

export type ResourceConnectInput = {
  assignments?: InputMaybe<Array<ResourceAssignmentsConnectFieldInput>>;
  moduleHasResources?: InputMaybe<Array<ResourceModuleHasResourcesConnectFieldInput>>;
  pages?: InputMaybe<Array<ResourcePagesConnectFieldInput>>;
};

export type ResourceConnectOrCreateInput = {
  assignments?: InputMaybe<Array<ResourceAssignmentsConnectOrCreateFieldInput>>;
  moduleHasResources?: InputMaybe<Array<ResourceModuleHasResourcesConnectOrCreateFieldInput>>;
  pages?: InputMaybe<Array<ResourcePagesConnectOrCreateFieldInput>>;
};

export type ResourceConnectOrCreateWhere = {
  node: ResourceUniqueWhere;
};

export type ResourceConnectWhere = {
  node: ResourceWhere;
};

export type ResourceCreateInput = {
  assignments?: InputMaybe<ResourceAssignmentsFieldInput>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  moduleHasResources?: InputMaybe<ResourceModuleHasResourcesFieldInput>;
  pages?: InputMaybe<ResourcePagesFieldInput>;
};

export type ResourceDeleteInput = {
  assignments?: InputMaybe<Array<ResourceAssignmentsDeleteFieldInput>>;
  moduleHasResources?: InputMaybe<Array<ResourceModuleHasResourcesDeleteFieldInput>>;
  pages?: InputMaybe<Array<ResourcePagesDeleteFieldInput>>;
};

export type ResourceDisconnectInput = {
  assignments?: InputMaybe<Array<ResourceAssignmentsDisconnectFieldInput>>;
  moduleHasResources?: InputMaybe<Array<ResourceModuleHasResourcesDisconnectFieldInput>>;
  pages?: InputMaybe<Array<ResourcePagesDisconnectFieldInput>>;
};

export type ResourceEdge = {
  __typename?: "ResourceEdge";
  cursor: Scalars["String"]["output"];
  node: Resource;
};

export type ResourceModuleHasResourcesAggregateInput = {
  AND?: InputMaybe<Array<ResourceModuleHasResourcesAggregateInput>>;
  NOT?: InputMaybe<ResourceModuleHasResourcesAggregateInput>;
  OR?: InputMaybe<Array<ResourceModuleHasResourcesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<ResourceModuleHasResourcesNodeAggregationWhereInput>;
};

export type ResourceModuleHasResourcesConnectFieldInput = {
  connect?: InputMaybe<Array<ModuleConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ModuleConnectWhere>;
};

export type ResourceModuleHasResourcesConnectOrCreateFieldInput = {
  onCreate: ResourceModuleHasResourcesConnectOrCreateFieldInputOnCreate;
  where: ModuleConnectOrCreateWhere;
};

export type ResourceModuleHasResourcesConnectOrCreateFieldInputOnCreate = {
  node: ModuleOnCreateInput;
};

export type ResourceModuleHasResourcesConnection = {
  __typename?: "ResourceModuleHasResourcesConnection";
  edges: Array<ResourceModuleHasResourcesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ResourceModuleHasResourcesConnectionSort = {
  node?: InputMaybe<ModuleSort>;
};

export type ResourceModuleHasResourcesConnectionWhere = {
  AND?: InputMaybe<Array<ResourceModuleHasResourcesConnectionWhere>>;
  NOT?: InputMaybe<ResourceModuleHasResourcesConnectionWhere>;
  OR?: InputMaybe<Array<ResourceModuleHasResourcesConnectionWhere>>;
  node?: InputMaybe<ModuleWhere>;
};

export type ResourceModuleHasResourcesCreateFieldInput = {
  node: ModuleCreateInput;
};

export type ResourceModuleHasResourcesDeleteFieldInput = {
  delete?: InputMaybe<ModuleDeleteInput>;
  where?: InputMaybe<ResourceModuleHasResourcesConnectionWhere>;
};

export type ResourceModuleHasResourcesDisconnectFieldInput = {
  disconnect?: InputMaybe<ModuleDisconnectInput>;
  where?: InputMaybe<ResourceModuleHasResourcesConnectionWhere>;
};

export type ResourceModuleHasResourcesFieldInput = {
  connect?: InputMaybe<Array<ResourceModuleHasResourcesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ResourceModuleHasResourcesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ResourceModuleHasResourcesCreateFieldInput>>;
};

export type ResourceModuleHasResourcesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ResourceModuleHasResourcesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ResourceModuleHasResourcesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ResourceModuleHasResourcesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  module_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  module_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  module_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  order_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  order_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ResourceModuleHasResourcesRelationship = {
  __typename?: "ResourceModuleHasResourcesRelationship";
  cursor: Scalars["String"]["output"];
  node: Module;
};

export type ResourceModuleHasResourcesUpdateConnectionInput = {
  node?: InputMaybe<ModuleUpdateInput>;
};

export type ResourceModuleHasResourcesUpdateFieldInput = {
  connect?: InputMaybe<Array<ResourceModuleHasResourcesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ResourceModuleHasResourcesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ResourceModuleHasResourcesCreateFieldInput>>;
  delete?: InputMaybe<Array<ResourceModuleHasResourcesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ResourceModuleHasResourcesDisconnectFieldInput>>;
  update?: InputMaybe<ResourceModuleHasResourcesUpdateConnectionInput>;
  where?: InputMaybe<ResourceModuleHasResourcesConnectionWhere>;
};

export type ResourceModuleModuleHasResourcesAggregationSelection = {
  __typename?: "ResourceModuleModuleHasResourcesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<ResourceModuleModuleHasResourcesNodeAggregateSelection>;
};

export type ResourceModuleModuleHasResourcesNodeAggregateSelection = {
  __typename?: "ResourceModuleModuleHasResourcesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  module_name: StringAggregateSelection;
  order: FloatAggregateSelection;
  title: StringAggregateSelection;
};

export type ResourceOnCreateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
};

export type ResourceOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ResourceSort objects to sort Resources by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ResourceSort>>;
};

export type ResourcePagePagesAggregationSelection = {
  __typename?: "ResourcePagePagesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<ResourcePagePagesNodeAggregateSelection>;
};

export type ResourcePagePagesNodeAggregateSelection = {
  __typename?: "ResourcePagePagesNodeAggregateSelection";
  _empty: StringAggregateSelection;
  assignee_mode: StringAggregateSelection;
  body: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  created_at: StringAggregateSelection;
  created_date: StringAggregateSelection;
  description: StringAggregateSelection;
  editing_roles: StringAggregateSelection;
  html_url: StringAggregateSelection;
  id: IdAggregateSelection;
  lastModified: DateTimeAggregateSelection;
  last_edited_by_anonymous_id: StringAggregateSelection;
  last_edited_by_avatar_image_url: StringAggregateSelection;
  last_edited_by_display_name: StringAggregateSelection;
  last_edited_by_html_url: StringAggregateSelection;
  last_edited_by_id: FloatAggregateSelection;
  page_id: FloatAggregateSelection;
  state: StringAggregateSelection;
  text: StringAggregateSelection;
  title: StringAggregateSelection;
  updated_at: StringAggregateSelection;
  updated_date: StringAggregateSelection;
  url: StringAggregateSelection;
};

export type ResourcePagesAggregateInput = {
  AND?: InputMaybe<Array<ResourcePagesAggregateInput>>;
  NOT?: InputMaybe<ResourcePagesAggregateInput>;
  OR?: InputMaybe<Array<ResourcePagesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<ResourcePagesNodeAggregationWhereInput>;
};

export type ResourcePagesConnectFieldInput = {
  connect?: InputMaybe<Array<PageConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<PageConnectWhere>;
};

export type ResourcePagesConnectOrCreateFieldInput = {
  onCreate: ResourcePagesConnectOrCreateFieldInputOnCreate;
  where: PageConnectOrCreateWhere;
};

export type ResourcePagesConnectOrCreateFieldInputOnCreate = {
  node: PageOnCreateInput;
};

export type ResourcePagesConnection = {
  __typename?: "ResourcePagesConnection";
  edges: Array<ResourcePagesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ResourcePagesConnectionSort = {
  node?: InputMaybe<PageSort>;
};

export type ResourcePagesConnectionWhere = {
  AND?: InputMaybe<Array<ResourcePagesConnectionWhere>>;
  NOT?: InputMaybe<ResourcePagesConnectionWhere>;
  OR?: InputMaybe<Array<ResourcePagesConnectionWhere>>;
  node?: InputMaybe<PageWhere>;
};

export type ResourcePagesCreateFieldInput = {
  node: PageCreateInput;
};

export type ResourcePagesDeleteFieldInput = {
  delete?: InputMaybe<PageDeleteInput>;
  where?: InputMaybe<ResourcePagesConnectionWhere>;
};

export type ResourcePagesDisconnectFieldInput = {
  disconnect?: InputMaybe<PageDisconnectInput>;
  where?: InputMaybe<ResourcePagesConnectionWhere>;
};

export type ResourcePagesFieldInput = {
  connect?: InputMaybe<Array<ResourcePagesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ResourcePagesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ResourcePagesCreateFieldInput>>;
};

export type ResourcePagesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<ResourcePagesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<ResourcePagesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<ResourcePagesNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignee_mode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignee_mode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  body_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  body_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  body_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  created_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  editing_roles_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  editing_roles_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  lastModified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_anonymous_id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_avatar_image_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_display_name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_html_url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_edited_by_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  last_edited_by_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  page_id_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  state_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  state_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  state_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  title_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  title_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  title_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_at_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_at_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  updated_date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  updated_date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type ResourcePagesRelationship = {
  __typename?: "ResourcePagesRelationship";
  cursor: Scalars["String"]["output"];
  node: Page;
};

export type ResourcePagesUpdateConnectionInput = {
  node?: InputMaybe<PageUpdateInput>;
};

export type ResourcePagesUpdateFieldInput = {
  connect?: InputMaybe<Array<ResourcePagesConnectFieldInput>>;
  connectOrCreate?: InputMaybe<Array<ResourcePagesConnectOrCreateFieldInput>>;
  create?: InputMaybe<Array<ResourcePagesCreateFieldInput>>;
  delete?: InputMaybe<Array<ResourcePagesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<ResourcePagesDisconnectFieldInput>>;
  update?: InputMaybe<ResourcePagesUpdateConnectionInput>;
  where?: InputMaybe<ResourcePagesConnectionWhere>;
};

export type ResourceRelationInput = {
  assignments?: InputMaybe<Array<ResourceAssignmentsCreateFieldInput>>;
  moduleHasResources?: InputMaybe<Array<ResourceModuleHasResourcesCreateFieldInput>>;
  pages?: InputMaybe<Array<ResourcePagesCreateFieldInput>>;
};

/** Fields to sort Resources by. The order in which sorts are applied is not guaranteed when specifying many fields in one ResourceSort object. */
export type ResourceSort = {
  created?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  lastModified?: InputMaybe<SortDirection>;
};

export type ResourceUniqueWhere = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
};

export type ResourceUpdateInput = {
  assignments?: InputMaybe<Array<ResourceAssignmentsUpdateFieldInput>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  moduleHasResources?: InputMaybe<Array<ResourceModuleHasResourcesUpdateFieldInput>>;
  pages?: InputMaybe<Array<ResourcePagesUpdateFieldInput>>;
};

export type ResourceWhere = {
  AND?: InputMaybe<Array<ResourceWhere>>;
  NOT?: InputMaybe<ResourceWhere>;
  OR?: InputMaybe<Array<ResourceWhere>>;
  assignmentsAggregate?: InputMaybe<ResourceAssignmentsAggregateInput>;
  /** Return Resources where all of the related ResourceAssignmentsConnections match this filter */
  assignmentsConnection_ALL?: InputMaybe<ResourceAssignmentsConnectionWhere>;
  /** Return Resources where none of the related ResourceAssignmentsConnections match this filter */
  assignmentsConnection_NONE?: InputMaybe<ResourceAssignmentsConnectionWhere>;
  /** Return Resources where one of the related ResourceAssignmentsConnections match this filter */
  assignmentsConnection_SINGLE?: InputMaybe<ResourceAssignmentsConnectionWhere>;
  /** Return Resources where some of the related ResourceAssignmentsConnections match this filter */
  assignmentsConnection_SOME?: InputMaybe<ResourceAssignmentsConnectionWhere>;
  /** Return Resources where all of the related Assignments match this filter */
  assignments_ALL?: InputMaybe<AssignmentWhere>;
  /** Return Resources where none of the related Assignments match this filter */
  assignments_NONE?: InputMaybe<AssignmentWhere>;
  /** Return Resources where one of the related Assignments match this filter */
  assignments_SINGLE?: InputMaybe<AssignmentWhere>;
  /** Return Resources where some of the related Assignments match this filter */
  assignments_SOME?: InputMaybe<AssignmentWhere>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  lastModified?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_IN?: InputMaybe<Array<InputMaybe<Scalars["DateTime"]["input"]>>>;
  lastModified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  lastModified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  moduleHasResourcesAggregate?: InputMaybe<ResourceModuleHasResourcesAggregateInput>;
  /** Return Resources where all of the related ResourceModuleHasResourcesConnections match this filter */
  moduleHasResourcesConnection_ALL?: InputMaybe<ResourceModuleHasResourcesConnectionWhere>;
  /** Return Resources where none of the related ResourceModuleHasResourcesConnections match this filter */
  moduleHasResourcesConnection_NONE?: InputMaybe<ResourceModuleHasResourcesConnectionWhere>;
  /** Return Resources where one of the related ResourceModuleHasResourcesConnections match this filter */
  moduleHasResourcesConnection_SINGLE?: InputMaybe<ResourceModuleHasResourcesConnectionWhere>;
  /** Return Resources where some of the related ResourceModuleHasResourcesConnections match this filter */
  moduleHasResourcesConnection_SOME?: InputMaybe<ResourceModuleHasResourcesConnectionWhere>;
  /** Return Resources where all of the related Modules match this filter */
  moduleHasResources_ALL?: InputMaybe<ModuleWhere>;
  /** Return Resources where none of the related Modules match this filter */
  moduleHasResources_NONE?: InputMaybe<ModuleWhere>;
  /** Return Resources where one of the related Modules match this filter */
  moduleHasResources_SINGLE?: InputMaybe<ModuleWhere>;
  /** Return Resources where some of the related Modules match this filter */
  moduleHasResources_SOME?: InputMaybe<ModuleWhere>;
  pagesAggregate?: InputMaybe<ResourcePagesAggregateInput>;
  /** Return Resources where all of the related ResourcePagesConnections match this filter */
  pagesConnection_ALL?: InputMaybe<ResourcePagesConnectionWhere>;
  /** Return Resources where none of the related ResourcePagesConnections match this filter */
  pagesConnection_NONE?: InputMaybe<ResourcePagesConnectionWhere>;
  /** Return Resources where one of the related ResourcePagesConnections match this filter */
  pagesConnection_SINGLE?: InputMaybe<ResourcePagesConnectionWhere>;
  /** Return Resources where some of the related ResourcePagesConnections match this filter */
  pagesConnection_SOME?: InputMaybe<ResourcePagesConnectionWhere>;
  /** Return Resources where all of the related Pages match this filter */
  pages_ALL?: InputMaybe<PageWhere>;
  /** Return Resources where none of the related Pages match this filter */
  pages_NONE?: InputMaybe<PageWhere>;
  /** Return Resources where one of the related Pages match this filter */
  pages_SINGLE?: InputMaybe<PageWhere>;
  /** Return Resources where some of the related Pages match this filter */
  pages_SOME?: InputMaybe<PageWhere>;
};

export type ResourcesConnection = {
  __typename?: "ResourcesConnection";
  edges: Array<ResourceEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ResponseType = {
  __typename?: "ResponseType";
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type ResponseTypeAggregateSelection = {
  __typename?: "ResponseTypeAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type ResponseTypeCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type ResponseTypeEdge = {
  __typename?: "ResponseTypeEdge";
  cursor: Scalars["String"]["output"];
  node: ResponseType;
};

export type ResponseTypeOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ResponseTypeSort objects to sort ResponseTypes by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ResponseTypeSort>>;
};

/** Fields to sort ResponseTypes by. The order in which sorts are applied is not guaranteed when specifying many fields in one ResponseTypeSort object. */
export type ResponseTypeSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type ResponseTypeUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ResponseTypeWhere = {
  AND?: InputMaybe<Array<ResponseTypeWhere>>;
  NOT?: InputMaybe<ResponseTypeWhere>;
  OR?: InputMaybe<Array<ResponseTypeWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type ResponseTypesConnection = {
  __typename?: "ResponseTypesConnection";
  edges: Array<ResponseTypeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Role = {
  __typename?: "Role";
  name: Scalars["String"]["output"];
  permissions: Array<Permission>;
  permissionsAggregate?: Maybe<RolePermissionPermissionsAggregationSelection>;
  permissionsConnection: RolePermissionsConnection;
  usersHasRole: Array<User>;
  usersHasRoleAggregate?: Maybe<RoleUserUsersHasRoleAggregationSelection>;
  usersHasRoleConnection: RoleUsersHasRoleConnection;
};

export type RolePermissionsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<PermissionOptions>;
  where?: InputMaybe<PermissionWhere>;
};

export type RolePermissionsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<PermissionWhere>;
};

export type RolePermissionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<RolePermissionsConnectionSort>>;
  where?: InputMaybe<RolePermissionsConnectionWhere>;
};

export type RoleUsersHasRoleArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type RoleUsersHasRoleAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type RoleUsersHasRoleConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<RoleUsersHasRoleConnectionSort>>;
  where?: InputMaybe<RoleUsersHasRoleConnectionWhere>;
};

export type RoleAggregateSelection = {
  __typename?: "RoleAggregateSelection";
  count: Scalars["Int"]["output"];
  name: StringAggregateSelection;
};

export type RoleConnectInput = {
  permissions?: InputMaybe<Array<RolePermissionsConnectFieldInput>>;
  usersHasRole?: InputMaybe<Array<RoleUsersHasRoleConnectFieldInput>>;
};

export type RoleConnectWhere = {
  node: RoleWhere;
};

export type RoleCreateInput = {
  name: Scalars["String"]["input"];
  permissions?: InputMaybe<RolePermissionsFieldInput>;
  usersHasRole?: InputMaybe<RoleUsersHasRoleFieldInput>;
};

export type RoleDeleteInput = {
  permissions?: InputMaybe<Array<RolePermissionsDeleteFieldInput>>;
  usersHasRole?: InputMaybe<Array<RoleUsersHasRoleDeleteFieldInput>>;
};

export type RoleDisconnectInput = {
  permissions?: InputMaybe<Array<RolePermissionsDisconnectFieldInput>>;
  usersHasRole?: InputMaybe<Array<RoleUsersHasRoleDisconnectFieldInput>>;
};

export type RoleEdge = {
  __typename?: "RoleEdge";
  cursor: Scalars["String"]["output"];
  node: Role;
};

export type RoleOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more RoleSort objects to sort Roles by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<RoleSort>>;
};

export type RolePermissionPermissionsAggregationSelection = {
  __typename?: "RolePermissionPermissionsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<RolePermissionPermissionsNodeAggregateSelection>;
};

export type RolePermissionPermissionsNodeAggregateSelection = {
  __typename?: "RolePermissionPermissionsNodeAggregateSelection";
  name: StringAggregateSelection;
};

export type RolePermissionsAggregateInput = {
  AND?: InputMaybe<Array<RolePermissionsAggregateInput>>;
  NOT?: InputMaybe<RolePermissionsAggregateInput>;
  OR?: InputMaybe<Array<RolePermissionsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<RolePermissionsNodeAggregationWhereInput>;
};

export type RolePermissionsConnectFieldInput = {
  connect?: InputMaybe<Array<PermissionConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<PermissionConnectWhere>;
};

export type RolePermissionsConnection = {
  __typename?: "RolePermissionsConnection";
  edges: Array<RolePermissionsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type RolePermissionsConnectionSort = {
  node?: InputMaybe<PermissionSort>;
};

export type RolePermissionsConnectionWhere = {
  AND?: InputMaybe<Array<RolePermissionsConnectionWhere>>;
  NOT?: InputMaybe<RolePermissionsConnectionWhere>;
  OR?: InputMaybe<Array<RolePermissionsConnectionWhere>>;
  node?: InputMaybe<PermissionWhere>;
};

export type RolePermissionsCreateFieldInput = {
  node: PermissionCreateInput;
};

export type RolePermissionsDeleteFieldInput = {
  delete?: InputMaybe<PermissionDeleteInput>;
  where?: InputMaybe<RolePermissionsConnectionWhere>;
};

export type RolePermissionsDisconnectFieldInput = {
  disconnect?: InputMaybe<PermissionDisconnectInput>;
  where?: InputMaybe<RolePermissionsConnectionWhere>;
};

export type RolePermissionsFieldInput = {
  connect?: InputMaybe<Array<RolePermissionsConnectFieldInput>>;
  create?: InputMaybe<Array<RolePermissionsCreateFieldInput>>;
};

export type RolePermissionsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<RolePermissionsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<RolePermissionsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<RolePermissionsNodeAggregationWhereInput>>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RolePermissionsRelationship = {
  __typename?: "RolePermissionsRelationship";
  cursor: Scalars["String"]["output"];
  node: Permission;
};

export type RolePermissionsUpdateConnectionInput = {
  node?: InputMaybe<PermissionUpdateInput>;
};

export type RolePermissionsUpdateFieldInput = {
  connect?: InputMaybe<Array<RolePermissionsConnectFieldInput>>;
  create?: InputMaybe<Array<RolePermissionsCreateFieldInput>>;
  delete?: InputMaybe<Array<RolePermissionsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<RolePermissionsDisconnectFieldInput>>;
  update?: InputMaybe<RolePermissionsUpdateConnectionInput>;
  where?: InputMaybe<RolePermissionsConnectionWhere>;
};

export type RoleRelationInput = {
  permissions?: InputMaybe<Array<RolePermissionsCreateFieldInput>>;
  usersHasRole?: InputMaybe<Array<RoleUsersHasRoleCreateFieldInput>>;
};

/** Fields to sort Roles by. The order in which sorts are applied is not guaranteed when specifying many fields in one RoleSort object. */
export type RoleSort = {
  name?: InputMaybe<SortDirection>;
};

export type RoleUpdateInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  permissions?: InputMaybe<Array<RolePermissionsUpdateFieldInput>>;
  usersHasRole?: InputMaybe<Array<RoleUsersHasRoleUpdateFieldInput>>;
};

export type RoleUserUsersHasRoleAggregationSelection = {
  __typename?: "RoleUserUsersHasRoleAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<RoleUserUsersHasRoleEdgeAggregateSelection>;
  node?: Maybe<RoleUserUsersHasRoleNodeAggregateSelection>;
};

export type RoleUserUsersHasRoleEdgeAggregateSelection = {
  __typename?: "RoleUserUsersHasRoleEdgeAggregateSelection";
  tenantId: IdAggregateSelection;
};

export type RoleUserUsersHasRoleNodeAggregateSelection = {
  __typename?: "RoleUserUsersHasRoleNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type RoleUsersHasRoleAggregateInput = {
  AND?: InputMaybe<Array<RoleUsersHasRoleAggregateInput>>;
  NOT?: InputMaybe<RoleUsersHasRoleAggregateInput>;
  OR?: InputMaybe<Array<RoleUsersHasRoleAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<UserRolePropertiesAggregationWhereInput>;
  node?: InputMaybe<RoleUsersHasRoleNodeAggregationWhereInput>;
};

export type RoleUsersHasRoleConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge: UserRolePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type RoleUsersHasRoleConnection = {
  __typename?: "RoleUsersHasRoleConnection";
  edges: Array<RoleUsersHasRoleRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type RoleUsersHasRoleConnectionSort = {
  edge?: InputMaybe<UserRolePropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type RoleUsersHasRoleConnectionWhere = {
  AND?: InputMaybe<Array<RoleUsersHasRoleConnectionWhere>>;
  NOT?: InputMaybe<RoleUsersHasRoleConnectionWhere>;
  OR?: InputMaybe<Array<RoleUsersHasRoleConnectionWhere>>;
  edge?: InputMaybe<UserRolePropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type RoleUsersHasRoleCreateFieldInput = {
  edge: UserRolePropertiesCreateInput;
  node: UserCreateInput;
};

export type RoleUsersHasRoleDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<RoleUsersHasRoleConnectionWhere>;
};

export type RoleUsersHasRoleDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<RoleUsersHasRoleConnectionWhere>;
};

export type RoleUsersHasRoleFieldInput = {
  connect?: InputMaybe<Array<RoleUsersHasRoleConnectFieldInput>>;
  create?: InputMaybe<Array<RoleUsersHasRoleCreateFieldInput>>;
};

export type RoleUsersHasRoleNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<RoleUsersHasRoleNodeAggregationWhereInput>>;
  NOT?: InputMaybe<RoleUsersHasRoleNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<RoleUsersHasRoleNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type RoleUsersHasRoleRelationship = {
  __typename?: "RoleUsersHasRoleRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: UserRoleProperties;
};

export type RoleUsersHasRoleUpdateConnectionInput = {
  edge?: InputMaybe<UserRolePropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type RoleUsersHasRoleUpdateFieldInput = {
  connect?: InputMaybe<Array<RoleUsersHasRoleConnectFieldInput>>;
  create?: InputMaybe<Array<RoleUsersHasRoleCreateFieldInput>>;
  delete?: InputMaybe<Array<RoleUsersHasRoleDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<RoleUsersHasRoleDisconnectFieldInput>>;
  update?: InputMaybe<RoleUsersHasRoleUpdateConnectionInput>;
  where?: InputMaybe<RoleUsersHasRoleConnectionWhere>;
};

export type RoleWhere = {
  AND?: InputMaybe<Array<RoleWhere>>;
  NOT?: InputMaybe<RoleWhere>;
  OR?: InputMaybe<Array<RoleWhere>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  permissionsAggregate?: InputMaybe<RolePermissionsAggregateInput>;
  /** Return Roles where all of the related RolePermissionsConnections match this filter */
  permissionsConnection_ALL?: InputMaybe<RolePermissionsConnectionWhere>;
  /** Return Roles where none of the related RolePermissionsConnections match this filter */
  permissionsConnection_NONE?: InputMaybe<RolePermissionsConnectionWhere>;
  /** Return Roles where one of the related RolePermissionsConnections match this filter */
  permissionsConnection_SINGLE?: InputMaybe<RolePermissionsConnectionWhere>;
  /** Return Roles where some of the related RolePermissionsConnections match this filter */
  permissionsConnection_SOME?: InputMaybe<RolePermissionsConnectionWhere>;
  /** Return Roles where all of the related Permissions match this filter */
  permissions_ALL?: InputMaybe<PermissionWhere>;
  /** Return Roles where none of the related Permissions match this filter */
  permissions_NONE?: InputMaybe<PermissionWhere>;
  /** Return Roles where one of the related Permissions match this filter */
  permissions_SINGLE?: InputMaybe<PermissionWhere>;
  /** Return Roles where some of the related Permissions match this filter */
  permissions_SOME?: InputMaybe<PermissionWhere>;
  usersHasRoleAggregate?: InputMaybe<RoleUsersHasRoleAggregateInput>;
  /** Return Roles where all of the related RoleUsersHasRoleConnections match this filter */
  usersHasRoleConnection_ALL?: InputMaybe<RoleUsersHasRoleConnectionWhere>;
  /** Return Roles where none of the related RoleUsersHasRoleConnections match this filter */
  usersHasRoleConnection_NONE?: InputMaybe<RoleUsersHasRoleConnectionWhere>;
  /** Return Roles where one of the related RoleUsersHasRoleConnections match this filter */
  usersHasRoleConnection_SINGLE?: InputMaybe<RoleUsersHasRoleConnectionWhere>;
  /** Return Roles where some of the related RoleUsersHasRoleConnections match this filter */
  usersHasRoleConnection_SOME?: InputMaybe<RoleUsersHasRoleConnectionWhere>;
  /** Return Roles where all of the related Users match this filter */
  usersHasRole_ALL?: InputMaybe<UserWhere>;
  /** Return Roles where none of the related Users match this filter */
  usersHasRole_NONE?: InputMaybe<UserWhere>;
  /** Return Roles where one of the related Users match this filter */
  usersHasRole_SINGLE?: InputMaybe<UserWhere>;
  /** Return Roles where some of the related Users match this filter */
  usersHasRole_SOME?: InputMaybe<UserWhere>;
};

export type RolesConnection = {
  __typename?: "RolesConnection";
  edges: Array<RoleEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type School = {
  __typename?: "School";
  icon?: Maybe<Scalars["String"]["output"]>;
  id: Scalars["ID"]["output"];
  providerName?: Maybe<Scalars["String"]["output"]>;
  teamName?: Maybe<Scalars["String"]["output"]>;
};

export type SchoolAggregateSelection = {
  __typename?: "SchoolAggregateSelection";
  count: Scalars["Int"]["output"];
  icon: StringAggregateSelection;
  id: IdAggregateSelection;
  providerName: StringAggregateSelection;
  teamName: StringAggregateSelection;
};

export type SchoolConnectWhere = {
  node: SchoolWhere;
};

export type SchoolCreateInput = {
  icon?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  providerName?: InputMaybe<Scalars["String"]["input"]>;
  teamName?: InputMaybe<Scalars["String"]["input"]>;
};

export type SchoolEdge = {
  __typename?: "SchoolEdge";
  cursor: Scalars["String"]["output"];
  node: School;
};

export type SchoolOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more SchoolSort objects to sort Schools by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SchoolSort>>;
};

/** Fields to sort Schools by. The order in which sorts are applied is not guaranteed when specifying many fields in one SchoolSort object. */
export type SchoolSort = {
  icon?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  providerName?: InputMaybe<SortDirection>;
  teamName?: InputMaybe<SortDirection>;
};

export type SchoolUpdateInput = {
  icon?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  providerName?: InputMaybe<Scalars["String"]["input"]>;
  teamName?: InputMaybe<Scalars["String"]["input"]>;
};

export type SchoolWhere = {
  AND?: InputMaybe<Array<SchoolWhere>>;
  NOT?: InputMaybe<SchoolWhere>;
  OR?: InputMaybe<Array<SchoolWhere>>;
  icon?: InputMaybe<Scalars["String"]["input"]>;
  icon_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  icon_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  icon_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  icon_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  providerName?: InputMaybe<Scalars["String"]["input"]>;
  providerName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  providerName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  providerName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  providerName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  teamName?: InputMaybe<Scalars["String"]["input"]>;
  teamName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  teamName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  teamName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  teamName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type SchoolsConnection = {
  __typename?: "SchoolsConnection";
  edges: Array<SchoolEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type SessionResponse = {
  __typename?: "SessionResponse";
  isValid: Scalars["Boolean"]["output"];
};

export type SessionResponseAggregateSelection = {
  __typename?: "SessionResponseAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type SessionResponseCreateInput = {
  isValid: Scalars["Boolean"]["input"];
};

export type SessionResponseEdge = {
  __typename?: "SessionResponseEdge";
  cursor: Scalars["String"]["output"];
  node: SessionResponse;
};

export type SessionResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more SessionResponseSort objects to sort SessionResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SessionResponseSort>>;
};

/** Fields to sort SessionResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one SessionResponseSort object. */
export type SessionResponseSort = {
  isValid?: InputMaybe<SortDirection>;
};

export type SessionResponseUpdateInput = {
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SessionResponseWhere = {
  AND?: InputMaybe<Array<SessionResponseWhere>>;
  NOT?: InputMaybe<SessionResponseWhere>;
  OR?: InputMaybe<Array<SessionResponseWhere>>;
  isValid?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type SessionResponsesConnection = {
  __typename?: "SessionResponsesConnection";
  edges: Array<SessionResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** An enum for sorting in either ascending or descending order. */
export enum SortDirection {
  /** Sort by field values in ascending order. */
  Asc = "ASC",
  /** Sort by field values in descending order. */
  Desc = "DESC",
}

export type StringAggregateSelection = {
  __typename?: "StringAggregateSelection";
  longest?: Maybe<Scalars["String"]["output"]>;
  shortest?: Maybe<Scalars["String"]["output"]>;
};

export type Subchapter = {
  __typename?: "Subchapter";
  isRelevant?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
};

export type SubchapterAggregateSelection = {
  __typename?: "SubchapterAggregateSelection";
  count: Scalars["Int"]["output"];
  name: StringAggregateSelection;
};

export type SubchapterCreateInput = {
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
};

export type SubchapterDetails = {
  __typename?: "SubchapterDetails";
  description?: Maybe<Scalars["String"]["output"]>;
  id?: Maybe<Scalars["ID"]["output"]>;
  message?: Maybe<Scalars["String"]["output"]>;
  name?: Maybe<Scalars["String"]["output"]>;
  objectives?: Maybe<Array<Maybe<LearningObjective>>>;
  success: Scalars["Boolean"]["output"];
  totalMastery?: Maybe<Scalars["Float"]["output"]>;
};

export type SubchapterDetailsAggregateSelection = {
  __typename?: "SubchapterDetailsAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  message: StringAggregateSelection;
  name: StringAggregateSelection;
  totalMastery: FloatAggregateSelection;
};

export type SubchapterDetailsConnection = {
  __typename?: "SubchapterDetailsConnection";
  edges: Array<SubchapterDetailsEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type SubchapterDetailsCreateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
};

export type SubchapterDetailsEdge = {
  __typename?: "SubchapterDetailsEdge";
  cursor: Scalars["String"]["output"];
  node: SubchapterDetails;
};

export type SubchapterDetailsOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more SubchapterDetailsSort objects to sort SubchapterDetails by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SubchapterDetailsSort>>;
};

/** Fields to sort SubchapterDetails by. The order in which sorts are applied is not guaranteed when specifying many fields in one SubchapterDetailsSort object. */
export type SubchapterDetailsSort = {
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
  totalMastery?: InputMaybe<SortDirection>;
};

export type SubchapterDetailsUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type SubchapterDetailsWhere = {
  AND?: InputMaybe<Array<SubchapterDetailsWhere>>;
  NOT?: InputMaybe<SubchapterDetailsWhere>;
  OR?: InputMaybe<Array<SubchapterDetailsWhere>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalMastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type SubchapterEdge = {
  __typename?: "SubchapterEdge";
  cursor: Scalars["String"]["output"];
  node: Subchapter;
};

export type SubchapterOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more SubchapterSort objects to sort Subchapters by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SubchapterSort>>;
};

/** Fields to sort Subchapters by. The order in which sorts are applied is not guaranteed when specifying many fields in one SubchapterSort object. */
export type SubchapterSort = {
  isRelevant?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type SubchapterUpdateInput = {
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type SubchapterWhere = {
  AND?: InputMaybe<Array<SubchapterWhere>>;
  NOT?: InputMaybe<SubchapterWhere>;
  OR?: InputMaybe<Array<SubchapterWhere>>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type SubchaptersConnection = {
  __typename?: "SubchaptersConnection";
  edges: Array<SubchapterEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Subfield = {
  __typename?: "Subfield";
  description?: Maybe<Scalars["String"]["output"]>;
  isRelevant?: Maybe<Scalars["Boolean"]["output"]>;
  name: Scalars["String"]["output"];
};

export type SubfieldAggregateSelection = {
  __typename?: "SubfieldAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  name: StringAggregateSelection;
};

export type SubfieldCreateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  name: Scalars["String"]["input"];
};

export type SubfieldEdge = {
  __typename?: "SubfieldEdge";
  cursor: Scalars["String"]["output"];
  node: Subfield;
};

export type SubfieldOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more SubfieldSort objects to sort Subfields by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SubfieldSort>>;
};

/** Fields to sort Subfields by. The order in which sorts are applied is not guaranteed when specifying many fields in one SubfieldSort object. */
export type SubfieldSort = {
  description?: InputMaybe<SortDirection>;
  isRelevant?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type SubfieldUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type SubfieldWhere = {
  AND?: InputMaybe<Array<SubfieldWhere>>;
  NOT?: InputMaybe<SubfieldWhere>;
  OR?: InputMaybe<Array<SubfieldWhere>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type SubfieldsConnection = {
  __typename?: "SubfieldsConnection";
  edges: Array<SubfieldEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Subscription = {
  __typename?: "Subscription";
  _empty?: Maybe<Scalars["String"]["output"]>;
};

export type Suggestion = {
  __typename?: "Suggestion";
  assignmentId?: Maybe<Scalars["String"]["output"]>;
  classId?: Maybe<Scalars["String"]["output"]>;
  content: Scalars["String"]["output"];
  created: Scalars["DateTime"]["output"];
  integrationId?: Maybe<Scalars["String"]["output"]>;
  journeyId?: Maybe<Scalars["String"]["output"]>;
  trackName?: Maybe<Scalars["String"]["output"]>;
  type: Scalars["String"]["output"];
  usersHasSuggestion: Array<User>;
  usersHasSuggestionAggregate?: Maybe<SuggestionUserUsersHasSuggestionAggregationSelection>;
  usersHasSuggestionConnection: SuggestionUsersHasSuggestionConnection;
};

export type SuggestionUsersHasSuggestionArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type SuggestionUsersHasSuggestionAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type SuggestionUsersHasSuggestionConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<SuggestionUsersHasSuggestionConnectionSort>>;
  where?: InputMaybe<SuggestionUsersHasSuggestionConnectionWhere>;
};

export type SuggestionAggregateSelection = {
  __typename?: "SuggestionAggregateSelection";
  assignmentId: StringAggregateSelection;
  classId: StringAggregateSelection;
  content: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  integrationId: StringAggregateSelection;
  journeyId: StringAggregateSelection;
  trackName: StringAggregateSelection;
  type: StringAggregateSelection;
};

export type SuggestionConnectInput = {
  usersHasSuggestion?: InputMaybe<Array<SuggestionUsersHasSuggestionConnectFieldInput>>;
};

export type SuggestionConnectWhere = {
  node: SuggestionWhere;
};

export type SuggestionCreateInput = {
  assignmentId?: InputMaybe<Scalars["String"]["input"]>;
  classId?: InputMaybe<Scalars["String"]["input"]>;
  content: Scalars["String"]["input"];
  created: Scalars["DateTime"]["input"];
  integrationId?: InputMaybe<Scalars["String"]["input"]>;
  journeyId?: InputMaybe<Scalars["String"]["input"]>;
  trackName?: InputMaybe<Scalars["String"]["input"]>;
  type: Scalars["String"]["input"];
  usersHasSuggestion?: InputMaybe<SuggestionUsersHasSuggestionFieldInput>;
};

export type SuggestionDeleteInput = {
  usersHasSuggestion?: InputMaybe<Array<SuggestionUsersHasSuggestionDeleteFieldInput>>;
};

export type SuggestionDisconnectInput = {
  usersHasSuggestion?: InputMaybe<Array<SuggestionUsersHasSuggestionDisconnectFieldInput>>;
};

export type SuggestionEdge = {
  __typename?: "SuggestionEdge";
  cursor: Scalars["String"]["output"];
  node: Suggestion;
};

export type SuggestionOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more SuggestionSort objects to sort Suggestions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<SuggestionSort>>;
};

export type SuggestionRelationInput = {
  usersHasSuggestion?: InputMaybe<Array<SuggestionUsersHasSuggestionCreateFieldInput>>;
};

/** Fields to sort Suggestions by. The order in which sorts are applied is not guaranteed when specifying many fields in one SuggestionSort object. */
export type SuggestionSort = {
  assignmentId?: InputMaybe<SortDirection>;
  classId?: InputMaybe<SortDirection>;
  content?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  integrationId?: InputMaybe<SortDirection>;
  journeyId?: InputMaybe<SortDirection>;
  trackName?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
};

export type SuggestionUpdateInput = {
  assignmentId?: InputMaybe<Scalars["String"]["input"]>;
  classId?: InputMaybe<Scalars["String"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  integrationId?: InputMaybe<Scalars["String"]["input"]>;
  journeyId?: InputMaybe<Scalars["String"]["input"]>;
  trackName?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSuggestion?: InputMaybe<Array<SuggestionUsersHasSuggestionUpdateFieldInput>>;
};

export type SuggestionUserUsersHasSuggestionAggregationSelection = {
  __typename?: "SuggestionUserUsersHasSuggestionAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<SuggestionUserUsersHasSuggestionNodeAggregateSelection>;
};

export type SuggestionUserUsersHasSuggestionNodeAggregateSelection = {
  __typename?: "SuggestionUserUsersHasSuggestionNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type SuggestionUsersHasSuggestionAggregateInput = {
  AND?: InputMaybe<Array<SuggestionUsersHasSuggestionAggregateInput>>;
  NOT?: InputMaybe<SuggestionUsersHasSuggestionAggregateInput>;
  OR?: InputMaybe<Array<SuggestionUsersHasSuggestionAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<SuggestionUsersHasSuggestionNodeAggregationWhereInput>;
};

export type SuggestionUsersHasSuggestionConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type SuggestionUsersHasSuggestionConnection = {
  __typename?: "SuggestionUsersHasSuggestionConnection";
  edges: Array<SuggestionUsersHasSuggestionRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type SuggestionUsersHasSuggestionConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type SuggestionUsersHasSuggestionConnectionWhere = {
  AND?: InputMaybe<Array<SuggestionUsersHasSuggestionConnectionWhere>>;
  NOT?: InputMaybe<SuggestionUsersHasSuggestionConnectionWhere>;
  OR?: InputMaybe<Array<SuggestionUsersHasSuggestionConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type SuggestionUsersHasSuggestionCreateFieldInput = {
  node: UserCreateInput;
};

export type SuggestionUsersHasSuggestionDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<SuggestionUsersHasSuggestionConnectionWhere>;
};

export type SuggestionUsersHasSuggestionDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<SuggestionUsersHasSuggestionConnectionWhere>;
};

export type SuggestionUsersHasSuggestionFieldInput = {
  connect?: InputMaybe<Array<SuggestionUsersHasSuggestionConnectFieldInput>>;
  create?: InputMaybe<Array<SuggestionUsersHasSuggestionCreateFieldInput>>;
};

export type SuggestionUsersHasSuggestionNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<SuggestionUsersHasSuggestionNodeAggregationWhereInput>>;
  NOT?: InputMaybe<SuggestionUsersHasSuggestionNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<SuggestionUsersHasSuggestionNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type SuggestionUsersHasSuggestionRelationship = {
  __typename?: "SuggestionUsersHasSuggestionRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type SuggestionUsersHasSuggestionUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type SuggestionUsersHasSuggestionUpdateFieldInput = {
  connect?: InputMaybe<Array<SuggestionUsersHasSuggestionConnectFieldInput>>;
  create?: InputMaybe<Array<SuggestionUsersHasSuggestionCreateFieldInput>>;
  delete?: InputMaybe<Array<SuggestionUsersHasSuggestionDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<SuggestionUsersHasSuggestionDisconnectFieldInput>>;
  update?: InputMaybe<SuggestionUsersHasSuggestionUpdateConnectionInput>;
  where?: InputMaybe<SuggestionUsersHasSuggestionConnectionWhere>;
};

export type SuggestionWhere = {
  AND?: InputMaybe<Array<SuggestionWhere>>;
  NOT?: InputMaybe<SuggestionWhere>;
  OR?: InputMaybe<Array<SuggestionWhere>>;
  assignmentId?: InputMaybe<Scalars["String"]["input"]>;
  assignmentId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  assignmentId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  assignmentId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  assignmentId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  classId?: InputMaybe<Scalars["String"]["input"]>;
  classId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  classId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  classId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  classId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  content?: InputMaybe<Scalars["String"]["input"]>;
  content_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  content_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  content_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  content_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  integrationId?: InputMaybe<Scalars["String"]["input"]>;
  integrationId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  integrationId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  integrationId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  integrationId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  journeyId?: InputMaybe<Scalars["String"]["input"]>;
  journeyId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  journeyId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  journeyId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  journeyId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  trackName?: InputMaybe<Scalars["String"]["input"]>;
  trackName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  trackName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  trackName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  trackName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSuggestionAggregate?: InputMaybe<SuggestionUsersHasSuggestionAggregateInput>;
  /** Return Suggestions where all of the related SuggestionUsersHasSuggestionConnections match this filter */
  usersHasSuggestionConnection_ALL?: InputMaybe<SuggestionUsersHasSuggestionConnectionWhere>;
  /** Return Suggestions where none of the related SuggestionUsersHasSuggestionConnections match this filter */
  usersHasSuggestionConnection_NONE?: InputMaybe<SuggestionUsersHasSuggestionConnectionWhere>;
  /** Return Suggestions where one of the related SuggestionUsersHasSuggestionConnections match this filter */
  usersHasSuggestionConnection_SINGLE?: InputMaybe<SuggestionUsersHasSuggestionConnectionWhere>;
  /** Return Suggestions where some of the related SuggestionUsersHasSuggestionConnections match this filter */
  usersHasSuggestionConnection_SOME?: InputMaybe<SuggestionUsersHasSuggestionConnectionWhere>;
  /** Return Suggestions where all of the related Users match this filter */
  usersHasSuggestion_ALL?: InputMaybe<UserWhere>;
  /** Return Suggestions where none of the related Users match this filter */
  usersHasSuggestion_NONE?: InputMaybe<UserWhere>;
  /** Return Suggestions where one of the related Users match this filter */
  usersHasSuggestion_SINGLE?: InputMaybe<UserWhere>;
  /** Return Suggestions where some of the related Users match this filter */
  usersHasSuggestion_SOME?: InputMaybe<UserWhere>;
};

export type SuggestionsConnection = {
  __typename?: "SuggestionsConnection";
  edges: Array<SuggestionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Tenant = {
  __typename?: "Tenant";
  additionalScope?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  bugReportEmails: Scalars["String"]["output"];
  companionName: Scalars["String"]["output"];
  connectURL: Scalars["String"]["output"];
  created?: Maybe<Scalars["Date"]["output"]>;
  customNews: Scalars["Boolean"]["output"];
  disableWebsearchWhenRAG: Scalars["Boolean"]["output"];
  emulationStatement: Scalars["String"]["output"];
  externalSendGridAppInviteTemplateId?: Maybe<Scalars["String"]["output"]>;
  externalSendGridDailyEmailTemplateId?: Maybe<Scalars["String"]["output"]>;
  externalSendGridFromEmail?: Maybe<Scalars["String"]["output"]>;
  externalSendGridFromName?: Maybe<Scalars["String"]["output"]>;
  externalSendGridTwilioServiceSID?: Maybe<Scalars["String"]["output"]>;
  externalStripeHasTrial?: Maybe<Scalars["Boolean"]["output"]>;
  externalStripeStripeSubscriptionId?: Maybe<Scalars["String"]["output"]>;
  externalStripeTrialDays?: Maybe<Scalars["Int"]["output"]>;
  gameMenuBg: Scalars["String"]["output"];
  gameMenuCardBg: Scalars["String"]["output"];
  gameMenuCardButton: Scalars["String"]["output"];
  gameMenuCardButtonText: Scalars["String"]["output"];
  gameMenuCardRefresh: Scalars["String"]["output"];
  gameMenuProgress: Scalars["String"]["output"];
  gameMenuRefresh: Scalars["String"]["output"];
  gameMenuRefreshText: Scalars["String"]["output"];
  gameMenuTrack: Scalars["String"]["output"];
  generalPrompt: Scalars["String"]["output"];
  hasEmailVerification: Scalars["Boolean"]["output"];
  id: Scalars["ID"]["output"];
  isFreemium: Scalars["Boolean"]["output"];
  isLMS: Scalars["Boolean"]["output"];
  isLTI: Scalars["Boolean"]["output"];
  isMoodleOffering: Scalars["Boolean"]["output"];
  isPrivateLabelIcon: Scalars["Boolean"]["output"];
  isSuggestedInsights: Scalars["Boolean"]["output"];
  key: Scalars["String"]["output"];
  last_modified?: Maybe<Scalars["Date"]["output"]>;
  learningEmulationStatement: Scalars["String"]["output"];
  learningFieldColor: Scalars["String"]["output"];
  learningSubFieldColor: Scalars["String"]["output"];
  logo: Scalars["String"]["output"];
  metaTagsAppleTouchIcon?: Maybe<Scalars["String"]["output"]>;
  metaTagsDescription?: Maybe<Scalars["String"]["output"]>;
  metaTagsThemeColor?: Maybe<Scalars["String"]["output"]>;
  metaTagsTitle?: Maybe<Scalars["String"]["output"]>;
  metaTagsTwitterCard?: Maybe<Scalars["String"]["output"]>;
  metaTagsTwitterDescription?: Maybe<Scalars["String"]["output"]>;
  metaTagsTwitterImage?: Maybe<Scalars["String"]["output"]>;
  metaTagsTwitterSite?: Maybe<Scalars["String"]["output"]>;
  metaTagsTwitterTitle?: Maybe<Scalars["String"]["output"]>;
  moderationHarassment?: Maybe<Scalars["Boolean"]["output"]>;
  moderationHarassmentThreatening?: Maybe<Scalars["Boolean"]["output"]>;
  moderationHate?: Maybe<Scalars["Boolean"]["output"]>;
  moderationHateThreatening?: Maybe<Scalars["Boolean"]["output"]>;
  moderationSelfHarm?: Maybe<Scalars["Boolean"]["output"]>;
  moderationSelfHarmInstructions?: Maybe<Scalars["Boolean"]["output"]>;
  moderationSelfHarmIntent?: Maybe<Scalars["Boolean"]["output"]>;
  moderationSexual?: Maybe<Scalars["Boolean"]["output"]>;
  moderationSexualMinors?: Maybe<Scalars["Boolean"]["output"]>;
  moderationViolence?: Maybe<Scalars["Boolean"]["output"]>;
  moderationViolenceGraphic?: Maybe<Scalars["Boolean"]["output"]>;
  moodleCourses: Array<Scalars["Int"]["output"]>;
  newsSearchTerms: Scalars["String"]["output"];
  newsSectionTitle: Scalars["String"]["output"];
  onboardingPrompt: Scalars["String"]["output"];
  primaryColor: Scalars["String"]["output"];
  scope: Array<Scalars["String"]["output"]>;
  showFeedback: Scalars["Boolean"]["output"];
  showInviteFriends: Scalars["Boolean"]["output"];
  showJobsTab: Scalars["Boolean"]["output"];
  showMiniLogo: Scalars["Boolean"]["output"];
  showNews: Scalars["Boolean"]["output"];
  showObjectivesTab: Scalars["Boolean"]["output"];
  tenantDomain: Scalars["String"]["output"];
  termsOfServiceLink: Scalars["String"]["output"];
  upgradeModalBody: Scalars["String"]["output"];
  users: Array<User>;
  usersAggregate?: Maybe<TenantUserUsersAggregationSelection>;
  usersConnection: TenantUsersConnection;
};

export type TenantUsersArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type TenantUsersAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type TenantUsersConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<TenantUsersConnectionSort>>;
  where?: InputMaybe<TenantUsersConnectionWhere>;
};

export type TenantAggregateSelection = {
  __typename?: "TenantAggregateSelection";
  bugReportEmails: StringAggregateSelection;
  companionName: StringAggregateSelection;
  connectURL: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  emulationStatement: StringAggregateSelection;
  externalSendGridAppInviteTemplateId: StringAggregateSelection;
  externalSendGridDailyEmailTemplateId: StringAggregateSelection;
  externalSendGridFromEmail: StringAggregateSelection;
  externalSendGridFromName: StringAggregateSelection;
  externalSendGridTwilioServiceSID: StringAggregateSelection;
  externalStripeStripeSubscriptionId: StringAggregateSelection;
  externalStripeTrialDays: IntAggregateSelection;
  gameMenuBg: StringAggregateSelection;
  gameMenuCardBg: StringAggregateSelection;
  gameMenuCardButton: StringAggregateSelection;
  gameMenuCardButtonText: StringAggregateSelection;
  gameMenuCardRefresh: StringAggregateSelection;
  gameMenuProgress: StringAggregateSelection;
  gameMenuRefresh: StringAggregateSelection;
  gameMenuRefreshText: StringAggregateSelection;
  gameMenuTrack: StringAggregateSelection;
  generalPrompt: StringAggregateSelection;
  id: IdAggregateSelection;
  key: StringAggregateSelection;
  learningEmulationStatement: StringAggregateSelection;
  learningFieldColor: StringAggregateSelection;
  learningSubFieldColor: StringAggregateSelection;
  logo: StringAggregateSelection;
  metaTagsAppleTouchIcon: StringAggregateSelection;
  metaTagsDescription: StringAggregateSelection;
  metaTagsThemeColor: StringAggregateSelection;
  metaTagsTitle: StringAggregateSelection;
  metaTagsTwitterCard: StringAggregateSelection;
  metaTagsTwitterDescription: StringAggregateSelection;
  metaTagsTwitterImage: StringAggregateSelection;
  metaTagsTwitterSite: StringAggregateSelection;
  metaTagsTwitterTitle: StringAggregateSelection;
  newsSearchTerms: StringAggregateSelection;
  newsSectionTitle: StringAggregateSelection;
  onboardingPrompt: StringAggregateSelection;
  primaryColor: StringAggregateSelection;
  tenantDomain: StringAggregateSelection;
  termsOfServiceLink: StringAggregateSelection;
  upgradeModalBody: StringAggregateSelection;
};

export type TenantConnectInput = {
  users?: InputMaybe<Array<TenantUsersConnectFieldInput>>;
};

export type TenantConnectWhere = {
  node: TenantWhere;
};

export type TenantCreateInput = {
  additionalScope?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  bugReportEmails: Scalars["String"]["input"];
  companionName: Scalars["String"]["input"];
  connectURL: Scalars["String"]["input"];
  created?: InputMaybe<Scalars["Date"]["input"]>;
  customNews: Scalars["Boolean"]["input"];
  disableWebsearchWhenRAG: Scalars["Boolean"]["input"];
  emulationStatement: Scalars["String"]["input"];
  externalSendGridAppInviteTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridDailyEmailTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromEmail?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromName?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridTwilioServiceSID?: InputMaybe<Scalars["String"]["input"]>;
  externalStripeHasTrial?: InputMaybe<Scalars["Boolean"]["input"]>;
  externalStripeStripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  externalStripeTrialDays?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg: Scalars["String"]["input"];
  gameMenuCardBg: Scalars["String"]["input"];
  gameMenuCardButton: Scalars["String"]["input"];
  gameMenuCardButtonText: Scalars["String"]["input"];
  gameMenuCardRefresh: Scalars["String"]["input"];
  gameMenuProgress: Scalars["String"]["input"];
  gameMenuRefresh: Scalars["String"]["input"];
  gameMenuRefreshText: Scalars["String"]["input"];
  gameMenuTrack: Scalars["String"]["input"];
  generalPrompt: Scalars["String"]["input"];
  hasEmailVerification: Scalars["Boolean"]["input"];
  id: Scalars["ID"]["input"];
  isFreemium: Scalars["Boolean"]["input"];
  isLMS: Scalars["Boolean"]["input"];
  isLTI: Scalars["Boolean"]["input"];
  isMoodleOffering: Scalars["Boolean"]["input"];
  isPrivateLabelIcon: Scalars["Boolean"]["input"];
  isSuggestedInsights: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  last_modified?: InputMaybe<Scalars["Date"]["input"]>;
  learningEmulationStatement: Scalars["String"]["input"];
  learningFieldColor: Scalars["String"]["input"];
  learningSubFieldColor: Scalars["String"]["input"];
  logo: Scalars["String"]["input"];
  metaTagsAppleTouchIcon?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsThemeColor?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTitle?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterCard?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterImage?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterSite?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterTitle?: InputMaybe<Scalars["String"]["input"]>;
  moderationHarassment?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHarassmentThreatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHate?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHateThreatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarm?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarmInstructions?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarmIntent?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSexual?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSexualMinors?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationViolence?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationViolenceGraphic?: InputMaybe<Scalars["Boolean"]["input"]>;
  moodleCourses: Array<Scalars["Int"]["input"]>;
  newsSearchTerms: Scalars["String"]["input"];
  newsSectionTitle: Scalars["String"]["input"];
  onboardingPrompt: Scalars["String"]["input"];
  primaryColor: Scalars["String"]["input"];
  scope: Array<Scalars["String"]["input"]>;
  showFeedback: Scalars["Boolean"]["input"];
  showInviteFriends: Scalars["Boolean"]["input"];
  showJobsTab: Scalars["Boolean"]["input"];
  showMiniLogo: Scalars["Boolean"]["input"];
  showNews: Scalars["Boolean"]["input"];
  showObjectivesTab: Scalars["Boolean"]["input"];
  tenantDomain: Scalars["String"]["input"];
  termsOfServiceLink: Scalars["String"]["input"];
  upgradeModalBody: Scalars["String"]["input"];
  users?: InputMaybe<TenantUsersFieldInput>;
};

export type TenantDeleteInput = {
  users?: InputMaybe<Array<TenantUsersDeleteFieldInput>>;
};

export type TenantDisconnectInput = {
  users?: InputMaybe<Array<TenantUsersDisconnectFieldInput>>;
};

export type TenantEdge = {
  __typename?: "TenantEdge";
  cursor: Scalars["String"]["output"];
  node: Tenant;
};

export type TenantInput = {
  additionalScope?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  bugReportEmails: Scalars["String"]["input"];
  companionName: Scalars["String"]["input"];
  connectURL: Scalars["String"]["input"];
  customNews: Scalars["Boolean"]["input"];
  disableWebsearchWhenRAG: Scalars["Boolean"]["input"];
  emulationStatement: Scalars["String"]["input"];
  externalSendGridAppInviteTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridDailyEmailTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromEmail?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromName?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridTwilioServiceSID?: InputMaybe<Scalars["String"]["input"]>;
  externalStripeHasTrial?: InputMaybe<Scalars["Boolean"]["input"]>;
  externalStripeStripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  externalStripeTrialDays?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg: Scalars["String"]["input"];
  gameMenuCardBg: Scalars["String"]["input"];
  gameMenuCardButton: Scalars["String"]["input"];
  gameMenuCardButtonText: Scalars["String"]["input"];
  gameMenuCardRefresh: Scalars["String"]["input"];
  gameMenuProgress: Scalars["String"]["input"];
  gameMenuRefresh: Scalars["String"]["input"];
  gameMenuRefreshText: Scalars["String"]["input"];
  gameMenuTrack: Scalars["String"]["input"];
  generalPrompt: Scalars["String"]["input"];
  hasEmailVerification: Scalars["Boolean"]["input"];
  id: Scalars["ID"]["input"];
  isFreemium: Scalars["Boolean"]["input"];
  isLMS: Scalars["Boolean"]["input"];
  isLTI: Scalars["Boolean"]["input"];
  isMoodleOffering: Scalars["Boolean"]["input"];
  isPrivateLabelIcon: Scalars["Boolean"]["input"];
  isSuggestedInsights: Scalars["Boolean"]["input"];
  key: Scalars["String"]["input"];
  learningEmulationStatement: Scalars["String"]["input"];
  learningFieldColor: Scalars["String"]["input"];
  learningSubFieldColor: Scalars["String"]["input"];
  logo: Scalars["String"]["input"];
  metaTagsAppleTouchIcon?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsThemeColor?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTitle?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterCard?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterImage?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterSite?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterTitle?: InputMaybe<Scalars["String"]["input"]>;
  moderationHarassment?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHarassmentThreatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHate?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHateThreatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarm?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarmInstructions?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarmIntent?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSexual?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSexualMinors?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationViolence?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationViolenceGraphic?: InputMaybe<Scalars["Boolean"]["input"]>;
  moodleCourses: Array<Scalars["Int"]["input"]>;
  newsSearchTerms: Scalars["String"]["input"];
  newsSectionTitle: Scalars["String"]["input"];
  onboardingPrompt: Scalars["String"]["input"];
  primaryColor: Scalars["String"]["input"];
  scope: Array<Scalars["String"]["input"]>;
  showFeedback: Scalars["Boolean"]["input"];
  showInviteFriends: Scalars["Boolean"]["input"];
  showJobsTab: Scalars["Boolean"]["input"];
  showMiniLogo: Scalars["Boolean"]["input"];
  showNews: Scalars["Boolean"]["input"];
  showObjectivesTab: Scalars["Boolean"]["input"];
  tenantDomain: Scalars["String"]["input"];
  termsOfServiceLink: Scalars["String"]["input"];
  upgradeModalBody: Scalars["String"]["input"];
};

export type TenantOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more TenantSort objects to sort Tenants by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TenantSort>>;
};

export type TenantRelationInput = {
  users?: InputMaybe<Array<TenantUsersCreateFieldInput>>;
};

export type TenantResponse = {
  __typename?: "TenantResponse";
  count?: Maybe<Scalars["Int"]["output"]>;
  data?: Maybe<Array<Maybe<Tenant>>>;
  message?: Maybe<Scalars["String"]["output"]>;
  success?: Maybe<Scalars["Boolean"]["output"]>;
};

export type TenantResponseAggregateSelection = {
  __typename?: "TenantResponseAggregateSelection";
  count: IntAggregateSelection;
  message: StringAggregateSelection;
};

export type TenantResponseCreateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TenantResponseEdge = {
  __typename?: "TenantResponseEdge";
  cursor: Scalars["String"]["output"];
  node: TenantResponse;
};

export type TenantResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more TenantResponseSort objects to sort TenantResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TenantResponseSort>>;
};

/** Fields to sort TenantResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one TenantResponseSort object. */
export type TenantResponseSort = {
  count?: InputMaybe<SortDirection>;
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type TenantResponseUpdateInput = {
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  count_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TenantResponseWhere = {
  AND?: InputMaybe<Array<TenantResponseWhere>>;
  NOT?: InputMaybe<TenantResponseWhere>;
  OR?: InputMaybe<Array<TenantResponseWhere>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type TenantResponsesConnection = {
  __typename?: "TenantResponsesConnection";
  edges: Array<TenantResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/** Fields to sort Tenants by. The order in which sorts are applied is not guaranteed when specifying many fields in one TenantSort object. */
export type TenantSort = {
  bugReportEmails?: InputMaybe<SortDirection>;
  companionName?: InputMaybe<SortDirection>;
  connectURL?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  customNews?: InputMaybe<SortDirection>;
  disableWebsearchWhenRAG?: InputMaybe<SortDirection>;
  emulationStatement?: InputMaybe<SortDirection>;
  externalSendGridAppInviteTemplateId?: InputMaybe<SortDirection>;
  externalSendGridDailyEmailTemplateId?: InputMaybe<SortDirection>;
  externalSendGridFromEmail?: InputMaybe<SortDirection>;
  externalSendGridFromName?: InputMaybe<SortDirection>;
  externalSendGridTwilioServiceSID?: InputMaybe<SortDirection>;
  externalStripeHasTrial?: InputMaybe<SortDirection>;
  externalStripeStripeSubscriptionId?: InputMaybe<SortDirection>;
  externalStripeTrialDays?: InputMaybe<SortDirection>;
  gameMenuBg?: InputMaybe<SortDirection>;
  gameMenuCardBg?: InputMaybe<SortDirection>;
  gameMenuCardButton?: InputMaybe<SortDirection>;
  gameMenuCardButtonText?: InputMaybe<SortDirection>;
  gameMenuCardRefresh?: InputMaybe<SortDirection>;
  gameMenuProgress?: InputMaybe<SortDirection>;
  gameMenuRefresh?: InputMaybe<SortDirection>;
  gameMenuRefreshText?: InputMaybe<SortDirection>;
  gameMenuTrack?: InputMaybe<SortDirection>;
  generalPrompt?: InputMaybe<SortDirection>;
  hasEmailVerification?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  isFreemium?: InputMaybe<SortDirection>;
  isLMS?: InputMaybe<SortDirection>;
  isLTI?: InputMaybe<SortDirection>;
  isMoodleOffering?: InputMaybe<SortDirection>;
  isPrivateLabelIcon?: InputMaybe<SortDirection>;
  isSuggestedInsights?: InputMaybe<SortDirection>;
  key?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  learningEmulationStatement?: InputMaybe<SortDirection>;
  learningFieldColor?: InputMaybe<SortDirection>;
  learningSubFieldColor?: InputMaybe<SortDirection>;
  logo?: InputMaybe<SortDirection>;
  metaTagsAppleTouchIcon?: InputMaybe<SortDirection>;
  metaTagsDescription?: InputMaybe<SortDirection>;
  metaTagsThemeColor?: InputMaybe<SortDirection>;
  metaTagsTitle?: InputMaybe<SortDirection>;
  metaTagsTwitterCard?: InputMaybe<SortDirection>;
  metaTagsTwitterDescription?: InputMaybe<SortDirection>;
  metaTagsTwitterImage?: InputMaybe<SortDirection>;
  metaTagsTwitterSite?: InputMaybe<SortDirection>;
  metaTagsTwitterTitle?: InputMaybe<SortDirection>;
  moderationHarassment?: InputMaybe<SortDirection>;
  moderationHarassmentThreatening?: InputMaybe<SortDirection>;
  moderationHate?: InputMaybe<SortDirection>;
  moderationHateThreatening?: InputMaybe<SortDirection>;
  moderationSelfHarm?: InputMaybe<SortDirection>;
  moderationSelfHarmInstructions?: InputMaybe<SortDirection>;
  moderationSelfHarmIntent?: InputMaybe<SortDirection>;
  moderationSexual?: InputMaybe<SortDirection>;
  moderationSexualMinors?: InputMaybe<SortDirection>;
  moderationViolence?: InputMaybe<SortDirection>;
  moderationViolenceGraphic?: InputMaybe<SortDirection>;
  newsSearchTerms?: InputMaybe<SortDirection>;
  newsSectionTitle?: InputMaybe<SortDirection>;
  onboardingPrompt?: InputMaybe<SortDirection>;
  primaryColor?: InputMaybe<SortDirection>;
  showFeedback?: InputMaybe<SortDirection>;
  showInviteFriends?: InputMaybe<SortDirection>;
  showJobsTab?: InputMaybe<SortDirection>;
  showMiniLogo?: InputMaybe<SortDirection>;
  showNews?: InputMaybe<SortDirection>;
  showObjectivesTab?: InputMaybe<SortDirection>;
  tenantDomain?: InputMaybe<SortDirection>;
  termsOfServiceLink?: InputMaybe<SortDirection>;
  upgradeModalBody?: InputMaybe<SortDirection>;
};

export type TenantUpdateInput = {
  additionalScope?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  additionalScope_POP?: InputMaybe<Scalars["Int"]["input"]>;
  additionalScope_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  bugReportEmails?: InputMaybe<Scalars["String"]["input"]>;
  companionName?: InputMaybe<Scalars["String"]["input"]>;
  connectURL?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["Date"]["input"]>;
  customNews?: InputMaybe<Scalars["Boolean"]["input"]>;
  disableWebsearchWhenRAG?: InputMaybe<Scalars["Boolean"]["input"]>;
  emulationStatement?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridAppInviteTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridDailyEmailTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromEmail?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromName?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridTwilioServiceSID?: InputMaybe<Scalars["String"]["input"]>;
  externalStripeHasTrial?: InputMaybe<Scalars["Boolean"]["input"]>;
  externalStripeStripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  externalStripeTrialDays?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardBg?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButton?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButtonText?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardRefresh?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuProgress?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefresh?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefreshText?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuTrack?: InputMaybe<Scalars["String"]["input"]>;
  generalPrompt?: InputMaybe<Scalars["String"]["input"]>;
  hasEmailVerification?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  isFreemium?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLMS?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLTI?: InputMaybe<Scalars["Boolean"]["input"]>;
  isMoodleOffering?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPrivateLabelIcon?: InputMaybe<Scalars["Boolean"]["input"]>;
  isSuggestedInsights?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["Date"]["input"]>;
  learningEmulationStatement?: InputMaybe<Scalars["String"]["input"]>;
  learningFieldColor?: InputMaybe<Scalars["String"]["input"]>;
  learningSubFieldColor?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsAppleTouchIcon?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsThemeColor?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTitle?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterCard?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterImage?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterSite?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterTitle?: InputMaybe<Scalars["String"]["input"]>;
  moderationHarassment?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHarassmentThreatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHate?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHateThreatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarm?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarmInstructions?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarmIntent?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSexual?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSexualMinors?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationViolence?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationViolenceGraphic?: InputMaybe<Scalars["Boolean"]["input"]>;
  moodleCourses?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  moodleCourses_POP?: InputMaybe<Scalars["Int"]["input"]>;
  moodleCourses_PUSH?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  newsSearchTerms?: InputMaybe<Scalars["String"]["input"]>;
  newsSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  onboardingPrompt?: InputMaybe<Scalars["String"]["input"]>;
  primaryColor?: InputMaybe<Scalars["String"]["input"]>;
  scope?: InputMaybe<Array<Scalars["String"]["input"]>>;
  scope_POP?: InputMaybe<Scalars["Int"]["input"]>;
  scope_PUSH?: InputMaybe<Array<Scalars["String"]["input"]>>;
  showFeedback?: InputMaybe<Scalars["Boolean"]["input"]>;
  showInviteFriends?: InputMaybe<Scalars["Boolean"]["input"]>;
  showJobsTab?: InputMaybe<Scalars["Boolean"]["input"]>;
  showMiniLogo?: InputMaybe<Scalars["Boolean"]["input"]>;
  showNews?: InputMaybe<Scalars["Boolean"]["input"]>;
  showObjectivesTab?: InputMaybe<Scalars["Boolean"]["input"]>;
  tenantDomain?: InputMaybe<Scalars["String"]["input"]>;
  termsOfServiceLink?: InputMaybe<Scalars["String"]["input"]>;
  upgradeModalBody?: InputMaybe<Scalars["String"]["input"]>;
  users?: InputMaybe<Array<TenantUsersUpdateFieldInput>>;
};

export type TenantUserUsersAggregationSelection = {
  __typename?: "TenantUserUsersAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<TenantUserUsersNodeAggregateSelection>;
};

export type TenantUserUsersNodeAggregateSelection = {
  __typename?: "TenantUserUsersNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type TenantUsersAggregateInput = {
  AND?: InputMaybe<Array<TenantUsersAggregateInput>>;
  NOT?: InputMaybe<TenantUsersAggregateInput>;
  OR?: InputMaybe<Array<TenantUsersAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<TenantUsersNodeAggregationWhereInput>;
};

export type TenantUsersConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type TenantUsersConnection = {
  __typename?: "TenantUsersConnection";
  edges: Array<TenantUsersRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TenantUsersConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type TenantUsersConnectionWhere = {
  AND?: InputMaybe<Array<TenantUsersConnectionWhere>>;
  NOT?: InputMaybe<TenantUsersConnectionWhere>;
  OR?: InputMaybe<Array<TenantUsersConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type TenantUsersCreateFieldInput = {
  node: UserCreateInput;
};

export type TenantUsersDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<TenantUsersConnectionWhere>;
};

export type TenantUsersDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<TenantUsersConnectionWhere>;
};

export type TenantUsersFieldInput = {
  connect?: InputMaybe<Array<TenantUsersConnectFieldInput>>;
  create?: InputMaybe<Array<TenantUsersCreateFieldInput>>;
};

export type TenantUsersNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TenantUsersNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TenantUsersNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TenantUsersNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TenantUsersRelationship = {
  __typename?: "TenantUsersRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type TenantUsersUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type TenantUsersUpdateFieldInput = {
  connect?: InputMaybe<Array<TenantUsersConnectFieldInput>>;
  create?: InputMaybe<Array<TenantUsersCreateFieldInput>>;
  delete?: InputMaybe<Array<TenantUsersDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TenantUsersDisconnectFieldInput>>;
  update?: InputMaybe<TenantUsersUpdateConnectionInput>;
  where?: InputMaybe<TenantUsersConnectionWhere>;
};

export type TenantWhere = {
  AND?: InputMaybe<Array<TenantWhere>>;
  NOT?: InputMaybe<TenantWhere>;
  OR?: InputMaybe<Array<TenantWhere>>;
  additionalScope?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  additionalScope_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  bugReportEmails?: InputMaybe<Scalars["String"]["input"]>;
  bugReportEmails_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  bugReportEmails_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  bugReportEmails_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  bugReportEmails_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  companionName?: InputMaybe<Scalars["String"]["input"]>;
  companionName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  companionName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  companionName_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  companionName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  connectURL?: InputMaybe<Scalars["String"]["input"]>;
  connectURL_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  connectURL_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  connectURL_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  connectURL_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["Date"]["input"]>;
  created_GT?: InputMaybe<Scalars["Date"]["input"]>;
  created_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  created_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  created_LT?: InputMaybe<Scalars["Date"]["input"]>;
  created_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  customNews?: InputMaybe<Scalars["Boolean"]["input"]>;
  disableWebsearchWhenRAG?: InputMaybe<Scalars["Boolean"]["input"]>;
  emulationStatement?: InputMaybe<Scalars["String"]["input"]>;
  emulationStatement_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  emulationStatement_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  emulationStatement_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  emulationStatement_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridAppInviteTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridAppInviteTemplateId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridAppInviteTemplateId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridAppInviteTemplateId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  externalSendGridAppInviteTemplateId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridDailyEmailTemplateId?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridDailyEmailTemplateId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridDailyEmailTemplateId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridDailyEmailTemplateId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  externalSendGridDailyEmailTemplateId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromEmail?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromEmail_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromEmail_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromEmail_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  externalSendGridFromEmail_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromName?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromName_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromName_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridFromName_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  externalSendGridFromName_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridTwilioServiceSID?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridTwilioServiceSID_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridTwilioServiceSID_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalSendGridTwilioServiceSID_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  externalSendGridTwilioServiceSID_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalStripeHasTrial?: InputMaybe<Scalars["Boolean"]["input"]>;
  externalStripeStripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  externalStripeStripeSubscriptionId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  externalStripeStripeSubscriptionId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalStripeStripeSubscriptionId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  externalStripeStripeSubscriptionId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  externalStripeTrialDays?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  externalStripeTrialDays_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuBg_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuBg_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuBg_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  gameMenuBg_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardBg?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardBg_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardBg_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardBg_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  gameMenuCardBg_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButton?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButtonText?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButtonText_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButtonText_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButtonText_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  gameMenuCardButtonText_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButton_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButton_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardButton_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  gameMenuCardButton_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardRefresh?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardRefresh_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardRefresh_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuCardRefresh_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  gameMenuCardRefresh_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuProgress?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuProgress_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuProgress_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuProgress_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  gameMenuProgress_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefresh?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefreshText?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefreshText_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefreshText_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefreshText_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  gameMenuRefreshText_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefresh_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefresh_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuRefresh_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  gameMenuRefresh_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuTrack?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuTrack_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuTrack_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gameMenuTrack_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  gameMenuTrack_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  generalPrompt?: InputMaybe<Scalars["String"]["input"]>;
  generalPrompt_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  generalPrompt_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  generalPrompt_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  generalPrompt_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  hasEmailVerification?: InputMaybe<Scalars["Boolean"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  isFreemium?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLMS?: InputMaybe<Scalars["Boolean"]["input"]>;
  isLTI?: InputMaybe<Scalars["Boolean"]["input"]>;
  isMoodleOffering?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPrivateLabelIcon?: InputMaybe<Scalars["Boolean"]["input"]>;
  isSuggestedInsights?: InputMaybe<Scalars["Boolean"]["input"]>;
  key?: InputMaybe<Scalars["String"]["input"]>;
  key_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  key_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  key_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  key_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  last_modified_LT?: InputMaybe<Scalars["Date"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  learningEmulationStatement?: InputMaybe<Scalars["String"]["input"]>;
  learningEmulationStatement_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  learningEmulationStatement_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  learningEmulationStatement_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  learningEmulationStatement_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  learningFieldColor?: InputMaybe<Scalars["String"]["input"]>;
  learningFieldColor_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  learningFieldColor_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  learningFieldColor_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  learningFieldColor_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  learningSubFieldColor?: InputMaybe<Scalars["String"]["input"]>;
  learningSubFieldColor_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  learningSubFieldColor_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  learningSubFieldColor_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  learningSubFieldColor_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  logo?: InputMaybe<Scalars["String"]["input"]>;
  logo_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  logo_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  logo_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  logo_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsAppleTouchIcon?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsAppleTouchIcon_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsAppleTouchIcon_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsAppleTouchIcon_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  metaTagsAppleTouchIcon_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsDescription_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsDescription_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsDescription_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  metaTagsDescription_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsThemeColor?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsThemeColor_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsThemeColor_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsThemeColor_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  metaTagsThemeColor_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTitle?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTitle_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTitle_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTitle_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  metaTagsTitle_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterCard?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterCard_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterCard_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterCard_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  metaTagsTwitterCard_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterDescription?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterDescription_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterDescription_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterDescription_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  metaTagsTwitterDescription_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterImage?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterImage_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterImage_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterImage_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  metaTagsTwitterImage_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterSite?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterSite_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterSite_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterSite_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  metaTagsTwitterSite_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterTitle?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterTitle_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterTitle_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  metaTagsTwitterTitle_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  metaTagsTwitterTitle_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  moderationHarassment?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHarassmentThreatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHate?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationHateThreatening?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarm?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarmInstructions?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSelfHarmIntent?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSexual?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationSexualMinors?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationViolence?: InputMaybe<Scalars["Boolean"]["input"]>;
  moderationViolenceGraphic?: InputMaybe<Scalars["Boolean"]["input"]>;
  moodleCourses?: InputMaybe<Array<Scalars["Int"]["input"]>>;
  moodleCourses_INCLUDES?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms?: InputMaybe<Scalars["String"]["input"]>;
  newsSearchTerms_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  newsSearchTerms_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  newsSearchTerms_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  newsSearchTerms_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  newsSectionTitle?: InputMaybe<Scalars["String"]["input"]>;
  newsSectionTitle_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  newsSectionTitle_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  newsSectionTitle_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  newsSectionTitle_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  onboardingPrompt?: InputMaybe<Scalars["String"]["input"]>;
  onboardingPrompt_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  onboardingPrompt_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  onboardingPrompt_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  onboardingPrompt_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  primaryColor?: InputMaybe<Scalars["String"]["input"]>;
  primaryColor_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  primaryColor_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  primaryColor_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  primaryColor_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  scope?: InputMaybe<Array<Scalars["String"]["input"]>>;
  scope_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  showFeedback?: InputMaybe<Scalars["Boolean"]["input"]>;
  showInviteFriends?: InputMaybe<Scalars["Boolean"]["input"]>;
  showJobsTab?: InputMaybe<Scalars["Boolean"]["input"]>;
  showMiniLogo?: InputMaybe<Scalars["Boolean"]["input"]>;
  showNews?: InputMaybe<Scalars["Boolean"]["input"]>;
  showObjectivesTab?: InputMaybe<Scalars["Boolean"]["input"]>;
  tenantDomain?: InputMaybe<Scalars["String"]["input"]>;
  tenantDomain_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  tenantDomain_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  tenantDomain_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  tenantDomain_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  termsOfServiceLink?: InputMaybe<Scalars["String"]["input"]>;
  termsOfServiceLink_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  termsOfServiceLink_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  termsOfServiceLink_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  termsOfServiceLink_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  upgradeModalBody?: InputMaybe<Scalars["String"]["input"]>;
  upgradeModalBody_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  upgradeModalBody_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  upgradeModalBody_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  upgradeModalBody_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersAggregate?: InputMaybe<TenantUsersAggregateInput>;
  /** Return Tenants where all of the related TenantUsersConnections match this filter */
  usersConnection_ALL?: InputMaybe<TenantUsersConnectionWhere>;
  /** Return Tenants where none of the related TenantUsersConnections match this filter */
  usersConnection_NONE?: InputMaybe<TenantUsersConnectionWhere>;
  /** Return Tenants where one of the related TenantUsersConnections match this filter */
  usersConnection_SINGLE?: InputMaybe<TenantUsersConnectionWhere>;
  /** Return Tenants where some of the related TenantUsersConnections match this filter */
  usersConnection_SOME?: InputMaybe<TenantUsersConnectionWhere>;
  /** Return Tenants where all of the related Users match this filter */
  users_ALL?: InputMaybe<UserWhere>;
  /** Return Tenants where none of the related Users match this filter */
  users_NONE?: InputMaybe<UserWhere>;
  /** Return Tenants where one of the related Users match this filter */
  users_SINGLE?: InputMaybe<UserWhere>;
  /** Return Tenants where some of the related Users match this filter */
  users_SOME?: InputMaybe<UserWhere>;
};

export type TenantsConnection = {
  __typename?: "TenantsConnection";
  edges: Array<TenantEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Todo = {
  __typename?: "Todo";
  achievements?: Maybe<Array<Maybe<Achievements>>>;
  challenges?: Maybe<Array<Maybe<Challenge>>>;
  completed: Scalars["Boolean"]["output"];
  completed_date?: Maybe<Scalars["Date"]["output"]>;
  coreValues?: Maybe<Array<Maybe<CoreValue>>>;
  date?: Maybe<Scalars["Date"]["output"]>;
  description?: Maybe<Scalars["String"]["output"]>;
  emotionalStates?: Maybe<Array<Maybe<EmotionalState>>>;
  feedback?: Maybe<Feedback>;
  goals?: Maybe<Array<Maybe<Goal>>>;
  hobbies?: Maybe<Array<Maybe<Hobby>>>;
  id: Scalars["ID"]["output"];
  interests?: Maybe<Array<Maybe<Interest>>>;
  isPartOfJourney?: Maybe<Scalars["Boolean"]["output"]>;
  isRecurring?: Maybe<Scalars["Boolean"]["output"]>;
  journeyId?: Maybe<Scalars["ID"]["output"]>;
  journeysHasTask: Array<Journey>;
  journeysHasTaskAggregate?: Maybe<TodoJourneyJourneysHasTaskAggregationSelection>;
  journeysHasTaskConnection: TodoJourneysHasTaskConnection;
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  lifeAspirations?: Maybe<Array<Maybe<LifeAspiration>>>;
  name: Scalars["String"]["output"];
  personalityTraits?: Maybe<Array<Maybe<PersonalityTrait>>>;
  pointsReceived?: Maybe<Scalars["Int"]["output"]>;
  preferences?: Maybe<Preferences>;
  priority?: Maybe<Scalars["String"]["output"]>;
  progress?: Maybe<Progress>;
  recurringDaily?: Maybe<Scalars["Boolean"]["output"]>;
  recurringDayOfMonth?: Maybe<Scalars["Int"]["output"]>;
  recurringDayOfWeek?: Maybe<Scalars["String"]["output"]>;
  recurringFrequency?: Maybe<Scalars["String"]["output"]>;
  recurringTime?: Maybe<Scalars["String"]["output"]>;
  reflections?: Maybe<Array<Maybe<Reflection>>>;
  timeOfDay?: Maybe<Scalars["String"]["output"]>;
  timezone?: Maybe<Scalars["String"]["output"]>;
  todos: Array<Todo>;
  user: User;
  usersHasTodo: Array<User>;
  usersHasTodoAggregate?: Maybe<TodoUserUsersHasTodoAggregationSelection>;
  usersHasTodoConnection: TodoUsersHasTodoConnection;
};

export type TodoJourneysHasTaskArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<JourneyOptions>;
  where?: InputMaybe<JourneyWhere>;
};

export type TodoJourneysHasTaskAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<JourneyWhere>;
};

export type TodoJourneysHasTaskConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<TodoJourneysHasTaskConnectionSort>>;
  where?: InputMaybe<TodoJourneysHasTaskConnectionWhere>;
};

export type TodoUsersHasTodoArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type TodoUsersHasTodoAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type TodoUsersHasTodoConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<TodoUsersHasTodoConnectionSort>>;
  where?: InputMaybe<TodoUsersHasTodoConnectionWhere>;
};

export type TodoAggregateSelection = {
  __typename?: "TodoAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  journeyId: IdAggregateSelection;
  name: StringAggregateSelection;
  pointsReceived: IntAggregateSelection;
  priority: StringAggregateSelection;
  recurringDayOfMonth: IntAggregateSelection;
  recurringDayOfWeek: StringAggregateSelection;
  recurringFrequency: StringAggregateSelection;
  recurringTime: StringAggregateSelection;
  timeOfDay: StringAggregateSelection;
  timezone: StringAggregateSelection;
};

export type TodoConnectInput = {
  journeysHasTask?: InputMaybe<Array<TodoJourneysHasTaskConnectFieldInput>>;
  usersHasTodo?: InputMaybe<Array<TodoUsersHasTodoConnectFieldInput>>;
};

export type TodoConnectWhere = {
  node: TodoWhere;
};

export type TodoCreateInput = {
  completed: Scalars["Boolean"]["input"];
  completed_date?: InputMaybe<Scalars["Date"]["input"]>;
  date?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id: Scalars["ID"]["input"];
  isPartOfJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyId?: InputMaybe<Scalars["ID"]["input"]>;
  journeysHasTask?: InputMaybe<TodoJourneysHasTaskFieldInput>;
  name: Scalars["String"]["input"];
  pointsReceived?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  recurringDaily?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringDayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek?: InputMaybe<Scalars["String"]["input"]>;
  recurringFrequency?: InputMaybe<Scalars["String"]["input"]>;
  recurringTime?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  usersHasTodo?: InputMaybe<TodoUsersHasTodoFieldInput>;
};

export type TodoDeleteInput = {
  journeysHasTask?: InputMaybe<Array<TodoJourneysHasTaskDeleteFieldInput>>;
  usersHasTodo?: InputMaybe<Array<TodoUsersHasTodoDeleteFieldInput>>;
};

export type TodoDisconnectInput = {
  journeysHasTask?: InputMaybe<Array<TodoJourneysHasTaskDisconnectFieldInput>>;
  usersHasTodo?: InputMaybe<Array<TodoUsersHasTodoDisconnectFieldInput>>;
};

export type TodoEdge = {
  __typename?: "TodoEdge";
  cursor: Scalars["String"]["output"];
  node: Todo;
};

export type TodoJourneyJourneysHasTaskAggregationSelection = {
  __typename?: "TodoJourneyJourneysHasTaskAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<TodoJourneyJourneysHasTaskEdgeAggregateSelection>;
  node?: Maybe<TodoJourneyJourneysHasTaskNodeAggregateSelection>;
};

export type TodoJourneyJourneysHasTaskEdgeAggregateSelection = {
  __typename?: "TodoJourneyJourneysHasTaskEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
};

export type TodoJourneyJourneysHasTaskNodeAggregateSelection = {
  __typename?: "TodoJourneyJourneysHasTaskNodeAggregateSelection";
  _empty: StringAggregateSelection;
  completedTasks: IntAggregateSelection;
  description: StringAggregateSelection;
  dueDate: StringAggregateSelection;
  id: IdAggregateSelection;
  name: StringAggregateSelection;
  totalTasks: IntAggregateSelection;
  url: StringAggregateSelection;
};

export type TodoJourneysHasTaskAggregateInput = {
  AND?: InputMaybe<Array<TodoJourneysHasTaskAggregateInput>>;
  NOT?: InputMaybe<TodoJourneysHasTaskAggregateInput>;
  OR?: InputMaybe<Array<TodoJourneysHasTaskAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasTaskPropertiesAggregationWhereInput>;
  node?: InputMaybe<TodoJourneysHasTaskNodeAggregationWhereInput>;
};

export type TodoJourneysHasTaskConnectFieldInput = {
  connect?: InputMaybe<Array<JourneyConnectInput>>;
  edge?: InputMaybe<HasTaskPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<JourneyConnectWhere>;
};

export type TodoJourneysHasTaskConnection = {
  __typename?: "TodoJourneysHasTaskConnection";
  edges: Array<TodoJourneysHasTaskRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TodoJourneysHasTaskConnectionSort = {
  edge?: InputMaybe<HasTaskPropertiesSort>;
  node?: InputMaybe<JourneySort>;
};

export type TodoJourneysHasTaskConnectionWhere = {
  AND?: InputMaybe<Array<TodoJourneysHasTaskConnectionWhere>>;
  NOT?: InputMaybe<TodoJourneysHasTaskConnectionWhere>;
  OR?: InputMaybe<Array<TodoJourneysHasTaskConnectionWhere>>;
  edge?: InputMaybe<HasTaskPropertiesWhere>;
  node?: InputMaybe<JourneyWhere>;
};

export type TodoJourneysHasTaskCreateFieldInput = {
  edge?: InputMaybe<HasTaskPropertiesCreateInput>;
  node: JourneyCreateInput;
};

export type TodoJourneysHasTaskDeleteFieldInput = {
  delete?: InputMaybe<JourneyDeleteInput>;
  where?: InputMaybe<TodoJourneysHasTaskConnectionWhere>;
};

export type TodoJourneysHasTaskDisconnectFieldInput = {
  disconnect?: InputMaybe<JourneyDisconnectInput>;
  where?: InputMaybe<TodoJourneysHasTaskConnectionWhere>;
};

export type TodoJourneysHasTaskFieldInput = {
  connect?: InputMaybe<Array<TodoJourneysHasTaskConnectFieldInput>>;
  create?: InputMaybe<Array<TodoJourneysHasTaskCreateFieldInput>>;
};

export type TodoJourneysHasTaskNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TodoJourneysHasTaskNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TodoJourneysHasTaskNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TodoJourneysHasTaskNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  dueDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  dueDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  dueDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  dueDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  dueDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TodoJourneysHasTaskRelationship = {
  __typename?: "TodoJourneysHasTaskRelationship";
  cursor: Scalars["String"]["output"];
  node: Journey;
  properties: HasTaskProperties;
};

export type TodoJourneysHasTaskUpdateConnectionInput = {
  edge?: InputMaybe<HasTaskPropertiesUpdateInput>;
  node?: InputMaybe<JourneyUpdateInput>;
};

export type TodoJourneysHasTaskUpdateFieldInput = {
  connect?: InputMaybe<Array<TodoJourneysHasTaskConnectFieldInput>>;
  create?: InputMaybe<Array<TodoJourneysHasTaskCreateFieldInput>>;
  delete?: InputMaybe<Array<TodoJourneysHasTaskDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TodoJourneysHasTaskDisconnectFieldInput>>;
  update?: InputMaybe<TodoJourneysHasTaskUpdateConnectionInput>;
  where?: InputMaybe<TodoJourneysHasTaskConnectionWhere>;
};

export type TodoOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more TodoSort objects to sort Todos by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TodoSort>>;
};

export type TodoRelationInput = {
  journeysHasTask?: InputMaybe<Array<TodoJourneysHasTaskCreateFieldInput>>;
  usersHasTodo?: InputMaybe<Array<TodoUsersHasTodoCreateFieldInput>>;
};

/** Fields to sort Todos by. The order in which sorts are applied is not guaranteed when specifying many fields in one TodoSort object. */
export type TodoSort = {
  completed?: InputMaybe<SortDirection>;
  completed_date?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  description?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  isPartOfJourney?: InputMaybe<SortDirection>;
  isRecurring?: InputMaybe<SortDirection>;
  journeyId?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  pointsReceived?: InputMaybe<SortDirection>;
  priority?: InputMaybe<SortDirection>;
  recurringDaily?: InputMaybe<SortDirection>;
  recurringDayOfMonth?: InputMaybe<SortDirection>;
  recurringDayOfWeek?: InputMaybe<SortDirection>;
  recurringFrequency?: InputMaybe<SortDirection>;
  recurringTime?: InputMaybe<SortDirection>;
  timeOfDay?: InputMaybe<SortDirection>;
  timezone?: InputMaybe<SortDirection>;
};

export type TodoUpdateInput = {
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  completed_date?: InputMaybe<Scalars["Date"]["input"]>;
  date?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  isPartOfJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyId?: InputMaybe<Scalars["ID"]["input"]>;
  journeysHasTask?: InputMaybe<Array<TodoJourneysHasTaskUpdateFieldInput>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  pointsReceived?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  recurringDaily?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringDayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek?: InputMaybe<Scalars["String"]["input"]>;
  recurringFrequency?: InputMaybe<Scalars["String"]["input"]>;
  recurringTime?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  usersHasTodo?: InputMaybe<Array<TodoUsersHasTodoUpdateFieldInput>>;
};

export type TodoUserUsersHasTodoAggregationSelection = {
  __typename?: "TodoUserUsersHasTodoAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<TodoUserUsersHasTodoEdgeAggregateSelection>;
  node?: Maybe<TodoUserUsersHasTodoNodeAggregateSelection>;
};

export type TodoUserUsersHasTodoEdgeAggregateSelection = {
  __typename?: "TodoUserUsersHasTodoEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  quality: BigIntAggregateSelection;
  strength: BigIntAggregateSelection;
};

export type TodoUserUsersHasTodoNodeAggregateSelection = {
  __typename?: "TodoUserUsersHasTodoNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type TodoUsersHasTodoAggregateInput = {
  AND?: InputMaybe<Array<TodoUsersHasTodoAggregateInput>>;
  NOT?: InputMaybe<TodoUsersHasTodoAggregateInput>;
  OR?: InputMaybe<Array<TodoUsersHasTodoAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasTodoPropertiesAggregationWhereInput>;
  node?: InputMaybe<TodoUsersHasTodoNodeAggregationWhereInput>;
};

export type TodoUsersHasTodoConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge?: InputMaybe<HasTodoPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type TodoUsersHasTodoConnection = {
  __typename?: "TodoUsersHasTodoConnection";
  edges: Array<TodoUsersHasTodoRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TodoUsersHasTodoConnectionSort = {
  edge?: InputMaybe<HasTodoPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type TodoUsersHasTodoConnectionWhere = {
  AND?: InputMaybe<Array<TodoUsersHasTodoConnectionWhere>>;
  NOT?: InputMaybe<TodoUsersHasTodoConnectionWhere>;
  OR?: InputMaybe<Array<TodoUsersHasTodoConnectionWhere>>;
  edge?: InputMaybe<HasTodoPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type TodoUsersHasTodoCreateFieldInput = {
  edge?: InputMaybe<HasTodoPropertiesCreateInput>;
  node: UserCreateInput;
};

export type TodoUsersHasTodoDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<TodoUsersHasTodoConnectionWhere>;
};

export type TodoUsersHasTodoDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<TodoUsersHasTodoConnectionWhere>;
};

export type TodoUsersHasTodoFieldInput = {
  connect?: InputMaybe<Array<TodoUsersHasTodoConnectFieldInput>>;
  create?: InputMaybe<Array<TodoUsersHasTodoCreateFieldInput>>;
};

export type TodoUsersHasTodoNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<TodoUsersHasTodoNodeAggregationWhereInput>>;
  NOT?: InputMaybe<TodoUsersHasTodoNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<TodoUsersHasTodoNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type TodoUsersHasTodoRelationship = {
  __typename?: "TodoUsersHasTodoRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasTodoProperties;
};

export type TodoUsersHasTodoUpdateConnectionInput = {
  edge?: InputMaybe<HasTodoPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type TodoUsersHasTodoUpdateFieldInput = {
  connect?: InputMaybe<Array<TodoUsersHasTodoConnectFieldInput>>;
  create?: InputMaybe<Array<TodoUsersHasTodoCreateFieldInput>>;
  delete?: InputMaybe<Array<TodoUsersHasTodoDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<TodoUsersHasTodoDisconnectFieldInput>>;
  update?: InputMaybe<TodoUsersHasTodoUpdateConnectionInput>;
  where?: InputMaybe<TodoUsersHasTodoConnectionWhere>;
};

export type TodoWhere = {
  AND?: InputMaybe<Array<TodoWhere>>;
  NOT?: InputMaybe<TodoWhere>;
  OR?: InputMaybe<Array<TodoWhere>>;
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  completed_date?: InputMaybe<Scalars["Date"]["input"]>;
  completed_date_GT?: InputMaybe<Scalars["Date"]["input"]>;
  completed_date_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  completed_date_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  completed_date_LT?: InputMaybe<Scalars["Date"]["input"]>;
  completed_date_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  date?: InputMaybe<Scalars["Date"]["input"]>;
  date_GT?: InputMaybe<Scalars["Date"]["input"]>;
  date_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  date_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  date_LT?: InputMaybe<Scalars["Date"]["input"]>;
  date_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  isPartOfJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyId?: InputMaybe<Scalars["ID"]["input"]>;
  journeyId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  journeyId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  journeyId_IN?: InputMaybe<Array<InputMaybe<Scalars["ID"]["input"]>>>;
  journeyId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  journeysHasTaskAggregate?: InputMaybe<TodoJourneysHasTaskAggregateInput>;
  /** Return Todos where all of the related TodoJourneysHasTaskConnections match this filter */
  journeysHasTaskConnection_ALL?: InputMaybe<TodoJourneysHasTaskConnectionWhere>;
  /** Return Todos where none of the related TodoJourneysHasTaskConnections match this filter */
  journeysHasTaskConnection_NONE?: InputMaybe<TodoJourneysHasTaskConnectionWhere>;
  /** Return Todos where one of the related TodoJourneysHasTaskConnections match this filter */
  journeysHasTaskConnection_SINGLE?: InputMaybe<TodoJourneysHasTaskConnectionWhere>;
  /** Return Todos where some of the related TodoJourneysHasTaskConnections match this filter */
  journeysHasTaskConnection_SOME?: InputMaybe<TodoJourneysHasTaskConnectionWhere>;
  /** Return Todos where all of the related Journeys match this filter */
  journeysHasTask_ALL?: InputMaybe<JourneyWhere>;
  /** Return Todos where none of the related Journeys match this filter */
  journeysHasTask_NONE?: InputMaybe<JourneyWhere>;
  /** Return Todos where one of the related Journeys match this filter */
  journeysHasTask_SINGLE?: InputMaybe<JourneyWhere>;
  /** Return Todos where some of the related Journeys match this filter */
  journeysHasTask_SOME?: InputMaybe<JourneyWhere>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  pointsReceived?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_GT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  pointsReceived_LT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  priority_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  priority_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  priority_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  priority_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  recurringDaily?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringDayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  recurringDayOfMonth_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek?: InputMaybe<Scalars["String"]["input"]>;
  recurringDayOfWeek_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  recurringDayOfWeek_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  recurringDayOfWeek_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  recurringDayOfWeek_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  recurringFrequency?: InputMaybe<Scalars["String"]["input"]>;
  recurringFrequency_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  recurringFrequency_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  recurringFrequency_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  recurringFrequency_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  recurringTime?: InputMaybe<Scalars["String"]["input"]>;
  recurringTime_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  recurringTime_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  recurringTime_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  recurringTime_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  timeOfDay_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  timezone_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  timezone_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  timezone_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  timezone_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasTodoAggregate?: InputMaybe<TodoUsersHasTodoAggregateInput>;
  /** Return Todos where all of the related TodoUsersHasTodoConnections match this filter */
  usersHasTodoConnection_ALL?: InputMaybe<TodoUsersHasTodoConnectionWhere>;
  /** Return Todos where none of the related TodoUsersHasTodoConnections match this filter */
  usersHasTodoConnection_NONE?: InputMaybe<TodoUsersHasTodoConnectionWhere>;
  /** Return Todos where one of the related TodoUsersHasTodoConnections match this filter */
  usersHasTodoConnection_SINGLE?: InputMaybe<TodoUsersHasTodoConnectionWhere>;
  /** Return Todos where some of the related TodoUsersHasTodoConnections match this filter */
  usersHasTodoConnection_SOME?: InputMaybe<TodoUsersHasTodoConnectionWhere>;
  /** Return Todos where all of the related Users match this filter */
  usersHasTodo_ALL?: InputMaybe<UserWhere>;
  /** Return Todos where none of the related Users match this filter */
  usersHasTodo_NONE?: InputMaybe<UserWhere>;
  /** Return Todos where one of the related Users match this filter */
  usersHasTodo_SINGLE?: InputMaybe<UserWhere>;
  /** Return Todos where some of the related Users match this filter */
  usersHasTodo_SOME?: InputMaybe<UserWhere>;
};

export type TodosConnection = {
  __typename?: "TodosConnection";
  edges: Array<TodoEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type ToolAssignment = {
  __typename?: "ToolAssignment";
  assignment_name: Scalars["String"]["output"];
  html: Scalars["String"]["output"];
};

export type ToolAssignmentAggregateSelection = {
  __typename?: "ToolAssignmentAggregateSelection";
  assignment_name: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  html: StringAggregateSelection;
};

export type ToolAssignmentCreateInput = {
  assignment_name: Scalars["String"]["input"];
  html: Scalars["String"]["input"];
};

export type ToolAssignmentEdge = {
  __typename?: "ToolAssignmentEdge";
  cursor: Scalars["String"]["output"];
  node: ToolAssignment;
};

export type ToolAssignmentOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more ToolAssignmentSort objects to sort ToolAssignments by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<ToolAssignmentSort>>;
};

/** Fields to sort ToolAssignments by. The order in which sorts are applied is not guaranteed when specifying many fields in one ToolAssignmentSort object. */
export type ToolAssignmentSort = {
  assignment_name?: InputMaybe<SortDirection>;
  html?: InputMaybe<SortDirection>;
};

export type ToolAssignmentUpdateInput = {
  assignment_name?: InputMaybe<Scalars["String"]["input"]>;
  html?: InputMaybe<Scalars["String"]["input"]>;
};

export type ToolAssignmentWhere = {
  AND?: InputMaybe<Array<ToolAssignmentWhere>>;
  NOT?: InputMaybe<ToolAssignmentWhere>;
  OR?: InputMaybe<Array<ToolAssignmentWhere>>;
  assignment_name?: InputMaybe<Scalars["String"]["input"]>;
  assignment_name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  assignment_name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  assignment_name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  assignment_name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  html?: InputMaybe<Scalars["String"]["input"]>;
  html_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  html_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  html_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  html_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type ToolAssignmentsConnection = {
  __typename?: "ToolAssignmentsConnection";
  edges: Array<ToolAssignmentEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Topic = {
  __typename?: "Topic";
  description?: Maybe<Scalars["String"]["output"]>;
  isRelevant?: Maybe<Scalars["Boolean"]["output"]>;
  mastery?: Maybe<Scalars["Float"]["output"]>;
  name: Scalars["String"]["output"];
};

export type TopicAggregateSelection = {
  __typename?: "TopicAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  mastery: FloatAggregateSelection;
  name: StringAggregateSelection;
};

export type TopicCreateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  name: Scalars["String"]["input"];
};

export type TopicEdge = {
  __typename?: "TopicEdge";
  cursor: Scalars["String"]["output"];
  node: Topic;
};

export type TopicOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more TopicSort objects to sort Topics by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TopicSort>>;
};

/** Fields to sort Topics by. The order in which sorts are applied is not guaranteed when specifying many fields in one TopicSort object. */
export type TopicSort = {
  description?: InputMaybe<SortDirection>;
  isRelevant?: InputMaybe<SortDirection>;
  mastery?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
};

export type TopicUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
};

export type TopicWhere = {
  AND?: InputMaybe<Array<TopicWhere>>;
  NOT?: InputMaybe<TopicWhere>;
  OR?: InputMaybe<Array<TopicWhere>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  isRelevant?: InputMaybe<Scalars["Boolean"]["input"]>;
  mastery?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  mastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  mastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type TopicsConnection = {
  __typename?: "TopicsConnection";
  edges: Array<TopicEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TrackOverview = {
  __typename?: "TrackOverview";
  description?: Maybe<Scalars["String"]["output"]>;
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  name?: Maybe<Scalars["String"]["output"]>;
  objectives?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  otherRequirements?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  otherRequirementsTitle?: Maybe<Scalars["String"]["output"]>;
  prerequisites?: Maybe<Array<Maybe<Prerequisite>>>;
  skillRequirements?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
};

export type TrackOverviewAggregateSelection = {
  __typename?: "TrackOverviewAggregateSelection";
  count: Scalars["Int"]["output"];
  description: StringAggregateSelection;
  name: StringAggregateSelection;
  otherRequirementsTitle: StringAggregateSelection;
};

export type TrackOverviewCreateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  otherRequirements?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  otherRequirementsTitle?: InputMaybe<Scalars["String"]["input"]>;
  skillRequirements?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type TrackOverviewEdge = {
  __typename?: "TrackOverviewEdge";
  cursor: Scalars["String"]["output"];
  node: TrackOverview;
};

export type TrackOverviewOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more TrackOverviewSort objects to sort TrackOverviews by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TrackOverviewSort>>;
};

/** Fields to sort TrackOverviews by. The order in which sorts are applied is not guaranteed when specifying many fields in one TrackOverviewSort object. */
export type TrackOverviewSort = {
  description?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  otherRequirementsTitle?: InputMaybe<SortDirection>;
};

export type TrackOverviewUpdateInput = {
  description?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  objectives_POP?: InputMaybe<Scalars["Int"]["input"]>;
  objectives_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  otherRequirements?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  otherRequirementsTitle?: InputMaybe<Scalars["String"]["input"]>;
  otherRequirements_POP?: InputMaybe<Scalars["Int"]["input"]>;
  otherRequirements_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  skillRequirements?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  skillRequirements_POP?: InputMaybe<Scalars["Int"]["input"]>;
  skillRequirements_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
};

export type TrackOverviewWhere = {
  AND?: InputMaybe<Array<TrackOverviewWhere>>;
  NOT?: InputMaybe<TrackOverviewWhere>;
  OR?: InputMaybe<Array<TrackOverviewWhere>>;
  description?: InputMaybe<Scalars["String"]["input"]>;
  description_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  description_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  description_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  description_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  objectives?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  objectives_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  otherRequirements?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  otherRequirementsTitle?: InputMaybe<Scalars["String"]["input"]>;
  otherRequirementsTitle_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  otherRequirementsTitle_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  otherRequirementsTitle_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  otherRequirementsTitle_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  otherRequirements_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  skillRequirements?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  skillRequirements_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
};

export type TrackOverviewsConnection = {
  __typename?: "TrackOverviewsConnection";
  edges: Array<TrackOverviewEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Transaction = {
  __typename?: "Transaction";
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  modified: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  transactionType: TransactionType;
};

export type TransactionAggregateSelection = {
  __typename?: "TransactionAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  modified: StringAggregateSelection;
  name: StringAggregateSelection;
};

export type TransactionByDate = {
  __typename?: "TransactionByDate";
  id: Scalars["ID"]["output"];
  label: Scalars["String"]["output"];
  modified: Scalars["String"]["output"];
  name: Scalars["String"]["output"];
  transactionType: TransactionType;
};

export type TransactionByDateAggregateSelection = {
  __typename?: "TransactionByDateAggregateSelection";
  count: Scalars["Int"]["output"];
  id: IdAggregateSelection;
  label: StringAggregateSelection;
  modified: StringAggregateSelection;
  name: StringAggregateSelection;
};

export type TransactionByDateCreateInput = {
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  modified: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  transactionType: TransactionType;
};

export type TransactionByDateEdge = {
  __typename?: "TransactionByDateEdge";
  cursor: Scalars["String"]["output"];
  node: TransactionByDate;
};

export type TransactionByDateOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more TransactionByDateSort objects to sort TransactionByDates by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TransactionByDateSort>>;
};

/** Fields to sort TransactionByDates by. The order in which sorts are applied is not guaranteed when specifying many fields in one TransactionByDateSort object. */
export type TransactionByDateSort = {
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  transactionType?: InputMaybe<SortDirection>;
};

export type TransactionByDateUpdateInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  transactionType?: InputMaybe<TransactionType>;
};

export type TransactionByDateWhere = {
  AND?: InputMaybe<Array<TransactionByDateWhere>>;
  NOT?: InputMaybe<TransactionByDateWhere>;
  OR?: InputMaybe<Array<TransactionByDateWhere>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["String"]["input"]>;
  modified_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  modified_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  modified_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  modified_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  transactionType?: InputMaybe<TransactionType>;
  transactionType_IN?: InputMaybe<Array<TransactionType>>;
};

export type TransactionByDatesConnection = {
  __typename?: "TransactionByDatesConnection";
  edges: Array<TransactionByDateEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type TransactionCreateInput = {
  id: Scalars["ID"]["input"];
  label: Scalars["String"]["input"];
  modified: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  transactionType: TransactionType;
};

export type TransactionEdge = {
  __typename?: "TransactionEdge";
  cursor: Scalars["String"]["output"];
  node: Transaction;
};

export type TransactionOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more TransactionSort objects to sort Transactions by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<TransactionSort>>;
};

/** Fields to sort Transactions by. The order in which sorts are applied is not guaranteed when specifying many fields in one TransactionSort object. */
export type TransactionSort = {
  id?: InputMaybe<SortDirection>;
  label?: InputMaybe<SortDirection>;
  modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  transactionType?: InputMaybe<SortDirection>;
};

export enum TransactionType {
  Created = "Created",
  Deleted = "Deleted",
  Updated = "Updated",
}

export type TransactionUpdateInput = {
  id?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  transactionType?: InputMaybe<TransactionType>;
};

export type TransactionWhere = {
  AND?: InputMaybe<Array<TransactionWhere>>;
  NOT?: InputMaybe<TransactionWhere>;
  OR?: InputMaybe<Array<TransactionWhere>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  label?: InputMaybe<Scalars["String"]["input"]>;
  label_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  label_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  label_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  label_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  modified?: InputMaybe<Scalars["String"]["input"]>;
  modified_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  modified_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  modified_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  modified_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  transactionType?: InputMaybe<TransactionType>;
  transactionType_IN?: InputMaybe<Array<TransactionType>>;
};

export type TransactionsConnection = {
  __typename?: "TransactionsConnection";
  edges: Array<TransactionEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

/**
 * The edge properties for the following fields:
 * * User.undefinedFoodItems
 * * User.undefinedWaterIntakes
 * * FoodItem.usersundefined
 * * WaterIntake.usersundefined
 */
export type UndefinedProperties = {
  __typename?: "UndefinedProperties";
  created: Scalars["DateTime"]["output"];
  isArchived: Scalars["Boolean"]["output"];
  isPositive?: Maybe<Scalars["Boolean"]["output"]>;
  last_modified: Scalars["DateTime"]["output"];
  quality: Scalars["BigInt"]["output"];
  strength: Scalars["BigInt"]["output"];
};

export type UndefinedPropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<UndefinedPropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<UndefinedPropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<UndefinedPropertiesAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality_AVERAGE_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_AVERAGE_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MAX_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_MIN_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_SUM_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_AVERAGE_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MAX_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_MIN_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_EQUAL?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_SUM_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type UndefinedPropertiesCreateInput = {
  created: Scalars["DateTime"]["input"];
  isArchived: Scalars["Boolean"]["input"];
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified: Scalars["DateTime"]["input"];
  quality: Scalars["BigInt"]["input"];
  strength: Scalars["BigInt"]["input"];
};

export type UndefinedPropertiesSort = {
  created?: InputMaybe<SortDirection>;
  isArchived?: InputMaybe<SortDirection>;
  isPositive?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  quality?: InputMaybe<SortDirection>;
  strength?: InputMaybe<SortDirection>;
};

export type UndefinedPropertiesUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_DECREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_INCREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_DECREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_INCREMENT?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type UndefinedPropertiesWhere = {
  AND?: InputMaybe<Array<UndefinedPropertiesWhere>>;
  NOT?: InputMaybe<UndefinedPropertiesWhere>;
  OR?: InputMaybe<Array<UndefinedPropertiesWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPositive?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  quality?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_IN?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  quality_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  quality_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_GT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_GTE?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_IN?: InputMaybe<Array<Scalars["BigInt"]["input"]>>;
  strength_LT?: InputMaybe<Scalars["BigInt"]["input"]>;
  strength_LTE?: InputMaybe<Scalars["BigInt"]["input"]>;
};

export type UpdateAchievementsMutationResponse = {
  __typename?: "UpdateAchievementsMutationResponse";
  achievements: Array<Achievements>;
  info: UpdateInfo;
};

export type UpdateActiveTracksMutationResponse = {
  __typename?: "UpdateActiveTracksMutationResponse";
  activeTracks: Array<ActiveTrack>;
  info: UpdateInfo;
};

export type UpdateAddAssignmentResponsesMutationResponse = {
  __typename?: "UpdateAddAssignmentResponsesMutationResponse";
  addAssignmentResponses: Array<AddAssignmentResponse>;
  info: UpdateInfo;
};

export type UpdateAddContentModuleResponsesMutationResponse = {
  __typename?: "UpdateAddContentModuleResponsesMutationResponse";
  addContentModuleResponses: Array<AddContentModuleResponse>;
  info: UpdateInfo;
};

export type UpdateAspectDetailsTypesMutationResponse = {
  __typename?: "UpdateAspectDetailsTypesMutationResponse";
  aspectDetailsTypes: Array<AspectDetailsType>;
  info: UpdateInfo;
};

export type UpdateAssignedQuestsMutationResponse = {
  __typename?: "UpdateAssignedQuestsMutationResponse";
  assignedQuests: Array<AssignedQuest>;
  info: UpdateInfo;
};

export type UpdateAssignmentInput = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateAssignmentResponse = {
  __typename?: "UpdateAssignmentResponse";
  data?: Maybe<ToolAssignment>;
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type UpdateAssignmentResponseAggregateSelection = {
  __typename?: "UpdateAssignmentResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type UpdateAssignmentResponseCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type UpdateAssignmentResponseEdge = {
  __typename?: "UpdateAssignmentResponseEdge";
  cursor: Scalars["String"]["output"];
  node: UpdateAssignmentResponse;
};

export type UpdateAssignmentResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UpdateAssignmentResponseSort objects to sort UpdateAssignmentResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UpdateAssignmentResponseSort>>;
};

/** Fields to sort UpdateAssignmentResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one UpdateAssignmentResponseSort object. */
export type UpdateAssignmentResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type UpdateAssignmentResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateAssignmentResponseWhere = {
  AND?: InputMaybe<Array<UpdateAssignmentResponseWhere>>;
  NOT?: InputMaybe<UpdateAssignmentResponseWhere>;
  OR?: InputMaybe<Array<UpdateAssignmentResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateAssignmentResponsesConnection = {
  __typename?: "UpdateAssignmentResponsesConnection";
  edges: Array<UpdateAssignmentResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UpdateAssignmentResponsesMutationResponse = {
  __typename?: "UpdateAssignmentResponsesMutationResponse";
  assignmentResponses: Array<AssignmentResponse>;
  info: UpdateInfo;
};

export type UpdateAssignmentsMutationResponse = {
  __typename?: "UpdateAssignmentsMutationResponse";
  assignments: Array<Assignment>;
  info: UpdateInfo;
};

export type UpdateAttributesMutationResponse = {
  __typename?: "UpdateAttributesMutationResponse";
  attributes: Array<Attribute>;
  info: UpdateInfo;
};

export type UpdateAudioToggleUserResponsesMutationResponse = {
  __typename?: "UpdateAudioToggleUserResponsesMutationResponse";
  audioToggleUserResponses: Array<AudioToggleUserResponse>;
  info: UpdateInfo;
};

export type UpdateAuthPayloadResponsesMutationResponse = {
  __typename?: "UpdateAuthPayloadResponsesMutationResponse";
  authPayloadResponses: Array<AuthPayloadResponse>;
  info: UpdateInfo;
};

export type UpdateAuthPayloadUserPreferencesMutationResponse = {
  __typename?: "UpdateAuthPayloadUserPreferencesMutationResponse";
  authPayloadUserPreferences: Array<AuthPayloadUserPreferences>;
  info: UpdateInfo;
};

export type UpdateAuthPayloadUsersMutationResponse = {
  __typename?: "UpdateAuthPayloadUsersMutationResponse";
  authPayloadUsers: Array<AuthPayloadUser>;
  info: UpdateInfo;
};

export type UpdateCareerHeadersMutationResponse = {
  __typename?: "UpdateCareerHeadersMutationResponse";
  careerHeaders: Array<CareerHeader>;
  info: UpdateInfo;
};

export type UpdateCareerTopicResponsesMutationResponse = {
  __typename?: "UpdateCareerTopicResponsesMutationResponse";
  careerTopicResponses: Array<CareerTopicResponse>;
  info: UpdateInfo;
};

export type UpdateCareersMutationResponse = {
  __typename?: "UpdateCareersMutationResponse";
  careers: Array<Career>;
  info: UpdateInfo;
};

export type UpdateChallengesMutationResponse = {
  __typename?: "UpdateChallengesMutationResponse";
  challenges: Array<Challenge>;
  info: UpdateInfo;
};

export type UpdateChaptersMutationResponse = {
  __typename?: "UpdateChaptersMutationResponse";
  chapters: Array<Chapter>;
  info: UpdateInfo;
};

export type UpdateChatsMutationResponse = {
  __typename?: "UpdateChatsMutationResponse";
  chats: Array<Chat>;
  info: UpdateInfo;
};

export type UpdateChildInfosMutationResponse = {
  __typename?: "UpdateChildInfosMutationResponse";
  childInfos: Array<ChildInfo>;
  info: UpdateInfo;
};

export type UpdateContentModuleNameResponse = {
  __typename?: "UpdateContentModuleNameResponse";
  message?: Maybe<Scalars["String"]["output"]>;
  success: Scalars["Boolean"]["output"];
};

export type UpdateContentModuleNameResponseAggregateSelection = {
  __typename?: "UpdateContentModuleNameResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type UpdateContentModuleNameResponseCreateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success: Scalars["Boolean"]["input"];
};

export type UpdateContentModuleNameResponseEdge = {
  __typename?: "UpdateContentModuleNameResponseEdge";
  cursor: Scalars["String"]["output"];
  node: UpdateContentModuleNameResponse;
};

export type UpdateContentModuleNameResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UpdateContentModuleNameResponseSort objects to sort UpdateContentModuleNameResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UpdateContentModuleNameResponseSort>>;
};

/** Fields to sort UpdateContentModuleNameResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one UpdateContentModuleNameResponseSort object. */
export type UpdateContentModuleNameResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type UpdateContentModuleNameResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateContentModuleNameResponseWhere = {
  AND?: InputMaybe<Array<UpdateContentModuleNameResponseWhere>>;
  NOT?: InputMaybe<UpdateContentModuleNameResponseWhere>;
  OR?: InputMaybe<Array<UpdateContentModuleNameResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UpdateContentModuleNameResponsesConnection = {
  __typename?: "UpdateContentModuleNameResponsesConnection";
  edges: Array<UpdateContentModuleNameResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UpdateCoreValuesMutationResponse = {
  __typename?: "UpdateCoreValuesMutationResponse";
  coreValues: Array<CoreValue>;
  info: UpdateInfo;
};

export type UpdateCourseCategoriesMutationResponse = {
  __typename?: "UpdateCourseCategoriesMutationResponse";
  courseCategories: Array<CourseCategory>;
  info: UpdateInfo;
};

export type UpdateCourseCreationResponsesMutationResponse = {
  __typename?: "UpdateCourseCreationResponsesMutationResponse";
  courseCreationResponses: Array<CourseCreationResponse>;
  info: UpdateInfo;
};

export type UpdateCourseInput = {
  learningStandards?: InputMaybe<Scalars["String"]["input"]>;
  lengthInWeeks?: InputMaybe<Scalars["Float"]["input"]>;
  objectives?: InputMaybe<Array<Scalars["String"]["input"]>>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateCourseResponsesMutationResponse = {
  __typename?: "UpdateCourseResponsesMutationResponse";
  courseResponses: Array<CourseResponse>;
  info: UpdateInfo;
};

export type UpdateCoursesMutationResponse = {
  __typename?: "UpdateCoursesMutationResponse";
  courses: Array<Course>;
  info: UpdateInfo;
};

export type UpdateCurriculaMutationResponse = {
  __typename?: "UpdateCurriculaMutationResponse";
  curricula: Array<Curriculum>;
  info: UpdateInfo;
};

export type UpdateDailyCompletedTodosCountsMutationResponse = {
  __typename?: "UpdateDailyCompletedTodosCountsMutationResponse";
  dailyCompletedTodosCounts: Array<DailyCompletedTodosCount>;
  info: UpdateInfo;
};

export type UpdateDailyExerciseTotalsMutationResponse = {
  __typename?: "UpdateDailyExerciseTotalsMutationResponse";
  dailyExerciseTotals: Array<DailyExerciseTotals>;
  info: UpdateInfo;
};

export type UpdateDailyNutritionTotalsMutationResponse = {
  __typename?: "UpdateDailyNutritionTotalsMutationResponse";
  dailyNutritionTotals: Array<DailyNutritionTotals>;
  info: UpdateInfo;
};

export type UpdateDailySummariesMutationResponse = {
  __typename?: "UpdateDailySummariesMutationResponse";
  dailySummaries: Array<DailySummary>;
  info: UpdateInfo;
};

export type UpdateDeleteContentAssignmentResponsesMutationResponse = {
  __typename?: "UpdateDeleteContentAssignmentResponsesMutationResponse";
  deleteContentAssignmentResponses: Array<DeleteContentAssignmentResponse>;
  info: UpdateInfo;
};

export type UpdateDeleteContentAssignmentVariablesMutationResponse = {
  __typename?: "UpdateDeleteContentAssignmentVariablesMutationResponse";
  deleteContentAssignmentVariables: Array<DeleteContentAssignmentVariables>;
  info: UpdateInfo;
};

export type UpdateDeleteContentModuleResponsesMutationResponse = {
  __typename?: "UpdateDeleteContentModuleResponsesMutationResponse";
  deleteContentModuleResponses: Array<DeleteContentModuleResponse>;
  info: UpdateInfo;
};

export type UpdateDeleteContentModuleVariablesMutationResponse = {
  __typename?: "UpdateDeleteContentModuleVariablesMutationResponse";
  deleteContentModuleVariables: Array<DeleteContentModuleVariables>;
  info: UpdateInfo;
};

export type UpdateDeleteCourseResponsesMutationResponse = {
  __typename?: "UpdateDeleteCourseResponsesMutationResponse";
  deleteCourseResponses: Array<DeleteCourseResponse>;
  info: UpdateInfo;
};

export type UpdateDeviceSessionsMutationResponse = {
  __typename?: "UpdateDeviceSessionsMutationResponse";
  deviceSessions: Array<DeviceSession>;
  info: UpdateInfo;
};

export type UpdateEmotionalStatesMutationResponse = {
  __typename?: "UpdateEmotionalStatesMutationResponse";
  emotionalStates: Array<EmotionalState>;
  info: UpdateInfo;
};

export type UpdateEventsMutationResponse = {
  __typename?: "UpdateEventsMutationResponse";
  events: Array<Event>;
  info: UpdateInfo;
};

export type UpdateExportCsvResponsesMutationResponse = {
  __typename?: "UpdateExportCsvResponsesMutationResponse";
  exportCsvResponses: Array<ExportCsvResponse>;
  info: UpdateInfo;
};

export type UpdateFeedbacksMutationResponse = {
  __typename?: "UpdateFeedbacksMutationResponse";
  feedbacks: Array<Feedback>;
  info: UpdateInfo;
};

export type UpdateFieldsMutationResponse = {
  __typename?: "UpdateFieldsMutationResponse";
  fields: Array<Field>;
  info: UpdateInfo;
};

export type UpdateFilesMutationResponse = {
  __typename?: "UpdateFilesMutationResponse";
  files: Array<File>;
  info: UpdateInfo;
};

export type UpdateFoodItemsMutationResponse = {
  __typename?: "UpdateFoodItemsMutationResponse";
  foodItems: Array<FoodItem>;
  info: UpdateInfo;
};

export type UpdateFoodsMutationResponse = {
  __typename?: "UpdateFoodsMutationResponse";
  foods: Array<Food>;
  info: UpdateInfo;
};

export type UpdateGamesMutationResponse = {
  __typename?: "UpdateGamesMutationResponse";
  games: Array<Game>;
  info: UpdateInfo;
};

export type UpdateGeneratedsMutationResponse = {
  __typename?: "UpdateGeneratedsMutationResponse";
  generateds: Array<Generated>;
  info: UpdateInfo;
};

export type UpdateGetAssignmentObjectiveResponsesMutationResponse = {
  __typename?: "UpdateGetAssignmentObjectiveResponsesMutationResponse";
  getAssignmentObjectiveResponses: Array<GetAssignmentObjectiveResponse>;
  info: UpdateInfo;
};

export type UpdateGetAssignmentResponsesMutationResponse = {
  __typename?: "UpdateGetAssignmentResponsesMutationResponse";
  getAssignmentResponses: Array<GetAssignmentResponse>;
  info: UpdateInfo;
};

export type UpdateGetJourneyByIdResponsesMutationResponse = {
  __typename?: "UpdateGetJourneyByIdResponsesMutationResponse";
  getJourneyByIdResponses: Array<GetJourneyByIdResponse>;
  info: UpdateInfo;
};

export type UpdateGetJourneysResponsesMutationResponse = {
  __typename?: "UpdateGetJourneysResponsesMutationResponse";
  getJourneysResponses: Array<GetJourneysResponse>;
  info: UpdateInfo;
};

export type UpdateGoalsMutationResponse = {
  __typename?: "UpdateGoalsMutationResponse";
  goals: Array<Goal>;
  info: UpdateInfo;
};

export type UpdateGoogleAuthTokensMutationResponse = {
  __typename?: "UpdateGoogleAuthTokensMutationResponse";
  googleAuthTokens: Array<GoogleAuthTokens>;
  info: UpdateInfo;
};

export type UpdateHierarchiesMutationResponse = {
  __typename?: "UpdateHierarchiesMutationResponse";
  hierarchies: Array<Hierarchy>;
  info: UpdateInfo;
};

export type UpdateHierarchyNodesMutationResponse = {
  __typename?: "UpdateHierarchyNodesMutationResponse";
  hierarchyNodes: Array<HierarchyNode>;
  info: UpdateInfo;
};

export type UpdateHobbiesMutationResponse = {
  __typename?: "UpdateHobbiesMutationResponse";
  hobbies: Array<Hobby>;
  info: UpdateInfo;
};

/** Information about the number of nodes and relationships created and deleted during an update mutation */
export type UpdateInfo = {
  __typename?: "UpdateInfo";
  /** @deprecated This field has been deprecated because bookmarks are now handled by the driver. */
  bookmark?: Maybe<Scalars["String"]["output"]>;
  nodesCreated: Scalars["Int"]["output"];
  nodesDeleted: Scalars["Int"]["output"];
  relationshipsCreated: Scalars["Int"]["output"];
  relationshipsDeleted: Scalars["Int"]["output"];
};

export type UpdateInterestsMutationResponse = {
  __typename?: "UpdateInterestsMutationResponse";
  info: UpdateInfo;
  interests: Array<Interest>;
};

export type UpdateInviteCountResponsesMutationResponse = {
  __typename?: "UpdateInviteCountResponsesMutationResponse";
  info: UpdateInfo;
  inviteCountResponses: Array<InviteCountResponse>;
};

export type UpdateJourneysMutationResponse = {
  __typename?: "UpdateJourneysMutationResponse";
  info: UpdateInfo;
  journeys: Array<Journey>;
};

export type UpdateLearningNodesMutationResponse = {
  __typename?: "UpdateLearningNodesMutationResponse";
  info: UpdateInfo;
  learningNodes: Array<LearningNode>;
};

export type UpdateLearningObjectiveResponsesMutationResponse = {
  __typename?: "UpdateLearningObjectiveResponsesMutationResponse";
  info: UpdateInfo;
  learningObjectiveResponses: Array<LearningObjectiveResponse>;
};

export type UpdateLearningObjectivesMutationResponse = {
  __typename?: "UpdateLearningObjectivesMutationResponse";
  info: UpdateInfo;
  learningObjectives: Array<LearningObjective>;
};

export type UpdateLearningSummariesMutationResponse = {
  __typename?: "UpdateLearningSummariesMutationResponse";
  info: UpdateInfo;
  learningSummaries: Array<LearningSummary>;
};

export type UpdateLearningTreesMutationResponse = {
  __typename?: "UpdateLearningTreesMutationResponse";
  info: UpdateInfo;
  learningTrees: Array<LearningTree>;
};

export type UpdateLessonsMutationResponse = {
  __typename?: "UpdateLessonsMutationResponse";
  info: UpdateInfo;
  lessons: Array<Lesson>;
};

export type UpdateLevelDetailsMutationResponse = {
  __typename?: "UpdateLevelDetailsMutationResponse";
  info: UpdateInfo;
  levelDetails: Array<LevelDetails>;
};

export type UpdateLifeAspirationsMutationResponse = {
  __typename?: "UpdateLifeAspirationsMutationResponse";
  info: UpdateInfo;
  lifeAspirations: Array<LifeAspiration>;
};

export type UpdateMasteryResultsMutationResponse = {
  __typename?: "UpdateMasteryResultsMutationResponse";
  info: UpdateInfo;
  masteryResults: Array<MasteryResult>;
};

export type UpdateMeResponsesMutationResponse = {
  __typename?: "UpdateMeResponsesMutationResponse";
  info: UpdateInfo;
  meResponses: Array<MeResponse>;
};

export type UpdateMessagesMutationResponse = {
  __typename?: "UpdateMessagesMutationResponse";
  info: UpdateInfo;
  messages: Array<Message>;
};

export type UpdateModuleInput = {
  order?: InputMaybe<Scalars["Float"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateModuleResponsesMutationResponse = {
  __typename?: "UpdateModuleResponsesMutationResponse";
  info: UpdateInfo;
  moduleResponses: Array<ModuleResponse>;
};

export type UpdateModulesMutationResponse = {
  __typename?: "UpdateModulesMutationResponse";
  info: UpdateInfo;
  modules: Array<Module>;
};

export type UpdateMonthlySummariesMutationResponse = {
  __typename?: "UpdateMonthlySummariesMutationResponse";
  info: UpdateInfo;
  monthlySummaries: Array<MonthlySummary>;
};

export type UpdateNodeIdentitiesMutationResponse = {
  __typename?: "UpdateNodeIdentitiesMutationResponse";
  info: UpdateInfo;
  nodeIdentities: Array<NodeIdentity>;
};

export type UpdateNodePropertiesMutationResponse = {
  __typename?: "UpdateNodePropertiesMutationResponse";
  info: UpdateInfo;
  nodeProperties: Array<NodeProperties>;
};

export type UpdateNutritionsMutationResponse = {
  __typename?: "UpdateNutritionsMutationResponse";
  info: UpdateInfo;
  nutritions: Array<Nutrition>;
};

export type UpdateObjectivesMutationResponse = {
  __typename?: "UpdateObjectivesMutationResponse";
  info: UpdateInfo;
  objectives: Array<Objective>;
};

export type UpdatePageInput = {
  body?: InputMaybe<Scalars["String"]["input"]>;
  title?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdatePageResponsesMutationResponse = {
  __typename?: "UpdatePageResponsesMutationResponse";
  info: UpdateInfo;
  pageResponses: Array<PageResponse>;
};

export type UpdatePagesMutationResponse = {
  __typename?: "UpdatePagesMutationResponse";
  info: UpdateInfo;
  pages: Array<Page>;
};

export type UpdateParametersTypesMutationResponse = {
  __typename?: "UpdateParametersTypesMutationResponse";
  info: UpdateInfo;
  parametersTypes: Array<ParametersType>;
};

export type UpdatePaymentsMutationResponse = {
  __typename?: "UpdatePaymentsMutationResponse";
  info: UpdateInfo;
  payments: Array<Payment>;
};

export type UpdatePermissionsMutationResponse = {
  __typename?: "UpdatePermissionsMutationResponse";
  info: UpdateInfo;
  permissions: Array<Permission>;
};

export type UpdatePersonalityTraitsMutationResponse = {
  __typename?: "UpdatePersonalityTraitsMutationResponse";
  info: UpdateInfo;
  personalityTraits: Array<PersonalityTrait>;
};

export type UpdatePreferencesMutationResponse = {
  __typename?: "UpdatePreferencesMutationResponse";
  info: UpdateInfo;
  preferences: Array<Preferences>;
};

export type UpdatePrerequisitesMutationResponse = {
  __typename?: "UpdatePrerequisitesMutationResponse";
  info: UpdateInfo;
  prerequisites: Array<Prerequisite>;
};

export type UpdateProgressesMutationResponse = {
  __typename?: "UpdateProgressesMutationResponse";
  info: UpdateInfo;
  progresses: Array<Progress>;
};

export type UpdateQuarterlySummariesMutationResponse = {
  __typename?: "UpdateQuarterlySummariesMutationResponse";
  info: UpdateInfo;
  quarterlySummaries: Array<QuarterlySummary>;
};

export type UpdateRateLimitResultsMutationResponse = {
  __typename?: "UpdateRateLimitResultsMutationResponse";
  info: UpdateInfo;
  rateLimitResults: Array<RateLimitResult>;
};

export type UpdateRecentCategoriesMutationResponse = {
  __typename?: "UpdateRecentCategoriesMutationResponse";
  info: UpdateInfo;
  recentCategories: Array<RecentCategory>;
};

export type UpdateRecentTracksMutationResponse = {
  __typename?: "UpdateRecentTracksMutationResponse";
  info: UpdateInfo;
  recentTracks: Array<RecentTrack>;
};

export type UpdateRecomendedsMutationResponse = {
  __typename?: "UpdateRecomendedsMutationResponse";
  info: UpdateInfo;
  recomendeds: Array<Recomended>;
};

export type UpdateReflectionsMutationResponse = {
  __typename?: "UpdateReflectionsMutationResponse";
  info: UpdateInfo;
  reflections: Array<Reflection>;
};

export type UpdateRefreshQuestResponsesMutationResponse = {
  __typename?: "UpdateRefreshQuestResponsesMutationResponse";
  info: UpdateInfo;
  refreshQuestResponses: Array<RefreshQuestResponse>;
};

export type UpdateResourcesMutationResponse = {
  __typename?: "UpdateResourcesMutationResponse";
  info: UpdateInfo;
  resources: Array<Resource>;
};

export type UpdateResponseTypesMutationResponse = {
  __typename?: "UpdateResponseTypesMutationResponse";
  info: UpdateInfo;
  responseTypes: Array<ResponseType>;
};

export type UpdateRolesMutationResponse = {
  __typename?: "UpdateRolesMutationResponse";
  info: UpdateInfo;
  roles: Array<Role>;
};

export type UpdateSchoolsMutationResponse = {
  __typename?: "UpdateSchoolsMutationResponse";
  info: UpdateInfo;
  schools: Array<School>;
};

export type UpdateSessionResponsesMutationResponse = {
  __typename?: "UpdateSessionResponsesMutationResponse";
  info: UpdateInfo;
  sessionResponses: Array<SessionResponse>;
};

export type UpdateSubchapterDetailsMutationResponse = {
  __typename?: "UpdateSubchapterDetailsMutationResponse";
  info: UpdateInfo;
  subchapterDetails: Array<SubchapterDetails>;
};

export type UpdateSubchaptersMutationResponse = {
  __typename?: "UpdateSubchaptersMutationResponse";
  info: UpdateInfo;
  subchapters: Array<Subchapter>;
};

export type UpdateSubfieldsMutationResponse = {
  __typename?: "UpdateSubfieldsMutationResponse";
  info: UpdateInfo;
  subfields: Array<Subfield>;
};

export type UpdateSuggestionsMutationResponse = {
  __typename?: "UpdateSuggestionsMutationResponse";
  info: UpdateInfo;
  suggestions: Array<Suggestion>;
};

export type UpdateTenantResponsesMutationResponse = {
  __typename?: "UpdateTenantResponsesMutationResponse";
  info: UpdateInfo;
  tenantResponses: Array<TenantResponse>;
};

export type UpdateTenantsMutationResponse = {
  __typename?: "UpdateTenantsMutationResponse";
  info: UpdateInfo;
  tenants: Array<Tenant>;
};

export type UpdateTodosMutationResponse = {
  __typename?: "UpdateTodosMutationResponse";
  info: UpdateInfo;
  todos: Array<Todo>;
};

export type UpdateToolAssignmentsMutationResponse = {
  __typename?: "UpdateToolAssignmentsMutationResponse";
  info: UpdateInfo;
  toolAssignments: Array<ToolAssignment>;
};

export type UpdateTopicsMutationResponse = {
  __typename?: "UpdateTopicsMutationResponse";
  info: UpdateInfo;
  topics: Array<Topic>;
};

export type UpdateTrackOverviewsMutationResponse = {
  __typename?: "UpdateTrackOverviewsMutationResponse";
  info: UpdateInfo;
  trackOverviews: Array<TrackOverview>;
};

export type UpdateTransactionByDatesMutationResponse = {
  __typename?: "UpdateTransactionByDatesMutationResponse";
  info: UpdateInfo;
  transactionByDates: Array<TransactionByDate>;
};

export type UpdateTransactionsMutationResponse = {
  __typename?: "UpdateTransactionsMutationResponse";
  info: UpdateInfo;
  transactions: Array<Transaction>;
};

export type UpdateUpdateAssignmentResponsesMutationResponse = {
  __typename?: "UpdateUpdateAssignmentResponsesMutationResponse";
  info: UpdateInfo;
  updateAssignmentResponses: Array<UpdateAssignmentResponse>;
};

export type UpdateUpdateContentModuleNameResponsesMutationResponse = {
  __typename?: "UpdateUpdateContentModuleNameResponsesMutationResponse";
  info: UpdateInfo;
  updateContentModuleNameResponses: Array<UpdateContentModuleNameResponse>;
};

export type UpdateUpdateUserModalOnboardingResponsesMutationResponse = {
  __typename?: "UpdateUpdateUserModalOnboardingResponsesMutationResponse";
  info: UpdateInfo;
  updateUserModalOnboardingResponses: Array<UpdateUserModalOnboardingResponse>;
};

export type UpdateUploadDocumentsResponsesMutationResponse = {
  __typename?: "UpdateUploadDocumentsResponsesMutationResponse";
  info: UpdateInfo;
  uploadDocumentsResponses: Array<UploadDocumentsResponse>;
};

export type UpdateUploadDocumentsToBucketResponsesMutationResponse = {
  __typename?: "UpdateUploadDocumentsToBucketResponsesMutationResponse";
  info: UpdateInfo;
  uploadDocumentsToBucketResponses: Array<UploadDocumentsToBucketResponse>;
};

export type UpdateUserCareerLoadingResponsesMutationResponse = {
  __typename?: "UpdateUserCareerLoadingResponsesMutationResponse";
  info: UpdateInfo;
  userCareerLoadingResponses: Array<UserCareerLoadingResponse>;
};

export type UpdateUserCourseLoadingResponsesMutationResponse = {
  __typename?: "UpdateUserCourseLoadingResponsesMutationResponse";
  info: UpdateInfo;
  userCourseLoadingResponses: Array<UserCourseLoadingResponse>;
};

export type UpdateUserMasteriesMutationResponse = {
  __typename?: "UpdateUserMasteriesMutationResponse";
  info: UpdateInfo;
  userMasteries: Array<UserMastery>;
};

export type UpdateUserModalOnboardingResponse = {
  __typename?: "UpdateUserModalOnboardingResponse";
  modalOnboarding?: Maybe<Scalars["String"]["output"]>;
};

export type UpdateUserModalOnboardingResponseAggregateSelection = {
  __typename?: "UpdateUserModalOnboardingResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  modalOnboarding: StringAggregateSelection;
};

export type UpdateUserModalOnboardingResponseCreateInput = {
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateUserModalOnboardingResponseEdge = {
  __typename?: "UpdateUserModalOnboardingResponseEdge";
  cursor: Scalars["String"]["output"];
  node: UpdateUserModalOnboardingResponse;
};

export type UpdateUserModalOnboardingResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UpdateUserModalOnboardingResponseSort objects to sort UpdateUserModalOnboardingResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UpdateUserModalOnboardingResponseSort>>;
};

/** Fields to sort UpdateUserModalOnboardingResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one UpdateUserModalOnboardingResponseSort object. */
export type UpdateUserModalOnboardingResponseSort = {
  modalOnboarding?: InputMaybe<SortDirection>;
};

export type UpdateUserModalOnboardingResponseUpdateInput = {
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateUserModalOnboardingResponseWhere = {
  AND?: InputMaybe<Array<UpdateUserModalOnboardingResponseWhere>>;
  NOT?: InputMaybe<UpdateUserModalOnboardingResponseWhere>;
  OR?: InputMaybe<Array<UpdateUserModalOnboardingResponseWhere>>;
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  modalOnboarding_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type UpdateUserModalOnboardingResponsesConnection = {
  __typename?: "UpdateUserModalOnboardingResponsesConnection";
  edges: Array<UpdateUserModalOnboardingResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UpdateUserProgressesMutationResponse = {
  __typename?: "UpdateUserProgressesMutationResponse";
  info: UpdateInfo;
  userProgresses: Array<UserProgress>;
};

export type UpdateUsersMutationResponse = {
  __typename?: "UpdateUsersMutationResponse";
  info: UpdateInfo;
  users: Array<User>;
};

export type UpdateWaterIntakeSummariesMutationResponse = {
  __typename?: "UpdateWaterIntakeSummariesMutationResponse";
  info: UpdateInfo;
  waterIntakeSummaries: Array<WaterIntakeSummary>;
};

export type UpdateWaterIntakesMutationResponse = {
  __typename?: "UpdateWaterIntakesMutationResponse";
  info: UpdateInfo;
  waterIntakes: Array<WaterIntake>;
};

export type UpdateWeeklySummariesMutationResponse = {
  __typename?: "UpdateWeeklySummariesMutationResponse";
  info: UpdateInfo;
  weeklySummaries: Array<WeeklySummary>;
};

export type UpdateWhitelistedEmailsMutationResponse = {
  __typename?: "UpdateWhitelistedEmailsMutationResponse";
  info: UpdateInfo;
  whitelistedEmails: Array<WhitelistedEmail>;
};

export type UpdateWorkoutsMutationResponse = {
  __typename?: "UpdateWorkoutsMutationResponse";
  info: UpdateInfo;
  workouts: Array<Workout>;
};

export type UpdateYearlySummariesMutationResponse = {
  __typename?: "UpdateYearlySummariesMutationResponse";
  info: UpdateInfo;
  yearlySummaries: Array<YearlySummary>;
};

export type UploadDocumentsResponse = {
  __typename?: "UploadDocumentsResponse";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
};

export type UploadDocumentsResponseAggregateSelection = {
  __typename?: "UploadDocumentsResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type UploadDocumentsResponseCreateInput = {
  message: Scalars["String"]["input"];
  success: Scalars["Boolean"]["input"];
};

export type UploadDocumentsResponseEdge = {
  __typename?: "UploadDocumentsResponseEdge";
  cursor: Scalars["String"]["output"];
  node: UploadDocumentsResponse;
};

export type UploadDocumentsResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UploadDocumentsResponseSort objects to sort UploadDocumentsResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UploadDocumentsResponseSort>>;
};

/** Fields to sort UploadDocumentsResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one UploadDocumentsResponseSort object. */
export type UploadDocumentsResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type UploadDocumentsResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UploadDocumentsResponseWhere = {
  AND?: InputMaybe<Array<UploadDocumentsResponseWhere>>;
  NOT?: InputMaybe<UploadDocumentsResponseWhere>;
  OR?: InputMaybe<Array<UploadDocumentsResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UploadDocumentsResponsesConnection = {
  __typename?: "UploadDocumentsResponsesConnection";
  edges: Array<UploadDocumentsResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UploadDocumentsToBucketResponse = {
  __typename?: "UploadDocumentsToBucketResponse";
  message: Scalars["String"]["output"];
  success: Scalars["Boolean"]["output"];
  urls: Array<Scalars["String"]["output"]>;
};

export type UploadDocumentsToBucketResponseAggregateSelection = {
  __typename?: "UploadDocumentsToBucketResponseAggregateSelection";
  count: Scalars["Int"]["output"];
  message: StringAggregateSelection;
};

export type UploadDocumentsToBucketResponseCreateInput = {
  message: Scalars["String"]["input"];
  success: Scalars["Boolean"]["input"];
  urls: Array<Scalars["String"]["input"]>;
};

export type UploadDocumentsToBucketResponseEdge = {
  __typename?: "UploadDocumentsToBucketResponseEdge";
  cursor: Scalars["String"]["output"];
  node: UploadDocumentsToBucketResponse;
};

export type UploadDocumentsToBucketResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UploadDocumentsToBucketResponseSort objects to sort UploadDocumentsToBucketResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UploadDocumentsToBucketResponseSort>>;
};

/** Fields to sort UploadDocumentsToBucketResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one UploadDocumentsToBucketResponseSort object. */
export type UploadDocumentsToBucketResponseSort = {
  message?: InputMaybe<SortDirection>;
  success?: InputMaybe<SortDirection>;
};

export type UploadDocumentsToBucketResponseUpdateInput = {
  message?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
  urls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  urls_POP?: InputMaybe<Scalars["Int"]["input"]>;
  urls_PUSH?: InputMaybe<Array<Scalars["String"]["input"]>>;
};

export type UploadDocumentsToBucketResponseWhere = {
  AND?: InputMaybe<Array<UploadDocumentsToBucketResponseWhere>>;
  NOT?: InputMaybe<UploadDocumentsToBucketResponseWhere>;
  OR?: InputMaybe<Array<UploadDocumentsToBucketResponseWhere>>;
  message?: InputMaybe<Scalars["String"]["input"]>;
  message_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  message_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  message_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  message_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  success?: InputMaybe<Scalars["Boolean"]["input"]>;
  urls?: InputMaybe<Array<Scalars["String"]["input"]>>;
  urls_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
};

export type UploadDocumentsToBucketResponsesConnection = {
  __typename?: "UploadDocumentsToBucketResponsesConnection";
  edges: Array<UploadDocumentsToBucketResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type User = {
  __typename?: "User";
  _empty?: Maybe<Scalars["String"]["output"]>;
  achievements: Array<Achievements>;
  achievementsAggregate?: Maybe<UserAchievementsAchievementsAggregationSelection>;
  achievementsConnection: UserAchievementsConnection;
  attributes: Array<Attribute>;
  attributesAggregate?: Maybe<UserAttributeAttributesAggregationSelection>;
  attributesConnection: UserAttributesConnection;
  audioOn?: Maybe<Scalars["Boolean"]["output"]>;
  birthday?: Maybe<Scalars["Date"]["output"]>;
  challenges?: Maybe<Array<Maybe<Challenge>>>;
  coreValues?: Maybe<Array<Maybe<CoreValue>>>;
  countryCode?: Maybe<Scalars["String"]["output"]>;
  created: Scalars["DateTime"]["output"];
  currentWeightLbs?: Maybe<Scalars["Float"]["output"]>;
  dailyEmailOptIn?: Maybe<Scalars["Boolean"]["output"]>;
  email: Scalars["String"]["output"];
  emotionalStates?: Maybe<Array<Maybe<EmotionalState>>>;
  enrolledTracks?: Maybe<Array<Maybe<Scalars["String"]["output"]>>>;
  feedback?: Maybe<Feedback>;
  fullPhoneNumber?: Maybe<Scalars["String"]["output"]>;
  gender?: Maybe<Scalars["String"]["output"]>;
  goalWeightLbs?: Maybe<Scalars["Float"]["output"]>;
  goals?: Maybe<Array<Maybe<Goal>>>;
  googleAuthTokens?: Maybe<Scalars["String"]["output"]>;
  googleCalendarAuthToken?: Maybe<Scalars["String"]["output"]>;
  hasChatChats: Array<Chat>;
  hasChatChatsAggregate?: Maybe<UserChatHasChatChatsAggregationSelection>;
  hasChatChatsConnection: UserHasChatChatsConnection;
  hasFoodItemFoodItems: Array<FoodItem>;
  hasFoodItemFoodItemsAggregate?: Maybe<UserFoodItemHasFoodItemFoodItemsAggregationSelection>;
  hasFoodItemFoodItemsConnection: UserHasFoodItemFoodItemsConnection;
  hasJourneyJourneys: Array<Journey>;
  hasJourneyJourneysAggregate?: Maybe<UserJourneyHasJourneyJourneysAggregationSelection>;
  hasJourneyJourneysConnection: UserHasJourneyJourneysConnection;
  hasPaymentPayments: Array<Payment>;
  hasPaymentPaymentsAggregate?: Maybe<UserPaymentHasPaymentPaymentsAggregationSelection>;
  hasPaymentPaymentsConnection: UserHasPaymentPaymentsConnection;
  hasPreferencesPreferences: Array<Preferences>;
  hasPreferencesPreferencesAggregate?: Maybe<UserPreferencesHasPreferencesPreferencesAggregationSelection>;
  hasPreferencesPreferencesConnection: UserHasPreferencesPreferencesConnection;
  hasRoleRoles: Array<Role>;
  hasRoleRolesAggregate?: Maybe<UserRoleHasRoleRolesAggregationSelection>;
  hasRoleRolesConnection: UserHasRoleRolesConnection;
  hasSessionDeviceSessions: Array<DeviceSession>;
  hasSessionDeviceSessionsAggregate?: Maybe<UserDeviceSessionHasSessionDeviceSessionsAggregationSelection>;
  hasSessionDeviceSessionsConnection: UserHasSessionDeviceSessionsConnection;
  hasSuggestionSuggestions: Array<Suggestion>;
  hasSuggestionSuggestionsAggregate?: Maybe<UserSuggestionHasSuggestionSuggestionsAggregationSelection>;
  hasSuggestionSuggestionsConnection: UserHasSuggestionSuggestionsConnection;
  hasSummaryDailySummaries: Array<DailySummary>;
  hasSummaryDailySummariesAggregate?: Maybe<UserDailySummaryHasSummaryDailySummariesAggregationSelection>;
  hasSummaryDailySummariesConnection: UserHasSummaryDailySummariesConnection;
  hasSummaryMonthlySummaries: Array<MonthlySummary>;
  hasSummaryMonthlySummariesAggregate?: Maybe<UserMonthlySummaryHasSummaryMonthlySummariesAggregationSelection>;
  hasSummaryMonthlySummariesConnection: UserHasSummaryMonthlySummariesConnection;
  hasSummaryQuarterlySummaries: Array<QuarterlySummary>;
  hasSummaryQuarterlySummariesAggregate?: Maybe<UserQuarterlySummaryHasSummaryQuarterlySummariesAggregationSelection>;
  hasSummaryQuarterlySummariesConnection: UserHasSummaryQuarterlySummariesConnection;
  hasSummaryWeeklySummaries: Array<WeeklySummary>;
  hasSummaryWeeklySummariesAggregate?: Maybe<UserWeeklySummaryHasSummaryWeeklySummariesAggregationSelection>;
  hasSummaryWeeklySummariesConnection: UserHasSummaryWeeklySummariesConnection;
  hasSummaryYearlySummaries: Array<YearlySummary>;
  hasSummaryYearlySummariesAggregate?: Maybe<UserYearlySummaryHasSummaryYearlySummariesAggregationSelection>;
  hasSummaryYearlySummariesConnection: UserHasSummaryYearlySummariesConnection;
  hasWaterIntakeWaterIntakes: Array<WaterIntake>;
  hasWaterIntakeWaterIntakesAggregate?: Maybe<UserWaterIntakeHasWaterIntakeWaterIntakesAggregationSelection>;
  hasWaterIntakeWaterIntakesConnection: UserHasWaterIntakeWaterIntakesConnection;
  hasWhitelistedEmails: Array<WhitelistedEmail>;
  hasWhitelistedEmailsAggregate?: Maybe<UserWhitelistedEmailHasWhitelistedEmailsAggregationSelection>;
  hasWhitelistedEmailsConnection: UserHasWhitelistedEmailsConnection;
  hobbies?: Maybe<Array<Maybe<Hobby>>>;
  id: Scalars["ID"]["output"];
  interests?: Maybe<Array<Maybe<Interest>>>;
  inviteCount?: Maybe<Scalars["Int"]["output"]>;
  isCareerLoading?: Maybe<Scalars["Boolean"]["output"]>;
  isCourseLoading?: Maybe<Scalars["Boolean"]["output"]>;
  isEmailVerified?: Maybe<Scalars["Boolean"]["output"]>;
  isOnboarded?: Maybe<Scalars["Boolean"]["output"]>;
  isPro?: Maybe<Scalars["Boolean"]["output"]>;
  lessons?: Maybe<Array<Maybe<Lesson>>>;
  lifeAspirations?: Maybe<Array<Maybe<LifeAspiration>>>;
  modalOnboarding?: Maybe<Scalars["String"]["output"]>;
  moodlePassword?: Maybe<Scalars["String"]["output"]>;
  name: Scalars["String"]["output"];
  otpPreference?: Maybe<Scalars["String"]["output"]>;
  password: Scalars["String"]["output"];
  personalityTraits?: Maybe<Array<Maybe<PersonalityTrait>>>;
  phoneNumber?: Maybe<Scalars["String"]["output"]>;
  preferences?: Maybe<Preferences>;
  progress?: Maybe<Progress>;
  reflections?: Maybe<Array<Maybe<Reflection>>>;
  stripeCustomerId?: Maybe<Scalars["String"]["output"]>;
  stripeSubscriptionId?: Maybe<Scalars["String"]["output"]>;
  tenants: Array<Tenant>;
  tenantsAggregate?: Maybe<UserTenantTenantsAggregationSelection>;
  tenantsConnection: UserTenantsConnection;
  todos: Array<Todo>;
  todosAggregate?: Maybe<UserTodoTodosAggregationSelection>;
  todosConnection: UserTodosConnection;
  trialEndDate?: Maybe<Scalars["Date"]["output"]>;
  undefinedFoodItems: Array<FoodItem>;
  undefinedFoodItemsAggregate?: Maybe<UserFoodItemUndefinedFoodItemsAggregationSelection>;
  undefinedFoodItemsConnection: UserUndefinedFoodItemsConnection;
  undefinedWaterIntakes: Array<WaterIntake>;
  undefinedWaterIntakesAggregate?: Maybe<UserWaterIntakeUndefinedWaterIntakesAggregationSelection>;
  undefinedWaterIntakesConnection: UserUndefinedWaterIntakesConnection;
  username?: Maybe<Scalars["String"]["output"]>;
};

export type UserAchievementsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AchievementsOptions>;
  where?: InputMaybe<AchievementsWhere>;
};

export type UserAchievementsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AchievementsWhere>;
};

export type UserAchievementsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserAchievementsConnectionSort>>;
  where?: InputMaybe<UserAchievementsConnectionWhere>;
};

export type UserAttributesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<AttributeOptions>;
  where?: InputMaybe<AttributeWhere>;
};

export type UserAttributesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<AttributeWhere>;
};

export type UserAttributesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserAttributesConnectionSort>>;
  where?: InputMaybe<UserAttributesConnectionWhere>;
};

export type UserHasChatChatsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<ChatOptions>;
  where?: InputMaybe<ChatWhere>;
};

export type UserHasChatChatsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<ChatWhere>;
};

export type UserHasChatChatsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasChatChatsConnectionSort>>;
  where?: InputMaybe<UserHasChatChatsConnectionWhere>;
};

export type UserHasFoodItemFoodItemsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<FoodItemOptions>;
  where?: InputMaybe<FoodItemWhere>;
};

export type UserHasFoodItemFoodItemsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<FoodItemWhere>;
};

export type UserHasFoodItemFoodItemsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasFoodItemFoodItemsConnectionSort>>;
  where?: InputMaybe<UserHasFoodItemFoodItemsConnectionWhere>;
};

export type UserHasJourneyJourneysArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<JourneyOptions>;
  where?: InputMaybe<JourneyWhere>;
};

export type UserHasJourneyJourneysAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<JourneyWhere>;
};

export type UserHasJourneyJourneysConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasJourneyJourneysConnectionSort>>;
  where?: InputMaybe<UserHasJourneyJourneysConnectionWhere>;
};

export type UserHasPaymentPaymentsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<PaymentOptions>;
  where?: InputMaybe<PaymentWhere>;
};

export type UserHasPaymentPaymentsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<PaymentWhere>;
};

export type UserHasPaymentPaymentsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasPaymentPaymentsConnectionSort>>;
  where?: InputMaybe<UserHasPaymentPaymentsConnectionWhere>;
};

export type UserHasPreferencesPreferencesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<PreferencesOptions>;
  where?: InputMaybe<PreferencesWhere>;
};

export type UserHasPreferencesPreferencesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<PreferencesWhere>;
};

export type UserHasPreferencesPreferencesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasPreferencesPreferencesConnectionSort>>;
  where?: InputMaybe<UserHasPreferencesPreferencesConnectionWhere>;
};

export type UserHasRoleRolesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<RoleOptions>;
  where?: InputMaybe<RoleWhere>;
};

export type UserHasRoleRolesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<RoleWhere>;
};

export type UserHasRoleRolesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasRoleRolesConnectionSort>>;
  where?: InputMaybe<UserHasRoleRolesConnectionWhere>;
};

export type UserHasSessionDeviceSessionsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<DeviceSessionOptions>;
  where?: InputMaybe<DeviceSessionWhere>;
};

export type UserHasSessionDeviceSessionsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<DeviceSessionWhere>;
};

export type UserHasSessionDeviceSessionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasSessionDeviceSessionsConnectionSort>>;
  where?: InputMaybe<UserHasSessionDeviceSessionsConnectionWhere>;
};

export type UserHasSuggestionSuggestionsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<SuggestionOptions>;
  where?: InputMaybe<SuggestionWhere>;
};

export type UserHasSuggestionSuggestionsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<SuggestionWhere>;
};

export type UserHasSuggestionSuggestionsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasSuggestionSuggestionsConnectionSort>>;
  where?: InputMaybe<UserHasSuggestionSuggestionsConnectionWhere>;
};

export type UserHasSummaryDailySummariesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<DailySummaryOptions>;
  where?: InputMaybe<DailySummaryWhere>;
};

export type UserHasSummaryDailySummariesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<DailySummaryWhere>;
};

export type UserHasSummaryDailySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasSummaryDailySummariesConnectionSort>>;
  where?: InputMaybe<UserHasSummaryDailySummariesConnectionWhere>;
};

export type UserHasSummaryMonthlySummariesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<MonthlySummaryOptions>;
  where?: InputMaybe<MonthlySummaryWhere>;
};

export type UserHasSummaryMonthlySummariesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<MonthlySummaryWhere>;
};

export type UserHasSummaryMonthlySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasSummaryMonthlySummariesConnectionSort>>;
  where?: InputMaybe<UserHasSummaryMonthlySummariesConnectionWhere>;
};

export type UserHasSummaryQuarterlySummariesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<QuarterlySummaryOptions>;
  where?: InputMaybe<QuarterlySummaryWhere>;
};

export type UserHasSummaryQuarterlySummariesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<QuarterlySummaryWhere>;
};

export type UserHasSummaryQuarterlySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasSummaryQuarterlySummariesConnectionSort>>;
  where?: InputMaybe<UserHasSummaryQuarterlySummariesConnectionWhere>;
};

export type UserHasSummaryWeeklySummariesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<WeeklySummaryOptions>;
  where?: InputMaybe<WeeklySummaryWhere>;
};

export type UserHasSummaryWeeklySummariesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<WeeklySummaryWhere>;
};

export type UserHasSummaryWeeklySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasSummaryWeeklySummariesConnectionSort>>;
  where?: InputMaybe<UserHasSummaryWeeklySummariesConnectionWhere>;
};

export type UserHasSummaryYearlySummariesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<YearlySummaryOptions>;
  where?: InputMaybe<YearlySummaryWhere>;
};

export type UserHasSummaryYearlySummariesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<YearlySummaryWhere>;
};

export type UserHasSummaryYearlySummariesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasSummaryYearlySummariesConnectionSort>>;
  where?: InputMaybe<UserHasSummaryYearlySummariesConnectionWhere>;
};

export type UserHasWaterIntakeWaterIntakesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<WaterIntakeOptions>;
  where?: InputMaybe<WaterIntakeWhere>;
};

export type UserHasWaterIntakeWaterIntakesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<WaterIntakeWhere>;
};

export type UserHasWaterIntakeWaterIntakesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesConnectionSort>>;
  where?: InputMaybe<UserHasWaterIntakeWaterIntakesConnectionWhere>;
};

export type UserHasWhitelistedEmailsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<WhitelistedEmailOptions>;
  where?: InputMaybe<WhitelistedEmailWhere>;
};

export type UserHasWhitelistedEmailsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<WhitelistedEmailWhere>;
};

export type UserHasWhitelistedEmailsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserHasWhitelistedEmailsConnectionSort>>;
  where?: InputMaybe<UserHasWhitelistedEmailsConnectionWhere>;
};

export type UserTenantsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<TenantOptions>;
  where?: InputMaybe<TenantWhere>;
};

export type UserTenantsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<TenantWhere>;
};

export type UserTenantsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserTenantsConnectionSort>>;
  where?: InputMaybe<UserTenantsConnectionWhere>;
};

export type UserTodosArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<TodoOptions>;
  where?: InputMaybe<TodoWhere>;
};

export type UserTodosAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<TodoWhere>;
};

export type UserTodosConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserTodosConnectionSort>>;
  where?: InputMaybe<UserTodosConnectionWhere>;
};

export type UserUndefinedFoodItemsArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<FoodItemOptions>;
  where?: InputMaybe<FoodItemWhere>;
};

export type UserUndefinedFoodItemsAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<FoodItemWhere>;
};

export type UserUndefinedFoodItemsConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserUndefinedFoodItemsConnectionSort>>;
  where?: InputMaybe<UserUndefinedFoodItemsConnectionWhere>;
};

export type UserUndefinedWaterIntakesArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<WaterIntakeOptions>;
  where?: InputMaybe<WaterIntakeWhere>;
};

export type UserUndefinedWaterIntakesAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<WaterIntakeWhere>;
};

export type UserUndefinedWaterIntakesConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<UserUndefinedWaterIntakesConnectionSort>>;
  where?: InputMaybe<UserUndefinedWaterIntakesConnectionWhere>;
};

export type UserAchievementsAchievementsAggregationSelection = {
  __typename?: "UserAchievementsAchievementsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserAchievementsAchievementsNodeAggregateSelection>;
};

export type UserAchievementsAchievementsNodeAggregateSelection = {
  __typename?: "UserAchievementsAchievementsNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
};

export type UserAchievementsAggregateInput = {
  AND?: InputMaybe<Array<UserAchievementsAggregateInput>>;
  NOT?: InputMaybe<UserAchievementsAggregateInput>;
  OR?: InputMaybe<Array<UserAchievementsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserAchievementsNodeAggregationWhereInput>;
};

export type UserAchievementsConnectFieldInput = {
  connect?: InputMaybe<Array<AchievementsConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AchievementsConnectWhere>;
};

export type UserAchievementsConnection = {
  __typename?: "UserAchievementsConnection";
  edges: Array<UserAchievementsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserAchievementsConnectionSort = {
  node?: InputMaybe<AchievementsSort>;
};

export type UserAchievementsConnectionWhere = {
  AND?: InputMaybe<Array<UserAchievementsConnectionWhere>>;
  NOT?: InputMaybe<UserAchievementsConnectionWhere>;
  OR?: InputMaybe<Array<UserAchievementsConnectionWhere>>;
  node?: InputMaybe<AchievementsWhere>;
};

export type UserAchievementsCreateFieldInput = {
  node: AchievementsCreateInput;
};

export type UserAchievementsDeleteFieldInput = {
  delete?: InputMaybe<AchievementsDeleteInput>;
  where?: InputMaybe<UserAchievementsConnectionWhere>;
};

export type UserAchievementsDisconnectFieldInput = {
  disconnect?: InputMaybe<AchievementsDisconnectInput>;
  where?: InputMaybe<UserAchievementsConnectionWhere>;
};

export type UserAchievementsFieldInput = {
  connect?: InputMaybe<Array<UserAchievementsConnectFieldInput>>;
  create?: InputMaybe<Array<UserAchievementsCreateFieldInput>>;
};

export type UserAchievementsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserAchievementsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserAchievementsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserAchievementsNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserAchievementsRelationship = {
  __typename?: "UserAchievementsRelationship";
  cursor: Scalars["String"]["output"];
  node: Achievements;
};

export type UserAchievementsUpdateConnectionInput = {
  node?: InputMaybe<AchievementsUpdateInput>;
};

export type UserAchievementsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserAchievementsConnectFieldInput>>;
  create?: InputMaybe<Array<UserAchievementsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserAchievementsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserAchievementsDisconnectFieldInput>>;
  update?: InputMaybe<UserAchievementsUpdateConnectionInput>;
  where?: InputMaybe<UserAchievementsConnectionWhere>;
};

export type UserAggregateSelection = {
  __typename?: "UserAggregateSelection";
  _empty: StringAggregateSelection;
  count: Scalars["Int"]["output"];
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type UserAttributeAttributesAggregationSelection = {
  __typename?: "UserAttributeAttributesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserAttributeAttributesNodeAggregateSelection>;
};

export type UserAttributeAttributesNodeAggregateSelection = {
  __typename?: "UserAttributeAttributesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  text: StringAggregateSelection;
};

export type UserAttributesAggregateInput = {
  AND?: InputMaybe<Array<UserAttributesAggregateInput>>;
  NOT?: InputMaybe<UserAttributesAggregateInput>;
  OR?: InputMaybe<Array<UserAttributesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserAttributesNodeAggregationWhereInput>;
};

export type UserAttributesConnectFieldInput = {
  connect?: InputMaybe<Array<AttributeConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<AttributeConnectWhere>;
};

export type UserAttributesConnection = {
  __typename?: "UserAttributesConnection";
  edges: Array<UserAttributesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserAttributesConnectionSort = {
  node?: InputMaybe<AttributeSort>;
};

export type UserAttributesConnectionWhere = {
  AND?: InputMaybe<Array<UserAttributesConnectionWhere>>;
  NOT?: InputMaybe<UserAttributesConnectionWhere>;
  OR?: InputMaybe<Array<UserAttributesConnectionWhere>>;
  node?: InputMaybe<AttributeWhere>;
};

export type UserAttributesCreateFieldInput = {
  node: AttributeCreateInput;
};

export type UserAttributesDeleteFieldInput = {
  delete?: InputMaybe<AttributeDeleteInput>;
  where?: InputMaybe<UserAttributesConnectionWhere>;
};

export type UserAttributesDisconnectFieldInput = {
  disconnect?: InputMaybe<AttributeDisconnectInput>;
  where?: InputMaybe<UserAttributesConnectionWhere>;
};

export type UserAttributesFieldInput = {
  connect?: InputMaybe<Array<UserAttributesConnectFieldInput>>;
  create?: InputMaybe<Array<UserAttributesCreateFieldInput>>;
};

export type UserAttributesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserAttributesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserAttributesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserAttributesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  text_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  text_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  text_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserAttributesRelationship = {
  __typename?: "UserAttributesRelationship";
  cursor: Scalars["String"]["output"];
  node: Attribute;
};

export type UserAttributesUpdateConnectionInput = {
  node?: InputMaybe<AttributeUpdateInput>;
};

export type UserAttributesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserAttributesConnectFieldInput>>;
  create?: InputMaybe<Array<UserAttributesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserAttributesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserAttributesDisconnectFieldInput>>;
  update?: InputMaybe<UserAttributesUpdateConnectionInput>;
  where?: InputMaybe<UserAttributesConnectionWhere>;
};

export type UserCareerLoadingResponse = {
  __typename?: "UserCareerLoadingResponse";
  isCareerLoading: Scalars["Boolean"]["output"];
};

export type UserCareerLoadingResponseAggregateSelection = {
  __typename?: "UserCareerLoadingResponseAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type UserCareerLoadingResponseCreateInput = {
  isCareerLoading: Scalars["Boolean"]["input"];
};

export type UserCareerLoadingResponseEdge = {
  __typename?: "UserCareerLoadingResponseEdge";
  cursor: Scalars["String"]["output"];
  node: UserCareerLoadingResponse;
};

export type UserCareerLoadingResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UserCareerLoadingResponseSort objects to sort UserCareerLoadingResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UserCareerLoadingResponseSort>>;
};

/** Fields to sort UserCareerLoadingResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one UserCareerLoadingResponseSort object. */
export type UserCareerLoadingResponseSort = {
  isCareerLoading?: InputMaybe<SortDirection>;
};

export type UserCareerLoadingResponseUpdateInput = {
  isCareerLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UserCareerLoadingResponseWhere = {
  AND?: InputMaybe<Array<UserCareerLoadingResponseWhere>>;
  NOT?: InputMaybe<UserCareerLoadingResponseWhere>;
  OR?: InputMaybe<Array<UserCareerLoadingResponseWhere>>;
  isCareerLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UserCareerLoadingResponsesConnection = {
  __typename?: "UserCareerLoadingResponsesConnection";
  edges: Array<UserCareerLoadingResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserChatHasChatChatsAggregationSelection = {
  __typename?: "UserChatHasChatChatsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserChatHasChatChatsEdgeAggregateSelection>;
  node?: Maybe<UserChatHasChatChatsNodeAggregateSelection>;
};

export type UserChatHasChatChatsEdgeAggregateSelection = {
  __typename?: "UserChatHasChatChatsEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
};

export type UserChatHasChatChatsNodeAggregateSelection = {
  __typename?: "UserChatHasChatChatsNodeAggregateSelection";
  context: StringAggregateSelection;
  date: StringAggregateSelection;
  id: IdAggregateSelection;
  name: StringAggregateSelection;
  objectId: IdAggregateSelection;
  timezone: StringAggregateSelection;
  url: StringAggregateSelection;
  userId: IdAggregateSelection;
};

export type UserConnectInput = {
  achievements?: InputMaybe<Array<UserAchievementsConnectFieldInput>>;
  attributes?: InputMaybe<Array<UserAttributesConnectFieldInput>>;
  hasChatChats?: InputMaybe<Array<UserHasChatChatsConnectFieldInput>>;
  hasFoodItemFoodItems?: InputMaybe<Array<UserHasFoodItemFoodItemsConnectFieldInput>>;
  hasJourneyJourneys?: InputMaybe<Array<UserHasJourneyJourneysConnectFieldInput>>;
  hasPaymentPayments?: InputMaybe<Array<UserHasPaymentPaymentsConnectFieldInput>>;
  hasPreferencesPreferences?: InputMaybe<Array<UserHasPreferencesPreferencesConnectFieldInput>>;
  hasRoleRoles?: InputMaybe<Array<UserHasRoleRolesConnectFieldInput>>;
  hasSessionDeviceSessions?: InputMaybe<Array<UserHasSessionDeviceSessionsConnectFieldInput>>;
  hasSuggestionSuggestions?: InputMaybe<Array<UserHasSuggestionSuggestionsConnectFieldInput>>;
  hasSummaryDailySummaries?: InputMaybe<Array<UserHasSummaryDailySummariesConnectFieldInput>>;
  hasSummaryMonthlySummaries?: InputMaybe<Array<UserHasSummaryMonthlySummariesConnectFieldInput>>;
  hasSummaryQuarterlySummaries?: InputMaybe<Array<UserHasSummaryQuarterlySummariesConnectFieldInput>>;
  hasSummaryWeeklySummaries?: InputMaybe<Array<UserHasSummaryWeeklySummariesConnectFieldInput>>;
  hasSummaryYearlySummaries?: InputMaybe<Array<UserHasSummaryYearlySummariesConnectFieldInput>>;
  hasWaterIntakeWaterIntakes?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesConnectFieldInput>>;
  hasWhitelistedEmails?: InputMaybe<Array<UserHasWhitelistedEmailsConnectFieldInput>>;
  tenants?: InputMaybe<Array<UserTenantsConnectFieldInput>>;
  todos?: InputMaybe<Array<UserTodosConnectFieldInput>>;
  undefinedFoodItems?: InputMaybe<Array<UserUndefinedFoodItemsConnectFieldInput>>;
  undefinedWaterIntakes?: InputMaybe<Array<UserUndefinedWaterIntakesConnectFieldInput>>;
};

export type UserConnectWhere = {
  node: UserWhere;
};

export type UserCourseLoadingResponse = {
  __typename?: "UserCourseLoadingResponse";
  isCourseLoading: Scalars["Boolean"]["output"];
};

export type UserCourseLoadingResponseAggregateSelection = {
  __typename?: "UserCourseLoadingResponseAggregateSelection";
  count: Scalars["Int"]["output"];
};

export type UserCourseLoadingResponseCreateInput = {
  isCourseLoading: Scalars["Boolean"]["input"];
};

export type UserCourseLoadingResponseEdge = {
  __typename?: "UserCourseLoadingResponseEdge";
  cursor: Scalars["String"]["output"];
  node: UserCourseLoadingResponse;
};

export type UserCourseLoadingResponseOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UserCourseLoadingResponseSort objects to sort UserCourseLoadingResponses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UserCourseLoadingResponseSort>>;
};

/** Fields to sort UserCourseLoadingResponses by. The order in which sorts are applied is not guaranteed when specifying many fields in one UserCourseLoadingResponseSort object. */
export type UserCourseLoadingResponseSort = {
  isCourseLoading?: InputMaybe<SortDirection>;
};

export type UserCourseLoadingResponseUpdateInput = {
  isCourseLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UserCourseLoadingResponseWhere = {
  AND?: InputMaybe<Array<UserCourseLoadingResponseWhere>>;
  NOT?: InputMaybe<UserCourseLoadingResponseWhere>;
  OR?: InputMaybe<Array<UserCourseLoadingResponseWhere>>;
  isCourseLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
};

export type UserCourseLoadingResponsesConnection = {
  __typename?: "UserCourseLoadingResponsesConnection";
  edges: Array<UserCourseLoadingResponseEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserCreateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  achievements?: InputMaybe<UserAchievementsFieldInput>;
  attributes?: InputMaybe<UserAttributesFieldInput>;
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
  birthday?: InputMaybe<Scalars["Date"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  created: Scalars["DateTime"]["input"];
  currentWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  dailyEmailOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  email: Scalars["String"]["input"];
  enrolledTracks?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fullPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  goalWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens?: InputMaybe<Scalars["String"]["input"]>;
  googleCalendarAuthToken?: InputMaybe<Scalars["String"]["input"]>;
  hasChatChats?: InputMaybe<UserHasChatChatsFieldInput>;
  hasFoodItemFoodItems?: InputMaybe<UserHasFoodItemFoodItemsFieldInput>;
  hasJourneyJourneys?: InputMaybe<UserHasJourneyJourneysFieldInput>;
  hasPaymentPayments?: InputMaybe<UserHasPaymentPaymentsFieldInput>;
  hasPreferencesPreferences?: InputMaybe<UserHasPreferencesPreferencesFieldInput>;
  hasRoleRoles?: InputMaybe<UserHasRoleRolesFieldInput>;
  hasSessionDeviceSessions?: InputMaybe<UserHasSessionDeviceSessionsFieldInput>;
  hasSuggestionSuggestions?: InputMaybe<UserHasSuggestionSuggestionsFieldInput>;
  hasSummaryDailySummaries?: InputMaybe<UserHasSummaryDailySummariesFieldInput>;
  hasSummaryMonthlySummaries?: InputMaybe<UserHasSummaryMonthlySummariesFieldInput>;
  hasSummaryQuarterlySummaries?: InputMaybe<UserHasSummaryQuarterlySummariesFieldInput>;
  hasSummaryWeeklySummaries?: InputMaybe<UserHasSummaryWeeklySummariesFieldInput>;
  hasSummaryYearlySummaries?: InputMaybe<UserHasSummaryYearlySummariesFieldInput>;
  hasWaterIntakeWaterIntakes?: InputMaybe<UserHasWaterIntakeWaterIntakesFieldInput>;
  hasWhitelistedEmails?: InputMaybe<UserHasWhitelistedEmailsFieldInput>;
  id: Scalars["ID"]["input"];
  inviteCount?: InputMaybe<Scalars["Int"]["input"]>;
  isCareerLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCourseLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEmailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
  moodlePassword?: InputMaybe<Scalars["String"]["input"]>;
  name: Scalars["String"]["input"];
  otpPreference?: InputMaybe<Scalars["String"]["input"]>;
  password: Scalars["String"]["input"];
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  tenants?: InputMaybe<UserTenantsFieldInput>;
  todos?: InputMaybe<UserTodosFieldInput>;
  trialEndDate?: InputMaybe<Scalars["Date"]["input"]>;
  undefinedFoodItems?: InputMaybe<UserUndefinedFoodItemsFieldInput>;
  undefinedWaterIntakes?: InputMaybe<UserUndefinedWaterIntakesFieldInput>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserDailySummaryHasSummaryDailySummariesAggregationSelection = {
  __typename?: "UserDailySummaryHasSummaryDailySummariesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserDailySummaryHasSummaryDailySummariesNodeAggregateSelection>;
};

export type UserDailySummaryHasSummaryDailySummariesNodeAggregateSelection = {
  __typename?: "UserDailySummaryHasSummaryDailySummariesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  date: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type UserDeleteInput = {
  achievements?: InputMaybe<Array<UserAchievementsDeleteFieldInput>>;
  attributes?: InputMaybe<Array<UserAttributesDeleteFieldInput>>;
  hasChatChats?: InputMaybe<Array<UserHasChatChatsDeleteFieldInput>>;
  hasFoodItemFoodItems?: InputMaybe<Array<UserHasFoodItemFoodItemsDeleteFieldInput>>;
  hasJourneyJourneys?: InputMaybe<Array<UserHasJourneyJourneysDeleteFieldInput>>;
  hasPaymentPayments?: InputMaybe<Array<UserHasPaymentPaymentsDeleteFieldInput>>;
  hasPreferencesPreferences?: InputMaybe<Array<UserHasPreferencesPreferencesDeleteFieldInput>>;
  hasRoleRoles?: InputMaybe<Array<UserHasRoleRolesDeleteFieldInput>>;
  hasSessionDeviceSessions?: InputMaybe<Array<UserHasSessionDeviceSessionsDeleteFieldInput>>;
  hasSuggestionSuggestions?: InputMaybe<Array<UserHasSuggestionSuggestionsDeleteFieldInput>>;
  hasSummaryDailySummaries?: InputMaybe<Array<UserHasSummaryDailySummariesDeleteFieldInput>>;
  hasSummaryMonthlySummaries?: InputMaybe<Array<UserHasSummaryMonthlySummariesDeleteFieldInput>>;
  hasSummaryQuarterlySummaries?: InputMaybe<Array<UserHasSummaryQuarterlySummariesDeleteFieldInput>>;
  hasSummaryWeeklySummaries?: InputMaybe<Array<UserHasSummaryWeeklySummariesDeleteFieldInput>>;
  hasSummaryYearlySummaries?: InputMaybe<Array<UserHasSummaryYearlySummariesDeleteFieldInput>>;
  hasWaterIntakeWaterIntakes?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesDeleteFieldInput>>;
  hasWhitelistedEmails?: InputMaybe<Array<UserHasWhitelistedEmailsDeleteFieldInput>>;
  tenants?: InputMaybe<Array<UserTenantsDeleteFieldInput>>;
  todos?: InputMaybe<Array<UserTodosDeleteFieldInput>>;
  undefinedFoodItems?: InputMaybe<Array<UserUndefinedFoodItemsDeleteFieldInput>>;
  undefinedWaterIntakes?: InputMaybe<Array<UserUndefinedWaterIntakesDeleteFieldInput>>;
};

export type UserDeviceSessionHasSessionDeviceSessionsAggregationSelection = {
  __typename?: "UserDeviceSessionHasSessionDeviceSessionsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserDeviceSessionHasSessionDeviceSessionsEdgeAggregateSelection>;
  node?: Maybe<UserDeviceSessionHasSessionDeviceSessionsNodeAggregateSelection>;
};

export type UserDeviceSessionHasSessionDeviceSessionsEdgeAggregateSelection = {
  __typename?: "UserDeviceSessionHasSessionDeviceSessionsEdgeAggregateSelection";
  createdAt: DateTimeAggregateSelection;
  expiresAt: DateTimeAggregateSelection;
};

export type UserDeviceSessionHasSessionDeviceSessionsNodeAggregateSelection = {
  __typename?: "UserDeviceSessionHasSessionDeviceSessionsNodeAggregateSelection";
  id: StringAggregateSelection;
};

export type UserDisconnectInput = {
  achievements?: InputMaybe<Array<UserAchievementsDisconnectFieldInput>>;
  attributes?: InputMaybe<Array<UserAttributesDisconnectFieldInput>>;
  hasChatChats?: InputMaybe<Array<UserHasChatChatsDisconnectFieldInput>>;
  hasFoodItemFoodItems?: InputMaybe<Array<UserHasFoodItemFoodItemsDisconnectFieldInput>>;
  hasJourneyJourneys?: InputMaybe<Array<UserHasJourneyJourneysDisconnectFieldInput>>;
  hasPaymentPayments?: InputMaybe<Array<UserHasPaymentPaymentsDisconnectFieldInput>>;
  hasPreferencesPreferences?: InputMaybe<Array<UserHasPreferencesPreferencesDisconnectFieldInput>>;
  hasRoleRoles?: InputMaybe<Array<UserHasRoleRolesDisconnectFieldInput>>;
  hasSessionDeviceSessions?: InputMaybe<Array<UserHasSessionDeviceSessionsDisconnectFieldInput>>;
  hasSuggestionSuggestions?: InputMaybe<Array<UserHasSuggestionSuggestionsDisconnectFieldInput>>;
  hasSummaryDailySummaries?: InputMaybe<Array<UserHasSummaryDailySummariesDisconnectFieldInput>>;
  hasSummaryMonthlySummaries?: InputMaybe<Array<UserHasSummaryMonthlySummariesDisconnectFieldInput>>;
  hasSummaryQuarterlySummaries?: InputMaybe<Array<UserHasSummaryQuarterlySummariesDisconnectFieldInput>>;
  hasSummaryWeeklySummaries?: InputMaybe<Array<UserHasSummaryWeeklySummariesDisconnectFieldInput>>;
  hasSummaryYearlySummaries?: InputMaybe<Array<UserHasSummaryYearlySummariesDisconnectFieldInput>>;
  hasWaterIntakeWaterIntakes?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesDisconnectFieldInput>>;
  hasWhitelistedEmails?: InputMaybe<Array<UserHasWhitelistedEmailsDisconnectFieldInput>>;
  tenants?: InputMaybe<Array<UserTenantsDisconnectFieldInput>>;
  todos?: InputMaybe<Array<UserTodosDisconnectFieldInput>>;
  undefinedFoodItems?: InputMaybe<Array<UserUndefinedFoodItemsDisconnectFieldInput>>;
  undefinedWaterIntakes?: InputMaybe<Array<UserUndefinedWaterIntakesDisconnectFieldInput>>;
};

export type UserEdge = {
  __typename?: "UserEdge";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type UserFoodItemHasFoodItemFoodItemsAggregationSelection = {
  __typename?: "UserFoodItemHasFoodItemFoodItemsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserFoodItemHasFoodItemFoodItemsEdgeAggregateSelection>;
  node?: Maybe<UserFoodItemHasFoodItemFoodItemsNodeAggregateSelection>;
};

export type UserFoodItemHasFoodItemFoodItemsEdgeAggregateSelection = {
  __typename?: "UserFoodItemHasFoodItemFoodItemsEdgeAggregateSelection";
  consumption_date: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  date: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  meal: StringAggregateSelection;
  meal_date: StringAggregateSelection;
  meal_time: StringAggregateSelection;
  meal_type: StringAggregateSelection;
  portion: FloatAggregateSelection;
  quality: BigIntAggregateSelection;
  quantity: FloatAggregateSelection;
  strength: BigIntAggregateSelection;
  time: StringAggregateSelection;
};

export type UserFoodItemHasFoodItemFoodItemsNodeAggregateSelection = {
  __typename?: "UserFoodItemHasFoodItemFoodItemsNodeAggregateSelection";
  calories: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  date: StringAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  serving_size: StringAggregateSelection;
  sugar_grams: FloatAggregateSelection;
};

export type UserFoodItemUndefinedFoodItemsAggregationSelection = {
  __typename?: "UserFoodItemUndefinedFoodItemsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserFoodItemUndefinedFoodItemsEdgeAggregateSelection>;
  node?: Maybe<UserFoodItemUndefinedFoodItemsNodeAggregateSelection>;
};

export type UserFoodItemUndefinedFoodItemsEdgeAggregateSelection = {
  __typename?: "UserFoodItemUndefinedFoodItemsEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  quality: BigIntAggregateSelection;
  strength: BigIntAggregateSelection;
};

export type UserFoodItemUndefinedFoodItemsNodeAggregateSelection = {
  __typename?: "UserFoodItemUndefinedFoodItemsNodeAggregateSelection";
  calories: FloatAggregateSelection;
  created: DateTimeAggregateSelection;
  date: StringAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  serving_size: StringAggregateSelection;
  sugar_grams: FloatAggregateSelection;
};

export type UserHasChatChatsAggregateInput = {
  AND?: InputMaybe<Array<UserHasChatChatsAggregateInput>>;
  NOT?: InputMaybe<UserHasChatChatsAggregateInput>;
  OR?: InputMaybe<Array<UserHasChatChatsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasChatPropertiesAggregationWhereInput>;
  node?: InputMaybe<UserHasChatChatsNodeAggregationWhereInput>;
};

export type UserHasChatChatsConnectFieldInput = {
  connect?: InputMaybe<Array<ChatConnectInput>>;
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<ChatConnectWhere>;
};

export type UserHasChatChatsConnection = {
  __typename?: "UserHasChatChatsConnection";
  edges: Array<UserHasChatChatsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasChatChatsConnectionSort = {
  edge?: InputMaybe<HasChatPropertiesSort>;
  node?: InputMaybe<ChatSort>;
};

export type UserHasChatChatsConnectionWhere = {
  AND?: InputMaybe<Array<UserHasChatChatsConnectionWhere>>;
  NOT?: InputMaybe<UserHasChatChatsConnectionWhere>;
  OR?: InputMaybe<Array<UserHasChatChatsConnectionWhere>>;
  edge?: InputMaybe<HasChatPropertiesWhere>;
  node?: InputMaybe<ChatWhere>;
};

export type UserHasChatChatsCreateFieldInput = {
  edge?: InputMaybe<HasChatPropertiesCreateInput>;
  node: ChatCreateInput;
};

export type UserHasChatChatsDeleteFieldInput = {
  delete?: InputMaybe<ChatDeleteInput>;
  where?: InputMaybe<UserHasChatChatsConnectionWhere>;
};

export type UserHasChatChatsDisconnectFieldInput = {
  disconnect?: InputMaybe<ChatDisconnectInput>;
  where?: InputMaybe<UserHasChatChatsConnectionWhere>;
};

export type UserHasChatChatsFieldInput = {
  connect?: InputMaybe<Array<UserHasChatChatsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasChatChatsCreateFieldInput>>;
};

export type UserHasChatChatsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasChatChatsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasChatChatsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasChatChatsNodeAggregationWhereInput>>;
  context_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  context_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  context_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  context_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasChatChatsRelationship = {
  __typename?: "UserHasChatChatsRelationship";
  cursor: Scalars["String"]["output"];
  node: Chat;
  properties: HasChatProperties;
};

export type UserHasChatChatsUpdateConnectionInput = {
  edge?: InputMaybe<HasChatPropertiesUpdateInput>;
  node?: InputMaybe<ChatUpdateInput>;
};

export type UserHasChatChatsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasChatChatsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasChatChatsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasChatChatsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasChatChatsDisconnectFieldInput>>;
  update?: InputMaybe<UserHasChatChatsUpdateConnectionInput>;
  where?: InputMaybe<UserHasChatChatsConnectionWhere>;
};

export type UserHasFoodItemFoodItemsAggregateInput = {
  AND?: InputMaybe<Array<UserHasFoodItemFoodItemsAggregateInput>>;
  NOT?: InputMaybe<UserHasFoodItemFoodItemsAggregateInput>;
  OR?: InputMaybe<Array<UserHasFoodItemFoodItemsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasFoodItemPropertiesAggregationWhereInput>;
  node?: InputMaybe<UserHasFoodItemFoodItemsNodeAggregationWhereInput>;
};

export type UserHasFoodItemFoodItemsConnectFieldInput = {
  connect?: InputMaybe<Array<FoodItemConnectInput>>;
  edge: HasFoodItemPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<FoodItemConnectWhere>;
};

export type UserHasFoodItemFoodItemsConnection = {
  __typename?: "UserHasFoodItemFoodItemsConnection";
  edges: Array<UserHasFoodItemFoodItemsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasFoodItemFoodItemsConnectionSort = {
  edge?: InputMaybe<HasFoodItemPropertiesSort>;
  node?: InputMaybe<FoodItemSort>;
};

export type UserHasFoodItemFoodItemsConnectionWhere = {
  AND?: InputMaybe<Array<UserHasFoodItemFoodItemsConnectionWhere>>;
  NOT?: InputMaybe<UserHasFoodItemFoodItemsConnectionWhere>;
  OR?: InputMaybe<Array<UserHasFoodItemFoodItemsConnectionWhere>>;
  edge?: InputMaybe<HasFoodItemPropertiesWhere>;
  node?: InputMaybe<FoodItemWhere>;
};

export type UserHasFoodItemFoodItemsCreateFieldInput = {
  edge: HasFoodItemPropertiesCreateInput;
  node: FoodItemCreateInput;
};

export type UserHasFoodItemFoodItemsDeleteFieldInput = {
  delete?: InputMaybe<FoodItemDeleteInput>;
  where?: InputMaybe<UserHasFoodItemFoodItemsConnectionWhere>;
};

export type UserHasFoodItemFoodItemsDisconnectFieldInput = {
  disconnect?: InputMaybe<FoodItemDisconnectInput>;
  where?: InputMaybe<UserHasFoodItemFoodItemsConnectionWhere>;
};

export type UserHasFoodItemFoodItemsFieldInput = {
  connect?: InputMaybe<Array<UserHasFoodItemFoodItemsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasFoodItemFoodItemsCreateFieldInput>>;
};

export type UserHasFoodItemFoodItemsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasFoodItemFoodItemsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasFoodItemFoodItemsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasFoodItemFoodItemsNodeAggregationWhereInput>>;
  calories_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calories_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sugar_grams_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserHasFoodItemFoodItemsRelationship = {
  __typename?: "UserHasFoodItemFoodItemsRelationship";
  cursor: Scalars["String"]["output"];
  node: FoodItem;
  properties: HasFoodItemProperties;
};

export type UserHasFoodItemFoodItemsUpdateConnectionInput = {
  edge?: InputMaybe<HasFoodItemPropertiesUpdateInput>;
  node?: InputMaybe<FoodItemUpdateInput>;
};

export type UserHasFoodItemFoodItemsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasFoodItemFoodItemsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasFoodItemFoodItemsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasFoodItemFoodItemsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasFoodItemFoodItemsDisconnectFieldInput>>;
  update?: InputMaybe<UserHasFoodItemFoodItemsUpdateConnectionInput>;
  where?: InputMaybe<UserHasFoodItemFoodItemsConnectionWhere>;
};

export type UserHasJourneyJourneysAggregateInput = {
  AND?: InputMaybe<Array<UserHasJourneyJourneysAggregateInput>>;
  NOT?: InputMaybe<UserHasJourneyJourneysAggregateInput>;
  OR?: InputMaybe<Array<UserHasJourneyJourneysAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasJourneyJourneysNodeAggregationWhereInput>;
};

export type UserHasJourneyJourneysConnectFieldInput = {
  connect?: InputMaybe<Array<JourneyConnectInput>>;
  edge?: InputMaybe<HasJourneyPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<JourneyConnectWhere>;
};

export type UserHasJourneyJourneysConnection = {
  __typename?: "UserHasJourneyJourneysConnection";
  edges: Array<UserHasJourneyJourneysRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasJourneyJourneysConnectionSort = {
  edge?: InputMaybe<HasJourneyPropertiesSort>;
  node?: InputMaybe<JourneySort>;
};

export type UserHasJourneyJourneysConnectionWhere = {
  AND?: InputMaybe<Array<UserHasJourneyJourneysConnectionWhere>>;
  NOT?: InputMaybe<UserHasJourneyJourneysConnectionWhere>;
  OR?: InputMaybe<Array<UserHasJourneyJourneysConnectionWhere>>;
  edge?: InputMaybe<HasJourneyPropertiesWhere>;
  node?: InputMaybe<JourneyWhere>;
};

export type UserHasJourneyJourneysCreateFieldInput = {
  edge?: InputMaybe<HasJourneyPropertiesCreateInput>;
  node: JourneyCreateInput;
};

export type UserHasJourneyJourneysDeleteFieldInput = {
  delete?: InputMaybe<JourneyDeleteInput>;
  where?: InputMaybe<UserHasJourneyJourneysConnectionWhere>;
};

export type UserHasJourneyJourneysDisconnectFieldInput = {
  disconnect?: InputMaybe<JourneyDisconnectInput>;
  where?: InputMaybe<UserHasJourneyJourneysConnectionWhere>;
};

export type UserHasJourneyJourneysFieldInput = {
  connect?: InputMaybe<Array<UserHasJourneyJourneysConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasJourneyJourneysCreateFieldInput>>;
};

export type UserHasJourneyJourneysNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasJourneyJourneysNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasJourneyJourneysNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasJourneyJourneysNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  completedTasks_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  completedTasks_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  dueDate_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  dueDate_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  dueDate_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  dueDate_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  dueDate_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  dueDate_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalTasks_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  totalTasks_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  url_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  url_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  url_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasJourneyJourneysRelationship = {
  __typename?: "UserHasJourneyJourneysRelationship";
  cursor: Scalars["String"]["output"];
  node: Journey;
  properties: HasJourneyProperties;
};

export type UserHasJourneyJourneysUpdateConnectionInput = {
  edge?: InputMaybe<HasJourneyPropertiesUpdateInput>;
  node?: InputMaybe<JourneyUpdateInput>;
};

export type UserHasJourneyJourneysUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasJourneyJourneysConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasJourneyJourneysCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasJourneyJourneysDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasJourneyJourneysDisconnectFieldInput>>;
  update?: InputMaybe<UserHasJourneyJourneysUpdateConnectionInput>;
  where?: InputMaybe<UserHasJourneyJourneysConnectionWhere>;
};

export type UserHasPaymentPaymentsAggregateInput = {
  AND?: InputMaybe<Array<UserHasPaymentPaymentsAggregateInput>>;
  NOT?: InputMaybe<UserHasPaymentPaymentsAggregateInput>;
  OR?: InputMaybe<Array<UserHasPaymentPaymentsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasPaymentPropertiesAggregationWhereInput>;
  node?: InputMaybe<UserHasPaymentPaymentsNodeAggregationWhereInput>;
};

export type UserHasPaymentPaymentsConnectFieldInput = {
  connect?: InputMaybe<Array<PaymentConnectInput>>;
  edge: HasPaymentPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<PaymentConnectWhere>;
};

export type UserHasPaymentPaymentsConnection = {
  __typename?: "UserHasPaymentPaymentsConnection";
  edges: Array<UserHasPaymentPaymentsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasPaymentPaymentsConnectionSort = {
  edge?: InputMaybe<HasPaymentPropertiesSort>;
  node?: InputMaybe<PaymentSort>;
};

export type UserHasPaymentPaymentsConnectionWhere = {
  AND?: InputMaybe<Array<UserHasPaymentPaymentsConnectionWhere>>;
  NOT?: InputMaybe<UserHasPaymentPaymentsConnectionWhere>;
  OR?: InputMaybe<Array<UserHasPaymentPaymentsConnectionWhere>>;
  edge?: InputMaybe<HasPaymentPropertiesWhere>;
  node?: InputMaybe<PaymentWhere>;
};

export type UserHasPaymentPaymentsCreateFieldInput = {
  edge: HasPaymentPropertiesCreateInput;
  node: PaymentCreateInput;
};

export type UserHasPaymentPaymentsDeleteFieldInput = {
  delete?: InputMaybe<PaymentDeleteInput>;
  where?: InputMaybe<UserHasPaymentPaymentsConnectionWhere>;
};

export type UserHasPaymentPaymentsDisconnectFieldInput = {
  disconnect?: InputMaybe<PaymentDisconnectInput>;
  where?: InputMaybe<UserHasPaymentPaymentsConnectionWhere>;
};

export type UserHasPaymentPaymentsFieldInput = {
  connect?: InputMaybe<Array<UserHasPaymentPaymentsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasPaymentPaymentsCreateFieldInput>>;
};

export type UserHasPaymentPaymentsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasPaymentPaymentsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasPaymentPaymentsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasPaymentPaymentsNodeAggregationWhereInput>>;
  domain_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  domain_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  domain_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  domain_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  domain_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  domain_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  domain_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  domain_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  domain_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  domain_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  domain_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  domain_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  domain_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  domain_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  domain_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasPaymentPaymentsRelationship = {
  __typename?: "UserHasPaymentPaymentsRelationship";
  cursor: Scalars["String"]["output"];
  node: Payment;
  properties: HasPaymentProperties;
};

export type UserHasPaymentPaymentsUpdateConnectionInput = {
  edge?: InputMaybe<HasPaymentPropertiesUpdateInput>;
  node?: InputMaybe<PaymentUpdateInput>;
};

export type UserHasPaymentPaymentsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasPaymentPaymentsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasPaymentPaymentsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasPaymentPaymentsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasPaymentPaymentsDisconnectFieldInput>>;
  update?: InputMaybe<UserHasPaymentPaymentsUpdateConnectionInput>;
  where?: InputMaybe<UserHasPaymentPaymentsConnectionWhere>;
};

export type UserHasPreferencesPreferencesAggregateInput = {
  AND?: InputMaybe<Array<UserHasPreferencesPreferencesAggregateInput>>;
  NOT?: InputMaybe<UserHasPreferencesPreferencesAggregateInput>;
  OR?: InputMaybe<Array<UserHasPreferencesPreferencesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasPreferencesPreferencesNodeAggregationWhereInput>;
};

export type UserHasPreferencesPreferencesConnectFieldInput = {
  connect?: InputMaybe<Array<PreferencesConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<PreferencesConnectWhere>;
};

export type UserHasPreferencesPreferencesConnection = {
  __typename?: "UserHasPreferencesPreferencesConnection";
  edges: Array<UserHasPreferencesPreferencesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasPreferencesPreferencesConnectionSort = {
  node?: InputMaybe<PreferencesSort>;
};

export type UserHasPreferencesPreferencesConnectionWhere = {
  AND?: InputMaybe<Array<UserHasPreferencesPreferencesConnectionWhere>>;
  NOT?: InputMaybe<UserHasPreferencesPreferencesConnectionWhere>;
  OR?: InputMaybe<Array<UserHasPreferencesPreferencesConnectionWhere>>;
  node?: InputMaybe<PreferencesWhere>;
};

export type UserHasPreferencesPreferencesCreateFieldInput = {
  node: PreferencesCreateInput;
};

export type UserHasPreferencesPreferencesDeleteFieldInput = {
  delete?: InputMaybe<PreferencesDeleteInput>;
  where?: InputMaybe<UserHasPreferencesPreferencesConnectionWhere>;
};

export type UserHasPreferencesPreferencesDisconnectFieldInput = {
  disconnect?: InputMaybe<PreferencesDisconnectInput>;
  where?: InputMaybe<UserHasPreferencesPreferencesConnectionWhere>;
};

export type UserHasPreferencesPreferencesFieldInput = {
  connect?: InputMaybe<Array<UserHasPreferencesPreferencesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasPreferencesPreferencesCreateFieldInput>>;
};

export type UserHasPreferencesPreferencesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasPreferencesPreferencesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasPreferencesPreferencesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasPreferencesPreferencesNodeAggregationWhereInput>>;
  contentType_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  contentType_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  contentType_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  contentType_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  contentType_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  contentType_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  contentType_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  contentType_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  contentType_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  contentType_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  contentType_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  contentType_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  contentType_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  contentType_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  contentType_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  language_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  language_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  language_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  language_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  language_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  language_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  language_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  language_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  language_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  language_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  language_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  language_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  language_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  language_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  language_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningDuration_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningDuration_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningDuration_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningDuration_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningDuration_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningDuration_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningStyle_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStyle_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStyle_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningStyle_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningStyle_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningStyle_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  notificationPreferences_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  notificationPreferences_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  notificationPreferences_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  notificationPreferences_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  notificationPreferences_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  notificationPreferences_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasPreferencesPreferencesRelationship = {
  __typename?: "UserHasPreferencesPreferencesRelationship";
  cursor: Scalars["String"]["output"];
  node: Preferences;
};

export type UserHasPreferencesPreferencesUpdateConnectionInput = {
  node?: InputMaybe<PreferencesUpdateInput>;
};

export type UserHasPreferencesPreferencesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasPreferencesPreferencesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasPreferencesPreferencesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasPreferencesPreferencesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasPreferencesPreferencesDisconnectFieldInput>>;
  update?: InputMaybe<UserHasPreferencesPreferencesUpdateConnectionInput>;
  where?: InputMaybe<UserHasPreferencesPreferencesConnectionWhere>;
};

export type UserHasRoleRolesAggregateInput = {
  AND?: InputMaybe<Array<UserHasRoleRolesAggregateInput>>;
  NOT?: InputMaybe<UserHasRoleRolesAggregateInput>;
  OR?: InputMaybe<Array<UserHasRoleRolesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<UserRolePropertiesAggregationWhereInput>;
  node?: InputMaybe<UserHasRoleRolesNodeAggregationWhereInput>;
};

export type UserHasRoleRolesConnectFieldInput = {
  connect?: InputMaybe<Array<RoleConnectInput>>;
  edge: UserRolePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<RoleConnectWhere>;
};

export type UserHasRoleRolesConnection = {
  __typename?: "UserHasRoleRolesConnection";
  edges: Array<UserHasRoleRolesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasRoleRolesConnectionSort = {
  edge?: InputMaybe<UserRolePropertiesSort>;
  node?: InputMaybe<RoleSort>;
};

export type UserHasRoleRolesConnectionWhere = {
  AND?: InputMaybe<Array<UserHasRoleRolesConnectionWhere>>;
  NOT?: InputMaybe<UserHasRoleRolesConnectionWhere>;
  OR?: InputMaybe<Array<UserHasRoleRolesConnectionWhere>>;
  edge?: InputMaybe<UserRolePropertiesWhere>;
  node?: InputMaybe<RoleWhere>;
};

export type UserHasRoleRolesCreateFieldInput = {
  edge: UserRolePropertiesCreateInput;
  node: RoleCreateInput;
};

export type UserHasRoleRolesDeleteFieldInput = {
  delete?: InputMaybe<RoleDeleteInput>;
  where?: InputMaybe<UserHasRoleRolesConnectionWhere>;
};

export type UserHasRoleRolesDisconnectFieldInput = {
  disconnect?: InputMaybe<RoleDisconnectInput>;
  where?: InputMaybe<UserHasRoleRolesConnectionWhere>;
};

export type UserHasRoleRolesFieldInput = {
  connect?: InputMaybe<Array<UserHasRoleRolesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasRoleRolesCreateFieldInput>>;
};

export type UserHasRoleRolesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasRoleRolesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasRoleRolesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasRoleRolesNodeAggregationWhereInput>>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasRoleRolesRelationship = {
  __typename?: "UserHasRoleRolesRelationship";
  cursor: Scalars["String"]["output"];
  node: Role;
  properties: UserRoleProperties;
};

export type UserHasRoleRolesUpdateConnectionInput = {
  edge?: InputMaybe<UserRolePropertiesUpdateInput>;
  node?: InputMaybe<RoleUpdateInput>;
};

export type UserHasRoleRolesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasRoleRolesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasRoleRolesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasRoleRolesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasRoleRolesDisconnectFieldInput>>;
  update?: InputMaybe<UserHasRoleRolesUpdateConnectionInput>;
  where?: InputMaybe<UserHasRoleRolesConnectionWhere>;
};

export type UserHasSessionDeviceSessionsAggregateInput = {
  AND?: InputMaybe<Array<UserHasSessionDeviceSessionsAggregateInput>>;
  NOT?: InputMaybe<UserHasSessionDeviceSessionsAggregateInput>;
  OR?: InputMaybe<Array<UserHasSessionDeviceSessionsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasSessionPropertiesAggregationWhereInput>;
  node?: InputMaybe<UserHasSessionDeviceSessionsNodeAggregationWhereInput>;
};

export type UserHasSessionDeviceSessionsConnectFieldInput = {
  connect?: InputMaybe<Array<DeviceSessionConnectInput>>;
  edge: HasSessionPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<DeviceSessionConnectWhere>;
};

export type UserHasSessionDeviceSessionsConnection = {
  __typename?: "UserHasSessionDeviceSessionsConnection";
  edges: Array<UserHasSessionDeviceSessionsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasSessionDeviceSessionsConnectionSort = {
  edge?: InputMaybe<HasSessionPropertiesSort>;
  node?: InputMaybe<DeviceSessionSort>;
};

export type UserHasSessionDeviceSessionsConnectionWhere = {
  AND?: InputMaybe<Array<UserHasSessionDeviceSessionsConnectionWhere>>;
  NOT?: InputMaybe<UserHasSessionDeviceSessionsConnectionWhere>;
  OR?: InputMaybe<Array<UserHasSessionDeviceSessionsConnectionWhere>>;
  edge?: InputMaybe<HasSessionPropertiesWhere>;
  node?: InputMaybe<DeviceSessionWhere>;
};

export type UserHasSessionDeviceSessionsCreateFieldInput = {
  edge: HasSessionPropertiesCreateInput;
  node: DeviceSessionCreateInput;
};

export type UserHasSessionDeviceSessionsDeleteFieldInput = {
  delete?: InputMaybe<DeviceSessionDeleteInput>;
  where?: InputMaybe<UserHasSessionDeviceSessionsConnectionWhere>;
};

export type UserHasSessionDeviceSessionsDisconnectFieldInput = {
  disconnect?: InputMaybe<DeviceSessionDisconnectInput>;
  where?: InputMaybe<UserHasSessionDeviceSessionsConnectionWhere>;
};

export type UserHasSessionDeviceSessionsFieldInput = {
  connect?: InputMaybe<Array<UserHasSessionDeviceSessionsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSessionDeviceSessionsCreateFieldInput>>;
};

export type UserHasSessionDeviceSessionsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasSessionDeviceSessionsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasSessionDeviceSessionsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasSessionDeviceSessionsNodeAggregationWhereInput>>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasSessionDeviceSessionsRelationship = {
  __typename?: "UserHasSessionDeviceSessionsRelationship";
  cursor: Scalars["String"]["output"];
  node: DeviceSession;
  properties: HasSessionProperties;
};

export type UserHasSessionDeviceSessionsUpdateConnectionInput = {
  edge?: InputMaybe<HasSessionPropertiesUpdateInput>;
  node?: InputMaybe<DeviceSessionUpdateInput>;
};

export type UserHasSessionDeviceSessionsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasSessionDeviceSessionsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSessionDeviceSessionsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasSessionDeviceSessionsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasSessionDeviceSessionsDisconnectFieldInput>>;
  update?: InputMaybe<UserHasSessionDeviceSessionsUpdateConnectionInput>;
  where?: InputMaybe<UserHasSessionDeviceSessionsConnectionWhere>;
};

export type UserHasSuggestionSuggestionsAggregateInput = {
  AND?: InputMaybe<Array<UserHasSuggestionSuggestionsAggregateInput>>;
  NOT?: InputMaybe<UserHasSuggestionSuggestionsAggregateInput>;
  OR?: InputMaybe<Array<UserHasSuggestionSuggestionsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasSuggestionSuggestionsNodeAggregationWhereInput>;
};

export type UserHasSuggestionSuggestionsConnectFieldInput = {
  connect?: InputMaybe<Array<SuggestionConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<SuggestionConnectWhere>;
};

export type UserHasSuggestionSuggestionsConnection = {
  __typename?: "UserHasSuggestionSuggestionsConnection";
  edges: Array<UserHasSuggestionSuggestionsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasSuggestionSuggestionsConnectionSort = {
  node?: InputMaybe<SuggestionSort>;
};

export type UserHasSuggestionSuggestionsConnectionWhere = {
  AND?: InputMaybe<Array<UserHasSuggestionSuggestionsConnectionWhere>>;
  NOT?: InputMaybe<UserHasSuggestionSuggestionsConnectionWhere>;
  OR?: InputMaybe<Array<UserHasSuggestionSuggestionsConnectionWhere>>;
  node?: InputMaybe<SuggestionWhere>;
};

export type UserHasSuggestionSuggestionsCreateFieldInput = {
  node: SuggestionCreateInput;
};

export type UserHasSuggestionSuggestionsDeleteFieldInput = {
  delete?: InputMaybe<SuggestionDeleteInput>;
  where?: InputMaybe<UserHasSuggestionSuggestionsConnectionWhere>;
};

export type UserHasSuggestionSuggestionsDisconnectFieldInput = {
  disconnect?: InputMaybe<SuggestionDisconnectInput>;
  where?: InputMaybe<UserHasSuggestionSuggestionsConnectionWhere>;
};

export type UserHasSuggestionSuggestionsFieldInput = {
  connect?: InputMaybe<Array<UserHasSuggestionSuggestionsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSuggestionSuggestionsCreateFieldInput>>;
};

export type UserHasSuggestionSuggestionsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasSuggestionSuggestionsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasSuggestionSuggestionsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasSuggestionSuggestionsNodeAggregationWhereInput>>;
  assignmentId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  assignmentId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  assignmentId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignmentId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  assignmentId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  assignmentId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignmentId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignmentId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignmentId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignmentId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignmentId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  assignmentId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  assignmentId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  assignmentId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  assignmentId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  classId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  classId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  classId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  classId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  classId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  classId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  classId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  classId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  classId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  classId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  classId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  classId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  classId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  classId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  classId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  content_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  content_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  content_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  content_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  integrationId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  integrationId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  integrationId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  journeyId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  journeyId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  journeyId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  journeyId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  journeyId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  journeyId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  trackName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  trackName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  trackName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  trackName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  trackName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  trackName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  type_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  type_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  type_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  type_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasSuggestionSuggestionsRelationship = {
  __typename?: "UserHasSuggestionSuggestionsRelationship";
  cursor: Scalars["String"]["output"];
  node: Suggestion;
};

export type UserHasSuggestionSuggestionsUpdateConnectionInput = {
  node?: InputMaybe<SuggestionUpdateInput>;
};

export type UserHasSuggestionSuggestionsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasSuggestionSuggestionsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSuggestionSuggestionsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasSuggestionSuggestionsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasSuggestionSuggestionsDisconnectFieldInput>>;
  update?: InputMaybe<UserHasSuggestionSuggestionsUpdateConnectionInput>;
  where?: InputMaybe<UserHasSuggestionSuggestionsConnectionWhere>;
};

export type UserHasSummaryDailySummariesAggregateInput = {
  AND?: InputMaybe<Array<UserHasSummaryDailySummariesAggregateInput>>;
  NOT?: InputMaybe<UserHasSummaryDailySummariesAggregateInput>;
  OR?: InputMaybe<Array<UserHasSummaryDailySummariesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasSummaryDailySummariesNodeAggregationWhereInput>;
};

export type UserHasSummaryDailySummariesConnectFieldInput = {
  connect?: InputMaybe<Array<DailySummaryConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<DailySummaryConnectWhere>;
};

export type UserHasSummaryDailySummariesConnection = {
  __typename?: "UserHasSummaryDailySummariesConnection";
  edges: Array<UserHasSummaryDailySummariesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasSummaryDailySummariesConnectionSort = {
  node?: InputMaybe<DailySummarySort>;
};

export type UserHasSummaryDailySummariesConnectionWhere = {
  AND?: InputMaybe<Array<UserHasSummaryDailySummariesConnectionWhere>>;
  NOT?: InputMaybe<UserHasSummaryDailySummariesConnectionWhere>;
  OR?: InputMaybe<Array<UserHasSummaryDailySummariesConnectionWhere>>;
  node?: InputMaybe<DailySummaryWhere>;
};

export type UserHasSummaryDailySummariesCreateFieldInput = {
  node: DailySummaryCreateInput;
};

export type UserHasSummaryDailySummariesDeleteFieldInput = {
  delete?: InputMaybe<DailySummaryDeleteInput>;
  where?: InputMaybe<UserHasSummaryDailySummariesConnectionWhere>;
};

export type UserHasSummaryDailySummariesDisconnectFieldInput = {
  disconnect?: InputMaybe<DailySummaryDisconnectInput>;
  where?: InputMaybe<UserHasSummaryDailySummariesConnectionWhere>;
};

export type UserHasSummaryDailySummariesFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryDailySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryDailySummariesCreateFieldInput>>;
};

export type UserHasSummaryDailySummariesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasSummaryDailySummariesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasSummaryDailySummariesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasSummaryDailySummariesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasSummaryDailySummariesRelationship = {
  __typename?: "UserHasSummaryDailySummariesRelationship";
  cursor: Scalars["String"]["output"];
  node: DailySummary;
};

export type UserHasSummaryDailySummariesUpdateConnectionInput = {
  node?: InputMaybe<DailySummaryUpdateInput>;
};

export type UserHasSummaryDailySummariesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryDailySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryDailySummariesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasSummaryDailySummariesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasSummaryDailySummariesDisconnectFieldInput>>;
  update?: InputMaybe<UserHasSummaryDailySummariesUpdateConnectionInput>;
  where?: InputMaybe<UserHasSummaryDailySummariesConnectionWhere>;
};

export type UserHasSummaryMonthlySummariesAggregateInput = {
  AND?: InputMaybe<Array<UserHasSummaryMonthlySummariesAggregateInput>>;
  NOT?: InputMaybe<UserHasSummaryMonthlySummariesAggregateInput>;
  OR?: InputMaybe<Array<UserHasSummaryMonthlySummariesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasSummaryMonthlySummariesNodeAggregationWhereInput>;
};

export type UserHasSummaryMonthlySummariesConnectFieldInput = {
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<MonthlySummaryConnectWhere>;
};

export type UserHasSummaryMonthlySummariesConnection = {
  __typename?: "UserHasSummaryMonthlySummariesConnection";
  edges: Array<UserHasSummaryMonthlySummariesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasSummaryMonthlySummariesConnectionSort = {
  node?: InputMaybe<MonthlySummarySort>;
};

export type UserHasSummaryMonthlySummariesConnectionWhere = {
  AND?: InputMaybe<Array<UserHasSummaryMonthlySummariesConnectionWhere>>;
  NOT?: InputMaybe<UserHasSummaryMonthlySummariesConnectionWhere>;
  OR?: InputMaybe<Array<UserHasSummaryMonthlySummariesConnectionWhere>>;
  node?: InputMaybe<MonthlySummaryWhere>;
};

export type UserHasSummaryMonthlySummariesCreateFieldInput = {
  node: MonthlySummaryCreateInput;
};

export type UserHasSummaryMonthlySummariesDeleteFieldInput = {
  where?: InputMaybe<UserHasSummaryMonthlySummariesConnectionWhere>;
};

export type UserHasSummaryMonthlySummariesDisconnectFieldInput = {
  where?: InputMaybe<UserHasSummaryMonthlySummariesConnectionWhere>;
};

export type UserHasSummaryMonthlySummariesFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryMonthlySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryMonthlySummariesCreateFieldInput>>;
};

export type UserHasSummaryMonthlySummariesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasSummaryMonthlySummariesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasSummaryMonthlySummariesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasSummaryMonthlySummariesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasSummaryMonthlySummariesRelationship = {
  __typename?: "UserHasSummaryMonthlySummariesRelationship";
  cursor: Scalars["String"]["output"];
  node: MonthlySummary;
};

export type UserHasSummaryMonthlySummariesUpdateConnectionInput = {
  node?: InputMaybe<MonthlySummaryUpdateInput>;
};

export type UserHasSummaryMonthlySummariesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryMonthlySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryMonthlySummariesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasSummaryMonthlySummariesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasSummaryMonthlySummariesDisconnectFieldInput>>;
  update?: InputMaybe<UserHasSummaryMonthlySummariesUpdateConnectionInput>;
  where?: InputMaybe<UserHasSummaryMonthlySummariesConnectionWhere>;
};

export type UserHasSummaryQuarterlySummariesAggregateInput = {
  AND?: InputMaybe<Array<UserHasSummaryQuarterlySummariesAggregateInput>>;
  NOT?: InputMaybe<UserHasSummaryQuarterlySummariesAggregateInput>;
  OR?: InputMaybe<Array<UserHasSummaryQuarterlySummariesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasSummaryQuarterlySummariesNodeAggregationWhereInput>;
};

export type UserHasSummaryQuarterlySummariesConnectFieldInput = {
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<QuarterlySummaryConnectWhere>;
};

export type UserHasSummaryQuarterlySummariesConnection = {
  __typename?: "UserHasSummaryQuarterlySummariesConnection";
  edges: Array<UserHasSummaryQuarterlySummariesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasSummaryQuarterlySummariesConnectionSort = {
  node?: InputMaybe<QuarterlySummarySort>;
};

export type UserHasSummaryQuarterlySummariesConnectionWhere = {
  AND?: InputMaybe<Array<UserHasSummaryQuarterlySummariesConnectionWhere>>;
  NOT?: InputMaybe<UserHasSummaryQuarterlySummariesConnectionWhere>;
  OR?: InputMaybe<Array<UserHasSummaryQuarterlySummariesConnectionWhere>>;
  node?: InputMaybe<QuarterlySummaryWhere>;
};

export type UserHasSummaryQuarterlySummariesCreateFieldInput = {
  node: QuarterlySummaryCreateInput;
};

export type UserHasSummaryQuarterlySummariesDeleteFieldInput = {
  where?: InputMaybe<UserHasSummaryQuarterlySummariesConnectionWhere>;
};

export type UserHasSummaryQuarterlySummariesDisconnectFieldInput = {
  where?: InputMaybe<UserHasSummaryQuarterlySummariesConnectionWhere>;
};

export type UserHasSummaryQuarterlySummariesFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryQuarterlySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryQuarterlySummariesCreateFieldInput>>;
};

export type UserHasSummaryQuarterlySummariesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasSummaryQuarterlySummariesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasSummaryQuarterlySummariesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasSummaryQuarterlySummariesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasSummaryQuarterlySummariesRelationship = {
  __typename?: "UserHasSummaryQuarterlySummariesRelationship";
  cursor: Scalars["String"]["output"];
  node: QuarterlySummary;
};

export type UserHasSummaryQuarterlySummariesUpdateConnectionInput = {
  node?: InputMaybe<QuarterlySummaryUpdateInput>;
};

export type UserHasSummaryQuarterlySummariesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryQuarterlySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryQuarterlySummariesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasSummaryQuarterlySummariesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasSummaryQuarterlySummariesDisconnectFieldInput>>;
  update?: InputMaybe<UserHasSummaryQuarterlySummariesUpdateConnectionInput>;
  where?: InputMaybe<UserHasSummaryQuarterlySummariesConnectionWhere>;
};

export type UserHasSummaryWeeklySummariesAggregateInput = {
  AND?: InputMaybe<Array<UserHasSummaryWeeklySummariesAggregateInput>>;
  NOT?: InputMaybe<UserHasSummaryWeeklySummariesAggregateInput>;
  OR?: InputMaybe<Array<UserHasSummaryWeeklySummariesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasSummaryWeeklySummariesNodeAggregationWhereInput>;
};

export type UserHasSummaryWeeklySummariesConnectFieldInput = {
  connect?: InputMaybe<Array<WeeklySummaryConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<WeeklySummaryConnectWhere>;
};

export type UserHasSummaryWeeklySummariesConnection = {
  __typename?: "UserHasSummaryWeeklySummariesConnection";
  edges: Array<UserHasSummaryWeeklySummariesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasSummaryWeeklySummariesConnectionSort = {
  node?: InputMaybe<WeeklySummarySort>;
};

export type UserHasSummaryWeeklySummariesConnectionWhere = {
  AND?: InputMaybe<Array<UserHasSummaryWeeklySummariesConnectionWhere>>;
  NOT?: InputMaybe<UserHasSummaryWeeklySummariesConnectionWhere>;
  OR?: InputMaybe<Array<UserHasSummaryWeeklySummariesConnectionWhere>>;
  node?: InputMaybe<WeeklySummaryWhere>;
};

export type UserHasSummaryWeeklySummariesCreateFieldInput = {
  node: WeeklySummaryCreateInput;
};

export type UserHasSummaryWeeklySummariesDeleteFieldInput = {
  delete?: InputMaybe<WeeklySummaryDeleteInput>;
  where?: InputMaybe<UserHasSummaryWeeklySummariesConnectionWhere>;
};

export type UserHasSummaryWeeklySummariesDisconnectFieldInput = {
  disconnect?: InputMaybe<WeeklySummaryDisconnectInput>;
  where?: InputMaybe<UserHasSummaryWeeklySummariesConnectionWhere>;
};

export type UserHasSummaryWeeklySummariesFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryWeeklySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryWeeklySummariesCreateFieldInput>>;
};

export type UserHasSummaryWeeklySummariesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasSummaryWeeklySummariesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasSummaryWeeklySummariesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasSummaryWeeklySummariesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasSummaryWeeklySummariesRelationship = {
  __typename?: "UserHasSummaryWeeklySummariesRelationship";
  cursor: Scalars["String"]["output"];
  node: WeeklySummary;
};

export type UserHasSummaryWeeklySummariesUpdateConnectionInput = {
  node?: InputMaybe<WeeklySummaryUpdateInput>;
};

export type UserHasSummaryWeeklySummariesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryWeeklySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryWeeklySummariesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasSummaryWeeklySummariesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasSummaryWeeklySummariesDisconnectFieldInput>>;
  update?: InputMaybe<UserHasSummaryWeeklySummariesUpdateConnectionInput>;
  where?: InputMaybe<UserHasSummaryWeeklySummariesConnectionWhere>;
};

export type UserHasSummaryYearlySummariesAggregateInput = {
  AND?: InputMaybe<Array<UserHasSummaryYearlySummariesAggregateInput>>;
  NOT?: InputMaybe<UserHasSummaryYearlySummariesAggregateInput>;
  OR?: InputMaybe<Array<UserHasSummaryYearlySummariesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasSummaryYearlySummariesNodeAggregationWhereInput>;
};

export type UserHasSummaryYearlySummariesConnectFieldInput = {
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<YearlySummaryConnectWhere>;
};

export type UserHasSummaryYearlySummariesConnection = {
  __typename?: "UserHasSummaryYearlySummariesConnection";
  edges: Array<UserHasSummaryYearlySummariesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasSummaryYearlySummariesConnectionSort = {
  node?: InputMaybe<YearlySummarySort>;
};

export type UserHasSummaryYearlySummariesConnectionWhere = {
  AND?: InputMaybe<Array<UserHasSummaryYearlySummariesConnectionWhere>>;
  NOT?: InputMaybe<UserHasSummaryYearlySummariesConnectionWhere>;
  OR?: InputMaybe<Array<UserHasSummaryYearlySummariesConnectionWhere>>;
  node?: InputMaybe<YearlySummaryWhere>;
};

export type UserHasSummaryYearlySummariesCreateFieldInput = {
  node: YearlySummaryCreateInput;
};

export type UserHasSummaryYearlySummariesDeleteFieldInput = {
  where?: InputMaybe<UserHasSummaryYearlySummariesConnectionWhere>;
};

export type UserHasSummaryYearlySummariesDisconnectFieldInput = {
  where?: InputMaybe<UserHasSummaryYearlySummariesConnectionWhere>;
};

export type UserHasSummaryYearlySummariesFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryYearlySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryYearlySummariesCreateFieldInput>>;
};

export type UserHasSummaryYearlySummariesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasSummaryYearlySummariesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasSummaryYearlySummariesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasSummaryYearlySummariesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  summary_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  summary_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  summary_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasSummaryYearlySummariesRelationship = {
  __typename?: "UserHasSummaryYearlySummariesRelationship";
  cursor: Scalars["String"]["output"];
  node: YearlySummary;
};

export type UserHasSummaryYearlySummariesUpdateConnectionInput = {
  node?: InputMaybe<YearlySummaryUpdateInput>;
};

export type UserHasSummaryYearlySummariesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasSummaryYearlySummariesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasSummaryYearlySummariesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasSummaryYearlySummariesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasSummaryYearlySummariesDisconnectFieldInput>>;
  update?: InputMaybe<UserHasSummaryYearlySummariesUpdateConnectionInput>;
  where?: InputMaybe<UserHasSummaryYearlySummariesConnectionWhere>;
};

export type UserHasWaterIntakeWaterIntakesAggregateInput = {
  AND?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesAggregateInput>>;
  NOT?: InputMaybe<UserHasWaterIntakeWaterIntakesAggregateInput>;
  OR?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasWaterIntakePropertiesAggregationWhereInput>;
  node?: InputMaybe<UserHasWaterIntakeWaterIntakesNodeAggregationWhereInput>;
};

export type UserHasWaterIntakeWaterIntakesConnectFieldInput = {
  connect?: InputMaybe<Array<WaterIntakeConnectInput>>;
  edge: HasWaterIntakePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<WaterIntakeConnectWhere>;
};

export type UserHasWaterIntakeWaterIntakesConnection = {
  __typename?: "UserHasWaterIntakeWaterIntakesConnection";
  edges: Array<UserHasWaterIntakeWaterIntakesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasWaterIntakeWaterIntakesConnectionSort = {
  edge?: InputMaybe<HasWaterIntakePropertiesSort>;
  node?: InputMaybe<WaterIntakeSort>;
};

export type UserHasWaterIntakeWaterIntakesConnectionWhere = {
  AND?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesConnectionWhere>>;
  NOT?: InputMaybe<UserHasWaterIntakeWaterIntakesConnectionWhere>;
  OR?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesConnectionWhere>>;
  edge?: InputMaybe<HasWaterIntakePropertiesWhere>;
  node?: InputMaybe<WaterIntakeWhere>;
};

export type UserHasWaterIntakeWaterIntakesCreateFieldInput = {
  edge: HasWaterIntakePropertiesCreateInput;
  node: WaterIntakeCreateInput;
};

export type UserHasWaterIntakeWaterIntakesDeleteFieldInput = {
  delete?: InputMaybe<WaterIntakeDeleteInput>;
  where?: InputMaybe<UserHasWaterIntakeWaterIntakesConnectionWhere>;
};

export type UserHasWaterIntakeWaterIntakesDisconnectFieldInput = {
  disconnect?: InputMaybe<WaterIntakeDisconnectInput>;
  where?: InputMaybe<UserHasWaterIntakeWaterIntakesConnectionWhere>;
};

export type UserHasWaterIntakeWaterIntakesFieldInput = {
  connect?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesCreateFieldInput>>;
};

export type UserHasWaterIntakeWaterIntakesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasWaterIntakeWaterIntakesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_oz_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserHasWaterIntakeWaterIntakesRelationship = {
  __typename?: "UserHasWaterIntakeWaterIntakesRelationship";
  cursor: Scalars["String"]["output"];
  node: WaterIntake;
  properties: HasWaterIntakeProperties;
};

export type UserHasWaterIntakeWaterIntakesUpdateConnectionInput = {
  edge?: InputMaybe<HasWaterIntakePropertiesUpdateInput>;
  node?: InputMaybe<WaterIntakeUpdateInput>;
};

export type UserHasWaterIntakeWaterIntakesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesDisconnectFieldInput>>;
  update?: InputMaybe<UserHasWaterIntakeWaterIntakesUpdateConnectionInput>;
  where?: InputMaybe<UserHasWaterIntakeWaterIntakesConnectionWhere>;
};

export type UserHasWhitelistedEmailsAggregateInput = {
  AND?: InputMaybe<Array<UserHasWhitelistedEmailsAggregateInput>>;
  NOT?: InputMaybe<UserHasWhitelistedEmailsAggregateInput>;
  OR?: InputMaybe<Array<UserHasWhitelistedEmailsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserHasWhitelistedEmailsNodeAggregationWhereInput>;
};

export type UserHasWhitelistedEmailsConnectFieldInput = {
  connect?: InputMaybe<Array<WhitelistedEmailConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<WhitelistedEmailConnectWhere>;
};

export type UserHasWhitelistedEmailsConnection = {
  __typename?: "UserHasWhitelistedEmailsConnection";
  edges: Array<UserHasWhitelistedEmailsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserHasWhitelistedEmailsConnectionSort = {
  node?: InputMaybe<WhitelistedEmailSort>;
};

export type UserHasWhitelistedEmailsConnectionWhere = {
  AND?: InputMaybe<Array<UserHasWhitelistedEmailsConnectionWhere>>;
  NOT?: InputMaybe<UserHasWhitelistedEmailsConnectionWhere>;
  OR?: InputMaybe<Array<UserHasWhitelistedEmailsConnectionWhere>>;
  node?: InputMaybe<WhitelistedEmailWhere>;
};

export type UserHasWhitelistedEmailsCreateFieldInput = {
  node: WhitelistedEmailCreateInput;
};

export type UserHasWhitelistedEmailsDeleteFieldInput = {
  delete?: InputMaybe<WhitelistedEmailDeleteInput>;
  where?: InputMaybe<UserHasWhitelistedEmailsConnectionWhere>;
};

export type UserHasWhitelistedEmailsDisconnectFieldInput = {
  disconnect?: InputMaybe<WhitelistedEmailDisconnectInput>;
  where?: InputMaybe<UserHasWhitelistedEmailsConnectionWhere>;
};

export type UserHasWhitelistedEmailsFieldInput = {
  connect?: InputMaybe<Array<UserHasWhitelistedEmailsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasWhitelistedEmailsCreateFieldInput>>;
};

export type UserHasWhitelistedEmailsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserHasWhitelistedEmailsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserHasWhitelistedEmailsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserHasWhitelistedEmailsNodeAggregationWhereInput>>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserHasWhitelistedEmailsRelationship = {
  __typename?: "UserHasWhitelistedEmailsRelationship";
  cursor: Scalars["String"]["output"];
  node: WhitelistedEmail;
};

export type UserHasWhitelistedEmailsUpdateConnectionInput = {
  node?: InputMaybe<WhitelistedEmailUpdateInput>;
};

export type UserHasWhitelistedEmailsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserHasWhitelistedEmailsConnectFieldInput>>;
  create?: InputMaybe<Array<UserHasWhitelistedEmailsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserHasWhitelistedEmailsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserHasWhitelistedEmailsDisconnectFieldInput>>;
  update?: InputMaybe<UserHasWhitelistedEmailsUpdateConnectionInput>;
  where?: InputMaybe<UserHasWhitelistedEmailsConnectionWhere>;
};

export type UserInput = {
  id: Scalars["ID"]["input"];
};

export type UserJourneyHasJourneyJourneysAggregationSelection = {
  __typename?: "UserJourneyHasJourneyJourneysAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserJourneyHasJourneyJourneysNodeAggregateSelection>;
};

export type UserJourneyHasJourneyJourneysNodeAggregateSelection = {
  __typename?: "UserJourneyHasJourneyJourneysNodeAggregateSelection";
  _empty: StringAggregateSelection;
  completedTasks: IntAggregateSelection;
  description: StringAggregateSelection;
  dueDate: StringAggregateSelection;
  id: IdAggregateSelection;
  name: StringAggregateSelection;
  totalTasks: IntAggregateSelection;
  url: StringAggregateSelection;
};

export type UserMasteriesConnection = {
  __typename?: "UserMasteriesConnection";
  edges: Array<UserMasteryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserMastery = {
  __typename?: "UserMastery";
  child: ChildInfo;
  lastModified: Scalars["String"]["output"];
  totalMastery?: Maybe<Scalars["Float"]["output"]>;
};

export type UserMasteryAggregateSelection = {
  __typename?: "UserMasteryAggregateSelection";
  count: Scalars["Int"]["output"];
  lastModified: StringAggregateSelection;
  totalMastery: FloatAggregateSelection;
};

export type UserMasteryCreateInput = {
  lastModified: Scalars["String"]["input"];
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserMasteryEdge = {
  __typename?: "UserMasteryEdge";
  cursor: Scalars["String"]["output"];
  node: UserMastery;
};

export type UserMasteryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UserMasterySort objects to sort UserMasteries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UserMasterySort>>;
};

/** Fields to sort UserMasteries by. The order in which sorts are applied is not guaranteed when specifying many fields in one UserMasterySort object. */
export type UserMasterySort = {
  lastModified?: InputMaybe<SortDirection>;
  totalMastery?: InputMaybe<SortDirection>;
};

export type UserMasteryUpdateInput = {
  lastModified?: InputMaybe<Scalars["String"]["input"]>;
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserMasteryWhere = {
  AND?: InputMaybe<Array<UserMasteryWhere>>;
  NOT?: InputMaybe<UserMasteryWhere>;
  OR?: InputMaybe<Array<UserMasteryWhere>>;
  lastModified?: InputMaybe<Scalars["String"]["input"]>;
  lastModified_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  lastModified_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lastModified_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lastModified_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalMastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserMonthlySummaryHasSummaryMonthlySummariesAggregationSelection = {
  __typename?: "UserMonthlySummaryHasSummaryMonthlySummariesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserMonthlySummaryHasSummaryMonthlySummariesNodeAggregateSelection>;
};

export type UserMonthlySummaryHasSummaryMonthlySummariesNodeAggregateSelection = {
  __typename?: "UserMonthlySummaryHasSummaryMonthlySummariesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  date: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type UserOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UserSort objects to sort Users by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UserSort>>;
};

export type UserPaymentHasPaymentPaymentsAggregationSelection = {
  __typename?: "UserPaymentHasPaymentPaymentsAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserPaymentHasPaymentPaymentsEdgeAggregateSelection>;
  node?: Maybe<UserPaymentHasPaymentPaymentsNodeAggregateSelection>;
};

export type UserPaymentHasPaymentPaymentsEdgeAggregateSelection = {
  __typename?: "UserPaymentHasPaymentPaymentsEdgeAggregateSelection";
  integrationId: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  trialEndDate: DateTimeAggregateSelection;
};

export type UserPaymentHasPaymentPaymentsNodeAggregateSelection = {
  __typename?: "UserPaymentHasPaymentPaymentsNodeAggregateSelection";
  domain: StringAggregateSelection;
};

export type UserPreferencesHasPreferencesPreferencesAggregationSelection = {
  __typename?: "UserPreferencesHasPreferencesPreferencesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserPreferencesHasPreferencesPreferencesNodeAggregateSelection>;
};

export type UserPreferencesHasPreferencesPreferencesNodeAggregateSelection = {
  __typename?: "UserPreferencesHasPreferencesPreferencesNodeAggregateSelection";
  contentType: StringAggregateSelection;
  language: StringAggregateSelection;
  learningDuration: IntAggregateSelection;
  learningStyle: StringAggregateSelection;
  notificationPreferences: StringAggregateSelection;
  timeOfDay: StringAggregateSelection;
};

export type UserProgress = {
  __typename?: "UserProgress";
  child: ChildInfo;
  lastModified: Scalars["String"]["output"];
  parent?: Maybe<ChildInfo>;
  totalMastery?: Maybe<Scalars["Float"]["output"]>;
};

export type UserProgressAggregateSelection = {
  __typename?: "UserProgressAggregateSelection";
  count: Scalars["Int"]["output"];
  lastModified: StringAggregateSelection;
  totalMastery: FloatAggregateSelection;
};

export type UserProgressCreateInput = {
  lastModified: Scalars["String"]["input"];
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserProgressEdge = {
  __typename?: "UserProgressEdge";
  cursor: Scalars["String"]["output"];
  node: UserProgress;
};

export type UserProgressOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more UserProgressSort objects to sort UserProgresses by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<UserProgressSort>>;
};

/** Fields to sort UserProgresses by. The order in which sorts are applied is not guaranteed when specifying many fields in one UserProgressSort object. */
export type UserProgressSort = {
  lastModified?: InputMaybe<SortDirection>;
  totalMastery?: InputMaybe<SortDirection>;
};

export type UserProgressUpdateInput = {
  lastModified?: InputMaybe<Scalars["String"]["input"]>;
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserProgressWhere = {
  AND?: InputMaybe<Array<UserProgressWhere>>;
  NOT?: InputMaybe<UserProgressWhere>;
  OR?: InputMaybe<Array<UserProgressWhere>>;
  lastModified?: InputMaybe<Scalars["String"]["input"]>;
  lastModified_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  lastModified_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  lastModified_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  lastModified_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  totalMastery?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  totalMastery_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalMastery_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserProgressesConnection = {
  __typename?: "UserProgressesConnection";
  edges: Array<UserProgressEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserQuarterlySummaryHasSummaryQuarterlySummariesAggregationSelection = {
  __typename?: "UserQuarterlySummaryHasSummaryQuarterlySummariesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserQuarterlySummaryHasSummaryQuarterlySummariesNodeAggregateSelection>;
};

export type UserQuarterlySummaryHasSummaryQuarterlySummariesNodeAggregateSelection = {
  __typename?: "UserQuarterlySummaryHasSummaryQuarterlySummariesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  date: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type UserRelationInput = {
  achievements?: InputMaybe<Array<UserAchievementsCreateFieldInput>>;
  attributes?: InputMaybe<Array<UserAttributesCreateFieldInput>>;
  hasChatChats?: InputMaybe<Array<UserHasChatChatsCreateFieldInput>>;
  hasFoodItemFoodItems?: InputMaybe<Array<UserHasFoodItemFoodItemsCreateFieldInput>>;
  hasJourneyJourneys?: InputMaybe<Array<UserHasJourneyJourneysCreateFieldInput>>;
  hasPaymentPayments?: InputMaybe<Array<UserHasPaymentPaymentsCreateFieldInput>>;
  hasPreferencesPreferences?: InputMaybe<Array<UserHasPreferencesPreferencesCreateFieldInput>>;
  hasRoleRoles?: InputMaybe<Array<UserHasRoleRolesCreateFieldInput>>;
  hasSessionDeviceSessions?: InputMaybe<Array<UserHasSessionDeviceSessionsCreateFieldInput>>;
  hasSuggestionSuggestions?: InputMaybe<Array<UserHasSuggestionSuggestionsCreateFieldInput>>;
  hasSummaryDailySummaries?: InputMaybe<Array<UserHasSummaryDailySummariesCreateFieldInput>>;
  hasSummaryMonthlySummaries?: InputMaybe<Array<UserHasSummaryMonthlySummariesCreateFieldInput>>;
  hasSummaryQuarterlySummaries?: InputMaybe<Array<UserHasSummaryQuarterlySummariesCreateFieldInput>>;
  hasSummaryWeeklySummaries?: InputMaybe<Array<UserHasSummaryWeeklySummariesCreateFieldInput>>;
  hasSummaryYearlySummaries?: InputMaybe<Array<UserHasSummaryYearlySummariesCreateFieldInput>>;
  hasWaterIntakeWaterIntakes?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesCreateFieldInput>>;
  hasWhitelistedEmails?: InputMaybe<Array<UserHasWhitelistedEmailsCreateFieldInput>>;
  tenants?: InputMaybe<Array<UserTenantsCreateFieldInput>>;
  todos?: InputMaybe<Array<UserTodosCreateFieldInput>>;
  undefinedFoodItems?: InputMaybe<Array<UserUndefinedFoodItemsCreateFieldInput>>;
  undefinedWaterIntakes?: InputMaybe<Array<UserUndefinedWaterIntakesCreateFieldInput>>;
};

export type UserRoleHasRoleRolesAggregationSelection = {
  __typename?: "UserRoleHasRoleRolesAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserRoleHasRoleRolesEdgeAggregateSelection>;
  node?: Maybe<UserRoleHasRoleRolesNodeAggregateSelection>;
};

export type UserRoleHasRoleRolesEdgeAggregateSelection = {
  __typename?: "UserRoleHasRoleRolesEdgeAggregateSelection";
  tenantId: IdAggregateSelection;
};

export type UserRoleHasRoleRolesNodeAggregateSelection = {
  __typename?: "UserRoleHasRoleRolesNodeAggregateSelection";
  name: StringAggregateSelection;
};

/**
 * The edge properties for the following fields:
 * * User.hasRoleRoles
 * * Role.usersHasRole
 */
export type UserRoleProperties = {
  __typename?: "UserRoleProperties";
  tenantId: Scalars["ID"]["output"];
};

export type UserRolePropertiesAggregationWhereInput = {
  AND?: InputMaybe<Array<UserRolePropertiesAggregationWhereInput>>;
  NOT?: InputMaybe<UserRolePropertiesAggregationWhereInput>;
  OR?: InputMaybe<Array<UserRolePropertiesAggregationWhereInput>>;
};

export type UserRolePropertiesCreateInput = {
  tenantId: Scalars["ID"]["input"];
};

export type UserRolePropertiesSort = {
  tenantId?: InputMaybe<SortDirection>;
};

export type UserRolePropertiesUpdateInput = {
  tenantId?: InputMaybe<Scalars["ID"]["input"]>;
};

export type UserRolePropertiesWhere = {
  AND?: InputMaybe<Array<UserRolePropertiesWhere>>;
  NOT?: InputMaybe<UserRolePropertiesWhere>;
  OR?: InputMaybe<Array<UserRolePropertiesWhere>>;
  tenantId?: InputMaybe<Scalars["ID"]["input"]>;
  tenantId_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  tenantId_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  tenantId_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  tenantId_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
};

/** Fields to sort Users by. The order in which sorts are applied is not guaranteed when specifying many fields in one UserSort object. */
export type UserSort = {
  _empty?: InputMaybe<SortDirection>;
  audioOn?: InputMaybe<SortDirection>;
  birthday?: InputMaybe<SortDirection>;
  countryCode?: InputMaybe<SortDirection>;
  created?: InputMaybe<SortDirection>;
  currentWeightLbs?: InputMaybe<SortDirection>;
  dailyEmailOptIn?: InputMaybe<SortDirection>;
  email?: InputMaybe<SortDirection>;
  fullPhoneNumber?: InputMaybe<SortDirection>;
  gender?: InputMaybe<SortDirection>;
  goalWeightLbs?: InputMaybe<SortDirection>;
  googleAuthTokens?: InputMaybe<SortDirection>;
  googleCalendarAuthToken?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  inviteCount?: InputMaybe<SortDirection>;
  isCareerLoading?: InputMaybe<SortDirection>;
  isCourseLoading?: InputMaybe<SortDirection>;
  isEmailVerified?: InputMaybe<SortDirection>;
  isOnboarded?: InputMaybe<SortDirection>;
  isPro?: InputMaybe<SortDirection>;
  modalOnboarding?: InputMaybe<SortDirection>;
  moodlePassword?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  otpPreference?: InputMaybe<SortDirection>;
  password?: InputMaybe<SortDirection>;
  phoneNumber?: InputMaybe<SortDirection>;
  stripeCustomerId?: InputMaybe<SortDirection>;
  stripeSubscriptionId?: InputMaybe<SortDirection>;
  trialEndDate?: InputMaybe<SortDirection>;
  username?: InputMaybe<SortDirection>;
};

export type UserSuggestionHasSuggestionSuggestionsAggregationSelection = {
  __typename?: "UserSuggestionHasSuggestionSuggestionsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserSuggestionHasSuggestionSuggestionsNodeAggregateSelection>;
};

export type UserSuggestionHasSuggestionSuggestionsNodeAggregateSelection = {
  __typename?: "UserSuggestionHasSuggestionSuggestionsNodeAggregateSelection";
  assignmentId: StringAggregateSelection;
  classId: StringAggregateSelection;
  content: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  integrationId: StringAggregateSelection;
  journeyId: StringAggregateSelection;
  trackName: StringAggregateSelection;
  type: StringAggregateSelection;
};

export type UserTenantTenantsAggregationSelection = {
  __typename?: "UserTenantTenantsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserTenantTenantsNodeAggregateSelection>;
};

export type UserTenantTenantsNodeAggregateSelection = {
  __typename?: "UserTenantTenantsNodeAggregateSelection";
  bugReportEmails: StringAggregateSelection;
  companionName: StringAggregateSelection;
  connectURL: StringAggregateSelection;
  emulationStatement: StringAggregateSelection;
  externalSendGridAppInviteTemplateId: StringAggregateSelection;
  externalSendGridDailyEmailTemplateId: StringAggregateSelection;
  externalSendGridFromEmail: StringAggregateSelection;
  externalSendGridFromName: StringAggregateSelection;
  externalSendGridTwilioServiceSID: StringAggregateSelection;
  externalStripeStripeSubscriptionId: StringAggregateSelection;
  externalStripeTrialDays: IntAggregateSelection;
  gameMenuBg: StringAggregateSelection;
  gameMenuCardBg: StringAggregateSelection;
  gameMenuCardButton: StringAggregateSelection;
  gameMenuCardButtonText: StringAggregateSelection;
  gameMenuCardRefresh: StringAggregateSelection;
  gameMenuProgress: StringAggregateSelection;
  gameMenuRefresh: StringAggregateSelection;
  gameMenuRefreshText: StringAggregateSelection;
  gameMenuTrack: StringAggregateSelection;
  generalPrompt: StringAggregateSelection;
  id: IdAggregateSelection;
  key: StringAggregateSelection;
  learningEmulationStatement: StringAggregateSelection;
  learningFieldColor: StringAggregateSelection;
  learningSubFieldColor: StringAggregateSelection;
  logo: StringAggregateSelection;
  metaTagsAppleTouchIcon: StringAggregateSelection;
  metaTagsDescription: StringAggregateSelection;
  metaTagsThemeColor: StringAggregateSelection;
  metaTagsTitle: StringAggregateSelection;
  metaTagsTwitterCard: StringAggregateSelection;
  metaTagsTwitterDescription: StringAggregateSelection;
  metaTagsTwitterImage: StringAggregateSelection;
  metaTagsTwitterSite: StringAggregateSelection;
  metaTagsTwitterTitle: StringAggregateSelection;
  newsSearchTerms: StringAggregateSelection;
  newsSectionTitle: StringAggregateSelection;
  onboardingPrompt: StringAggregateSelection;
  primaryColor: StringAggregateSelection;
  tenantDomain: StringAggregateSelection;
  termsOfServiceLink: StringAggregateSelection;
  upgradeModalBody: StringAggregateSelection;
};

export type UserTenantsAggregateInput = {
  AND?: InputMaybe<Array<UserTenantsAggregateInput>>;
  NOT?: InputMaybe<UserTenantsAggregateInput>;
  OR?: InputMaybe<Array<UserTenantsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<UserTenantsNodeAggregationWhereInput>;
};

export type UserTenantsConnectFieldInput = {
  connect?: InputMaybe<Array<TenantConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<TenantConnectWhere>;
};

export type UserTenantsConnection = {
  __typename?: "UserTenantsConnection";
  edges: Array<UserTenantsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserTenantsConnectionSort = {
  node?: InputMaybe<TenantSort>;
};

export type UserTenantsConnectionWhere = {
  AND?: InputMaybe<Array<UserTenantsConnectionWhere>>;
  NOT?: InputMaybe<UserTenantsConnectionWhere>;
  OR?: InputMaybe<Array<UserTenantsConnectionWhere>>;
  node?: InputMaybe<TenantWhere>;
};

export type UserTenantsCreateFieldInput = {
  node: TenantCreateInput;
};

export type UserTenantsDeleteFieldInput = {
  delete?: InputMaybe<TenantDeleteInput>;
  where?: InputMaybe<UserTenantsConnectionWhere>;
};

export type UserTenantsDisconnectFieldInput = {
  disconnect?: InputMaybe<TenantDisconnectInput>;
  where?: InputMaybe<UserTenantsConnectionWhere>;
};

export type UserTenantsFieldInput = {
  connect?: InputMaybe<Array<UserTenantsConnectFieldInput>>;
  create?: InputMaybe<Array<UserTenantsCreateFieldInput>>;
};

export type UserTenantsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserTenantsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserTenantsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserTenantsNodeAggregationWhereInput>>;
  bugReportEmails_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  bugReportEmails_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  bugReportEmails_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  bugReportEmails_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  bugReportEmails_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  bugReportEmails_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  bugReportEmails_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  companionName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  companionName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  companionName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  companionName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  companionName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  companionName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  connectURL_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  connectURL_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  connectURL_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  connectURL_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  connectURL_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  connectURL_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  emulationStatement_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  emulationStatement_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  emulationStatement_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  emulationStatement_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  emulationStatement_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  emulationStatement_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridAppInviteTemplateId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridAppInviteTemplateId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridAppInviteTemplateId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridAppInviteTemplateId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridAppInviteTemplateId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridAppInviteTemplateId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridDailyEmailTemplateId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridDailyEmailTemplateId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridDailyEmailTemplateId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridDailyEmailTemplateId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridDailyEmailTemplateId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridDailyEmailTemplateId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromEmail_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromEmail_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromEmail_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromEmail_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromEmail_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromEmail_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromName_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromName_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromName_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromName_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridFromName_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridFromName_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridTwilioServiceSID_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridTwilioServiceSID_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridTwilioServiceSID_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridTwilioServiceSID_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalSendGridTwilioServiceSID_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalSendGridTwilioServiceSID_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeStripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  externalStripeStripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  externalStripeStripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalStripeStripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  externalStripeStripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalStripeStripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeStripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeStripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeStripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeStripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeStripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeStripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeStripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeStripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeStripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  externalStripeTrialDays_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  externalStripeTrialDays_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalStripeTrialDays_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  externalStripeTrialDays_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  externalStripeTrialDays_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  externalStripeTrialDays_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuBg_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuBg_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuBg_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuBg_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuBg_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuBg_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardBg_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardBg_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardBg_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardBg_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardBg_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardBg_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButtonText_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButtonText_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButtonText_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButtonText_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButtonText_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButtonText_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButton_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButton_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButton_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButton_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardButton_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardButton_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardRefresh_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardRefresh_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardRefresh_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardRefresh_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuCardRefresh_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuCardRefresh_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuProgress_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuProgress_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuProgress_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuProgress_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuProgress_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuProgress_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefreshText_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefreshText_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefreshText_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefreshText_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefreshText_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefreshText_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefresh_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefresh_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefresh_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefresh_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuRefresh_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuRefresh_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuTrack_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuTrack_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuTrack_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuTrack_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gameMenuTrack_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gameMenuTrack_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  generalPrompt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  generalPrompt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  generalPrompt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  generalPrompt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  generalPrompt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  generalPrompt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  key_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  key_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  key_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  key_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  key_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  key_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  key_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  key_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  key_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  key_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  key_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  key_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  key_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  key_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  key_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningEmulationStatement_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningEmulationStatement_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningEmulationStatement_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningEmulationStatement_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningEmulationStatement_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningEmulationStatement_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningFieldColor_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningFieldColor_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningFieldColor_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningFieldColor_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningFieldColor_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningFieldColor_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  learningSubFieldColor_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  learningSubFieldColor_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningSubFieldColor_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  learningSubFieldColor_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  learningSubFieldColor_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  learningSubFieldColor_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  logo_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  logo_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  logo_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  logo_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  logo_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  logo_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  logo_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  logo_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  logo_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  logo_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  logo_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  logo_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  logo_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  logo_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  logo_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsAppleTouchIcon_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsAppleTouchIcon_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsAppleTouchIcon_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsAppleTouchIcon_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsAppleTouchIcon_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsAppleTouchIcon_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsThemeColor_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsThemeColor_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsThemeColor_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsThemeColor_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsThemeColor_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsThemeColor_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTitle_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTitle_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTitle_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTitle_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTitle_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTitle_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterCard_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterCard_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterCard_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterCard_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterCard_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterCard_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterDescription_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterDescription_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterDescription_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterDescription_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterDescription_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterDescription_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterImage_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterImage_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterImage_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterImage_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterImage_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterImage_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterSite_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterSite_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterSite_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterSite_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterSite_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterSite_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterTitle_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterTitle_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterTitle_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterTitle_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  metaTagsTwitterTitle_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  metaTagsTwitterTitle_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  newsSearchTerms_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  newsSearchTerms_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  newsSearchTerms_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  newsSearchTerms_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  newsSearchTerms_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSearchTerms_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  newsSectionTitle_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  newsSectionTitle_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  newsSectionTitle_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  newsSectionTitle_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  newsSectionTitle_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  newsSectionTitle_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  onboardingPrompt_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  onboardingPrompt_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  onboardingPrompt_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  onboardingPrompt_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  onboardingPrompt_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  onboardingPrompt_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  primaryColor_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  primaryColor_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  primaryColor_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  primaryColor_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  primaryColor_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  primaryColor_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  tenantDomain_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  tenantDomain_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  tenantDomain_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  tenantDomain_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  tenantDomain_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  tenantDomain_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  termsOfServiceLink_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  termsOfServiceLink_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  termsOfServiceLink_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  termsOfServiceLink_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  termsOfServiceLink_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  termsOfServiceLink_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  upgradeModalBody_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  upgradeModalBody_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  upgradeModalBody_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  upgradeModalBody_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  upgradeModalBody_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  upgradeModalBody_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserTenantsRelationship = {
  __typename?: "UserTenantsRelationship";
  cursor: Scalars["String"]["output"];
  node: Tenant;
};

export type UserTenantsUpdateConnectionInput = {
  node?: InputMaybe<TenantUpdateInput>;
};

export type UserTenantsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserTenantsConnectFieldInput>>;
  create?: InputMaybe<Array<UserTenantsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserTenantsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserTenantsDisconnectFieldInput>>;
  update?: InputMaybe<UserTenantsUpdateConnectionInput>;
  where?: InputMaybe<UserTenantsConnectionWhere>;
};

export type UserTodoTodosAggregationSelection = {
  __typename?: "UserTodoTodosAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserTodoTodosEdgeAggregateSelection>;
  node?: Maybe<UserTodoTodosNodeAggregateSelection>;
};

export type UserTodoTodosEdgeAggregateSelection = {
  __typename?: "UserTodoTodosEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  quality: BigIntAggregateSelection;
  strength: BigIntAggregateSelection;
};

export type UserTodoTodosNodeAggregateSelection = {
  __typename?: "UserTodoTodosNodeAggregateSelection";
  description: StringAggregateSelection;
  id: IdAggregateSelection;
  journeyId: IdAggregateSelection;
  name: StringAggregateSelection;
  pointsReceived: IntAggregateSelection;
  priority: StringAggregateSelection;
  recurringDayOfMonth: IntAggregateSelection;
  recurringDayOfWeek: StringAggregateSelection;
  recurringFrequency: StringAggregateSelection;
  recurringTime: StringAggregateSelection;
  timeOfDay: StringAggregateSelection;
  timezone: StringAggregateSelection;
};

export type UserTodosAggregateInput = {
  AND?: InputMaybe<Array<UserTodosAggregateInput>>;
  NOT?: InputMaybe<UserTodosAggregateInput>;
  OR?: InputMaybe<Array<UserTodosAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasTodoPropertiesAggregationWhereInput>;
  node?: InputMaybe<UserTodosNodeAggregationWhereInput>;
};

export type UserTodosConnectFieldInput = {
  connect?: InputMaybe<Array<TodoConnectInput>>;
  edge?: InputMaybe<HasTodoPropertiesCreateInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<TodoConnectWhere>;
};

export type UserTodosConnection = {
  __typename?: "UserTodosConnection";
  edges: Array<UserTodosRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserTodosConnectionSort = {
  edge?: InputMaybe<HasTodoPropertiesSort>;
  node?: InputMaybe<TodoSort>;
};

export type UserTodosConnectionWhere = {
  AND?: InputMaybe<Array<UserTodosConnectionWhere>>;
  NOT?: InputMaybe<UserTodosConnectionWhere>;
  OR?: InputMaybe<Array<UserTodosConnectionWhere>>;
  edge?: InputMaybe<HasTodoPropertiesWhere>;
  node?: InputMaybe<TodoWhere>;
};

export type UserTodosCreateFieldInput = {
  edge?: InputMaybe<HasTodoPropertiesCreateInput>;
  node: TodoCreateInput;
};

export type UserTodosDeleteFieldInput = {
  delete?: InputMaybe<TodoDeleteInput>;
  where?: InputMaybe<UserTodosConnectionWhere>;
};

export type UserTodosDisconnectFieldInput = {
  disconnect?: InputMaybe<TodoDisconnectInput>;
  where?: InputMaybe<UserTodosConnectionWhere>;
};

export type UserTodosFieldInput = {
  connect?: InputMaybe<Array<UserTodosConnectFieldInput>>;
  create?: InputMaybe<Array<UserTodosCreateFieldInput>>;
};

export type UserTodosNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserTodosNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserTodosNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserTodosNodeAggregationWhereInput>>;
  description_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  description_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  description_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  description_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  pointsReceived_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  pointsReceived_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  priority_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  priority_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  priority_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  priority_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  priority_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  priority_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  priority_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  priority_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  priority_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  priority_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  priority_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfMonth_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfMonth_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringDayOfWeek_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringFrequency_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  recurringTime_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timeOfDay_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timeOfDay_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  timezone_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  timezone_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type UserTodosRelationship = {
  __typename?: "UserTodosRelationship";
  cursor: Scalars["String"]["output"];
  node: Todo;
  properties: HasTodoProperties;
};

export type UserTodosUpdateConnectionInput = {
  edge?: InputMaybe<HasTodoPropertiesUpdateInput>;
  node?: InputMaybe<TodoUpdateInput>;
};

export type UserTodosUpdateFieldInput = {
  connect?: InputMaybe<Array<UserTodosConnectFieldInput>>;
  create?: InputMaybe<Array<UserTodosCreateFieldInput>>;
  delete?: InputMaybe<Array<UserTodosDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserTodosDisconnectFieldInput>>;
  update?: InputMaybe<UserTodosUpdateConnectionInput>;
  where?: InputMaybe<UserTodosConnectionWhere>;
};

export type UserUndefinedFoodItemsAggregateInput = {
  AND?: InputMaybe<Array<UserUndefinedFoodItemsAggregateInput>>;
  NOT?: InputMaybe<UserUndefinedFoodItemsAggregateInput>;
  OR?: InputMaybe<Array<UserUndefinedFoodItemsAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<UndefinedPropertiesAggregationWhereInput>;
  node?: InputMaybe<UserUndefinedFoodItemsNodeAggregationWhereInput>;
};

export type UserUndefinedFoodItemsConnectFieldInput = {
  connect?: InputMaybe<Array<FoodItemConnectInput>>;
  edge: UndefinedPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<FoodItemConnectWhere>;
};

export type UserUndefinedFoodItemsConnection = {
  __typename?: "UserUndefinedFoodItemsConnection";
  edges: Array<UserUndefinedFoodItemsRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserUndefinedFoodItemsConnectionSort = {
  edge?: InputMaybe<UndefinedPropertiesSort>;
  node?: InputMaybe<FoodItemSort>;
};

export type UserUndefinedFoodItemsConnectionWhere = {
  AND?: InputMaybe<Array<UserUndefinedFoodItemsConnectionWhere>>;
  NOT?: InputMaybe<UserUndefinedFoodItemsConnectionWhere>;
  OR?: InputMaybe<Array<UserUndefinedFoodItemsConnectionWhere>>;
  edge?: InputMaybe<UndefinedPropertiesWhere>;
  node?: InputMaybe<FoodItemWhere>;
};

export type UserUndefinedFoodItemsCreateFieldInput = {
  edge: UndefinedPropertiesCreateInput;
  node: FoodItemCreateInput;
};

export type UserUndefinedFoodItemsDeleteFieldInput = {
  delete?: InputMaybe<FoodItemDeleteInput>;
  where?: InputMaybe<UserUndefinedFoodItemsConnectionWhere>;
};

export type UserUndefinedFoodItemsDisconnectFieldInput = {
  disconnect?: InputMaybe<FoodItemDisconnectInput>;
  where?: InputMaybe<UserUndefinedFoodItemsConnectionWhere>;
};

export type UserUndefinedFoodItemsFieldInput = {
  connect?: InputMaybe<Array<UserUndefinedFoodItemsConnectFieldInput>>;
  create?: InputMaybe<Array<UserUndefinedFoodItemsCreateFieldInput>>;
};

export type UserUndefinedFoodItemsNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserUndefinedFoodItemsNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserUndefinedFoodItemsNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserUndefinedFoodItemsNodeAggregationWhereInput>>;
  calories_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calories_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  calories_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  date_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  date_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  date_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  serving_size_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  serving_size_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  sugar_grams_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  sugar_grams_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserUndefinedFoodItemsRelationship = {
  __typename?: "UserUndefinedFoodItemsRelationship";
  cursor: Scalars["String"]["output"];
  node: FoodItem;
  properties: UndefinedProperties;
};

export type UserUndefinedFoodItemsUpdateConnectionInput = {
  edge?: InputMaybe<UndefinedPropertiesUpdateInput>;
  node?: InputMaybe<FoodItemUpdateInput>;
};

export type UserUndefinedFoodItemsUpdateFieldInput = {
  connect?: InputMaybe<Array<UserUndefinedFoodItemsConnectFieldInput>>;
  create?: InputMaybe<Array<UserUndefinedFoodItemsCreateFieldInput>>;
  delete?: InputMaybe<Array<UserUndefinedFoodItemsDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserUndefinedFoodItemsDisconnectFieldInput>>;
  update?: InputMaybe<UserUndefinedFoodItemsUpdateConnectionInput>;
  where?: InputMaybe<UserUndefinedFoodItemsConnectionWhere>;
};

export type UserUndefinedWaterIntakesAggregateInput = {
  AND?: InputMaybe<Array<UserUndefinedWaterIntakesAggregateInput>>;
  NOT?: InputMaybe<UserUndefinedWaterIntakesAggregateInput>;
  OR?: InputMaybe<Array<UserUndefinedWaterIntakesAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<UndefinedPropertiesAggregationWhereInput>;
  node?: InputMaybe<UserUndefinedWaterIntakesNodeAggregationWhereInput>;
};

export type UserUndefinedWaterIntakesConnectFieldInput = {
  connect?: InputMaybe<Array<WaterIntakeConnectInput>>;
  edge: UndefinedPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<WaterIntakeConnectWhere>;
};

export type UserUndefinedWaterIntakesConnection = {
  __typename?: "UserUndefinedWaterIntakesConnection";
  edges: Array<UserUndefinedWaterIntakesRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type UserUndefinedWaterIntakesConnectionSort = {
  edge?: InputMaybe<UndefinedPropertiesSort>;
  node?: InputMaybe<WaterIntakeSort>;
};

export type UserUndefinedWaterIntakesConnectionWhere = {
  AND?: InputMaybe<Array<UserUndefinedWaterIntakesConnectionWhere>>;
  NOT?: InputMaybe<UserUndefinedWaterIntakesConnectionWhere>;
  OR?: InputMaybe<Array<UserUndefinedWaterIntakesConnectionWhere>>;
  edge?: InputMaybe<UndefinedPropertiesWhere>;
  node?: InputMaybe<WaterIntakeWhere>;
};

export type UserUndefinedWaterIntakesCreateFieldInput = {
  edge: UndefinedPropertiesCreateInput;
  node: WaterIntakeCreateInput;
};

export type UserUndefinedWaterIntakesDeleteFieldInput = {
  delete?: InputMaybe<WaterIntakeDeleteInput>;
  where?: InputMaybe<UserUndefinedWaterIntakesConnectionWhere>;
};

export type UserUndefinedWaterIntakesDisconnectFieldInput = {
  disconnect?: InputMaybe<WaterIntakeDisconnectInput>;
  where?: InputMaybe<UserUndefinedWaterIntakesConnectionWhere>;
};

export type UserUndefinedWaterIntakesFieldInput = {
  connect?: InputMaybe<Array<UserUndefinedWaterIntakesConnectFieldInput>>;
  create?: InputMaybe<Array<UserUndefinedWaterIntakesCreateFieldInput>>;
};

export type UserUndefinedWaterIntakesNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<UserUndefinedWaterIntakesNodeAggregationWhereInput>>;
  NOT?: InputMaybe<UserUndefinedWaterIntakesNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<UserUndefinedWaterIntakesNodeAggregationWhereInput>>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  id_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  id_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  id_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  last_modified_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  water_intake_oz_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type UserUndefinedWaterIntakesRelationship = {
  __typename?: "UserUndefinedWaterIntakesRelationship";
  cursor: Scalars["String"]["output"];
  node: WaterIntake;
  properties: UndefinedProperties;
};

export type UserUndefinedWaterIntakesUpdateConnectionInput = {
  edge?: InputMaybe<UndefinedPropertiesUpdateInput>;
  node?: InputMaybe<WaterIntakeUpdateInput>;
};

export type UserUndefinedWaterIntakesUpdateFieldInput = {
  connect?: InputMaybe<Array<UserUndefinedWaterIntakesConnectFieldInput>>;
  create?: InputMaybe<Array<UserUndefinedWaterIntakesCreateFieldInput>>;
  delete?: InputMaybe<Array<UserUndefinedWaterIntakesDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<UserUndefinedWaterIntakesDisconnectFieldInput>>;
  update?: InputMaybe<UserUndefinedWaterIntakesUpdateConnectionInput>;
  where?: InputMaybe<UserUndefinedWaterIntakesConnectionWhere>;
};

export type UserUpdateInput = {
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  achievements?: InputMaybe<Array<UserAchievementsUpdateFieldInput>>;
  attributes?: InputMaybe<Array<UserAttributesUpdateFieldInput>>;
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
  birthday?: InputMaybe<Scalars["Date"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  dailyEmailOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  enrolledTracks?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  enrolledTracks_POP?: InputMaybe<Scalars["Int"]["input"]>;
  enrolledTracks_PUSH?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fullPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  goalWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens?: InputMaybe<Scalars["String"]["input"]>;
  googleCalendarAuthToken?: InputMaybe<Scalars["String"]["input"]>;
  hasChatChats?: InputMaybe<Array<UserHasChatChatsUpdateFieldInput>>;
  hasFoodItemFoodItems?: InputMaybe<Array<UserHasFoodItemFoodItemsUpdateFieldInput>>;
  hasJourneyJourneys?: InputMaybe<Array<UserHasJourneyJourneysUpdateFieldInput>>;
  hasPaymentPayments?: InputMaybe<Array<UserHasPaymentPaymentsUpdateFieldInput>>;
  hasPreferencesPreferences?: InputMaybe<Array<UserHasPreferencesPreferencesUpdateFieldInput>>;
  hasRoleRoles?: InputMaybe<Array<UserHasRoleRolesUpdateFieldInput>>;
  hasSessionDeviceSessions?: InputMaybe<Array<UserHasSessionDeviceSessionsUpdateFieldInput>>;
  hasSuggestionSuggestions?: InputMaybe<Array<UserHasSuggestionSuggestionsUpdateFieldInput>>;
  hasSummaryDailySummaries?: InputMaybe<Array<UserHasSummaryDailySummariesUpdateFieldInput>>;
  hasSummaryMonthlySummaries?: InputMaybe<Array<UserHasSummaryMonthlySummariesUpdateFieldInput>>;
  hasSummaryQuarterlySummaries?: InputMaybe<Array<UserHasSummaryQuarterlySummariesUpdateFieldInput>>;
  hasSummaryWeeklySummaries?: InputMaybe<Array<UserHasSummaryWeeklySummariesUpdateFieldInput>>;
  hasSummaryYearlySummaries?: InputMaybe<Array<UserHasSummaryYearlySummariesUpdateFieldInput>>;
  hasWaterIntakeWaterIntakes?: InputMaybe<Array<UserHasWaterIntakeWaterIntakesUpdateFieldInput>>;
  hasWhitelistedEmails?: InputMaybe<Array<UserHasWhitelistedEmailsUpdateFieldInput>>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  inviteCount?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_DECREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_INCREMENT?: InputMaybe<Scalars["Int"]["input"]>;
  isCareerLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCourseLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEmailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
  moodlePassword?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  tenants?: InputMaybe<Array<UserTenantsUpdateFieldInput>>;
  todos?: InputMaybe<Array<UserTodosUpdateFieldInput>>;
  trialEndDate?: InputMaybe<Scalars["Date"]["input"]>;
  undefinedFoodItems?: InputMaybe<Array<UserUndefinedFoodItemsUpdateFieldInput>>;
  undefinedWaterIntakes?: InputMaybe<Array<UserUndefinedWaterIntakesUpdateFieldInput>>;
  username?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserWaterIntakeHasWaterIntakeWaterIntakesAggregationSelection = {
  __typename?: "UserWaterIntakeHasWaterIntakeWaterIntakesAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserWaterIntakeHasWaterIntakeWaterIntakesEdgeAggregateSelection>;
  node?: Maybe<UserWaterIntakeHasWaterIntakeWaterIntakesNodeAggregateSelection>;
};

export type UserWaterIntakeHasWaterIntakeWaterIntakesEdgeAggregateSelection = {
  __typename?: "UserWaterIntakeHasWaterIntakeWaterIntakesEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  quality: BigIntAggregateSelection;
  strength: BigIntAggregateSelection;
};

export type UserWaterIntakeHasWaterIntakeWaterIntakesNodeAggregateSelection = {
  __typename?: "UserWaterIntakeHasWaterIntakeWaterIntakesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  water_intake_oz: FloatAggregateSelection;
};

export type UserWaterIntakeUndefinedWaterIntakesAggregationSelection = {
  __typename?: "UserWaterIntakeUndefinedWaterIntakesAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<UserWaterIntakeUndefinedWaterIntakesEdgeAggregateSelection>;
  node?: Maybe<UserWaterIntakeUndefinedWaterIntakesNodeAggregateSelection>;
};

export type UserWaterIntakeUndefinedWaterIntakesEdgeAggregateSelection = {
  __typename?: "UserWaterIntakeUndefinedWaterIntakesEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  quality: BigIntAggregateSelection;
  strength: BigIntAggregateSelection;
};

export type UserWaterIntakeUndefinedWaterIntakesNodeAggregateSelection = {
  __typename?: "UserWaterIntakeUndefinedWaterIntakesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  water_intake_oz: FloatAggregateSelection;
};

export type UserWeeklySummaryHasSummaryWeeklySummariesAggregationSelection = {
  __typename?: "UserWeeklySummaryHasSummaryWeeklySummariesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserWeeklySummaryHasSummaryWeeklySummariesNodeAggregateSelection>;
};

export type UserWeeklySummaryHasSummaryWeeklySummariesNodeAggregateSelection = {
  __typename?: "UserWeeklySummaryHasSummaryWeeklySummariesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  date: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type UserWhere = {
  AND?: InputMaybe<Array<UserWhere>>;
  NOT?: InputMaybe<UserWhere>;
  OR?: InputMaybe<Array<UserWhere>>;
  _empty?: InputMaybe<Scalars["String"]["input"]>;
  _empty_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  _empty_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  _empty_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  _empty_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  achievementsAggregate?: InputMaybe<UserAchievementsAggregateInput>;
  /** Return Users where all of the related UserAchievementsConnections match this filter */
  achievementsConnection_ALL?: InputMaybe<UserAchievementsConnectionWhere>;
  /** Return Users where none of the related UserAchievementsConnections match this filter */
  achievementsConnection_NONE?: InputMaybe<UserAchievementsConnectionWhere>;
  /** Return Users where one of the related UserAchievementsConnections match this filter */
  achievementsConnection_SINGLE?: InputMaybe<UserAchievementsConnectionWhere>;
  /** Return Users where some of the related UserAchievementsConnections match this filter */
  achievementsConnection_SOME?: InputMaybe<UserAchievementsConnectionWhere>;
  /** Return Users where all of the related Achievements match this filter */
  achievements_ALL?: InputMaybe<AchievementsWhere>;
  /** Return Users where none of the related Achievements match this filter */
  achievements_NONE?: InputMaybe<AchievementsWhere>;
  /** Return Users where one of the related Achievements match this filter */
  achievements_SINGLE?: InputMaybe<AchievementsWhere>;
  /** Return Users where some of the related Achievements match this filter */
  achievements_SOME?: InputMaybe<AchievementsWhere>;
  attributesAggregate?: InputMaybe<UserAttributesAggregateInput>;
  /** Return Users where all of the related UserAttributesConnections match this filter */
  attributesConnection_ALL?: InputMaybe<UserAttributesConnectionWhere>;
  /** Return Users where none of the related UserAttributesConnections match this filter */
  attributesConnection_NONE?: InputMaybe<UserAttributesConnectionWhere>;
  /** Return Users where one of the related UserAttributesConnections match this filter */
  attributesConnection_SINGLE?: InputMaybe<UserAttributesConnectionWhere>;
  /** Return Users where some of the related UserAttributesConnections match this filter */
  attributesConnection_SOME?: InputMaybe<UserAttributesConnectionWhere>;
  /** Return Users where all of the related Attributes match this filter */
  attributes_ALL?: InputMaybe<AttributeWhere>;
  /** Return Users where none of the related Attributes match this filter */
  attributes_NONE?: InputMaybe<AttributeWhere>;
  /** Return Users where one of the related Attributes match this filter */
  attributes_SINGLE?: InputMaybe<AttributeWhere>;
  /** Return Users where some of the related Attributes match this filter */
  attributes_SOME?: InputMaybe<AttributeWhere>;
  audioOn?: InputMaybe<Scalars["Boolean"]["input"]>;
  birthday?: InputMaybe<Scalars["Date"]["input"]>;
  birthday_GT?: InputMaybe<Scalars["Date"]["input"]>;
  birthday_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  birthday_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  birthday_LT?: InputMaybe<Scalars["Date"]["input"]>;
  birthday_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  countryCode?: InputMaybe<Scalars["String"]["input"]>;
  countryCode_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  countryCode_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  countryCode_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  countryCode_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  currentWeightLbs_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  dailyEmailOptIn?: InputMaybe<Scalars["Boolean"]["input"]>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  email_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  email_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  email_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  email_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  enrolledTracks?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  enrolledTracks_INCLUDES?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  fullPhoneNumber_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  fullPhoneNumber_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gender?: InputMaybe<Scalars["String"]["input"]>;
  gender_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  gender_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  gender_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  gender_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  goalWeightLbs?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  goalWeightLbs_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens?: InputMaybe<Scalars["String"]["input"]>;
  googleAuthTokens_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  googleAuthTokens_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  googleAuthTokens_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  googleAuthTokens_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  googleCalendarAuthToken?: InputMaybe<Scalars["String"]["input"]>;
  googleCalendarAuthToken_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  googleCalendarAuthToken_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  googleCalendarAuthToken_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  googleCalendarAuthToken_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  hasChatChatsAggregate?: InputMaybe<UserHasChatChatsAggregateInput>;
  /** Return Users where all of the related UserHasChatChatsConnections match this filter */
  hasChatChatsConnection_ALL?: InputMaybe<UserHasChatChatsConnectionWhere>;
  /** Return Users where none of the related UserHasChatChatsConnections match this filter */
  hasChatChatsConnection_NONE?: InputMaybe<UserHasChatChatsConnectionWhere>;
  /** Return Users where one of the related UserHasChatChatsConnections match this filter */
  hasChatChatsConnection_SINGLE?: InputMaybe<UserHasChatChatsConnectionWhere>;
  /** Return Users where some of the related UserHasChatChatsConnections match this filter */
  hasChatChatsConnection_SOME?: InputMaybe<UserHasChatChatsConnectionWhere>;
  /** Return Users where all of the related Chats match this filter */
  hasChatChats_ALL?: InputMaybe<ChatWhere>;
  /** Return Users where none of the related Chats match this filter */
  hasChatChats_NONE?: InputMaybe<ChatWhere>;
  /** Return Users where one of the related Chats match this filter */
  hasChatChats_SINGLE?: InputMaybe<ChatWhere>;
  /** Return Users where some of the related Chats match this filter */
  hasChatChats_SOME?: InputMaybe<ChatWhere>;
  hasFoodItemFoodItemsAggregate?: InputMaybe<UserHasFoodItemFoodItemsAggregateInput>;
  /** Return Users where all of the related UserHasFoodItemFoodItemsConnections match this filter */
  hasFoodItemFoodItemsConnection_ALL?: InputMaybe<UserHasFoodItemFoodItemsConnectionWhere>;
  /** Return Users where none of the related UserHasFoodItemFoodItemsConnections match this filter */
  hasFoodItemFoodItemsConnection_NONE?: InputMaybe<UserHasFoodItemFoodItemsConnectionWhere>;
  /** Return Users where one of the related UserHasFoodItemFoodItemsConnections match this filter */
  hasFoodItemFoodItemsConnection_SINGLE?: InputMaybe<UserHasFoodItemFoodItemsConnectionWhere>;
  /** Return Users where some of the related UserHasFoodItemFoodItemsConnections match this filter */
  hasFoodItemFoodItemsConnection_SOME?: InputMaybe<UserHasFoodItemFoodItemsConnectionWhere>;
  /** Return Users where all of the related FoodItems match this filter */
  hasFoodItemFoodItems_ALL?: InputMaybe<FoodItemWhere>;
  /** Return Users where none of the related FoodItems match this filter */
  hasFoodItemFoodItems_NONE?: InputMaybe<FoodItemWhere>;
  /** Return Users where one of the related FoodItems match this filter */
  hasFoodItemFoodItems_SINGLE?: InputMaybe<FoodItemWhere>;
  /** Return Users where some of the related FoodItems match this filter */
  hasFoodItemFoodItems_SOME?: InputMaybe<FoodItemWhere>;
  hasJourneyJourneysAggregate?: InputMaybe<UserHasJourneyJourneysAggregateInput>;
  /** Return Users where all of the related UserHasJourneyJourneysConnections match this filter */
  hasJourneyJourneysConnection_ALL?: InputMaybe<UserHasJourneyJourneysConnectionWhere>;
  /** Return Users where none of the related UserHasJourneyJourneysConnections match this filter */
  hasJourneyJourneysConnection_NONE?: InputMaybe<UserHasJourneyJourneysConnectionWhere>;
  /** Return Users where one of the related UserHasJourneyJourneysConnections match this filter */
  hasJourneyJourneysConnection_SINGLE?: InputMaybe<UserHasJourneyJourneysConnectionWhere>;
  /** Return Users where some of the related UserHasJourneyJourneysConnections match this filter */
  hasJourneyJourneysConnection_SOME?: InputMaybe<UserHasJourneyJourneysConnectionWhere>;
  /** Return Users where all of the related Journeys match this filter */
  hasJourneyJourneys_ALL?: InputMaybe<JourneyWhere>;
  /** Return Users where none of the related Journeys match this filter */
  hasJourneyJourneys_NONE?: InputMaybe<JourneyWhere>;
  /** Return Users where one of the related Journeys match this filter */
  hasJourneyJourneys_SINGLE?: InputMaybe<JourneyWhere>;
  /** Return Users where some of the related Journeys match this filter */
  hasJourneyJourneys_SOME?: InputMaybe<JourneyWhere>;
  hasPaymentPaymentsAggregate?: InputMaybe<UserHasPaymentPaymentsAggregateInput>;
  /** Return Users where all of the related UserHasPaymentPaymentsConnections match this filter */
  hasPaymentPaymentsConnection_ALL?: InputMaybe<UserHasPaymentPaymentsConnectionWhere>;
  /** Return Users where none of the related UserHasPaymentPaymentsConnections match this filter */
  hasPaymentPaymentsConnection_NONE?: InputMaybe<UserHasPaymentPaymentsConnectionWhere>;
  /** Return Users where one of the related UserHasPaymentPaymentsConnections match this filter */
  hasPaymentPaymentsConnection_SINGLE?: InputMaybe<UserHasPaymentPaymentsConnectionWhere>;
  /** Return Users where some of the related UserHasPaymentPaymentsConnections match this filter */
  hasPaymentPaymentsConnection_SOME?: InputMaybe<UserHasPaymentPaymentsConnectionWhere>;
  /** Return Users where all of the related Payments match this filter */
  hasPaymentPayments_ALL?: InputMaybe<PaymentWhere>;
  /** Return Users where none of the related Payments match this filter */
  hasPaymentPayments_NONE?: InputMaybe<PaymentWhere>;
  /** Return Users where one of the related Payments match this filter */
  hasPaymentPayments_SINGLE?: InputMaybe<PaymentWhere>;
  /** Return Users where some of the related Payments match this filter */
  hasPaymentPayments_SOME?: InputMaybe<PaymentWhere>;
  hasPreferencesPreferencesAggregate?: InputMaybe<UserHasPreferencesPreferencesAggregateInput>;
  /** Return Users where all of the related UserHasPreferencesPreferencesConnections match this filter */
  hasPreferencesPreferencesConnection_ALL?: InputMaybe<UserHasPreferencesPreferencesConnectionWhere>;
  /** Return Users where none of the related UserHasPreferencesPreferencesConnections match this filter */
  hasPreferencesPreferencesConnection_NONE?: InputMaybe<UserHasPreferencesPreferencesConnectionWhere>;
  /** Return Users where one of the related UserHasPreferencesPreferencesConnections match this filter */
  hasPreferencesPreferencesConnection_SINGLE?: InputMaybe<UserHasPreferencesPreferencesConnectionWhere>;
  /** Return Users where some of the related UserHasPreferencesPreferencesConnections match this filter */
  hasPreferencesPreferencesConnection_SOME?: InputMaybe<UserHasPreferencesPreferencesConnectionWhere>;
  /** Return Users where all of the related Preferences match this filter */
  hasPreferencesPreferences_ALL?: InputMaybe<PreferencesWhere>;
  /** Return Users where none of the related Preferences match this filter */
  hasPreferencesPreferences_NONE?: InputMaybe<PreferencesWhere>;
  /** Return Users where one of the related Preferences match this filter */
  hasPreferencesPreferences_SINGLE?: InputMaybe<PreferencesWhere>;
  /** Return Users where some of the related Preferences match this filter */
  hasPreferencesPreferences_SOME?: InputMaybe<PreferencesWhere>;
  hasRoleRolesAggregate?: InputMaybe<UserHasRoleRolesAggregateInput>;
  /** Return Users where all of the related UserHasRoleRolesConnections match this filter */
  hasRoleRolesConnection_ALL?: InputMaybe<UserHasRoleRolesConnectionWhere>;
  /** Return Users where none of the related UserHasRoleRolesConnections match this filter */
  hasRoleRolesConnection_NONE?: InputMaybe<UserHasRoleRolesConnectionWhere>;
  /** Return Users where one of the related UserHasRoleRolesConnections match this filter */
  hasRoleRolesConnection_SINGLE?: InputMaybe<UserHasRoleRolesConnectionWhere>;
  /** Return Users where some of the related UserHasRoleRolesConnections match this filter */
  hasRoleRolesConnection_SOME?: InputMaybe<UserHasRoleRolesConnectionWhere>;
  /** Return Users where all of the related Roles match this filter */
  hasRoleRoles_ALL?: InputMaybe<RoleWhere>;
  /** Return Users where none of the related Roles match this filter */
  hasRoleRoles_NONE?: InputMaybe<RoleWhere>;
  /** Return Users where one of the related Roles match this filter */
  hasRoleRoles_SINGLE?: InputMaybe<RoleWhere>;
  /** Return Users where some of the related Roles match this filter */
  hasRoleRoles_SOME?: InputMaybe<RoleWhere>;
  hasSessionDeviceSessionsAggregate?: InputMaybe<UserHasSessionDeviceSessionsAggregateInput>;
  /** Return Users where all of the related UserHasSessionDeviceSessionsConnections match this filter */
  hasSessionDeviceSessionsConnection_ALL?: InputMaybe<UserHasSessionDeviceSessionsConnectionWhere>;
  /** Return Users where none of the related UserHasSessionDeviceSessionsConnections match this filter */
  hasSessionDeviceSessionsConnection_NONE?: InputMaybe<UserHasSessionDeviceSessionsConnectionWhere>;
  /** Return Users where one of the related UserHasSessionDeviceSessionsConnections match this filter */
  hasSessionDeviceSessionsConnection_SINGLE?: InputMaybe<UserHasSessionDeviceSessionsConnectionWhere>;
  /** Return Users where some of the related UserHasSessionDeviceSessionsConnections match this filter */
  hasSessionDeviceSessionsConnection_SOME?: InputMaybe<UserHasSessionDeviceSessionsConnectionWhere>;
  /** Return Users where all of the related DeviceSessions match this filter */
  hasSessionDeviceSessions_ALL?: InputMaybe<DeviceSessionWhere>;
  /** Return Users where none of the related DeviceSessions match this filter */
  hasSessionDeviceSessions_NONE?: InputMaybe<DeviceSessionWhere>;
  /** Return Users where one of the related DeviceSessions match this filter */
  hasSessionDeviceSessions_SINGLE?: InputMaybe<DeviceSessionWhere>;
  /** Return Users where some of the related DeviceSessions match this filter */
  hasSessionDeviceSessions_SOME?: InputMaybe<DeviceSessionWhere>;
  hasSuggestionSuggestionsAggregate?: InputMaybe<UserHasSuggestionSuggestionsAggregateInput>;
  /** Return Users where all of the related UserHasSuggestionSuggestionsConnections match this filter */
  hasSuggestionSuggestionsConnection_ALL?: InputMaybe<UserHasSuggestionSuggestionsConnectionWhere>;
  /** Return Users where none of the related UserHasSuggestionSuggestionsConnections match this filter */
  hasSuggestionSuggestionsConnection_NONE?: InputMaybe<UserHasSuggestionSuggestionsConnectionWhere>;
  /** Return Users where one of the related UserHasSuggestionSuggestionsConnections match this filter */
  hasSuggestionSuggestionsConnection_SINGLE?: InputMaybe<UserHasSuggestionSuggestionsConnectionWhere>;
  /** Return Users where some of the related UserHasSuggestionSuggestionsConnections match this filter */
  hasSuggestionSuggestionsConnection_SOME?: InputMaybe<UserHasSuggestionSuggestionsConnectionWhere>;
  /** Return Users where all of the related Suggestions match this filter */
  hasSuggestionSuggestions_ALL?: InputMaybe<SuggestionWhere>;
  /** Return Users where none of the related Suggestions match this filter */
  hasSuggestionSuggestions_NONE?: InputMaybe<SuggestionWhere>;
  /** Return Users where one of the related Suggestions match this filter */
  hasSuggestionSuggestions_SINGLE?: InputMaybe<SuggestionWhere>;
  /** Return Users where some of the related Suggestions match this filter */
  hasSuggestionSuggestions_SOME?: InputMaybe<SuggestionWhere>;
  hasSummaryDailySummariesAggregate?: InputMaybe<UserHasSummaryDailySummariesAggregateInput>;
  /** Return Users where all of the related UserHasSummaryDailySummariesConnections match this filter */
  hasSummaryDailySummariesConnection_ALL?: InputMaybe<UserHasSummaryDailySummariesConnectionWhere>;
  /** Return Users where none of the related UserHasSummaryDailySummariesConnections match this filter */
  hasSummaryDailySummariesConnection_NONE?: InputMaybe<UserHasSummaryDailySummariesConnectionWhere>;
  /** Return Users where one of the related UserHasSummaryDailySummariesConnections match this filter */
  hasSummaryDailySummariesConnection_SINGLE?: InputMaybe<UserHasSummaryDailySummariesConnectionWhere>;
  /** Return Users where some of the related UserHasSummaryDailySummariesConnections match this filter */
  hasSummaryDailySummariesConnection_SOME?: InputMaybe<UserHasSummaryDailySummariesConnectionWhere>;
  /** Return Users where all of the related DailySummaries match this filter */
  hasSummaryDailySummaries_ALL?: InputMaybe<DailySummaryWhere>;
  /** Return Users where none of the related DailySummaries match this filter */
  hasSummaryDailySummaries_NONE?: InputMaybe<DailySummaryWhere>;
  /** Return Users where one of the related DailySummaries match this filter */
  hasSummaryDailySummaries_SINGLE?: InputMaybe<DailySummaryWhere>;
  /** Return Users where some of the related DailySummaries match this filter */
  hasSummaryDailySummaries_SOME?: InputMaybe<DailySummaryWhere>;
  hasSummaryMonthlySummariesAggregate?: InputMaybe<UserHasSummaryMonthlySummariesAggregateInput>;
  /** Return Users where all of the related UserHasSummaryMonthlySummariesConnections match this filter */
  hasSummaryMonthlySummariesConnection_ALL?: InputMaybe<UserHasSummaryMonthlySummariesConnectionWhere>;
  /** Return Users where none of the related UserHasSummaryMonthlySummariesConnections match this filter */
  hasSummaryMonthlySummariesConnection_NONE?: InputMaybe<UserHasSummaryMonthlySummariesConnectionWhere>;
  /** Return Users where one of the related UserHasSummaryMonthlySummariesConnections match this filter */
  hasSummaryMonthlySummariesConnection_SINGLE?: InputMaybe<UserHasSummaryMonthlySummariesConnectionWhere>;
  /** Return Users where some of the related UserHasSummaryMonthlySummariesConnections match this filter */
  hasSummaryMonthlySummariesConnection_SOME?: InputMaybe<UserHasSummaryMonthlySummariesConnectionWhere>;
  /** Return Users where all of the related MonthlySummaries match this filter */
  hasSummaryMonthlySummaries_ALL?: InputMaybe<MonthlySummaryWhere>;
  /** Return Users where none of the related MonthlySummaries match this filter */
  hasSummaryMonthlySummaries_NONE?: InputMaybe<MonthlySummaryWhere>;
  /** Return Users where one of the related MonthlySummaries match this filter */
  hasSummaryMonthlySummaries_SINGLE?: InputMaybe<MonthlySummaryWhere>;
  /** Return Users where some of the related MonthlySummaries match this filter */
  hasSummaryMonthlySummaries_SOME?: InputMaybe<MonthlySummaryWhere>;
  hasSummaryQuarterlySummariesAggregate?: InputMaybe<UserHasSummaryQuarterlySummariesAggregateInput>;
  /** Return Users where all of the related UserHasSummaryQuarterlySummariesConnections match this filter */
  hasSummaryQuarterlySummariesConnection_ALL?: InputMaybe<UserHasSummaryQuarterlySummariesConnectionWhere>;
  /** Return Users where none of the related UserHasSummaryQuarterlySummariesConnections match this filter */
  hasSummaryQuarterlySummariesConnection_NONE?: InputMaybe<UserHasSummaryQuarterlySummariesConnectionWhere>;
  /** Return Users where one of the related UserHasSummaryQuarterlySummariesConnections match this filter */
  hasSummaryQuarterlySummariesConnection_SINGLE?: InputMaybe<UserHasSummaryQuarterlySummariesConnectionWhere>;
  /** Return Users where some of the related UserHasSummaryQuarterlySummariesConnections match this filter */
  hasSummaryQuarterlySummariesConnection_SOME?: InputMaybe<UserHasSummaryQuarterlySummariesConnectionWhere>;
  /** Return Users where all of the related QuarterlySummaries match this filter */
  hasSummaryQuarterlySummaries_ALL?: InputMaybe<QuarterlySummaryWhere>;
  /** Return Users where none of the related QuarterlySummaries match this filter */
  hasSummaryQuarterlySummaries_NONE?: InputMaybe<QuarterlySummaryWhere>;
  /** Return Users where one of the related QuarterlySummaries match this filter */
  hasSummaryQuarterlySummaries_SINGLE?: InputMaybe<QuarterlySummaryWhere>;
  /** Return Users where some of the related QuarterlySummaries match this filter */
  hasSummaryQuarterlySummaries_SOME?: InputMaybe<QuarterlySummaryWhere>;
  hasSummaryWeeklySummariesAggregate?: InputMaybe<UserHasSummaryWeeklySummariesAggregateInput>;
  /** Return Users where all of the related UserHasSummaryWeeklySummariesConnections match this filter */
  hasSummaryWeeklySummariesConnection_ALL?: InputMaybe<UserHasSummaryWeeklySummariesConnectionWhere>;
  /** Return Users where none of the related UserHasSummaryWeeklySummariesConnections match this filter */
  hasSummaryWeeklySummariesConnection_NONE?: InputMaybe<UserHasSummaryWeeklySummariesConnectionWhere>;
  /** Return Users where one of the related UserHasSummaryWeeklySummariesConnections match this filter */
  hasSummaryWeeklySummariesConnection_SINGLE?: InputMaybe<UserHasSummaryWeeklySummariesConnectionWhere>;
  /** Return Users where some of the related UserHasSummaryWeeklySummariesConnections match this filter */
  hasSummaryWeeklySummariesConnection_SOME?: InputMaybe<UserHasSummaryWeeklySummariesConnectionWhere>;
  /** Return Users where all of the related WeeklySummaries match this filter */
  hasSummaryWeeklySummaries_ALL?: InputMaybe<WeeklySummaryWhere>;
  /** Return Users where none of the related WeeklySummaries match this filter */
  hasSummaryWeeklySummaries_NONE?: InputMaybe<WeeklySummaryWhere>;
  /** Return Users where one of the related WeeklySummaries match this filter */
  hasSummaryWeeklySummaries_SINGLE?: InputMaybe<WeeklySummaryWhere>;
  /** Return Users where some of the related WeeklySummaries match this filter */
  hasSummaryWeeklySummaries_SOME?: InputMaybe<WeeklySummaryWhere>;
  hasSummaryYearlySummariesAggregate?: InputMaybe<UserHasSummaryYearlySummariesAggregateInput>;
  /** Return Users where all of the related UserHasSummaryYearlySummariesConnections match this filter */
  hasSummaryYearlySummariesConnection_ALL?: InputMaybe<UserHasSummaryYearlySummariesConnectionWhere>;
  /** Return Users where none of the related UserHasSummaryYearlySummariesConnections match this filter */
  hasSummaryYearlySummariesConnection_NONE?: InputMaybe<UserHasSummaryYearlySummariesConnectionWhere>;
  /** Return Users where one of the related UserHasSummaryYearlySummariesConnections match this filter */
  hasSummaryYearlySummariesConnection_SINGLE?: InputMaybe<UserHasSummaryYearlySummariesConnectionWhere>;
  /** Return Users where some of the related UserHasSummaryYearlySummariesConnections match this filter */
  hasSummaryYearlySummariesConnection_SOME?: InputMaybe<UserHasSummaryYearlySummariesConnectionWhere>;
  /** Return Users where all of the related YearlySummaries match this filter */
  hasSummaryYearlySummaries_ALL?: InputMaybe<YearlySummaryWhere>;
  /** Return Users where none of the related YearlySummaries match this filter */
  hasSummaryYearlySummaries_NONE?: InputMaybe<YearlySummaryWhere>;
  /** Return Users where one of the related YearlySummaries match this filter */
  hasSummaryYearlySummaries_SINGLE?: InputMaybe<YearlySummaryWhere>;
  /** Return Users where some of the related YearlySummaries match this filter */
  hasSummaryYearlySummaries_SOME?: InputMaybe<YearlySummaryWhere>;
  hasWaterIntakeWaterIntakesAggregate?: InputMaybe<UserHasWaterIntakeWaterIntakesAggregateInput>;
  /** Return Users where all of the related UserHasWaterIntakeWaterIntakesConnections match this filter */
  hasWaterIntakeWaterIntakesConnection_ALL?: InputMaybe<UserHasWaterIntakeWaterIntakesConnectionWhere>;
  /** Return Users where none of the related UserHasWaterIntakeWaterIntakesConnections match this filter */
  hasWaterIntakeWaterIntakesConnection_NONE?: InputMaybe<UserHasWaterIntakeWaterIntakesConnectionWhere>;
  /** Return Users where one of the related UserHasWaterIntakeWaterIntakesConnections match this filter */
  hasWaterIntakeWaterIntakesConnection_SINGLE?: InputMaybe<UserHasWaterIntakeWaterIntakesConnectionWhere>;
  /** Return Users where some of the related UserHasWaterIntakeWaterIntakesConnections match this filter */
  hasWaterIntakeWaterIntakesConnection_SOME?: InputMaybe<UserHasWaterIntakeWaterIntakesConnectionWhere>;
  /** Return Users where all of the related WaterIntakes match this filter */
  hasWaterIntakeWaterIntakes_ALL?: InputMaybe<WaterIntakeWhere>;
  /** Return Users where none of the related WaterIntakes match this filter */
  hasWaterIntakeWaterIntakes_NONE?: InputMaybe<WaterIntakeWhere>;
  /** Return Users where one of the related WaterIntakes match this filter */
  hasWaterIntakeWaterIntakes_SINGLE?: InputMaybe<WaterIntakeWhere>;
  /** Return Users where some of the related WaterIntakes match this filter */
  hasWaterIntakeWaterIntakes_SOME?: InputMaybe<WaterIntakeWhere>;
  hasWhitelistedEmailsAggregate?: InputMaybe<UserHasWhitelistedEmailsAggregateInput>;
  /** Return Users where all of the related UserHasWhitelistedEmailsConnections match this filter */
  hasWhitelistedEmailsConnection_ALL?: InputMaybe<UserHasWhitelistedEmailsConnectionWhere>;
  /** Return Users where none of the related UserHasWhitelistedEmailsConnections match this filter */
  hasWhitelistedEmailsConnection_NONE?: InputMaybe<UserHasWhitelistedEmailsConnectionWhere>;
  /** Return Users where one of the related UserHasWhitelistedEmailsConnections match this filter */
  hasWhitelistedEmailsConnection_SINGLE?: InputMaybe<UserHasWhitelistedEmailsConnectionWhere>;
  /** Return Users where some of the related UserHasWhitelistedEmailsConnections match this filter */
  hasWhitelistedEmailsConnection_SOME?: InputMaybe<UserHasWhitelistedEmailsConnectionWhere>;
  /** Return Users where all of the related WhitelistedEmails match this filter */
  hasWhitelistedEmails_ALL?: InputMaybe<WhitelistedEmailWhere>;
  /** Return Users where none of the related WhitelistedEmails match this filter */
  hasWhitelistedEmails_NONE?: InputMaybe<WhitelistedEmailWhere>;
  /** Return Users where one of the related WhitelistedEmails match this filter */
  hasWhitelistedEmails_SINGLE?: InputMaybe<WhitelistedEmailWhere>;
  /** Return Users where some of the related WhitelistedEmails match this filter */
  hasWhitelistedEmails_SOME?: InputMaybe<WhitelistedEmailWhere>;
  id?: InputMaybe<Scalars["ID"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["ID"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["ID"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["ID"]["input"]>;
  inviteCount?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_IN?: InputMaybe<Array<InputMaybe<Scalars["Int"]["input"]>>>;
  inviteCount_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  isCareerLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isCourseLoading?: InputMaybe<Scalars["Boolean"]["input"]>;
  isEmailVerified?: InputMaybe<Scalars["Boolean"]["input"]>;
  isOnboarded?: InputMaybe<Scalars["Boolean"]["input"]>;
  isPro?: InputMaybe<Scalars["Boolean"]["input"]>;
  modalOnboarding?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  modalOnboarding_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  modalOnboarding_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  moodlePassword?: InputMaybe<Scalars["String"]["input"]>;
  moodlePassword_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  moodlePassword_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  moodlePassword_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  moodlePassword_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  otpPreference_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  otpPreference_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  password?: InputMaybe<Scalars["String"]["input"]>;
  password_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  password_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  password_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  password_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  phoneNumber_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  phoneNumber_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeCustomerId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  stripeCustomerId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  stripeSubscriptionId_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  stripeSubscriptionId_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  tenantsAggregate?: InputMaybe<UserTenantsAggregateInput>;
  /** Return Users where all of the related UserTenantsConnections match this filter */
  tenantsConnection_ALL?: InputMaybe<UserTenantsConnectionWhere>;
  /** Return Users where none of the related UserTenantsConnections match this filter */
  tenantsConnection_NONE?: InputMaybe<UserTenantsConnectionWhere>;
  /** Return Users where one of the related UserTenantsConnections match this filter */
  tenantsConnection_SINGLE?: InputMaybe<UserTenantsConnectionWhere>;
  /** Return Users where some of the related UserTenantsConnections match this filter */
  tenantsConnection_SOME?: InputMaybe<UserTenantsConnectionWhere>;
  /** Return Users where all of the related Tenants match this filter */
  tenants_ALL?: InputMaybe<TenantWhere>;
  /** Return Users where none of the related Tenants match this filter */
  tenants_NONE?: InputMaybe<TenantWhere>;
  /** Return Users where one of the related Tenants match this filter */
  tenants_SINGLE?: InputMaybe<TenantWhere>;
  /** Return Users where some of the related Tenants match this filter */
  tenants_SOME?: InputMaybe<TenantWhere>;
  todosAggregate?: InputMaybe<UserTodosAggregateInput>;
  /** Return Users where all of the related UserTodosConnections match this filter */
  todosConnection_ALL?: InputMaybe<UserTodosConnectionWhere>;
  /** Return Users where none of the related UserTodosConnections match this filter */
  todosConnection_NONE?: InputMaybe<UserTodosConnectionWhere>;
  /** Return Users where one of the related UserTodosConnections match this filter */
  todosConnection_SINGLE?: InputMaybe<UserTodosConnectionWhere>;
  /** Return Users where some of the related UserTodosConnections match this filter */
  todosConnection_SOME?: InputMaybe<UserTodosConnectionWhere>;
  /** Return Users where all of the related Todos match this filter */
  todos_ALL?: InputMaybe<TodoWhere>;
  /** Return Users where none of the related Todos match this filter */
  todos_NONE?: InputMaybe<TodoWhere>;
  /** Return Users where one of the related Todos match this filter */
  todos_SINGLE?: InputMaybe<TodoWhere>;
  /** Return Users where some of the related Todos match this filter */
  todos_SOME?: InputMaybe<TodoWhere>;
  trialEndDate?: InputMaybe<Scalars["Date"]["input"]>;
  trialEndDate_GT?: InputMaybe<Scalars["Date"]["input"]>;
  trialEndDate_GTE?: InputMaybe<Scalars["Date"]["input"]>;
  trialEndDate_IN?: InputMaybe<Array<InputMaybe<Scalars["Date"]["input"]>>>;
  trialEndDate_LT?: InputMaybe<Scalars["Date"]["input"]>;
  trialEndDate_LTE?: InputMaybe<Scalars["Date"]["input"]>;
  undefinedFoodItemsAggregate?: InputMaybe<UserUndefinedFoodItemsAggregateInput>;
  /** Return Users where all of the related UserUndefinedFoodItemsConnections match this filter */
  undefinedFoodItemsConnection_ALL?: InputMaybe<UserUndefinedFoodItemsConnectionWhere>;
  /** Return Users where none of the related UserUndefinedFoodItemsConnections match this filter */
  undefinedFoodItemsConnection_NONE?: InputMaybe<UserUndefinedFoodItemsConnectionWhere>;
  /** Return Users where one of the related UserUndefinedFoodItemsConnections match this filter */
  undefinedFoodItemsConnection_SINGLE?: InputMaybe<UserUndefinedFoodItemsConnectionWhere>;
  /** Return Users where some of the related UserUndefinedFoodItemsConnections match this filter */
  undefinedFoodItemsConnection_SOME?: InputMaybe<UserUndefinedFoodItemsConnectionWhere>;
  /** Return Users where all of the related FoodItems match this filter */
  undefinedFoodItems_ALL?: InputMaybe<FoodItemWhere>;
  /** Return Users where none of the related FoodItems match this filter */
  undefinedFoodItems_NONE?: InputMaybe<FoodItemWhere>;
  /** Return Users where one of the related FoodItems match this filter */
  undefinedFoodItems_SINGLE?: InputMaybe<FoodItemWhere>;
  /** Return Users where some of the related FoodItems match this filter */
  undefinedFoodItems_SOME?: InputMaybe<FoodItemWhere>;
  undefinedWaterIntakesAggregate?: InputMaybe<UserUndefinedWaterIntakesAggregateInput>;
  /** Return Users where all of the related UserUndefinedWaterIntakesConnections match this filter */
  undefinedWaterIntakesConnection_ALL?: InputMaybe<UserUndefinedWaterIntakesConnectionWhere>;
  /** Return Users where none of the related UserUndefinedWaterIntakesConnections match this filter */
  undefinedWaterIntakesConnection_NONE?: InputMaybe<UserUndefinedWaterIntakesConnectionWhere>;
  /** Return Users where one of the related UserUndefinedWaterIntakesConnections match this filter */
  undefinedWaterIntakesConnection_SINGLE?: InputMaybe<UserUndefinedWaterIntakesConnectionWhere>;
  /** Return Users where some of the related UserUndefinedWaterIntakesConnections match this filter */
  undefinedWaterIntakesConnection_SOME?: InputMaybe<UserUndefinedWaterIntakesConnectionWhere>;
  /** Return Users where all of the related WaterIntakes match this filter */
  undefinedWaterIntakes_ALL?: InputMaybe<WaterIntakeWhere>;
  /** Return Users where none of the related WaterIntakes match this filter */
  undefinedWaterIntakes_NONE?: InputMaybe<WaterIntakeWhere>;
  /** Return Users where one of the related WaterIntakes match this filter */
  undefinedWaterIntakes_SINGLE?: InputMaybe<WaterIntakeWhere>;
  /** Return Users where some of the related WaterIntakes match this filter */
  undefinedWaterIntakes_SOME?: InputMaybe<WaterIntakeWhere>;
  username?: InputMaybe<Scalars["String"]["input"]>;
  username_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  username_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  username_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  username_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type UserWhitelistedEmailHasWhitelistedEmailsAggregationSelection = {
  __typename?: "UserWhitelistedEmailHasWhitelistedEmailsAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserWhitelistedEmailHasWhitelistedEmailsNodeAggregateSelection>;
};

export type UserWhitelistedEmailHasWhitelistedEmailsNodeAggregateSelection = {
  __typename?: "UserWhitelistedEmailHasWhitelistedEmailsNodeAggregateSelection";
  email: StringAggregateSelection;
};

export type UserYearlySummaryHasSummaryYearlySummariesAggregationSelection = {
  __typename?: "UserYearlySummaryHasSummaryYearlySummariesAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<UserYearlySummaryHasSummaryYearlySummariesNodeAggregateSelection>;
};

export type UserYearlySummaryHasSummaryYearlySummariesNodeAggregateSelection = {
  __typename?: "UserYearlySummaryHasSummaryYearlySummariesNodeAggregateSelection";
  created: DateTimeAggregateSelection;
  date: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type UsersConnection = {
  __typename?: "UsersConnection";
  edges: Array<UserEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WaterIntake = {
  __typename?: "WaterIntake";
  created: Scalars["DateTime"]["output"];
  id: Scalars["String"]["output"];
  last_modified: Scalars["DateTime"]["output"];
  name: Scalars["String"]["output"];
  usersHasWaterIntake: Array<User>;
  usersHasWaterIntakeAggregate?: Maybe<WaterIntakeUserUsersHasWaterIntakeAggregationSelection>;
  usersHasWaterIntakeConnection: WaterIntakeUsersHasWaterIntakeConnection;
  usersundefined: Array<User>;
  usersundefinedAggregate?: Maybe<WaterIntakeUserUsersundefinedAggregationSelection>;
  usersundefinedConnection: WaterIntakeUsersundefinedConnection;
  water_intake_oz?: Maybe<Scalars["Float"]["output"]>;
};

export type WaterIntakeUsersHasWaterIntakeArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type WaterIntakeUsersHasWaterIntakeAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type WaterIntakeUsersHasWaterIntakeConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeConnectionSort>>;
  where?: InputMaybe<WaterIntakeUsersHasWaterIntakeConnectionWhere>;
};

export type WaterIntakeUsersundefinedArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type WaterIntakeUsersundefinedAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type WaterIntakeUsersundefinedConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<WaterIntakeUsersundefinedConnectionSort>>;
  where?: InputMaybe<WaterIntakeUsersundefinedConnectionWhere>;
};

export type WaterIntakeAggregateSelection = {
  __typename?: "WaterIntakeAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  id: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  name: StringAggregateSelection;
  water_intake_oz: FloatAggregateSelection;
};

export type WaterIntakeConnectInput = {
  usersHasWaterIntake?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeConnectFieldInput>>;
  usersundefined?: InputMaybe<Array<WaterIntakeUsersundefinedConnectFieldInput>>;
};

export type WaterIntakeConnectWhere = {
  node: WaterIntakeWhere;
};

export type WaterIntakeCreateInput = {
  created: Scalars["DateTime"]["input"];
  id: Scalars["String"]["input"];
  last_modified: Scalars["DateTime"]["input"];
  name: Scalars["String"]["input"];
  usersHasWaterIntake?: InputMaybe<WaterIntakeUsersHasWaterIntakeFieldInput>;
  usersundefined?: InputMaybe<WaterIntakeUsersundefinedFieldInput>;
  water_intake_oz?: InputMaybe<Scalars["Float"]["input"]>;
};

export type WaterIntakeDeleteInput = {
  usersHasWaterIntake?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeDeleteFieldInput>>;
  usersundefined?: InputMaybe<Array<WaterIntakeUsersundefinedDeleteFieldInput>>;
};

export type WaterIntakeDisconnectInput = {
  usersHasWaterIntake?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeDisconnectFieldInput>>;
  usersundefined?: InputMaybe<Array<WaterIntakeUsersundefinedDisconnectFieldInput>>;
};

export type WaterIntakeEdge = {
  __typename?: "WaterIntakeEdge";
  cursor: Scalars["String"]["output"];
  node: WaterIntake;
};

export type WaterIntakeOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more WaterIntakeSort objects to sort WaterIntakes by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<WaterIntakeSort>>;
};

export type WaterIntakeRelationInput = {
  usersHasWaterIntake?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeCreateFieldInput>>;
  usersundefined?: InputMaybe<Array<WaterIntakeUsersundefinedCreateFieldInput>>;
};

/** Fields to sort WaterIntakes by. The order in which sorts are applied is not guaranteed when specifying many fields in one WaterIntakeSort object. */
export type WaterIntakeSort = {
  created?: InputMaybe<SortDirection>;
  id?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  name?: InputMaybe<SortDirection>;
  water_intake_oz?: InputMaybe<SortDirection>;
};

export type WaterIntakeSummariesConnection = {
  __typename?: "WaterIntakeSummariesConnection";
  edges: Array<WaterIntakeSummaryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WaterIntakeSummary = {
  __typename?: "WaterIntakeSummary";
  goalOz: Scalars["Float"]["output"];
  percentageTowardsGoal: Scalars["Float"]["output"];
  totalWaterIntake: Scalars["Float"]["output"];
};

export type WaterIntakeSummaryAggregateSelection = {
  __typename?: "WaterIntakeSummaryAggregateSelection";
  count: Scalars["Int"]["output"];
  goalOz: FloatAggregateSelection;
  percentageTowardsGoal: FloatAggregateSelection;
  totalWaterIntake: FloatAggregateSelection;
};

export type WaterIntakeSummaryCreateInput = {
  goalOz: Scalars["Float"]["input"];
  percentageTowardsGoal: Scalars["Float"]["input"];
  totalWaterIntake: Scalars["Float"]["input"];
};

export type WaterIntakeSummaryEdge = {
  __typename?: "WaterIntakeSummaryEdge";
  cursor: Scalars["String"]["output"];
  node: WaterIntakeSummary;
};

export type WaterIntakeSummaryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more WaterIntakeSummarySort objects to sort WaterIntakeSummaries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<WaterIntakeSummarySort>>;
};

/** Fields to sort WaterIntakeSummaries by. The order in which sorts are applied is not guaranteed when specifying many fields in one WaterIntakeSummarySort object. */
export type WaterIntakeSummarySort = {
  goalOz?: InputMaybe<SortDirection>;
  percentageTowardsGoal?: InputMaybe<SortDirection>;
  totalWaterIntake?: InputMaybe<SortDirection>;
};

export type WaterIntakeSummaryUpdateInput = {
  goalOz?: InputMaybe<Scalars["Float"]["input"]>;
  goalOz_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  goalOz_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  goalOz_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  goalOz_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type WaterIntakeSummaryWhere = {
  AND?: InputMaybe<Array<WaterIntakeSummaryWhere>>;
  NOT?: InputMaybe<WaterIntakeSummaryWhere>;
  OR?: InputMaybe<Array<WaterIntakeSummaryWhere>>;
  goalOz?: InputMaybe<Scalars["Float"]["input"]>;
  goalOz_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalOz_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalOz_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  goalOz_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalOz_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal_GT?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  percentageTowardsGoal_LT?: InputMaybe<Scalars["Float"]["input"]>;
  percentageTowardsGoal_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake_GT?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake_IN?: InputMaybe<Array<Scalars["Float"]["input"]>>;
  totalWaterIntake_LT?: InputMaybe<Scalars["Float"]["input"]>;
  totalWaterIntake_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type WaterIntakeUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  usersHasWaterIntake?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeUpdateFieldInput>>;
  usersundefined?: InputMaybe<Array<WaterIntakeUsersundefinedUpdateFieldInput>>;
  water_intake_oz?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_ADD?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_DIVIDE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_MULTIPLY?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_SUBTRACT?: InputMaybe<Scalars["Float"]["input"]>;
};

export type WaterIntakeUserUsersHasWaterIntakeAggregationSelection = {
  __typename?: "WaterIntakeUserUsersHasWaterIntakeAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<WaterIntakeUserUsersHasWaterIntakeEdgeAggregateSelection>;
  node?: Maybe<WaterIntakeUserUsersHasWaterIntakeNodeAggregateSelection>;
};

export type WaterIntakeUserUsersHasWaterIntakeEdgeAggregateSelection = {
  __typename?: "WaterIntakeUserUsersHasWaterIntakeEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  quality: BigIntAggregateSelection;
  strength: BigIntAggregateSelection;
};

export type WaterIntakeUserUsersHasWaterIntakeNodeAggregateSelection = {
  __typename?: "WaterIntakeUserUsersHasWaterIntakeNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type WaterIntakeUserUsersundefinedAggregationSelection = {
  __typename?: "WaterIntakeUserUsersundefinedAggregationSelection";
  count: Scalars["Int"]["output"];
  edge?: Maybe<WaterIntakeUserUsersundefinedEdgeAggregateSelection>;
  node?: Maybe<WaterIntakeUserUsersundefinedNodeAggregateSelection>;
};

export type WaterIntakeUserUsersundefinedEdgeAggregateSelection = {
  __typename?: "WaterIntakeUserUsersundefinedEdgeAggregateSelection";
  created: DateTimeAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  quality: BigIntAggregateSelection;
  strength: BigIntAggregateSelection;
};

export type WaterIntakeUserUsersundefinedNodeAggregateSelection = {
  __typename?: "WaterIntakeUserUsersundefinedNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type WaterIntakeUsersHasWaterIntakeAggregateInput = {
  AND?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeAggregateInput>>;
  NOT?: InputMaybe<WaterIntakeUsersHasWaterIntakeAggregateInput>;
  OR?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<HasWaterIntakePropertiesAggregationWhereInput>;
  node?: InputMaybe<WaterIntakeUsersHasWaterIntakeNodeAggregationWhereInput>;
};

export type WaterIntakeUsersHasWaterIntakeConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge: HasWaterIntakePropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type WaterIntakeUsersHasWaterIntakeConnection = {
  __typename?: "WaterIntakeUsersHasWaterIntakeConnection";
  edges: Array<WaterIntakeUsersHasWaterIntakeRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WaterIntakeUsersHasWaterIntakeConnectionSort = {
  edge?: InputMaybe<HasWaterIntakePropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type WaterIntakeUsersHasWaterIntakeConnectionWhere = {
  AND?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeConnectionWhere>>;
  NOT?: InputMaybe<WaterIntakeUsersHasWaterIntakeConnectionWhere>;
  OR?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeConnectionWhere>>;
  edge?: InputMaybe<HasWaterIntakePropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type WaterIntakeUsersHasWaterIntakeCreateFieldInput = {
  edge: HasWaterIntakePropertiesCreateInput;
  node: UserCreateInput;
};

export type WaterIntakeUsersHasWaterIntakeDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<WaterIntakeUsersHasWaterIntakeConnectionWhere>;
};

export type WaterIntakeUsersHasWaterIntakeDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<WaterIntakeUsersHasWaterIntakeConnectionWhere>;
};

export type WaterIntakeUsersHasWaterIntakeFieldInput = {
  connect?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeConnectFieldInput>>;
  create?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeCreateFieldInput>>;
};

export type WaterIntakeUsersHasWaterIntakeNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeNodeAggregationWhereInput>>;
  NOT?: InputMaybe<WaterIntakeUsersHasWaterIntakeNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WaterIntakeUsersHasWaterIntakeRelationship = {
  __typename?: "WaterIntakeUsersHasWaterIntakeRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: HasWaterIntakeProperties;
};

export type WaterIntakeUsersHasWaterIntakeUpdateConnectionInput = {
  edge?: InputMaybe<HasWaterIntakePropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type WaterIntakeUsersHasWaterIntakeUpdateFieldInput = {
  connect?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeConnectFieldInput>>;
  create?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeCreateFieldInput>>;
  delete?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<WaterIntakeUsersHasWaterIntakeDisconnectFieldInput>>;
  update?: InputMaybe<WaterIntakeUsersHasWaterIntakeUpdateConnectionInput>;
  where?: InputMaybe<WaterIntakeUsersHasWaterIntakeConnectionWhere>;
};

export type WaterIntakeUsersundefinedAggregateInput = {
  AND?: InputMaybe<Array<WaterIntakeUsersundefinedAggregateInput>>;
  NOT?: InputMaybe<WaterIntakeUsersundefinedAggregateInput>;
  OR?: InputMaybe<Array<WaterIntakeUsersundefinedAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  edge?: InputMaybe<UndefinedPropertiesAggregationWhereInput>;
  node?: InputMaybe<WaterIntakeUsersundefinedNodeAggregationWhereInput>;
};

export type WaterIntakeUsersundefinedConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  edge: UndefinedPropertiesCreateInput;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type WaterIntakeUsersundefinedConnection = {
  __typename?: "WaterIntakeUsersundefinedConnection";
  edges: Array<WaterIntakeUsersundefinedRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WaterIntakeUsersundefinedConnectionSort = {
  edge?: InputMaybe<UndefinedPropertiesSort>;
  node?: InputMaybe<UserSort>;
};

export type WaterIntakeUsersundefinedConnectionWhere = {
  AND?: InputMaybe<Array<WaterIntakeUsersundefinedConnectionWhere>>;
  NOT?: InputMaybe<WaterIntakeUsersundefinedConnectionWhere>;
  OR?: InputMaybe<Array<WaterIntakeUsersundefinedConnectionWhere>>;
  edge?: InputMaybe<UndefinedPropertiesWhere>;
  node?: InputMaybe<UserWhere>;
};

export type WaterIntakeUsersundefinedCreateFieldInput = {
  edge: UndefinedPropertiesCreateInput;
  node: UserCreateInput;
};

export type WaterIntakeUsersundefinedDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<WaterIntakeUsersundefinedConnectionWhere>;
};

export type WaterIntakeUsersundefinedDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<WaterIntakeUsersundefinedConnectionWhere>;
};

export type WaterIntakeUsersundefinedFieldInput = {
  connect?: InputMaybe<Array<WaterIntakeUsersundefinedConnectFieldInput>>;
  create?: InputMaybe<Array<WaterIntakeUsersundefinedCreateFieldInput>>;
};

export type WaterIntakeUsersundefinedNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<WaterIntakeUsersundefinedNodeAggregationWhereInput>>;
  NOT?: InputMaybe<WaterIntakeUsersundefinedNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<WaterIntakeUsersundefinedNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WaterIntakeUsersundefinedRelationship = {
  __typename?: "WaterIntakeUsersundefinedRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
  properties: UndefinedProperties;
};

export type WaterIntakeUsersundefinedUpdateConnectionInput = {
  edge?: InputMaybe<UndefinedPropertiesUpdateInput>;
  node?: InputMaybe<UserUpdateInput>;
};

export type WaterIntakeUsersundefinedUpdateFieldInput = {
  connect?: InputMaybe<Array<WaterIntakeUsersundefinedConnectFieldInput>>;
  create?: InputMaybe<Array<WaterIntakeUsersundefinedCreateFieldInput>>;
  delete?: InputMaybe<Array<WaterIntakeUsersundefinedDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<WaterIntakeUsersundefinedDisconnectFieldInput>>;
  update?: InputMaybe<WaterIntakeUsersundefinedUpdateConnectionInput>;
  where?: InputMaybe<WaterIntakeUsersundefinedConnectionWhere>;
};

export type WaterIntakeWhere = {
  AND?: InputMaybe<Array<WaterIntakeWhere>>;
  NOT?: InputMaybe<WaterIntakeWhere>;
  OR?: InputMaybe<Array<WaterIntakeWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  id?: InputMaybe<Scalars["String"]["input"]>;
  id_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  id_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  id_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  id_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasWaterIntakeAggregate?: InputMaybe<WaterIntakeUsersHasWaterIntakeAggregateInput>;
  /** Return WaterIntakes where all of the related WaterIntakeUsersHasWaterIntakeConnections match this filter */
  usersHasWaterIntakeConnection_ALL?: InputMaybe<WaterIntakeUsersHasWaterIntakeConnectionWhere>;
  /** Return WaterIntakes where none of the related WaterIntakeUsersHasWaterIntakeConnections match this filter */
  usersHasWaterIntakeConnection_NONE?: InputMaybe<WaterIntakeUsersHasWaterIntakeConnectionWhere>;
  /** Return WaterIntakes where one of the related WaterIntakeUsersHasWaterIntakeConnections match this filter */
  usersHasWaterIntakeConnection_SINGLE?: InputMaybe<WaterIntakeUsersHasWaterIntakeConnectionWhere>;
  /** Return WaterIntakes where some of the related WaterIntakeUsersHasWaterIntakeConnections match this filter */
  usersHasWaterIntakeConnection_SOME?: InputMaybe<WaterIntakeUsersHasWaterIntakeConnectionWhere>;
  /** Return WaterIntakes where all of the related Users match this filter */
  usersHasWaterIntake_ALL?: InputMaybe<UserWhere>;
  /** Return WaterIntakes where none of the related Users match this filter */
  usersHasWaterIntake_NONE?: InputMaybe<UserWhere>;
  /** Return WaterIntakes where one of the related Users match this filter */
  usersHasWaterIntake_SINGLE?: InputMaybe<UserWhere>;
  /** Return WaterIntakes where some of the related Users match this filter */
  usersHasWaterIntake_SOME?: InputMaybe<UserWhere>;
  usersundefinedAggregate?: InputMaybe<WaterIntakeUsersundefinedAggregateInput>;
  /** Return WaterIntakes where all of the related WaterIntakeUsersundefinedConnections match this filter */
  usersundefinedConnection_ALL?: InputMaybe<WaterIntakeUsersundefinedConnectionWhere>;
  /** Return WaterIntakes where none of the related WaterIntakeUsersundefinedConnections match this filter */
  usersundefinedConnection_NONE?: InputMaybe<WaterIntakeUsersundefinedConnectionWhere>;
  /** Return WaterIntakes where one of the related WaterIntakeUsersundefinedConnections match this filter */
  usersundefinedConnection_SINGLE?: InputMaybe<WaterIntakeUsersundefinedConnectionWhere>;
  /** Return WaterIntakes where some of the related WaterIntakeUsersundefinedConnections match this filter */
  usersundefinedConnection_SOME?: InputMaybe<WaterIntakeUsersundefinedConnectionWhere>;
  /** Return WaterIntakes where all of the related Users match this filter */
  usersundefined_ALL?: InputMaybe<UserWhere>;
  /** Return WaterIntakes where none of the related Users match this filter */
  usersundefined_NONE?: InputMaybe<UserWhere>;
  /** Return WaterIntakes where one of the related Users match this filter */
  usersundefined_SINGLE?: InputMaybe<UserWhere>;
  /** Return WaterIntakes where some of the related Users match this filter */
  usersundefined_SOME?: InputMaybe<UserWhere>;
  water_intake_oz?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_GT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_IN?: InputMaybe<Array<InputMaybe<Scalars["Float"]["input"]>>>;
  water_intake_oz_LT?: InputMaybe<Scalars["Float"]["input"]>;
  water_intake_oz_LTE?: InputMaybe<Scalars["Float"]["input"]>;
};

export type WaterIntakesConnection = {
  __typename?: "WaterIntakesConnection";
  edges: Array<WaterIntakeEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WeeklySummariesConnection = {
  __typename?: "WeeklySummariesConnection";
  edges: Array<WeeklySummaryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WeeklySummary = {
  __typename?: "WeeklySummary";
  created: Scalars["DateTime"]["output"];
  date: Scalars["String"]["output"];
  isArchived: Scalars["Boolean"]["output"];
  last_modified: Scalars["DateTime"]["output"];
  summary: Scalars["String"]["output"];
  usersHasSummary: Array<User>;
  usersHasSummaryAggregate?: Maybe<WeeklySummaryUserUsersHasSummaryAggregationSelection>;
  usersHasSummaryConnection: WeeklySummaryUsersHasSummaryConnection;
};

export type WeeklySummaryUsersHasSummaryArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type WeeklySummaryUsersHasSummaryAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type WeeklySummaryUsersHasSummaryConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<WeeklySummaryUsersHasSummaryConnectionSort>>;
  where?: InputMaybe<WeeklySummaryUsersHasSummaryConnectionWhere>;
};

export type WeeklySummaryAggregateSelection = {
  __typename?: "WeeklySummaryAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  date: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type WeeklySummaryConnectInput = {
  usersHasSummary?: InputMaybe<Array<WeeklySummaryUsersHasSummaryConnectFieldInput>>;
};

export type WeeklySummaryConnectWhere = {
  node: WeeklySummaryWhere;
};

export type WeeklySummaryCreateInput = {
  created: Scalars["DateTime"]["input"];
  date: Scalars["String"]["input"];
  isArchived: Scalars["Boolean"]["input"];
  last_modified: Scalars["DateTime"]["input"];
  summary: Scalars["String"]["input"];
  usersHasSummary?: InputMaybe<WeeklySummaryUsersHasSummaryFieldInput>;
};

export type WeeklySummaryDeleteInput = {
  usersHasSummary?: InputMaybe<Array<WeeklySummaryUsersHasSummaryDeleteFieldInput>>;
};

export type WeeklySummaryDisconnectInput = {
  usersHasSummary?: InputMaybe<Array<WeeklySummaryUsersHasSummaryDisconnectFieldInput>>;
};

export type WeeklySummaryEdge = {
  __typename?: "WeeklySummaryEdge";
  cursor: Scalars["String"]["output"];
  node: WeeklySummary;
};

export type WeeklySummaryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more WeeklySummarySort objects to sort WeeklySummaries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<WeeklySummarySort>>;
};

export type WeeklySummaryRelationInput = {
  usersHasSummary?: InputMaybe<Array<WeeklySummaryUsersHasSummaryCreateFieldInput>>;
};

/** Fields to sort WeeklySummaries by. The order in which sorts are applied is not guaranteed when specifying many fields in one WeeklySummarySort object. */
export type WeeklySummarySort = {
  created?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  isArchived?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  summary?: InputMaybe<SortDirection>;
};

export type WeeklySummaryUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSummary?: InputMaybe<Array<WeeklySummaryUsersHasSummaryUpdateFieldInput>>;
};

export type WeeklySummaryUserUsersHasSummaryAggregationSelection = {
  __typename?: "WeeklySummaryUserUsersHasSummaryAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<WeeklySummaryUserUsersHasSummaryNodeAggregateSelection>;
};

export type WeeklySummaryUserUsersHasSummaryNodeAggregateSelection = {
  __typename?: "WeeklySummaryUserUsersHasSummaryNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type WeeklySummaryUsersHasSummaryAggregateInput = {
  AND?: InputMaybe<Array<WeeklySummaryUsersHasSummaryAggregateInput>>;
  NOT?: InputMaybe<WeeklySummaryUsersHasSummaryAggregateInput>;
  OR?: InputMaybe<Array<WeeklySummaryUsersHasSummaryAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<WeeklySummaryUsersHasSummaryNodeAggregationWhereInput>;
};

export type WeeklySummaryUsersHasSummaryConnectFieldInput = {
  connect?: InputMaybe<Array<UserConnectInput>>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type WeeklySummaryUsersHasSummaryConnection = {
  __typename?: "WeeklySummaryUsersHasSummaryConnection";
  edges: Array<WeeklySummaryUsersHasSummaryRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WeeklySummaryUsersHasSummaryConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type WeeklySummaryUsersHasSummaryConnectionWhere = {
  AND?: InputMaybe<Array<WeeklySummaryUsersHasSummaryConnectionWhere>>;
  NOT?: InputMaybe<WeeklySummaryUsersHasSummaryConnectionWhere>;
  OR?: InputMaybe<Array<WeeklySummaryUsersHasSummaryConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type WeeklySummaryUsersHasSummaryCreateFieldInput = {
  node: UserCreateInput;
};

export type WeeklySummaryUsersHasSummaryDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<WeeklySummaryUsersHasSummaryConnectionWhere>;
};

export type WeeklySummaryUsersHasSummaryDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<WeeklySummaryUsersHasSummaryConnectionWhere>;
};

export type WeeklySummaryUsersHasSummaryFieldInput = {
  connect?: InputMaybe<Array<WeeklySummaryUsersHasSummaryConnectFieldInput>>;
  create?: InputMaybe<Array<WeeklySummaryUsersHasSummaryCreateFieldInput>>;
};

export type WeeklySummaryUsersHasSummaryNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<WeeklySummaryUsersHasSummaryNodeAggregationWhereInput>>;
  NOT?: InputMaybe<WeeklySummaryUsersHasSummaryNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<WeeklySummaryUsersHasSummaryNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WeeklySummaryUsersHasSummaryRelationship = {
  __typename?: "WeeklySummaryUsersHasSummaryRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type WeeklySummaryUsersHasSummaryUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type WeeklySummaryUsersHasSummaryUpdateFieldInput = {
  connect?: InputMaybe<Array<WeeklySummaryUsersHasSummaryConnectFieldInput>>;
  create?: InputMaybe<Array<WeeklySummaryUsersHasSummaryCreateFieldInput>>;
  delete?: InputMaybe<Array<WeeklySummaryUsersHasSummaryDeleteFieldInput>>;
  disconnect?: InputMaybe<Array<WeeklySummaryUsersHasSummaryDisconnectFieldInput>>;
  update?: InputMaybe<WeeklySummaryUsersHasSummaryUpdateConnectionInput>;
  where?: InputMaybe<WeeklySummaryUsersHasSummaryConnectionWhere>;
};

export type WeeklySummaryWhere = {
  AND?: InputMaybe<Array<WeeklySummaryWhere>>;
  NOT?: InputMaybe<WeeklySummaryWhere>;
  OR?: InputMaybe<Array<WeeklySummaryWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  date_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  summary_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  summary_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  summary_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  summary_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  usersHasSummaryAggregate?: InputMaybe<WeeklySummaryUsersHasSummaryAggregateInput>;
  /** Return WeeklySummaries where all of the related WeeklySummaryUsersHasSummaryConnections match this filter */
  usersHasSummaryConnection_ALL?: InputMaybe<WeeklySummaryUsersHasSummaryConnectionWhere>;
  /** Return WeeklySummaries where none of the related WeeklySummaryUsersHasSummaryConnections match this filter */
  usersHasSummaryConnection_NONE?: InputMaybe<WeeklySummaryUsersHasSummaryConnectionWhere>;
  /** Return WeeklySummaries where one of the related WeeklySummaryUsersHasSummaryConnections match this filter */
  usersHasSummaryConnection_SINGLE?: InputMaybe<WeeklySummaryUsersHasSummaryConnectionWhere>;
  /** Return WeeklySummaries where some of the related WeeklySummaryUsersHasSummaryConnections match this filter */
  usersHasSummaryConnection_SOME?: InputMaybe<WeeklySummaryUsersHasSummaryConnectionWhere>;
  /** Return WeeklySummaries where all of the related Users match this filter */
  usersHasSummary_ALL?: InputMaybe<UserWhere>;
  /** Return WeeklySummaries where none of the related Users match this filter */
  usersHasSummary_NONE?: InputMaybe<UserWhere>;
  /** Return WeeklySummaries where one of the related Users match this filter */
  usersHasSummary_SINGLE?: InputMaybe<UserWhere>;
  /** Return WeeklySummaries where some of the related Users match this filter */
  usersHasSummary_SOME?: InputMaybe<UserWhere>;
};

export type WhitelistedEmail = {
  __typename?: "WhitelistedEmail";
  email: Scalars["String"]["output"];
  invitee: User;
  inviteeAggregate?: Maybe<WhitelistedEmailUserInviteeAggregationSelection>;
  inviteeConnection: WhitelistedEmailInviteeConnection;
};

export type WhitelistedEmailInviteeArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  options?: InputMaybe<UserOptions>;
  where?: InputMaybe<UserWhere>;
};

export type WhitelistedEmailInviteeAggregateArgs = {
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  where?: InputMaybe<UserWhere>;
};

export type WhitelistedEmailInviteeConnectionArgs = {
  after?: InputMaybe<Scalars["String"]["input"]>;
  directed?: InputMaybe<Scalars["Boolean"]["input"]>;
  first?: InputMaybe<Scalars["Int"]["input"]>;
  sort?: InputMaybe<Array<WhitelistedEmailInviteeConnectionSort>>;
  where?: InputMaybe<WhitelistedEmailInviteeConnectionWhere>;
};

export type WhitelistedEmailAggregateSelection = {
  __typename?: "WhitelistedEmailAggregateSelection";
  count: Scalars["Int"]["output"];
  email: StringAggregateSelection;
};

export type WhitelistedEmailConnectInput = {
  invitee?: InputMaybe<WhitelistedEmailInviteeConnectFieldInput>;
};

export type WhitelistedEmailConnectWhere = {
  node: WhitelistedEmailWhere;
};

export type WhitelistedEmailCreateInput = {
  email: Scalars["String"]["input"];
  invitee?: InputMaybe<WhitelistedEmailInviteeFieldInput>;
};

export type WhitelistedEmailDeleteInput = {
  invitee?: InputMaybe<WhitelistedEmailInviteeDeleteFieldInput>;
};

export type WhitelistedEmailDisconnectInput = {
  invitee?: InputMaybe<WhitelistedEmailInviteeDisconnectFieldInput>;
};

export type WhitelistedEmailEdge = {
  __typename?: "WhitelistedEmailEdge";
  cursor: Scalars["String"]["output"];
  node: WhitelistedEmail;
};

export type WhitelistedEmailInviteeAggregateInput = {
  AND?: InputMaybe<Array<WhitelistedEmailInviteeAggregateInput>>;
  NOT?: InputMaybe<WhitelistedEmailInviteeAggregateInput>;
  OR?: InputMaybe<Array<WhitelistedEmailInviteeAggregateInput>>;
  count?: InputMaybe<Scalars["Int"]["input"]>;
  count_GT?: InputMaybe<Scalars["Int"]["input"]>;
  count_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  count_LT?: InputMaybe<Scalars["Int"]["input"]>;
  count_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  node?: InputMaybe<WhitelistedEmailInviteeNodeAggregationWhereInput>;
};

export type WhitelistedEmailInviteeConnectFieldInput = {
  connect?: InputMaybe<UserConnectInput>;
  /** Whether or not to overwrite any matching relationship with the new properties. */
  overwrite?: Scalars["Boolean"]["input"];
  where?: InputMaybe<UserConnectWhere>;
};

export type WhitelistedEmailInviteeConnection = {
  __typename?: "WhitelistedEmailInviteeConnection";
  edges: Array<WhitelistedEmailInviteeRelationship>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type WhitelistedEmailInviteeConnectionSort = {
  node?: InputMaybe<UserSort>;
};

export type WhitelistedEmailInviteeConnectionWhere = {
  AND?: InputMaybe<Array<WhitelistedEmailInviteeConnectionWhere>>;
  NOT?: InputMaybe<WhitelistedEmailInviteeConnectionWhere>;
  OR?: InputMaybe<Array<WhitelistedEmailInviteeConnectionWhere>>;
  node?: InputMaybe<UserWhere>;
};

export type WhitelistedEmailInviteeCreateFieldInput = {
  node: UserCreateInput;
};

export type WhitelistedEmailInviteeDeleteFieldInput = {
  delete?: InputMaybe<UserDeleteInput>;
  where?: InputMaybe<WhitelistedEmailInviteeConnectionWhere>;
};

export type WhitelistedEmailInviteeDisconnectFieldInput = {
  disconnect?: InputMaybe<UserDisconnectInput>;
  where?: InputMaybe<WhitelistedEmailInviteeConnectionWhere>;
};

export type WhitelistedEmailInviteeFieldInput = {
  connect?: InputMaybe<WhitelistedEmailInviteeConnectFieldInput>;
  create?: InputMaybe<WhitelistedEmailInviteeCreateFieldInput>;
};

export type WhitelistedEmailInviteeNodeAggregationWhereInput = {
  AND?: InputMaybe<Array<WhitelistedEmailInviteeNodeAggregationWhereInput>>;
  NOT?: InputMaybe<WhitelistedEmailInviteeNodeAggregationWhereInput>;
  OR?: InputMaybe<Array<WhitelistedEmailInviteeNodeAggregationWhereInput>>;
  _empty_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  _empty_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  _empty_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  countryCode_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  countryCode_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  created_MAX_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MAX_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_EQUAL?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_MIN_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  currentWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  currentWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  email_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  email_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  email_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  fullPhoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  gender_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  gender_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  gender_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  goalWeightLbs_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MAX_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_MIN_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LT?: InputMaybe<Scalars["Float"]["input"]>;
  goalWeightLbs_SUM_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleAuthTokens_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  googleCalendarAuthToken_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_AVERAGE_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LT?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_AVERAGE_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  inviteCount_MAX_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MAX_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_MIN_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LT?: InputMaybe<Scalars["Int"]["input"]>;
  inviteCount_SUM_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  modalOnboarding_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  moodlePassword_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  moodlePassword_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  name_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  name_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  name_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  otpPreference_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  otpPreference_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  password_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  password_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  password_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  phoneNumber_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  phoneNumber_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeCustomerId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  stripeSubscriptionId_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_AVERAGE_LENGTH_EQUAL?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_GTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LT?: InputMaybe<Scalars["Float"]["input"]>;
  username_AVERAGE_LENGTH_LTE?: InputMaybe<Scalars["Float"]["input"]>;
  username_LONGEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_LONGEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_EQUAL?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_GTE?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LT?: InputMaybe<Scalars["Int"]["input"]>;
  username_SHORTEST_LENGTH_LTE?: InputMaybe<Scalars["Int"]["input"]>;
};

export type WhitelistedEmailInviteeRelationship = {
  __typename?: "WhitelistedEmailInviteeRelationship";
  cursor: Scalars["String"]["output"];
  node: User;
};

export type WhitelistedEmailInviteeUpdateConnectionInput = {
  node?: InputMaybe<UserUpdateInput>;
};

export type WhitelistedEmailInviteeUpdateFieldInput = {
  connect?: InputMaybe<WhitelistedEmailInviteeConnectFieldInput>;
  create?: InputMaybe<WhitelistedEmailInviteeCreateFieldInput>;
  delete?: InputMaybe<WhitelistedEmailInviteeDeleteFieldInput>;
  disconnect?: InputMaybe<WhitelistedEmailInviteeDisconnectFieldInput>;
  update?: InputMaybe<WhitelistedEmailInviteeUpdateConnectionInput>;
  where?: InputMaybe<WhitelistedEmailInviteeConnectionWhere>;
};

export type WhitelistedEmailOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more WhitelistedEmailSort objects to sort WhitelistedEmails by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<WhitelistedEmailSort>>;
};

export type WhitelistedEmailRelationInput = {
  invitee?: InputMaybe<WhitelistedEmailInviteeCreateFieldInput>;
};

/** Fields to sort WhitelistedEmails by. The order in which sorts are applied is not guaranteed when specifying many fields in one WhitelistedEmailSort object. */
export type WhitelistedEmailSort = {
  email?: InputMaybe<SortDirection>;
};

export type WhitelistedEmailUpdateInput = {
  email?: InputMaybe<Scalars["String"]["input"]>;
  invitee?: InputMaybe<WhitelistedEmailInviteeUpdateFieldInput>;
};

export type WhitelistedEmailUserInviteeAggregationSelection = {
  __typename?: "WhitelistedEmailUserInviteeAggregationSelection";
  count: Scalars["Int"]["output"];
  node?: Maybe<WhitelistedEmailUserInviteeNodeAggregateSelection>;
};

export type WhitelistedEmailUserInviteeNodeAggregateSelection = {
  __typename?: "WhitelistedEmailUserInviteeNodeAggregateSelection";
  _empty: StringAggregateSelection;
  countryCode: StringAggregateSelection;
  created: DateTimeAggregateSelection;
  currentWeightLbs: FloatAggregateSelection;
  email: StringAggregateSelection;
  fullPhoneNumber: StringAggregateSelection;
  gender: StringAggregateSelection;
  goalWeightLbs: FloatAggregateSelection;
  googleAuthTokens: StringAggregateSelection;
  googleCalendarAuthToken: StringAggregateSelection;
  id: IdAggregateSelection;
  inviteCount: IntAggregateSelection;
  modalOnboarding: StringAggregateSelection;
  moodlePassword: StringAggregateSelection;
  name: StringAggregateSelection;
  otpPreference: StringAggregateSelection;
  password: StringAggregateSelection;
  phoneNumber: StringAggregateSelection;
  stripeCustomerId: StringAggregateSelection;
  stripeSubscriptionId: StringAggregateSelection;
  username: StringAggregateSelection;
};

export type WhitelistedEmailWhere = {
  AND?: InputMaybe<Array<WhitelistedEmailWhere>>;
  NOT?: InputMaybe<WhitelistedEmailWhere>;
  OR?: InputMaybe<Array<WhitelistedEmailWhere>>;
  email?: InputMaybe<Scalars["String"]["input"]>;
  email_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  email_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  email_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  email_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  invitee?: InputMaybe<UserWhere>;
  inviteeAggregate?: InputMaybe<WhitelistedEmailInviteeAggregateInput>;
  inviteeConnection?: InputMaybe<WhitelistedEmailInviteeConnectionWhere>;
  inviteeConnection_NOT?: InputMaybe<WhitelistedEmailInviteeConnectionWhere>;
  invitee_NOT?: InputMaybe<UserWhere>;
};

export type WhitelistedEmailsConnection = {
  __typename?: "WhitelistedEmailsConnection";
  edges: Array<WhitelistedEmailEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type Workout = {
  __typename?: "Workout";
  name?: Maybe<Scalars["String"]["output"]>;
  type?: Maybe<Scalars["String"]["output"]>;
};

export type WorkoutAggregateSelection = {
  __typename?: "WorkoutAggregateSelection";
  count: Scalars["Int"]["output"];
  name: StringAggregateSelection;
  type: StringAggregateSelection;
};

export type WorkoutCreateInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type WorkoutEdge = {
  __typename?: "WorkoutEdge";
  cursor: Scalars["String"]["output"];
  node: Workout;
};

export type WorkoutOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more WorkoutSort objects to sort Workouts by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<WorkoutSort>>;
};

/** Fields to sort Workouts by. The order in which sorts are applied is not guaranteed when specifying many fields in one WorkoutSort object. */
export type WorkoutSort = {
  name?: InputMaybe<SortDirection>;
  type?: InputMaybe<SortDirection>;
};

export type WorkoutUpdateInput = {
  name?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
};

export type WorkoutWhere = {
  AND?: InputMaybe<Array<WorkoutWhere>>;
  NOT?: InputMaybe<WorkoutWhere>;
  OR?: InputMaybe<Array<WorkoutWhere>>;
  name?: InputMaybe<Scalars["String"]["input"]>;
  name_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  name_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  name_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  name_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type?: InputMaybe<Scalars["String"]["input"]>;
  type_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  type_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  type_IN?: InputMaybe<Array<InputMaybe<Scalars["String"]["input"]>>>;
  type_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type WorkoutsConnection = {
  __typename?: "WorkoutsConnection";
  edges: Array<WorkoutEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type YearlySummariesConnection = {
  __typename?: "YearlySummariesConnection";
  edges: Array<YearlySummaryEdge>;
  pageInfo: PageInfo;
  totalCount: Scalars["Int"]["output"];
};

export type YearlySummary = {
  __typename?: "YearlySummary";
  created: Scalars["DateTime"]["output"];
  date: Scalars["String"]["output"];
  isArchived: Scalars["Boolean"]["output"];
  last_modified: Scalars["DateTime"]["output"];
  summary: Scalars["String"]["output"];
};

export type YearlySummaryAggregateSelection = {
  __typename?: "YearlySummaryAggregateSelection";
  count: Scalars["Int"]["output"];
  created: DateTimeAggregateSelection;
  date: StringAggregateSelection;
  last_modified: DateTimeAggregateSelection;
  summary: StringAggregateSelection;
};

export type YearlySummaryConnectWhere = {
  node: YearlySummaryWhere;
};

export type YearlySummaryCreateInput = {
  created: Scalars["DateTime"]["input"];
  date: Scalars["String"]["input"];
  isArchived: Scalars["Boolean"]["input"];
  last_modified: Scalars["DateTime"]["input"];
  summary: Scalars["String"]["input"];
};

export type YearlySummaryEdge = {
  __typename?: "YearlySummaryEdge";
  cursor: Scalars["String"]["output"];
  node: YearlySummary;
};

export type YearlySummaryOptions = {
  limit?: InputMaybe<Scalars["Int"]["input"]>;
  offset?: InputMaybe<Scalars["Int"]["input"]>;
  /** Specify one or more YearlySummarySort objects to sort YearlySummaries by. The sorts will be applied in the order in which they are arranged in the array. */
  sort?: InputMaybe<Array<YearlySummarySort>>;
};

/** Fields to sort YearlySummaries by. The order in which sorts are applied is not guaranteed when specifying many fields in one YearlySummarySort object. */
export type YearlySummarySort = {
  created?: InputMaybe<SortDirection>;
  date?: InputMaybe<SortDirection>;
  isArchived?: InputMaybe<SortDirection>;
  last_modified?: InputMaybe<SortDirection>;
  summary?: InputMaybe<SortDirection>;
};

export type YearlySummaryUpdateInput = {
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
};

export type YearlySummaryWhere = {
  AND?: InputMaybe<Array<YearlySummaryWhere>>;
  NOT?: InputMaybe<YearlySummaryWhere>;
  OR?: InputMaybe<Array<YearlySummaryWhere>>;
  created?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  created_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  created_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  date_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  date_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  date_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  date_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]["input"]>;
  last_modified?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_GTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_IN?: InputMaybe<Array<Scalars["DateTime"]["input"]>>;
  last_modified_LT?: InputMaybe<Scalars["DateTime"]["input"]>;
  last_modified_LTE?: InputMaybe<Scalars["DateTime"]["input"]>;
  summary?: InputMaybe<Scalars["String"]["input"]>;
  summary_CONTAINS?: InputMaybe<Scalars["String"]["input"]>;
  summary_ENDS_WITH?: InputMaybe<Scalars["String"]["input"]>;
  summary_IN?: InputMaybe<Array<Scalars["String"]["input"]>>;
  summary_STARTS_WITH?: InputMaybe<Scalars["String"]["input"]>;
};

export type LoginMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
}>;

export type LoginMutation = {
  __typename?: "Mutation";
  login?: {
    __typename?: "AuthPayloadResponse";
    token: string;
    user?: {
      __typename?: "AuthPayloadUser";
      id?: string | null;
      email?: string | null;
      isOnboarded?: boolean | null;
      isPro?: boolean | null;
      name?: string | null;
      isEmailVerified?: boolean | null;
      otpPreference?: string | null;
      countryCode?: string | null;
      phoneNumber?: string | null;
      fullPhoneNumber?: string | null;
      googleAuthTokens?: {
        __typename?: "GoogleAuthTokens";
        access_token?: string | null;
        refresh_token?: string | null;
        scope?: string | null;
        token_type?: string | null;
        expiry_date?: number | null;
      } | null;
    } | null;
  } | null;
};

export type LogoutMutationVariables = Exact<{ [key: string]: never }>;

export type LogoutMutation = {
  __typename?: "Mutation";
  logout: { __typename?: "ResponseType"; message?: string | null; success: boolean };
};

export type ResetPasswordMutationVariables = Exact<{
  newPassword: Scalars["String"]["input"];
  token: Scalars["String"]["input"];
}>;

export type ResetPasswordMutation = {
  __typename?: "Mutation";
  resetPassword?: { __typename?: "ResponseType"; message?: string | null; success: boolean } | null;
};

export type SignupMutationVariables = Exact<{
  email: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  password: Scalars["String"]["input"];
  gender?: InputMaybe<Scalars["String"]["input"]>;
  birthday: Scalars["String"]["input"];
  dailyEmailOptIn: Scalars["Boolean"]["input"];
  language: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type SignupMutation = {
  __typename?: "Mutation";
  signup?: {
    __typename?: "AuthPayloadResponse";
    token: string;
    user?: {
      __typename?: "AuthPayloadUser";
      id?: string | null;
      email?: string | null;
      name?: string | null;
      isPro?: boolean | null;
      isOnboarded?: boolean | null;
      otpPreference?: string | null;
      isEmailVerified?: boolean | null;
      fullPhoneNumber?: string | null;
      preferences?: { __typename?: "AuthPayloadUserPreferences"; language?: string | null } | null;
    } | null;
  } | null;
};

export type CareerIsLoadingMutationVariables = Exact<{
  isLoading: Scalars["Boolean"]["input"];
}>;

export type CareerIsLoadingMutation = {
  __typename?: "Mutation";
  careerIsLoading: { __typename?: "UserCareerLoadingResponse"; isCareerLoading: boolean };
};

export type CareerSubmitFormMutationVariables = Exact<{
  currentOccupation: Scalars["String"]["input"];
  careerPathName: Scalars["String"]["input"];
  careerGoal: Scalars["String"]["input"];
}>;

export type CareerSubmitFormMutation = { __typename?: "Mutation"; careerSubmitForm: boolean };

export type GenerateSuggestedCareersMutationVariables = Exact<{ [key: string]: never }>;

export type GenerateSuggestedCareersMutation = {
  __typename?: "Mutation";
  generateSuggestedCareers?: { __typename?: "Career"; name: string; id: string } | null;
};

export type SetCareerPathActiveMutationVariables = Exact<{
  careerId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type SetCareerPathActiveMutation = { __typename?: "Mutation"; setCareerPathActive: boolean };

export type DeleteAspectMutationVariables = Exact<{
  nodeId: Scalars["ID"]["input"];
}>;

export type DeleteAspectMutation = {
  __typename?: "Mutation";
  deleteAspect: { __typename?: "ResponseType"; message?: string | null; success: boolean };
};

export type DeleteMessageMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteMessageMutation = {
  __typename?: "Mutation";
  deleteMessage?: { __typename?: "Message"; id: string } | null;
};

export type AddAssignmentMutationVariables = Exact<{
  courseId: Scalars["ID"]["input"];
  moduleName: Scalars["String"]["input"];
  newAssignmentName: Scalars["String"]["input"];
  updatedHtml: Scalars["String"]["input"];
}>;

export type AddAssignmentMutation = {
  __typename?: "Mutation";
  addAssignment: { __typename?: "AddAssignmentResponse"; success: boolean; message?: string | null };
};

export type AddContentModuleMutationVariables = Exact<{
  courseId: Scalars["ID"]["input"];
  moduleName: Scalars["String"]["input"];
}>;

export type AddContentModuleMutation = {
  __typename?: "Mutation";
  addContentModule: { __typename?: "AddContentModuleResponse"; success: boolean; message?: string | null };
};

export type CourseCreationMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  standard: Scalars["String"]["input"];
  weeks: Scalars["Int"]["input"];
  objectives: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
  urls: Array<Scalars["String"]["input"]> | Scalars["String"]["input"];
}>;

export type CourseCreationMutation = {
  __typename?: "Mutation";
  courseCreation?: {
    __typename?: "CourseCreationResponse";
    success: boolean;
    message?: string | null;
    data?: {
      __typename?: "Curriculum";
      name: string;
      standard: string;
      weeks: number;
      objectives: Array<string>;
      created?: any | null;
      last_modified?: any | null;
      modules: Array<{
        __typename?: "Module";
        module_name: string;
        assignments: Array<{ __typename?: "ToolAssignment"; assignment_name: string; html: string }>;
      }>;
    } | null;
  } | null;
};

export type CourseIsLoadingMutationVariables = Exact<{
  isLoading: Scalars["Boolean"]["input"];
}>;

export type CourseIsLoadingMutation = {
  __typename?: "Mutation";
  courseIsLoading: { __typename?: "UserCourseLoadingResponse"; isCourseLoading: boolean };
};

export type DeleteContentAssignmentMutationVariables = Exact<{
  courseId: Scalars["ID"]["input"];
  moduleName: Scalars["String"]["input"];
  assignmentName: Scalars["String"]["input"];
}>;

export type DeleteContentAssignmentMutation = {
  __typename?: "Mutation";
  deleteContentAssignment: {
    __typename?: "DeleteContentAssignmentResponse";
    success: boolean;
    message?: string | null;
  };
};

export type DeleteContentModuleMutationVariables = Exact<{
  courseId: Scalars["ID"]["input"];
  moduleName: Scalars["String"]["input"];
}>;

export type DeleteContentModuleMutation = {
  __typename?: "Mutation";
  deleteContentModule: { __typename?: "DeleteContentModuleResponse"; success: boolean; message?: string | null };
};

export type DeleteCourseMutationVariables = Exact<{
  courseId: Scalars["ID"]["input"];
}>;

export type DeleteCourseMutation = {
  __typename?: "Mutation";
  deleteCourse: { __typename?: "DeleteCourseResponse"; success: boolean; message: string };
};

export type UpdateAssignmentMutationVariables = Exact<{
  courseId: Scalars["ID"]["input"];
  moduleName: Scalars["String"]["input"];
  currentAssignmentName: Scalars["String"]["input"];
  newAssignmentName: Scalars["String"]["input"];
  updatedHtml: Scalars["String"]["input"];
}>;

export type UpdateAssignmentMutation = {
  __typename?: "Mutation";
  updateAssignment: {
    __typename?: "UpdateAssignmentResponse";
    success: boolean;
    message?: string | null;
    data?: { __typename?: "ToolAssignment"; assignment_name: string; html: string } | null;
  };
};

export type UpdateContentModuleNameMutationVariables = Exact<{
  courseId: Scalars["ID"]["input"];
  oldModuleName: Scalars["String"]["input"];
  newModuleName: Scalars["String"]["input"];
}>;

export type UpdateContentModuleNameMutation = {
  __typename?: "Mutation";
  updateContentModuleName: {
    __typename?: "UpdateContentModuleNameResponse";
    success: boolean;
    message?: string | null;
  };
};

export type UploadDocumentsMutationVariables = Exact<{
  files: Array<Scalars["Upload"]["input"]> | Scalars["Upload"]["input"];
}>;

export type UploadDocumentsMutation = {
  __typename?: "Mutation";
  uploadDocuments?: { __typename?: "UploadDocumentsResponse"; success: boolean; message: string } | null;
};

export type UploadDocumentsToBucketMutationVariables = Exact<{
  files: Array<Scalars["Upload"]["input"]> | Scalars["Upload"]["input"];
}>;

export type UploadDocumentsToBucketMutation = {
  __typename?: "Mutation";
  uploadDocumentsToBucket?: {
    __typename?: "UploadDocumentsToBucketResponse";
    success: boolean;
    message: string;
    urls: Array<string>;
  } | null;
};

export type CreateAssignmentMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  dueDate: Scalars["String"]["input"];
  body?: InputMaybe<Scalars["String"]["input"]>;
  parentResourceId: Scalars["ID"]["input"];
}>;

export type CreateAssignmentMutation = {
  __typename?: "Mutation";
  createAssignment?: {
    __typename?: "AssignmentResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{
      __typename?: "Assignment";
      title: string;
      due_date?: string | null;
      body?: string | null;
    } | null> | null;
  } | null;
};

export type DeleteAssignmentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteAssignmentMutation = {
  __typename?: "Mutation";
  deleteAssignment?: { __typename?: "AssignmentResponse"; success?: boolean | null; message?: string | null } | null;
};

export type EditAssignmentMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
  dueDate?: InputMaybe<Scalars["String"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type EditAssignmentMutation = {
  __typename?: "Mutation";
  editAssignment?: {
    __typename?: "AssignmentResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{
      __typename?: "Assignment";
      title: string;
      due_date?: string | null;
      body?: string | null;
    } | null> | null;
  } | null;
};

export type DeleteCourseCascadeMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteCourseCascadeMutation = {
  __typename?: "Mutation";
  deleteCourseCascade?: { __typename?: "CourseResponse"; success?: boolean | null; message?: string | null } | null;
};

export type CreateCourseMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  learningStandards?: InputMaybe<Scalars["String"]["input"]>;
  lengthInWeeks?: InputMaybe<Scalars["Float"]["input"]>;
  objectives?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
  parentSchoolId: Scalars["ID"]["input"];
}>;

export type CreateCourseMutation = {
  __typename?: "Mutation";
  createCourse?: {
    __typename?: "CourseResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{
      __typename?: "Course";
      title?: string | null;
      learningStandards?: string | null;
      lengthInWeeks?: number | null;
      objectives?: Array<string> | null;
    } | null> | null;
  } | null;
};

export type UpdateCourseMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
  learningStandards?: InputMaybe<Scalars["String"]["input"]>;
  lengthInWeeks?: InputMaybe<Scalars["Float"]["input"]>;
  objectives?: InputMaybe<Array<Scalars["String"]["input"]> | Scalars["String"]["input"]>;
}>;

export type UpdateCourseMutation = {
  __typename?: "Mutation";
  updateCourse?: {
    __typename?: "CourseResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{
      __typename?: "Course";
      title?: string | null;
      learningStandards?: string | null;
      lengthInWeeks?: number | null;
      objectives?: Array<string> | null;
    } | null> | null;
  } | null;
};

export type DeleteModuleCascadeMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeleteModuleCascadeMutation = {
  __typename?: "Mutation";
  deleteModuleCascade?: { __typename?: "ModuleResponse"; success?: boolean | null; message?: string | null } | null;
};

export type CreateModuleMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  order: Scalars["Float"]["input"];
  parentCourseId: Scalars["ID"]["input"];
}>;

export type CreateModuleMutation = {
  __typename?: "Mutation";
  createModuleAndResource?: {
    __typename?: "ModuleResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{ __typename?: "Module"; title?: string | null; order?: number | null } | null> | null;
  } | null;
};

export type UpdateModuleMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
  order?: InputMaybe<Scalars["Float"]["input"]>;
}>;

export type UpdateModuleMutation = {
  __typename?: "Mutation";
  updateModule?: {
    __typename?: "ModuleResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{ __typename?: "Module"; title?: string | null; order?: number | null } | null> | null;
  } | null;
};

export type CreatePageMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  body?: InputMaybe<Scalars["String"]["input"]>;
  parentResourceId: Scalars["ID"]["input"];
}>;

export type CreatePageMutation = {
  __typename?: "Mutation";
  createPage?: {
    __typename?: "PageResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{ __typename?: "Page"; title: string; body?: string | null } | null> | null;
  } | null;
};

export type DeletePageMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type DeletePageMutation = {
  __typename?: "Mutation";
  deletePage?: { __typename?: "PageResponse"; success?: boolean | null; message?: string | null } | null;
};

export type UpdatePageMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  title?: InputMaybe<Scalars["String"]["input"]>;
  body?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type UpdatePageMutation = {
  __typename?: "Mutation";
  updatePage?: {
    __typename?: "PageResponse";
    success?: boolean | null;
    message?: string | null;
    data?: Array<{ __typename?: "Page"; title: string; body?: string | null } | null> | null;
  } | null;
};

export type GenerateKeywordsMutationVariables = Exact<{
  title: Scalars["String"]["input"];
  description: Scalars["String"]["input"];
}>;

export type GenerateKeywordsMutation = { __typename?: "Mutation"; generateYoutubeKeywords?: string | null };

export type MarkQuestCompleteMutationVariables = Exact<{
  chatId: Scalars["ID"]["input"];
  questId: Scalars["ID"]["input"];
}>;

export type MarkQuestCompleteMutation = {
  __typename?: "Mutation";
  markQuestComplete?: {
    __typename?: "Chat";
    id: string;
    assignedQuests?: Array<{
      __typename?: "AssignedQuest";
      id: string;
      title: string;
      isComplete: boolean;
    } | null> | null;
  } | null;
};

export type RefreshQuestMutationVariables = Exact<{
  questIdToReplace: Scalars["ID"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type RefreshQuestMutation = {
  __typename?: "Mutation";
  refreshQuest?: {
    __typename?: "RefreshQuestResponse";
    success: boolean;
    message: string;
    quests: Array<{
      __typename?: "AssignedQuest";
      id: string;
      title: string;
      description: string;
      isComplete: boolean;
    }>;
  } | null;
};

export type AudioToggleUserMutationVariables = Exact<{
  audioOn: Scalars["Boolean"]["input"];
}>;

export type AudioToggleUserMutation = {
  __typename?: "Mutation";
  audioToggleUser?: { __typename?: "AudioToggleUserResponse"; audioOn?: boolean | null } | null;
};

export type UpdateNotificationsMutationVariables = Exact<{
  dailyEmailOptIn: Scalars["Boolean"]["input"];
}>;

export type UpdateNotificationsMutation = {
  __typename?: "Mutation";
  updateNotifications?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type AddToWhitelistMutationVariables = Exact<{
  email: Scalars["String"]["input"];
}>;

export type AddToWhitelistMutation = {
  __typename?: "Mutation";
  addToWhitelist: { __typename?: "WhitelistedEmail"; email: string };
};

export type CompleteJourneyMutationVariables = Exact<{
  journeyId: Scalars["ID"]["input"];
}>;

export type CompleteJourneyMutation = {
  __typename?: "Mutation";
  completeJourney?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type DeleteJourneyMutationVariables = Exact<{
  journeyId: Scalars["ID"]["input"];
}>;

export type DeleteJourneyMutation = {
  __typename?: "Mutation";
  deleteJourney?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type JourneySubmitFormMutationVariables = Exact<{
  description: Scalars["String"]["input"];
  dueDate: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type JourneySubmitFormMutation = {
  __typename?: "Mutation";
  journeySubmitForm?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type CreateEducationChildrenMutationVariables = Exact<{
  label: NodeLabel;
  name: Scalars["String"]["input"];
}>;

export type CreateEducationChildrenMutation = {
  __typename?: "Mutation";
  createEducationChildren: {
    __typename?: "Generated";
    success: boolean;
    message?: string | null;
    data?: Array<{ __typename?: "Subfield"; name: string } | null> | null;
  };
};

export type EnrollUserInTrackMutationVariables = Exact<{
  topicName: Scalars["String"]["input"];
}>;

export type EnrollUserInTrackMutation = {
  __typename?: "Mutation";
  enrollUserInTrack: { __typename?: "ResponseType"; success: boolean; message?: string | null };
};

export type SetKnowledgeRelevanceMutationVariables = Exact<{
  nodeName: Scalars["String"]["input"];
  nodeLabel: Scalars["String"]["input"];
  isRelevant: Scalars["Boolean"]["input"];
}>;

export type SetKnowledgeRelevanceMutation = {
  __typename?: "Mutation";
  setKnowledgeRelevance: { __typename?: "ResponseType"; success: boolean; message?: string | null };
};

export type UpdateTopicMutationVariables = Exact<{
  operationType: Scalars["String"]["input"];
  topicName: Scalars["String"]["input"];
}>;

export type UpdateTopicMutation = {
  __typename?: "Mutation";
  updateTopic: { __typename?: "ResponseType"; success: boolean; message?: string | null };
};

export type UpdateLearningObjectiveCompletionMutationVariables = Exact<{
  id: Scalars["ID"]["input"];
  completed: Scalars["Boolean"]["input"];
}>;

export type UpdateLearningObjectiveCompletionMutation = {
  __typename?: "Mutation";
  updateLearningObjectiveCompletion: { __typename?: "ResponseType"; success: boolean; message?: string | null };
};

export type SetOnboardingStatusMutationVariables = Exact<{
  isOnboarded: Scalars["Boolean"]["input"];
}>;

export type SetOnboardingStatusMutation = {
  __typename?: "Mutation";
  setOnboardingStatus: { __typename?: "ResponseType"; message?: string | null; success: boolean };
};

export type UpdateUserModalOnboardingMutationVariables = Exact<{
  modalOnboarding: Scalars["String"]["input"];
}>;

export type UpdateUserModalOnboardingMutation = {
  __typename?: "Mutation";
  updateUserModalOnboarding?: {
    __typename?: "UpdateUserModalOnboardingResponse";
    modalOnboarding?: string | null;
  } | null;
};

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteAccountMutation = {
  __typename?: "Mutation";
  deleteAccount?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type ResetAccountMutationVariables = Exact<{ [key: string]: never }>;

export type ResetAccountMutation = {
  __typename?: "Mutation";
  resetAccount?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type AddTodoMutationVariables = Exact<{
  name: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  completed_date?: InputMaybe<Scalars["String"]["input"]>;
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringFrequency?: InputMaybe<Scalars["String"]["input"]>;
  recurringDaily?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringDayOfWeek?: InputMaybe<Scalars["String"]["input"]>;
  recurringDayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  timezone?: InputMaybe<Scalars["String"]["input"]>;
  isPartOfJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type AddTodoMutation = {
  __typename?: "Mutation";
  addTodo?: {
    __typename?: "Todo";
    id: string;
    name: string;
    description?: string | null;
    priority?: string | null;
    date?: any | null;
    completed_date?: any | null;
    isRecurring?: boolean | null;
    recurringFrequency?: string | null;
    recurringDaily?: boolean | null;
    recurringDayOfWeek?: string | null;
    recurringDayOfMonth?: number | null;
    recurringTime?: string | null;
    timeOfDay?: string | null;
    timezone?: string | null;
    isPartOfJourney?: boolean | null;
  } | null;
};

export type UpdateTodoMutationVariables = Exact<{
  id: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
  description?: InputMaybe<Scalars["String"]["input"]>;
  priority?: InputMaybe<Scalars["String"]["input"]>;
  date?: InputMaybe<Scalars["String"]["input"]>;
  completed_date?: InputMaybe<Scalars["String"]["input"]>;
  completed?: InputMaybe<Scalars["Boolean"]["input"]>;
  pointsReceived?: InputMaybe<Scalars["Int"]["input"]>;
  timezone: Scalars["String"]["input"];
  isRecurring?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringFrequency?: InputMaybe<Scalars["String"]["input"]>;
  recurringDaily?: InputMaybe<Scalars["Boolean"]["input"]>;
  recurringDayOfWeek?: InputMaybe<Scalars["String"]["input"]>;
  recurringDayOfMonth?: InputMaybe<Scalars["Int"]["input"]>;
  recurringTime?: InputMaybe<Scalars["String"]["input"]>;
  timeOfDay?: InputMaybe<Scalars["String"]["input"]>;
  isPartOfJourney?: InputMaybe<Scalars["Boolean"]["input"]>;
  journeyId?: InputMaybe<Scalars["ID"]["input"]>;
}>;

export type UpdateTodoMutation = {
  __typename?: "Mutation";
  updateTodo?: {
    __typename?: "Todo";
    id: string;
    name: string;
    description?: string | null;
    priority?: string | null;
    date?: any | null;
    completed_date?: any | null;
    completed: boolean;
    pointsReceived?: number | null;
    timezone?: string | null;
    isRecurring?: boolean | null;
    recurringFrequency?: string | null;
    recurringDaily?: boolean | null;
    recurringDayOfWeek?: string | null;
    recurringDayOfMonth?: number | null;
    recurringTime?: string | null;
    timeOfDay?: string | null;
    isPartOfJourney?: boolean | null;
    journeyId?: string | null;
  } | null;
};

export type SessionQueryVariables = Exact<{ [key: string]: never }>;

export type SessionQuery = {
  __typename?: "Query";
  validateSession: { __typename?: "SessionResponse"; isValid: boolean };
};

export type CareerByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type CareerByIdQuery = {
  __typename?: "Query";
  getCareerById?: {
    __typename?: "Career";
    id: string;
    name: string;
    brief_description?: string | null;
    description?: string | null;
    created?: any | null;
    last_modified?: any | null;
    path?: any | null;
    trends?: any | null;
    pros?: string | null;
    cons?: string | null;
    skills?: string | null;
    core_skills?: string | null;
    growth?: string | null;
    salary?: string | null;
    url?: string | null;
  } | null;
};

export type GetCareerHeaderQueryVariables = Exact<{ [key: string]: never }>;

export type GetCareerHeaderQuery = {
  __typename?: "Query";
  getCareerHeader: {
    __typename?: "CareerHeader";
    jobTitle?: string | null;
    careerGoal?: string | null;
    careerName?: string | null;
    careerId?: string | null;
  };
};

export type GetCareerTopicsQueryVariables = Exact<{
  careerId: Scalars["ID"]["input"];
}>;

export type GetCareerTopicsQuery = {
  __typename?: "Query";
  getCareerTopics?: Array<{
    __typename?: "Topic";
    name: string;
    description?: string | null;
    mastery?: number | null;
  } | null> | null;
};

export type GetCareersQueryVariables = Exact<{ [key: string]: never }>;

export type GetCareersQuery = {
  __typename?: "Query";
  getCareers?: Array<{
    __typename?: "Career";
    id: string;
    name: string;
    brief_description?: string | null;
    description?: string | null;
    created?: any | null;
    last_modified?: any | null;
    path?: any | null;
    trends?: any | null;
    pros?: string | null;
    cons?: string | null;
    skills?: string | null;
    core_skills?: string | null;
    growth?: string | null;
    salary?: string | null;
    url?: string | null;
  } | null> | null;
};

export type DailyLimitQueryVariables = Exact<{ [key: string]: never }>;

export type DailyLimitQuery = {
  __typename?: "Query";
  getDailyLimit?: { __typename?: "RateLimitResult"; dailyRequests?: number | null; result?: boolean | null } | null;
};

export type GetChatByContextAndParamsQueryVariables = Exact<{
  context: Scalars["String"]["input"];
  params?: InputMaybe<Scalars["JSON"]["input"]>;
}>;

export type GetChatByContextAndParamsQuery = {
  __typename?: "Query";
  getChatByContextAndParams?: {
    __typename?: "Chat";
    id: string;
    date?: string | null;
    name: string;
    userId: string;
    objectId?: string | null;
    context?: string | null;
    params?: any | null;
    conversation: Array<{
      __typename?: "Message";
      id: string;
      sender?: string | null;
      content?: string | null;
      type?: string | null;
      nodeId?: string | null;
      snackType?: string | null;
      parameters?: {
        __typename?: "ParametersType";
        lastGeneratedTopic?: string | null;
        lastGeneratedLabel?: string | null;
        questContext?: string | null;
        userResponse?: string | null;
      } | null;
      aspectDetails?: {
        __typename?: "AspectDetailsType";
        name?: string | null;
        category?: string | null;
        id?: number | null;
        friendId?: number | null;
        relationshipType?: string | null;
        connectionStrength?: number | null;
        frequencyOfInteraction?: string | null;
        lastInteractionDate?: any | null;
        sharedInterests?: Array<string | null> | null;
        sharedGoals?: Array<string | null> | null;
        sharedEventsAttended?: Array<string | null> | null;
        friendPersonalityTraits?: Array<string | null> | null;
        communicationChannel?: string | null;
        level?: string | null;
        startDate?: any | null;
        lastUpdated?: any | null;
        learningStyle?: string | null;
        language?: string | null;
        timeOfDay?: string | null;
        learningDuration?: number | null;
        contentType?: string | null;
        notificationPreferences?: string | null;
        type?: string | null;
        completionStatus?: string | null;
        deadline?: any | null;
        priority?: string | null;
        value?: number | null;
        description?: string | null;
        date?: any | null;
        importance?: number | null;
        status?: string | null;
        endDate?: any | null;
        text?: string | null;
        sentiment?: string | null;
        context?: string | null;
        intensity?: number | null;
        timestamp?: any | null;
        trigger?: string | null;
        calories?: number | null;
        carbohydrates_grams?: number | null;
        fat_grams?: number | null;
        fiber_grams?: number | null;
        protein_grams?: number | null;
        serving_size?: string | null;
        sodium_mg?: number | null;
        sugars_grams?: number | null;
        totalCalories?: number | null;
        totalCarbs?: number | null;
        totalFats?: number | null;
        totalProteins?: number | null;
        totalCaloriesBurned?: number | null;
        totalDurationMinutes?: number | null;
        totalWorkouts?: number | null;
        water_intake_oz?: number | null;
      } | null;
    }>;
  } | null;
};

export type GetChatByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetChatByIdQuery = {
  __typename?: "Query";
  getChatById?: {
    __typename?: "Chat";
    id: string;
    date?: string | null;
    name: string;
    userId: string;
    objectId?: string | null;
    context?: string | null;
    params?: any | null;
    conversation: Array<{
      __typename?: "Message";
      id: string;
      sender?: string | null;
      content?: string | null;
      type?: string | null;
      nodeId?: string | null;
      snackType?: string | null;
      parameters?: {
        __typename?: "ParametersType";
        lastGeneratedTopic?: string | null;
        lastGeneratedLabel?: string | null;
        questContext?: string | null;
        userResponse?: string | null;
      } | null;
      aspectDetails?: {
        __typename?: "AspectDetailsType";
        name?: string | null;
        category?: string | null;
        id?: number | null;
        friendId?: number | null;
        relationshipType?: string | null;
        connectionStrength?: number | null;
        frequencyOfInteraction?: string | null;
        lastInteractionDate?: any | null;
        sharedInterests?: Array<string | null> | null;
        sharedGoals?: Array<string | null> | null;
        sharedEventsAttended?: Array<string | null> | null;
        friendPersonalityTraits?: Array<string | null> | null;
        communicationChannel?: string | null;
        level?: string | null;
        startDate?: any | null;
        lastUpdated?: any | null;
        learningStyle?: string | null;
        language?: string | null;
        timeOfDay?: string | null;
        learningDuration?: number | null;
        contentType?: string | null;
        notificationPreferences?: string | null;
        type?: string | null;
        completionStatus?: string | null;
        deadline?: any | null;
        priority?: string | null;
        value?: number | null;
        description?: string | null;
        date?: any | null;
        importance?: number | null;
        status?: string | null;
        endDate?: any | null;
        text?: string | null;
        sentiment?: string | null;
        context?: string | null;
        intensity?: number | null;
        timestamp?: any | null;
        trigger?: string | null;
        calories?: number | null;
        carbohydrates_grams?: number | null;
        fat_grams?: number | null;
        fiber_grams?: number | null;
        protein_grams?: number | null;
        serving_size?: string | null;
        sodium_mg?: number | null;
        sugars_grams?: number | null;
        totalCalories?: number | null;
        totalCarbs?: number | null;
        totalFats?: number | null;
        totalProteins?: number | null;
        totalCaloriesBurned?: number | null;
        totalDurationMinutes?: number | null;
        totalWorkouts?: number | null;
        water_intake_oz?: number | null;
      } | null;
    }>;
  } | null;
};

export type GetChatByObjectIdQueryVariables = Exact<{
  objectId: Scalars["ID"]["input"];
  context: Scalars["String"]["input"];
}>;

export type GetChatByObjectIdQuery = {
  __typename?: "Query";
  getChatByObjectId?: {
    __typename?: "Chat";
    id: string;
    date?: string | null;
    name: string;
    userId: string;
    objectId?: string | null;
    context?: string | null;
    params?: any | null;
    conversation: Array<{
      __typename?: "Message";
      id: string;
      sender?: string | null;
      content?: string | null;
      type?: string | null;
      nodeId?: string | null;
      snackType?: string | null;
      parameters?: {
        __typename?: "ParametersType";
        lastGeneratedTopic?: string | null;
        lastGeneratedLabel?: string | null;
        questContext?: string | null;
        userResponse?: string | null;
      } | null;
      aspectDetails?: {
        __typename?: "AspectDetailsType";
        name?: string | null;
        category?: string | null;
        id?: number | null;
        friendId?: number | null;
        relationshipType?: string | null;
        connectionStrength?: number | null;
        frequencyOfInteraction?: string | null;
        lastInteractionDate?: any | null;
        sharedInterests?: Array<string | null> | null;
        sharedGoals?: Array<string | null> | null;
        sharedEventsAttended?: Array<string | null> | null;
        friendPersonalityTraits?: Array<string | null> | null;
        communicationChannel?: string | null;
        level?: string | null;
        startDate?: any | null;
        lastUpdated?: any | null;
        learningStyle?: string | null;
        language?: string | null;
        timeOfDay?: string | null;
        learningDuration?: number | null;
        contentType?: string | null;
        notificationPreferences?: string | null;
        type?: string | null;
        completionStatus?: string | null;
        deadline?: any | null;
        priority?: string | null;
        value?: number | null;
        description?: string | null;
        date?: any | null;
        importance?: number | null;
        status?: string | null;
        endDate?: any | null;
        text?: string | null;
        sentiment?: string | null;
        context?: string | null;
        intensity?: number | null;
        timestamp?: any | null;
        trigger?: string | null;
        calories?: number | null;
        carbohydrates_grams?: number | null;
        fat_grams?: number | null;
        fiber_grams?: number | null;
        protein_grams?: number | null;
        serving_size?: string | null;
        sodium_mg?: number | null;
        sugars_grams?: number | null;
        totalCalories?: number | null;
        totalCarbs?: number | null;
        totalFats?: number | null;
        totalProteins?: number | null;
        totalCaloriesBurned?: number | null;
        totalDurationMinutes?: number | null;
        totalWorkouts?: number | null;
        water_intake_oz?: number | null;
      } | null;
    }>;
  } | null;
};

export type GetChatsQueryVariables = Exact<{ [key: string]: never }>;

export type GetChatsQuery = {
  __typename?: "Query";
  getChats?: Array<{
    __typename?: "Chat";
    id: string;
    date?: string | null;
    name: string;
    userId: string;
    objectId?: string | null;
    context?: string | null;
    params?: any | null;
    url?: string | null;
  } | null> | null;
};

export type TodayChatQueryVariables = Exact<{
  timezone: Scalars["String"]["input"];
}>;

export type TodayChatQuery = {
  __typename?: "Query";
  getTodayChat?: {
    __typename?: "Chat";
    id: string;
    date?: string | null;
    name: string;
    timezone: string;
    objectId?: string | null;
    context?: string | null;
    params?: any | null;
    userId: string;
    conversation: Array<{
      __typename?: "Message";
      id: string;
      sender?: string | null;
      content?: string | null;
      type?: string | null;
      nodeId?: string | null;
      snackType?: string | null;
      parameters?: {
        __typename?: "ParametersType";
        lastGeneratedTopic?: string | null;
        lastGeneratedLabel?: string | null;
        questContext?: string | null;
        userResponse?: string | null;
      } | null;
      aspectDetails?: {
        __typename?: "AspectDetailsType";
        name?: string | null;
        category?: string | null;
        id?: number | null;
        friendId?: number | null;
        relationshipType?: string | null;
        connectionStrength?: number | null;
        frequencyOfInteraction?: string | null;
        lastInteractionDate?: any | null;
        sharedInterests?: Array<string | null> | null;
        sharedGoals?: Array<string | null> | null;
        sharedEventsAttended?: Array<string | null> | null;
        friendPersonalityTraits?: Array<string | null> | null;
        communicationChannel?: string | null;
        level?: string | null;
        startDate?: any | null;
        lastUpdated?: any | null;
        learningStyle?: string | null;
        language?: string | null;
        timeOfDay?: string | null;
        learningDuration?: number | null;
        contentType?: string | null;
        notificationPreferences?: string | null;
        type?: string | null;
        completionStatus?: string | null;
        deadline?: any | null;
        priority?: string | null;
        value?: number | null;
        description?: string | null;
        date?: any | null;
        importance?: number | null;
        status?: string | null;
        endDate?: any | null;
        text?: string | null;
        sentiment?: string | null;
        context?: string | null;
        intensity?: number | null;
        timestamp?: any | null;
        trigger?: string | null;
        calories?: number | null;
        carbohydrates_grams?: number | null;
        fat_grams?: number | null;
        fiber_grams?: number | null;
        protein_grams?: number | null;
        serving_size?: string | null;
        sodium_mg?: number | null;
        sugars_grams?: number | null;
        totalCalories?: number | null;
        totalCarbs?: number | null;
        totalFats?: number | null;
        totalProteins?: number | null;
        totalCaloriesBurned?: number | null;
        totalDurationMinutes?: number | null;
        totalWorkouts?: number | null;
        water_intake_oz?: number | null;
      } | null;
    }>;
  } | null;
};

export type GetContentByIdQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetContentByIdQuery = {
  __typename?: "Query";
  getContentById?: {
    __typename?: "Curriculum";
    id: string;
    name: string;
    standard: string;
    weeks: number;
    objectives: Array<string>;
    created?: any | null;
    last_modified?: any | null;
    modules: Array<{
      __typename?: "Module";
      module_name: string;
      assignments: Array<{ __typename?: "ToolAssignment"; assignment_name: string; html: string }>;
    }>;
  } | null;
};

export type GetGeneratedContentQueryVariables = Exact<{ [key: string]: never }>;

export type GetGeneratedContentQuery = {
  __typename?: "Query";
  getGeneratedContent?: Array<{
    __typename?: "Curriculum";
    id: string;
    name: string;
    standard: string;
    weeks: number;
    objectives: Array<string>;
    created?: any | null;
    last_modified?: any | null;
    modules: Array<{
      __typename?: "Module";
      module_name: string;
      assignments: Array<{ __typename?: "ToolAssignment"; assignment_name: string; html: string }>;
    }>;
  } | null> | null;
};

export type GetCourseQueryVariables = Exact<{
  id: Scalars["ID"]["input"];
}>;

export type GetCourseQuery = {
  __typename?: "Query";
  getCourse?: {
    __typename?: "CourseResponse";
    data?: Array<{
      __typename?: "Course";
      id: string;
      title?: string | null;
      learningStandards?: string | null;
      lengthInWeeks?: number | null;
      objectives?: Array<string> | null;
      last_modified?: any | null;
      modules: Array<{
        __typename?: "Module";
        id: string;
        title?: string | null;
        order?: number | null;
        lastModified?: any | null;
        resources: Array<{
          __typename?: "Resource";
          id: string;
          assignments: Array<{
            __typename?: "Assignment";
            id: string;
            title: string;
            body?: string | null;
            due_date?: string | null;
            lastModified?: any | null;
          }>;
          pages: Array<{
            __typename?: "Page";
            id: string;
            title: string;
            body?: string | null;
            lastModified?: any | null;
          }>;
        }>;
      }>;
    } | null> | null;
  } | null;
};

export type GetAllCoursesBySchoolQueryVariables = Exact<{
  schoolId: Scalars["ID"]["input"];
}>;

export type GetAllCoursesBySchoolQuery = {
  __typename?: "Query";
  getAllCoursesBySchool?: {
    __typename?: "CourseResponse";
    data?: Array<{
      __typename?: "Course";
      id: string;
      title?: string | null;
      learningStandards?: string | null;
      lengthInWeeks?: number | null;
      objectives?: Array<string> | null;
      last_modified?: any | null;
    } | null> | null;
  } | null;
};

export type SuggestionsQueryVariables = Exact<{ [key: string]: never }>;

export type SuggestionsQuery = {
  __typename?: "Query";
  getSuggestions?: Array<{
    __typename?: "Suggestion";
    type: string;
    content: string;
    integrationId?: string | null;
    classId?: string | null;
    assignmentId?: string | null;
    journeyId?: string | null;
    trackName?: string | null;
  } | null> | null;
};

export type LevelDetailsQueryVariables = Exact<{ [key: string]: never }>;

export type LevelDetailsQuery = {
  __typename?: "Query";
  levelDetails?: {
    __typename?: "LevelDetails";
    name?: string | null;
    currentXP: number;
    level: number;
    xpToNextLevel: number;
    startingXPForCurrentLevel: number;
    progressPercentage: number;
  } | null;
};

export type TodayGameQueryVariables = Exact<{
  timezone: Scalars["String"]["input"];
}>;

export type TodayGameQuery = {
  __typename?: "Query";
  getTodayGame?: {
    __typename?: "Game";
    chatId?: string | null;
    refreshCounter?: number | null;
    dailyTaskGoalForToday?: number | null;
    dailyTaskCompletedForToday?: number | null;
    dailyLearningModulesGoalForToday?: number | null;
    dailyLearningModulesCompletedForToday?: number | null;
    dailyCalorieGoalForToday?: number | null;
    dailyCalorieCompletedForToday?: number | null;
    assignedQuests?: Array<{
      __typename?: "AssignedQuest";
      id: string;
      title: string;
      description: string;
      isComplete: boolean;
    } | null> | null;
  } | null;
};

export type DailyExerciseTotalsByDateQueryVariables = Exact<{
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type DailyExerciseTotalsByDateQuery = {
  __typename?: "Query";
  getDailyExerciseTotalsByDate?: {
    __typename?: "DailyExerciseTotals";
    totalCaloriesBurned?: number | null;
    totalDurationMinutes?: number | null;
    totalWorkouts?: number | null;
    workoutsList?: Array<{ __typename?: "Workout"; name?: string | null; type?: string | null } | null> | null;
  } | null;
};

export type DailyNutritionTotalsByDateQueryVariables = Exact<{
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type DailyNutritionTotalsByDateQuery = {
  __typename?: "Query";
  getDailyNutritionTotalsByDate?: {
    __typename?: "DailyNutritionTotals";
    totalCalories?: number | null;
    totalCarbs?: number | null;
    totalFats?: number | null;
    totalProteins?: number | null;
  } | null;
};

export type FoodByDateQueryVariables = Exact<{
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type FoodByDateQuery = {
  __typename?: "Query";
  getFoodsByDate?: Array<{
    __typename?: "Food";
    name: string;
    calories?: number | null;
    carbohydrates_grams?: number | null;
    fat_grams?: number | null;
    fiber_grams?: number | null;
    protein_grams?: number | null;
    serving_size?: string | null;
    sodium_mg?: number | null;
    sugars_grams?: number | null;
  } | null> | null;
};

export type WaterIntakeByDateQueryVariables = Exact<{
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type WaterIntakeByDateQuery = {
  __typename?: "Query";
  getWaterIntakeByDate?: {
    __typename?: "WaterIntakeSummary";
    totalWaterIntake: number;
    goalOz: number;
    percentageTowardsGoal: number;
  } | null;
};

export type InviteCountQueryVariables = Exact<{ [key: string]: never }>;

export type InviteCountQuery = {
  __typename?: "Query";
  userInviteCount?: { __typename?: "InviteCountResponse"; count: number } | null;
  me?: {
    __typename?: "MeResponse";
    name?: string | null;
    username?: string | null;
    gender?: string | null;
    isOnboarded?: boolean | null;
    isPro?: boolean | null;
    phoneNumber?: string | null;
    isEmailVerified?: boolean | null;
  } | null;
};

export type DateForChatQueryVariables = Exact<{
  date: Scalars["String"]["input"];
  timezone: Scalars["String"]["input"];
}>;

export type DateForChatQuery = {
  __typename?: "Query";
  getChatForDate?: {
    __typename?: "Chat";
    id: string;
    date?: string | null;
    timezone: string;
    userId: string;
    conversation: Array<{
      __typename?: "Message";
      id: string;
      sender?: string | null;
      content?: string | null;
      type?: string | null;
      nodeId?: string | null;
      snackType?: string | null;
      parameters?: {
        __typename?: "ParametersType";
        lastGeneratedTopic?: string | null;
        lastGeneratedLabel?: string | null;
        questContext?: string | null;
        userResponse?: string | null;
      } | null;
      aspectDetails?: {
        __typename?: "AspectDetailsType";
        name?: string | null;
        category?: string | null;
        id?: number | null;
        friendId?: number | null;
        relationshipType?: string | null;
        connectionStrength?: number | null;
        frequencyOfInteraction?: string | null;
        lastInteractionDate?: any | null;
        sharedInterests?: Array<string | null> | null;
        sharedGoals?: Array<string | null> | null;
        sharedEventsAttended?: Array<string | null> | null;
        friendPersonalityTraits?: Array<string | null> | null;
        communicationChannel?: string | null;
        level?: string | null;
        startDate?: any | null;
        lastUpdated?: any | null;
        learningStyle?: string | null;
        language?: string | null;
        timeOfDay?: string | null;
        learningDuration?: number | null;
        contentType?: string | null;
        notificationPreferences?: string | null;
        type?: string | null;
        completionStatus?: string | null;
        deadline?: any | null;
        priority?: string | null;
        value?: number | null;
        description?: string | null;
        date?: any | null;
        importance?: number | null;
        status?: string | null;
        endDate?: any | null;
        text?: string | null;
        sentiment?: string | null;
        context?: string | null;
        intensity?: number | null;
        timestamp?: any | null;
        trigger?: string | null;
        calories?: number | null;
        carbohydrates_grams?: number | null;
        fat_grams?: number | null;
        fiber_grams?: number | null;
        protein_grams?: number | null;
        serving_size?: string | null;
        sodium_mg?: number | null;
        sugars_grams?: number | null;
        totalCalories?: number | null;
        totalCarbs?: number | null;
        totalFats?: number | null;
        totalProteins?: number | null;
        totalCaloriesBurned?: number | null;
        totalDurationMinutes?: number | null;
        totalWorkouts?: number | null;
        water_intake_oz?: number | null;
      } | null;
    }>;
  } | null;
};

export type GetJourneyByIdQueryVariables = Exact<{
  journeyId: Scalars["ID"]["input"];
}>;

export type GetJourneyByIdQuery = {
  __typename?: "Query";
  getJourneyById?: {
    __typename?: "GetJourneyByIdResponse";
    id: string;
    name: string;
    description?: string | null;
    dueDate?: string | null;
    url?: string | null;
    created?: string | null;
    lastModified?: string | null;
    tasks?: Array<{
      __typename?: "Todo";
      id: string;
      name: string;
      description?: string | null;
      priority?: string | null;
      date?: any | null;
      completed: boolean;
      isRecurring?: boolean | null;
      recurringFrequency?: string | null;
      recurringDaily?: boolean | null;
      recurringDayOfWeek?: string | null;
      recurringDayOfMonth?: number | null;
      recurringTime?: string | null;
      timeOfDay?: string | null;
      timezone?: string | null;
      isPartOfJourney?: boolean | null;
      journeyId?: string | null;
    } | null> | null;
  } | null;
};

export type GetJourneysQueryVariables = Exact<{ [key: string]: never }>;

export type GetJourneysQuery = {
  __typename?: "Query";
  getJourneys?: Array<{
    __typename?: "GetJourneysResponse";
    id: string;
    name: string;
    description?: string | null;
    url?: string | null;
    created?: string | null;
    lastModified?: string | null;
    dueDate?: string | null;
    totalTasks?: number | null;
    completedTasks?: number | null;
  } | null> | null;
};

export type FieldsQueryVariables = Exact<{ [key: string]: never }>;

export type FieldsQuery = {
  __typename?: "Query";
  getFields: Array<{
    __typename?: "Field";
    name: string;
    id: string;
    mastery?: number | null;
    isRelevant?: boolean | null;
    imageUrl?: string | null;
  } | null>;
};

export type GetLearningTreeQueryVariables = Exact<{
  level: Scalars["String"]["input"];
  name: Scalars["String"]["input"];
}>;

export type GetLearningTreeQuery = {
  __typename?: "Query";
  getLearningTree?: {
    __typename?: "LearningTree";
    FieldName?: string | null;
    SubfieldName?: string | null;
    TopicName?: string | null;
    ChapterName?: string | null;
    SubchapterName?: string | null;
  } | null;
};

export type NodeGraphQueryVariables = Exact<{
  nodeName: Scalars["String"]["input"];
  nodeLabel: NodeLabel;
}>;

export type NodeGraphQuery = {
  __typename?: "Query";
  getNodeChildren: Array<{
    __typename?: "LearningNode";
    name: string;
    description?: string | null;
    id: string;
    mastery?: number | null;
    label?: string | null;
    unitsCount?: number | null;
    imageUrl?: string | null;
  } | null>;
  getNode?: {
    __typename?: "LearningNode";
    name: string;
    description?: string | null;
    id: string;
    mastery?: number | null;
    label?: string | null;
    imageUrl?: string | null;
  } | null;
  getNodeParents: Array<{
    __typename?: "LearningNode";
    name: string;
    id: string;
    mastery?: number | null;
    label?: string | null;
  } | null>;
};

export type RecentTracksQueryVariables = Exact<{
  filterByCategory?: InputMaybe<Scalars["String"]["input"]>;
}>;

export type RecentTracksQuery = {
  __typename?: "Query";
  getRecentTracks?: Array<{
    __typename?: "RecentTrack";
    name: string;
    description?: string | null;
    totalUnits?: number | null;
    completedUnits?: number | null;
    parentFields?: Array<string | null> | null;
    parentSubfield?: string | null;
    mastery?: number | null;
    imageUrl?: string | null;
  } | null> | null;
};

export type SiblingSubchaptersQueryVariables = Exact<{
  limit: Scalars["Int"]["input"];
  chapterName: Scalars["String"]["input"];
  subchapterName: Scalars["String"]["input"];
}>;

export type SiblingSubchaptersQuery = {
  __typename?: "Query";
  getSiblingSubchapters?: Array<{
    __typename?: "HierarchyNode";
    name: string;
    label: string;
    mastery?: number | null;
  } | null> | null;
};

export type SubchapterPrerequisitesQueryVariables = Exact<{
  subchapterName: Scalars["String"]["input"];
  limit?: InputMaybe<Scalars["Int"]["input"]>;
}>;

export type SubchapterPrerequisitesQuery = {
  __typename?: "Query";
  getSubchapterPrerequisites?: Array<{
    __typename?: "HierarchyNode";
    label: string;
    name: string;
    mastery?: number | null;
  } | null> | null;
};

export type TableOfContentsQueryVariables = Exact<{
  learningNodeNames: Array<InputMaybe<Scalars["String"]["input"]>> | InputMaybe<Scalars["String"]["input"]>;
  hierarchyLabel: Scalars["String"]["input"];
}>;

export type TableOfContentsQuery = {
  __typename?: "Query";
  getTableOfContents?: Array<{
    __typename?: "Hierarchy";
    name: string;
    mastery: number;
    id?: string | null;
    isRelevant: boolean;
    parentName: string;
    description?: string | null;
    completed?: number | null;
    index?: number | null;
  } | null> | null;
};

export type TrackOverviewQueryVariables = Exact<{
  topicName: Scalars["String"]["input"];
}>;

export type TrackOverviewQuery = {
  __typename?: "Query";
  getTrackOverview?: {
    __typename?: "TrackOverview";
    name?: string | null;
    description?: string | null;
    objectives?: Array<string | null> | null;
    skillRequirements?: Array<string | null> | null;
    otherRequirements?: Array<string | null> | null;
    otherRequirementsTitle?: string | null;
    lessons?: Array<{ __typename?: "Lesson"; name?: string | null; description?: string | null } | null> | null;
    prerequisites?: Array<{
      __typename?: "Prerequisite";
      name?: string | null;
      subfield?: string | null;
      field?: string | null;
      mastery?: number | null;
    } | null> | null;
  } | null;
};

export type UserOverallProgressQueryVariables = Exact<{ [key: string]: never }>;

export type UserOverallProgressQuery = {
  __typename?: "Query";
  getUserOverallProgress: Array<{
    __typename?: "UserProgress";
    totalMastery?: number | null;
    lastModified: string;
    child: { __typename?: "ChildInfo"; name: string; label: string };
    parent?: { __typename?: "ChildInfo"; label: string; name: string } | null;
  }>;
};

export type UserProgressQueryVariables = Exact<{
  nodeName: Scalars["String"]["input"];
  nodeLabel: NodeLabel;
}>;

export type UserProgressQuery = {
  __typename?: "Query";
  getUserProgress: Array<{
    __typename?: "UserProgress";
    totalMastery?: number | null;
    lastModified: string;
    child: {
      __typename?: "ChildInfo";
      name: string;
      label: string;
      totalTracks?: number | null;
      completedTracks?: number | null;
    };
  }>;
};

export type GetFirstSubchapterByTopicNameQueryVariables = Exact<{
  topicName: Scalars["String"]["input"];
}>;

export type GetFirstSubchapterByTopicNameQuery = {
  __typename?: "Query";
  getFirstSubchapterByTopicName: {
    __typename?: "SubchapterDetails";
    success: boolean;
    message?: string | null;
    id?: string | null;
    name?: string | null;
    description?: string | null;
    objectives?: Array<{
      __typename?: "LearningObjective";
      id: string;
      title: string;
      description: string;
      completed?: number | null;
    } | null> | null;
  };
};

export type GetLearningObjectiveByIdQueryVariables = Exact<{
  loId: Scalars["ID"]["input"];
}>;

export type GetLearningObjectiveByIdQuery = {
  __typename?: "Query";
  getLearningObjectiveById: {
    __typename?: "LearningObjectiveResponse";
    success: boolean;
    message?: string | null;
    id?: string | null;
    title?: string | null;
    description?: string | null;
    completed?: number | null;
  };
};

export type GetLearningObjectivesBySubchapterQueryVariables = Exact<{
  learningObjectiveId: Scalars["ID"]["input"];
}>;

export type GetLearningObjectivesBySubchapterQuery = {
  __typename?: "Query";
  getLearningObjectivesBySubchapter?: Array<{
    __typename?: "LearningObjective";
    id: string;
    title: string;
    description: string;
    index?: number | null;
    completed?: number | null;
  } | null> | null;
};

export type SubchapterByIdQueryVariables = Exact<{
  name: Scalars["String"]["input"];
}>;

export type SubchapterByIdQuery = {
  __typename?: "Query";
  getSubchapterById?: {
    __typename?: "SubchapterDetails";
    id?: string | null;
    name?: string | null;
    description?: string | null;
    totalMastery?: number | null;
    objectives?: Array<{
      __typename?: "LearningObjective";
      id: string;
      title: string;
      description: string;
      completed?: number | null;
    } | null> | null;
  } | null;
};

export type GetMasteryQueryVariables = Exact<{
  learningObjectiveId: Scalars["ID"]["input"];
}>;

export type GetMasteryQuery = {
  __typename?: "Query";
  getUserLearningObjectiveMastery?: {
    __typename?: "MasteryResult";
    learningObjectiveId?: string | null;
    userId?: string | null;
    totalMastery?: number | null;
  } | null;
};

export type ExportCsvQueryVariables = Exact<{ [key: string]: never }>;

export type ExportCsvQuery = {
  __typename?: "Query";
  exportCsv: { __typename?: "ExportCsvResponse"; success: boolean; csvData?: string | null; message?: string | null };
};

export type AssignmentQueryVariables = Exact<{
  assignmentId: Scalars["ID"]["input"];
}>;

export type AssignmentQuery = {
  __typename?: "Query";
  getAssignment?: {
    __typename?: "GetAssignmentResponse";
    id: string;
    title: string;
    description?: string | null;
    dueDate?: string | null;
    url?: string | null;
    objectives?: Array<{
      __typename?: "GetAssignmentObjectiveResponse";
      id: string;
      objective?: string | null;
      status?: string | null;
    } | null> | null;
  } | null;
};

export type AssignmentsQueryVariables = Exact<{ [key: string]: never }>;

export type AssignmentsQuery = {
  __typename?: "Query";
  getAssignments?: Array<{
    __typename?: "GetAssignmentResponse";
    id: string;
    title: string;
    description?: string | null;
    dueDate?: string | null;
    url?: string | null;
    objectives?: Array<{
      __typename?: "GetAssignmentObjectiveResponse";
      id: string;
      objective?: string | null;
      status?: string | null;
    } | null> | null;
  } | null> | null;
};

export type SchoolsQueryVariables = Exact<{ [key: string]: never }>;

export type SchoolsQuery = {
  __typename?: "Query";
  getSchools?: Array<{
    __typename?: "School";
    id: string;
    providerName?: string | null;
    teamName?: string | null;
    icon?: string | null;
  } | null> | null;
};

export type DeleteTodoMutationVariables = Exact<{
  id: Scalars["String"]["input"];
}>;

export type DeleteTodoMutation = {
  __typename?: "Mutation";
  deleteTodo?: { __typename?: "ResponseType"; success: boolean; message?: string | null } | null;
};

export type RecentTodosQueryVariables = Exact<{ [key: string]: never }>;

export type RecentTodosQuery = {
  __typename?: "Query";
  recentTodos?: Array<{
    __typename?: "Todo";
    id: string;
    name: string;
    description?: string | null;
    priority?: string | null;
    date?: any | null;
    completed: boolean;
    isRecurring?: boolean | null;
    recurringFrequency?: string | null;
    recurringDaily?: boolean | null;
    recurringDayOfWeek?: string | null;
    recurringDayOfMonth?: number | null;
    recurringTime?: string | null;
    timeOfDay?: string | null;
    timezone?: string | null;
    isPartOfJourney?: boolean | null;
    journeyId?: string | null;
  } | null> | null;
};

export type TodosQueryVariables = Exact<{ [key: string]: never }>;

export type TodosQuery = {
  __typename?: "Query";
  todos?: Array<{
    __typename?: "Todo";
    id: string;
    name: string;
    description?: string | null;
    priority?: string | null;
    date?: any | null;
    completed: boolean;
    isRecurring?: boolean | null;
    recurringFrequency?: string | null;
    recurringDaily?: boolean | null;
    recurringDayOfWeek?: string | null;
    recurringDayOfMonth?: number | null;
    recurringTime?: string | null;
    timeOfDay?: string | null;
    timezone?: string | null;
    isPartOfJourney?: boolean | null;
    journeyId?: string | null;
  } | null> | null;
};

export type GetActiveTracksQueryVariables = Exact<{ [key: string]: never }>;

export type GetActiveTracksQuery = {
  __typename?: "Query";
  getActiveTracks?: Array<{
    __typename?: "ActiveTrack";
    name?: string | null;
    description?: string | null;
    last_modified?: string | null;
    totalUnits?: number | null;
    completedUnits?: number | null;
    parentSubfield?: string | null;
    parentFields?: Array<string | null> | null;
    mastery?: number | null;
    imageUrl?: string | null;
  } | null> | null;
};

export type MeQueryVariables = Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: "Query";
  me?: {
    __typename?: "MeResponse";
    audioOn?: boolean | null;
    countryCode?: string | null;
    currentWeightLbs?: number | null;
    dailyEmailOptIn?: boolean | null;
    enrolledTracks?: Array<string | null> | null;
    email?: string | null;
    fullPhoneNumber?: string | null;
    gender?: string | null;
    goalWeightLbs?: number | null;
    googleCalendarAuthToken?: string | null;
    id: string;
    isCareerLoading?: boolean | null;
    isCourseLoading?: boolean | null;
    isEmailVerified?: boolean | null;
    isPro?: boolean | null;
    isOnboarded?: boolean | null;
    modalOnboarding?: string | null;
    name?: string | null;
    otpPreference?: string | null;
    phoneNumber?: string | null;
    trialEndDate?: any | null;
    username?: string | null;
  } | null;
};

export const LoginDocument = gql`
  mutation Login($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      token
      user {
        id
        email
        isOnboarded
        isPro
        name
        googleAuthTokens {
          access_token
          refresh_token
          scope
          token_type
          expiry_date
        }
        isEmailVerified
        otpPreference
        countryCode
        phoneNumber
        fullPhoneNumber
      }
    }
  }
`;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
}
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const LogoutDocument = gql`
  mutation Logout {
    logout {
      message
      success
    }
  }
`;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
}
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const ResetPasswordDocument = gql`
  mutation ResetPassword($newPassword: String!, $token: String!) {
    resetPassword(newPassword: $newPassword, token: $token) {
      message
      success
    }
  }
`;
export type ResetPasswordMutationFn = Apollo.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      newPassword: // value for 'newPassword'
 *      token: // value for 'token'
 *   },
 * });
 */
export function useResetPasswordMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
}
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = Apollo.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = Apollo.BaseMutationOptions<
  ResetPasswordMutation,
  ResetPasswordMutationVariables
>;
export const SignupDocument = gql`
  mutation Signup(
    $email: String!
    $name: String!
    $password: String!
    $gender: String
    $birthday: String!
    $dailyEmailOptIn: Boolean!
    $language: String!
    $timezone: String!
  ) {
    signup(
      email: $email
      name: $name
      password: $password
      gender: $gender
      birthday: $birthday
      dailyEmailOptIn: $dailyEmailOptIn
      language: $language
      timezone: $timezone
    ) {
      token
      user {
        id
        email
        name
        isPro
        isOnboarded
        otpPreference
        isEmailVerified
        fullPhoneNumber
        preferences {
          language
        }
      }
    }
  }
`;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      password: // value for 'password'
 *      gender: // value for 'gender'
 *      birthday: // value for 'birthday'
 *      dailyEmailOptIn: // value for 'dailyEmailOptIn'
 *      language: // value for 'language'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
}
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const CareerIsLoadingDocument = gql`
  mutation careerIsLoading($isLoading: Boolean!) {
    careerIsLoading(isLoading: $isLoading) {
      isCareerLoading
    }
  }
`;
export type CareerIsLoadingMutationFn = Apollo.MutationFunction<
  CareerIsLoadingMutation,
  CareerIsLoadingMutationVariables
>;

/**
 * __useCareerIsLoadingMutation__
 *
 * To run a mutation, you first call `useCareerIsLoadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCareerIsLoadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [careerIsLoadingMutation, { data, loading, error }] = useCareerIsLoadingMutation({
 *   variables: {
 *      isLoading: // value for 'isLoading'
 *   },
 * });
 */
export function useCareerIsLoadingMutation(
  baseOptions?: Apollo.MutationHookOptions<CareerIsLoadingMutation, CareerIsLoadingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CareerIsLoadingMutation, CareerIsLoadingMutationVariables>(
    CareerIsLoadingDocument,
    options
  );
}
export type CareerIsLoadingMutationHookResult = ReturnType<typeof useCareerIsLoadingMutation>;
export type CareerIsLoadingMutationResult = Apollo.MutationResult<CareerIsLoadingMutation>;
export type CareerIsLoadingMutationOptions = Apollo.BaseMutationOptions<
  CareerIsLoadingMutation,
  CareerIsLoadingMutationVariables
>;
export const CareerSubmitFormDocument = gql`
  mutation CareerSubmitForm($currentOccupation: String!, $careerPathName: String!, $careerGoal: String!) {
    careerSubmitForm(currentOccupation: $currentOccupation, careerPathName: $careerPathName, careerGoal: $careerGoal)
  }
`;
export type CareerSubmitFormMutationFn = Apollo.MutationFunction<
  CareerSubmitFormMutation,
  CareerSubmitFormMutationVariables
>;

/**
 * __useCareerSubmitFormMutation__
 *
 * To run a mutation, you first call `useCareerSubmitFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCareerSubmitFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [careerSubmitFormMutation, { data, loading, error }] = useCareerSubmitFormMutation({
 *   variables: {
 *      currentOccupation: // value for 'currentOccupation'
 *      careerPathName: // value for 'careerPathName'
 *      careerGoal: // value for 'careerGoal'
 *   },
 * });
 */
export function useCareerSubmitFormMutation(
  baseOptions?: Apollo.MutationHookOptions<CareerSubmitFormMutation, CareerSubmitFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CareerSubmitFormMutation, CareerSubmitFormMutationVariables>(
    CareerSubmitFormDocument,
    options
  );
}
export type CareerSubmitFormMutationHookResult = ReturnType<typeof useCareerSubmitFormMutation>;
export type CareerSubmitFormMutationResult = Apollo.MutationResult<CareerSubmitFormMutation>;
export type CareerSubmitFormMutationOptions = Apollo.BaseMutationOptions<
  CareerSubmitFormMutation,
  CareerSubmitFormMutationVariables
>;
export const GenerateSuggestedCareersDocument = gql`
  mutation generateSuggestedCareers {
    generateSuggestedCareers {
      name
      id
    }
  }
`;
export type GenerateSuggestedCareersMutationFn = Apollo.MutationFunction<
  GenerateSuggestedCareersMutation,
  GenerateSuggestedCareersMutationVariables
>;

/**
 * __useGenerateSuggestedCareersMutation__
 *
 * To run a mutation, you first call `useGenerateSuggestedCareersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateSuggestedCareersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateSuggestedCareersMutation, { data, loading, error }] = useGenerateSuggestedCareersMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateSuggestedCareersMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateSuggestedCareersMutation, GenerateSuggestedCareersMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateSuggestedCareersMutation, GenerateSuggestedCareersMutationVariables>(
    GenerateSuggestedCareersDocument,
    options
  );
}
export type GenerateSuggestedCareersMutationHookResult = ReturnType<typeof useGenerateSuggestedCareersMutation>;
export type GenerateSuggestedCareersMutationResult = Apollo.MutationResult<GenerateSuggestedCareersMutation>;
export type GenerateSuggestedCareersMutationOptions = Apollo.BaseMutationOptions<
  GenerateSuggestedCareersMutation,
  GenerateSuggestedCareersMutationVariables
>;
export const SetCareerPathActiveDocument = gql`
  mutation SetCareerPathActive($careerId: ID) {
    setCareerPathActive(careerId: $careerId)
  }
`;
export type SetCareerPathActiveMutationFn = Apollo.MutationFunction<
  SetCareerPathActiveMutation,
  SetCareerPathActiveMutationVariables
>;

/**
 * __useSetCareerPathActiveMutation__
 *
 * To run a mutation, you first call `useSetCareerPathActiveMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetCareerPathActiveMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setCareerPathActiveMutation, { data, loading, error }] = useSetCareerPathActiveMutation({
 *   variables: {
 *      careerId: // value for 'careerId'
 *   },
 * });
 */
export function useSetCareerPathActiveMutation(
  baseOptions?: Apollo.MutationHookOptions<SetCareerPathActiveMutation, SetCareerPathActiveMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetCareerPathActiveMutation, SetCareerPathActiveMutationVariables>(
    SetCareerPathActiveDocument,
    options
  );
}
export type SetCareerPathActiveMutationHookResult = ReturnType<typeof useSetCareerPathActiveMutation>;
export type SetCareerPathActiveMutationResult = Apollo.MutationResult<SetCareerPathActiveMutation>;
export type SetCareerPathActiveMutationOptions = Apollo.BaseMutationOptions<
  SetCareerPathActiveMutation,
  SetCareerPathActiveMutationVariables
>;
export const DeleteAspectDocument = gql`
  mutation DeleteAspect($nodeId: ID!) {
    deleteAspect(nodeId: $nodeId) {
      message
      success
    }
  }
`;
export type DeleteAspectMutationFn = Apollo.MutationFunction<DeleteAspectMutation, DeleteAspectMutationVariables>;

/**
 * __useDeleteAspectMutation__
 *
 * To run a mutation, you first call `useDeleteAspectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAspectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAspectMutation, { data, loading, error }] = useDeleteAspectMutation({
 *   variables: {
 *      nodeId: // value for 'nodeId'
 *   },
 * });
 */
export function useDeleteAspectMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAspectMutation, DeleteAspectMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAspectMutation, DeleteAspectMutationVariables>(DeleteAspectDocument, options);
}
export type DeleteAspectMutationHookResult = ReturnType<typeof useDeleteAspectMutation>;
export type DeleteAspectMutationResult = Apollo.MutationResult<DeleteAspectMutation>;
export type DeleteAspectMutationOptions = Apollo.BaseMutationOptions<
  DeleteAspectMutation,
  DeleteAspectMutationVariables
>;
export const DeleteMessageDocument = gql`
  mutation DeleteMessage($id: String!) {
    deleteMessage(id: $id) {
      id
    }
  }
`;
export type DeleteMessageMutationFn = Apollo.MutationFunction<DeleteMessageMutation, DeleteMessageMutationVariables>;

/**
 * __useDeleteMessageMutation__
 *
 * To run a mutation, you first call `useDeleteMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMessageMutation, { data, loading, error }] = useDeleteMessageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteMessageMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMessageMutation, DeleteMessageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMessageMutation, DeleteMessageMutationVariables>(DeleteMessageDocument, options);
}
export type DeleteMessageMutationHookResult = ReturnType<typeof useDeleteMessageMutation>;
export type DeleteMessageMutationResult = Apollo.MutationResult<DeleteMessageMutation>;
export type DeleteMessageMutationOptions = Apollo.BaseMutationOptions<
  DeleteMessageMutation,
  DeleteMessageMutationVariables
>;
export const AddAssignmentDocument = gql`
  mutation AddAssignment($courseId: ID!, $moduleName: String!, $newAssignmentName: String!, $updatedHtml: String!) {
    addAssignment(
      courseId: $courseId
      moduleName: $moduleName
      newAssignmentName: $newAssignmentName
      updatedHtml: $updatedHtml
    ) {
      success
      message
    }
  }
`;
export type AddAssignmentMutationFn = Apollo.MutationFunction<AddAssignmentMutation, AddAssignmentMutationVariables>;

/**
 * __useAddAssignmentMutation__
 *
 * To run a mutation, you first call `useAddAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAssignmentMutation, { data, loading, error }] = useAddAssignmentMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      moduleName: // value for 'moduleName'
 *      newAssignmentName: // value for 'newAssignmentName'
 *      updatedHtml: // value for 'updatedHtml'
 *   },
 * });
 */
export function useAddAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<AddAssignmentMutation, AddAssignmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAssignmentMutation, AddAssignmentMutationVariables>(AddAssignmentDocument, options);
}
export type AddAssignmentMutationHookResult = ReturnType<typeof useAddAssignmentMutation>;
export type AddAssignmentMutationResult = Apollo.MutationResult<AddAssignmentMutation>;
export type AddAssignmentMutationOptions = Apollo.BaseMutationOptions<
  AddAssignmentMutation,
  AddAssignmentMutationVariables
>;
export const AddContentModuleDocument = gql`
  mutation AddContentModule($courseId: ID!, $moduleName: String!) {
    addContentModule(courseId: $courseId, moduleName: $moduleName) {
      success
      message
    }
  }
`;
export type AddContentModuleMutationFn = Apollo.MutationFunction<
  AddContentModuleMutation,
  AddContentModuleMutationVariables
>;

/**
 * __useAddContentModuleMutation__
 *
 * To run a mutation, you first call `useAddContentModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddContentModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addContentModuleMutation, { data, loading, error }] = useAddContentModuleMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      moduleName: // value for 'moduleName'
 *   },
 * });
 */
export function useAddContentModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<AddContentModuleMutation, AddContentModuleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddContentModuleMutation, AddContentModuleMutationVariables>(
    AddContentModuleDocument,
    options
  );
}
export type AddContentModuleMutationHookResult = ReturnType<typeof useAddContentModuleMutation>;
export type AddContentModuleMutationResult = Apollo.MutationResult<AddContentModuleMutation>;
export type AddContentModuleMutationOptions = Apollo.BaseMutationOptions<
  AddContentModuleMutation,
  AddContentModuleMutationVariables
>;
export const CourseCreationDocument = gql`
  mutation CourseCreation(
    $title: String!
    $standard: String!
    $weeks: Int!
    $objectives: [String!]!
    $urls: [String!]!
  ) {
    courseCreation(title: $title, standard: $standard, weeks: $weeks, objectives: $objectives, urls: $urls) {
      success
      message
      data {
        name
        standard
        weeks
        objectives
        modules {
          module_name
          assignments {
            assignment_name
            html
          }
        }
        created
        last_modified
      }
    }
  }
`;
export type CourseCreationMutationFn = Apollo.MutationFunction<CourseCreationMutation, CourseCreationMutationVariables>;

/**
 * __useCourseCreationMutation__
 *
 * To run a mutation, you first call `useCourseCreationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseCreationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseCreationMutation, { data, loading, error }] = useCourseCreationMutation({
 *   variables: {
 *      title: // value for 'title'
 *      standard: // value for 'standard'
 *      weeks: // value for 'weeks'
 *      objectives: // value for 'objectives'
 *      urls: // value for 'urls'
 *   },
 * });
 */
export function useCourseCreationMutation(
  baseOptions?: Apollo.MutationHookOptions<CourseCreationMutation, CourseCreationMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CourseCreationMutation, CourseCreationMutationVariables>(CourseCreationDocument, options);
}
export type CourseCreationMutationHookResult = ReturnType<typeof useCourseCreationMutation>;
export type CourseCreationMutationResult = Apollo.MutationResult<CourseCreationMutation>;
export type CourseCreationMutationOptions = Apollo.BaseMutationOptions<
  CourseCreationMutation,
  CourseCreationMutationVariables
>;
export const CourseIsLoadingDocument = gql`
  mutation courseIsLoading($isLoading: Boolean!) {
    courseIsLoading(isLoading: $isLoading) {
      isCourseLoading
    }
  }
`;
export type CourseIsLoadingMutationFn = Apollo.MutationFunction<
  CourseIsLoadingMutation,
  CourseIsLoadingMutationVariables
>;

/**
 * __useCourseIsLoadingMutation__
 *
 * To run a mutation, you first call `useCourseIsLoadingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCourseIsLoadingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [courseIsLoadingMutation, { data, loading, error }] = useCourseIsLoadingMutation({
 *   variables: {
 *      isLoading: // value for 'isLoading'
 *   },
 * });
 */
export function useCourseIsLoadingMutation(
  baseOptions?: Apollo.MutationHookOptions<CourseIsLoadingMutation, CourseIsLoadingMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CourseIsLoadingMutation, CourseIsLoadingMutationVariables>(
    CourseIsLoadingDocument,
    options
  );
}
export type CourseIsLoadingMutationHookResult = ReturnType<typeof useCourseIsLoadingMutation>;
export type CourseIsLoadingMutationResult = Apollo.MutationResult<CourseIsLoadingMutation>;
export type CourseIsLoadingMutationOptions = Apollo.BaseMutationOptions<
  CourseIsLoadingMutation,
  CourseIsLoadingMutationVariables
>;
export const DeleteContentAssignmentDocument = gql`
  mutation DeleteContentAssignment($courseId: ID!, $moduleName: String!, $assignmentName: String!) {
    deleteContentAssignment(courseId: $courseId, moduleName: $moduleName, assignmentName: $assignmentName) {
      success
      message
    }
  }
`;
export type DeleteContentAssignmentMutationFn = Apollo.MutationFunction<
  DeleteContentAssignmentMutation,
  DeleteContentAssignmentMutationVariables
>;

/**
 * __useDeleteContentAssignmentMutation__
 *
 * To run a mutation, you first call `useDeleteContentAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentAssignmentMutation, { data, loading, error }] = useDeleteContentAssignmentMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      moduleName: // value for 'moduleName'
 *      assignmentName: // value for 'assignmentName'
 *   },
 * });
 */
export function useDeleteContentAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteContentAssignmentMutation, DeleteContentAssignmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteContentAssignmentMutation, DeleteContentAssignmentMutationVariables>(
    DeleteContentAssignmentDocument,
    options
  );
}
export type DeleteContentAssignmentMutationHookResult = ReturnType<typeof useDeleteContentAssignmentMutation>;
export type DeleteContentAssignmentMutationResult = Apollo.MutationResult<DeleteContentAssignmentMutation>;
export type DeleteContentAssignmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteContentAssignmentMutation,
  DeleteContentAssignmentMutationVariables
>;
export const DeleteContentModuleDocument = gql`
  mutation DeleteContentModule($courseId: ID!, $moduleName: String!) {
    deleteContentModule(courseId: $courseId, moduleName: $moduleName) {
      success
      message
    }
  }
`;
export type DeleteContentModuleMutationFn = Apollo.MutationFunction<
  DeleteContentModuleMutation,
  DeleteContentModuleMutationVariables
>;

/**
 * __useDeleteContentModuleMutation__
 *
 * To run a mutation, you first call `useDeleteContentModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContentModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContentModuleMutation, { data, loading, error }] = useDeleteContentModuleMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      moduleName: // value for 'moduleName'
 *   },
 * });
 */
export function useDeleteContentModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteContentModuleMutation, DeleteContentModuleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteContentModuleMutation, DeleteContentModuleMutationVariables>(
    DeleteContentModuleDocument,
    options
  );
}
export type DeleteContentModuleMutationHookResult = ReturnType<typeof useDeleteContentModuleMutation>;
export type DeleteContentModuleMutationResult = Apollo.MutationResult<DeleteContentModuleMutation>;
export type DeleteContentModuleMutationOptions = Apollo.BaseMutationOptions<
  DeleteContentModuleMutation,
  DeleteContentModuleMutationVariables
>;
export const DeleteCourseDocument = gql`
  mutation DeleteCourse($courseId: ID!) {
    deleteCourse(courseId: $courseId) {
      success
      message
    }
  }
`;
export type DeleteCourseMutationFn = Apollo.MutationFunction<DeleteCourseMutation, DeleteCourseMutationVariables>;

/**
 * __useDeleteCourseMutation__
 *
 * To run a mutation, you first call `useDeleteCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseMutation, { data, loading, error }] = useDeleteCourseMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *   },
 * });
 */
export function useDeleteCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCourseMutation, DeleteCourseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCourseMutation, DeleteCourseMutationVariables>(DeleteCourseDocument, options);
}
export type DeleteCourseMutationHookResult = ReturnType<typeof useDeleteCourseMutation>;
export type DeleteCourseMutationResult = Apollo.MutationResult<DeleteCourseMutation>;
export type DeleteCourseMutationOptions = Apollo.BaseMutationOptions<
  DeleteCourseMutation,
  DeleteCourseMutationVariables
>;
export const UpdateAssignmentDocument = gql`
  mutation UpdateAssignment(
    $courseId: ID!
    $moduleName: String!
    $currentAssignmentName: String!
    $newAssignmentName: String!
    $updatedHtml: String!
  ) {
    updateAssignment(
      courseId: $courseId
      moduleName: $moduleName
      currentAssignmentName: $currentAssignmentName
      newAssignmentName: $newAssignmentName
      updatedHtml: $updatedHtml
    ) {
      success
      message
      data {
        assignment_name
        html
      }
    }
  }
`;
export type UpdateAssignmentMutationFn = Apollo.MutationFunction<
  UpdateAssignmentMutation,
  UpdateAssignmentMutationVariables
>;

/**
 * __useUpdateAssignmentMutation__
 *
 * To run a mutation, you first call `useUpdateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAssignmentMutation, { data, loading, error }] = useUpdateAssignmentMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      moduleName: // value for 'moduleName'
 *      currentAssignmentName: // value for 'currentAssignmentName'
 *      newAssignmentName: // value for 'newAssignmentName'
 *      updatedHtml: // value for 'updatedHtml'
 *   },
 * });
 */
export function useUpdateAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAssignmentMutation, UpdateAssignmentMutationVariables>(
    UpdateAssignmentDocument,
    options
  );
}
export type UpdateAssignmentMutationHookResult = ReturnType<typeof useUpdateAssignmentMutation>;
export type UpdateAssignmentMutationResult = Apollo.MutationResult<UpdateAssignmentMutation>;
export type UpdateAssignmentMutationOptions = Apollo.BaseMutationOptions<
  UpdateAssignmentMutation,
  UpdateAssignmentMutationVariables
>;
export const UpdateContentModuleNameDocument = gql`
  mutation UpdateContentModuleName($courseId: ID!, $oldModuleName: String!, $newModuleName: String!) {
    updateContentModuleName(courseId: $courseId, oldModuleName: $oldModuleName, newModuleName: $newModuleName) {
      success
      message
    }
  }
`;
export type UpdateContentModuleNameMutationFn = Apollo.MutationFunction<
  UpdateContentModuleNameMutation,
  UpdateContentModuleNameMutationVariables
>;

/**
 * __useUpdateContentModuleNameMutation__
 *
 * To run a mutation, you first call `useUpdateContentModuleNameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContentModuleNameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContentModuleNameMutation, { data, loading, error }] = useUpdateContentModuleNameMutation({
 *   variables: {
 *      courseId: // value for 'courseId'
 *      oldModuleName: // value for 'oldModuleName'
 *      newModuleName: // value for 'newModuleName'
 *   },
 * });
 */
export function useUpdateContentModuleNameMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContentModuleNameMutation, UpdateContentModuleNameMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContentModuleNameMutation, UpdateContentModuleNameMutationVariables>(
    UpdateContentModuleNameDocument,
    options
  );
}
export type UpdateContentModuleNameMutationHookResult = ReturnType<typeof useUpdateContentModuleNameMutation>;
export type UpdateContentModuleNameMutationResult = Apollo.MutationResult<UpdateContentModuleNameMutation>;
export type UpdateContentModuleNameMutationOptions = Apollo.BaseMutationOptions<
  UpdateContentModuleNameMutation,
  UpdateContentModuleNameMutationVariables
>;
export const UploadDocumentsDocument = gql`
  mutation UploadDocuments($files: [Upload!]!) {
    uploadDocuments(files: $files) {
      success
      message
    }
  }
`;
export type UploadDocumentsMutationFn = Apollo.MutationFunction<
  UploadDocumentsMutation,
  UploadDocumentsMutationVariables
>;

/**
 * __useUploadDocumentsMutation__
 *
 * To run a mutation, you first call `useUploadDocumentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentsMutation, { data, loading, error }] = useUploadDocumentsMutation({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadDocumentsMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadDocumentsMutation, UploadDocumentsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadDocumentsMutation, UploadDocumentsMutationVariables>(
    UploadDocumentsDocument,
    options
  );
}
export type UploadDocumentsMutationHookResult = ReturnType<typeof useUploadDocumentsMutation>;
export type UploadDocumentsMutationResult = Apollo.MutationResult<UploadDocumentsMutation>;
export type UploadDocumentsMutationOptions = Apollo.BaseMutationOptions<
  UploadDocumentsMutation,
  UploadDocumentsMutationVariables
>;
export const UploadDocumentsToBucketDocument = gql`
  mutation UploadDocumentsToBucket($files: [Upload!]!) {
    uploadDocumentsToBucket(files: $files) {
      success
      message
      urls
    }
  }
`;
export type UploadDocumentsToBucketMutationFn = Apollo.MutationFunction<
  UploadDocumentsToBucketMutation,
  UploadDocumentsToBucketMutationVariables
>;

/**
 * __useUploadDocumentsToBucketMutation__
 *
 * To run a mutation, you first call `useUploadDocumentsToBucketMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadDocumentsToBucketMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadDocumentsToBucketMutation, { data, loading, error }] = useUploadDocumentsToBucketMutation({
 *   variables: {
 *      files: // value for 'files'
 *   },
 * });
 */
export function useUploadDocumentsToBucketMutation(
  baseOptions?: Apollo.MutationHookOptions<UploadDocumentsToBucketMutation, UploadDocumentsToBucketMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UploadDocumentsToBucketMutation, UploadDocumentsToBucketMutationVariables>(
    UploadDocumentsToBucketDocument,
    options
  );
}
export type UploadDocumentsToBucketMutationHookResult = ReturnType<typeof useUploadDocumentsToBucketMutation>;
export type UploadDocumentsToBucketMutationResult = Apollo.MutationResult<UploadDocumentsToBucketMutation>;
export type UploadDocumentsToBucketMutationOptions = Apollo.BaseMutationOptions<
  UploadDocumentsToBucketMutation,
  UploadDocumentsToBucketMutationVariables
>;
export const CreateAssignmentDocument = gql`
  mutation CreateAssignment($title: String!, $dueDate: String!, $body: String, $parentResourceId: ID!) {
    createAssignment(
      assignment: { title: $title, dueDate: $dueDate, body: $body, parentResourceId: $parentResourceId }
    ) {
      success
      message
      data {
        title
        due_date
        body
      }
    }
  }
`;
export type CreateAssignmentMutationFn = Apollo.MutationFunction<
  CreateAssignmentMutation,
  CreateAssignmentMutationVariables
>;

/**
 * __useCreateAssignmentMutation__
 *
 * To run a mutation, you first call `useCreateAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAssignmentMutation, { data, loading, error }] = useCreateAssignmentMutation({
 *   variables: {
 *      title: // value for 'title'
 *      dueDate: // value for 'dueDate'
 *      body: // value for 'body'
 *      parentResourceId: // value for 'parentResourceId'
 *   },
 * });
 */
export function useCreateAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateAssignmentMutation, CreateAssignmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateAssignmentMutation, CreateAssignmentMutationVariables>(
    CreateAssignmentDocument,
    options
  );
}
export type CreateAssignmentMutationHookResult = ReturnType<typeof useCreateAssignmentMutation>;
export type CreateAssignmentMutationResult = Apollo.MutationResult<CreateAssignmentMutation>;
export type CreateAssignmentMutationOptions = Apollo.BaseMutationOptions<
  CreateAssignmentMutation,
  CreateAssignmentMutationVariables
>;
export const DeleteAssignmentDocument = gql`
  mutation DeleteAssignment($id: ID!) {
    deleteAssignment(id: $id) {
      success
      message
    }
  }
`;
export type DeleteAssignmentMutationFn = Apollo.MutationFunction<
  DeleteAssignmentMutation,
  DeleteAssignmentMutationVariables
>;

/**
 * __useDeleteAssignmentMutation__
 *
 * To run a mutation, you first call `useDeleteAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAssignmentMutation, { data, loading, error }] = useDeleteAssignmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAssignmentMutation, DeleteAssignmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAssignmentMutation, DeleteAssignmentMutationVariables>(
    DeleteAssignmentDocument,
    options
  );
}
export type DeleteAssignmentMutationHookResult = ReturnType<typeof useDeleteAssignmentMutation>;
export type DeleteAssignmentMutationResult = Apollo.MutationResult<DeleteAssignmentMutation>;
export type DeleteAssignmentMutationOptions = Apollo.BaseMutationOptions<
  DeleteAssignmentMutation,
  DeleteAssignmentMutationVariables
>;
export const EditAssignmentDocument = gql`
  mutation EditAssignment($id: ID!, $title: String, $dueDate: String, $body: String) {
    editAssignment(id: $id, assignment: { title: $title, dueDate: $dueDate, body: $body }) {
      success
      message
      data {
        title
        due_date
        body
      }
    }
  }
`;
export type EditAssignmentMutationFn = Apollo.MutationFunction<EditAssignmentMutation, EditAssignmentMutationVariables>;

/**
 * __useEditAssignmentMutation__
 *
 * To run a mutation, you first call `useEditAssignmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditAssignmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editAssignmentMutation, { data, loading, error }] = useEditAssignmentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      dueDate: // value for 'dueDate'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useEditAssignmentMutation(
  baseOptions?: Apollo.MutationHookOptions<EditAssignmentMutation, EditAssignmentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EditAssignmentMutation, EditAssignmentMutationVariables>(EditAssignmentDocument, options);
}
export type EditAssignmentMutationHookResult = ReturnType<typeof useEditAssignmentMutation>;
export type EditAssignmentMutationResult = Apollo.MutationResult<EditAssignmentMutation>;
export type EditAssignmentMutationOptions = Apollo.BaseMutationOptions<
  EditAssignmentMutation,
  EditAssignmentMutationVariables
>;
export const DeleteCourseCascadeDocument = gql`
  mutation DeleteCourseCascade($id: ID!) {
    deleteCourseCascade(id: $id) {
      success
      message
    }
  }
`;
export type DeleteCourseCascadeMutationFn = Apollo.MutationFunction<
  DeleteCourseCascadeMutation,
  DeleteCourseCascadeMutationVariables
>;

/**
 * __useDeleteCourseCascadeMutation__
 *
 * To run a mutation, you first call `useDeleteCourseCascadeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCourseCascadeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCourseCascadeMutation, { data, loading, error }] = useDeleteCourseCascadeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteCourseCascadeMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteCourseCascadeMutation, DeleteCourseCascadeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteCourseCascadeMutation, DeleteCourseCascadeMutationVariables>(
    DeleteCourseCascadeDocument,
    options
  );
}
export type DeleteCourseCascadeMutationHookResult = ReturnType<typeof useDeleteCourseCascadeMutation>;
export type DeleteCourseCascadeMutationResult = Apollo.MutationResult<DeleteCourseCascadeMutation>;
export type DeleteCourseCascadeMutationOptions = Apollo.BaseMutationOptions<
  DeleteCourseCascadeMutation,
  DeleteCourseCascadeMutationVariables
>;
export const CreateCourseDocument = gql`
  mutation CreateCourse(
    $title: String!
    $learningStandards: String
    $lengthInWeeks: Float
    $objectives: [String!]
    $parentSchoolId: ID!
  ) {
    createCourse(
      course: {
        title: $title
        learningStandards: $learningStandards
        lengthInWeeks: $lengthInWeeks
        objectives: $objectives
        parentSchoolId: $parentSchoolId
      }
    ) {
      success
      message
      data {
        title
        learningStandards
        lengthInWeeks
        objectives
      }
    }
  }
`;
export type CreateCourseMutationFn = Apollo.MutationFunction<CreateCourseMutation, CreateCourseMutationVariables>;

/**
 * __useCreateCourseMutation__
 *
 * To run a mutation, you first call `useCreateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCourseMutation, { data, loading, error }] = useCreateCourseMutation({
 *   variables: {
 *      title: // value for 'title'
 *      learningStandards: // value for 'learningStandards'
 *      lengthInWeeks: // value for 'lengthInWeeks'
 *      objectives: // value for 'objectives'
 *      parentSchoolId: // value for 'parentSchoolId'
 *   },
 * });
 */
export function useCreateCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateCourseMutation, CreateCourseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateCourseMutation, CreateCourseMutationVariables>(CreateCourseDocument, options);
}
export type CreateCourseMutationHookResult = ReturnType<typeof useCreateCourseMutation>;
export type CreateCourseMutationResult = Apollo.MutationResult<CreateCourseMutation>;
export type CreateCourseMutationOptions = Apollo.BaseMutationOptions<
  CreateCourseMutation,
  CreateCourseMutationVariables
>;
export const UpdateCourseDocument = gql`
  mutation UpdateCourse(
    $id: ID!
    $title: String
    $learningStandards: String
    $lengthInWeeks: Float
    $objectives: [String!]
  ) {
    updateCourse(
      id: $id
      course: {
        title: $title
        learningStandards: $learningStandards
        lengthInWeeks: $lengthInWeeks
        objectives: $objectives
      }
    ) {
      success
      message
      data {
        title
        learningStandards
        lengthInWeeks
        objectives
      }
    }
  }
`;
export type UpdateCourseMutationFn = Apollo.MutationFunction<UpdateCourseMutation, UpdateCourseMutationVariables>;

/**
 * __useUpdateCourseMutation__
 *
 * To run a mutation, you first call `useUpdateCourseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCourseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCourseMutation, { data, loading, error }] = useUpdateCourseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      learningStandards: // value for 'learningStandards'
 *      lengthInWeeks: // value for 'lengthInWeeks'
 *      objectives: // value for 'objectives'
 *   },
 * });
 */
export function useUpdateCourseMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateCourseMutation, UpdateCourseMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateCourseMutation, UpdateCourseMutationVariables>(UpdateCourseDocument, options);
}
export type UpdateCourseMutationHookResult = ReturnType<typeof useUpdateCourseMutation>;
export type UpdateCourseMutationResult = Apollo.MutationResult<UpdateCourseMutation>;
export type UpdateCourseMutationOptions = Apollo.BaseMutationOptions<
  UpdateCourseMutation,
  UpdateCourseMutationVariables
>;
export const DeleteModuleCascadeDocument = gql`
  mutation DeleteModuleCascade($id: ID!) {
    deleteModuleCascade(id: $id) {
      success
      message
    }
  }
`;
export type DeleteModuleCascadeMutationFn = Apollo.MutationFunction<
  DeleteModuleCascadeMutation,
  DeleteModuleCascadeMutationVariables
>;

/**
 * __useDeleteModuleCascadeMutation__
 *
 * To run a mutation, you first call `useDeleteModuleCascadeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModuleCascadeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModuleCascadeMutation, { data, loading, error }] = useDeleteModuleCascadeMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteModuleCascadeMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteModuleCascadeMutation, DeleteModuleCascadeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteModuleCascadeMutation, DeleteModuleCascadeMutationVariables>(
    DeleteModuleCascadeDocument,
    options
  );
}
export type DeleteModuleCascadeMutationHookResult = ReturnType<typeof useDeleteModuleCascadeMutation>;
export type DeleteModuleCascadeMutationResult = Apollo.MutationResult<DeleteModuleCascadeMutation>;
export type DeleteModuleCascadeMutationOptions = Apollo.BaseMutationOptions<
  DeleteModuleCascadeMutation,
  DeleteModuleCascadeMutationVariables
>;
export const CreateModuleDocument = gql`
  mutation CreateModule($title: String!, $order: Float!, $parentCourseId: ID!) {
    createModuleAndResource(module: { title: $title, order: $order, parentCourseId: $parentCourseId }) {
      success
      message
      data {
        title
        order
      }
    }
  }
`;
export type CreateModuleMutationFn = Apollo.MutationFunction<CreateModuleMutation, CreateModuleMutationVariables>;

/**
 * __useCreateModuleMutation__
 *
 * To run a mutation, you first call `useCreateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createModuleMutation, { data, loading, error }] = useCreateModuleMutation({
 *   variables: {
 *      title: // value for 'title'
 *      order: // value for 'order'
 *      parentCourseId: // value for 'parentCourseId'
 *   },
 * });
 */
export function useCreateModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateModuleMutation, CreateModuleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateModuleMutation, CreateModuleMutationVariables>(CreateModuleDocument, options);
}
export type CreateModuleMutationHookResult = ReturnType<typeof useCreateModuleMutation>;
export type CreateModuleMutationResult = Apollo.MutationResult<CreateModuleMutation>;
export type CreateModuleMutationOptions = Apollo.BaseMutationOptions<
  CreateModuleMutation,
  CreateModuleMutationVariables
>;
export const UpdateModuleDocument = gql`
  mutation UpdateModule($id: ID!, $title: String, $order: Float) {
    updateModule(id: $id, module: { title: $title, order: $order }) {
      success
      message
      data {
        title
        order
      }
    }
  }
`;
export type UpdateModuleMutationFn = Apollo.MutationFunction<UpdateModuleMutation, UpdateModuleMutationVariables>;

/**
 * __useUpdateModuleMutation__
 *
 * To run a mutation, you first call `useUpdateModuleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModuleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModuleMutation, { data, loading, error }] = useUpdateModuleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useUpdateModuleMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateModuleMutation, UpdateModuleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateModuleMutation, UpdateModuleMutationVariables>(UpdateModuleDocument, options);
}
export type UpdateModuleMutationHookResult = ReturnType<typeof useUpdateModuleMutation>;
export type UpdateModuleMutationResult = Apollo.MutationResult<UpdateModuleMutation>;
export type UpdateModuleMutationOptions = Apollo.BaseMutationOptions<
  UpdateModuleMutation,
  UpdateModuleMutationVariables
>;
export const CreatePageDocument = gql`
  mutation CreatePage($title: String!, $body: String, $parentResourceId: ID!) {
    createPage(page: { title: $title, body: $body, parentResourceId: $parentResourceId }) {
      success
      message
      data {
        title
        body
      }
    }
  }
`;
export type CreatePageMutationFn = Apollo.MutationFunction<CreatePageMutation, CreatePageMutationVariables>;

/**
 * __useCreatePageMutation__
 *
 * To run a mutation, you first call `useCreatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPageMutation, { data, loading, error }] = useCreatePageMutation({
 *   variables: {
 *      title: // value for 'title'
 *      body: // value for 'body'
 *      parentResourceId: // value for 'parentResourceId'
 *   },
 * });
 */
export function useCreatePageMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePageMutation, CreatePageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePageMutation, CreatePageMutationVariables>(CreatePageDocument, options);
}
export type CreatePageMutationHookResult = ReturnType<typeof useCreatePageMutation>;
export type CreatePageMutationResult = Apollo.MutationResult<CreatePageMutation>;
export type CreatePageMutationOptions = Apollo.BaseMutationOptions<CreatePageMutation, CreatePageMutationVariables>;
export const DeletePageDocument = gql`
  mutation DeletePage($id: ID!) {
    deletePage(id: $id) {
      success
      message
    }
  }
`;
export type DeletePageMutationFn = Apollo.MutationFunction<DeletePageMutation, DeletePageMutationVariables>;

/**
 * __useDeletePageMutation__
 *
 * To run a mutation, you first call `useDeletePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePageMutation, { data, loading, error }] = useDeletePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePageMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePageMutation, DeletePageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePageMutation, DeletePageMutationVariables>(DeletePageDocument, options);
}
export type DeletePageMutationHookResult = ReturnType<typeof useDeletePageMutation>;
export type DeletePageMutationResult = Apollo.MutationResult<DeletePageMutation>;
export type DeletePageMutationOptions = Apollo.BaseMutationOptions<DeletePageMutation, DeletePageMutationVariables>;
export const UpdatePageDocument = gql`
  mutation UpdatePage($id: ID!, $title: String, $body: String) {
    updatePage(id: $id, page: { title: $title, body: $body }) {
      success
      message
      data {
        title
        body
      }
    }
  }
`;
export type UpdatePageMutationFn = Apollo.MutationFunction<UpdatePageMutation, UpdatePageMutationVariables>;

/**
 * __useUpdatePageMutation__
 *
 * To run a mutation, you first call `useUpdatePageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePageMutation, { data, loading, error }] = useUpdatePageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      title: // value for 'title'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdatePageMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePageMutation, UpdatePageMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePageMutation, UpdatePageMutationVariables>(UpdatePageDocument, options);
}
export type UpdatePageMutationHookResult = ReturnType<typeof useUpdatePageMutation>;
export type UpdatePageMutationResult = Apollo.MutationResult<UpdatePageMutation>;
export type UpdatePageMutationOptions = Apollo.BaseMutationOptions<UpdatePageMutation, UpdatePageMutationVariables>;
export const GenerateKeywordsDocument = gql`
  mutation GenerateKeywords($title: String!, $description: String!) {
    generateYoutubeKeywords(title: $title, description: $description)
  }
`;
export type GenerateKeywordsMutationFn = Apollo.MutationFunction<
  GenerateKeywordsMutation,
  GenerateKeywordsMutationVariables
>;

/**
 * __useGenerateKeywordsMutation__
 *
 * To run a mutation, you first call `useGenerateKeywordsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateKeywordsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateKeywordsMutation, { data, loading, error }] = useGenerateKeywordsMutation({
 *   variables: {
 *      title: // value for 'title'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useGenerateKeywordsMutation(
  baseOptions?: Apollo.MutationHookOptions<GenerateKeywordsMutation, GenerateKeywordsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<GenerateKeywordsMutation, GenerateKeywordsMutationVariables>(
    GenerateKeywordsDocument,
    options
  );
}
export type GenerateKeywordsMutationHookResult = ReturnType<typeof useGenerateKeywordsMutation>;
export type GenerateKeywordsMutationResult = Apollo.MutationResult<GenerateKeywordsMutation>;
export type GenerateKeywordsMutationOptions = Apollo.BaseMutationOptions<
  GenerateKeywordsMutation,
  GenerateKeywordsMutationVariables
>;
export const MarkQuestCompleteDocument = gql`
  mutation MarkQuestComplete($chatId: ID!, $questId: ID!) {
    markQuestComplete(chatId: $chatId, questId: $questId) {
      id
      assignedQuests {
        id
        title
        isComplete
      }
    }
  }
`;
export type MarkQuestCompleteMutationFn = Apollo.MutationFunction<
  MarkQuestCompleteMutation,
  MarkQuestCompleteMutationVariables
>;

/**
 * __useMarkQuestCompleteMutation__
 *
 * To run a mutation, you first call `useMarkQuestCompleteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMarkQuestCompleteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [markQuestCompleteMutation, { data, loading, error }] = useMarkQuestCompleteMutation({
 *   variables: {
 *      chatId: // value for 'chatId'
 *      questId: // value for 'questId'
 *   },
 * });
 */
export function useMarkQuestCompleteMutation(
  baseOptions?: Apollo.MutationHookOptions<MarkQuestCompleteMutation, MarkQuestCompleteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<MarkQuestCompleteMutation, MarkQuestCompleteMutationVariables>(
    MarkQuestCompleteDocument,
    options
  );
}
export type MarkQuestCompleteMutationHookResult = ReturnType<typeof useMarkQuestCompleteMutation>;
export type MarkQuestCompleteMutationResult = Apollo.MutationResult<MarkQuestCompleteMutation>;
export type MarkQuestCompleteMutationOptions = Apollo.BaseMutationOptions<
  MarkQuestCompleteMutation,
  MarkQuestCompleteMutationVariables
>;
export const RefreshQuestDocument = gql`
  mutation RefreshQuest($questIdToReplace: ID!, $timezone: String!) {
    refreshQuest(questIdToReplace: $questIdToReplace, timezone: $timezone) {
      success
      message
      quests {
        id
        title
        description
        isComplete
      }
    }
  }
`;
export type RefreshQuestMutationFn = Apollo.MutationFunction<RefreshQuestMutation, RefreshQuestMutationVariables>;

/**
 * __useRefreshQuestMutation__
 *
 * To run a mutation, you first call `useRefreshQuestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRefreshQuestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [refreshQuestMutation, { data, loading, error }] = useRefreshQuestMutation({
 *   variables: {
 *      questIdToReplace: // value for 'questIdToReplace'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useRefreshQuestMutation(
  baseOptions?: Apollo.MutationHookOptions<RefreshQuestMutation, RefreshQuestMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RefreshQuestMutation, RefreshQuestMutationVariables>(RefreshQuestDocument, options);
}
export type RefreshQuestMutationHookResult = ReturnType<typeof useRefreshQuestMutation>;
export type RefreshQuestMutationResult = Apollo.MutationResult<RefreshQuestMutation>;
export type RefreshQuestMutationOptions = Apollo.BaseMutationOptions<
  RefreshQuestMutation,
  RefreshQuestMutationVariables
>;
export const AudioToggleUserDocument = gql`
  mutation AudioToggleUser($audioOn: Boolean!) {
    audioToggleUser(audioOn: $audioOn) {
      audioOn
    }
  }
`;
export type AudioToggleUserMutationFn = Apollo.MutationFunction<
  AudioToggleUserMutation,
  AudioToggleUserMutationVariables
>;

/**
 * __useAudioToggleUserMutation__
 *
 * To run a mutation, you first call `useAudioToggleUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAudioToggleUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [audioToggleUserMutation, { data, loading, error }] = useAudioToggleUserMutation({
 *   variables: {
 *      audioOn: // value for 'audioOn'
 *   },
 * });
 */
export function useAudioToggleUserMutation(
  baseOptions?: Apollo.MutationHookOptions<AudioToggleUserMutation, AudioToggleUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AudioToggleUserMutation, AudioToggleUserMutationVariables>(
    AudioToggleUserDocument,
    options
  );
}
export type AudioToggleUserMutationHookResult = ReturnType<typeof useAudioToggleUserMutation>;
export type AudioToggleUserMutationResult = Apollo.MutationResult<AudioToggleUserMutation>;
export type AudioToggleUserMutationOptions = Apollo.BaseMutationOptions<
  AudioToggleUserMutation,
  AudioToggleUserMutationVariables
>;
export const UpdateNotificationsDocument = gql`
  mutation UpdateNotifications($dailyEmailOptIn: Boolean!) {
    updateNotifications(dailyEmailOptIn: $dailyEmailOptIn) {
      success
      message
    }
  }
`;
export type UpdateNotificationsMutationFn = Apollo.MutationFunction<
  UpdateNotificationsMutation,
  UpdateNotificationsMutationVariables
>;

/**
 * __useUpdateNotificationsMutation__
 *
 * To run a mutation, you first call `useUpdateNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNotificationsMutation, { data, loading, error }] = useUpdateNotificationsMutation({
 *   variables: {
 *      dailyEmailOptIn: // value for 'dailyEmailOptIn'
 *   },
 * });
 */
export function useUpdateNotificationsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateNotificationsMutation, UpdateNotificationsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNotificationsMutation, UpdateNotificationsMutationVariables>(
    UpdateNotificationsDocument,
    options
  );
}
export type UpdateNotificationsMutationHookResult = ReturnType<typeof useUpdateNotificationsMutation>;
export type UpdateNotificationsMutationResult = Apollo.MutationResult<UpdateNotificationsMutation>;
export type UpdateNotificationsMutationOptions = Apollo.BaseMutationOptions<
  UpdateNotificationsMutation,
  UpdateNotificationsMutationVariables
>;
export const AddToWhitelistDocument = gql`
  mutation AddToWhitelist($email: String!) {
    addToWhitelist(email: $email) {
      email
    }
  }
`;
export type AddToWhitelistMutationFn = Apollo.MutationFunction<AddToWhitelistMutation, AddToWhitelistMutationVariables>;

/**
 * __useAddToWhitelistMutation__
 *
 * To run a mutation, you first call `useAddToWhitelistMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddToWhitelistMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addToWhitelistMutation, { data, loading, error }] = useAddToWhitelistMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAddToWhitelistMutation(
  baseOptions?: Apollo.MutationHookOptions<AddToWhitelistMutation, AddToWhitelistMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddToWhitelistMutation, AddToWhitelistMutationVariables>(AddToWhitelistDocument, options);
}
export type AddToWhitelistMutationHookResult = ReturnType<typeof useAddToWhitelistMutation>;
export type AddToWhitelistMutationResult = Apollo.MutationResult<AddToWhitelistMutation>;
export type AddToWhitelistMutationOptions = Apollo.BaseMutationOptions<
  AddToWhitelistMutation,
  AddToWhitelistMutationVariables
>;
export const CompleteJourneyDocument = gql`
  mutation CompleteJourney($journeyId: ID!) {
    completeJourney(journeyId: $journeyId) {
      success
      message
    }
  }
`;
export type CompleteJourneyMutationFn = Apollo.MutationFunction<
  CompleteJourneyMutation,
  CompleteJourneyMutationVariables
>;

/**
 * __useCompleteJourneyMutation__
 *
 * To run a mutation, you first call `useCompleteJourneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteJourneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeJourneyMutation, { data, loading, error }] = useCompleteJourneyMutation({
 *   variables: {
 *      journeyId: // value for 'journeyId'
 *   },
 * });
 */
export function useCompleteJourneyMutation(
  baseOptions?: Apollo.MutationHookOptions<CompleteJourneyMutation, CompleteJourneyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CompleteJourneyMutation, CompleteJourneyMutationVariables>(
    CompleteJourneyDocument,
    options
  );
}
export type CompleteJourneyMutationHookResult = ReturnType<typeof useCompleteJourneyMutation>;
export type CompleteJourneyMutationResult = Apollo.MutationResult<CompleteJourneyMutation>;
export type CompleteJourneyMutationOptions = Apollo.BaseMutationOptions<
  CompleteJourneyMutation,
  CompleteJourneyMutationVariables
>;
export const DeleteJourneyDocument = gql`
  mutation DeleteJourney($journeyId: ID!) {
    deleteJourney(journeyId: $journeyId) {
      success
      message
    }
  }
`;
export type DeleteJourneyMutationFn = Apollo.MutationFunction<DeleteJourneyMutation, DeleteJourneyMutationVariables>;

/**
 * __useDeleteJourneyMutation__
 *
 * To run a mutation, you first call `useDeleteJourneyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJourneyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJourneyMutation, { data, loading, error }] = useDeleteJourneyMutation({
 *   variables: {
 *      journeyId: // value for 'journeyId'
 *   },
 * });
 */
export function useDeleteJourneyMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteJourneyMutation, DeleteJourneyMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteJourneyMutation, DeleteJourneyMutationVariables>(DeleteJourneyDocument, options);
}
export type DeleteJourneyMutationHookResult = ReturnType<typeof useDeleteJourneyMutation>;
export type DeleteJourneyMutationResult = Apollo.MutationResult<DeleteJourneyMutation>;
export type DeleteJourneyMutationOptions = Apollo.BaseMutationOptions<
  DeleteJourneyMutation,
  DeleteJourneyMutationVariables
>;
export const JourneySubmitFormDocument = gql`
  mutation JourneySubmitForm($description: String!, $dueDate: String!, $timezone: String!) {
    journeySubmitForm(description: $description, dueDate: $dueDate, timezone: $timezone) {
      success
      message
    }
  }
`;
export type JourneySubmitFormMutationFn = Apollo.MutationFunction<
  JourneySubmitFormMutation,
  JourneySubmitFormMutationVariables
>;

/**
 * __useJourneySubmitFormMutation__
 *
 * To run a mutation, you first call `useJourneySubmitFormMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useJourneySubmitFormMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [journeySubmitFormMutation, { data, loading, error }] = useJourneySubmitFormMutation({
 *   variables: {
 *      description: // value for 'description'
 *      dueDate: // value for 'dueDate'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useJourneySubmitFormMutation(
  baseOptions?: Apollo.MutationHookOptions<JourneySubmitFormMutation, JourneySubmitFormMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<JourneySubmitFormMutation, JourneySubmitFormMutationVariables>(
    JourneySubmitFormDocument,
    options
  );
}
export type JourneySubmitFormMutationHookResult = ReturnType<typeof useJourneySubmitFormMutation>;
export type JourneySubmitFormMutationResult = Apollo.MutationResult<JourneySubmitFormMutation>;
export type JourneySubmitFormMutationOptions = Apollo.BaseMutationOptions<
  JourneySubmitFormMutation,
  JourneySubmitFormMutationVariables
>;
export const CreateEducationChildrenDocument = gql`
  mutation CreateEducationChildren($label: NodeLabel!, $name: String!) {
    createEducationChildren(label: $label, name: $name) {
      success
      message
      data {
        name
      }
    }
  }
`;
export type CreateEducationChildrenMutationFn = Apollo.MutationFunction<
  CreateEducationChildrenMutation,
  CreateEducationChildrenMutationVariables
>;

/**
 * __useCreateEducationChildrenMutation__
 *
 * To run a mutation, you first call `useCreateEducationChildrenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEducationChildrenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEducationChildrenMutation, { data, loading, error }] = useCreateEducationChildrenMutation({
 *   variables: {
 *      label: // value for 'label'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateEducationChildrenMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEducationChildrenMutation, CreateEducationChildrenMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEducationChildrenMutation, CreateEducationChildrenMutationVariables>(
    CreateEducationChildrenDocument,
    options
  );
}
export type CreateEducationChildrenMutationHookResult = ReturnType<typeof useCreateEducationChildrenMutation>;
export type CreateEducationChildrenMutationResult = Apollo.MutationResult<CreateEducationChildrenMutation>;
export type CreateEducationChildrenMutationOptions = Apollo.BaseMutationOptions<
  CreateEducationChildrenMutation,
  CreateEducationChildrenMutationVariables
>;
export const EnrollUserInTrackDocument = gql`
  mutation EnrollUserInTrack($topicName: String!) {
    enrollUserInTrack(topicName: $topicName) {
      success
      message
    }
  }
`;
export type EnrollUserInTrackMutationFn = Apollo.MutationFunction<
  EnrollUserInTrackMutation,
  EnrollUserInTrackMutationVariables
>;

/**
 * __useEnrollUserInTrackMutation__
 *
 * To run a mutation, you first call `useEnrollUserInTrackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnrollUserInTrackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enrollUserInTrackMutation, { data, loading, error }] = useEnrollUserInTrackMutation({
 *   variables: {
 *      topicName: // value for 'topicName'
 *   },
 * });
 */
export function useEnrollUserInTrackMutation(
  baseOptions?: Apollo.MutationHookOptions<EnrollUserInTrackMutation, EnrollUserInTrackMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<EnrollUserInTrackMutation, EnrollUserInTrackMutationVariables>(
    EnrollUserInTrackDocument,
    options
  );
}
export type EnrollUserInTrackMutationHookResult = ReturnType<typeof useEnrollUserInTrackMutation>;
export type EnrollUserInTrackMutationResult = Apollo.MutationResult<EnrollUserInTrackMutation>;
export type EnrollUserInTrackMutationOptions = Apollo.BaseMutationOptions<
  EnrollUserInTrackMutation,
  EnrollUserInTrackMutationVariables
>;
export const SetKnowledgeRelevanceDocument = gql`
  mutation SetKnowledgeRelevance($nodeName: String!, $nodeLabel: String!, $isRelevant: Boolean!) {
    setKnowledgeRelevance(nodeName: $nodeName, nodeLabel: $nodeLabel, isRelevant: $isRelevant) {
      success
      message
    }
  }
`;
export type SetKnowledgeRelevanceMutationFn = Apollo.MutationFunction<
  SetKnowledgeRelevanceMutation,
  SetKnowledgeRelevanceMutationVariables
>;

/**
 * __useSetKnowledgeRelevanceMutation__
 *
 * To run a mutation, you first call `useSetKnowledgeRelevanceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetKnowledgeRelevanceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setKnowledgeRelevanceMutation, { data, loading, error }] = useSetKnowledgeRelevanceMutation({
 *   variables: {
 *      nodeName: // value for 'nodeName'
 *      nodeLabel: // value for 'nodeLabel'
 *      isRelevant: // value for 'isRelevant'
 *   },
 * });
 */
export function useSetKnowledgeRelevanceMutation(
  baseOptions?: Apollo.MutationHookOptions<SetKnowledgeRelevanceMutation, SetKnowledgeRelevanceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetKnowledgeRelevanceMutation, SetKnowledgeRelevanceMutationVariables>(
    SetKnowledgeRelevanceDocument,
    options
  );
}
export type SetKnowledgeRelevanceMutationHookResult = ReturnType<typeof useSetKnowledgeRelevanceMutation>;
export type SetKnowledgeRelevanceMutationResult = Apollo.MutationResult<SetKnowledgeRelevanceMutation>;
export type SetKnowledgeRelevanceMutationOptions = Apollo.BaseMutationOptions<
  SetKnowledgeRelevanceMutation,
  SetKnowledgeRelevanceMutationVariables
>;
export const UpdateTopicDocument = gql`
  mutation UpdateTopic($operationType: String!, $topicName: String!) {
    updateTopic(operationType: $operationType, topicName: $topicName) {
      success
      message
    }
  }
`;
export type UpdateTopicMutationFn = Apollo.MutationFunction<UpdateTopicMutation, UpdateTopicMutationVariables>;

/**
 * __useUpdateTopicMutation__
 *
 * To run a mutation, you first call `useUpdateTopicMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTopicMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTopicMutation, { data, loading, error }] = useUpdateTopicMutation({
 *   variables: {
 *      operationType: // value for 'operationType'
 *      topicName: // value for 'topicName'
 *   },
 * });
 */
export function useUpdateTopicMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTopicMutation, UpdateTopicMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTopicMutation, UpdateTopicMutationVariables>(UpdateTopicDocument, options);
}
export type UpdateTopicMutationHookResult = ReturnType<typeof useUpdateTopicMutation>;
export type UpdateTopicMutationResult = Apollo.MutationResult<UpdateTopicMutation>;
export type UpdateTopicMutationOptions = Apollo.BaseMutationOptions<UpdateTopicMutation, UpdateTopicMutationVariables>;
export const UpdateLearningObjectiveCompletionDocument = gql`
  mutation UpdateLearningObjectiveCompletion($id: ID!, $completed: Boolean!) {
    updateLearningObjectiveCompletion(id: $id, completed: $completed) {
      success
      message
    }
  }
`;
export type UpdateLearningObjectiveCompletionMutationFn = Apollo.MutationFunction<
  UpdateLearningObjectiveCompletionMutation,
  UpdateLearningObjectiveCompletionMutationVariables
>;

/**
 * __useUpdateLearningObjectiveCompletionMutation__
 *
 * To run a mutation, you first call `useUpdateLearningObjectiveCompletionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateLearningObjectiveCompletionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateLearningObjectiveCompletionMutation, { data, loading, error }] = useUpdateLearningObjectiveCompletionMutation({
 *   variables: {
 *      id: // value for 'id'
 *      completed: // value for 'completed'
 *   },
 * });
 */
export function useUpdateLearningObjectiveCompletionMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateLearningObjectiveCompletionMutation,
    UpdateLearningObjectiveCompletionMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateLearningObjectiveCompletionMutation,
    UpdateLearningObjectiveCompletionMutationVariables
  >(UpdateLearningObjectiveCompletionDocument, options);
}
export type UpdateLearningObjectiveCompletionMutationHookResult = ReturnType<
  typeof useUpdateLearningObjectiveCompletionMutation
>;
export type UpdateLearningObjectiveCompletionMutationResult =
  Apollo.MutationResult<UpdateLearningObjectiveCompletionMutation>;
export type UpdateLearningObjectiveCompletionMutationOptions = Apollo.BaseMutationOptions<
  UpdateLearningObjectiveCompletionMutation,
  UpdateLearningObjectiveCompletionMutationVariables
>;
export const SetOnboardingStatusDocument = gql`
  mutation SetOnboardingStatus($isOnboarded: Boolean!) {
    setOnboardingStatus(isOnboarded: $isOnboarded) {
      message
      success
    }
  }
`;
export type SetOnboardingStatusMutationFn = Apollo.MutationFunction<
  SetOnboardingStatusMutation,
  SetOnboardingStatusMutationVariables
>;

/**
 * __useSetOnboardingStatusMutation__
 *
 * To run a mutation, you first call `useSetOnboardingStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetOnboardingStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setOnboardingStatusMutation, { data, loading, error }] = useSetOnboardingStatusMutation({
 *   variables: {
 *      isOnboarded: // value for 'isOnboarded'
 *   },
 * });
 */
export function useSetOnboardingStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<SetOnboardingStatusMutation, SetOnboardingStatusMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<SetOnboardingStatusMutation, SetOnboardingStatusMutationVariables>(
    SetOnboardingStatusDocument,
    options
  );
}
export type SetOnboardingStatusMutationHookResult = ReturnType<typeof useSetOnboardingStatusMutation>;
export type SetOnboardingStatusMutationResult = Apollo.MutationResult<SetOnboardingStatusMutation>;
export type SetOnboardingStatusMutationOptions = Apollo.BaseMutationOptions<
  SetOnboardingStatusMutation,
  SetOnboardingStatusMutationVariables
>;
export const UpdateUserModalOnboardingDocument = gql`
  mutation UpdateUserModalOnboarding($modalOnboarding: String!) {
    updateUserModalOnboarding(modalOnboarding: $modalOnboarding) {
      modalOnboarding
    }
  }
`;
export type UpdateUserModalOnboardingMutationFn = Apollo.MutationFunction<
  UpdateUserModalOnboardingMutation,
  UpdateUserModalOnboardingMutationVariables
>;

/**
 * __useUpdateUserModalOnboardingMutation__
 *
 * To run a mutation, you first call `useUpdateUserModalOnboardingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserModalOnboardingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserModalOnboardingMutation, { data, loading, error }] = useUpdateUserModalOnboardingMutation({
 *   variables: {
 *      modalOnboarding: // value for 'modalOnboarding'
 *   },
 * });
 */
export function useUpdateUserModalOnboardingMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateUserModalOnboardingMutation,
    UpdateUserModalOnboardingMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateUserModalOnboardingMutation, UpdateUserModalOnboardingMutationVariables>(
    UpdateUserModalOnboardingDocument,
    options
  );
}
export type UpdateUserModalOnboardingMutationHookResult = ReturnType<typeof useUpdateUserModalOnboardingMutation>;
export type UpdateUserModalOnboardingMutationResult = Apollo.MutationResult<UpdateUserModalOnboardingMutation>;
export type UpdateUserModalOnboardingMutationOptions = Apollo.BaseMutationOptions<
  UpdateUserModalOnboardingMutation,
  UpdateUserModalOnboardingMutationVariables
>;
export const DeleteAccountDocument = gql`
  mutation deleteAccount {
    deleteAccount {
      success
      message
    }
  }
`;
export type DeleteAccountMutationFn = Apollo.MutationFunction<DeleteAccountMutation, DeleteAccountMutationVariables>;

/**
 * __useDeleteAccountMutation__
 *
 * To run a mutation, you first call `useDeleteAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAccountMutation, { data, loading, error }] = useDeleteAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useDeleteAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteAccountMutation, DeleteAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteAccountMutation, DeleteAccountMutationVariables>(DeleteAccountDocument, options);
}
export type DeleteAccountMutationHookResult = ReturnType<typeof useDeleteAccountMutation>;
export type DeleteAccountMutationResult = Apollo.MutationResult<DeleteAccountMutation>;
export type DeleteAccountMutationOptions = Apollo.BaseMutationOptions<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;
export const ResetAccountDocument = gql`
  mutation resetAccount {
    resetAccount {
      success
      message
    }
  }
`;
export type ResetAccountMutationFn = Apollo.MutationFunction<ResetAccountMutation, ResetAccountMutationVariables>;

/**
 * __useResetAccountMutation__
 *
 * To run a mutation, you first call `useResetAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetAccountMutation, { data, loading, error }] = useResetAccountMutation({
 *   variables: {
 *   },
 * });
 */
export function useResetAccountMutation(
  baseOptions?: Apollo.MutationHookOptions<ResetAccountMutation, ResetAccountMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ResetAccountMutation, ResetAccountMutationVariables>(ResetAccountDocument, options);
}
export type ResetAccountMutationHookResult = ReturnType<typeof useResetAccountMutation>;
export type ResetAccountMutationResult = Apollo.MutationResult<ResetAccountMutation>;
export type ResetAccountMutationOptions = Apollo.BaseMutationOptions<
  ResetAccountMutation,
  ResetAccountMutationVariables
>;
export const AddTodoDocument = gql`
  mutation AddTodo(
    $name: String!
    $description: String
    $priority: String
    $date: String
    $completed_date: String
    $isRecurring: Boolean
    $recurringFrequency: String
    $recurringDaily: Boolean
    $recurringDayOfWeek: String
    $recurringDayOfMonth: Int
    $recurringTime: String
    $timeOfDay: String
    $timezone: String
    $isPartOfJourney: Boolean
    $journeyId: ID
  ) {
    addTodo(
      name: $name
      description: $description
      priority: $priority
      date: $date
      completed_date: $completed_date
      isRecurring: $isRecurring
      recurringFrequency: $recurringFrequency
      recurringDaily: $recurringDaily
      recurringDayOfWeek: $recurringDayOfWeek
      recurringDayOfMonth: $recurringDayOfMonth
      recurringTime: $recurringTime
      timeOfDay: $timeOfDay
      timezone: $timezone
      isPartOfJourney: $isPartOfJourney
      journeyId: $journeyId
    ) {
      id
      name
      description
      priority
      date
      completed_date
      isRecurring
      recurringFrequency
      recurringDaily
      recurringDayOfWeek
      recurringDayOfMonth
      recurringTime
      timeOfDay
      timezone
      isPartOfJourney
    }
  }
`;
export type AddTodoMutationFn = Apollo.MutationFunction<AddTodoMutation, AddTodoMutationVariables>;

/**
 * __useAddTodoMutation__
 *
 * To run a mutation, you first call `useAddTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTodoMutation, { data, loading, error }] = useAddTodoMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      priority: // value for 'priority'
 *      date: // value for 'date'
 *      completed_date: // value for 'completed_date'
 *      isRecurring: // value for 'isRecurring'
 *      recurringFrequency: // value for 'recurringFrequency'
 *      recurringDaily: // value for 'recurringDaily'
 *      recurringDayOfWeek: // value for 'recurringDayOfWeek'
 *      recurringDayOfMonth: // value for 'recurringDayOfMonth'
 *      recurringTime: // value for 'recurringTime'
 *      timeOfDay: // value for 'timeOfDay'
 *      timezone: // value for 'timezone'
 *      isPartOfJourney: // value for 'isPartOfJourney'
 *      journeyId: // value for 'journeyId'
 *   },
 * });
 */
export function useAddTodoMutation(
  baseOptions?: Apollo.MutationHookOptions<AddTodoMutation, AddTodoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddTodoMutation, AddTodoMutationVariables>(AddTodoDocument, options);
}
export type AddTodoMutationHookResult = ReturnType<typeof useAddTodoMutation>;
export type AddTodoMutationResult = Apollo.MutationResult<AddTodoMutation>;
export type AddTodoMutationOptions = Apollo.BaseMutationOptions<AddTodoMutation, AddTodoMutationVariables>;
export const UpdateTodoDocument = gql`
  mutation UpdateTodo(
    $id: String!
    $name: String!
    $description: String
    $priority: String
    $date: String
    $completed_date: String
    $completed: Boolean
    $pointsReceived: Int
    $timezone: String!
    $isRecurring: Boolean
    $recurringFrequency: String
    $recurringDaily: Boolean
    $recurringDayOfWeek: String
    $recurringDayOfMonth: Int
    $recurringTime: String
    $timeOfDay: String
    $isPartOfJourney: Boolean
    $journeyId: ID
  ) {
    updateTodo(
      id: $id
      name: $name
      description: $description
      priority: $priority
      date: $date
      completed_date: $completed_date
      completed: $completed
      pointsReceived: $pointsReceived
      timezone: $timezone
      isRecurring: $isRecurring
      recurringFrequency: $recurringFrequency
      recurringDaily: $recurringDaily
      recurringDayOfWeek: $recurringDayOfWeek
      recurringDayOfMonth: $recurringDayOfMonth
      recurringTime: $recurringTime
      timeOfDay: $timeOfDay
      isPartOfJourney: $isPartOfJourney
      journeyId: $journeyId
    ) {
      id
      name
      description
      priority
      date
      completed_date
      completed
      pointsReceived
      timezone
      isRecurring
      recurringFrequency
      recurringDaily
      recurringDayOfWeek
      recurringDayOfMonth
      recurringTime
      timeOfDay
      isPartOfJourney
      journeyId
    }
  }
`;
export type UpdateTodoMutationFn = Apollo.MutationFunction<UpdateTodoMutation, UpdateTodoMutationVariables>;

/**
 * __useUpdateTodoMutation__
 *
 * To run a mutation, you first call `useUpdateTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTodoMutation, { data, loading, error }] = useUpdateTodoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      priority: // value for 'priority'
 *      date: // value for 'date'
 *      completed_date: // value for 'completed_date'
 *      completed: // value for 'completed'
 *      pointsReceived: // value for 'pointsReceived'
 *      timezone: // value for 'timezone'
 *      isRecurring: // value for 'isRecurring'
 *      recurringFrequency: // value for 'recurringFrequency'
 *      recurringDaily: // value for 'recurringDaily'
 *      recurringDayOfWeek: // value for 'recurringDayOfWeek'
 *      recurringDayOfMonth: // value for 'recurringDayOfMonth'
 *      recurringTime: // value for 'recurringTime'
 *      timeOfDay: // value for 'timeOfDay'
 *      isPartOfJourney: // value for 'isPartOfJourney'
 *      journeyId: // value for 'journeyId'
 *   },
 * });
 */
export function useUpdateTodoMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTodoMutation, UpdateTodoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTodoMutation, UpdateTodoMutationVariables>(UpdateTodoDocument, options);
}
export type UpdateTodoMutationHookResult = ReturnType<typeof useUpdateTodoMutation>;
export type UpdateTodoMutationResult = Apollo.MutationResult<UpdateTodoMutation>;
export type UpdateTodoMutationOptions = Apollo.BaseMutationOptions<UpdateTodoMutation, UpdateTodoMutationVariables>;
export const SessionDocument = gql`
  query Session {
    validateSession {
      isValid
    }
  }
`;

/**
 * __useSessionQuery__
 *
 * To run a query within a React component, call `useSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useSessionQuery(baseOptions?: Apollo.QueryHookOptions<SessionQuery, SessionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SessionQuery, SessionQueryVariables>(SessionDocument, options);
}
export function useSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SessionQuery, SessionQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SessionQuery, SessionQueryVariables>(SessionDocument, options);
}
export function useSessionSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SessionQuery, SessionQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SessionQuery, SessionQueryVariables>(SessionDocument, options);
}
export type SessionQueryHookResult = ReturnType<typeof useSessionQuery>;
export type SessionLazyQueryHookResult = ReturnType<typeof useSessionLazyQuery>;
export type SessionSuspenseQueryHookResult = ReturnType<typeof useSessionSuspenseQuery>;
export type SessionQueryResult = Apollo.QueryResult<SessionQuery, SessionQueryVariables>;
export const CareerByIdDocument = gql`
  query CareerById($id: ID!) {
    getCareerById(id: $id) {
      id
      name
      brief_description
      description
      created
      last_modified
      path
      trends
      pros
      cons
      skills
      core_skills
      growth
      salary
      url
    }
  }
`;

/**
 * __useCareerByIdQuery__
 *
 * To run a query within a React component, call `useCareerByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useCareerByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCareerByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCareerByIdQuery(
  baseOptions: Apollo.QueryHookOptions<CareerByIdQuery, CareerByIdQueryVariables> &
    ({ variables: CareerByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CareerByIdQuery, CareerByIdQueryVariables>(CareerByIdDocument, options);
}
export function useCareerByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<CareerByIdQuery, CareerByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CareerByIdQuery, CareerByIdQueryVariables>(CareerByIdDocument, options);
}
export function useCareerByIdSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<CareerByIdQuery, CareerByIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<CareerByIdQuery, CareerByIdQueryVariables>(CareerByIdDocument, options);
}
export type CareerByIdQueryHookResult = ReturnType<typeof useCareerByIdQuery>;
export type CareerByIdLazyQueryHookResult = ReturnType<typeof useCareerByIdLazyQuery>;
export type CareerByIdSuspenseQueryHookResult = ReturnType<typeof useCareerByIdSuspenseQuery>;
export type CareerByIdQueryResult = Apollo.QueryResult<CareerByIdQuery, CareerByIdQueryVariables>;
export const GetCareerHeaderDocument = gql`
  query GetCareerHeader {
    getCareerHeader {
      jobTitle
      careerGoal
      careerName
      careerId
    }
  }
`;

/**
 * __useGetCareerHeaderQuery__
 *
 * To run a query within a React component, call `useGetCareerHeaderQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareerHeaderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareerHeaderQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCareerHeaderQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>(GetCareerHeaderDocument, options);
}
export function useGetCareerHeaderLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>(GetCareerHeaderDocument, options);
}
export function useGetCareerHeaderSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>(GetCareerHeaderDocument, options);
}
export type GetCareerHeaderQueryHookResult = ReturnType<typeof useGetCareerHeaderQuery>;
export type GetCareerHeaderLazyQueryHookResult = ReturnType<typeof useGetCareerHeaderLazyQuery>;
export type GetCareerHeaderSuspenseQueryHookResult = ReturnType<typeof useGetCareerHeaderSuspenseQuery>;
export type GetCareerHeaderQueryResult = Apollo.QueryResult<GetCareerHeaderQuery, GetCareerHeaderQueryVariables>;
export const GetCareerTopicsDocument = gql`
  query getCareerTopics($careerId: ID!) {
    getCareerTopics(careerId: $careerId) {
      name
      description
      mastery
    }
  }
`;

/**
 * __useGetCareerTopicsQuery__
 *
 * To run a query within a React component, call `useGetCareerTopicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareerTopicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareerTopicsQuery({
 *   variables: {
 *      careerId: // value for 'careerId'
 *   },
 * });
 */
export function useGetCareerTopicsQuery(
  baseOptions: Apollo.QueryHookOptions<GetCareerTopicsQuery, GetCareerTopicsQueryVariables> &
    ({ variables: GetCareerTopicsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCareerTopicsQuery, GetCareerTopicsQueryVariables>(GetCareerTopicsDocument, options);
}
export function useGetCareerTopicsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCareerTopicsQuery, GetCareerTopicsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCareerTopicsQuery, GetCareerTopicsQueryVariables>(GetCareerTopicsDocument, options);
}
export function useGetCareerTopicsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCareerTopicsQuery, GetCareerTopicsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCareerTopicsQuery, GetCareerTopicsQueryVariables>(GetCareerTopicsDocument, options);
}
export type GetCareerTopicsQueryHookResult = ReturnType<typeof useGetCareerTopicsQuery>;
export type GetCareerTopicsLazyQueryHookResult = ReturnType<typeof useGetCareerTopicsLazyQuery>;
export type GetCareerTopicsSuspenseQueryHookResult = ReturnType<typeof useGetCareerTopicsSuspenseQuery>;
export type GetCareerTopicsQueryResult = Apollo.QueryResult<GetCareerTopicsQuery, GetCareerTopicsQueryVariables>;
export const GetCareersDocument = gql`
  query GetCareers {
    getCareers {
      id
      name
      brief_description
      description
      created
      last_modified
      path
      trends
      pros
      cons
      skills
      core_skills
      growth
      salary
      url
    }
  }
`;

/**
 * __useGetCareersQuery__
 *
 * To run a query within a React component, call `useGetCareersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCareersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCareersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCareersQuery(baseOptions?: Apollo.QueryHookOptions<GetCareersQuery, GetCareersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCareersQuery, GetCareersQueryVariables>(GetCareersDocument, options);
}
export function useGetCareersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCareersQuery, GetCareersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCareersQuery, GetCareersQueryVariables>(GetCareersDocument, options);
}
export function useGetCareersSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCareersQuery, GetCareersQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCareersQuery, GetCareersQueryVariables>(GetCareersDocument, options);
}
export type GetCareersQueryHookResult = ReturnType<typeof useGetCareersQuery>;
export type GetCareersLazyQueryHookResult = ReturnType<typeof useGetCareersLazyQuery>;
export type GetCareersSuspenseQueryHookResult = ReturnType<typeof useGetCareersSuspenseQuery>;
export type GetCareersQueryResult = Apollo.QueryResult<GetCareersQuery, GetCareersQueryVariables>;
export const DailyLimitDocument = gql`
  query DailyLimit {
    getDailyLimit {
      dailyRequests
      result
    }
  }
`;

/**
 * __useDailyLimitQuery__
 *
 * To run a query within a React component, call `useDailyLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyLimitQuery({
 *   variables: {
 *   },
 * });
 */
export function useDailyLimitQuery(baseOptions?: Apollo.QueryHookOptions<DailyLimitQuery, DailyLimitQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DailyLimitQuery, DailyLimitQueryVariables>(DailyLimitDocument, options);
}
export function useDailyLimitLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DailyLimitQuery, DailyLimitQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DailyLimitQuery, DailyLimitQueryVariables>(DailyLimitDocument, options);
}
export function useDailyLimitSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DailyLimitQuery, DailyLimitQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DailyLimitQuery, DailyLimitQueryVariables>(DailyLimitDocument, options);
}
export type DailyLimitQueryHookResult = ReturnType<typeof useDailyLimitQuery>;
export type DailyLimitLazyQueryHookResult = ReturnType<typeof useDailyLimitLazyQuery>;
export type DailyLimitSuspenseQueryHookResult = ReturnType<typeof useDailyLimitSuspenseQuery>;
export type DailyLimitQueryResult = Apollo.QueryResult<DailyLimitQuery, DailyLimitQueryVariables>;
export const GetChatByContextAndParamsDocument = gql`
  query GetChatByContextAndParams($context: String!, $params: JSON) {
    getChatByContextAndParams(context: $context, params: $params) {
      id
      date
      name
      userId
      objectId
      context
      params
      conversation {
        id
        sender
        content
        type
        nodeId
        snackType
        parameters {
          lastGeneratedTopic
          lastGeneratedLabel
          questContext
          userResponse
        }
        aspectDetails {
          name
          category
          id
          friendId
          relationshipType
          connectionStrength
          frequencyOfInteraction
          lastInteractionDate
          sharedInterests
          sharedGoals
          sharedEventsAttended
          friendPersonalityTraits
          communicationChannel
          level
          startDate
          lastUpdated
          learningStyle
          language
          timeOfDay
          learningDuration
          contentType
          notificationPreferences
          type
          completionStatus
          deadline
          priority
          value
          description
          date
          importance
          status
          endDate
          text
          sentiment
          context
          intensity
          timestamp
          trigger
          calories
          carbohydrates_grams
          fat_grams
          fiber_grams
          protein_grams
          serving_size
          sodium_mg
          sugars_grams
          totalCalories
          totalCarbs
          totalFats
          totalProteins
          totalCaloriesBurned
          totalDurationMinutes
          totalWorkouts
          water_intake_oz
        }
      }
    }
  }
`;

/**
 * __useGetChatByContextAndParamsQuery__
 *
 * To run a query within a React component, call `useGetChatByContextAndParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatByContextAndParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatByContextAndParamsQuery({
 *   variables: {
 *      context: // value for 'context'
 *      params: // value for 'params'
 *   },
 * });
 */
export function useGetChatByContextAndParamsQuery(
  baseOptions: Apollo.QueryHookOptions<GetChatByContextAndParamsQuery, GetChatByContextAndParamsQueryVariables> &
    ({ variables: GetChatByContextAndParamsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatByContextAndParamsQuery, GetChatByContextAndParamsQueryVariables>(
    GetChatByContextAndParamsDocument,
    options
  );
}
export function useGetChatByContextAndParamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChatByContextAndParamsQuery, GetChatByContextAndParamsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatByContextAndParamsQuery, GetChatByContextAndParamsQueryVariables>(
    GetChatByContextAndParamsDocument,
    options
  );
}
export function useGetChatByContextAndParamsSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetChatByContextAndParamsQuery, GetChatByContextAndParamsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetChatByContextAndParamsQuery, GetChatByContextAndParamsQueryVariables>(
    GetChatByContextAndParamsDocument,
    options
  );
}
export type GetChatByContextAndParamsQueryHookResult = ReturnType<typeof useGetChatByContextAndParamsQuery>;
export type GetChatByContextAndParamsLazyQueryHookResult = ReturnType<typeof useGetChatByContextAndParamsLazyQuery>;
export type GetChatByContextAndParamsSuspenseQueryHookResult = ReturnType<
  typeof useGetChatByContextAndParamsSuspenseQuery
>;
export type GetChatByContextAndParamsQueryResult = Apollo.QueryResult<
  GetChatByContextAndParamsQuery,
  GetChatByContextAndParamsQueryVariables
>;
export const GetChatByIdDocument = gql`
  query GetChatById($id: ID!) {
    getChatById(id: $id) {
      id
      date
      name
      userId
      objectId
      context
      params
      conversation {
        id
        sender
        content
        type
        nodeId
        snackType
        parameters {
          lastGeneratedTopic
          lastGeneratedLabel
          questContext
          userResponse
        }
        aspectDetails {
          name
          category
          id
          friendId
          relationshipType
          connectionStrength
          frequencyOfInteraction
          lastInteractionDate
          sharedInterests
          sharedGoals
          sharedEventsAttended
          friendPersonalityTraits
          communicationChannel
          level
          startDate
          lastUpdated
          learningStyle
          language
          timeOfDay
          learningDuration
          contentType
          notificationPreferences
          type
          completionStatus
          deadline
          priority
          value
          description
          date
          importance
          status
          endDate
          text
          sentiment
          context
          intensity
          timestamp
          trigger
          calories
          carbohydrates_grams
          fat_grams
          fiber_grams
          protein_grams
          serving_size
          sodium_mg
          sugars_grams
          totalCalories
          totalCarbs
          totalFats
          totalProteins
          totalCaloriesBurned
          totalDurationMinutes
          totalWorkouts
          water_intake_oz
        }
      }
    }
  }
`;

/**
 * __useGetChatByIdQuery__
 *
 * To run a query within a React component, call `useGetChatByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetChatByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetChatByIdQuery, GetChatByIdQueryVariables> &
    ({ variables: GetChatByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatByIdQuery, GetChatByIdQueryVariables>(GetChatByIdDocument, options);
}
export function useGetChatByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChatByIdQuery, GetChatByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatByIdQuery, GetChatByIdQueryVariables>(GetChatByIdDocument, options);
}
export function useGetChatByIdSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetChatByIdQuery, GetChatByIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetChatByIdQuery, GetChatByIdQueryVariables>(GetChatByIdDocument, options);
}
export type GetChatByIdQueryHookResult = ReturnType<typeof useGetChatByIdQuery>;
export type GetChatByIdLazyQueryHookResult = ReturnType<typeof useGetChatByIdLazyQuery>;
export type GetChatByIdSuspenseQueryHookResult = ReturnType<typeof useGetChatByIdSuspenseQuery>;
export type GetChatByIdQueryResult = Apollo.QueryResult<GetChatByIdQuery, GetChatByIdQueryVariables>;
export const GetChatByObjectIdDocument = gql`
  query GetChatByObjectId($objectId: ID!, $context: String!) {
    getChatByObjectId(objectId: $objectId, context: $context) {
      id
      date
      name
      userId
      objectId
      context
      params
      conversation {
        id
        sender
        content
        type
        nodeId
        snackType
        parameters {
          lastGeneratedTopic
          lastGeneratedLabel
          questContext
          userResponse
        }
        aspectDetails {
          name
          category
          id
          friendId
          relationshipType
          connectionStrength
          frequencyOfInteraction
          lastInteractionDate
          sharedInterests
          sharedGoals
          sharedEventsAttended
          friendPersonalityTraits
          communicationChannel
          level
          startDate
          lastUpdated
          learningStyle
          language
          timeOfDay
          learningDuration
          contentType
          notificationPreferences
          type
          completionStatus
          deadline
          priority
          value
          description
          date
          importance
          status
          endDate
          text
          sentiment
          context
          intensity
          timestamp
          trigger
          calories
          carbohydrates_grams
          fat_grams
          fiber_grams
          protein_grams
          serving_size
          sodium_mg
          sugars_grams
          totalCalories
          totalCarbs
          totalFats
          totalProteins
          totalCaloriesBurned
          totalDurationMinutes
          totalWorkouts
          water_intake_oz
        }
      }
    }
  }
`;

/**
 * __useGetChatByObjectIdQuery__
 *
 * To run a query within a React component, call `useGetChatByObjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatByObjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatByObjectIdQuery({
 *   variables: {
 *      objectId: // value for 'objectId'
 *      context: // value for 'context'
 *   },
 * });
 */
export function useGetChatByObjectIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables> &
    ({ variables: GetChatByObjectIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables>(GetChatByObjectIdDocument, options);
}
export function useGetChatByObjectIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables>(
    GetChatByObjectIdDocument,
    options
  );
}
export function useGetChatByObjectIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables>(
    GetChatByObjectIdDocument,
    options
  );
}
export type GetChatByObjectIdQueryHookResult = ReturnType<typeof useGetChatByObjectIdQuery>;
export type GetChatByObjectIdLazyQueryHookResult = ReturnType<typeof useGetChatByObjectIdLazyQuery>;
export type GetChatByObjectIdSuspenseQueryHookResult = ReturnType<typeof useGetChatByObjectIdSuspenseQuery>;
export type GetChatByObjectIdQueryResult = Apollo.QueryResult<GetChatByObjectIdQuery, GetChatByObjectIdQueryVariables>;
export const GetChatsDocument = gql`
  query GetChats {
    getChats {
      id
      date
      name
      userId
      objectId
      context
      params
      url
    }
  }
`;

/**
 * __useGetChatsQuery__
 *
 * To run a query within a React component, call `useGetChatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetChatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetChatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetChatsQuery(baseOptions?: Apollo.QueryHookOptions<GetChatsQuery, GetChatsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetChatsQuery, GetChatsQueryVariables>(GetChatsDocument, options);
}
export function useGetChatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetChatsQuery, GetChatsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetChatsQuery, GetChatsQueryVariables>(GetChatsDocument, options);
}
export function useGetChatsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetChatsQuery, GetChatsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetChatsQuery, GetChatsQueryVariables>(GetChatsDocument, options);
}
export type GetChatsQueryHookResult = ReturnType<typeof useGetChatsQuery>;
export type GetChatsLazyQueryHookResult = ReturnType<typeof useGetChatsLazyQuery>;
export type GetChatsSuspenseQueryHookResult = ReturnType<typeof useGetChatsSuspenseQuery>;
export type GetChatsQueryResult = Apollo.QueryResult<GetChatsQuery, GetChatsQueryVariables>;
export const TodayChatDocument = gql`
  query TodayChat($timezone: String!) {
    getTodayChat(timezone: $timezone) {
      id
      date
      name
      timezone
      objectId
      context
      params
      userId
      conversation {
        id
        sender
        content
        type
        nodeId
        snackType
        parameters {
          lastGeneratedTopic
          lastGeneratedLabel
          questContext
          userResponse
        }
        aspectDetails {
          name
          category
          id
          friendId
          relationshipType
          connectionStrength
          frequencyOfInteraction
          lastInteractionDate
          sharedInterests
          sharedGoals
          sharedEventsAttended
          friendPersonalityTraits
          communicationChannel
          level
          startDate
          lastUpdated
          learningStyle
          language
          timeOfDay
          learningDuration
          contentType
          notificationPreferences
          type
          completionStatus
          deadline
          priority
          value
          description
          date
          importance
          status
          endDate
          text
          sentiment
          context
          intensity
          timestamp
          trigger
          calories
          carbohydrates_grams
          fat_grams
          fiber_grams
          protein_grams
          serving_size
          sodium_mg
          sugars_grams
          totalCalories
          totalCarbs
          totalFats
          totalProteins
          totalCaloriesBurned
          totalDurationMinutes
          totalWorkouts
          water_intake_oz
        }
      }
    }
  }
`;

/**
 * __useTodayChatQuery__
 *
 * To run a query within a React component, call `useTodayChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodayChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodayChatQuery({
 *   variables: {
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useTodayChatQuery(
  baseOptions: Apollo.QueryHookOptions<TodayChatQuery, TodayChatQueryVariables> &
    ({ variables: TodayChatQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TodayChatQuery, TodayChatQueryVariables>(TodayChatDocument, options);
}
export function useTodayChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TodayChatQuery, TodayChatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TodayChatQuery, TodayChatQueryVariables>(TodayChatDocument, options);
}
export function useTodayChatSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TodayChatQuery, TodayChatQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TodayChatQuery, TodayChatQueryVariables>(TodayChatDocument, options);
}
export type TodayChatQueryHookResult = ReturnType<typeof useTodayChatQuery>;
export type TodayChatLazyQueryHookResult = ReturnType<typeof useTodayChatLazyQuery>;
export type TodayChatSuspenseQueryHookResult = ReturnType<typeof useTodayChatSuspenseQuery>;
export type TodayChatQueryResult = Apollo.QueryResult<TodayChatQuery, TodayChatQueryVariables>;
export const GetContentByIdDocument = gql`
  query GetContentById($id: ID!) {
    getContentById(id: $id) {
      id
      name
      standard
      weeks
      objectives
      modules {
        module_name
        assignments {
          assignment_name
          html
        }
      }
      created
      last_modified
    }
  }
`;

/**
 * __useGetContentByIdQuery__
 *
 * To run a query within a React component, call `useGetContentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContentByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContentByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetContentByIdQuery, GetContentByIdQueryVariables> &
    ({ variables: GetContentByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetContentByIdQuery, GetContentByIdQueryVariables>(GetContentByIdDocument, options);
}
export function useGetContentByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetContentByIdQuery, GetContentByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetContentByIdQuery, GetContentByIdQueryVariables>(GetContentByIdDocument, options);
}
export function useGetContentByIdSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetContentByIdQuery, GetContentByIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetContentByIdQuery, GetContentByIdQueryVariables>(GetContentByIdDocument, options);
}
export type GetContentByIdQueryHookResult = ReturnType<typeof useGetContentByIdQuery>;
export type GetContentByIdLazyQueryHookResult = ReturnType<typeof useGetContentByIdLazyQuery>;
export type GetContentByIdSuspenseQueryHookResult = ReturnType<typeof useGetContentByIdSuspenseQuery>;
export type GetContentByIdQueryResult = Apollo.QueryResult<GetContentByIdQuery, GetContentByIdQueryVariables>;
export const GetGeneratedContentDocument = gql`
  query GetGeneratedContent {
    getGeneratedContent {
      id
      name
      standard
      weeks
      objectives
      modules {
        module_name
        assignments {
          assignment_name
          html
        }
      }
      created
      last_modified
    }
  }
`;

/**
 * __useGetGeneratedContentQuery__
 *
 * To run a query within a React component, call `useGetGeneratedContentQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGeneratedContentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGeneratedContentQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGeneratedContentQuery(
  baseOptions?: Apollo.QueryHookOptions<GetGeneratedContentQuery, GetGeneratedContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetGeneratedContentQuery, GetGeneratedContentQueryVariables>(
    GetGeneratedContentDocument,
    options
  );
}
export function useGetGeneratedContentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetGeneratedContentQuery, GetGeneratedContentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetGeneratedContentQuery, GetGeneratedContentQueryVariables>(
    GetGeneratedContentDocument,
    options
  );
}
export function useGetGeneratedContentSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetGeneratedContentQuery, GetGeneratedContentQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetGeneratedContentQuery, GetGeneratedContentQueryVariables>(
    GetGeneratedContentDocument,
    options
  );
}
export type GetGeneratedContentQueryHookResult = ReturnType<typeof useGetGeneratedContentQuery>;
export type GetGeneratedContentLazyQueryHookResult = ReturnType<typeof useGetGeneratedContentLazyQuery>;
export type GetGeneratedContentSuspenseQueryHookResult = ReturnType<typeof useGetGeneratedContentSuspenseQuery>;
export type GetGeneratedContentQueryResult = Apollo.QueryResult<
  GetGeneratedContentQuery,
  GetGeneratedContentQueryVariables
>;
export const GetCourseDocument = gql`
  query GetCourse($id: ID!) {
    getCourse(id: $id) {
      data {
        id
        title
        learningStandards
        lengthInWeeks
        objectives
        last_modified
        modules {
          id
          title
          order
          lastModified
          resources {
            id
            assignments {
              id
              title
              body
              due_date
              lastModified
            }
            pages {
              id
              title
              body
              lastModified
            }
          }
        }
      }
    }
  }
`;

/**
 * __useGetCourseQuery__
 *
 * To run a query within a React component, call `useGetCourseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourseQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCourseQuery(
  baseOptions: Apollo.QueryHookOptions<GetCourseQuery, GetCourseQueryVariables> &
    ({ variables: GetCourseQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCourseQuery, GetCourseQueryVariables>(GetCourseDocument, options);
}
export function useGetCourseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetCourseQuery, GetCourseQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCourseQuery, GetCourseQueryVariables>(GetCourseDocument, options);
}
export function useGetCourseSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetCourseQuery, GetCourseQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetCourseQuery, GetCourseQueryVariables>(GetCourseDocument, options);
}
export type GetCourseQueryHookResult = ReturnType<typeof useGetCourseQuery>;
export type GetCourseLazyQueryHookResult = ReturnType<typeof useGetCourseLazyQuery>;
export type GetCourseSuspenseQueryHookResult = ReturnType<typeof useGetCourseSuspenseQuery>;
export type GetCourseQueryResult = Apollo.QueryResult<GetCourseQuery, GetCourseQueryVariables>;
export const GetAllCoursesBySchoolDocument = gql`
  query GetAllCoursesBySchool($schoolId: ID!) {
    getAllCoursesBySchool(schoolId: $schoolId) {
      data {
        id
        title
        learningStandards
        lengthInWeeks
        objectives
        last_modified
      }
    }
  }
`;

/**
 * __useGetAllCoursesBySchoolQuery__
 *
 * To run a query within a React component, call `useGetAllCoursesBySchoolQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCoursesBySchoolQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCoursesBySchoolQuery({
 *   variables: {
 *      schoolId: // value for 'schoolId'
 *   },
 * });
 */
export function useGetAllCoursesBySchoolQuery(
  baseOptions: Apollo.QueryHookOptions<GetAllCoursesBySchoolQuery, GetAllCoursesBySchoolQueryVariables> &
    ({ variables: GetAllCoursesBySchoolQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllCoursesBySchoolQuery, GetAllCoursesBySchoolQueryVariables>(
    GetAllCoursesBySchoolDocument,
    options
  );
}
export function useGetAllCoursesBySchoolLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllCoursesBySchoolQuery, GetAllCoursesBySchoolQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllCoursesBySchoolQuery, GetAllCoursesBySchoolQueryVariables>(
    GetAllCoursesBySchoolDocument,
    options
  );
}
export function useGetAllCoursesBySchoolSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetAllCoursesBySchoolQuery, GetAllCoursesBySchoolQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetAllCoursesBySchoolQuery, GetAllCoursesBySchoolQueryVariables>(
    GetAllCoursesBySchoolDocument,
    options
  );
}
export type GetAllCoursesBySchoolQueryHookResult = ReturnType<typeof useGetAllCoursesBySchoolQuery>;
export type GetAllCoursesBySchoolLazyQueryHookResult = ReturnType<typeof useGetAllCoursesBySchoolLazyQuery>;
export type GetAllCoursesBySchoolSuspenseQueryHookResult = ReturnType<typeof useGetAllCoursesBySchoolSuspenseQuery>;
export type GetAllCoursesBySchoolQueryResult = Apollo.QueryResult<
  GetAllCoursesBySchoolQuery,
  GetAllCoursesBySchoolQueryVariables
>;
export const SuggestionsDocument = gql`
  query Suggestions {
    getSuggestions {
      type
      content
      integrationId
      classId
      assignmentId
      journeyId
      trackName
    }
  }
`;

/**
 * __useSuggestionsQuery__
 *
 * To run a query within a React component, call `useSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSuggestionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSuggestionsQuery(
  baseOptions?: Apollo.QueryHookOptions<SuggestionsQuery, SuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SuggestionsQuery, SuggestionsQueryVariables>(SuggestionsDocument, options);
}
export function useSuggestionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SuggestionsQuery, SuggestionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SuggestionsQuery, SuggestionsQueryVariables>(SuggestionsDocument, options);
}
export function useSuggestionsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SuggestionsQuery, SuggestionsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SuggestionsQuery, SuggestionsQueryVariables>(SuggestionsDocument, options);
}
export type SuggestionsQueryHookResult = ReturnType<typeof useSuggestionsQuery>;
export type SuggestionsLazyQueryHookResult = ReturnType<typeof useSuggestionsLazyQuery>;
export type SuggestionsSuspenseQueryHookResult = ReturnType<typeof useSuggestionsSuspenseQuery>;
export type SuggestionsQueryResult = Apollo.QueryResult<SuggestionsQuery, SuggestionsQueryVariables>;
export const LevelDetailsDocument = gql`
  query LevelDetails {
    levelDetails: getLevelDetails {
      name
      currentXP
      level
      xpToNextLevel
      startingXPForCurrentLevel
      progressPercentage
    }
  }
`;

/**
 * __useLevelDetailsQuery__
 *
 * To run a query within a React component, call `useLevelDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLevelDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLevelDetailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useLevelDetailsQuery(
  baseOptions?: Apollo.QueryHookOptions<LevelDetailsQuery, LevelDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<LevelDetailsQuery, LevelDetailsQueryVariables>(LevelDetailsDocument, options);
}
export function useLevelDetailsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<LevelDetailsQuery, LevelDetailsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<LevelDetailsQuery, LevelDetailsQueryVariables>(LevelDetailsDocument, options);
}
export function useLevelDetailsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<LevelDetailsQuery, LevelDetailsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<LevelDetailsQuery, LevelDetailsQueryVariables>(LevelDetailsDocument, options);
}
export type LevelDetailsQueryHookResult = ReturnType<typeof useLevelDetailsQuery>;
export type LevelDetailsLazyQueryHookResult = ReturnType<typeof useLevelDetailsLazyQuery>;
export type LevelDetailsSuspenseQueryHookResult = ReturnType<typeof useLevelDetailsSuspenseQuery>;
export type LevelDetailsQueryResult = Apollo.QueryResult<LevelDetailsQuery, LevelDetailsQueryVariables>;
export const TodayGameDocument = gql`
  query TodayGame($timezone: String!) {
    getTodayGame(timezone: $timezone) {
      chatId
      refreshCounter
      dailyTaskGoalForToday
      dailyTaskCompletedForToday
      dailyLearningModulesGoalForToday
      dailyLearningModulesCompletedForToday
      dailyCalorieGoalForToday
      dailyCalorieCompletedForToday
      assignedQuests {
        id
        title
        description
        isComplete
      }
    }
  }
`;

/**
 * __useTodayGameQuery__
 *
 * To run a query within a React component, call `useTodayGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodayGameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodayGameQuery({
 *   variables: {
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useTodayGameQuery(
  baseOptions: Apollo.QueryHookOptions<TodayGameQuery, TodayGameQueryVariables> &
    ({ variables: TodayGameQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TodayGameQuery, TodayGameQueryVariables>(TodayGameDocument, options);
}
export function useTodayGameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TodayGameQuery, TodayGameQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TodayGameQuery, TodayGameQueryVariables>(TodayGameDocument, options);
}
export function useTodayGameSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TodayGameQuery, TodayGameQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TodayGameQuery, TodayGameQueryVariables>(TodayGameDocument, options);
}
export type TodayGameQueryHookResult = ReturnType<typeof useTodayGameQuery>;
export type TodayGameLazyQueryHookResult = ReturnType<typeof useTodayGameLazyQuery>;
export type TodayGameSuspenseQueryHookResult = ReturnType<typeof useTodayGameSuspenseQuery>;
export type TodayGameQueryResult = Apollo.QueryResult<TodayGameQuery, TodayGameQueryVariables>;
export const DailyExerciseTotalsByDateDocument = gql`
  query DailyExerciseTotalsByDate($date: String!, $timezone: String!) {
    getDailyExerciseTotalsByDate(date: $date, timezone: $timezone) {
      totalCaloriesBurned
      totalDurationMinutes
      totalWorkouts
      workoutsList {
        name
        type
      }
    }
  }
`;

/**
 * __useDailyExerciseTotalsByDateQuery__
 *
 * To run a query within a React component, call `useDailyExerciseTotalsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyExerciseTotalsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyExerciseTotalsByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useDailyExerciseTotalsByDateQuery(
  baseOptions: Apollo.QueryHookOptions<DailyExerciseTotalsByDateQuery, DailyExerciseTotalsByDateQueryVariables> &
    ({ variables: DailyExerciseTotalsByDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DailyExerciseTotalsByDateQuery, DailyExerciseTotalsByDateQueryVariables>(
    DailyExerciseTotalsByDateDocument,
    options
  );
}
export function useDailyExerciseTotalsByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DailyExerciseTotalsByDateQuery, DailyExerciseTotalsByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DailyExerciseTotalsByDateQuery, DailyExerciseTotalsByDateQueryVariables>(
    DailyExerciseTotalsByDateDocument,
    options
  );
}
export function useDailyExerciseTotalsByDateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DailyExerciseTotalsByDateQuery, DailyExerciseTotalsByDateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DailyExerciseTotalsByDateQuery, DailyExerciseTotalsByDateQueryVariables>(
    DailyExerciseTotalsByDateDocument,
    options
  );
}
export type DailyExerciseTotalsByDateQueryHookResult = ReturnType<typeof useDailyExerciseTotalsByDateQuery>;
export type DailyExerciseTotalsByDateLazyQueryHookResult = ReturnType<typeof useDailyExerciseTotalsByDateLazyQuery>;
export type DailyExerciseTotalsByDateSuspenseQueryHookResult = ReturnType<
  typeof useDailyExerciseTotalsByDateSuspenseQuery
>;
export type DailyExerciseTotalsByDateQueryResult = Apollo.QueryResult<
  DailyExerciseTotalsByDateQuery,
  DailyExerciseTotalsByDateQueryVariables
>;
export const DailyNutritionTotalsByDateDocument = gql`
  query DailyNutritionTotalsByDate($date: String!, $timezone: String!) {
    getDailyNutritionTotalsByDate(date: $date, timezone: $timezone) {
      totalCalories
      totalCarbs
      totalFats
      totalProteins
    }
  }
`;

/**
 * __useDailyNutritionTotalsByDateQuery__
 *
 * To run a query within a React component, call `useDailyNutritionTotalsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useDailyNutritionTotalsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDailyNutritionTotalsByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useDailyNutritionTotalsByDateQuery(
  baseOptions: Apollo.QueryHookOptions<DailyNutritionTotalsByDateQuery, DailyNutritionTotalsByDateQueryVariables> &
    ({ variables: DailyNutritionTotalsByDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DailyNutritionTotalsByDateQuery, DailyNutritionTotalsByDateQueryVariables>(
    DailyNutritionTotalsByDateDocument,
    options
  );
}
export function useDailyNutritionTotalsByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DailyNutritionTotalsByDateQuery, DailyNutritionTotalsByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DailyNutritionTotalsByDateQuery, DailyNutritionTotalsByDateQueryVariables>(
    DailyNutritionTotalsByDateDocument,
    options
  );
}
export function useDailyNutritionTotalsByDateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<DailyNutritionTotalsByDateQuery, DailyNutritionTotalsByDateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DailyNutritionTotalsByDateQuery, DailyNutritionTotalsByDateQueryVariables>(
    DailyNutritionTotalsByDateDocument,
    options
  );
}
export type DailyNutritionTotalsByDateQueryHookResult = ReturnType<typeof useDailyNutritionTotalsByDateQuery>;
export type DailyNutritionTotalsByDateLazyQueryHookResult = ReturnType<typeof useDailyNutritionTotalsByDateLazyQuery>;
export type DailyNutritionTotalsByDateSuspenseQueryHookResult = ReturnType<
  typeof useDailyNutritionTotalsByDateSuspenseQuery
>;
export type DailyNutritionTotalsByDateQueryResult = Apollo.QueryResult<
  DailyNutritionTotalsByDateQuery,
  DailyNutritionTotalsByDateQueryVariables
>;
export const FoodByDateDocument = gql`
  query FoodByDate($date: String!, $timezone: String!) {
    getFoodsByDate(date: $date, timezone: $timezone) {
      name
      calories
      carbohydrates_grams
      fat_grams
      fiber_grams
      protein_grams
      serving_size
      sodium_mg
      sugars_grams
    }
  }
`;

/**
 * __useFoodByDateQuery__
 *
 * To run a query within a React component, call `useFoodByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useFoodByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFoodByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useFoodByDateQuery(
  baseOptions: Apollo.QueryHookOptions<FoodByDateQuery, FoodByDateQueryVariables> &
    ({ variables: FoodByDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FoodByDateQuery, FoodByDateQueryVariables>(FoodByDateDocument, options);
}
export function useFoodByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FoodByDateQuery, FoodByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FoodByDateQuery, FoodByDateQueryVariables>(FoodByDateDocument, options);
}
export function useFoodByDateSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FoodByDateQuery, FoodByDateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FoodByDateQuery, FoodByDateQueryVariables>(FoodByDateDocument, options);
}
export type FoodByDateQueryHookResult = ReturnType<typeof useFoodByDateQuery>;
export type FoodByDateLazyQueryHookResult = ReturnType<typeof useFoodByDateLazyQuery>;
export type FoodByDateSuspenseQueryHookResult = ReturnType<typeof useFoodByDateSuspenseQuery>;
export type FoodByDateQueryResult = Apollo.QueryResult<FoodByDateQuery, FoodByDateQueryVariables>;
export const WaterIntakeByDateDocument = gql`
  query WaterIntakeByDate($date: String!, $timezone: String!) {
    getWaterIntakeByDate(date: $date, timezone: $timezone) {
      totalWaterIntake
      goalOz
      percentageTowardsGoal
    }
  }
`;

/**
 * __useWaterIntakeByDateQuery__
 *
 * To run a query within a React component, call `useWaterIntakeByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useWaterIntakeByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWaterIntakeByDateQuery({
 *   variables: {
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useWaterIntakeByDateQuery(
  baseOptions: Apollo.QueryHookOptions<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables> &
    ({ variables: WaterIntakeByDateQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables>(WaterIntakeByDateDocument, options);
}
export function useWaterIntakeByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables>(
    WaterIntakeByDateDocument,
    options
  );
}
export function useWaterIntakeByDateSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables>(
    WaterIntakeByDateDocument,
    options
  );
}
export type WaterIntakeByDateQueryHookResult = ReturnType<typeof useWaterIntakeByDateQuery>;
export type WaterIntakeByDateLazyQueryHookResult = ReturnType<typeof useWaterIntakeByDateLazyQuery>;
export type WaterIntakeByDateSuspenseQueryHookResult = ReturnType<typeof useWaterIntakeByDateSuspenseQuery>;
export type WaterIntakeByDateQueryResult = Apollo.QueryResult<WaterIntakeByDateQuery, WaterIntakeByDateQueryVariables>;
export const InviteCountDocument = gql`
  query InviteCount {
    userInviteCount {
      count
    }
    me {
      name
      username
      gender
      isOnboarded
      isPro
      phoneNumber
      isEmailVerified
    }
  }
`;

/**
 * __useInviteCountQuery__
 *
 * To run a query within a React component, call `useInviteCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useInviteCountQuery(
  baseOptions?: Apollo.QueryHookOptions<InviteCountQuery, InviteCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<InviteCountQuery, InviteCountQueryVariables>(InviteCountDocument, options);
}
export function useInviteCountLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<InviteCountQuery, InviteCountQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<InviteCountQuery, InviteCountQueryVariables>(InviteCountDocument, options);
}
export function useInviteCountSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<InviteCountQuery, InviteCountQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<InviteCountQuery, InviteCountQueryVariables>(InviteCountDocument, options);
}
export type InviteCountQueryHookResult = ReturnType<typeof useInviteCountQuery>;
export type InviteCountLazyQueryHookResult = ReturnType<typeof useInviteCountLazyQuery>;
export type InviteCountSuspenseQueryHookResult = ReturnType<typeof useInviteCountSuspenseQuery>;
export type InviteCountQueryResult = Apollo.QueryResult<InviteCountQuery, InviteCountQueryVariables>;
export const DateForChatDocument = gql`
  query DateForChat($date: String!, $timezone: String!) {
    getChatForDate(date: $date, timezone: $timezone) {
      id
      date
      timezone
      userId
      conversation {
        id
        sender
        content
        type
        nodeId
        snackType
        parameters {
          lastGeneratedTopic
          lastGeneratedLabel
          questContext
          userResponse
        }
        aspectDetails {
          name
          category
          id
          friendId
          relationshipType
          connectionStrength
          frequencyOfInteraction
          lastInteractionDate
          sharedInterests
          sharedGoals
          sharedEventsAttended
          friendPersonalityTraits
          communicationChannel
          level
          startDate
          lastUpdated
          learningStyle
          language
          timeOfDay
          learningDuration
          contentType
          notificationPreferences
          type
          completionStatus
          deadline
          priority
          value
          description
          date
          importance
          status
          endDate
          text
          sentiment
          context
          intensity
          timestamp
          trigger
          calories
          carbohydrates_grams
          fat_grams
          fiber_grams
          protein_grams
          serving_size
          sodium_mg
          sugars_grams
          totalCalories
          totalCarbs
          totalFats
          totalProteins
          totalCaloriesBurned
          totalDurationMinutes
          totalWorkouts
          water_intake_oz
        }
      }
    }
  }
`;

/**
 * __useDateForChatQuery__
 *
 * To run a query within a React component, call `useDateForChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useDateForChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDateForChatQuery({
 *   variables: {
 *      date: // value for 'date'
 *      timezone: // value for 'timezone'
 *   },
 * });
 */
export function useDateForChatQuery(
  baseOptions: Apollo.QueryHookOptions<DateForChatQuery, DateForChatQueryVariables> &
    ({ variables: DateForChatQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<DateForChatQuery, DateForChatQueryVariables>(DateForChatDocument, options);
}
export function useDateForChatLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<DateForChatQuery, DateForChatQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<DateForChatQuery, DateForChatQueryVariables>(DateForChatDocument, options);
}
export function useDateForChatSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<DateForChatQuery, DateForChatQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<DateForChatQuery, DateForChatQueryVariables>(DateForChatDocument, options);
}
export type DateForChatQueryHookResult = ReturnType<typeof useDateForChatQuery>;
export type DateForChatLazyQueryHookResult = ReturnType<typeof useDateForChatLazyQuery>;
export type DateForChatSuspenseQueryHookResult = ReturnType<typeof useDateForChatSuspenseQuery>;
export type DateForChatQueryResult = Apollo.QueryResult<DateForChatQuery, DateForChatQueryVariables>;
export const GetJourneyByIdDocument = gql`
  query GetJourneyById($journeyId: ID!) {
    getJourneyById(journeyId: $journeyId) {
      id
      name
      description
      dueDate
      url
      created
      lastModified
      tasks {
        id
        name
        description
        priority
        date
        completed
        isRecurring
        recurringFrequency
        recurringDaily
        recurringDayOfWeek
        recurringDayOfMonth
        recurringTime
        timeOfDay
        timezone
        isPartOfJourney
        journeyId
      }
    }
  }
`;

/**
 * __useGetJourneyByIdQuery__
 *
 * To run a query within a React component, call `useGetJourneyByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJourneyByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJourneyByIdQuery({
 *   variables: {
 *      journeyId: // value for 'journeyId'
 *   },
 * });
 */
export function useGetJourneyByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetJourneyByIdQuery, GetJourneyByIdQueryVariables> &
    ({ variables: GetJourneyByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJourneyByIdQuery, GetJourneyByIdQueryVariables>(GetJourneyByIdDocument, options);
}
export function useGetJourneyByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJourneyByIdQuery, GetJourneyByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJourneyByIdQuery, GetJourneyByIdQueryVariables>(GetJourneyByIdDocument, options);
}
export function useGetJourneyByIdSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetJourneyByIdQuery, GetJourneyByIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJourneyByIdQuery, GetJourneyByIdQueryVariables>(GetJourneyByIdDocument, options);
}
export type GetJourneyByIdQueryHookResult = ReturnType<typeof useGetJourneyByIdQuery>;
export type GetJourneyByIdLazyQueryHookResult = ReturnType<typeof useGetJourneyByIdLazyQuery>;
export type GetJourneyByIdSuspenseQueryHookResult = ReturnType<typeof useGetJourneyByIdSuspenseQuery>;
export type GetJourneyByIdQueryResult = Apollo.QueryResult<GetJourneyByIdQuery, GetJourneyByIdQueryVariables>;
export const GetJourneysDocument = gql`
  query GetJourneys {
    getJourneys {
      id
      name
      description
      url
      created
      lastModified
      dueDate
      totalTasks
      completedTasks
    }
  }
`;

/**
 * __useGetJourneysQuery__
 *
 * To run a query within a React component, call `useGetJourneysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJourneysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJourneysQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetJourneysQuery(
  baseOptions?: Apollo.QueryHookOptions<GetJourneysQuery, GetJourneysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetJourneysQuery, GetJourneysQueryVariables>(GetJourneysDocument, options);
}
export function useGetJourneysLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetJourneysQuery, GetJourneysQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetJourneysQuery, GetJourneysQueryVariables>(GetJourneysDocument, options);
}
export function useGetJourneysSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetJourneysQuery, GetJourneysQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetJourneysQuery, GetJourneysQueryVariables>(GetJourneysDocument, options);
}
export type GetJourneysQueryHookResult = ReturnType<typeof useGetJourneysQuery>;
export type GetJourneysLazyQueryHookResult = ReturnType<typeof useGetJourneysLazyQuery>;
export type GetJourneysSuspenseQueryHookResult = ReturnType<typeof useGetJourneysSuspenseQuery>;
export type GetJourneysQueryResult = Apollo.QueryResult<GetJourneysQuery, GetJourneysQueryVariables>;
export const FieldsDocument = gql`
  query Fields {
    getFields {
      name
      id
      mastery
      isRelevant
      imageUrl
    }
  }
`;

/**
 * __useFieldsQuery__
 *
 * To run a query within a React component, call `useFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFieldsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFieldsQuery(baseOptions?: Apollo.QueryHookOptions<FieldsQuery, FieldsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FieldsQuery, FieldsQueryVariables>(FieldsDocument, options);
}
export function useFieldsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FieldsQuery, FieldsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FieldsQuery, FieldsQueryVariables>(FieldsDocument, options);
}
export function useFieldsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FieldsQuery, FieldsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<FieldsQuery, FieldsQueryVariables>(FieldsDocument, options);
}
export type FieldsQueryHookResult = ReturnType<typeof useFieldsQuery>;
export type FieldsLazyQueryHookResult = ReturnType<typeof useFieldsLazyQuery>;
export type FieldsSuspenseQueryHookResult = ReturnType<typeof useFieldsSuspenseQuery>;
export type FieldsQueryResult = Apollo.QueryResult<FieldsQuery, FieldsQueryVariables>;
export const GetLearningTreeDocument = gql`
  query GetLearningTree($level: String!, $name: String!) {
    getLearningTree(level: $level, name: $name) {
      FieldName
      SubfieldName
      TopicName
      ChapterName
      SubchapterName
    }
  }
`;

/**
 * __useGetLearningTreeQuery__
 *
 * To run a query within a React component, call `useGetLearningTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningTreeQuery({
 *   variables: {
 *      level: // value for 'level'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetLearningTreeQuery(
  baseOptions: Apollo.QueryHookOptions<GetLearningTreeQuery, GetLearningTreeQueryVariables> &
    ({ variables: GetLearningTreeQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLearningTreeQuery, GetLearningTreeQueryVariables>(GetLearningTreeDocument, options);
}
export function useGetLearningTreeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLearningTreeQuery, GetLearningTreeQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLearningTreeQuery, GetLearningTreeQueryVariables>(GetLearningTreeDocument, options);
}
export function useGetLearningTreeSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetLearningTreeQuery, GetLearningTreeQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetLearningTreeQuery, GetLearningTreeQueryVariables>(GetLearningTreeDocument, options);
}
export type GetLearningTreeQueryHookResult = ReturnType<typeof useGetLearningTreeQuery>;
export type GetLearningTreeLazyQueryHookResult = ReturnType<typeof useGetLearningTreeLazyQuery>;
export type GetLearningTreeSuspenseQueryHookResult = ReturnType<typeof useGetLearningTreeSuspenseQuery>;
export type GetLearningTreeQueryResult = Apollo.QueryResult<GetLearningTreeQuery, GetLearningTreeQueryVariables>;
export const NodeGraphDocument = gql`
  query NodeGraph($nodeName: String!, $nodeLabel: NodeLabel!) {
    getNodeChildren(label: $nodeLabel, name: $nodeName) {
      name
      description
      id
      mastery
      label
      unitsCount
      imageUrl
    }
    getNode(label: $nodeLabel, name: $nodeName) {
      name
      description
      id
      mastery
      label
      imageUrl
    }
    getNodeParents(label: $nodeLabel, name: $nodeName) {
      name
      id
      mastery
      label
    }
  }
`;

/**
 * __useNodeGraphQuery__
 *
 * To run a query within a React component, call `useNodeGraphQuery` and pass it any options that fit your needs.
 * When your component renders, `useNodeGraphQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNodeGraphQuery({
 *   variables: {
 *      nodeName: // value for 'nodeName'
 *      nodeLabel: // value for 'nodeLabel'
 *   },
 * });
 */
export function useNodeGraphQuery(
  baseOptions: Apollo.QueryHookOptions<NodeGraphQuery, NodeGraphQueryVariables> &
    ({ variables: NodeGraphQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<NodeGraphQuery, NodeGraphQueryVariables>(NodeGraphDocument, options);
}
export function useNodeGraphLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<NodeGraphQuery, NodeGraphQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<NodeGraphQuery, NodeGraphQueryVariables>(NodeGraphDocument, options);
}
export function useNodeGraphSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<NodeGraphQuery, NodeGraphQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<NodeGraphQuery, NodeGraphQueryVariables>(NodeGraphDocument, options);
}
export type NodeGraphQueryHookResult = ReturnType<typeof useNodeGraphQuery>;
export type NodeGraphLazyQueryHookResult = ReturnType<typeof useNodeGraphLazyQuery>;
export type NodeGraphSuspenseQueryHookResult = ReturnType<typeof useNodeGraphSuspenseQuery>;
export type NodeGraphQueryResult = Apollo.QueryResult<NodeGraphQuery, NodeGraphQueryVariables>;
export const RecentTracksDocument = gql`
  query RecentTracks($filterByCategory: String) {
    getRecentTracks(filterByCategory: $filterByCategory) {
      name
      description
      totalUnits
      completedUnits
      parentFields
      parentSubfield
      mastery
      imageUrl
    }
  }
`;

/**
 * __useRecentTracksQuery__
 *
 * To run a query within a React component, call `useRecentTracksQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentTracksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentTracksQuery({
 *   variables: {
 *      filterByCategory: // value for 'filterByCategory'
 *   },
 * });
 */
export function useRecentTracksQuery(
  baseOptions?: Apollo.QueryHookOptions<RecentTracksQuery, RecentTracksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecentTracksQuery, RecentTracksQueryVariables>(RecentTracksDocument, options);
}
export function useRecentTracksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RecentTracksQuery, RecentTracksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RecentTracksQuery, RecentTracksQueryVariables>(RecentTracksDocument, options);
}
export function useRecentTracksSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RecentTracksQuery, RecentTracksQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RecentTracksQuery, RecentTracksQueryVariables>(RecentTracksDocument, options);
}
export type RecentTracksQueryHookResult = ReturnType<typeof useRecentTracksQuery>;
export type RecentTracksLazyQueryHookResult = ReturnType<typeof useRecentTracksLazyQuery>;
export type RecentTracksSuspenseQueryHookResult = ReturnType<typeof useRecentTracksSuspenseQuery>;
export type RecentTracksQueryResult = Apollo.QueryResult<RecentTracksQuery, RecentTracksQueryVariables>;
export const SiblingSubchaptersDocument = gql`
  query SiblingSubchapters($limit: Int!, $chapterName: String!, $subchapterName: String!) {
    getSiblingSubchapters(limit: $limit, chapterName: $chapterName, subchapterName: $subchapterName) {
      name
      label
      mastery
    }
  }
`;

/**
 * __useSiblingSubchaptersQuery__
 *
 * To run a query within a React component, call `useSiblingSubchaptersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSiblingSubchaptersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSiblingSubchaptersQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      chapterName: // value for 'chapterName'
 *      subchapterName: // value for 'subchapterName'
 *   },
 * });
 */
export function useSiblingSubchaptersQuery(
  baseOptions: Apollo.QueryHookOptions<SiblingSubchaptersQuery, SiblingSubchaptersQueryVariables> &
    ({ variables: SiblingSubchaptersQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SiblingSubchaptersQuery, SiblingSubchaptersQueryVariables>(
    SiblingSubchaptersDocument,
    options
  );
}
export function useSiblingSubchaptersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SiblingSubchaptersQuery, SiblingSubchaptersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SiblingSubchaptersQuery, SiblingSubchaptersQueryVariables>(
    SiblingSubchaptersDocument,
    options
  );
}
export function useSiblingSubchaptersSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SiblingSubchaptersQuery, SiblingSubchaptersQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SiblingSubchaptersQuery, SiblingSubchaptersQueryVariables>(
    SiblingSubchaptersDocument,
    options
  );
}
export type SiblingSubchaptersQueryHookResult = ReturnType<typeof useSiblingSubchaptersQuery>;
export type SiblingSubchaptersLazyQueryHookResult = ReturnType<typeof useSiblingSubchaptersLazyQuery>;
export type SiblingSubchaptersSuspenseQueryHookResult = ReturnType<typeof useSiblingSubchaptersSuspenseQuery>;
export type SiblingSubchaptersQueryResult = Apollo.QueryResult<
  SiblingSubchaptersQuery,
  SiblingSubchaptersQueryVariables
>;
export const SubchapterPrerequisitesDocument = gql`
  query SubchapterPrerequisites($subchapterName: String!, $limit: Int) {
    getSubchapterPrerequisites(subchapterName: $subchapterName, limit: $limit) {
      label
      name
      mastery
    }
  }
`;

/**
 * __useSubchapterPrerequisitesQuery__
 *
 * To run a query within a React component, call `useSubchapterPrerequisitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubchapterPrerequisitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubchapterPrerequisitesQuery({
 *   variables: {
 *      subchapterName: // value for 'subchapterName'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useSubchapterPrerequisitesQuery(
  baseOptions: Apollo.QueryHookOptions<SubchapterPrerequisitesQuery, SubchapterPrerequisitesQueryVariables> &
    ({ variables: SubchapterPrerequisitesQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubchapterPrerequisitesQuery, SubchapterPrerequisitesQueryVariables>(
    SubchapterPrerequisitesDocument,
    options
  );
}
export function useSubchapterPrerequisitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubchapterPrerequisitesQuery, SubchapterPrerequisitesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubchapterPrerequisitesQuery, SubchapterPrerequisitesQueryVariables>(
    SubchapterPrerequisitesDocument,
    options
  );
}
export function useSubchapterPrerequisitesSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<SubchapterPrerequisitesQuery, SubchapterPrerequisitesQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubchapterPrerequisitesQuery, SubchapterPrerequisitesQueryVariables>(
    SubchapterPrerequisitesDocument,
    options
  );
}
export type SubchapterPrerequisitesQueryHookResult = ReturnType<typeof useSubchapterPrerequisitesQuery>;
export type SubchapterPrerequisitesLazyQueryHookResult = ReturnType<typeof useSubchapterPrerequisitesLazyQuery>;
export type SubchapterPrerequisitesSuspenseQueryHookResult = ReturnType<typeof useSubchapterPrerequisitesSuspenseQuery>;
export type SubchapterPrerequisitesQueryResult = Apollo.QueryResult<
  SubchapterPrerequisitesQuery,
  SubchapterPrerequisitesQueryVariables
>;
export const TableOfContentsDocument = gql`
  query TableOfContents($learningNodeNames: [String]!, $hierarchyLabel: String!) {
    getTableOfContents(learningNodeNames: $learningNodeNames, hierarchyLabel: $hierarchyLabel) {
      name
      mastery
      id
      isRelevant
      parentName
      description
      completed
      index
    }
  }
`;

/**
 * __useTableOfContentsQuery__
 *
 * To run a query within a React component, call `useTableOfContentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTableOfContentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTableOfContentsQuery({
 *   variables: {
 *      learningNodeNames: // value for 'learningNodeNames'
 *      hierarchyLabel: // value for 'hierarchyLabel'
 *   },
 * });
 */
export function useTableOfContentsQuery(
  baseOptions: Apollo.QueryHookOptions<TableOfContentsQuery, TableOfContentsQueryVariables> &
    ({ variables: TableOfContentsQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TableOfContentsQuery, TableOfContentsQueryVariables>(TableOfContentsDocument, options);
}
export function useTableOfContentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TableOfContentsQuery, TableOfContentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TableOfContentsQuery, TableOfContentsQueryVariables>(TableOfContentsDocument, options);
}
export function useTableOfContentsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TableOfContentsQuery, TableOfContentsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TableOfContentsQuery, TableOfContentsQueryVariables>(TableOfContentsDocument, options);
}
export type TableOfContentsQueryHookResult = ReturnType<typeof useTableOfContentsQuery>;
export type TableOfContentsLazyQueryHookResult = ReturnType<typeof useTableOfContentsLazyQuery>;
export type TableOfContentsSuspenseQueryHookResult = ReturnType<typeof useTableOfContentsSuspenseQuery>;
export type TableOfContentsQueryResult = Apollo.QueryResult<TableOfContentsQuery, TableOfContentsQueryVariables>;
export const TrackOverviewDocument = gql`
  query TrackOverview($topicName: String!) {
    getTrackOverview(topicName: $topicName) {
      name
      description
      objectives
      lessons {
        name
        description
      }
      skillRequirements
      otherRequirements
      otherRequirementsTitle
      prerequisites {
        name
        subfield
        field
        mastery
      }
    }
  }
`;

/**
 * __useTrackOverviewQuery__
 *
 * To run a query within a React component, call `useTrackOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackOverviewQuery({
 *   variables: {
 *      topicName: // value for 'topicName'
 *   },
 * });
 */
export function useTrackOverviewQuery(
  baseOptions: Apollo.QueryHookOptions<TrackOverviewQuery, TrackOverviewQueryVariables> &
    ({ variables: TrackOverviewQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TrackOverviewQuery, TrackOverviewQueryVariables>(TrackOverviewDocument, options);
}
export function useTrackOverviewLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<TrackOverviewQuery, TrackOverviewQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TrackOverviewQuery, TrackOverviewQueryVariables>(TrackOverviewDocument, options);
}
export function useTrackOverviewSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TrackOverviewQuery, TrackOverviewQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TrackOverviewQuery, TrackOverviewQueryVariables>(TrackOverviewDocument, options);
}
export type TrackOverviewQueryHookResult = ReturnType<typeof useTrackOverviewQuery>;
export type TrackOverviewLazyQueryHookResult = ReturnType<typeof useTrackOverviewLazyQuery>;
export type TrackOverviewSuspenseQueryHookResult = ReturnType<typeof useTrackOverviewSuspenseQuery>;
export type TrackOverviewQueryResult = Apollo.QueryResult<TrackOverviewQuery, TrackOverviewQueryVariables>;
export const UserOverallProgressDocument = gql`
  query UserOverallProgress {
    getUserOverallProgress {
      child {
        name
        label
      }
      parent {
        label
        name
      }
      totalMastery
      lastModified
    }
  }
`;

/**
 * __useUserOverallProgressQuery__
 *
 * To run a query within a React component, call `useUserOverallProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOverallProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOverallProgressQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserOverallProgressQuery(
  baseOptions?: Apollo.QueryHookOptions<UserOverallProgressQuery, UserOverallProgressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserOverallProgressQuery, UserOverallProgressQueryVariables>(
    UserOverallProgressDocument,
    options
  );
}
export function useUserOverallProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserOverallProgressQuery, UserOverallProgressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserOverallProgressQuery, UserOverallProgressQueryVariables>(
    UserOverallProgressDocument,
    options
  );
}
export function useUserOverallProgressSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<UserOverallProgressQuery, UserOverallProgressQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserOverallProgressQuery, UserOverallProgressQueryVariables>(
    UserOverallProgressDocument,
    options
  );
}
export type UserOverallProgressQueryHookResult = ReturnType<typeof useUserOverallProgressQuery>;
export type UserOverallProgressLazyQueryHookResult = ReturnType<typeof useUserOverallProgressLazyQuery>;
export type UserOverallProgressSuspenseQueryHookResult = ReturnType<typeof useUserOverallProgressSuspenseQuery>;
export type UserOverallProgressQueryResult = Apollo.QueryResult<
  UserOverallProgressQuery,
  UserOverallProgressQueryVariables
>;
export const UserProgressDocument = gql`
  query UserProgress($nodeName: String!, $nodeLabel: NodeLabel!) {
    getUserProgress(nodeName: $nodeName, nodeLabel: $nodeLabel) {
      child {
        name
        label
        totalTracks
        completedTracks
      }
      totalMastery
      lastModified
    }
  }
`;

/**
 * __useUserProgressQuery__
 *
 * To run a query within a React component, call `useUserProgressQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProgressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProgressQuery({
 *   variables: {
 *      nodeName: // value for 'nodeName'
 *      nodeLabel: // value for 'nodeLabel'
 *   },
 * });
 */
export function useUserProgressQuery(
  baseOptions: Apollo.QueryHookOptions<UserProgressQuery, UserProgressQueryVariables> &
    ({ variables: UserProgressQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<UserProgressQuery, UserProgressQueryVariables>(UserProgressDocument, options);
}
export function useUserProgressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<UserProgressQuery, UserProgressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<UserProgressQuery, UserProgressQueryVariables>(UserProgressDocument, options);
}
export function useUserProgressSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<UserProgressQuery, UserProgressQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<UserProgressQuery, UserProgressQueryVariables>(UserProgressDocument, options);
}
export type UserProgressQueryHookResult = ReturnType<typeof useUserProgressQuery>;
export type UserProgressLazyQueryHookResult = ReturnType<typeof useUserProgressLazyQuery>;
export type UserProgressSuspenseQueryHookResult = ReturnType<typeof useUserProgressSuspenseQuery>;
export type UserProgressQueryResult = Apollo.QueryResult<UserProgressQuery, UserProgressQueryVariables>;
export const GetFirstSubchapterByTopicNameDocument = gql`
  query GetFirstSubchapterByTopicName($topicName: String!) {
    getFirstSubchapterByTopicName(topicName: $topicName) {
      success
      message
      id
      name
      description
      objectives {
        id
        title
        description
        completed
      }
    }
  }
`;

/**
 * __useGetFirstSubchapterByTopicNameQuery__
 *
 * To run a query within a React component, call `useGetFirstSubchapterByTopicNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirstSubchapterByTopicNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirstSubchapterByTopicNameQuery({
 *   variables: {
 *      topicName: // value for 'topicName'
 *   },
 * });
 */
export function useGetFirstSubchapterByTopicNameQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetFirstSubchapterByTopicNameQuery,
    GetFirstSubchapterByTopicNameQueryVariables
  > &
    ({ variables: GetFirstSubchapterByTopicNameQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFirstSubchapterByTopicNameQuery, GetFirstSubchapterByTopicNameQueryVariables>(
    GetFirstSubchapterByTopicNameDocument,
    options
  );
}
export function useGetFirstSubchapterByTopicNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFirstSubchapterByTopicNameQuery,
    GetFirstSubchapterByTopicNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFirstSubchapterByTopicNameQuery, GetFirstSubchapterByTopicNameQueryVariables>(
    GetFirstSubchapterByTopicNameDocument,
    options
  );
}
export function useGetFirstSubchapterByTopicNameSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetFirstSubchapterByTopicNameQuery, GetFirstSubchapterByTopicNameQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetFirstSubchapterByTopicNameQuery, GetFirstSubchapterByTopicNameQueryVariables>(
    GetFirstSubchapterByTopicNameDocument,
    options
  );
}
export type GetFirstSubchapterByTopicNameQueryHookResult = ReturnType<typeof useGetFirstSubchapterByTopicNameQuery>;
export type GetFirstSubchapterByTopicNameLazyQueryHookResult = ReturnType<
  typeof useGetFirstSubchapterByTopicNameLazyQuery
>;
export type GetFirstSubchapterByTopicNameSuspenseQueryHookResult = ReturnType<
  typeof useGetFirstSubchapterByTopicNameSuspenseQuery
>;
export type GetFirstSubchapterByTopicNameQueryResult = Apollo.QueryResult<
  GetFirstSubchapterByTopicNameQuery,
  GetFirstSubchapterByTopicNameQueryVariables
>;
export const GetLearningObjectiveByIdDocument = gql`
  query GetLearningObjectiveById($loId: ID!) {
    getLearningObjectiveById(loId: $loId) {
      success
      message
      id
      title
      description
      completed
    }
  }
`;

/**
 * __useGetLearningObjectiveByIdQuery__
 *
 * To run a query within a React component, call `useGetLearningObjectiveByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningObjectiveByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningObjectiveByIdQuery({
 *   variables: {
 *      loId: // value for 'loId'
 *   },
 * });
 */
export function useGetLearningObjectiveByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetLearningObjectiveByIdQuery, GetLearningObjectiveByIdQueryVariables> &
    ({ variables: GetLearningObjectiveByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLearningObjectiveByIdQuery, GetLearningObjectiveByIdQueryVariables>(
    GetLearningObjectiveByIdDocument,
    options
  );
}
export function useGetLearningObjectiveByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLearningObjectiveByIdQuery, GetLearningObjectiveByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLearningObjectiveByIdQuery, GetLearningObjectiveByIdQueryVariables>(
    GetLearningObjectiveByIdDocument,
    options
  );
}
export function useGetLearningObjectiveByIdSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<GetLearningObjectiveByIdQuery, GetLearningObjectiveByIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetLearningObjectiveByIdQuery, GetLearningObjectiveByIdQueryVariables>(
    GetLearningObjectiveByIdDocument,
    options
  );
}
export type GetLearningObjectiveByIdQueryHookResult = ReturnType<typeof useGetLearningObjectiveByIdQuery>;
export type GetLearningObjectiveByIdLazyQueryHookResult = ReturnType<typeof useGetLearningObjectiveByIdLazyQuery>;
export type GetLearningObjectiveByIdSuspenseQueryHookResult = ReturnType<
  typeof useGetLearningObjectiveByIdSuspenseQuery
>;
export type GetLearningObjectiveByIdQueryResult = Apollo.QueryResult<
  GetLearningObjectiveByIdQuery,
  GetLearningObjectiveByIdQueryVariables
>;
export const GetLearningObjectivesBySubchapterDocument = gql`
  query GetLearningObjectivesBySubchapter($learningObjectiveId: ID!) {
    getLearningObjectivesBySubchapter(learningObjectiveId: $learningObjectiveId) {
      id
      title
      description
      index
      completed
    }
  }
`;

/**
 * __useGetLearningObjectivesBySubchapterQuery__
 *
 * To run a query within a React component, call `useGetLearningObjectivesBySubchapterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLearningObjectivesBySubchapterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLearningObjectivesBySubchapterQuery({
 *   variables: {
 *      learningObjectiveId: // value for 'learningObjectiveId'
 *   },
 * });
 */
export function useGetLearningObjectivesBySubchapterQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLearningObjectivesBySubchapterQuery,
    GetLearningObjectivesBySubchapterQueryVariables
  > &
    ({ variables: GetLearningObjectivesBySubchapterQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLearningObjectivesBySubchapterQuery, GetLearningObjectivesBySubchapterQueryVariables>(
    GetLearningObjectivesBySubchapterDocument,
    options
  );
}
export function useGetLearningObjectivesBySubchapterLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLearningObjectivesBySubchapterQuery,
    GetLearningObjectivesBySubchapterQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLearningObjectivesBySubchapterQuery, GetLearningObjectivesBySubchapterQueryVariables>(
    GetLearningObjectivesBySubchapterDocument,
    options
  );
}
export function useGetLearningObjectivesBySubchapterSuspenseQuery(
  baseOptions?:
    | Apollo.SkipToken
    | Apollo.SuspenseQueryHookOptions<
        GetLearningObjectivesBySubchapterQuery,
        GetLearningObjectivesBySubchapterQueryVariables
      >
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<
    GetLearningObjectivesBySubchapterQuery,
    GetLearningObjectivesBySubchapterQueryVariables
  >(GetLearningObjectivesBySubchapterDocument, options);
}
export type GetLearningObjectivesBySubchapterQueryHookResult = ReturnType<
  typeof useGetLearningObjectivesBySubchapterQuery
>;
export type GetLearningObjectivesBySubchapterLazyQueryHookResult = ReturnType<
  typeof useGetLearningObjectivesBySubchapterLazyQuery
>;
export type GetLearningObjectivesBySubchapterSuspenseQueryHookResult = ReturnType<
  typeof useGetLearningObjectivesBySubchapterSuspenseQuery
>;
export type GetLearningObjectivesBySubchapterQueryResult = Apollo.QueryResult<
  GetLearningObjectivesBySubchapterQuery,
  GetLearningObjectivesBySubchapterQueryVariables
>;
export const SubchapterByIdDocument = gql`
  query SubchapterById($name: String!) {
    getSubchapterById(name: $name) {
      id
      name
      description
      objectives {
        id
        title
        description
        completed
      }
      totalMastery
    }
  }
`;

/**
 * __useSubchapterByIdQuery__
 *
 * To run a query within a React component, call `useSubchapterByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubchapterByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubchapterByIdQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useSubchapterByIdQuery(
  baseOptions: Apollo.QueryHookOptions<SubchapterByIdQuery, SubchapterByIdQueryVariables> &
    ({ variables: SubchapterByIdQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SubchapterByIdQuery, SubchapterByIdQueryVariables>(SubchapterByIdDocument, options);
}
export function useSubchapterByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<SubchapterByIdQuery, SubchapterByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SubchapterByIdQuery, SubchapterByIdQueryVariables>(SubchapterByIdDocument, options);
}
export function useSubchapterByIdSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SubchapterByIdQuery, SubchapterByIdQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SubchapterByIdQuery, SubchapterByIdQueryVariables>(SubchapterByIdDocument, options);
}
export type SubchapterByIdQueryHookResult = ReturnType<typeof useSubchapterByIdQuery>;
export type SubchapterByIdLazyQueryHookResult = ReturnType<typeof useSubchapterByIdLazyQuery>;
export type SubchapterByIdSuspenseQueryHookResult = ReturnType<typeof useSubchapterByIdSuspenseQuery>;
export type SubchapterByIdQueryResult = Apollo.QueryResult<SubchapterByIdQuery, SubchapterByIdQueryVariables>;
export const GetMasteryDocument = gql`
  query GetMastery($learningObjectiveId: ID!) {
    getUserLearningObjectiveMastery(learningObjectiveId: $learningObjectiveId) {
      learningObjectiveId
      userId
      totalMastery
    }
  }
`;

/**
 * __useGetMasteryQuery__
 *
 * To run a query within a React component, call `useGetMasteryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasteryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasteryQuery({
 *   variables: {
 *      learningObjectiveId: // value for 'learningObjectiveId'
 *   },
 * });
 */
export function useGetMasteryQuery(
  baseOptions: Apollo.QueryHookOptions<GetMasteryQuery, GetMasteryQueryVariables> &
    ({ variables: GetMasteryQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMasteryQuery, GetMasteryQueryVariables>(GetMasteryDocument, options);
}
export function useGetMasteryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMasteryQuery, GetMasteryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMasteryQuery, GetMasteryQueryVariables>(GetMasteryDocument, options);
}
export function useGetMasterySuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetMasteryQuery, GetMasteryQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetMasteryQuery, GetMasteryQueryVariables>(GetMasteryDocument, options);
}
export type GetMasteryQueryHookResult = ReturnType<typeof useGetMasteryQuery>;
export type GetMasteryLazyQueryHookResult = ReturnType<typeof useGetMasteryLazyQuery>;
export type GetMasterySuspenseQueryHookResult = ReturnType<typeof useGetMasterySuspenseQuery>;
export type GetMasteryQueryResult = Apollo.QueryResult<GetMasteryQuery, GetMasteryQueryVariables>;
export const ExportCsvDocument = gql`
  query ExportCsv {
    exportCsv {
      success
      csvData
      message
    }
  }
`;

/**
 * __useExportCsvQuery__
 *
 * To run a query within a React component, call `useExportCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useExportCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useExportCsvQuery({
 *   variables: {
 *   },
 * });
 */
export function useExportCsvQuery(baseOptions?: Apollo.QueryHookOptions<ExportCsvQuery, ExportCsvQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ExportCsvQuery, ExportCsvQueryVariables>(ExportCsvDocument, options);
}
export function useExportCsvLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ExportCsvQuery, ExportCsvQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ExportCsvQuery, ExportCsvQueryVariables>(ExportCsvDocument, options);
}
export function useExportCsvSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<ExportCsvQuery, ExportCsvQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<ExportCsvQuery, ExportCsvQueryVariables>(ExportCsvDocument, options);
}
export type ExportCsvQueryHookResult = ReturnType<typeof useExportCsvQuery>;
export type ExportCsvLazyQueryHookResult = ReturnType<typeof useExportCsvLazyQuery>;
export type ExportCsvSuspenseQueryHookResult = ReturnType<typeof useExportCsvSuspenseQuery>;
export type ExportCsvQueryResult = Apollo.QueryResult<ExportCsvQuery, ExportCsvQueryVariables>;
export const AssignmentDocument = gql`
  query Assignment($assignmentId: ID!) {
    getAssignment(assignmentId: $assignmentId) {
      id
      title
      description
      dueDate
      url
      objectives {
        id
        objective
        status
      }
    }
  }
`;

/**
 * __useAssignmentQuery__
 *
 * To run a query within a React component, call `useAssignmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentQuery({
 *   variables: {
 *      assignmentId: // value for 'assignmentId'
 *   },
 * });
 */
export function useAssignmentQuery(
  baseOptions: Apollo.QueryHookOptions<AssignmentQuery, AssignmentQueryVariables> &
    ({ variables: AssignmentQueryVariables; skip?: boolean } | { skip: boolean })
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssignmentQuery, AssignmentQueryVariables>(AssignmentDocument, options);
}
export function useAssignmentLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AssignmentQuery, AssignmentQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AssignmentQuery, AssignmentQueryVariables>(AssignmentDocument, options);
}
export function useAssignmentSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AssignmentQuery, AssignmentQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AssignmentQuery, AssignmentQueryVariables>(AssignmentDocument, options);
}
export type AssignmentQueryHookResult = ReturnType<typeof useAssignmentQuery>;
export type AssignmentLazyQueryHookResult = ReturnType<typeof useAssignmentLazyQuery>;
export type AssignmentSuspenseQueryHookResult = ReturnType<typeof useAssignmentSuspenseQuery>;
export type AssignmentQueryResult = Apollo.QueryResult<AssignmentQuery, AssignmentQueryVariables>;
export const AssignmentsDocument = gql`
  query Assignments {
    getAssignments {
      id
      title
      description
      dueDate
      url
      objectives {
        id
        objective
        status
      }
    }
  }
`;

/**
 * __useAssignmentsQuery__
 *
 * To run a query within a React component, call `useAssignmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAssignmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAssignmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAssignmentsQuery(
  baseOptions?: Apollo.QueryHookOptions<AssignmentsQuery, AssignmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<AssignmentsQuery, AssignmentsQueryVariables>(AssignmentsDocument, options);
}
export function useAssignmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<AssignmentsQuery, AssignmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<AssignmentsQuery, AssignmentsQueryVariables>(AssignmentsDocument, options);
}
export function useAssignmentsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<AssignmentsQuery, AssignmentsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<AssignmentsQuery, AssignmentsQueryVariables>(AssignmentsDocument, options);
}
export type AssignmentsQueryHookResult = ReturnType<typeof useAssignmentsQuery>;
export type AssignmentsLazyQueryHookResult = ReturnType<typeof useAssignmentsLazyQuery>;
export type AssignmentsSuspenseQueryHookResult = ReturnType<typeof useAssignmentsSuspenseQuery>;
export type AssignmentsQueryResult = Apollo.QueryResult<AssignmentsQuery, AssignmentsQueryVariables>;
export const SchoolsDocument = gql`
  query Schools {
    getSchools {
      id
      providerName
      teamName
      icon
    }
  }
`;

/**
 * __useSchoolsQuery__
 *
 * To run a query within a React component, call `useSchoolsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSchoolsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSchoolsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSchoolsQuery(baseOptions?: Apollo.QueryHookOptions<SchoolsQuery, SchoolsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<SchoolsQuery, SchoolsQueryVariables>(SchoolsDocument, options);
}
export function useSchoolsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SchoolsQuery, SchoolsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<SchoolsQuery, SchoolsQueryVariables>(SchoolsDocument, options);
}
export function useSchoolsSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SchoolsQuery, SchoolsQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<SchoolsQuery, SchoolsQueryVariables>(SchoolsDocument, options);
}
export type SchoolsQueryHookResult = ReturnType<typeof useSchoolsQuery>;
export type SchoolsLazyQueryHookResult = ReturnType<typeof useSchoolsLazyQuery>;
export type SchoolsSuspenseQueryHookResult = ReturnType<typeof useSchoolsSuspenseQuery>;
export type SchoolsQueryResult = Apollo.QueryResult<SchoolsQuery, SchoolsQueryVariables>;
export const DeleteTodoDocument = gql`
  mutation DeleteTodo($id: String!) {
    deleteTodo(id: $id) {
      success
      message
    }
  }
`;
export type DeleteTodoMutationFn = Apollo.MutationFunction<DeleteTodoMutation, DeleteTodoMutationVariables>;

/**
 * __useDeleteTodoMutation__
 *
 * To run a mutation, you first call `useDeleteTodoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTodoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTodoMutation, { data, loading, error }] = useDeleteTodoMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTodoMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTodoMutation, DeleteTodoMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTodoMutation, DeleteTodoMutationVariables>(DeleteTodoDocument, options);
}
export type DeleteTodoMutationHookResult = ReturnType<typeof useDeleteTodoMutation>;
export type DeleteTodoMutationResult = Apollo.MutationResult<DeleteTodoMutation>;
export type DeleteTodoMutationOptions = Apollo.BaseMutationOptions<DeleteTodoMutation, DeleteTodoMutationVariables>;
export const RecentTodosDocument = gql`
  query RecentTodos {
    recentTodos {
      id
      name
      description
      priority
      date
      completed
      isRecurring
      recurringFrequency
      recurringDaily
      recurringDayOfWeek
      recurringDayOfMonth
      recurringTime
      timeOfDay
      timezone
      isPartOfJourney
      journeyId
    }
  }
`;

/**
 * __useRecentTodosQuery__
 *
 * To run a query within a React component, call `useRecentTodosQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecentTodosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecentTodosQuery({
 *   variables: {
 *   },
 * });
 */
export function useRecentTodosQuery(
  baseOptions?: Apollo.QueryHookOptions<RecentTodosQuery, RecentTodosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<RecentTodosQuery, RecentTodosQueryVariables>(RecentTodosDocument, options);
}
export function useRecentTodosLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<RecentTodosQuery, RecentTodosQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<RecentTodosQuery, RecentTodosQueryVariables>(RecentTodosDocument, options);
}
export function useRecentTodosSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<RecentTodosQuery, RecentTodosQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<RecentTodosQuery, RecentTodosQueryVariables>(RecentTodosDocument, options);
}
export type RecentTodosQueryHookResult = ReturnType<typeof useRecentTodosQuery>;
export type RecentTodosLazyQueryHookResult = ReturnType<typeof useRecentTodosLazyQuery>;
export type RecentTodosSuspenseQueryHookResult = ReturnType<typeof useRecentTodosSuspenseQuery>;
export type RecentTodosQueryResult = Apollo.QueryResult<RecentTodosQuery, RecentTodosQueryVariables>;
export const TodosDocument = gql`
  query Todos {
    todos {
      id
      name
      description
      priority
      date
      completed
      isRecurring
      recurringFrequency
      recurringDaily
      recurringDayOfWeek
      recurringDayOfMonth
      recurringTime
      timeOfDay
      timezone
      isPartOfJourney
      journeyId
    }
  }
`;

/**
 * __useTodosQuery__
 *
 * To run a query within a React component, call `useTodosQuery` and pass it any options that fit your needs.
 * When your component renders, `useTodosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTodosQuery({
 *   variables: {
 *   },
 * });
 */
export function useTodosQuery(baseOptions?: Apollo.QueryHookOptions<TodosQuery, TodosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<TodosQuery, TodosQueryVariables>(TodosDocument, options);
}
export function useTodosLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TodosQuery, TodosQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<TodosQuery, TodosQueryVariables>(TodosDocument, options);
}
export function useTodosSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<TodosQuery, TodosQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<TodosQuery, TodosQueryVariables>(TodosDocument, options);
}
export type TodosQueryHookResult = ReturnType<typeof useTodosQuery>;
export type TodosLazyQueryHookResult = ReturnType<typeof useTodosLazyQuery>;
export type TodosSuspenseQueryHookResult = ReturnType<typeof useTodosSuspenseQuery>;
export type TodosQueryResult = Apollo.QueryResult<TodosQuery, TodosQueryVariables>;
export const GetActiveTracksDocument = gql`
  query GetActiveTracks {
    getActiveTracks {
      name
      description
      last_modified
      totalUnits
      completedUnits
      parentSubfield
      parentFields
      mastery
      imageUrl
    }
  }
`;

/**
 * __useGetActiveTracksQuery__
 *
 * To run a query within a React component, call `useGetActiveTracksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetActiveTracksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetActiveTracksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetActiveTracksQuery(
  baseOptions?: Apollo.QueryHookOptions<GetActiveTracksQuery, GetActiveTracksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetActiveTracksQuery, GetActiveTracksQueryVariables>(GetActiveTracksDocument, options);
}
export function useGetActiveTracksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetActiveTracksQuery, GetActiveTracksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetActiveTracksQuery, GetActiveTracksQueryVariables>(GetActiveTracksDocument, options);
}
export function useGetActiveTracksSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetActiveTracksQuery, GetActiveTracksQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<GetActiveTracksQuery, GetActiveTracksQueryVariables>(GetActiveTracksDocument, options);
}
export type GetActiveTracksQueryHookResult = ReturnType<typeof useGetActiveTracksQuery>;
export type GetActiveTracksLazyQueryHookResult = ReturnType<typeof useGetActiveTracksLazyQuery>;
export type GetActiveTracksSuspenseQueryHookResult = ReturnType<typeof useGetActiveTracksSuspenseQuery>;
export type GetActiveTracksQueryResult = Apollo.QueryResult<GetActiveTracksQuery, GetActiveTracksQueryVariables>;
export const MeDocument = gql`
  query Me {
    me {
      audioOn
      countryCode
      currentWeightLbs
      dailyEmailOptIn
      enrolledTracks
      email
      fullPhoneNumber
      gender
      goalWeightLbs
      googleCalendarAuthToken
      id
      isCareerLoading
      isCourseLoading
      isEmailVerified
      isPro
      isOnboarded
      modalOnboarding
      name
      otpPreference
      phoneNumber
      trialEndDate
      username
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeSuspenseQuery(
  baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<MeQuery, MeQueryVariables>
) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : { ...defaultOptions, ...baseOptions };
  return Apollo.useSuspenseQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeSuspenseQueryHookResult = ReturnType<typeof useMeSuspenseQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
